import React from 'react'
import styled from 'styled-components'

import { TextMLight } from '../../typography'
import { PaletteColor, palette } from '../../palette'
import { TooltipArrow } from './TooltipArrow'
import { NewIconButton } from '../../buttons/NewIconButton'
import { TooltipProps } from '../../types'

type Props = TooltipProps & {
  isOpen: boolean
  children: React.ReactNode
  bgColor?: PaletteColor
  size?: 'L' | 'M'
  padding?: string
  width?: string
  onClose?: () => void
}

// TODO вероятно стоит объединить с существующим тултипом

export const LightTooltip = ({
  refs,
  floatingStyles,
  isOpen,
  children,
  bgColor,
  size = 'L',
  padding,
  arrowProps,
  getFloatingProps,
  onClose,
}: Props) => (
  <>
    {isOpen && (
      <Tooltip
        ref={refs.setFloating}
        style={floatingStyles}
        {...getFloatingProps()}
        bgColor={bgColor}
        padding={padding}
        size={size}
      >
        {children}

        {arrowProps && <TooltipArrow {...arrowProps} fill="white" />}

        {onClose && (
          <ButtonWrapper>
            <NewIconButton
              icon="cross"
              size={size === 'L' ? 16 : 12}
              dataTestId="close-tooltip-btn"
              onClick={onClose}
            />
          </ButtonWrapper>
        )}
      </Tooltip>
    )}
  </>
)

type ToolTipProps = {
  size: Props['size']
  bgColor?: PaletteColor,
  padding?: string
  width?: string
}

const Tooltip = styled.div<ToolTipProps>`
  position: relative;
  max-width: 300px;
  background-color: ${({ bgColor }) => bgColor || palette.white};
  padding: ${({ size, padding }) => (padding ?? (size === 'L' ? '12px 20px 12px 14px' : '8px 16px 8px 8px'))};
  ${TextMLight};
  border-radius: 8px;
  box-shadow: 0 0 18px 1px ${palette.tooltipShadow};
  z-index: 900;
  text-align: left;
`

const ButtonWrapper = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
`
