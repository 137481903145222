import React from 'react'
import { useUnit } from 'effector-react'

import { AuthLayout } from '@/ui'

import { RestorePasswordForm } from '../containers'
import { $isSuccess } from '../../model/private'

export const RestorePassword = React.memo(() => {
  const isSuccess = useUnit($isSuccess)

  return (
    <AuthLayout
      title="Восстановление доступа"
      isSuccess={isSuccess}
      hasBackButton
      subText="Введите электронную почту, на которую зарегистрирован ваш личный кабинет."
    >
      <RestorePasswordForm />
    </AuthLayout>
  )
})


