import { createGate } from 'effector-react'

import { PaymentId } from '@/dal'
import { createDocumentBlocking, createSectionTabsModel } from '@/features/factories'

import {
  $paymentDocumentId,
  $paymentFeatures,
  $paymentInfo,
  getPaymentFeaturesFx,
  getPaymentItemFx,
  postPaymentToApproveFx,
} from './private'
import { PaymentTab } from './types'
import { d } from './domain'

export const $isPaymentLoading = getPaymentItemFx.pending
export const PaymentGate = createGate<{ id: PaymentId | null }>('PaymentGate')

export const $paymentRejectReason = $paymentInfo.map((info) => info?.cancel_comment ?? null)
export const $paymentExclusiveBlock = $paymentInfo.map((info) => (
  info?.features.document_exclusive_block ?? null
))

export const {
  $activeTabId,
  $invalidActiveTabId,
  changeActiveTab,
  resetActiveTab,
  setInvalidActiveTab,
} = createSectionTabsModel<PaymentTab>({
  clearErrorTime: 3500,
  defaultValue: PaymentTab.Info,
  clearEvent: PaymentGate.close,
  domain: d,
})

export const { checkDocumentBlocking } = createDocumentBlocking({
  getFeaturesFx: getPaymentFeaturesFx,
  $docId: $paymentDocumentId,
  $features: $paymentFeatures,
  triggerUnits: [postPaymentToApproveFx],
})
