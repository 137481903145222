import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import {
  palette,
  TextM,
  TextMLight,
} from '@/ui'
import { numberWithSpaces } from '@/lib/number-represent'

import { $paymentInfo } from '../../../model/private'
import { GuaranteeContractItem } from '../parts'

export const GuaranteeContracts = React.memo(() => {
  const info = useUnit($paymentInfo)

  if (!info?.guarantee_contracts) return null

  const { guarantee_contracts } = info
  const totalSum = guarantee_contracts.total_sum_of_security

  return (
    <Wrapper>
      <Header>
        <Title>Гарантии обеспечения</Title>
        <ColName>Срок обеспечения</ColName>
        <ColName>Сумма, руб</ColName>
      </Header>

      <ContractList>
        {guarantee_contracts.items.map((item) => (
          <GuaranteeContractItem key={item.id} {...item} />
        ))}
      </ContractList>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  padding: 24px 20px;
  border-radius: 20px;
  background-color: ${palette.white};
`

const Header = styled.header`
  display: grid;
  grid-template-columns: minmax(0, 400px) minmax(0, 160px) minmax(0, 120px);
  gap: 22px;
`

const Title = styled.h3`
  ${TextM}
  text-transform: uppercase;
`

const ColName = styled.p`
  ${TextMLight}
  text-align: right;
  color: ${palette.grey70};
`

const ContractList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  height: 20px;
  background-color: ${palette.grey10};
`

const TotalSumLabel = styled.p`
  ${TextM}
  color: ${palette.grey70};
`

const TotalSum = styled.p`
  ${TextM}
`
