import { attach, combine } from 'effector'
import { createForm } from 'effector-forms'
import { v4 as uuid4 } from 'uuid'

import {
  CompanyItem,
  ContactItemType,
  ProjectItemType,
  createContactReqFx,
  editContactReqFx,
} from '@/dal'

import { rules } from '@/lib/form-validators'
import { $allRoles, $companyProjects } from '@/features/my-company/model'

import { d } from './domain'
import { ProjectField } from './types'

export const $isOpen = d.store<boolean>(false)
export const closeModal = d.event<void>()

export const $roles = $allRoles.map((items) => items)
export const $projects = $companyProjects.map((items) => items)

export const $isEdit = d.store<boolean>(false)

export const addProjectField = d.event<void>()

export const contactForm = createForm({
  domain: d,
  fields: {
    id: {
      init: null as null | ContactItemType['id'],
    },
    company: {
      init: null as null | CompanyItem['id'],
    },
    fio: {
      init: '',
      rules: [rules.required()],
    },
    projects: {
      init: [{
        id: uuid4(),
        projectId: null as null | ProjectItemType['id'],
        roles: [],
      }] as ProjectField[],
      rules: [rules.contactProject()],
    },
    phone_number: {
      init: '',
      rules: [rules.required(), rules.phoneNumber()],
    },
    email: {
      init: '',
      rules: [rules.required(), rules.email()],
    },
  },
})

export const createContactFx = attach({
  effect: createContactReqFx,
})

export const editContactFx = attach({
  effect: editContactReqFx,
})

export const $isFormDisabled = combine(
  [createContactFx.pending, editContactFx.pending, $isEdit], ([a, b, isEdit]) => (isEdit ? b : a),
)

export const $hasProjectsToAdd = combine(
  [$projects, contactForm.$values],
  ([projects, form]) => (projects ? projects.length > form.projects.length : false),
)
