import { sample, split } from 'effector'

import { isAppealTaskItem } from '@/dal'
import { $userAuthorised, logout } from '@/features/login/model'

import {
  $selectedTaskType,
  $tasksList,
  getMoreTasksFx,
  initTaskListFx,
  loadMoreTasks,
  selectTaskType,
  getActiveTaskCountFx,
  startPolling,
  stopPolling,
  ActiveTaskGate,
  $taskTypeList,
  getTaskFiltersFx,
  $appealItems,
  $appealsFilters,
  $selectedAppealFilter,
  $selectedTabId,
  TasksAndResponsesGate,
  getAppealsFiltersFx,
  selectAppealFilter,
  selectTab,
  AppealResponsesGate,
  $appealResponsesCount,
  getResponsesCountFx,
  getAppealResponsesFx,
} from './private'
import { $activeTaskCount } from './public'
import { completeTaskFx, markTaskAsReadFx } from './appeals.private'
import { calculateTaskStatusByItem } from './helpers'

import './appeals.init'

$selectedTabId.on(selectTab, (_, id) => id)
  .reset(TasksAndResponsesGate.close)

$activeTaskCount
  .on(getActiveTaskCountFx.doneData, (_, g) => g.count)
  .reset(logout)

$appealResponsesCount
  .on(getResponsesCountFx.doneData, (_, g) => g.count)
  .reset(TasksAndResponsesGate.close)

$tasksList
  .on(initTaskListFx.doneData, (_, tasks) => tasks.data)
  .on(getMoreTasksFx.doneData,
    (prevItems, tasks) => (prevItems ? [...prevItems, ...tasks.data] : tasks.data),
  )
  .on([markTaskAsReadFx.doneData, completeTaskFx.doneData], (tasks, item) => {
    return tasks
      ? tasks.map((task) => (isAppealTaskItem(task) && Number(task.document_id) === item.id
        ? {
          ...task,
          task_data: {
            ...task.task_data,
            // быстрые костыли из-за различий в структуре данных
            task_status: calculateTaskStatusByItem(item),
            can_send_message: !item.completed,
          },
        }
        : task),
      )
      : null
  })
  .reset(ActiveTaskGate.close)

$selectedTaskType
  .on(selectTaskType, (_, type) => type)
  .reset(ActiveTaskGate.close)

$taskTypeList
  .on(getTaskFiltersFx.doneData, (prev, filters) => ([
    ...prev,
    ...filters,
  ]))
  .reset(ActiveTaskGate.close)

$appealItems
  .on(getAppealResponsesFx.doneData, (_, { items }) => items)
  .reset(AppealResponsesGate.close)

$appealsFilters
  .on(getAppealsFiltersFx.doneData, (_, filters) => (
    filters.length ? filters : [{ value: 'all', label: 'Все' }]
  ))
  .reset(AppealResponsesGate.close)

$selectedAppealFilter
  .on(getAppealsFiltersFx.doneData, (selected, filters) => selected ?? filters[0].value)
  .on(selectAppealFilter, (_, value) => value)
  .reset(AppealResponsesGate.close)

sample({
  clock: $userAuthorised,
  filter: $userAuthorised,
  target: getActiveTaskCountFx,
})

sample({
  clock: [TasksAndResponsesGate.open, AppealResponsesGate.open],
  fn: () => ({}),
  target: getResponsesCountFx,
})

split({
  source: $userAuthorised,
  match: {
    startPolling: (isAuth) => Boolean(isAuth),
    stopPolling: (isAuth) => !isAuth,
  },
  cases: {
    startPolling,
    stopPolling,
  },
})

sample({
  clock: ActiveTaskGate.open,
  fn: () => ({}),
  target: getTaskFiltersFx,
})

sample({
  clock: [ActiveTaskGate.open, $selectedTaskType.updates],
  source: $selectedTaskType,
  fn: (type) => ({
    type,
  }),
  target: initTaskListFx,
})

sample({
  clock: loadMoreTasks,
  source: $selectedTaskType,
  fn: (type) => ({
    type,
  }),
  target: getMoreTasksFx,
})

sample({
  clock: AppealResponsesGate.open,
  fn: () => ({}),
  target: getAppealsFiltersFx,
})

sample({
  clock: [AppealResponsesGate.open, $selectedAppealFilter.updates],
  source: $selectedAppealFilter,
  filter: Boolean,
  fn: (type) => ({ request_type: [type] }),
  target: getAppealResponsesFx,
})
