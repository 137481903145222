import { createForm } from 'effector-forms'

import { createSingleEffect } from '@/lib/createSingleEffect'
import {
  ActWorkStatus, RemainedFilter, RowFilterEnum, getArticlesOutlayReqFx,
} from '@/dal'
import { rules } from '@/lib/form-validators'

import { d } from './domain'
import { ActWorkGate } from './private'

export const $searchName = d.store('')
export const changeSearchName = d.event<string>()
export const resetSearchName = d.event()
export const $articleItems = d.store<{ id: number, label: string }[]>([])

export const filters = createForm({
  fields: {
    article: {
      init: [] as number[],
    },
    remained: {
      init: [] as RemainedFilter[],
    },
    reportPeriod: {
      init: [] as RemainedFilter[],
    },
    rowFilters: {
      init: [RowFilterEnum.HideDeleted],
    },
    acceptanceStatus: {
      init: null as ActWorkStatus | null,
    },
    dateFrom: {
      init: '',
      rules: [rules.dateRange('endDate', true)],
    },
    dateTo: {
      init: '',
      rules: [rules.dateRange('startDate')],
    },
  },
})

export const acceptSearch = d.event<void>()
export const resetFilters = d.event<void>()

export const {
  requestFx: getActArticleFx,
} = createSingleEffect(getArticlesOutlayReqFx, { abortEvents: [ActWorkGate.close] })
