import React from 'react'
import styled, { css } from 'styled-components'

import { getHighlightedText } from '@/lib/getHighlightedText'

import { palette } from '../palette'
import { TextLLight, TextMLight, TextSLight } from '../typography'
import { Option, OptionSize } from './types'
import { Icon2 } from '../icons'
import { NewCheckbox } from '../components/NewCheckbox'

type Props = Option & {
  searchValue?: string
  size?: OptionSize
  isActive?: boolean
  isDisabled?: boolean
  hasCheckbox?: boolean
  dataTestId: string
  isAction?: boolean
  onClick: (id: Option['id'] | null) => void
}

export const DropDownOption = ({
  id,
  label,
  subLabel,
  searchValue,
  icon,
  size = 'M',
  isActive,
  isDisabled,
  hasCheckbox,
  dataTestId,
  isAction,
  onClick,
}: Props) => {
  const highlightedLabel = getHighlightedText(
    label,
    searchValue,
    palette.yellow30,
    isActive ? palette.accent100 : palette.grey90,
  )

  const handleClick = () => onClick(id || null)

  return (
    <Item
      onClick={handleClick}
      isDisabled={isDisabled}
      size={size}
      data-testid={`${dataTestId}-option-${id}`}
    >
      {subLabel && <SubLabel color={subLabel.color}>{subLabel?.text}</SubLabel>}
      <LabelContainer>
        {hasCheckbox && (
          <NewCheckbox isChecked={isActive} value="" onChange={handleClick} />
        )}
        {icon && <Icon2 icon={icon} color={(isActive || isAction) ? 'accent100' : 'grey90'} />}
        <Label isActive={isActive || Boolean(isAction)} size={size}>
          {highlightedLabel}
        </Label>
      </LabelContainer>
    </Item>
  )
}

const Item = styled.li<Pick<Props, 'isDisabled' | 'size'>>`
  width: 100%;
  transition: color 0.15s linear, background-color 0.15s linear;
  padding: ${({ size }) => (size === 'M' ? '8px 12px' : '8px 16px')};
  min-height: 34px;
  text-align: left;
  ${TextMLight}
  user-select: none;

  @media (hover: hover) {
    &:hover {
      background: ${palette.grey10};
    }
  }

  &:active {
    background: ${palette.grey20};
  }

  ${({ isDisabled }) => (isDisabled
    ? css`
        color: ${palette.grey60};

        @media (hover: hover) {
          &:hover {
            background: ${palette.white};
          }
        }
      `
    : css`
        cursor: pointer;
      `)}
`

const SubLabel = styled.p<{ color: keyof typeof palette }>`
  ${TextSLight}
  color: ${({ color }) => palette[color]};
`

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const Label = styled.p<Pick<Props, 'size' | 'isActive'>>`
  ${({ size }) => (size === 'M' ? TextMLight : TextLLight)};
  
  ${({ isActive }) => isActive && css`
    color: ${palette.accent100};
  `}
`
