import { sample } from 'effector'

import { MorphologyItem, MorphologyType } from '@/dal'
import { $documentInfo, MorphologyRow } from '@/features/estimate-lot/model'

import {
  $isDeleteMorphologyModalOpen,
  $morphologyToDelete,
  $morphologyCatalog,
  $selectedMorphology,
  changeMorphologyRow,
  closeDeleteMorphologyModal,
  openDeleteMorphologyModal,
  setSelectedMorphology,
  $isNewRowAdded,
  setIsNewRowAdded,
} from './morphology.private'
import { getMorphologyFx } from './private'
import { EstimateLotGate } from './public'
import { getMorphologyRowDeepestNonNullField } from './helpers'

$morphologyCatalog
  .on(getMorphologyFx.doneData, (_, data) => data)
  .reset(EstimateLotGate.close)

$isDeleteMorphologyModalOpen
  .on(openDeleteMorphologyModal, () => true)
  .reset(EstimateLotGate.close, closeDeleteMorphologyModal)

$morphologyToDelete
  .on(openDeleteMorphologyModal, (_, data) => data)
  .reset(EstimateLotGate.close, closeDeleteMorphologyModal)

$isNewRowAdded
  .on(setIsNewRowAdded, (_, value) => value)
  .reset(EstimateLotGate.close)

$selectedMorphology
  .on(setSelectedMorphology, (_, data) => data)
  .on(changeMorphologyRow, (data, { row, value, type }) => {
    if (!row.object && type === MorphologyType.Object) {
      return [...data, { type, id: value }]
    }
    const oldDeepestField: keyof MorphologyRow | null = getMorphologyRowDeepestNonNullField(row)
    const updatedRow = {
      ...row,
      [type]: value,
    }
    const isFieldDeletion = type === MorphologyType.Section ? !value.length : !value
    const newDeepestField = isFieldDeletion ? getMorphologyRowDeepestNonNullField(updatedRow) : type

    let itemsToInsert: MorphologyItem[] = []

    if (newDeepestField) {
      if (newDeepestField === MorphologyType.Section) {
        itemsToInsert = updatedRow.section.map((id) => ({
          id,
          type: MorphologyType.Section,
        }))
      } else if (updatedRow[newDeepestField]) {
        itemsToInsert = [{
          id: updatedRow[newDeepestField],
          type: newDeepestField,
        }]
      }
    }

    const indexToInsert = data.findIndex((item) => {
      const value = (oldDeepestField && row[oldDeepestField]) || null
      const doesIdMatch = Array.isArray(value) ? value.includes(item.id) : item.id === value
      return item.type === oldDeepestField && doesIdMatch
    })
    const filteredArr = data.filter((item) => {
      if (item.type !== oldDeepestField) return true
      return oldDeepestField === MorphologyType.Section
        ? !row.section.includes(item.id)
        : row[oldDeepestField] !== item.id
    })
    return filteredArr.toSpliced(indexToInsert, 0, ...itemsToInsert)
  })
  .reset(EstimateLotGate.close)

sample({
  clock: $documentInfo,
  filter: Boolean,
  fn: ({ morphology }) => morphology,
  target: setSelectedMorphology,
})

sample({
  clock: changeMorphologyRow,
  fn: () => false,
  target: setIsNewRowAdded,
})
