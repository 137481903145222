import { css } from 'styled-components'
import { WINDOW_WIDTH } from '@/ui'

export const GridTemplate = css`
  display: grid;
  grid-template-columns:
    minmax(135px, 135fr) minmax(135px, 135fr) minmax(120px, 120fr)
    minmax(150px, 150fr) minmax(120px, 120fr) minmax(150px, 150fr) minmax(110px, 110fr)
    minmax(130px, 130fr) minmax(135px, 135fr) minmax(179px, 179fr);
  padding: 0 8px;

  @media (min-width: ${WINDOW_WIDTH.bigDesktop}px) {
    grid-template-columns:
      minmax(200px, 200fr) minmax(250px, 250fr) minmax(216px, 216fr)
      minmax(170px, 170fr) minmax(180px, 180fr) minmax(270px, 270fr) minmax(100px, 100fr)
      minmax(160px, 160fr) minmax(140px, 140fr) minmax(174px, 174fr);
  }
`
