import React from 'react'
import { useUnit } from 'effector-react'
import styled from 'styled-components'

import { TextLLight } from '@/ui'

import {
  $companyInfo,
  $innError,
} from '../../model/private'
import { ErrorInfo } from '../parts'
import { SecondStepForm } from './SecondStepForm'

export const SecondStep = React.memo(() => {
  const info = useUnit($companyInfo)
  const error = useUnit($innError)

  return (
    <Wrapper>
      <Company>
        {info?.inn}
        {info?.name && ` · ${info.name}`}
      </Company>

      {error && <ErrorInfo {...error.data} />}
      {!error && <SecondStepForm />}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Company = styled.div`
  ${TextLLight}
`
