import { sample } from 'effector'

import { createToast } from '@/features/toast-service/model'

import {
  $isCreateContragentOpen,
  $isPreview,
  closeCreateContragent,
  openCreateContragent,
  openNewContragentPreview,
  createContragentForm,
  createContragentFx,
  $isShowSuccess,
} from './create-contragent.private'
import { $paymentDocumentId } from './private'

$isCreateContragentOpen
  .on([openCreateContragent, openNewContragentPreview], () => true)
  .reset(closeCreateContragent)

$isPreview
  .on(openNewContragentPreview, () => true)
  .reset(closeCreateContragent)

$isShowSuccess
  .on(createContragentFx.done, () => true)
  .reset(closeCreateContragent)

sample({
  clock: [openCreateContragent, openNewContragentPreview],
  target: createContragentForm.setInitialForm,
})

sample({
  clock: createContragentForm.formValidated,
  source: $paymentDocumentId,
  filter: Boolean,
  fn: (docId, form) => ({
    ...form,
    payment: docId,
  }),
  target: createContragentFx,
})

sample({
  clock: [closeCreateContragent, createContragentFx.done],
  target: createContragentForm.reset,
})

createToast({
  effect: createContragentFx,
})
