import React from 'react'
import styled from 'styled-components'

import {
  HeadXL,
  ModalLayoutV2,
  TextM,
  TextMLight,
} from '@/ui'

type Props = {
  onClose: () => void
}

export const CostCalculationModal = ({ onClose }: Props) => {
  return (
    <ModalLayoutV2
      dataTestId="cost-calculation-modal"
      closeModal={onClose}
    >
      <Wrapper>
        <Header>
          Расчёт стоимости
        </Header>

        <Block>
          <BoldText>Обозначения:</BoldText>
          <Paragraph>
            <BoldText>КТ*</BoldText>
            {' '}
            — коэфицент трансформации
          </Paragraph>
          <Paragraph>
            <BoldText>% потерь**</BoldText>
            {' '}
            — потери счётчика
          </Paragraph>
          <Paragraph>
            <BoldText>Расход</BoldText>
            {' '}
            = текущие показания – предыдущие показания
          </Paragraph>
        </Block>

        <Block>
          <Paragraph>
            Расчёт стоимости электроэнергии по счётчику:
          </Paragraph>
          <FormulaRow>
            Расчёт стоимости электроэнергии =  КТ ∙ Расход ∙ Тариф
          </FormulaRow>
        </Block>

        <Block>
          <Paragraph>
            Расчёт стоимости потерь по счётчику:
          </Paragraph>
          <FormulaRow>
            Расчёт стоимости потерь =  % потерь ∙ Расход ∙ Тариф
          </FormulaRow>
        </Block>

        <Block>
          <Paragraph>
            Итоговый расчёт затрат за потреблённую электроэнергию вычисляется по формуле:
          </Paragraph>
          <FormulaRow>
            Расчёт затрат =  Расчёт стоимости электроэнергии + Расчёт стоимости потерь
          </FormulaRow>
        </Block>

        <Block>
          <Paragraph>
            * если потребление энергии учитывается не по счётчику, то КТ отсутствует
          </Paragraph>
          <Paragraph>
            ** потери могут отсутствовать
          </Paragraph>
        </Block>
      </Wrapper>
    </ModalLayoutV2>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 660px;
  padding: 32px;
`

const Header = styled.div`
  ${HeadXL}
`

const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Paragraph = styled.p`
  ${TextMLight}
`

const BoldText = styled.span`
  ${TextM}
`

const FormulaRow = styled.div`
  ${TextM}
  display: flex;
  justify-content: center;
  text-align: center;
  white-space: pre;
`
