import React from 'react'

import { NewTextButton, NotificationItem, NotificationType } from '@/ui'

type Props = {
  isThirdParties?: boolean
  onDownload: () => void
}

const THIRD_PARTIES_TEXT = 'Приложите счёт. Распорядительное письмо будет сформировано электронно. Для проверки шаблона распорядительного письма можете его скачать'
const SMR_TEXT = 'Счёт на оплату будет сформирован автоматически. Дата счёта будет сформирована автоматически. Номер счёта укажет ваш бухгалтер при подписании в ДИАДОК. Для проверки шаблона счёта можете его скачать'

export const PaymentDocsNotification = ({ isThirdParties = false, onDownload }: Props) => {
  return (
    <NotificationItem
      body={(
        <>
          {isThirdParties ? THIRD_PARTIES_TEXT : SMR_TEXT}
          {' '}
          <NewTextButton
            label="по ссылке"
            textStyle="M"
            dataTestId="payment-template-link"
            isInline
            hasUnderline
            onClick={onDownload}
          />
          .
        </>
      )}
      type={NotificationType.Info}
    />
  )
}
