import React, { memo } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { OtherAppealAlias } from '@/dal'
import {
  HeadXL, ModalLayoutV2, NewButton, NewTextButton, TextLLight,
} from '@/ui'
import { RouterPaths } from '@/features/app/model'
import { noop } from '@/lib/noop'

import { errorAsIssueModal } from '../../model/private'

const TEST_ID_PREFIX = 'error-as-issue-modal'

export const ErrorAsIssueModal = memo(() => {
  const info = useUnit(errorAsIssueModal.$meta)

  if (!info) return null

  const { issue: { id, number } } = info
  const link = `${RouterPaths.Support}?type=${OtherAppealAlias.TechSupport}&id=${id}`

  return (
    <ModalLayoutV2
      hasCloseButton={false}
      dataTestId={TEST_ID_PREFIX}
      closeModal={noop}
    >
      <Wrapper>
        <Content>
          <Title>Ошибка отправки на согласование</Title>

          <Text>
            Обращение
            {' '}
            <NewTextButton
              label={number}
              url={link}
              textStyle="LL"
              dataTestId=""
              isInline
              isNewBlank
            />
            {' '}
            в техническую поддержку создано автоматически.
            <br />
            Вы можете задать вопрос сотруднику технической поддержки в чате обращения
          </Text>
        </Content>

        <ButtonWrapper>
          <NewButton
            label="Закрыть"
            dataTestId={`${TEST_ID_PREFIX}-close-btn`}
            isFill
            onClick={errorAsIssueModal.close}
          />
        </ButtonWrapper>
      </Wrapper>
    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  width: 700px;
  padding: 32px;
  text-align: center;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Title = styled.header`
  ${HeadXL}
`

const Text = styled.p`
  ${TextLLight}
`

const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 320px;
`
