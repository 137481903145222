import React from 'react'
import { useStoreMap, useUnit } from 'effector-react'
import styled from 'styled-components'

import {
  ActTableFolder as ActTableFolderType,
  isPIRTableFolder,
} from '@/dal'
import {
  FirstTableCell,
  SimpleLoader,
  TableCell,
} from '@/ui'

import {
  $hiddenCellGroup,
  $isTree,
  $pendingFolderIds,
  $tableTree,
  onCloseFolder,
  openFolder,
} from '../../model/private'
import { $actType } from '../../../model'
import { $searchName } from '../../model/filters.private'
import {
  CellRow, FirstCellWrapper, SplitCellWrapper, TableRowWrapper,
} from '../parts'

type Props = {
  item: ActTableFolderType
  isScrolled?: boolean
}

export const ActTableFolder = React.memo(({ item, isScrolled }: Props) => {
  const isHiddenGroup = useUnit($hiddenCellGroup)
  const isTree = useUnit($isTree)
  const actType = useUnit($actType)
  const searchName = useUnit($searchName)

  const {
    id,
    is_folder,
    article,
    name,
    note,
    number_1c,
    depth,
    has_children,
  } = item

  const showLoading = useStoreMap({
    store: $pendingFolderIds,
    keys: [id, is_folder],
    fn: (arr, [id, isFolder]) => isFolder && arr.includes(id),
  })

  const isExpanded = useStoreMap({
    store: $tableTree,
    keys: [id],
    fn: (map, [rowId]) => Boolean(map[rowId]),
  })

  const isPIRFolder = isPIRTableFolder(item)

  if (!actType) return null

  if (showLoading) {
    return (
      <TableRowWrapper
        actType={actType}
        hiddenFirstGroup={isHiddenGroup}
        as="td"
      >
        <LoaderWrapper>
          <SimpleLoader sizePx={40} />
        </LoaderWrapper>
      </TableRowWrapper>
    )
  }

  return (
    <TableRowWrapper
      actType={actType}
      hiddenFirstGroup={isHiddenGroup}
      as="td"
    >
      <FirstCellWrapper hasShadow={isScrolled}>
        <FirstTableCell
          hierarchyCode={number_1c}
          depth={depth}
          hasChildren={has_children}
          isFolder={is_folder}
          isExpanded={isExpanded}
          isTree={isTree}
          name={name}
          project=""
          hasRightDivider={isHiddenGroup}
          searchValue={searchName}
          onToggleExpand={() => (
            isExpanded ? onCloseFolder(id) : openFolder(id)
          )}
        />
      </FirstCellWrapper>

      {!isHiddenGroup && (
        <>
          <TableCell isGray isText text={note} />
          <TableCell isGray isText text={article} />
        </>
      )}

      <TableCell
        isEmpty
      />

      <TableCell isEmpty />
      <TableCell isEmpty />
      <TableCell isEmpty />

      {isPIRFolder ? (
        <>
          <TableCell isEmpty />
          <TableCell isEmpty />
          <TableCell isEmpty />
          <TableCell value={item.total_cost} align="right" />
          <TableCell value={item.period_cost} align="right" />
        </>
      ) : (
        <>
          <SplitCellWrapper>
            <TableCell isEmpty />
            <CellRow>
              <TableCell isEmpty />
              <TableCell isEmpty />
            </CellRow>
          </SplitCellWrapper>

          <SplitCellWrapper>
            <TableCell value={item.period_cost.total} align="center" />
            <CellRow>
              <TableCell value={item.period_cost.material} align="center" />
              <TableCell value={item.period_cost.work} align="center" />
            </CellRow>
          </SplitCellWrapper>
        </>
      )}
    </TableRowWrapper>
  )
})

const LoaderWrapper = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: center;
  grid-column-start: 0;
  grid-column-end: -1;
`
