import React, { useCallback } from 'react'
import styled from 'styled-components'

import { DocumentAlias, DocumentStatuses } from '@/dal'
import { DefaultButton, SelectItemButton } from '@/ui'
import { noop } from '@/lib/noop'

import { getDocumentFiles } from '../../model/private'
import { DocumentFilesModal } from '../containers'

type Props = {
  id: number
  docType: DocumentAlias
  buttonLabel?: string
  downloadLink?: string
  status?: DocumentStatuses
  onClick?: () => void
}

export const DocumentFilesButton = React.memo(({
  id,
  docType,
  buttonLabel = 'Документы для согласования',
  downloadLink,
  status,
  onClick,
}: Props) => {
  const isDraft = status === DocumentStatuses.Draft
  // TODO пускай настоится
  // 27.11.2024 21:31 а пригодилась! Нюанс! 1С багует - не отдаёт файлы. Пока откатываем.
  // Спасибо, Саша, что оставил!
  // 28.11.2024 18:02 Откатываю откат обратно, 1с вроде починила
  const hasModal = true

  const Wrapper = useCallback(({ children }) => {
    if (hasModal) {
      return <>{children}</>
    }
    if (downloadLink) {
      return (
        <a href={downloadLink} download>
          {children}
        </a>
      )
    }
    if (onClick) {
      return (
        <Button onClick={onClick}>
          {children}
        </Button>
      )
    }
    return null
  }, [hasModal, downloadLink, onClick])

  return (
    <Wrapper>
      <SelectItemButton
        text={buttonLabel}
        icon="download"
        buttonStyle="secondary"
        hasBorder={false}
        dataTestId="open-doc-files-modal-btn"
        onClick={hasModal ? () => getDocumentFiles({ id, docType }) : noop}
      />
      <DocumentFilesModal isDraft={isDraft} />
    </Wrapper>
  )
})

const Button = styled(DefaultButton)`
  width: 100%;
`
