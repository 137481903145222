import {
  useEffect, useLayoutEffect, useRef, useState,
} from 'react'

type Params = {
  value: string
  height?: number
  maxHeight?: number
  deps?: unknown[]
  isAutoGrow?: boolean
}

export const useTextareaAutoHeight = ({
  value,
  height,
  maxHeight,
  deps = [],
  isAutoGrow,
}: Params) => {
  const [autoHeight, setAutoHeight] = useState<number|null>(height ?? null)
  const inputRef = useRef<HTMLTextAreaElement>(null)

  const calcHeight = () => {
    if (!isAutoGrow || !inputRef.current) return
    const baseHeight = (height || 0)
    const newHeight = inputRef.current.scrollHeight > baseHeight
      ? inputRef.current.scrollHeight
      : baseHeight
    if (maxHeight) {
      setAutoHeight(newHeight > maxHeight ? maxHeight : newHeight)
    } else {
      setAutoHeight(newHeight)
    }
  }

  useLayoutEffect(() => {
    calcHeight()
  }, deps)

  useEffect(() => {
    if (!value) return setAutoHeight(height ?? null)
  }, [value])

  return {
    inputRef,
    autoHeight,
  }
}
