import { attach } from 'effector'
import { createForm } from 'effector-forms'

import { putPlanReqFx } from '@/dal'
import { rules } from '@/lib/form-validators'

import { d } from './domain'

export const closePlanEditPeriodModal = d.event()
export const $modalPlanEditPeriodVisible = d.store(false)

export const planEditPeriodForm = createForm({
  domain: d,
  validateOn: ['submit'],
  fields: {
    date: {
      init: '',
      rules: [rules.required()],
    },
  },
})

export const editPlanPeriodFx = attach({
  effect: putPlanReqFx,
})
