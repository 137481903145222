import { DefaultHandledError, defaultErrorHandler } from '../error-handler'
import { CheckInnNotFoundError, NeedChangeTableError, PutAvansItemError } from './errors'
import { PaymentErrorCode, PaymentErrorResponse } from './types'

type PaymentErrorHandler =
  | CheckInnNotFoundError
  | NeedChangeTableError
  | PutAvansItemError
  | DefaultHandledError

export const paymentErrorHandler = (
  error: PaymentErrorResponse | undefined,
): PaymentErrorHandler => {
  if (error && 'item' in error) {
    return new PutAvansItemError(error)
  }

  switch (error?.code) {
    case PaymentErrorCode.NotFound: {
      return new CheckInnNotFoundError(error)
    }
    case PaymentErrorCode.NeedChangeTable: {
      return new NeedChangeTableError(error)
    }
    default: {
      return defaultErrorHandler(error)
    }
  }
}
