
type Params = {
  onChange: (v: File[]) => File[]
  currValue: File[],
  newFiles: File[]
}

export const handleFileChangeHelper = ({ currValue, newFiles, onChange }: Params) => {
  const newVal = [
    ...currValue,
    ...Array.from(newFiles)
      .filter((item) => !currValue.some((cur) => cur.name === item.name)),
  ]
  onChange(newVal)
}
