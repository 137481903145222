import React, { memo } from 'react'
import styled from 'styled-components'

import {
  HeadXL, ModalLayoutV2, NewButton, TextLLight,
} from '@/ui'
import { noop } from '@/lib/noop'

import { cancelAppealModal, cancelSupportAppeal } from '../../model'
import { ModalProps } from '../../model/types'

export const CancelResponsibleAppeal = memo(({ info, isSending }: ModalProps) => {
  return (
    <ModalLayoutV2
      borderRadius={12}
      closeModal={isSending ? noop : cancelAppealModal.close}
    >
      <Wrapper>
        <Header>
          <Title>Отменить обращение</Title>
          <Text>
            Вы действительно хотите отменить обращение
            {' '}
            {info.number}
            ?
          </Text>
        </Header>

        <Buttons>
          <NewButton
            label="Отменить"
            buttonType="grey"
            isDisabled={isSending}
            dataTestId=""
            isFill
            onClick={cancelAppealModal.close}
          />
          <NewButton
            label="Отменить обращение"
            isPending={isSending}
            dataTestId=""
            isFill
            onClick={() => cancelSupportAppeal({ ...info, appealType: info.issue_type })}
          />
        </Buttons>
      </Wrapper>
    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 500px;
  padding: 32px;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Title = styled.p`
  ${HeadXL}
`

const Text = styled.p`
  ${TextLLight}
`

const Buttons = styled.div`
  display: flex;
  gap: 16px;
`
