import React from 'react'
import styled from 'styled-components'

import { handleFileChangeHelper } from '@/lib/handleFileChangeHelper'
import { handleDeleteFileHelper } from '@/lib/handleDeleteFileHelper'
import { FileDTO } from '@/dal'

import { DropArea } from './DropArea'
import { NewFileItem } from './NewFileItem'

type Props = {
  onChange: (files: File[]) => File[]
  height?: number
  maxFiles?: number
  isDisabled?: boolean
  isLoading?: boolean
  items: File[]
  uploadedFiles?: FileDTO[] | null
  markUploadedFileToDelete?: (id: FileDTO['id']) => void
  disableRemoveFile?: boolean
  dataTestId: string
  listPosition?: 'top' | 'bottom'
  showInfo?: boolean
}

export const DropAreaWithList = ({
  items,
  onChange,
  height,
  isDisabled,
  isLoading,
  maxFiles,
  dataTestId,
  disableRemoveFile,
  uploadedFiles,
  listPosition = 'bottom',
  showInfo,
  markUploadedFileToDelete,
}: Props) => {
  const handleFiles = (files: File[]) => {
    handleFileChangeHelper({
      currValue: items,
      newFiles: files,
      onChange,
    })
  }
  const handleFileDelete = (name: string) => {
    handleDeleteFileHelper({
      fileName: name,
      onChange,
      value: items,
    })
  }
  const ListComponent = (
    <List>
      {uploadedFiles?.map((file) => (
        <NewFileItem
          key={file.id}
          name={file.name}
          url={file.url}
          onDelete={!disableRemoveFile ? () => markUploadedFileToDelete?.(file.id) : undefined}
          isDense
        />
      ))}
      {items.map((file) => (
        <NewFileItem
          key={file.name}
          name={file.name}
          onDelete={!disableRemoveFile ? handleFileDelete : undefined}
          isDense
        />
      ))}
    </List>
  )
  return (
    <Wrapper>
      {listPosition === 'top' && ListComponent}
      <DropArea
        onFiles={handleFiles}
        height={height}
        maxFiles={maxFiles}
        isDisabled={isDisabled}
        isLoading={isLoading}
        dataTestId={dataTestId}
        showInfo={showInfo}
      />
      {listPosition === 'bottom' && ListComponent}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const List = styled.div`
  display: grid;
  gap: 2px 20px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
`
