import React from 'react'
import styled, { FlattenSimpleInterpolation } from 'styled-components'

import { noop } from '@/lib/noop'
import { Button } from './buttons/button'


type FooterProps = {
  submitName: string
  onClose: () => void
  onSubmit?: () => void
  disabled?: boolean
  cancelName?: string
  type?: 'button' | 'reset' | 'submit'
  wrapperStyle?: FlattenSimpleInterpolation
  buttonWrapperStyle?: FlattenSimpleInterpolation
  isLoading?: boolean
  isBlockCancelButton?: boolean
}

export const ModalFooterButtons: React.FC<FooterProps> = ({
  submitName,
  onClose,
  onSubmit,
  disabled,
  cancelName = 'Отменить',
  type = 'submit',
  wrapperStyle,
  buttonWrapperStyle,
  isLoading,
  isBlockCancelButton = false,
}) => (
  <Wrapper wrapperStyle={wrapperStyle}>
    <ButtonWrapper buttonWrapperStyle={buttonWrapperStyle}>
      <Button
        type={type}
        size="M"
        styleButton="primary"
        disabled={disabled}
        isLoading={isLoading}
        onClick={!isLoading && onSubmit ? onSubmit : noop}
        dataTestId="button-footer-submit"
      >
        {submitName}
      </Button>
    </ButtonWrapper>
    <ButtonWrapper buttonWrapperStyle={buttonWrapperStyle}>
      <Button
        onClick={onClose}
        styleButton="secondary"
        size="M"
        dataTestId="button-footer-close"
        type="reset"
        disabled={isBlockCancelButton && isLoading}
      >
        {cancelName}
      </Button>
    </ButtonWrapper>
  </Wrapper>
)

type InternalProps = {
  wrapperStyle?: FlattenSimpleInterpolation
  buttonWrapperStyle?: FlattenSimpleInterpolation
}

const Wrapper = styled.div<InternalProps>`
  display: flex;
  justify-content: space-between;
  gap: 32px;

  ${({ wrapperStyle }) => wrapperStyle}
`

const ButtonWrapper = styled.div<InternalProps>`
  flex: 1;
  box-sizing: border-box;

  ${({ buttonWrapperStyle }) => buttonWrapperStyle}
`
