import { useUnit } from 'effector-react'
import React from 'react'
import styled from 'styled-components'

import { getDocumentNameForActionByType } from '@/lib/documentResolvers'
import { HeadXL } from '@/ui'

import { $selectedDocumentType } from '../../model/private'
import { CreateDocumentContent } from './CreateDocumentContent'

export const CreateSingleDocument = React.memo(() => {
  const type = useUnit($selectedDocumentType)

  if (!type) return null

  return (
    <Wrapper>
      <Title>
        Создание
        {' '}
        {getDocumentNameForActionByType(type).toLowerCase()}
      </Title>
      <CreateDocumentContent />
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  padding: 32px;
  width: 500px;
  max-width: 100%;
`

const Title = styled.div`
  ${HeadXL}
`
