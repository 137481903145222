import { attachWrapper } from '@42px/effector-extra'
import { AxiosError, AxiosResponse } from 'axios'

import { Method, authRequestFx } from '../request'
import { defaultErrorHandler } from '../error-handler'
import {
  ContactItemType,
  CreateContactParams,
  EditContactParams,
  GetContactParams,
  PutDetailsPayload,
  PutDetailsResponse,
  RoleItemType,
} from './types'
import { AbortableRequest, Response } from '../interfaces'

export const getAllContactRolesReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({
    signal,
    companyId,
    projectId,
  }: AbortableRequest<{ companyId: number; projectId?: number }>) => ({
    method: Method.get,
    url: '/contacts/roles/',
    signal,
    query: {
      company_id: companyId,
      project: projectId,
    },
  }),
  mapResult: ({ result }: {result: AxiosResponse<RoleItemType[]>}) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const getContactsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ company, project, signal }: AbortableRequest<GetContactParams>) => ({
    method: Method.get,
    url: '/contacts/',
    query: {
      company,
      project,
    },
    signal,
  }),
  mapResult: ({ result }: {result: AxiosResponse<ContactItemType[]>}) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const createContactReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({
    company,
    ...contact
  }: CreateContactParams) => ({
    method: Method.post,
    url: '/contacts/',
    query: { company },
    body: {
      ...contact,
      projects: contact.projects.flatMap((project) => project
        .roles.map((roleId) => ({ id: project.id, role: roleId }))),
    },
  }),
  mapResult: ({ result }: {result: AxiosResponse<ContactItemType>}) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const editContactReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, ...body }: EditContactParams) => ({
    method: Method.put,
    url: `/contacts/${id}/`,
    body: {
      ...body,
      projects: body.projects.flatMap((project) => project
        .roles.map((roleId) => ({ id: project.id, role: roleId }))),
    },
  }),
  mapResult: ({ result }: {result: AxiosResponse<ContactItemType>}) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const deleteContactReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (id: ContactItemType['id']) => ({
    method: Method.delete,
    url: `/contacts/${id}/`,
  }),
  mapResult: ({ result }: {result: AxiosResponse<void>}) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const putCompanyDetailsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (data: PutDetailsPayload | null) => ({
    method: Method.put,
    url: '/company/',
    body: data?.params,
    query: {
      id: data?.id,
    },
  }),
  mapResult: ({ result }: Response<PutDetailsResponse>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})
