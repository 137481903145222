import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'
import { PaymentOrderItem } from '../parts'
import { $paymentInfo } from '../../../model/private'

const $orders = $paymentInfo.map((payment) => payment?.orders ?? [])

export const PaymentOrders = React.memo(() => {
  const list = useUnit($orders)

  if (!list.length) return null

  return (
    <Wrapper>
      {list.map((item) => (
        <PaymentOrderItem {...item} key={item.number} />
      ))}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

`
