import { sample, split } from 'effector'

import { $userAuthorised } from '@/features/login/model'

import { ArticleItem, ArticleType } from '@/dal'
import { createToast } from '@/features/toast-service/model'
import {
  $articlesList,
  getMoreArticlesFx,
  getArticlesFx,
  openArticle,
  commentsModel,
  $openedArticleId,
  closeArticle,
  reactionButtonClicked,
  sendArticleReactionFx,
  onLoadMoreArticles,
  incrementArticleComment,
  checkNewArticleFx,
  startPolling,
  stopPolling,
  updateReactionsInfo,
  readArticleButtonClicked,
  updateArticleById,
  sendArticleForm,
  sendArticleToEmailFx,
  $openedArticle,
  readArticleFx,
  clearOpenedId,
  ArticleGate,
  loadNewArticlesFx,
  $articleTotal,
  $isVisibleShareModal,
  openShareModal,
  closeShareModal,
  $isArticlesModalOpen,
  openArticlesModal,
  closeArticlesModal,
  $filters,
  $tenActualArticles,
  saveActualTenArticles,
  $itemsToLoad,
  loadArticlesFx,
  clearFilter,
} from './private'
import { $newArticleCount } from './public'
import { DISLIKE_REACTION, LIKE_REACTION } from './const'
import { mapFilters } from './mapper'

$articlesList
  .on(getArticlesFx.doneData, (_, { data }) => data)
  .on(incrementArticleComment, (articles, id) => articles?.map((item) => (
    item.id === id ? { ...item, comments_count: item.comments_count + 1 } : item)),
  )
  .on(
    getMoreArticlesFx.doneData,
    (articles, { data }) => (articles ? [...articles, ...data] : data),
  )
  .on(updateReactionsInfo, (list, { id, reactions }) => (
    list?.map((item) => (
      item.id === id ? ({ ...item, reactions }) : item),
    ) ?? null
  ))
  .on(updateArticleById, (list, newItem) => (
    list?.map((item) => (item.id === newItem.id ? newItem : item)) ?? null
  ))
  .on(loadNewArticlesFx.doneData, (list, { data }) => {
    if (!list) return list
    return [...data, ...list]
  })
  .reset(ArticleGate.close)

$tenActualArticles
  .on(saveActualTenArticles, (_, ids) => ids)
  .reset(ArticleGate.close)

$articleTotal
  .on([
    loadNewArticlesFx.doneData,
    getMoreArticlesFx.doneData,
    getArticlesFx.doneData,
  ], (_, { total }) => total)
  .reset(ArticleGate.close)

$newArticleCount
  .on(checkNewArticleFx.doneData, (
    _, { all_unattended_publications },
  ) => all_unattended_publications.length)
  .on(readArticleFx.done, (count) => (count && count > 1 ? count - 1 : 0))

$openedArticleId
  .on(openArticle, (_, id) => id)
  .reset(clearOpenedId, ArticleGate.close)

$isVisibleShareModal
  .on(openShareModal, () => true)
  .reset(closeShareModal)

$isArticlesModalOpen
  .on(openArticlesModal, () => true)
  .reset(closeArticlesModal)

$itemsToLoad
  .on(loadArticlesFx, (_, { limit }) => limit)
  .reset(loadArticlesFx.finally)

sample({
  clock: [ArticleGate.open, $filters.updates],
  source: $filters,
  fn: mapFilters,
  target: getArticlesFx,
})

sample({
  clock: onLoadMoreArticles,
  source: $filters,
  fn: mapFilters,
  target: getMoreArticlesFx,
})

sample({
  clock: closeArticlesModal,
  target: [clearFilter, closeArticle],
})

sample({
  clock: readArticleFx.doneData,
  target: updateArticleById,
})

sample({
  clock: $userAuthorised,
  filter: $userAuthorised,
  target: checkNewArticleFx,
})

sample({
  clock: checkNewArticleFx.doneData,
  source: {
    isOpen: ArticleGate.status,
    articles: $articlesList,
    prev: $tenActualArticles,
  },
  filter: ({ isOpen, articles, prev }, { last_ten_publications: ids }) => {
    if (!isOpen || articles === null) return false
    let isFlag = false
    ids.forEach((id) => {
      const isPrev = prev.some((currId) => currId === id)
      if (!isPrev) {
        const item = articles?.find((item) => item.id === id)
        if (!item) isFlag = true
      }
    })
    return isFlag
  },
  fn: ({ articles }, { last_ten_publications: ids }) => {
    let articlesCountToLoad = 0
    ids?.forEach((id) => {
      const item = articles?.find((item) => item.id === id)
      if (!item) articlesCountToLoad++
    })
    return { limit: articlesCountToLoad, offset: 0 }
  },
  target: loadNewArticlesFx,
})
// Важно делать это после sample выше
sample({
  clock: checkNewArticleFx.doneData,
  fn: ({ last_ten_publications }) => last_ten_publications,
  target: saveActualTenArticles,
})

split({
  source: $userAuthorised,
  match: {
    startPolling: (isAuth) => Boolean(isAuth),
    stopPolling: (isAuth) => !isAuth,
  },
  cases: {
    startPolling,
    stopPolling,
  },
})

sample({
  clock: readArticleButtonClicked,
  source: $openedArticle,
  filter: (
    article: ArticleItem | null,
  ): article is ArticleItem => Boolean(
    article
    && !article.is_read
    && article.publication_type === ArticleType.Important,
  ),
  fn: (item) => item?.id,
  target: readArticleFx,
})

split({
  source: sample({
    clock: closeArticle,
    source: $openedArticle,
    filter: Boolean,
  }),
  match: {
    readArticle: (article) => !article.is_read && article.publication_type === ArticleType.Regular,
  },
  cases: {
    readArticle: [
      readArticleFx.prepend((article: ArticleItem) => article.id),
      clearOpenedId,
    ],
    __: clearOpenedId,
  },
})

sample({
  clock: closeArticle,
  target: commentsModel.clearId,
})

sample({
  clock: openArticle,
  target: commentsModel.setId,
})

sample({
  clock: sendArticleToEmailFx.done,
  target: closeShareModal,
})

sample({
  clock: closeShareModal,
  target: sendArticleForm.reset,
})

sample({
  clock: sendArticleForm.formValidated,
  source: $openedArticleId,
  filter: Boolean,
  fn: (id, form) => ({
    id,
    email: form.email,
  }),
  target: sendArticleToEmailFx,
})

sample({
  clock: commentsModel.newCommentAdded,
  source: $openedArticleId,
  filter: (id: number | null): id is number => Boolean(id),
  target: incrementArticleComment,
})

sample({
  clock: reactionButtonClicked,
  source: $openedArticleId,
  filter: (id: number | null): id is number => Boolean(id),
  fn: (id, isConfirm) => ({
    targetId: id,
    reaction: isConfirm ? LIKE_REACTION : DISLIKE_REACTION,
  }),
  target: sendArticleReactionFx,
})

sample({
  clock: sendArticleReactionFx.done,
  fn: ({ params, result }) => ({ id: params.targetId, reactions: result }),
  target: updateReactionsInfo,
})

createToast({
  effect: sendArticleReactionFx,
})

createToast({
  effect: sendArticleToEmailFx,
  doneText: 'Новость отправлена',
})
