import { sample } from 'effector'

import {
  ContractItem,
  ContractVisibility,
  DocumentAlias,
  DocumentStatuses,
  VisasDocType,
} from '@/dal'
import { onAdditionalRequestUpdated, openAdditionalRequestModal } from '@/features/additional-request/model'
import { openCreateDocumentModal, openCreateDocumentWithSelect } from '@/features/document-manager/model'
import { showToast } from '@/features/toast-service/model'
import { NotificationType } from '@/ui'

import {
  $contract,
  $isClosurePercentageUpdated,
  attachmentsModel,
  changeVisibilityFx,
  getContractByIdFx,
  onCloseButton,
  onGetRetentionButton,
  onOpenAdditionalModal,
  onOpenCreateDocumentModal,
  onRequestAdvans,
  onUpdateClosurePercentage,
  openAttachmentsModal,
  sendFilesFx,
  toggleVisibility,
  updateAttachments,
  updateClosurePercentageFx,
  updateContract,
} from './private'
import {
  $activeTab,
  ContractInfoGate,
  changeContractTab,
} from './public'

import { onCloseContract, onGetRetention, onUpdatedContractItem } from '../../close-contract/model'

$contract
  .on([
    changeVisibilityFx.doneData,
    getContractByIdFx.doneData,
    updateContract,
  ], (_, contract) => contract)
  .on(updateAttachments, (info, attachments) => (!info ? null : {
    ...info,
    attachments,
  }))
  .on(updateClosurePercentageFx.done, (info, { params, result }) => (
    !info ? null : params !== info.id
      ? info : {
        ...info,
        closure_percentage: result,
      }
  ))
  .reset(ContractInfoGate.close)

$activeTab
  .on(changeContractTab, (_, tab) => tab)
  .reset(ContractInfoGate.close)

$isClosurePercentageUpdated
  .on(updateClosurePercentageFx.done, () => true)
  .reset(ContractInfoGate.close)

sample({
  clock: [ContractInfoGate.state, onAdditionalRequestUpdated],
  source: ContractInfoGate.state,
  filter: ({ id }) => Boolean(id),
  target: getContractByIdFx,
})

sample({
  clock: toggleVisibility,
  source: $contract,
  filter: Boolean,
  fn: (contract) => ({
    visibility: contract.visibility === ContractVisibility.hidden
      ? ContractVisibility.visible : ContractVisibility.hidden,
    id: contract.id,
  }),
  target: changeVisibilityFx,
})

sample({
  clock: onOpenAdditionalModal,
  source: {
    isOpen: ContractInfoGate.status,
    contract: $contract,
  },
  filter: ({ isOpen, contract }) => Boolean(isOpen && contract),
  fn: ({ contract }) => ({
    docType: DocumentAlias.CONTRACT as VisasDocType,
    docId: contract?.id as ContractItem['id'],
    status: contract?.status as DocumentStatuses,
  }),
  target: openAdditionalRequestModal,
})

sample({
  clock: openAttachmentsModal,
  source: $contract,
  filter: (contract) => Boolean(contract?.id),
  fn: (contract) => ({ id: contract?.id as ContractItem['id'] }),
  target: attachmentsModel.open,
})

sample({
  clock: onCloseButton,
  source: $contract,
  filter: Boolean,
  fn: (contract, needDocs) => ({ id: contract.id, needDocs }),
  target: onCloseContract,
})

sample({
  clock: onGetRetentionButton,
  source: $contract,
  filter: Boolean,
  fn: (contract) => contract.id,
  target: onGetRetention,
})

sample({
  clock: onUpdatedContractItem,
  source: $contract,
  filter: (contract, updatedContract) => contract?.id === updatedContract.id,
  fn: (_, updatedContract) => updatedContract,
  target: updateContract,
})

sample({
  clock: onOpenCreateDocumentModal,
  source: $contract,
  filter: Boolean,
  target: openCreateDocumentWithSelect,
})

sample({
  clock: onRequestAdvans,
  source: $contract,
  filter: Boolean,
  fn: (contract) => ({
    contractName: contract.name,
    contractId: contract.id,
    contractDirection: contract.direction,
    type: DocumentAlias.PAYMENT,
  }),
  target: openCreateDocumentModal,
})

sample({
  clock: sendFilesFx.doneData,
  target: updateAttachments,
})

sample({
  clock: onUpdateClosurePercentage,
  target: updateClosurePercentageFx,
})

sample({
  clock: updateClosurePercentageFx.done,
  source: $contract,
  filter: Boolean,
  fn: (contract) => ({
    content: `Данные по ${contract.name} успешно обновлены`,
    icon: NotificationType.Success,
  }),
  target: showToast,
})
