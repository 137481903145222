import React from 'react'
import { useUnit } from 'effector-react'

import { NotificationType, SelectItemButton } from '@/ui'
import { createAttachmentsModalView } from '@/features/factories/attach-modal'

import {
  $canSendFiles,
  $documentAttachmentsCount,
  attachmentsModel,
  onOpenAttachmentsModal,
} from '../../../model/info.private'
import { $isActionPending } from '../../../model/header.private'

const AttachmentsModalView = createAttachmentsModalView(attachmentsModel)

export const ActAttachments = React.memo(() => {
  const [count, canSendFiles, isActionPending] = useUnit([
    $documentAttachmentsCount,
    $canSendFiles,
    $isActionPending,
  ])

  const notificationProps = {
    type: NotificationType.Warning,
    title: 'Внимание!',
    body: 'Не вкладывайте сюда документы, которые вы скачали из раздела «Документы для согласования». Наша система сама сформирует пакет документов и отправит их в Диадок.',
  }

  return (
    <>
      <SelectItemButton
        text="Мои вложения"
        additionalText={String(count || 0)}
        icon={canSendFiles ? 'plusWithCircle' : 'arrowRightM'}
        buttonStyle={canSendFiles ? 'secondary' : 'primary'}
        hasBorder={false}
        dataTestId="act-open-attachments"
        isDisabled={isActionPending}
        onClick={onOpenAttachmentsModal}
      />
      <AttachmentsModalView notificationProps={notificationProps} />
    </>
  )
})
