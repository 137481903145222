import React from 'react'
import { useGate, useUnit } from 'effector-react'
import { useRouteMatch } from 'react-router-dom'
import styled, { css } from 'styled-components'
import Scrollbars from 'react-custom-scrollbars-2'

import { DocumentAlias, EstimateDocId } from '@/dal'
import {
  $documentInfo,
  $isDocumentPending,
  ApproveDocumentModal,
  CommercialOffer,
  ErrorNotification,
  EstimateAttachments,
  EstimateCorrectionGate,
  EstimateHeader,
  EstimateInfo,
  EstimateTable,
  SendToApproveModal,
  SummaryTable,
  RejectApprovingModal,
  KorDocTab,
  $kordocExclusiveBlock,
} from '@/features/estimate-correction'
import { $activeTabId } from '@/features/estimate-correction/estimate-header'
import { SurveyModal } from '@/features/survey'
import { DocumentFilesButton } from '@/features/document-files'
import { downloadApprovingDocuments } from '@/features/estimate-correction/estimate-info/model'
import {
  ContentContainer,
  DocumentBlockingOverlay,
  LayoutGrid,
  Loader,
  LoaderOverlay,
  RejectReasonNotification,
  WINDOW_WIDTH,
} from '@/ui'
import { useBlockNestedInputs } from '@/lib/hooks/useBlockNestedInputs'

export const EstimateCorrectionPage = () => {
  const router = useRouteMatch<{id: string }>()
  const scrollbarsRef = React.useRef<Scrollbars>(null)
  const tabsRef = React.useRef<HTMLDivElement>(null)

  const isDocumentPending = useUnit($isDocumentPending)
  const info = useUnit($documentInfo)
  const activeTab = useUnit($activeTabId)
  const exclusiveBlock = useUnit($kordocExclusiveBlock)

  const id = parseInt(router.params.id, 10) as EstimateDocId

  useGate(EstimateCorrectionGate, id)
  useBlockNestedInputs(tabsRef, Boolean(exclusiveBlock?.is_blocked))

  if (!info && isDocumentPending) return <Loader />

  return (
    <>
      <Scrollbars ref={scrollbarsRef}>
        <ContentContainer isAutoHeight>
          <Wrapper>
            <Content>
              <EstimateHeader />
              <RejectReasonNotification rejectReason={info?.cancel_comment} />
              <CommercialOffer />
              <ErrorNotification />

              <TabsWrapper ref={tabsRef}>
                <Tab isActive={activeTab === KorDocTab.Info}>
                  <LayoutGrid>
                    <LeftCol>
                      <EstimateInfo />
                      <ButtonRow>
                        <EstimateAttachments />
                        <DocumentFilesButton
                          id={id}
                          docType={DocumentAlias.KOR_DOC}
                          status={info?.status}
                          onClick={downloadApprovingDocuments}
                        />
                      </ButtonRow>
                    </LeftCol>

                    <RightCol>
                      <SummaryTable />
                    </RightCol>
                  </LayoutGrid>
                </Tab>

                <Tab isActive={activeTab === KorDocTab.Works}>
                  <EstimateTable scrollRef={scrollbarsRef} />
                </Tab>

                {exclusiveBlock && (
                  <DocumentBlockingOverlay
                    description={exclusiveBlock.description}
                    isShown={exclusiveBlock.is_blocked}
                  />
                )}
              </TabsWrapper>
            </Content>
          </Wrapper>
        </ContentContainer>
      </Scrollbars>

      <SendToApproveModal />
      <RejectApprovingModal />
      <ApproveDocumentModal />
      <SurveyModal />
      <LoaderOverlay isShown={isDocumentPending} position="fixed" />
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
`

const TabsWrapper = styled.div`
  position: relative;
`

const Tab = styled.div<{ isActive: boolean }>`
  flex-grow: 1;

  ${({ isActive }) => !isActive && css`
    display: none;
  `}
`

const Content = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
`

const LeftCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  grid-column: span 5;
`

const ButtonRow = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: ${WINDOW_WIDTH.desktop - 1}px) {
    flex-direction: column;
  }

  @media (min-width: ${WINDOW_WIDTH.desktop}px) {
    & > * {
      flex: 1 0;
    }
  }
`

const RightCol = styled.div`
  grid-column: span 7;
`
