import { useUnit } from 'effector-react'
import React from 'react'
import styled from 'styled-components'

import { HeadXL } from '@/ui'

import { $isCreateFolder } from '../../../model/create-table-item.private'

export const ModalTitle = React.memo(() => {
  const isFolder = useUnit($isCreateFolder)

  return (
    <Title>
      {isFolder ? 'Добавление новой папки' : 'Добавление новой строки'}
    </Title>
  )
})

const Title = styled.div`
  ${HeadXL}
  padding: 0 32px 16px;
`
