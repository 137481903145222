import React from 'react'
import styled from 'styled-components'

import { formatDateString, formatTimeString } from '@/lib/date'

import { InlineDot } from './InlineDot'

type Props = {
  date: string | Date
}

export const FormatFullDate = ({ date }: Props) => (
  <Wrapper>
    {formatDateString(date)}
    {' '}
    <InlineDot size={1} />
    {' '}
    {formatTimeString(date)}
  </Wrapper>
)

const Wrapper = styled.span`
  display: inline-flex;
  align-items: center;
`
