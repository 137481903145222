import React, { memo, useMemo } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { Icon2, NewTextButton, TextMLight } from '@/ui'

import { $breadcrumbs, setOpenedKvrId } from '../../model/private'

export const CatalogBreadcrumbs = memo(() => {
  const breadcrumbs = useUnit($breadcrumbs)

  const arrowIcon = useMemo(() => (
    <Icon2 icon="arrowRightM" color="accent100" />
  ), [])

  return (
    <Wrapper>
      {breadcrumbs.map(({ id, name }, i) => (
        <Breadcrumb key={id} isActive={i === breadcrumbs.length - 1}>
          {Boolean(i) && arrowIcon}

          {i < breadcrumbs.length - 1 ? (
            <NewTextButton
              label={name}
              textStyle="ML"
              dataTestId=""
              hasUnderline
              onClick={() => setOpenedKvrId(id)}
            />
          ) : (
            <ActiveItem>
              {name}
            </ActiveItem>
          )}
        </Breadcrumb>
      ))}

      {breadcrumbs.length === 1 && arrowIcon}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`

const Breadcrumb = styled.p<{ isActive: boolean }>`
  display: flex;
  gap: 4px;
`

const ActiveItem = styled.p`
  ${TextMLight}
`
