import { sample } from 'effector'

import {
  clearAccessToken,
  loadAccessTokenFx,
  saveAccessTokenFx,
} from '@/dal/auth'
import { requestFx, UNAUTHORIZED_STATUS_CODE } from '@/dal/request'
import {
  pushNavigate,
  replaceNavigate,
  RouterPaths,
} from '@/features/app/model'

import { createToast } from '@/features/toast-service/model'

import {
  $credentialsError,
  LoginGate,
  clearAppDataFx,
  loginForm,
  loginFx,
  resetCredentialError,
  resetRedirectPath,
  setCredentialError,
} from './private'
import {
  $authorizedUserData,
  $redirectPathAfterLogin,
  companyDetailsChanged,
  fetchAuthDataFx,
  logout,
  logoutByUser,
  putUserInfoFx,
  setRedirectPathAfterLogin,
  tokenApply,
} from './public'

$authorizedUserData
  .on(fetchAuthDataFx.doneData, (_, user) => user)
  .on(putUserInfoFx.doneData, (data, newUserInfo) => {
    if (!data) return null
    return {
      ...data,
      ...newUserInfo,
    }
  })
  .on(companyDetailsChanged, (data, company) => {
    if (!data) return null
    const companyList = data?.company?.map((item) => (item.id === company.id ? company : item))
    return companyList ? { ...data, company: companyList } : data
  })
  .reset(logout)

$credentialsError
  .on(setCredentialError, () => true)
  .reset(resetCredentialError)

$redirectPathAfterLogin
  .on(setRedirectPathAfterLogin, (_, route) => route)
  .reset(resetRedirectPath)

/*
  login flow
*/
sample({
  clock: loginForm.formValidated,
  target: loginFx,
})

sample({
  clock: loginFx.done,
  source: $redirectPathAfterLogin,
  fn: (path) => path ?? RouterPaths.Home,
  target: [replaceNavigate, resetRedirectPath],
})

sample({
  clock: loginFx.doneData,
  fn: ({ token }) => token,
  target: saveAccessTokenFx,
})

sample({
  clock: tokenApply,
  target: saveAccessTokenFx,
})

sample({
  clock: tokenApply,
  fn: () => RouterPaths.Home,
  target: pushNavigate,
})

createToast({
  effect: loginFx,
})

sample({
  clock: loginFx.failData,
  target: setCredentialError,
})

/*
  logout flow
*/

sample({
  clock: logoutByUser,
  fn: () => RouterPaths.Login,
  target: [logout, pushNavigate],
})

sample({
  clock: logout,
  target: [clearAccessToken, clearAppDataFx],
})

sample({
  clock: loginForm.$values,
  target: resetCredentialError,
})

/*
  get userdata
*/
sample({
  clock: loadAccessTokenFx.doneData,
  filter: Boolean,
  target: fetchAuthDataFx,
})

sample({
  clock: saveAccessTokenFx.done,
  target: fetchAuthDataFx,
})

sample({
  clock: requestFx.fail,
  filter: ({ error }) => error.request?.status === UNAUTHORIZED_STATUS_CODE,
  target: logout,
})

sample({
  clock: LoginGate.close,
  target: loginForm.reset,
})

clearAppDataFx.use(() => localStorage.clear())

createToast({
  effect: putUserInfoFx,
  doneText: 'Данные сохранены',
})
