import React from 'react'
import styled from 'styled-components'

import {
  CellTextArea, Icon2, palette, TextMLight,
} from '@/ui'
import { getNoun } from '@/lib/text-noun'
import { noop } from '@/lib/noop'

type Props = {
  fileCount: number
  commentValue: string
  isDisabled: boolean
  onChangeComment: (val: string) => void
  onOpenAttachments: () => void
  onToggle: (val: boolean) => void
}

export const CommentAndFilesCell = ({
  fileCount, isDisabled, commentValue, onChangeComment, onOpenAttachments, onToggle,
}: Props) => {
  return (
    <Wrapper>
      <CellTextArea
        value={commentValue}
        maxHeight={350}
        popupWidth={150}
        disabled={isDisabled}
        placeholder="Комментарий"
        maxLines={2}
        dataTestId=""
        autoGrow
        onChange={onChangeComment}
        onToggle={isDisabled ? noop : onToggle}
      />

      <FileRow onClick={onOpenAttachments}>
        <p>
          {`${fileCount} ${getNoun(fileCount, 'файл', 'файла', 'файлов')}`}
        </p>
        <Icon2 icon="attach" />
      </FileRow>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  &:not(:last-child) {
    border-right: 1px solid ${palette.grey30};
  }
`

const FileRow = styled.div`
  ${TextMLight}
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 0 12px 12px;
  color: ${palette.accent100};
  cursor: pointer;
`
