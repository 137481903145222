import React, { FormEvent } from 'react'
import { useUnit } from 'effector-react'
import styled from 'styled-components'
import Scrollbars from 'react-custom-scrollbars-2'
import { useForm } from 'effector-forms'

import {
  HeadM,
  NewButton,
  NewCheckbox,
  NewDateInput,
  TextL,
  TextM,
} from '@/ui'
import { getDayJsObject } from '@/lib/date'
import { CreateFiltersViewParams } from '../../types'

export const createAppealsFilterView = ({
  $filterItems: $items,
  filterForm,
  $isPending,
  refreshFilters,
  filtersLabel,
}: CreateFiltersViewParams) => React.memo(() => {
  const items = useUnit($items)
  const { fields, submit } = useForm(filterForm)
  const isPending = useUnit($isPending)

  const handleSubmit = (e: FormEvent) => {
    if (isPending) return
    e.preventDefault()
    submit()
  }

  const handleCheckboxChange = (id: string | number) => {
    if (fields.types.value.includes(id)) {
      fields.types.onChange(fields.types.value.filter((val) => val !== id))
    } else {
      fields.types.onChange([...fields.types.value, id])
    }
  }

  const dateForFromInput = React.useMemo(() => ({
    to: fields.to.value ? getDayJsObject(fields.to.value).toDate() : undefined,
  }), [fields.to.value])

  const dateForToInput = React.useMemo(() => ({
    from: fields.from.value ? getDayJsObject(fields.from.value).toDate() : undefined,
  }), [fields.from.value])

  return (
    <Wrapper onSubmit={handleSubmit}>
      <Title>
        Фильтры
      </Title>

      <Scrollbars autoHeight autoHeightMax={330}>
        <ScrollContentWrapper>
          <ColWrapper>
            <FilterText>
              Период
            </FilterText>
            <DateInputWrapper>
              <InputItem>
                <InputLabel>c</InputLabel>
                <NewDateInput
                  disabled={isPending}
                  dataTestId="from-filter-date"
                  inputValue={fields.from.value}
                  onChange={fields.from.onChange}
                  activeDateRange={dateForFromInput}
                />
              </InputItem>
              <InputItem>
                <InputLabel>по</InputLabel>
                <NewDateInput
                  disabled={isPending}
                  dataTestId="to-filter-date"
                  inputValue={fields.to.value}
                  activeDateRange={dateForToInput}
                  onChange={fields.to.onChange}
                />
              </InputItem>
            </DateInputWrapper>
          </ColWrapper>

          <ColWrapper>
            <FilterText>
              {filtersLabel}
            </FilterText>

            <ItemsWrapper>
              {items.map((item) => (
                <NewCheckbox
                  key={item.alias}
                  onChange={handleCheckboxChange}
                  disabled={isPending}
                  label={item.name}
                  value={item.alias}
                  isChecked={fields.types.value.includes(item.alias)}
                  checkPosition="left"
                />
              ))}
            </ItemsWrapper>
          </ColWrapper>

        </ScrollContentWrapper>
      </Scrollbars>


      <Buttons>
        <NewButton
          dataTestId="accept-filters"
          label="Применить"
          buttonType="primary"
          isPending={isPending}
          type="submit"
        />
        <NewButton
          dataTestId="refresh-filters"
          label="Сбросить"
          type="button"
          onClick={refreshFilters}
          buttonType="line-grey"
          prefixIcon="refresh"
        />
      </Buttons>
    </Wrapper>
  )
})

const Wrapper = styled.form`
  width: 100%;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  ${HeadM}
  padding: 0 24px;
  margin-bottom: 28px;
`

const ScrollContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  gap: 28px;
`

const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
`

const DateInputWrapper = styled.div`
  display: flex;
  gap: 16px;
`

const InputItem = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

const InputLabel = styled.div`
  ${TextL}
`

const FilterText = styled.p`
  ${TextM}
`

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Buttons = styled.div`
  padding: 20px 24px;
  display: flex;
  gap: 16px;
`
