import { sample } from 'effector'

import { createToast } from '@/features/toast-service/model'

import { cancelAppealModal, cancelSupportAppeal, onAppealCanceled } from './public'
import { cancelSupportAppealFx } from './private'

sample({
  clock: cancelSupportAppeal,
  target: cancelSupportAppealFx,
})

sample({
  clock: cancelSupportAppealFx.doneData,
  target: [cancelAppealModal.close, onAppealCanceled],
})

createToast({
  effect: cancelSupportAppealFx,
  doneText: ({ params }) => `Обращение ${params.number} отменено`,
})
