import { combine } from 'effector'

import { subSystem } from './const'
import { d } from './domain'
import { SubSystemId } from './type'

export const appInit = d.event()
export const $appLoaded = d.store(false)

export const $currentRoute = d.store<string | null>(null)
export const setCurrentRoute = d.event<string>()

export const $previousRoute = d.store<string | null>(null)
export const setPreviousRoute = d.event<string>()

export const pushNavigate = d.event<string>()
export const goBackNavigate = d.event<void>()
export const replaceNavigate = d.event<string>()

export const $currentSubsystemId = d.store<SubSystemId>(subSystem[0].subsystemId)
export const resetCurrentSubsystem = d.event()
export const setCurrentSubsystem = d.event<SubSystemId>()

export const $currentSystemAlias = combine($currentSubsystemId, (id) => {
  const item = Object.values(subSystem).find((val) => val.subsystemId === id)
  return item?.alias ?? null
})
