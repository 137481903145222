import React from 'react'
import styled from 'styled-components'
import Scrollbars from 'react-custom-scrollbars-2'

import {
  AcceptAgreedContractor,
  CancelAgreedContractor,
  PlanEditPeriodModal,
  PlanInfo,
  Works,
} from '@/features/funding-schedule/plan-page'
import { ContentContainer } from '@/ui'

export const PlanEditPage = () => (
  <>
    <Scrollbars>
      <ContentContainer>
        <Wrapper>
          <ContentWrapper>
            <PlanInfo />
            <Works />
          </ContentWrapper>
        </Wrapper>
      </ContentContainer>
    </Scrollbars>

    <PlanEditPeriodModal />

    <AcceptAgreedContractor />
    <CancelAgreedContractor />
  </>
)

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

const ContentWrapper = styled.div`
  gap: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
`
