import React, { memo, useMemo } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { MorphologyType } from '@/dal'
import { NewDropDownField } from '@/ui'
import { $canEdit, MorphologyRow as MorphologyRowType } from '@/features/estimate-lot/model'
import { $isActionPending } from '@/features/estimate-lot/model/header.private'
import {
  $morphologyCatalog,
  $morphologyRows,
  $selectedMorphology,
  changeMorphologyRow,
} from '@/features/estimate-lot/model/morphology.private'

type Props = MorphologyRowType

export const MorphologyRow = memo((row: Props) => {
  const [catalog, rows, selectedMorphology, canEdit, isPending] = useUnit([
    $morphologyCatalog,
    $morphologyRows,
    $selectedMorphology,
    $canEdit,
    $isActionPending,
  ])

  const { object, building, section } = row

  const objectOptions = useMemo(() => {
    const available = catalog.construction_objects.filter(({ id }) => {
      if (id === object) return true
      const hasObject = selectedMorphology.some((item) => item.type === 'object' && item.id === id)
      const hasFreeBuildings = catalog.buildings.some((building) => (
        building.construction_object === id
          && !rows.some((item) => item.building === building.id)
      ),
      )
      return !hasObject && hasFreeBuildings
    })
    return available.map(({ id, name }) => ({ id, label: name }))
  }, [rows, object, catalog, selectedMorphology])

  const buildingOptions = useMemo(() => {
    const available = catalog.buildings.filter(({ id, construction_object }) => (
      construction_object === object
        && !rows.some((item) => item.building === id && item.building !== building)),
    )
    return available.map(({ id, name }) => ({ id, label: name }))
  }, [rows, object, building, catalog])

  const sectionOptions = useMemo(() => {
    const available = catalog.construction_section.filter((item) => item.building === building)
    return available.map(({ id, name }) => ({ id, label: name }))
  }, [rows, building, catalog])

  const isDisabled = !canEdit || isPending

  return (
    <Wrapper>
      <NewDropDownField
        label="Объект"
        placeholder="Выберите объект"
        options={objectOptions}
        selectedId={object}
        size="L"
        isDisabled={isDisabled}
        dataTestId="estimate-lot-morphology-object-select"
        required
        hasClear
        onOptionClick={(value) => changeMorphologyRow({ row, value, type: MorphologyType.Object })}
      />
      <NewDropDownField
        label="Корпус"
        placeholder="Выберите корпус"
        options={buildingOptions}
        selectedId={building}
        size="L"
        isDisabled={isDisabled || !object || !buildingOptions.length}
        dataTestId="estimate-lot-morphology-building-select"
        hasClear
        onOptionClick={(value) => (
          changeMorphologyRow({ row, value, type: MorphologyType.Building })
        )}
      />
      <NewDropDownField
        label="Секция/и"
        placeholder="Выберите секцию"
        options={sectionOptions}
        selectedId={section}
        size="L"
        isDisabled={isDisabled || !building || !sectionOptions.length}
        dataTestId="estimate-lot-morphology-section-select"
        hasClear
        isMultiple
        onOptionClick={(value) => (
          changeMorphologyRow({ row, value, type: MorphologyType.Section })
        )}
      />
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
`
