import React from 'react'
import styled from 'styled-components'

import { Icon } from './Icon'
import { IconButton } from './buttons/iconButton'

type Props = {
  fileName: string
  onRemove?: (fileName: string) => void
}

export const UploadedFileItem = ({ fileName, onRemove }: Props) => (
  <ItemWrapper>
    <IconWrapper>
      <Icon icon="pdf-file-icon" />
    </IconWrapper>
    <FileName>{fileName}</FileName>
    {onRemove && (
      <IconButton
        onClick={() => onRemove(fileName)}
        dataTestId={`remove-uploaded-${fileName}`}
        icon="fixed-close"
        iconSize={14}
        iconColor="gray9"
        size={14}
      />
    )}
  </ItemWrapper>
)

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 5px;
`

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
`

const FileName = styled.div`
  align-self: flex-end;
  font-weight: 300;
  font-size: 18px;
  margin: 0 5px;
  color: initial;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
