import { css } from 'styled-components'

import { palette } from '@/ui'

export const WrapperStyle = css`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 15px 12px 15px 20px;
  border: 1px solid ${palette.grey40};
  border-radius: 12px;
  transition: background-color 0.15s linear;

  &:hover {
    background-color: ${palette.grey20};
  }
`
