import { useUnit } from 'effector-react'
import React from 'react'
import { TableVirtuoso, TableVirtuosoHandle } from 'react-virtuoso'

import { EstimateTableRow } from '@/dal'
import { useCheckScrolled } from '@/lib/hooks'

import { $flatItems } from '../../../model/private'
import { TableItemContainer } from './TableItemContainer'
import { TableHeader } from './TableHeader'

export const TreeTable = React.memo(
  React.forwardRef((_, ref: React.ForwardedRef<TableVirtuosoHandle>) => {
    const items = useUnit($flatItems)

    const { isScrolled, containerRef } = useCheckScrolled()

    const MemoHeader = React.useCallback(() => {
      return <TableHeader isScrolled={isScrolled.horizontal} />
    }, [isScrolled])

    const MemoComponent = React.useCallback((_: number, item: EstimateTableRow) => {
      return <TableItemContainer item={item} isScrolled={isScrolled.horizontal} />
    }, [isScrolled])

    const handleScrollerRef = React.useCallback((ref) => {
      containerRef.current = ref
    }, [])

    if (!items) return null

    return (
      <TableVirtuoso
        ref={ref}
        scrollerRef={handleScrollerRef}
        style={{ height: '100%' }}
        data={items}
        fixedHeaderContent={MemoHeader}
        itemContent={MemoComponent}
      />
    )
  }))
