import React from 'react'
import styled from 'styled-components'

import { NewIconButton, TextM, palette } from '@/ui'

type Props = {
  onDelete: (id: number) => void
  items: {id: number, name: string}[]
  isDisabled?: boolean
}

export const SelectedRoles = ({
  items,
  isDisabled,
  onDelete,
}: Props) => (
  <List>
    {items.map(({ id, name }) => (
      <Item key={id}>
        <Label>
          {name}
        </Label>
        {!isDisabled && (
          <NewIconButton
            onClick={() => onDelete(id)}
            icon="cross"
            size={12}
            color="grey60"
            hoverColor="grey80"
            dataTestId={`delete-role-${id}`}
          />
        )}
      </Item>
    ))}
  </List>
)


const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`

const Item = styled.div`
  display: inline-flex;
  gap: 8px;
  padding: 4px 8px;
  border-radius: 4px;
  align-items: center;
  border: 1px solid ${palette.grey30};
  background: ${palette.grey10};
  width: max-content;
`

const Label = styled.div`
  ${TextM}
`
