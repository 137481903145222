import React from 'react'
import styled, { css } from 'styled-components'

import { usePagination } from '@/lib/pagination'
import { useWindowWidth } from '@/lib/useWindowWidth'
import { Icon } from './Icon'
import { ThemedStyledProps, themeVar, WINDOW_WIDTH } from './theming'

type Props = {
  totalPages: number
  currentPage: number
  onChangePage: (page: number) => void
  breakPoint?: number
  dataTestId: string
}

export const Pagination = ({
  totalPages,
  currentPage,
  onChangePage,
  breakPoint = WINDOW_WIDTH.smallDesktop,
  dataTestId,
}: Props) => {
  const width = useWindowWidth()
  const siblingCount = width !== null && width < breakPoint ? 0 : 1
  const pages = usePagination({
    totalPageCount: totalPages,
    pageSize: 5,
    currentPage,
    siblingCount,
  })

  if (!pages) {
    return null
  }

  return (
    <Container>
      <Item
        onClick={() => currentPage > 1 && onChangePage(currentPage - 1)}
        className="item"
        data-testid={`${dataTestId}-left-arrow`}
      >
        <Icon icon="arrow-left" />
      </Item>
      {pages.map((pageIndex) => (
        <Item
          key={pageIndex}
          active={pageIndex === currentPage}
          onClick={() => onChangePage(pageIndex)}
          className="item"
          data-testid={`${dataTestId}-page-item-${pageIndex}`}
        >
          {pageIndex}
        </Item>
      ))}
      <Item
        onClick={() => currentPage < totalPages && onChangePage(currentPage + 1)}
        data-testid={`${dataTestId}-right-arrow`}
      >
        <Icon icon="arrow-right" />
      </Item>
    </Container>
  )
}

const Container = styled.div`
  display:flex;
  width: max-content;
  flex-direction: row;
  border-left: 1px solid ${themeVar('line')};
  border-right: 1px solid ${themeVar('line')};

  .item:not(:last-child) {
    border-right: 1px solid ${themeVar('line')};
  }
`

type ItemProps = {
  active?: boolean
}

const Item = styled.div<ItemProps & ThemedStyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 45px;
  cursor: pointer;
  font-size: 14px;
  color: ${themeVar('textGray')};
  box-sizing: border-box;
  border-top: 1px solid ${themeVar('line')};
  border-bottom: 1px solid ${themeVar('line')};

  &:hover {
    color: ${themeVar('main')};
  }

  ${({ active }) => Boolean(active)
    && active
    && css`
      border-bottom: 2px solid ${themeVar('main')};
      color: ${themeVar('main')};
    `}
`
