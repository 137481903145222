import React from 'react'

type Params<T> = {
  items: T[] | null
  keyForSearch: keyof T
  defaultItem: T
  hasSearch?: boolean
}

export const useSearch = <T>({
  items,
  keyForSearch,
  defaultItem,
  hasSearch = true,
}: Params<T>) => {
  const [value, setValue] = React.useState('')

  const filteredItems = React.useMemo(() => {
    if (!hasSearch) return items
    const filteredItems = items?.filter(
      (item) => (
        String(item[keyForSearch])
      ).toLocaleLowerCase().includes(value.toLocaleLowerCase()),
    )
    if (!filteredItems?.length) {
      return [defaultItem]
    }
    return filteredItems
  }, [value, items, hasSearch])


  return {
    value,
    setValue,
    filteredItems,
  }
}
