import { createForm } from 'effector-forms'
import { AmountDistributionType } from './types'

export const distributeAmountsForm = createForm({
  fields: {
    amountDistributionType: {
      init: AmountDistributionType.SplitForAll,
    },
    split_count_to_all: {
      init: '',
    },
    single_count_for_all: {
      init: '',
    },
    single_price_for_all: {
      init: '',
    },
  },
})
