import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import {
  HeadL,
  Switch,
  palette,
} from '@/ui'

import {
  ChangeCompanyRequisitesConfirm,
  ChangePaymentTypeModal,
  CommentContainer,
  DocInfo,
  PaymentOrders,
  RelatedActDoc,
  Requisites,
} from '../containers'
import {
  $canEdit,
  $isDraft,
  $isPaymentAct,
  $isThirdPerson,
  changePaymentTypeModal,
} from '../../../model/private'
import { PaymentForm } from '../../../payment-edit/view'

export const DocumentInfo = React.memo(() => {
  const isThirdPerson = useUnit($isThirdPerson)
  const canEdit = useUnit($canEdit)
  const isPaymentAct = useUnit($isPaymentAct)
  const isDraft = useUnit($isDraft)

  const isShowRequisites = (!isThirdPerson && canEdit) || !canEdit

  return (
    <ContentWrapper>

      <ColHead>
        <Title>Данные платежа</Title>
        {!isPaymentAct && isDraft && (
          <Switch
            isChecked={isThirdPerson}
            label="Оплата 3-им лицам"
            onChange={() => changePaymentTypeModal.open({ value: !isThirdPerson })}
          />
        )}
      </ColHead>

      {isShowRequisites && <Requisites />}
      {canEdit && <PaymentForm />}
      {!canEdit && (
        <>
          <DocInfo />
          <RelatedActDoc />
          <CommentContainer />
          <PaymentOrders />
        </>
      )}

      <ChangeCompanyRequisitesConfirm />
      <ChangePaymentTypeModal />
    </ContentWrapper>
  )
})

const ContentWrapper = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: ${palette.white};
  border-radius: 20px;
`

const ColHead = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`

const Title = styled.div`
  ${HeadL}
`
