import React from 'react'
import styled, { css } from 'styled-components'
import { useGate, useUnit } from 'effector-react'
import { useRouteMatch } from 'react-router-dom'

import { EstimateLotId } from '@/dal'
import { ContentContainer, FlexScrollbar, Loader } from '@/ui'
import {
  $activeTabId,
  $documentInfo,
  $isDocumentPending,
  EstimateLotGate,
  EstimateLotTab,
} from '@/features/estimate-lot/model'
import { EstimateLotHeader } from '@/features/estimate-lot/header'
import { EstimateLotInfo } from '@/features/estimate-lot/info'
import { EstimateLotTable } from '@/features/estimate-lot/table'
import { KvrKerCatalog } from '@/features/estimate-lot/kvr-ker-catalog'

export const EstimateLotPage = () => {
  const router = useRouteMatch<{ id: string }>()

  const [info, activeTab, isDocumentPending] = useUnit([
    $documentInfo,
    $activeTabId,
    $isDocumentPending,
  ])

  const id = parseInt(router.params.id, 10) as EstimateLotId

  useGate(EstimateLotGate, id)

  if (!info && isDocumentPending) return <Loader />

  return (
    <FlexScrollbar>
      <ContentContainer>
        <Wrapper>
          <EstimateLotHeader />

          <Content isHidden={activeTab !== EstimateLotTab.Info}>
            <EstimateLotInfo />
          </Content>

          <Content isHidden={activeTab !== EstimateLotTab.CommercialOffer}>
            <Row>
              <EstimateLotTable />
              <KvrKerCatalog />
            </Row>
          </Content>
        </Wrapper>
      </ContentContainer>
    </FlexScrollbar>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
`

const Content = styled.div<{ isHidden: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${({ isHidden }) => isHidden && css`
    display: none;
  `}
`

const Row = styled.div`
  display: flex;
  gap: 16px;
  flex-grow: 1;
`
