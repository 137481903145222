import React from 'react'
import styled from 'styled-components'

import { GridTemplate, Cell } from '@/features/document-flow/shared'
import { TextM } from '@/ui'

export const FilterLabels = () => {
  return (
    <Wrapper>
      <HeaderCell>Документ</HeaderCell>
      <HeaderCell>Наименование документа</HeaderCell>
      <HeaderCell>Организация</HeaderCell>
      <HeaderCell>Дата документа</HeaderCell>
      <HeaderCell>Период действия</HeaderCell>
      <HeaderCell>Статус</HeaderCell>
      <HeaderCell>№ вход. док.</HeaderCell>
      <HeaderCell>Дата входящего док.</HeaderCell>
      <HeaderCell align="right">Сумма документа</HeaderCell>
      <HeaderCell align="right">Действия</HeaderCell>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${GridTemplate}
`

const HeaderCell = styled(Cell)`
  ${TextM}
`
