import React from 'react'
import styled from 'styled-components'

import {
  NotificationItem,
  NotificationType,
  TextLLight,
} from '@/ui'

import { getFormattedContractName } from '../mappers'
import { DocumentCreationButtons } from './DocumentCreationButtons'

type Props = {
  contractName?: string
  isPending: boolean
  onConfirm: () => void
  onClose: () => void
}

export const CreateKorDocConfirm = ({
  contractName, isPending, onConfirm, onClose,
}: Props) => {
  return (
    <Wrapper>
      <NotificationItem
        type={NotificationType.Warning}
        title="Внимание!"
        body="Убедитесь в том, что со стороны руководства проекта предварительно был согласован
          запрос на изменение текущей стоимости сметы и состава работ. В ином случае вы потратите
          много времени на согласование, и предварительная спецификация будет отклонена."
      />

      <Description>
        Создать документ предварительной спецификации по
        {' '}
        {getFormattedContractName(contractName)}
        ?
      </Description>

      <DocumentCreationButtons
        isPending={isPending}
        onSubmit={onConfirm}
        onClose={onClose}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Description = styled.div`
  ${TextLLight}
  margin-bottom: 12px;
`
