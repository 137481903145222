import React from 'react'
import styled, { css } from 'styled-components'

import { DefaultButton } from './DefaultButton'
import { Icon2, IconName2 } from '../icons'
import { HeadM } from '../typography'
import { palette } from '../palette'

type Props = {
  text: string
  icon: IconName2
  linkStyle?: 'primary' | 'secondary'
  dataTestId: string
  onClick?: () => void
}

export const ServiceLink = ({
  icon,
  text,
  dataTestId,
  linkStyle = 'primary',
  onClick,
}: Props) => (
  <Wrapper
    onClick={onClick}
    linkStyle={linkStyle}
    data-testid={dataTestId}
  >
    <ContentWrapper>
      <Icon2 icon={icon} size={linkStyle === 'primary' ? 32 : 20} />
      <TextWrapper linkStyle={linkStyle}>
        {text}
      </TextWrapper>
    </ContentWrapper>
    <ArrowWrapper>
      <Icon2 icon="arrowRight" />
    </ArrowWrapper>
  </Wrapper>
)

const TextWrapper = styled.div<Pick<Props, 'linkStyle'>>`
  ${HeadM}
  transition: color 0.15s linear;

  ${({ linkStyle }) => linkStyle === 'secondary' && css`
    font-weight: 300;
  `}
`

const ArrowWrapper = styled.div`
  height: 16px;
  color: ${palette.grey60};
  transition: color 0.15s linear;
`

const Wrapper = styled(DefaultButton)<Pick<Props, 'linkStyle'>>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  width: 100%;
  padding: ${({ linkStyle }) => (linkStyle === 'primary' ? '16px' : '8px 0')};
  border-radius: 20px;
  background-color: ${palette.white};
  cursor: pointer;

  ${({ linkStyle }) => linkStyle === 'primary'
    && css`
      border: 1px solid ${palette.grey20};
      transition: border-color 0.15s linear;

      @media (hover: hover) {
        &:hover {
          border-color: ${palette.grey40};
        }
      }
    `}

  @media (hover: hover) {
    &:hover {
      ${TextWrapper}, ${ArrowWrapper} {
        color: ${palette.accent100};
      }
    }
  }
`

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`
