import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { RouterPaths } from '@/features/app/model'

import { TextMLight } from '../../typography'
import { NewTextButton } from '../../buttons'

type Props = {
  contract: {
    name?: string,
    id?: number,
    project: {
      id: number
      name?: string
    }
  }
}

export const DocumentInfoBottom = ({
  contract,
}: Props) => {
  return (
    <>
      <ContractInfo>
        <Link
          to={`${RouterPaths.Administrative}/${contract.id}`}
          data-testid={`open-contract-${contract.id}`}
          target="_blank"
        >
          <NewTextButton
            label={contract.name ?? ''}
            textStyle="ML"
            tooltip="Открыть карточку договора"
            dataTestId=""
            hasUnderlineOnHover
          />
        </Link>
        <ProjectName>
          {contract.project.name ?? ''}
        </ProjectName>
      </ContractInfo>
    </>
  )
}

const ContractInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const ProjectName = styled.div`
  ${TextMLight}
`
