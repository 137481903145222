import {
  CustomResponseErrorData,
  ErrorAsIssueErrorData,
  RequestCancelErrorData,
  TextErrorData,
  UserNotFoundErrorData,
} from './types'

// TODO дженерик с Detail, вероятно, лучше поменять на что-то другое
export class CustomResponseError<Detail = string> extends Error {
  data: CustomResponseErrorData<Detail> = {
    detail: null,
    code: null,
  }

  constructor(data: any, message = '') {
    super(message)
    this.name = 'CustomResponseError'
    this.data = data
  }
}

export class TextError extends CustomResponseError {
  data: TextErrorData

  constructor(data: TextErrorData, message = '') {
    super(message)
    this.name = 'TextError'
    this.data = data
  }
}

export class UserNotFoundError extends CustomResponseError {
  data: UserNotFoundErrorData

  constructor(data: UserNotFoundErrorData, message = '') {
    super(message)
    this.name = 'UserNotFound'
    this.data = data
  }
}

export class ErrorAsIssueError extends CustomResponseError {
  data: ErrorAsIssueErrorData

  constructor(data: ErrorAsIssueErrorData, message = '') {
    super(message)
    this.name = 'ErrorAsIssue'
    this.data = data
  }
}

export class RequestCancelError extends CustomResponseError {
  data: RequestCancelErrorData

  constructor(data: RequestCancelErrorData, message = '') {
    super(message)
    this.name = '__REQUEST_CANCEL'
    this.data = data
  }
}
