import React from 'react'
import styled from 'styled-components'
import Scrollbars from 'react-custom-scrollbars-2'
import { useUnit } from 'effector-react'

import { DocumentFlowHeader } from '@/features/document-flow/header'
import { DocumentFlowFilters } from '@/features/document-flow/filters'
import { DocumentFlowList } from '@/features/document-flow/list'
import { $contractInfo } from '@/features/document-flow/filters/model'
import { pushNavigate, RouterPaths } from '@/features/app/model'
import { ContentContainer, palette, TagButton } from '@/ui'

export const DocumentFlowPage = () => {
  const contract = useUnit($contractInfo)

  const buttonLabel = contract ? `Все документы: ${contract.contractName}` : ''

  return (
    <Scrollbars autoHeight autoHeightMin={0} autoHeightMax="100%">
      <ContentContainer gap={16}>
        <DocumentFlowHeader />

        {contract && (
          <TagButton
            label={buttonLabel}
            isTextLimited={false}
            dataTestId=""
            onClose={() => pushNavigate(RouterPaths.DocumentFlow)}
          />
        )}

        <TableWrapper>
          <DocumentFlowFilters />
          <DocumentFlowList />
        </TableWrapper>
      </ContentContainer>
    </Scrollbars>
  )
}

const TableWrapper = styled.div`
  width: fit-content;
  background-color: ${palette.white};
  border-radius: 12px;
`
