import React from 'react'
import styled, { css } from 'styled-components'

import { Icon2, IconName2 } from '../icons'
import { palette } from '../palette'
import {
  TextLLight,
  TextMLight,
  TextSLight,
} from '../typography'
import { NewIconButton } from '../buttons'
import { OverflowText } from './OverflowText'

type Props = {
  name: string
  size?: 'L' | 'M' | 'S'
  url?: string
  isDense?: boolean
  extension?: string
  onDelete?: (name: File['name']) => void
}

const iconMap: {[key in string]: IconName2 } = {
  pdf: 'pdf-icon',
  png: 'png-icon',
  jpeg: 'jpeg-icon',
  jpg: 'jpeg-icon',
  svg: 'svg-icon',
  gif: 'gif-icon',
  xsl: 'xsl-icon',
  zip: 'zip-icon',
}

const fileIconSizeMap = {
  L: 32,
  M: 24,
  S: 24,
}

const deleteIconSizeMap = {
  L: { size: 24, padding: '8px' },
  M: { size: 12, padding: '6px' },
  S: { size: 12, padding: '6px' },
}

export const NewFileItem = ({
  url,
  name,
  size = 'M',
  isDense = false,
  extension: fileExtension,
  onDelete,
}: Props) => {
  const iconSize = fileIconSizeMap[size]
  const deleteSize = deleteIconSizeMap[size]
  const extension = fileExtension || (url?.split('.').pop()?.toLowerCase() ?? '')

  // @TODO Разобраться, почему иногда по клику на download link не происходит скачивание
  // const ref = React.createRef<HTMLAnchorElement>()
  // const onClick = () => {
  //   ref.current?.click()
  // }

  if (!url) {
    return (
      <AttachetWrapper>
        <Icon2 size={iconSize} icon={iconMap[extension] || 'pdf-icon'} />
        <Text
          size={size}
          isFill={!isDense}
        >
          <OverflowText maxTooltipWidth={250}>
            {name}
          </OverflowText>
        </Text>
        {onDelete && (
          <NewIconButton
            dataTestId={`remove-file-${name}`}
            icon="delete"
            size={deleteSize.size}
            padding={deleteSize.padding}
            onClick={() => onDelete(name)}
            hoverBackgroundColor="grey10"
            borderRadius="100px"
            color="grey60"
            hoverColor="grey100"
            tooltipText="Удалить документ"
          />
        )}
      </AttachetWrapper>
    )
  }

  return (
    <AttachetWrapper>
      <DownloadLink
        href={url}
        download={name}
        isFill={!isDense}
      >
        <Icon2 size={iconSize} icon={iconMap[extension] || 'pdf-icon'} />
        <Text
          size={size}
          isFill={!isDense}
        >
          <OverflowText maxTooltipWidth={250}>
            {name}
          </OverflowText>
        </Text>
      </DownloadLink>
      {onDelete && (
        <NewIconButton
          dataTestId={`remove-file-${name}`}
          icon="delete"
          size={deleteSize.size}
          padding={deleteSize.padding}
          onClick={() => onDelete(name)}
          hoverBackgroundColor="grey10"
          borderRadius="100px"
          color="grey60"
          hoverColor="grey100"
          tooltipText="Удалить документ"
        />
      )}
    </AttachetWrapper>
  )
}

const DownloadLink = styled.a<{ isFill: boolean }>`
  display: flex;
  gap: 6px;
  width: 100%;
  text-decoration: none;
  align-items: center;
  color: ${palette.accent100};
  ${({ isFill }) => isFill && css`
    flex-grow: 1;
  `}
`

const AttachetWrapper = styled.div`
  display: flex;
  gap: 6px;
  width: 100%;
  align-items: center;
  color: ${palette.grey90};
`

const textSizeMap = {
  L: TextLLight,
  M: TextMLight,
  S: TextSLight,
}

const Text = styled.div<{ size: 'L' | 'M' | 'S', isFill: boolean }>`
  ${({ size }) => textSizeMap[size]}
  min-width: 0;
  color: inherit;
  ${({ isFill }) => isFill && css`
    flex-grow: 1;
  `}
`
