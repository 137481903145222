import React from 'react'
import styled, { css } from 'styled-components'

import { ApprovementProcessItem, ApprovementProcessStatus } from '@/dal'
import { getFullTime } from '@/lib/date'

import { palette } from '../../palette'
import { TextSLight } from '../../typography'
import { DocumentStatusLabel } from '../labels'
import { ExpandableText } from '../ExpandableText'
import { getProcessStatusText } from './lib'

type Props = ApprovementProcessItem

export const Item = ({
  from_status, action_did, comment, date,
}: Props) => {
  const isRejected = action_did === ApprovementProcessStatus.Rejected

  return (
    <Wrapper>
      <Header>
        <DocumentStatusLabel status={from_status} size="S" />
        <StatusAndDate>
          <ProcessStatus isRejected={isRejected}>
            {getProcessStatusText(action_did)}
          </ProcessStatus>
          {' · '}
          {getFullTime(date)}
        </StatusAndDate>
      </Header>

      {comment && (
        <ExpandableText
          text={comment}
          lineCount={5}
          textSize="SL"
          expandLabel="Развернуть"
          canCollapse={false}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
`

const StatusAndDate = styled.div`
  ${TextSLight}
  color: ${palette.grey70};
`

const ProcessStatus = styled.span<{ isRejected: boolean }>`
  ${({ isRejected }) => isRejected && css`
    color: ${palette.red100}
  `}
`
