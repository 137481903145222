import { sample } from 'effector'
import { condition } from 'patronum'

import {
  DocumentAlias,
  AdditionalRequestModalInfo,
  PaymentSubTypes,
  PaymentId,
  isErrorAsIssueError,
} from '@/dal'
import { isNeedChangeTableError } from '@/dal/payment/type-guards'
import { createToast } from '@/features/toast-service/model'
import { RouterPaths, replaceNavigate } from '@/features/app/model'
import { onAdditionalRequestUpdated, openAdditionalRequestModal } from '@/features/additional-request/model'
import { deleteDocument, forceDeleteDocById, onPaymentDeleted } from '@/features/document-manager/model'
import { selectCompany } from '@/features/my-company/model'
import { downloadBlobFileFx } from '@/features/download-files'
import { onErrorAsIssueOccured } from '@/features/support/error-modal/model'

import {
  $isThirdPerson,
  $showConfirm,
  closeConfirm,
  deleteFile,
  getPaymentItemFx,
  onAdditionalRequestClick,
  onChangeInfo,
  onConfirm,
  onDelete,
  onOpenAttachments,
  openCompany,
  postPaymentFilesFx,
  sendFiles,
  setIsThirdPerson,
  $paymentInfo,
  onApproveButton,
  postPaymentToApproveFx,
  $paymentDocumentId,
  updatePayment,
  attachmentsModel,
  closeBankWarrantySplashFx,
  getPaymentFeaturesFx,
  downloadTemplateLink,
  getTemplateLinkFx,
  downloadTemplateLinkFx,
} from './private'
import {
  PaymentGate, changeActiveTab, setInvalidActiveTab,
} from './public'
import { editPaymentForm } from './edit-private'
import { createContragentFx } from './create-contragent.private'
import { PaymentTab } from './types'
import { createBankAccountFx } from './create-bank-private'

import './edit-init'
import './cancel-approve.init'
import './table.init'
import './create-contragent.init'
import './create-bank.init'

$showConfirm
  .on(onChangeInfo, () => true)
  .reset(PaymentGate.close, closeConfirm)

$isThirdPerson
  .on(setIsThirdPerson, (_, val) => val)
  .on(getPaymentItemFx.doneData, (_, info) => info.pay_type === PaymentSubTypes.THIRD)
  .reset(PaymentGate.close)

$paymentInfo
  .on([
    getPaymentItemFx.doneData,
    updatePayment,
    postPaymentToApproveFx.doneData,
  ], (_, item) => item)
  .on(getPaymentFeaturesFx.doneData, (info, features) => (info ? { ...info, features } : null))
  .on(postPaymentFilesFx.doneData, (info, attachments) => {
    if (!info) return info
    return {
      ...info,
      attachments,
    }
  })
  .on(deleteFile, (info, fileId) => {
    if (!info) return info
    return {
      ...info,
      attachments: info.attachments?.filter((file) => file.id !== fileId) || null,
    }
  })
  .on([createContragentFx.doneData, createBankAccountFx.doneData], (info, contragentInfo) => {
    if (!info) return info
    return {
      ...info,
      contragent_request: contragentInfo,
    }
  })
  .on(closeBankWarrantySplashFx.doneData, (info, features) => (info ? { ...info, features } : null))
  .reset(PaymentGate.close)

sample({
  clock: [PaymentGate.state, onAdditionalRequestUpdated],
  source: PaymentGate.state,
  filter: ({ id }) => Boolean(id),
  fn: ({ id }) => id as PaymentId,
  target: getPaymentItemFx,
})

sample({
  clock: postPaymentToApproveFx.doneData,
  fn: () => PaymentTab.Info,
  target: changeActiveTab,
})

sample({
  clock: onApproveButton,
  target: editPaymentForm.validate,
})

sample({
  clock: editPaymentForm.formValidated,
  source: $paymentDocumentId,
  filter: Boolean,
  target: postPaymentToApproveFx,
})

sample({
  clock: postPaymentToApproveFx.failData,
  filter: (error) => isNeedChangeTableError(error),
  fn: () => PaymentTab.Table,
  target: setInvalidActiveTab,
})

sample({
  clock: postPaymentToApproveFx.failData,
  filter: (error) => isErrorAsIssueError(error),
  target: onErrorAsIssueOccured,
})

sample({
  clock: getPaymentItemFx.failData,
  filter: PaymentGate.status,
  target: replaceNavigate.prepend(() => RouterPaths.Administrative),
})

createToast({
  effect: postPaymentToApproveFx,
})

sample({
  clock: onAdditionalRequestClick,
  source: $paymentInfo,
  filter: Boolean,
  fn: (info): AdditionalRequestModalInfo => ({
    docId: info.id,
    docType: DocumentAlias.PAYMENT,
    status: info.status,
  }),
  target: openAdditionalRequestModal,
})

sample({
  clock: onDelete,
  source: $paymentInfo,
  filter: Boolean,
  fn: (info) => ({
    id: info.id,
    type: DocumentAlias.PAYMENT as const,
    documentName: info.name,
    status: info.status,
  }),
  target: deleteDocument,
})

sample({
  clock: onOpenAttachments,
  source: $paymentInfo,
  filter: Boolean,
  fn: (info) => ({
    id: info.id,
  }),
  target: attachmentsModel.open,
})

sample({
  clock: openCompany,
  target: [replaceNavigate.prepend(() => RouterPaths.MyCompany), selectCompany],
})

condition({
  source: sample({
    clock: onPaymentDeleted,
    source: $paymentInfo.map((info) => info?.company_id ?? null),
    filter: PaymentGate.status,
  }),
  if: $showConfirm,
  then: openCompany,
  else: replaceNavigate.prepend(() => RouterPaths.Administrative),
})

sample({
  clock: onConfirm,
  source: $paymentInfo,
  filter: Boolean,
  fn: ({ id, name }) => ({
    id,
    type: DocumentAlias.PAYMENT as const,
    documentName: name,
  }),
  target: forceDeleteDocById,
})

sample({
  clock: deleteFile,
  source: $paymentDocumentId,
  filter: Boolean,
  fn: (docId, fileId) => ({ id: docId, files_to_delete: [fileId] }),
  target: postPaymentFilesFx,
})

sample({
  clock: sendFiles,
  source: $paymentDocumentId,
  filter: Boolean,
  fn: (docId, files) => ({ id: docId, files_to_save: files }),
  target: postPaymentFilesFx,
})

sample({
  clock: downloadTemplateLink,
  source: $paymentDocumentId,
  filter: Boolean,
  target: getTemplateLinkFx,
})

sample({
  clock: getTemplateLinkFx.doneData,
  filter: Boolean,
  target: downloadTemplateLinkFx,
})

sample({
  clock: downloadTemplateLinkFx.done,
  fn: ({ params: item, result: blob }) => ({
    blob,
    fileName: item.name,
  }),
  target: downloadBlobFileFx,
})

createToast({
  effect: postPaymentFilesFx,
  errorText: 'Произошла ошибка при обновлении файлов',
})
