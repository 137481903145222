import React from 'react'
import { useUnit } from 'effector-react'

import { SelectItemButton } from '@/ui'
import { createAttachmentsModalView } from '@/features/factories/attach-modal'

import {
  $canSendFiles, $documentAttachmentsCount, attachmentsModel, openAttachmentsModal,
} from '../../model/private'

const AttachmentsModalView = createAttachmentsModalView(attachmentsModel)

export const CompensationAttachments = React.memo(() => {
  const [count, canSendFiles] = useUnit([
    $documentAttachmentsCount,
    $canSendFiles,
  ])

  return (
    <>
      <SelectItemButton
        text="Мои вложения"
        additionalText={String(count || 0)}
        icon={canSendFiles ? 'plusWithCircle' : 'arrowRightM'}
        buttonStyle={canSendFiles ? 'secondary' : 'primary'}
        hasBorder={false}
        dataTestId="compensation-open-attachments"
        onClick={openAttachmentsModal}
      />
      <AttachmentsModalView />
    </>
  )
})
