import React from 'react'

type Params<T> = {
  source: Array<T> | null
  shortCount?: number
}

export const useShortItems = <T>({ source, shortCount = 3 }: Params<T>) => {
  const [isExpand, setIsExpand] = React.useState(false)

  const visibleItems = React.useMemo(() => {
    if (source === null) return source
    return isExpand ? source : source.slice(0, shortCount)
  }, [source, isExpand])

  return {
    visibleItems,
    isExpand,
    setIsExpand,
    showButton: !isExpand && (source?.length || 0) > shortCount,
  }
}
