import React from 'react'
import { useUnit } from 'effector-react'

import { AuthLayout } from '@/ui'
import { RepeatRegistrationForm } from '../containers'
import { $isSuccess } from '../../model/private'

export const RepeatRegistrationLetter = React.memo(() => {
  const isSuccess = useUnit($isSuccess)

  return (
    <AuthLayout
      title="Отправить повторное письмо о регистрации"
      hasBackButton
      subText="Введите электронную почту, на которую зарегистрирован ваш личный кабинет."
      isSuccess={isSuccess}
      successTitle="Запрос выполнен"
      successText="Информация по восстановлению доступа будет направлена на указанную электронную почту. "
    >
      <RepeatRegistrationForm />
    </AuthLayout>
  )
})
