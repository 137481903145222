import React, { memo } from 'react'
import styled from 'styled-components'

import { NewButton } from '@/ui'
import { DOCUMENT_TYPES, LKP_SECTIONS } from '@/features/support/model'
import { ModalHeader } from '@/features/support/shared'

import {
  createAppealModal,
  selectDocumentProblem,
  selectLkpSectionProblem,
} from '../../model/private'
import { AppealOptions } from '../parts'

export const SelectTechSupportType = memo(() => {
  return (
    <Wrapper>
      <ModalHeader
        title="С чем вам нужно помочь?"
        subtitle="Выберите раздел или документ, по которому возник вопрос"
      />

      <AppealTypes>
        <AppealOptions title="Документы" items={DOCUMENT_TYPES} onClick={selectDocumentProblem} />
        <AppealOptions title="Разделы ЛКП" items={LKP_SECTIONS} onClick={selectLkpSectionProblem} />
      </AppealTypes>

      <Footer>
        <NewButton
          dataTestId="cancel-button"
          label="Закрыть"
          buttonType="grey"
          isFill
          onClick={createAppealModal.close}
        />
      </Footer>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  width: 640px;
  padding: 32px;
`

const AppealTypes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const Footer = styled.div`
  width: 164px;
  margin: 0 auto;
`
