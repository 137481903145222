import React from 'react'
import styled from 'styled-components'
import { Icon } from './Icon'
import { themeVar } from './theming'
import { BadgeCountRounded } from './BadgeCount'

type Props = {
  comment?: string
}

export const HelpLegend = ({
  comment,
}: Props) => (
  <Container>
    <Row>
      <span>*</span>
      {' '}
      Принято технадзором
      <Badge>*****</Badge>
    </Row>
    {comment && (
      <Row>
        <span>*</span>
        {' '}
        Комментарий от Технадзора
        <IconContainer>
          <Icon icon="micro-message" size={8} />
        </IconContainer>
      </Row>
    )}
  </Container>
)

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-size: 12px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
  color: ${themeVar('gray4')};
`

const Badge = styled(BadgeCountRounded)`
  font-size: 11px;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${themeVar('gray5')};
  width: 14px;
  height: 14px;
  border-radius: 16px;
  cursor: pointer;

  svg {
    fill: ${themeVar('gray3')};
  }
`
