import React from 'react'
import * as Sentry from '@sentry/react'

// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history'

import { Routes } from './Routes'

import './init'
import { appInit } from './features/app/model'

// Themes and styles
import 'react-day-picker/dist/style.css'
import GlobalCSS from './ui/theming/main.css'
import './ui/theming/global.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'swiper/css'
import 'react-loading-skeleton/dist/skeleton.css'

import { ThemeProvider } from './ui'
import { ToastList } from './features/toast-service/view'

const history = createBrowserHistory()

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    })],
    tracesSampleRate: 1.0,
  })
}

export const App = Sentry.withProfiler(() => {
  React.useEffect(() => {
    appInit()
  }, [])

  return (
    <ThemeProvider>
      <>
        <GlobalCSS />
        <ToastList />
        <Routes />
      </>
    </ThemeProvider>
  )
})
