import React from 'react'
import styled, { css } from 'styled-components'

import { Icon2, TextMLight, palette } from '@/ui'

type Props = {
  text: string
  isCurrent: boolean
  isPrediction: boolean
}

export const Label = ({
  isCurrent,
  isPrediction,
  text,
}: Props) => (
  <Item
    isPrediction={isPrediction}
    isCurrent={isCurrent}
  >

    {isCurrent && <Icon2 size={10} icon="graphPeriodIcon" />}
    <div>
      {text}
      {isPrediction && <div>прогноз</div>}
    </div>
  </Item>
)



const Item = styled.div<{
  isCurrent: boolean
  isPrediction: boolean
}>`
  ${TextMLight};
  color: ${palette.grey70};
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  text-align: center;

  ${({ isPrediction }) => isPrediction && css`
    color: ${palette.accent100}
  `}
`
