import React from 'react'
import { useUnit } from 'effector-react'
import styled from 'styled-components'
import { useForm } from 'effector-forms'

import {
  HeadXL,
  ModalLayoutV2,
  NewButton,
  NewTextButton,
  NotificationItem,
  NotificationType,
  TextAreaField,
} from '@/ui'
import { useOnChangeLocation } from '@/lib/useOnChangeLocation'
import { noop } from '@/lib/noop'
import { RouterPaths } from '@/features/app/model'

import { $documentInfo } from '../../../model'
import {
  $isCancelApprovingModalOpen,
  MAX_COMMENT_LENGTH,
  cancelActApproveFx,
  cancelApprovingForm,
  closeCancelApprovingModal,
} from '../../../model/header.private'

export const CancelApprovingModal = React.memo(() => {
  const isOpen = useUnit($isCancelApprovingModalOpen)
  const info = useUnit($documentInfo)
  const isPending = useUnit(cancelActApproveFx.pending)
  const {
    fields, submit, isValid, isTouched,
  } = useForm(cancelApprovingForm)

  useOnChangeLocation(closeCancelApprovingModal)

  if (!info || !isOpen) return null

  const { id, draft } = info

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    submit()
  }

  const onClose = isPending ? noop : closeCancelApprovingModal

  return (
    <ModalLayoutV2
      closeModal={onClose}
      dataTestId={`cancel-approving-modal-${id}`}
    >
      <Wrapper>
        <Header>Отмена согласования</Header>

        <NotificationsWrapper>
          {draft && (
            <NotificationItem
              type={NotificationType.Warning}
              title="Внимание!"
              body={(
                <>
                  У вас уже есть сформированный черновик
                  {' '}
                  <NewTextButton
                    url={`${RouterPaths.ActPage}/${draft.id}`}
                    label={draft.name}
                    textStyle="ML"
                    dataTestId="act-existing-draft"
                    isNewBlank
                    isInline
                  />
                  . Если вы отмените согласование документа, черновик автоматически будет удален.
                </>
              )}
            />
          )}
          <NotificationItem
            type={NotificationType.Info}
            body="Документ будет доступен для редактирования через 20 минут после отмены согласования"
          />
        </NotificationsWrapper>

        <Form onSubmit={handleSubmit}>
          <TextAreaField
            label="Комментарий"
            value={fields.comment.value}
            currentSymbolCount={fields.comment.value.length}
            maxSymbolCount={MAX_COMMENT_LENGTH}
            error={fields.comment.firstError?.errorText}
            height={100}
            disabled={isPending}
            placeholder="Укажите краткую причину отмены согласования"
            dataTestId="cancel-approve-comment-textarea"
            autoGrow
            required
            onChange={fields.comment.onChange}
          />

          <Row>
            <NewButton
              label="Отменить согласование"
              type="submit"
              dataTestId="submit-cancel-approve-form"
              isDisabled={!isValid || !isTouched}
              isPending={isPending}
              isFill
            />
            <NewButton
              label="Назад"
              buttonType="grey"
              isDisabled={isPending}
              dataTestId="close-cancel-approve-modal"
              onClick={onClose}
              isFill
            />
          </Row>
        </Form>
      </Wrapper>
    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 500px;
  padding: 32px;
`

const Header = styled.div`
  ${HeadXL}
`

const NotificationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const Row = styled.div`
  display: flex;
  gap: 16px;
`
