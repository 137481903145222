import { ActTab } from './types'

export const ACT_TABS = [
  { id: ActTab.Info, label: 'Информация' },
  { id: ActTab.Works, label: 'КС-2' },
  { id: ActTab.Deductions, label: 'КС-3' },
]

export const ACT_PIR_TABS = [
  { id: ActTab.Info, label: 'Информация' },
  { id: ActTab.Works, label: 'Акт' },
  { id: ActTab.Deductions, label: 'Удержания' },
]
