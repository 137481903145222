import React from 'react'
import { useForm } from 'effector-forms'

import { DateRangeInput, MultiSelectInput, NewDropDownInput } from '@/ui'

import { PIR_REMAINED_FILTERS, PIR_STATUS_FILTERS } from '../../model/const'
import { filters } from '../../model/filters.private'
import { DateInputWrapper, InputWrapper } from '../parts'

export const PIRFilters = React.memo(() => {
  const {
    fields: {
      remained, acceptanceStatus, dateFrom, dateTo,
    },
  } = useForm(filters)

  return (
    <>
      <InputWrapper>
        <MultiSelectInput
          items={PIR_REMAINED_FILTERS}
          selectedIds={remained.value}
          testId="act-remained"
          onAcceptClick={remained.onChange}
          placeholder="Остаток к выполнению"
          hasAcceptOnClear
        />
      </InputWrapper>

      <InputWrapper>
        <NewDropDownInput
          dataTestId="administrative-select-project"
          options={PIR_STATUS_FILTERS}
          onOptionClick={acceptanceStatus.onChange}
          selectedId={acceptanceStatus.value}
          placeholder="Статус приемки"
        />
      </InputWrapper>

      <DateInputWrapper>
        <DateRangeInput
          placeholder="Период выполнения работ"
          inputValue={{ from: dateFrom.value, to: dateTo.value }}
          dataTestId="act-date-filter-input"
          onChangeFrom={dateFrom.onChange}
          onChangeTo={dateTo.onChange}
        />
      </DateInputWrapper>
    </>
  )
})
