import { createForm } from 'effector-forms'
import { attach } from 'effector'

import { rules, withDisableCondition } from '@/lib/form-validators'
import { $hasSplittingObjects } from '@/features/estimate-correction/shared-model'
import {
  EstimateTableRow,
  createEstimateFolderReqFx,
  createEstimateRowReqFx,
  getEstimateRowIndexReqFx,
} from '@/dal'

import { d } from './domain'

export const $isCreateFolder = d.store(false)
export const closeModal = d.event<void>()
export const $isOpen = d.store<boolean>(false)

export const $createdTableItem = d.store<EstimateTableRow | null>(null)
export const setCreatedTableItem = d.event<EstimateTableRow>()
export const resetCreatedTableItem = d.event()

const isProjectRequired = () => !$hasSplittingObjects.getState()

export const createFolderForm = createForm({
  domain: d,
  fields: {
    group_id: {
      init: null as null | EstimateTableRow['id'],
    },
    name: {
      init: '',
      rules: [rules.required()],
    },
  },
})

export const createRowForm = createForm({
  domain: d,
  validateOn: ['change'],
  fields: {
    group_id: {
      init: null as null | EstimateTableRow['id'],
    },
    name: {
      init: '',
      rules: [rules.required()],
    },
    project: {
      init: null as null | number,
      rules: [withDisableCondition(isProjectRequired, rules.required())],
    },
    unit: {
      init: null as null | number,
      rules: [rules.required()],
    },
    count_in_estimate: {
      init: '',
      rules: [rules.required()],
    },
    material: {
      init: '',
      rules: [rules.sumBothFieldsMustBeGreaterThanZero('work')],
    },
    work: {
      init: '',
      rules: [rules.sumBothFieldsMustBeGreaterThanZero('material')],
    },
    justification_of_changes_current: {
      init: '',
    },
    files: {
      init: [] as File[],
    },
    provider: {
      init: '',
      rules: [rules.maxLength(100)],
    },
    specifications: {
      init: '',
      rules: [rules.maxLength(100)],
    },
    stamp: {
      init: '',
      rules: [rules.maxLength(100)],
    },
  },
})

export const createEstimateRowFx = attach({
  effect: createEstimateRowReqFx,
})

export const createEstimateFolderFx = attach({
  effect: createEstimateFolderReqFx,
})

export const getEstimateRowIndexFx = attach({
  effect: getEstimateRowIndexReqFx,
})
