import React from 'react'
import styled from 'styled-components'
import { useUnit, useStoreMap } from 'effector-react'
import Scrollbars from 'react-custom-scrollbars-2'

import {
  HeadL,
  HeadXL,
  Loader,
  ModalLayoutV2,
  TextLLight,
  palette,
} from '@/ui'

import {
  $flatItems,
} from '../../../model/private'
import { CommentsList } from '../containers'
import {
  $openedCommentsRowId,
  $oldCommentsCount,
  $newCommentsCount,
  closeCommentsModal,
  getEstimateItemCommentsFx,
} from '../../../model/comments-item.private'

export const EstimateItemCommentsModal = React.memo(() => {
  const [
    isPending,
    oldCommentsCount,
    newCommentsCount,
  ] = useUnit([
    getEstimateItemCommentsFx.pending,
    $oldCommentsCount,
    $newCommentsCount,
  ])
  const rowId = useUnit($openedCommentsRowId)
  const rowName = useStoreMap({
    store: $flatItems,
    keys: [rowId],
    fn: (tableData, [rowId]) => {
      const row = tableData?.find((row) => row.id === rowId)
      return `${row?.code_1c || ''} ${row?.name.value || ''}`
    },
  })

  if (!rowId) return null
  const isOnlyNewComments = !oldCommentsCount && newCommentsCount
  const hasNewComments = Boolean(newCommentsCount)
  return (
    <ModalLayoutV2 closeModal={closeCommentsModal}>
      <Wrapper>
        <Header>
          <Title>
            Комментарии
          </Title>
          {!isPending && (
            <TotalComments>
              {!isOnlyNewComments && oldCommentsCount}
              {!isOnlyNewComments && hasNewComments && '/'}
              {hasNewComments && (
                <NewComments>
                  {`+${newCommentsCount}`}
                </NewComments>
              )}
            </TotalComments>
          )}

        </Header>

        <Body>
          <Scrollbars autoHeight autoHeightMax="50dvh">
            <RowName>
              {rowName}
            </RowName>
            {isPending ? <Loader /> : (
              <CommentsList />
            )}
          </Scrollbars>
        </Body>
      </Wrapper>
    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
`

const Header = styled.div`
  padding: 32px 32px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
`

const Title = styled.p`
  ${HeadXL}
`

const TotalComments = styled.div`
  ${HeadL}
  color: ${palette.grey60};
`

const NewComments = styled.span`
  color: ${palette.accent100};
`

const RowName = styled.div`
  ${TextLLight}
  padding: 0 32px 20px;
`

const Body = styled.div`
  flex-grow: 1;
  padding-bottom: 16px;
`
