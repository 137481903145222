import React from 'react'

import { DocumentFeatures } from '@/dal'

import { ButtonType, NewButton } from '../../buttons'
import { IconName2 } from '../../icons'

type Props = {
  features: DocumentFeatures
  onOpenAdditional: () => void
}

export const AdditionalRequestButton = ({
  features,
  onOpenAdditional,
}: Props) => {
  if (!features.can_open_visa_chat) return null

  const needAnswer = features.is_answer_required
  const canCreate = features.can_create_visa
  const hasUnreadAnswer = features.has_unread_answer

  const buttonConfig = React.useMemo(() => {
    if (hasUnreadAnswer) {
      return {
        label: '+1 ответ на доп. запрос',
        icon: 'visaTask' as IconName2,
        type: 'line-primary' as ButtonType,
      }
    }
    if (needAnswer) {
      return {
        label: '+1 новый доп. запрос',
        icon: 'visaTask' as IconName2,
        type: 'line-primary' as ButtonType,
      }
    }
    if (canCreate) {
      return {
        label: 'Создать доп. запрос',
        icon: 'plusButton' as IconName2,
        type: 'line-primary' as ButtonType,
      }
    }
    return {
      label: 'Чат по доп. запросам',
      icon: undefined,
      type: 'grey' as ButtonType,
    }
  }, [features])

  return (
    <NewButton
      dataTestId="open-additional-modal"
      label={buttonConfig.label}
      prefixIcon={buttonConfig.icon}
      buttonType={buttonConfig.type}
      size="S"
      onClick={onOpenAdditional}
      isFill
    />
  )
}
