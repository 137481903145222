import { attach, combine } from 'effector'
import { createGate } from 'effector-react'

import {
  RoleItemType,
  ContactItemType,
  getContactsReqFx,
  deleteContactReqFx,
} from '@/dal'
import { createSingleEffect } from '@/lib/createSingleEffect'

import { d } from './domain'
import { $allRoles, $companyProjects } from '../../model'
import { $selectedProjectId } from './public'

export const ContactsGate = createGate('my-company-contacts')

export const $projects = $companyProjects.map((val) => val)
export const selectProject = d.event<number|null>()
export const $selectedProject = combine(
  [$projects, $selectedProjectId],
  ([project, id]) => project?.find((item) => item.id === id) ?? null,
)

export const $selectedRoleId = d.store<RoleItemType['id']|null>(null)
export const setSelectedRole = d.event<RoleItemType['id']|null>()
export const $roles = $allRoles.map((val) => val)

export const $contacts = d.store<ContactItemType[]|null>(null)
export const $visibleContacts = combine([$contacts, $selectedRoleId, $selectedProjectId],
  ([items, roleId, projectId]) => (
    items?.filter(
      (item) => item.projects.some(
        (project) => (!projectId || project.id === projectId)
          && project.roles.some((role) => role.id === roleId),
      )) ?? null
  ),
)
export const {
  abortFx: cancelGetContactsFx,
  requestFx: getContactsFx,
} = createSingleEffect(
  attach({
    effect: getContactsReqFx,
  }),
)

export const onAddContact = d.event<RoleItemType['id'] | void>()
export const onEditContact = d.event<ContactItemType['id']>()

export const $deleteTargetContactId = d.store<ContactItemType['id']|null>(null)
export const openDeleteContactConfirm = d.event<ContactItemType['id']>()
export const rejectConfirm = d.event<void>()
export const deleteContact = d.event<void>()

export const deleteContactFx = attach({
  effect: deleteContactReqFx,
})
