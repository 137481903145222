import { useUnit } from 'effector-react'
import React from 'react'
import Scrollbars from 'react-custom-scrollbars-2'

import { $isCreateFolder } from '../../../model/create-table-item.private'
import { CreateFolderForm } from './CreateFolderForm'
import { CreateRowForm } from './CreateRowForm'


export const CreateForm = React.memo(() => {
  const isFolder = useUnit($isCreateFolder)

  return (
    <Scrollbars autoHeight autoHeightMax="70dvh">
      {isFolder ? <CreateFolderForm /> : <CreateRowForm />}
    </Scrollbars>
  )
})

