import React, { Key } from 'react'
import styled, { css } from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'

import { NewIconButton } from '../buttons'

type Id = Key | null

interface Props<T extends { id: Id }> {
  items: T[]
  itemRenderFn: (arg: T) => JSX.Element
  spaceBetween?: number
  disableArrow?: boolean
}

export function SwiperWrapper<T extends { id: Id }>({
  items,
  itemRenderFn,
  spaceBetween = 8,
  disableArrow,
}: Props<T>) {
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)

  return (
    <Wrapper>
      {!disableArrow
        && (
          <>

            <IconWrapper
              ref={navigationPrevRef}
            >
              <NewIconButton
                dataTestId="active-task-prev-slide"
                color="grey60"
                hoverColor="grey90"
                borderRadius="50%"
                padding="8px"
                icon="arrowLeft"
                hoverBackgroundColor="white"
                size={16}
              />
            </IconWrapper>

            <IconWrapper
              ref={navigationNextRef}
              isRight
            >
              <NewIconButton
                dataTestId="active-task-next-slide"
                color="grey60"
                hoverColor="grey90"
                borderRadius="50%"
                padding="8px"
                hoverBackgroundColor="white"
                icon="arrowRight"
                size={16}
              />
            </IconWrapper>
          </>
        )}


      <Swiper
        modules={[Navigation]}
        slidesPerView="auto"
        spaceBetween={spaceBetween}
        onBeforeInit={(swiper) => {
          // Без этого костыля, работать не хочет
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line no-param-reassign
          swiper.params.navigation.prevEl = navigationPrevRef.current
          // Без этого костыля, работать не хочет
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line no-param-reassign
          swiper.params.navigation.nextEl = navigationNextRef.current
        }}
        navigation
      >
        {items.map((item) => (
          <SwiperSlide key={item.id}>
            {itemRenderFn(item)}
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  )
}


const Wrapper = styled.div`
  width: 100%;
  position: relative;

  .swiper {
    overflow: auto;
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }

    & {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }

  & .swiper-slide {
    width: auto;
  }
`

const IconWrapper = styled.div<{isRight?: boolean}>`
  display: flex;
  align-items: center;

  position: absolute;
  z-index: 2;
  border-radius: 50%;
  left: 5px;
  height: 100%;

  &.swiper-button-disabled {
    display: none;
  }

  &:not(.swiper-button-disabled)::before {
    position: absolute;
    background: linear-gradient(90deg, #FFF 0%, #FFF 45.83%, rgba(255, 255, 255, 0.00) 100%);
    width: 105px;
    height: 100%;
    pointer-events: none;
    left: -9px;
    content: '';
    z-index: -2;
  }

  ${({ isRight }) => isRight && css`
    left: auto;
    right: 5px;

    &:not(.swiper-button-disabled)::before {
      left: auto;
      right: -6px;
      background: linear-gradient(270deg, #FFF 0%, #FFF 45.83%, rgba(255, 255, 255, 0.00) 100%);
    }
  `}
`
