import { createForm } from 'effector-forms'

import { DocumentAlias, JiraDocument, LkpSection } from '@/dal'
import { rules } from '@/lib/form-validators'

import { FILE_MAX_COUNT } from '../../model'
import { d } from './domain'
import { ContactsFormFields } from './types'

const baseFields = {
  subject: {
    init: '',
    rules: [rules.required(), rules.minLength(4)],
  },
  description: {
    init: '',
    rules: [rules.required(), rules.minLength(4)],
  },
  files: {
    init: [] as File[],
    rules: [rules.maxFiles(FILE_MAX_COUNT)],
  },
}

export const documentProblemForm = createForm({
  domain: d,
  validateOn: ['submit'],
  fields: {
    documentType: {
      init: null as DocumentAlias | null,
      rules: [rules.required()],
    },
    document: {
      init: null as JiraDocument['id'] | null,
      rules: [rules.required()],
    },
    ...baseFields,
  },
})

export const lkpSectionProblemForm = createForm({
  domain: d,
  validateOn: ['submit'],
  fields: {
    section: {
      init: null as LkpSection | null,
      rules: [rules.required()],
    },
    ...baseFields,
  },
})

export const contactsForm = createForm<ContactsFormFields>({
  domain: d,
  validateOn: ['change', 'submit'],
  fields: {
    fullName: {
      init: '',
      rules: [rules.required(), rules.fullName()],
    },
    phone: {
      init: '',
      rules: [rules.required(), rules.phoneNumber()],
    },
  },
})

export const addUserForm = createForm({
  domain: d,
  validateOn: ['change', 'submit'],
  fields: {
    first_name: {
      init: '',
      rules: [rules.required()],
    },
    last_name: {
      init: '',
      rules: [rules.required()],
    },
    patronymic: {
      init: '',
      rules: [rules.required()],
    },
    user_info: {
      init: '',
      rules: [rules.required()],
    },
    phone: {
      init: '',
      rules: [rules.required(), rules.phoneNumber()],
    },
    email: {
      init: '',
      rules: [rules.required(), rules.email()],
    },
  },
})

export const blockUserForm = createForm({
  domain: d,
  validateOn: ['change', 'submit'],
  fields: {
    userId: {
      init: null as number | null,
      rules: [rules.required()],
    },
  },
})
