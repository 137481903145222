import React from 'react'

import { Icon2, ToolTipWrapper } from '@/ui'
import { SubSystemId } from '@/features/app/model'
import { Wrapper } from './Styles'

type Props = {
  subsystemId: SubSystemId,
  name: string
}

export const InDevelopmentLink = ({
  name,
  subsystemId,
}: Props) => {
  return (
    <ToolTipWrapper referenceComponent={(
      <Wrapper
        data-testid={`a-menu-item-${subsystemId}`}
        isActive={false}
        isInDevelopment
      >
        {name}
        <Icon2 icon="lock" />
      </Wrapper>
    )}
    >
      Раздел в разработке
    </ToolTipWrapper>
  )
}
