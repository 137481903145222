import React from 'react'

import { Field } from './Field'
import { NewTextArea } from './NewTextArea'

type Props = {
  value: string
  placeholder: string
  onChange: (val: string) => void
  dataTestId: string
  disabled?: boolean
  height?: number
  label: string
  error?: string
  maxSymbolCount?: number
  currentSymbolCount?: number
  required?: boolean
  autoGrow?: boolean
  maxHeight?: number
  showMaxLengthError?: boolean
}

export const TextAreaField = ({
  height,
  label,
  onChange,
  dataTestId,
  placeholder,
  value,
  currentSymbolCount,
  disabled,
  error,
  maxSymbolCount,
  required,
  autoGrow,
  maxHeight,
  showMaxLengthError,
}: Props) => {
  const [hasLengthError, setHasLengthError] = React.useState(false)

  return (
    <Field
      label={label}
      currentSymbolCount={currentSymbolCount}
      maxSymbolCount={maxSymbolCount}
      required={required}
      hasLengthError={hasLengthError}
    >
      <NewTextArea
        dataTestId={dataTestId}
        disabled={disabled}
        height={height}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        isInvalid={Boolean(error)}
        maxLength={maxSymbolCount}
        error={error}
        maxHeight={maxHeight}
        autoGrow={autoGrow}
        showMaxLengthError={showMaxLengthError}
        onChangeLengthError={setHasLengthError}
      />
    </Field>
  )
}
