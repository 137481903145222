import React from 'react'
import { useUnit } from 'effector-react'
import { TableVirtuoso, TableVirtuosoHandle } from 'react-virtuoso'

import { EstimateTableRow } from '@/dal'
import { useCheckScrolled } from '@/lib/hooks'

import { TableItemContainer } from './TableItemContainer'
import {
  $flatItems,
  $hasMore,
  onLoadMore,
  paginateFlatTableFx,
} from '../../../model/private'
import { TableHeader } from './TableHeader'

export const FlatTable = React.memo(
  React.forwardRef((_, ref: React.ForwardedRef<TableVirtuosoHandle>) => {
    const [items, hasMore, isPaginationPending] = useUnit([
      $flatItems,
      $hasMore,
      paginateFlatTableFx.pending,
    ])

    const { isScrolled, containerRef } = useCheckScrolled()

    const MemoHeader = React.useCallback(() => {
      return <TableHeader isScrolled={isScrolled.horizontal} />
    }, [isScrolled])

    const MemoComponent = React.useCallback((_: number, item: EstimateTableRow) => {
      return <TableItemContainer item={item} isScrolled={isScrolled.horizontal} />
    }, [isScrolled])

    const handleScrollerRef = React.useCallback((ref) => {
      containerRef.current = ref
    }, [])

    if (!items) return null

    return (
      <TableVirtuoso
        style={{ height: '100%' }}
        ref={ref}
        scrollerRef={handleScrollerRef}
        data={items}
        fixedHeaderContent={MemoHeader}
        itemContent={MemoComponent}
        endReached={() => hasMore && !isPaginationPending && onLoadMore()}
      />
    )
  }),
)

