import React from 'react'
import { useUnit } from 'effector-react'
import styled from 'styled-components'

import { TextMLight } from '@/ui'
import { DocumentAlias } from '@/dal'

import { CreateDocumentRadio } from '../parts'
import {
  $contractFeatures, $selectedDocumentType, copyDocumentFx, selectType,
} from '../../model/private'
import { $isSomeDocumentCreating } from '../../model'

export const SelectDocumentType = React.memo(() => {
  const contractFeatures = useUnit($contractFeatures)
  const selectedDocumentType = useUnit($selectedDocumentType)
  const [isCreating, isCopying] = useUnit([
    $isSomeDocumentCreating,
    copyDocumentFx.pending,
  ])

  if (!contractFeatures) return null

  const isPending = isCreating || isCopying

  const {
    act,
    kor_doc,
    budget,
    payment_document,
  } = contractFeatures

  return (
    <Wrapper>
      <DocumentsListLabel>
        Выберите тип документа из списка:
      </DocumentsListLabel>

      <DocumentsList>
        <DocumentListItem>
          <CreateDocumentRadio
            currentType={selectedDocumentType}
            canCreateFeature={act.create}
            onChange={selectType}
            type={DocumentAlias.ACT}
            isPending={isPending}
          />
        </DocumentListItem>
        <DocumentListItem>
          <CreateDocumentRadio
            currentType={selectedDocumentType}
            canCreateFeature={kor_doc.create}
            onChange={selectType}
            type={DocumentAlias.KOR_DOC}
            isPending={isPending}
          />
        </DocumentListItem>
        <DocumentListItem>
          <CreateDocumentRadio
            currentType={selectedDocumentType}
            canCreateFeature={budget.create}
            onChange={selectType}
            type={DocumentAlias.BUDGET}
            isPending={isPending}
          />
        </DocumentListItem>
        <DocumentListItem>
          <CreateDocumentRadio
            currentType={selectedDocumentType}
            canCreateFeature={payment_document.create}
            onChange={selectType}
            type={DocumentAlias.PAYMENT}
            isPending={isPending}
            label="Аванс"
          />
        </DocumentListItem>
      </DocumentsList>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const DocumentsListLabel = styled.div`
  ${TextMLight}
`

const DocumentsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`

const DocumentListItem = styled.div`
  
`
