import React, { FormEvent } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'
import { useForm } from 'effector-forms'

import {
  InputField,
  NewDropDownField,
} from '@/ui'

import { $groupList } from '@/features/estimate-correction/shared-model'

import { closeModal, createFolderForm } from '../../../model/create-table-item.private'
import { ButtonRow } from '../parts'

export const CreateFolderForm = React.memo(() => {
  const { fields, isValid, submit } = useForm(createFolderForm)
  const groups = useUnit($groupList)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    submit()
  }

  const foldersOptions = React.useMemo(() => {
    if (!groups) return []
    return [
      { label: 'Папка первого уровня (снаружи всех папок)', id: null },
      ...groups.map((item) => ({ label: `${item.code_1c} ${item.name}`, id: item.id })),
    ]
  }, [groups])


  return (
    <ContentWrapper onSubmit={handleSubmit}>
      <NewDropDownField
        dataTestId="create-row-group"
        label="Папка для хранения"
        required
        options={foldersOptions}
        hasSearch
        placeholder="Выберите папку или начните вводить название..."
        size="L"
        onOptionClick={fields.group_id.onChange}
        selectedId={fields.group_id.value}
        error={fields.group_id.firstError?.errorText}
      />
      <InputField
        dataTestId="create-row-group-folder-name"
        label="Название папки"
        isRequired
        value={fields.name.value}
        onChange={fields.name.onChange}
        onClear={fields.name.reset}
        size="L"
        error={fields.name.firstError?.errorText}
      />
      <ButtonRow
        isDisableSubmit={!isValid}
        onCancel={closeModal}
      />
    </ContentWrapper>
  )
})

const ContentWrapper = styled.form`
  padding: 16px 32px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
