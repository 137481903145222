import { CustomResponseError, DefaultHandledError } from '../error-handler'
import {
  ActCompensationsErrorData,
  ActForApproveErrorData,
  CreateActConflictErrorData,
  CreateActReqiredFieldErrorData,
} from './types'

export type ActHandledError =
  | ApproveActError
  | CreateActConflictError
  | CreateActReqiredFieldError
  | ActCompensationsError
  | DefaultHandledError

export class ApproveActError extends CustomResponseError {
  data: ActForApproveErrorData

  constructor(data: ActForApproveErrorData, message = '') {
    super(message)
    this.name = 'ApproveActError'
    this.data = data
  }
}
export class CreateActConflictError extends CustomResponseError {
  data: CreateActConflictErrorData

  constructor(data: CreateActConflictErrorData, message = '') {
    super(message)
    this.name = 'CreateActError'
    this.data = data
  }
}
export class CreateActReqiredFieldError extends CustomResponseError<object> {
  data: CreateActReqiredFieldErrorData

  constructor(data: CreateActReqiredFieldErrorData, message = '') {
    super(message)
    this.name = 'CreateActError'
    this.data = data
  }
}

export class ActCompensationsError extends CustomResponseError<object> {
  data: ActCompensationsErrorData

  constructor(data: ActCompensationsErrorData, message = '') {
    super(message)
    this.name = 'ActCompensationsError'
    this.data = data
  }
}
