import React from 'react'

import {
  NotificationItem,
  NotificationType,
} from '@/ui'

export const CompanyNotFound = () => (
  <NotificationItem
    body={(
      <>
        Компании с указанным ИНН нет в
        нашей системе, чтобы зарегистрировать компанию перейдите
        {' '}
        <a href="https://fsk.ru/about/tenders" target="_blank" rel="noreferrer">сюда</a>
        .
      </>
    )}
    type={NotificationType.Alert}
  />
)
