import { attach } from 'effector'
import { createForm } from 'effector-forms'

import {
  AvansTableItem,
  Summ,
  fillTableReqFx,
  getAvansSumsReqFx,
  getPaymentAvansTableReqFx,
  putAvansItemReqFx,
} from '@/dal'

import { createSingleEffect } from '@/lib/createSingleEffect'
import { AvansInputChangeParams } from './types'
import { d } from './domain'
import { PaymentGate } from './public'

export const $disabledRowIds = d.store<AvansTableItem['id'][]>([])

export const onAvansInputChanged = d.event<AvansInputChangeParams>()
export const onMaxValueError = d.event<void>()

export const $avansTableItems = d.store<AvansTableItem[] | null>(null)
export const $sums = d.store<Summ|null>(null)

export const $isFilled = d.store<boolean>(false)
export const onFillTable = d.event<boolean>()

export const workFilters = createForm({
  domain: d,
  fields: {
    showOnlyFilled: {
      init: false,
    },
    availableAvans: {
      init: false,
    },
  },
})

export const updateTableItem = d.event<AvansTableItem>()

export const putAvansItemFx = attach({
  effect: putAvansItemReqFx,
})

export const {
  requestFx: getPaymentAvansTableFx,
} = createSingleEffect(
  getPaymentAvansTableReqFx,
  {
    abortEvents: [PaymentGate.close],
  },
)

export const {
  requestFx: getAvansSumsFx,
} = createSingleEffect(
  getAvansSumsReqFx,
  {
    abortEvents: [PaymentGate.close],
  },
)

export const fillTableFx = attach({
  effect: fillTableReqFx,
})


export const $isPutAvansItem = putAvansItemFx.pending
