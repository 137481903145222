
import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import {
  NewTextArea,
  Radio,
  TextL,
} from '@/ui'
import { StepChoice, StepType } from '@/dal'

import { QuestionsProgress } from './QuestionsProgress'
import { StepActions } from './StepActions'
import {
  ScrollableContainer,
} from '../parts'
import {
  $currentStep,
  $currentStepForm,
  changeStepFormField,
} from '../../model/private'

export const SingleChoiceStep = React.memo(() => {
  const [step, stepForm] = useUnit([$currentStep, $currentStepForm])

  if (!step || !stepForm || stepForm.type !== StepType.SingleChoice) return null

  const onRadioChange = (id: StepChoice['id']) => {
    changeStepFormField({ stepId: step.id, choiceId: id })
  }

  const onTextareaChange = (id: StepChoice['id'], text: string) => {
    changeStepFormField({ stepId: step.id, choiceId: id, text })
  }

  return (
    <>
      <QuestionsProgress />
      <ScrollableContainer>
        <QuestionWrapper>
          {step.title}
        </QuestionWrapper>

        <Fields>
          {stepForm.choices.map(({
            id, title, can_answer_with_text, answer_textarea_placeholder_text, isChecked, text,
          }) => (
            <React.Fragment key={id}>
              <Radio
                label={title}
                value={id}
                isChecked={isChecked}
                dataTestId="survey-radio"
                onChange={() => onRadioChange(id)}
              />
              {can_answer_with_text && (
                <NewTextArea
                  value={text}
                  placeholder={answer_textarea_placeholder_text ?? ''}
                  disabled={!isChecked}
                  height={100}
                  onChange={(value) => onTextareaChange(id, value)}
                  dataTestId="survey-textarea"
                  autoGrow
                />
              )}
            </React.Fragment>
          ))}
        </Fields>
      </ScrollableContainer>

      <StepActions />
    </>
  )
})

const QuestionWrapper = styled.div`
  ${TextL}
`

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 12px;
`
