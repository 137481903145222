import React from 'react'
import styled from 'styled-components'

import { getStatusName } from '@/lib/getStatusName'

import { WINDOW_WIDTH, themeVar } from './theming'
import { H2TitleCSS, MediumLightTextCSS, SmallTextCSS } from './Typography'

type Props = {
  name: string,
  objectName: string
  status: number,
}

export const EstimateListHeader = ({ name, objectName, status }: Props) => (
  <Header>
    <Texts>
      <ModalWrapper>
        <NameWrapper>{name}</NameWrapper>
        <ModalStatusText>{getStatusName(status)}</ModalStatusText>
      </ModalWrapper>
      <ObjectNameWrapper>{objectName}</ObjectNameWrapper>
    </Texts>
    <Status>
      <StatusNameText>Статус</StatusNameText>
      <StatusText>{getStatusName(status)}</StatusText>
    </Status>
  </Header>
)

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`

const Status = styled.div`
  text-align: right;
  @media (max-width: ${WINDOW_WIDTH.smallDesktop - 1}px) {
    display: none;
  }
`
const Texts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`

const NameWrapper = styled.span`
  ${H2TitleCSS}
`

const ObjectNameWrapper = styled.span`
  ${MediumLightTextCSS}
  margin-top: 10px;
  color: ${themeVar('textGray')};
`

const StatusNameText = styled.span`
  ${MediumLightTextCSS}
  color: ${themeVar('textGray')};
`

const StatusText = styled.div`
  ${SmallTextCSS}
  white-space: nowrap;
  color: ${themeVar('badgeTextColor')};
  padding: 2px 6px;
  border-radius: 9px;
  background-color: ${themeVar('badgeBgColor')};
  margin-top: 8px;
`

const ModalStatusText = styled(StatusText)`
  margin: 0 0 0 10px;
  display: none;

  @media (max-width: ${WINDOW_WIDTH.smallDesktop - 1}px) {
    display: block;
  }
`
