import React from 'react'

import { WorkListHeader } from './WorkListHeader'
import { WorkListBody } from './WorkListBody'

export const WorkList = () => (
  <>
    <WorkListHeader />
    <WorkListBody />
  </>
)
