import React from 'react'
import styled from 'styled-components'

import { themeVar } from './theming'

type Props = {
  value?: number
  onClick: (value: number) => void
  maxValue?: number
  dataTestId: string
}

export const GoToPage = ({
  value, onClick, maxValue, dataTestId,
}: Props) => {
  const [innerValue, setInnerValue] = React.useState<number>(value || 1)

  const handleSetValue = (v: string) => {
    let val = Number(v)
    if (maxValue && val > maxValue) val = maxValue
    setInnerValue(val)
  }

  const handleKeyPress = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        onClick(innerValue)
      }
    },
    [innerValue, onClick],
  )

  return (
    <Container>
      <InputWrapper>
        <Input
          type="number"
          min={1}
          max={maxValue}
          placeholder="1"
          value={innerValue}
          onKeyPress={handleKeyPress}
          onChange={(e) => handleSetValue(e.target.value)}
          data-testid={`${dataTestId}input-goto-page`}
        />
      </InputWrapper>
      <Button onClick={() => onClick(innerValue)}>Перейти</Button>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 45px;
  box-sizing: border-box;
  border: 1px solid ${themeVar('line')};
`

const InputWrapper = styled.div`
  border-right: 1px solid ${themeVar('line')};
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: text;
  flex: 0.4 0;
`

const Input = styled.input`
  background: none;
  height: 24px;
  border: none;
  font-weight: 300;
  font-size: 14px;
  font-family: ${themeVar('baseFont')};
  width: 100%;

  &::placeholder {
    color: ${themeVar('gray4')};
  }

  &:focus {
    outline: none;
  }
`

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.6 0;

  font-size: 14px;
  color: ${themeVar('textGray')};
  background-color: ${themeVar('gray5')};

  padding: 0 16px;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
`
