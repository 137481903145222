import { DocumentAlias } from '@/dal'
import { RouterPaths } from '@/features/app/model'

export const getRouterPathByDocumentAlias = (documentAlias: DocumentAlias) => {
  switch (documentAlias) {
    case DocumentAlias.ACT: {
      return RouterPaths.ActPage
    }
    case DocumentAlias.BUDGET: {
      return RouterPaths.PlanPage
    }
    case DocumentAlias.PAYMENT: {
      return RouterPaths.Payment
    }
    case DocumentAlias.CONTRACT: {
      return RouterPaths.Administrative
    }
    case DocumentAlias.ADDITIONAL_AGREEMENT: {
      return RouterPaths.AdditionalAgreement
    }
    case DocumentAlias.KOR_DOC: {
      return RouterPaths.EstimateCorrection
    }
    case DocumentAlias.COMPENSATION_DOCUMENT: {
      return RouterPaths.Compensation
    }
    case DocumentAlias.ESTIMATE_LOT: {
      return RouterPaths.EstimateLots
    }
    default: return null
  }
}
