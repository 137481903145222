import { AdministrativeAppealItemType } from '@/dal'

export const getAppealDocInfo = (item: AdministrativeAppealItemType) => {
  return item.related_document_id
    ? {
      projectName: item.related_document_project_name,
      documentId: item.related_document_id,
      documentSum: item.related_document_sum,
      documentName: item.related_document_name,
      documentType: item.related_document_type,
    }
    : null
}
