import React from 'react'
import { Link } from 'react-router-dom'

import { RouterPaths, SubSystemId } from '@/features/app/model'

import { MessageDot, Wrapper } from './Styles'

type Props = {
  link: RouterPaths
  name: string
  hasAction: boolean
  isActive: boolean
  subsystemId: SubSystemId
  handleClick: () => void
}

export const LinkItem = ({
  handleClick,
  hasAction,
  link,
  subsystemId,
  isActive,
  name,
}: Props) => {
  return (
    <Link to={link} onClick={handleClick}>
      <Wrapper
        data-testid={`a-menu-item-${subsystemId}`}
        isActive={isActive}
        isInDevelopment={false}
      >
        {name}
        {hasAction && <MessageDot />}
      </Wrapper>
    </Link>
  )
}

