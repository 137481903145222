import React from 'react'
import styled, { css } from 'styled-components'

import { DocumentStatuses } from '@/dal'
import { getStatusName } from '@/lib/getStatusName'

import { BaseTheme } from './theming/themes'
import { MediumTextCSS } from './Typography'
import { themeVar } from './theming'

type Props = {
  status: DocumentStatuses,
  textColor?: keyof BaseTheme
  backColor?: keyof BaseTheme
}

export const DocumentStatusLegend = ({
  backColor = 'orange5',
  status,
  textColor = 'main',
}: Props) => {
  const resolvedStatus = React.useMemo(() => getStatusName(status), [status])
  return (
    <Legend backColor={backColor} textColor={textColor}>
      {resolvedStatus}
    </Legend>
  )
}

export const Legend = styled.span<{
  textColor: keyof BaseTheme,
  backColor: keyof BaseTheme
}>`
  ${MediumTextCSS}
  width: max-content;
  padding: 3px 6px;
  border-radius: 15px;
  ${({ textColor, backColor }) => css`
    color: ${themeVar(textColor)};
    background-color: ${themeVar(backColor)};
  `}
`
