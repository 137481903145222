import React from 'react'
import styled from 'styled-components'
import { useGate, useUnit } from 'effector-react'
import { useRouteMatch } from 'react-router-dom'
import Scrollbars from 'react-custom-scrollbars-2'

import { DocumentAlias } from '@/dal'
import { ContentContainer, Loader, LoaderOverlay } from '@/ui'
import {
  $compensationDocsLink,
  $compensationStatus,
  $isCompensationPending,
  CompensationDocumentGate,
} from '@/features/compensation/model'
import {
  CompensationHeader,
  CompensationInfo,
  CompensationStatusCard,
  CompensationAttachments,
  ApprovingModal,
} from '@/features/compensation'
import { DocumentFilesButton } from '@/features/document-files'

export const CompensationPage = () => {
  const router = useRouteMatch<{ id: string }>()

  const [status, docsLink, isPending] = useUnit([
    $compensationStatus,
    $compensationDocsLink,
    $isCompensationPending,
  ])

  const docId = parseInt(router.params.id, 10)

  useGate(CompensationDocumentGate, { id: docId })

  if (isPending) return <Loader />

  return (
    <>
      <Scrollbars>
        <ContentContainer isAutoHeight>
          <Wrapper>
            <CompensationHeader />

            <Row>
              <Col>
                <CompensationStatusCard />
                <DocumentFilesButton
                  id={docId}
                  docType={DocumentAlias.COMPENSATION_DOCUMENT}
                  downloadLink={docsLink ?? undefined}
                  status={status ?? undefined}
                  buttonLabel="Скачать документы"
                />
                <CompensationAttachments />
              </Col>

              <Col>
                <CompensationInfo />
              </Col>
            </Row>
          </Wrapper>
        </ContentContainer>
      </Scrollbars>

      <LoaderOverlay isShown={isPending} position="fixed" />
      <ApprovingModal />
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const Row = styled.div`
  display: flex;
  gap: 16px;
`

const Col = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 0;
`
