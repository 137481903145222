import styled from 'styled-components'
import React from 'react'

import {
  NewInput,
  NewTextArea,
  NotificationItem,
  NotificationType,
  NumberInput,
  TextM,
  palette,
} from '@/ui'
import { NO_DATA } from '@/features/app/model/const'

type Props = {
  label: string
  required?: boolean
  inputType?: 'textArea' | 'number'
  placeholder?: string
  dataTestId: string
  disabled?: boolean
  error?: string
  format?: string
  isAddress?: boolean
  allowEmptyFormatting?: boolean
  onChange: (val: string) => void
  onClear?: () => void
  value: string
}

export const EditDetailsField = (props: Props) => {
  const {
    dataTestId,
    label,
    onChange,
    value,
    inputType,
    required,
    onClear,
    placeholder,
    disabled,
    error,
    format,
    allowEmptyFormatting,
    isAddress,
  } = props

  const Input = React.useMemo(() => {
    if (inputType === 'textArea') {
      return (
        <NewTextArea
          value={value}
          onChange={onChange}
          error={error}
          placeholder={placeholder}
          height={100}
          dataTestId={dataTestId}
          disabled={disabled}
        />
      )
    }

    if (inputType === 'number') {
      return (
        <NumberInput
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onClear={onClear}
          error={error}
          size="L"
          format={format}
          mask="_"
          dataTestId={dataTestId}
          disabled={disabled}
          allowEmptyFormatting={allowEmptyFormatting}
          letterSpacing="0.75px"
        />
      )
    }

    return (
      <NewInput
        value={value ?? NO_DATA}
        onChange={onChange}
        placeholder={placeholder}
        error={error}
        size="L"
        dataTestId={dataTestId}
        onClear={onClear}
        disabled={disabled}
      />
    )
  }, [props])

  return (
    <Wrapper>
      <Label>
        {label}
        {required && <Required>*</Required>}
      </Label>
      <InputWrapper>
        {Input}
      </InputWrapper>
      {isAddress && (
        <NotificationWrapper>
          <NotificationItem
            title="Пример заполнения юридического адреса"
            type={NotificationType.Info}
            body={(
              <>
                142155, Московская обл., г.Подольск, ул.Металлургов,
                дом №3, корпус 3 (здание ЦНТП), этаж 1, офис 2
              </>
            )}
          />
        </NotificationWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 16px;
`

const Label = styled.div`
  ${TextM}
  margin-top: 13px;
  flex: 0 0 140px;
`

const Required = styled.sup`
  color: ${palette.red100};
  padding-left: 4px;
`

const InputWrapper = styled.div`
  max-width: 344px;
  width: 100%;
`

const NotificationWrapper = styled.div`
  max-width: 400px;
`
