import React from 'react'

export const useCheckScrolled = <T extends HTMLElement>(deps: unknown[] = []) => {
  const containerRef: React.MutableRefObject<T | null> = React.useRef(null)

  const [isScrolled, setIsScrolled] = React.useState({ vertical: false, horizontal: false })

  const handleScroll = () => {
    const { current } = containerRef
    if (!current) return

    const vertical = current.scrollTop > 1
    const horizontal = current.scrollLeft > 1

    if (vertical !== isScrolled.vertical || horizontal !== isScrolled.horizontal) {
      setIsScrolled({ vertical, horizontal })
    }
  }

  React.useEffect(() => {
    if (!containerRef.current) return
    containerRef.current.addEventListener('scroll', handleScroll)

    return () => {
      containerRef.current?.removeEventListener('scroll', handleScroll)
    }
  }, [isScrolled, ...deps])

  return {
    isScrolled,
    containerRef,
  }
}
