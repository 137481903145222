import React from 'react'

import { NotificationItem, NotificationType } from '@/ui'

export const AccreditationFailed = () => (
  <NotificationItem
    body={(
      <>
        Сожалеем, ваша компания не прошла аккредитацию.
        Без нее мы не можем предоставить доступ в личный кабинет.
        Заново подать документы на прохождение аккредитации можно на
        {' '}
        <a href="https://fsk.ru/about/tenders" target="_blank" rel="noreferrer">сайте</a>
        .
      </>
    )}
    type={NotificationType.Alert}
  />
)
