import React from 'react'
import styled, { css } from 'styled-components'

import { Icon2 } from '../icons'
import { palette } from '../palette'

type Props = {
  children: React.ReactNode
  headSlot: React.ReactNode
  isActive: boolean
  onClick: () => void
}

export const AccordionV2 = ({
  children,
  headSlot,
  isActive,
  onClick,
}: Props) => (
  <Wrapper>
    <AccordionHeadContainer isActive={isActive} onClick={onClick}>
      <AccordionHead>
        {headSlot}
      </AccordionHead>
      <IconWrapper isActive={isActive}>
        <Icon2
          icon={isActive ? 'arrowUp' : 'arrowDown'}
          size={16}
        />
      </IconWrapper>
    </AccordionHeadContainer>

    <AccordionContent isHidden={!isActive}>
      {children}
    </AccordionContent>
  </Wrapper>
)

const Wrapper = styled.div`
  width: 100%;
`

const IconWrapper = styled.div<{isActive: boolean}>`
  display: flex;
  color: ${({ isActive }) => (isActive ? palette.accent100 : palette.grey60)};
  transition: color 0.15s linear;
`

const AccordionHeadContainer = styled.div<{isActive: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 18px;

  min-height: 50px;
  padding: 9px 20px;
  border: 1px solid ${palette.grey40};
  border-radius: 20px;
  background-color: ${palette.white};
  color: ${palette.grey90};
  transition: 0.15s linear;
  transition-property: border-color, color;
  cursor: pointer;

  ${({ isActive }) => isActive && css`
    border-color: ${palette.accent100};
    color: ${palette.accent100};
  `}

  @media (hover: hover) {
    &:hover {
      border-color: ${palette.accent100};
      color: ${palette.accent100};

      ${IconWrapper} {
        color: ${palette.accent100};
      }
    }
  }
`

const AccordionHead = styled.div`
  flex-grow: 1;
`

const AccordionContent = styled.div<{isHidden: boolean}>`
  ${({ isHidden }) => isHidden && css`
    display: none;
  `}
`
