import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { HeadL, palette } from '@/ui'

import {
  ActDataForm, PaymentOrders,
} from '../containers'
import { $isDraft } from '../../../model'

export const ActDataBlock = React.memo(() => {
  const isDraft = useUnit($isDraft)

  return (
    <Wrapper>
      <UpperBlockWrapper>
        <UpperBlock>
          <Header>
            {isDraft ? 'Заполните данные' : 'Данные выполнения'}
          </Header>
          <ActDataForm />
          <PaymentOrders />
        </UpperBlock>
      </UpperBlockWrapper>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const UpperBlockWrapper = styled.div`
  border-radius: 20px;
  overflow: hidden;
`

const UpperBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px;
  border-radius: 20px;
  background-color: ${palette.white};
`

const Header = styled.header`
  ${HeadL}
  margin-bottom: 4px;
`
