import { combine } from 'effector'

import { $authorizedUserData } from '@/features/login/model'
import { ContractDirectionItem } from '@/dal'

import { d } from './domain'

export const $companyList = combine(
  $authorizedUserData,
  (data) => data?.company ?? null,
)

export const $contractDirections = d.store<ContractDirectionItem[]>([])
