import React from 'react'

import { formatDiff } from '@/lib/formatDiff'

import { Cell } from './Cell'

type Props = {
  children: string
}

export const DiffCell = ({ children }: Props) => (
  <Cell color="accent100" textSize="S" isBold>
    {formatDiff(children)}
  </Cell>
)
