import React from 'react'
import styled from 'styled-components'

import { palette, TextMLight, TextSLight } from '@/ui'

export const SelectAppealFooter = () => {
  return (
    <Wrapper>
      <Col>
        <PholeLabel>
          Телефон технической поддержки
        </PholeLabel>
        <Phone>
          +7 (495) 660-15-55, доб.: 4444, нажмите 3
        </Phone>
      </Col>

      <RightText>
        Отвечаем в рабочие дни с 9:00 до 18:00
      </RightText>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const PholeLabel = styled.p`
  ${TextSLight}
  color: ${palette.grey70};
`

const Phone = styled.p`
  ${TextMLight}
`

const RightText = styled.p`
  ${TextMLight}
  color: ${palette.grey70};
`
