import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { NewTextButton, TextM } from '@/ui'
import { RouterPaths } from '@/features/app/model'

import { $paymentInfo } from '../../../model/private'

export const RelatedActDoc = React.memo(() => {
  const info = useUnit($paymentInfo)

  const actDoc = info?.basic_document

  if (!actDoc) return null

  return (
    <Wrapper>
      <Title>
        Документ основание
      </Title>

      <NewTextButton
        label={actDoc.name}
        url={`${RouterPaths.ActPage}/${actDoc.id}`}
        textStyle="ML"
        dataTestId="related-act-link"
      />
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const Title = styled.header`
  ${TextM}
  display: block;
`
