import React from 'react'
import { useUnit } from 'effector-react'

import { numberWithSpaces } from '@/lib/number-represent'

import { Cell, TableRow } from '../parts'
import { $documentInfo, $isDraft } from '../../../model'

export const OtherDeductions = React.memo(() => {
  const info = useUnit($documentInfo)
  const isDraft = useUnit($isDraft)

  if (!info) return null

  const { deductions } = info.ks3
  const hasDeductionItems = Boolean(deductions?.items?.length)

  if (!hasDeductionItems) return null

  const approveColor = isDraft ? 'grey70' : 'grey90'

  return (
    <>
      {deductions?.items.map(({
        name, percent, declared, approved,
      }) => (
        <TableRow key={name}>
          <Cell isFill>{name}</Cell>
          {percent && (
            <Cell color="grey90">
              {percent}
              %
            </Cell>
          )}
          <Cell color="grey90">{numberWithSpaces(declared)}</Cell>
          <Cell color={approveColor}>{numberWithSpaces(approved)}</Cell>
        </TableRow>
      ))}
    </>
  )
})
