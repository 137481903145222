import { createGate } from 'effector-react'

import { EstimateCorrectionType } from '@/dal'

import { TYPE_FILTER_ITEMS } from './const'
import { d } from './domain'

export const $searchInputValue = d.store<string>('')
export const changeSearch = d.event<string>()
export const clearSearch = d.event<void>()
export const acceptSearch = d.event<void>()

export const $types = d.store(TYPE_FILTER_ITEMS)

export const $acceptedTypes = d.store<EstimateCorrectionType[]>([])
export const $acceptedTypesCount = $acceptedTypes.map((types) => types.length)
export const setAcceptedTypes = d.event<EstimateCorrectionType[]>()

export const TableFiltersGate = createGate('table-filters-gate')
