import React, { memo } from 'react'
import { useUnit } from 'effector-react'

import { PendingOverlay } from '@/ui'

import { $counter, $totalItems, updateMultipleKorDocItemsFx } from '../../../model/edit-multiple-items.private'

export const MultipleEditPendingOverlay = memo(() => {
  const [isShown, successfulItemCount, totalItems] = useUnit([
    updateMultipleKorDocItemsFx.pending,
    $counter,
    $totalItems,
  ])

  return (
    <PendingOverlay
      isShown={isShown}
      successfulItemCount={successfulItemCount}
      totalItems={totalItems}
    />
  )
})
