import { createGate } from 'effector-react'

import {
  DocumentItem,
  DownloadDocumentFilePayload,
} from '@/dal'

import { d } from './domain'

export const $downloadInfo = d.store<DownloadDocumentFilePayload[]>([])

export const $documentList = d.store<DocumentItem[] | null>(null)
export const resetDocumentList = d.event()

export const onSuccessDeleteDocumentFlow = d.event()

export const DocumentFlowListGate = createGate('DocumentFlowListGate')
