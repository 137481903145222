import React, { FormEvent, memo } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import {
  HeadXL, ModalLayoutV2, NewButton, NewDropDownInput,
} from '@/ui'
import { noop } from '@/lib/noop'

import {
  $contragentList,
  $isLotCreationModalOpen,
  $selectedContragentId,
  closeLotCreationModal,
  createEstimateLot,
  createEstimateLotFx,
  selectContragent,
} from '../../model/actions.private'

export const LotCreationModal = memo(() => {
  const [isOpen, isPending, contragentList, selectedContragentId] = useUnit([
    $isLotCreationModalOpen,
    createEstimateLotFx.pending,
    $contragentList,
    $selectedContragentId,
  ])

  if (!isOpen) return null

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (selectedContragentId) {
      createEstimateLot(selectedContragentId)
    }
  }

  return (
    <ModalLayoutV2
      closeModal={isPending ? noop : closeLotCreationModal}
    >
      <Wrapper>
        <Title>
          Выберите компанию
        </Title>
        <Form onSubmit={handleSubmit}>
          <NewDropDownInput
            options={contragentList}
            selectedId={selectedContragentId}
            placeholder="Выберите компанию"
            dataTestId="estimate-lot-creation-contragents-select"
            hasClear
            hasSearch
            onOptionClick={(id) => selectContragent(id)}
          />
          <Buttons>
            <NewButton
              label="Выбрать"
              type="submit"
              isDisabled={!selectedContragentId}
              isPending={isPending}
              dataTestId="create-estimate-lot-btn"
              isFill
            />
            <NewButton
              label="Отмена"
              isDisabled={isPending}
              buttonType="grey"
              dataTestId="close-lot-creation-modal-btn"
              isFill
              onClick={closeLotCreationModal}
            />
          </Buttons>
        </Form>
      </Wrapper>
    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  width: 500px;
  padding: 32px;
`

const Title = styled.div`
  ${HeadXL}
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const Buttons = styled.div`
  display: flex;
  gap: 16px;
`
