import React from 'react'
import { useUnit } from 'effector-react'

import {
  DocumentHeader,
} from '@/ui'
import { RouterPaths } from '@/features/app/model'
import { $documentStatus, KOR_DOC_TABS } from '@/features/estimate-correction/shared-model'
import { DocumentStatuses, KORDOC_INSTRUCTION_URL } from '@/dal'

import {
  $headDocumentInfo,
  $isHeaderEditingBlocked,
  cancelApproveFx,
  ckeckValidationBeforeApproveFx,
  onApproveButton,
  onCancelApprove,
  onCopy,
  onDeleteButton,
  onReject,
} from '../../model/private'
import { $activeTabId, changeActiveTab } from '../../model'

export const EstimateHeader = React.memo(() => {
  const [info, status, activeTab] = useUnit([$headDocumentInfo, $documentStatus, $activeTabId])
  const isPending = useUnit(ckeckValidationBeforeApproveFx.pending)
  const isPendingCancel = useUnit(cancelApproveFx.pending)
  const isBlocked = useUnit($isHeaderEditingBlocked)

  if (!info) return null

  const {
    can_approve,
    can_cancel_approving,
    can_copy,
    can_delete,
    can_reject_approving,
    can_send_to_approving,
    is_second_approving,
    is_document_changed,
  } = info.feature

  const isDraft = status === DocumentStatuses.Draft
  const showApproveButton = can_approve || can_send_to_approving || isDraft
  const approveTitle = can_send_to_approving || isDraft ? 'Отправить на согласование' : 'Согласовать'

  return (
    <DocumentHeader
      documentName={info.name}
      lastUpdate={info.lastUpdate}
      link={RouterPaths.Administrative}
      canCancelApproving={can_cancel_approving}
      canCopy={can_copy}
      canDelete={can_delete && !isBlocked}
      approveTitle={approveTitle}
      showApproveButton={showApproveButton}
      isPendingApprove={isPending}
      isPendingCancel={isPendingCancel}
      onApproveButton={onApproveButton}
      onCancelApprove={onCancelApprove}
      onDeleteButton={onDeleteButton}
      downloadInstructionURL={KORDOC_INSTRUCTION_URL}
      onCopy={onCopy}
      canRejectApproving={can_reject_approving}
      isSecondApproving={is_second_approving}
      approvementProcess={info.approvementProcess}
      onReject={onReject}
      disableApproveButton={!is_document_changed || isBlocked}
      tabs={KOR_DOC_TABS}
      activeTabId={activeTab}
      onChangeTab={changeActiveTab}
    />
  )
})
