import { LotRowType } from '@/dal'

export const ROW_TYPE_OPTIONS = [
  {
    id: LotRowType.Work,
    label: 'Работа',
  },
  {
    id: LotRowType.Material,
    label: 'Материал',
  },
]
