import {
  Placement,
  Strategy,
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import React from 'react'

type Params = {
  strategy?: Strategy
  placement?: Placement
  tooltipOffset?: number
  tooltipCrossOffset?: number
  sameComponentWidth?: boolean
  hasCloseDelay?: boolean
  onTooltipClose?: () => void
  onTooltipOpen?: () => void
  onOpenChange?: (val: boolean) => void
}

export const useTooltip = ({
  onTooltipClose,
  onTooltipOpen,
  onOpenChange,
  placement = 'bottom',
  strategy = 'fixed',
  tooltipOffset = 0,
  tooltipCrossOffset = 0,
  sameComponentWidth,
  hasCloseDelay,
}: Params = {}) => {
  const arrowRef = React.useRef<SVGSVGElement>(null)
  const [isOpen, setIsOpen] = React.useState(false)
  const close = () => {
    onTooltipClose?.()
    onOpenChange?.(false)
    setIsOpen(false)
  }
  const {
    refs,
    floatingStyles,
    context,
  } = useFloating({
    open: isOpen,
    onOpenChange: (isOpen) => {
      if (isOpen) onTooltipOpen?.()
      else onTooltipClose?.()
      onOpenChange?.(isOpen)
      setIsOpen(isOpen)
    },
    middleware: [
      offset({
        mainAxis: tooltipOffset,
        crossAxis: tooltipCrossOffset,
      }),
      flip(),
      shift(),
      arrow({
        element: arrowRef,
      }),
      sameComponentWidth && size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
          })
        },
      }),
    ],
    placement,
    strategy,
    whileElementsMounted: autoUpdate,
  })

  const hover = useHover(context, {
    move: true,
    delay: {
      open: 100,
      close: hasCloseDelay ? 100 : 0,
    },
  })
  const role = useRole(context, { role: 'tooltip' })
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    role,
  ])

  const arrowProps = {
    ref: arrowRef,
    context,
  }

  return {
    refs,
    floatingStyles,
    getReferenceProps,
    getFloatingProps,
    isOpen,
    arrowProps,
    close,
  }
}
