import React from 'react'
import { NotificationItem, NotificationType } from '@/ui'

export const AccreditationInProccess = () => (
  <NotificationItem
    body={(
      <>
        Ваша компания проходит проверку на наличие действующей аккредитации.
        Дождитесь окончания процесса.
      </>
    )}
    type={NotificationType.Warning}
  />
)
