import React, { useEffect } from 'react'

import { CellNumberInput } from '@/ui'
import { Cell } from '@/features/estimate-lot/shared'

type Props = {
  value: string
  canEdit: boolean
  decimalScale?: number
  onChange: (val: string) => void
}

export const EditableCell = ({
  value, canEdit, decimalScale = 2, onChange,
}: Props) => {
  const [inputVal, setInputVal] = React.useState(value)

  useEffect(() => {
    setInputVal(value)
  }, [value])

  if (!canEdit) {
    return <Cell value={value} decimalScale={decimalScale} align="right" />
  }

  const onSubmit = () => {
    if (inputVal === value) return
    onChange(inputVal)
  }

  return (
    <Cell align="right" padding={0} borderBottomColor="accent80">
      <CellNumberInput
        inputValue={inputVal}
        customIcon="edit"
        textAlign="right"
        displayValue={value}
        padding="12px 6px 12px 12px"
        decimalScale={decimalScale}
        verticalAlign="center"
        popupZIndex={3}
        sameComponentHeight
        onChange={setInputVal}
        onEnter={onSubmit}
        onBlur={onSubmit}
      />
    </Cell>
  )
}
