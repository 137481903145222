import React, { Key } from 'react'
import styled from 'styled-components'

import {
  DefaultButton,
  palette,
  TextL,
  TextMLight,
} from '@/ui'

type Props<T> = {
  items: {
    id: T
    label: string
  }[]
  title: string
  onClick: (id: NoInfer<T>) => void
}

export const AppealOptions = <T extends Key>({ items, title, onClick }: Props<T>) => {
  return (
    <Wrapper>
      <Title>
        {title}
      </Title>

      <OptionsContainer>
        {items.map((item) => (
          <SelectButton key={item.id} onClick={() => onClick(item.id)}>
            {item.label}
          </SelectButton>
        ))}
      </OptionsContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Title = styled.header`
  ${TextL}
  color: ${palette.grey60};
`

const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

const SelectButton = styled(DefaultButton)`
  ${TextMLight}
  padding: 8px 12px;
  border: 1px solid ${palette.grey40};
  border-radius: 12px;
  transition: background-color 0.15s linear;

  @media (hover: hover) {
    &:hover {
      background-color: ${palette.grey40};
    }
  }
`
