import React from 'react'
import styled from 'styled-components'
import { useForm } from 'effector-forms'
import { useUnit } from 'effector-react'

import {
  HeadL,
  NewButton,
  RadioButtons,
  TextAreaField,
} from '@/ui'

import {
  $isWithComment,
  $tabItems,
  MAX_COMMENT_LENGHT,
  approveDocumentFx,
  approveForm,
  changeWithComment,
  closeModal,
} from '../../model/private'
import { WithComment } from '../../model/types'

export const Approving = React.memo(() => {
  const isWithComment = useUnit($isWithComment)
  const tabsItem = useUnit($tabItems)
  const isPending = useUnit(approveDocumentFx.pending)

  const { fields, submit, isValid } = useForm(approveForm)

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    submit()
  }

  return (
    <>
      <Header>
        Согласование документа
      </Header>
      <RadioButtons
        items={tabsItem}
        checkedVal={isWithComment}
        onChange={changeWithComment}
        radioSetName="isComment"
      />
      <Form onSubmit={handleSubmit}>
        {isWithComment === WithComment.Yes && (
          <TextAreaField
            dataTestId="approve-comment-input"
            label="Комментарий"
            onChange={fields.comment.onChange}
            autoGrow
            value={fields.comment.value}
            currentSymbolCount={fields.comment.value.length}
            maxSymbolCount={MAX_COMMENT_LENGHT}
            required
            error={fields.comment.firstError?.errorText}
            height={100}
            maxHeight={350}
            disabled={isPending}
            placeholder="Укажите причину несогласия с новыми условиями"
          />
        )}
        <Row>
          <NewButton
            label="Согласовать"
            type="submit"
            dataTestId="submit-approve-form"
            isDisabled={!isValid}
            isPending={isPending}
          />
          <NewButton
            label="Отменить"
            buttonType="grey"
            onClick={closeModal}
            dataTestId="close-approve-modal"
          />
        </Row>
      </Form>
    </>
  )
})

const Header = styled.div`
  ${HeadL}
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const Row = styled.div`
  display: flex;
  gap: 16px;
`
