import React from 'react'
import styled from 'styled-components'

import { formatDateString } from '@/lib/date'

import { InlineDot } from './InlineDot'

type Props = {
  start_date: string | Date
  end_date: string | Date
}

export const FormatPeriodDate = ({ start_date, end_date }: Props) => (
  <Wrapper>
    {formatDateString(start_date)}
    {' '}
    <InlineDot size={1} />
    {' '}
    {formatDateString(end_date)}
  </Wrapper>
)

const Wrapper = styled.span`
  display: inline-flex;
  align-items: center;
`
