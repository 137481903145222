import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { TextM, palette } from '@/ui'

import { $currentStepNumber, $stepsCount } from '../../model/private'

export const QuestionsProgress = React.memo(() => {
  const [currentStepNumber, stepsCount] = useUnit([$currentStepNumber, $stepsCount])

  return (
    <Wrapper>
      <TextWrapper>
        Вопрос
        {' '}
        {currentStepNumber}
      </TextWrapper>
      <Progress>
        {[...Array(stepsCount)].map((_, i) => (
          <ProgressLine key={i} isActive={currentStepNumber === i + 1} />
        ))}
      </Progress>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  position: sticky;
  top: 0;
  padding: 32px 32px 24px;
`

const TextWrapper = styled.p`
  ${TextM}
  margin-bottom: 6px;
  text-transform: uppercase;
`

const Progress = styled.div`
  display: flex;
  gap: 4px;
`

const ProgressLine = styled.div<{ isActive: boolean }>`
  height: 4px;
  border-radius: 99px;
  background-color: ${({ isActive }) => (isActive ? palette.accent80 : palette.accent10)};
  flex-grow: 1;
`
