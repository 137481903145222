export const getAuthorAndText = (str: string) => {
  const sepPosition = str.indexOf(':')
  if (sepPosition === -1) {
    return [null, str]
  }
  const splitted = str.split(':')
  const author = splitted.shift()
  const answer = splitted.join(':').trim()
  return [author, answer]
}
