import React, { memo, useCallback, useMemo } from 'react'
import { useStoreMap } from 'effector-react'

import { WorkTableItem as Item, LotRowType } from '@/dal'
import { DropDownList, NewIconButton } from '@/ui'
import { ButtonCell, Cell } from '@/features/estimate-lot/shared'
import { getRowTypeName } from '@/features/estimate-lot/model/helpers'

import {
  $editingRowId,
  $editingRows,
  changeEditingRow,
  deleteMaterialModal,
  distributeAmountsModal,
  onOptionClicked,
} from '../../model/private'
import { getActionsForItem } from '../../model/helpers'
import { EditingRow } from '../../model'
import { GridRow, EditableCell } from '../parts'

type Props = {
  item: Item
  isEditMode: boolean
  isFirstGroupOpen: boolean
}

export const WorkTableItem = memo(({ item, isEditMode, isFirstGroupOpen }: Props) => {
  const {
    id,
    name,
    description,
    row_type,
    unit,
    count,
    unit_price,
    total_cost,
    features: { can_delete: canDelete },
  } = item

  const {
    construction_object,
    construction_building,
    construction_section,
  } = item.morphology_elements

  const changedValues = useStoreMap({
    store: $editingRows,
    keys: [id],
    fn: (arr, [id]) => arr.find((row) => row.id === id) ?? null,
  })

  const isEditingRow = useStoreMap({
    store: $editingRowId,
    keys: [id],
    fn: (rowId, [id]) => rowId === id,
  })

  const actions = useMemo(() => (
    getActionsForItem({
      ...item,
      handleOptionClick: (type) => onOptionClicked({ item, type }),
    })
  ), [item])

  const ConstructionElementCell = useCallback(({ value }: { value: string }) => (
    <Cell value={value} valueType="string" align="left" hasLightGreyBackground />
  ), [])

  const EmptyCell = useCallback(() => (
    <Cell bgColor="grey10" />
  ), [])

  const onDelete = () => {
    deleteMaterialModal.open({ ...item, morphology: item.morphology_elements })
  }

  const onChange = (key: keyof Omit<EditingRow, 'id'>, val: string) => {
    changeEditingRow({ id, [key]: val })
  }

  const isAdditional = row_type === LotRowType.Additional
  const isEditing = isEditMode || isEditingRow

  return (
    <GridRow isEditMode={isEditMode} isFirstGroupOpen={isFirstGroupOpen}>
      <ConstructionElementCell value={construction_object} />
      <ConstructionElementCell value={construction_building} />
      <ConstructionElementCell value={construction_section} />
      <Cell value={name} valueType="string" align="left" hasRightDivider={!isFirstGroupOpen} />

      {isFirstGroupOpen && (
        <Cell
          value={description}
          valueType="string"
          align="left"
          hasLightGreyBackground
        />
      )}

      <Cell value={getRowTypeName(row_type)} valueType="string" />

      {isAdditional ? (
        <>
          <EmptyCell />
          <EmptyCell />
        </>
      ) : (
        <>
          <Cell value={unit.value} valueType="string" hasLightGreyBackground />
          <EditableCell
            value={changedValues?.count || count.value}
            decimalScale={4}
            canEdit={isEditing && count.can_edit}
            onChange={(val) => onChange('count', val)}
          />
        </>
      )}

      {isEditMode && (
        <ButtonCell
          icon="stars"
          color="accent100"
          hasLightGreyBackground
          onClick={() => distributeAmountsModal.open(item)}
        />
      )}

      {isAdditional ? (
        <EmptyCell />
      ) : (
        <EditableCell
          value={changedValues?.price || unit_price.value}
          canEdit={isEditing && unit_price.can_edit}
          onChange={(val) => onChange('price', val)}
        />
      )}

      <EditableCell
        value={changedValues?.total_cost || total_cost.value}
        canEdit={isEditing && total_cost.can_edit}
        onChange={(val) => onChange('total_cost', val)}
      />

      <Cell hasLightGreyBackground>
        {canDelete && (
          <NewIconButton
            icon="delete"
            disabled={isEditMode}
            color="grey60"
            hoverColor="grey70"
            dataTestId=""
            onClick={onDelete}
          />
        )}
      </Cell>

      <Cell hasLightGreyBackground>
        <DropDownList
          options={actions}
        >
          <NewIconButton
            icon="verticalDots"
            size={16}
            padding="10px"
            color="grey60"
            hoverBackgroundColor="accent20"
            hoverColor="accent100"
            borderRadius="50%"
            disabled={isEditMode}
            dataTestId=""
          />
        </DropDownList>
      </Cell>
    </GridRow>
  )
})
