import styled from 'styled-components'
import { BaseTheme } from './theming/themes'
import { themeVar } from './theming'

type StatusLegendProps = {
  color: keyof BaseTheme
}

export const StatusLegend = styled.div<StatusLegendProps>`
  width: 10px;
  height: 10px;
  margin-right: 4px;
  border-radius: 2px;
  background-color: ${({ color }) => themeVar(color)};
  display: flex;
  align-items: center;
  justify-content: center;
`
