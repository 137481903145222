import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { themeVar } from './theming'

type CellProps = {
  active?: boolean
  width?: string
  right?: boolean
  center?: boolean
  disableBorder?: boolean
  loading?: boolean
  direction?: 'row' | 'column'
  fontWeight?: number
  customStyles?: FlattenSimpleInterpolation
  noSvg?: boolean
  onClick?: () => void
}

export const Cell = styled.div<CellProps>`
  width: 100%;
  ${({ fontWeight = 500 }) => css`
    font-weight: ${fontWeight};
  `}

  text-align: left;
  font-size: 14px;
  padding: 8px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  border-right: 1px solid #cbcbcb;

  ${({ width }) => width
    && css`
      width: ${width};
    `}

  ${({ right }) => right
    && css`
      justify-content: flex-end;
    `}

  ${({ center }) => center
    && css`
      justify-content: center;
    `}

  ${({ loading = false }) => loading
    && css`
      background: linear-gradient(
        45deg,
        rgba(224, 224, 224, 0),
        rgba(224, 224, 224, 1),
        rgba(224, 224, 224, 0)
      )
      animation: table-cell-loading 1s linear infinite;
      background-size: 400% 400%;
    `}

  ${({ disableBorder }) => disableBorder
    && css`
      border: none;
    `}

  ${({ direction = 'row' }) => css`
    flex-direction: ${direction};

    ${() => direction === 'column'
      && css`
        justify-content: center;
        align-items: flex-start;
      `}
  `}

  ${({ customStyles }) => customStyles}

  ${({ onClick }) => onClick && css`
    cursor: pointer;
  `}
`

export const PriceCell = styled.div<CellProps>`
  width: 100%;
  font-weight: 500;
  font-size: 12px;

  text-transform: uppercase;
  text-align: left;

  border-right: 1px solid #cbcbcb;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  ${({ width }) => width
    && css`
      width: ${width};
    `}

  ${({ right }) => right
    && css`
      text-align: right;
    `}

  ${({ center }) => center
    && css`
      text-align: center;
    `}

  ${({ disableBorder }) => disableBorder
    && css`
      border: none;
    `}

  ${({ active }) => active
    && css`
      color: ${themeVar('main')};
      font-weight: 700;
    `}

  ${({ loading = false }) => loading
    && css`
      background: linear-gradient(
        45deg,
        rgba(224, 224, 224, 0),
        rgba(224, 224, 224, 1),
        rgba(224, 224, 224, 0)
      )
      animation: table-cell-loading 1s linear infinite;
      background-size: 400% 400%;
    `}

  &:hover {
    color: ${themeVar('gray2')};
    cursor: pointer;
  }

  ${({ customStyles }) => customStyles}
`

export const SubCellRow = styled.div<Pick<CellProps, 'active' | 'center'>>`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-top: 1px solid ${themeVar('line')};
  flex: 1;

  ${({ active }) => active
    && css`
      color: ${themeVar('main')};
    `}

  ${({ center }) => center
    && css`
      justify-content: center;
      align-items: center;
    `}
`
