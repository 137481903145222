import { AppealItem } from '@/dal'

import { d } from './domain'

export const setAppealIdToCopy = d.event<AppealItem['id']>()

export const copyAppeal = d.event<AppealItem>()

export const copyDocumentProblemAppeal = d.event<AppealItem>()
export const copyLkpProblemAppeal = d.event<AppealItem>()
