import { attach, combine } from 'effector'

import { EstimateTableRow, resetKorDocRowsReqFx } from '@/dal'

import { d } from './domain'
import { FlatResettingRows, ResettingRowsMap } from './types'
import { $isTreeTable } from './private'
import { getFlatResettingRowsData, getResettingRowsTreeData } from './helpers'

export const $isResetModeEnabled = d.store(false)
export const enableResetMode = d.event()
export const exitResetMode = d.event()

export const $flatResettingRows = d.store<FlatResettingRows>({})
export const $resettingRowsMap = d.store<ResettingRowsMap>({})
export const toggleResettingRow = d.event<{ id: EstimateTableRow['id'] }>()

export const $previousIsTree = d.store(false)
export const setPreviousIsTree = d.event<boolean>()

export const $resettingRows = combine(
  [$isTreeTable, $resettingRowsMap, $flatResettingRows],
  ([isTreeTable, treeMap, flatMap]) => (isTreeTable ? treeMap : flatMap),
)

export const $selectedRowsData = combine(
  [$isTreeTable, $resettingRowsMap, $flatResettingRows],
  ([isTreeTable, treeMap, flatMap]) => (
    isTreeTable
      ? getResettingRowsTreeData(treeMap)
      : getFlatResettingRowsData(flatMap)
  ),
)

export const $resettingRowsCount = $selectedRowsData.map(({ count }) => count)

export const $isResettingAll = d.store(false)
export const changeIsResettingAll = d.event<boolean>()

export const resetKorDocRows = d.event()

export const resetKorDocRowsFx = attach({
  effect: resetKorDocRowsReqFx,
})
