import React, { memo } from 'react'
import styled from 'styled-components'

import { palette, TextLLight } from '@/ui'
import { useUnit } from 'effector-react'
import { $isFirstGroupOpen, setIsFirstGroupOpen } from '../../model/private'

export const TableFilters = memo(() => {
  const [isFirstGroupOpen] = useUnit([$isFirstGroupOpen])

  return (
    <tr>
      <Wrapper>
        <Title onClick={() => setIsFirstGroupOpen(!isFirstGroupOpen)}>
          ФИЛЬТРЫ
        </Title>
      </Wrapper>
    </tr>
  )
})

const Wrapper = styled.td`
  height: 186px;
  background-color: ${palette.grey10};
  display: flex;
  align-items: center;
  justify-content: center;
`

const Title = styled.div`
  ${TextLLight}
  transform: rotate(-32deg);
  cursor: pointer;
`
