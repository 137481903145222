import React from 'react'
import { useUnit } from 'effector-react'
import styled from 'styled-components'

import { NewDivider } from '@/ui'
import { PaymentSubTypes } from '@/dal'
import { formatDateString } from '@/lib/date'
import { numberWithSpaces } from '@/lib/number-represent'

import { InfoItem } from '../../../shared-components'
import { $paymentInfo } from '../../../model/private'

export const DocInfo = React.memo(() => {
  const info = useUnit($paymentInfo)

  if (!info) return null

  const isPaymentAct = info.pay_type === PaymentSubTypes.ACT
  const isThirdPerson = info.pay_type === PaymentSubTypes.THIRD

  const billInfo = info.bill_details
  const thirdPersonInfo = info.third_person_info

  const outgoingMailDate = thirdPersonInfo?.outgoing_mail_date
    ? formatDateString(thirdPersonInfo.outgoing_mail_date)
    : '—'

  return (
    <>
      <NewDivider />

      <ColWrapper>
        {billInfo && (
          <RequisitesCol>
            {billInfo.number && (
              <InfoItem label="№ Счета на оплату" text={billInfo.number} />
            )}

            {billInfo.payment_date && (
              <InfoItem label="Дата счета на оплату" text={formatDateString(billInfo.payment_date)} />
            )}

            <InfoItem label="Дата зачета аванса" text={formatDateString(billInfo.avans_date)} />

            {billInfo.avans_sum && (
              <InfoItem label="Сумма аванса" text={`${numberWithSpaces(billInfo.avans_sum)} руб.`} />
            )}
          </RequisitesCol>
        )}

        {isThirdPerson && thirdPersonInfo && (
          <RequisitesCol>
            <InfoItem label="№ Исходящ. письма" text={thirdPersonInfo.outgoing_mail_number} />
            <InfoItem label="Дата исходящ. письма" text={outgoingMailDate} />
          </RequisitesCol>
        )}

        {isPaymentAct && (
          <RequisitesCol>
            {info.postpayment && (
              <InfoItem
                label="Постоплата"
                text={`${numberWithSpaces(info.postpayment)} руб.`}
              />
            )}
          </RequisitesCol>
        )}

        {info.plan_payment_date && (
          <InfoItem
            label="Плановая дата оплаты"
            text={formatDateString(info.plan_payment_date)}
            isHighlight
          />
        )}
      </ColWrapper>
    </>
  )
})

const ColWrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
`

const RequisitesCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
`
