import styled, { css } from 'styled-components'
import { ContractDirection } from '@/dal'

type Props = {
  hiddenFirstGroup: boolean
  actType: ContractDirection
}

export const TableGridWrapper = styled.div<Props>`
  display: grid;
  padding: 0;

  ${({ actType, hiddenFirstGroup }) => actType === ContractDirection.SMR && css`
    grid-template-columns: minmax(350px, 350fr) minmax(157px, 157fr)
      ${hiddenFirstGroup ? 'minmax(157px, 157fr)' : 'repeat(3, minmax(157px, 157fr))'}
      minmax(180px, 180fr) minmax(93px, 93fr) repeat(2, minmax(345px, 345fr));
  `}

  ${({ actType, hiddenFirstGroup }) => actType === ContractDirection.PDRD && css`
    grid-template-columns: minmax(354px, 1fr) 125px
      ${hiddenFirstGroup ? '125px' : 'repeat(3, 125px)'}
      repeat(2, 125px) 181px 195px 94px 149px 249px;
  `}
`
