import React from 'react'
import styled, { css } from 'styled-components'

import { NewInput } from './NewInput'
import {
  ActionOption,
  InputSize,
  Option,
  OptionSize,
} from './types'
import { useDropDownInput } from './useDropDownInput'
import { DropDownInputList } from './DropDownInputList'

type Props = {
  options: Option[] | null
  size?: InputSize
  optionSize?: OptionSize
  actionOptions?: ActionOption[] | null
  hasSearch?: boolean
  hasClear?: boolean
  placeholder?: string
  dataTestId: string,
  error?: string | null
  isDisabled?: boolean
  isLoading?: boolean
  defaultOptionText?: string
  isInvalid?: boolean
  isFill?: boolean
  minWidth?: string
  onToggle?: (val: boolean) => void
} & ({
  isMultiple?: false
  selectedId: Option['id'] | null
  // TODO лучше поменять нейминг
  onOptionClick: (id: Option['id'] | null) => void
} | {
  isMultiple: true
  selectedId: Option['id'][]
  onOptionClick: (id: Option['id'][]) => void
})

export const NewDropDownInput = ({
  options,
  size,
  optionSize,
  selectedId,
  hasSearch,
  hasClear = true,
  placeholder,
  dataTestId,
  error,
  isDisabled,
  isLoading,
  actionOptions,
  defaultOptionText,
  isInvalid,
  isMultiple,
  isFill,
  minWidth,
  onOptionClick,
  onToggle,
}: Props) => {
  const {
    activeLabel,
    floatingStyles,
    getFloatingProps,
    getReferenceProps,
    handleClear,
    handleInputChange,
    handleOptionClick,
    closeDropDown,
    isFocus,
    isOpen,
    refs,
    sortedItems,
    value,
    selectedOptions,
  } = useDropDownInput({
    onToggle,
    options,
    onOptionClick,
    selectedId,
    hasSearch,
    offsetNumber: 4,
    defaultOptionText,
    minWidth: minWidth ?? '300px',
    isMultiple,
  })

  return (
    <>
      <Wrapper
        ref={refs.setReference}
        {...getReferenceProps()}
        isFill={isFill}
      >
        <NewInput
          dataTestId={dataTestId}
          onChange={handleInputChange}
          value={isOpen && hasSearch ? value : activeLabel}
          placeholder={placeholder}
          readOnly={!hasSearch}
          error={isOpen ? null : error}
          disabled={isDisabled}
          size={size}
          type="text"
          isSelectAfterClear={isOpen}
          isSelect={isFocus}
          postfixIcon={isOpen ? 'arrowUp' : 'arrowDown'}
          onClear={hasClear ? handleClear : undefined}
          isInvalid={isInvalid || Boolean(error)}
        />
      </Wrapper>

      <DropDownInputList
        isDisabled={Boolean(isDisabled)}
        isLoading={Boolean(isLoading)}
        isOpen={isOpen}
        items={sortedItems}
        searchValue={value}
        size={optionSize}
        dataTestId={dataTestId}
        selectedId={isMultiple ? selectedOptions : selectedId}
        actionOptions={actionOptions}
        isMultiple={isMultiple}
        handleOptionClick={handleOptionClick}
        onCloseDropDown={closeDropDown}
        floatingStyles={floatingStyles}
        getFloatingProps={getFloatingProps}
        refs={refs}
      />
    </>
  )
}

const Wrapper = styled.div<{ isFill?: boolean }>`
  position: relative;

  ${({ isFill }) => isFill && css`
    width: 100%;
  `}
`
