import React, { FormEvent } from 'react'
import { useUnit } from 'effector-react'
import styled, { css } from 'styled-components'
import Scrollbars from 'react-custom-scrollbars-2'
import { useForm } from 'effector-forms'

import {
  MessageItem,
  DefaultButton,
  FileInput,
  Icon2,
  Loader,
  NewFileItem,
  NewIconButton,
  NewTextArea,
  TextL,
  palette,
  TextMLight,
  useDefaultDropzone,
} from '@/ui'

import { useScrollListToBottom } from '@/lib/hooks/useScrollListToBottom'
import { handleDeleteFileHelper } from '@/lib/handleDeleteFileHelper'
import { handleFileChangeHelper } from '@/lib/handleFileChangeHelper'
import { CreateAppealCommentsViewParams, RequiredAppealItemFields } from '../../types'

export const createAppealCommentsView = <T extends RequiredAppealItemFields, >({
  $comments,
  $isCommentsPending,
  $selectedItem,
  commentForm,
  $isMessageSending,
  resetSelectedItem,
}: CreateAppealCommentsViewParams<T>) => React.memo(() => {
    const comments = useUnit($comments)
    const isPending = useUnit($isCommentsPending)
    const item = useUnit($selectedItem)
    const isMessagePending = useUnit($isMessageSending)
    const refList = useScrollListToBottom([comments])

    const { fields, submit } = useForm(commentForm)

    const { getInputProps } = useDefaultDropzone()

    const handleSubmit = (e: FormEvent) => {
      e.preventDefault()
      if (isMessagePending) return
      submit()
    }

    const handleFileChange = (files: File[]) => {
      handleFileChangeHelper({
        onChange: fields.files.onChange,
        currValue: fields.files.value,
        newFiles: files,
      })
    }

    const handleDeleteFile = (fileName: File['name']) => {
      handleDeleteFileHelper({
        value: fields.files.value,
        fileName,
        onChange: fields.files.onChange,
      })
    }

    const isDisabled = (!fields.comment.value.trim() && fields.files.value.length === 0)
    || isMessagePending
    return (
      <Wrapper>
        <Header>
          <Title>
            {`${item?.request_number}`}
          </Title>
          <IconWrapper>
            <NewIconButton
              dataTestId={`close-comments-${item?.id}`}
              icon="cross"
              size={20}
              padding="6px"
              color="grey60"
              hoverColor="grey80"
              onClick={() => resetSelectedItem()}
            />
          </IconWrapper>

          {item?.project_name && (
            <DocumentInfo>
              <InfoName>Проект</InfoName>
              <InfoValue>{item?.project_name}</InfoValue>

              {item?.document_name && (
                <>
                  <InfoName>Документ</InfoName>
                  <InfoValue>{item?.document_name}</InfoValue>
                </>
              )}

              {item?.author && (
                <>
                  <InfoName>Автор</InfoName>
                  <InfoValue>{item?.author}</InfoValue>
                </>
              )}
            </DocumentInfo>
          )}
        </Header>

        <Scroll>
          <Scrollbars ref={refList}>
            <ScrollView>
              {isPending
                ? (
                  <Loader />
                )
                : comments?.map((comment, i, arr) => {
                  const prevItem = arr[i - 1]
                  return (
                    <MessageItem
                      key={comment.id}
                      author={comment.author}
                      body={comment.body}
                      files={comment.files}
                      date_time={comment.date_time}
                      isRight={comment.user_lkp}
                      prevMessageDate={prevItem?.date_time}
                    />
                  )
                },
                )}

            </ScrollView>
          </Scrollbars>
        </Scroll>

        {item?.can_send_message && (
          <Form onSubmit={handleSubmit}>
            {Boolean(fields.files.value.length) && (
              <AttachedFiles>
                {fields.files.value.map(({ name }) => (
                  <NewFileItem key={name} name={name} onDelete={handleDeleteFile} />
                ))}
              </AttachedFiles>
            )}
            <Row>
              <FileInput
                {...getInputProps()}
                multiple
                onFile={handleFileChange}
              >
                <AttachIcon>
                  <Icon2
                    icon="attach"
                    size={24}
                    data-testid={`attach-files-${item?.id}`}
                  />
                </AttachIcon>
              </FileInput>
              <NewTextArea
                placeholder="Введите сообщение..."
                dataTestId="send-comment-input"
                maxHeight={350}
                value={fields.comment.value}
                onChange={fields.comment.onChange}
                disabled={isMessagePending}
                height={42}
                autoGrow
              />
              <SubmitButton
                disabled={isDisabled}
                data-testid="send-comment"
                type="submit"
              >
                <Icon2
                  icon="sendIcon"
                  size={24}
                />
              </SubmitButton>
            </Row>
          </Form>
        )}
      </Wrapper>
    )
  })

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Header = styled.div`
  position: relative;
  padding: 32px 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const Title = styled.h4`
  ${TextL}
  display: inline-flex;
  align-items: center;
  gap: 6px;
  width: fit-content;

  ${({ onClick }) => onClick && css`
    cursor: pointer;
  `}
`

const Scroll = styled.div`
  flex-grow: 1;
  padding-bottom: 10px;
  height: 100%;
`

const IconWrapper = styled.div`
  position: absolute;
  z-index: 3;
  right: 12px;
  top: 12px;
`

const DocumentInfo = styled.div`
  display: grid;
  grid-template-columns: 88px 1fr;
  gap: 4px;
`

const InfoName = styled.p`
  ${TextMLight}
  color: ${palette.grey70};
`

const InfoValue = styled.p`
  ${TextMLight}
`

const ScrollView = styled.div`
  padding: 24px;
  padding-top: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Form = styled.form`
  padding: 16px 24px 20px;
  max-height: 350px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const AttachedFiles = styled.div`
  padding: 0 44px;
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const Row = styled.div`
  display: flex;
  align-items: flex-end;
  max-height: 100%;
`

const AttachIcon = styled.div`
  color: ${palette.grey60};
  padding: 8px;
  cursor: pointer;
  display: inline-flex;
  transition: color 0.15s linear;
  @media (hover: hover) {
    &:hover {
      color: ${palette.grey90};
    }
  }
`

const SubmitButton = styled(DefaultButton)`
  color: ${palette.accent100};
  padding: 8px;
  display: inline-flex;
  cursor: pointer;

  @media (hover: hover) {
    color: ${palette.accent90}
  }

  &:disabled {
    color: ${palette.accent40};
    cursor: not-allowed;
  }
`
