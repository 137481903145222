import { combine, sample } from 'effector'

import { createToast } from '@/features/toast-service/model'

import { EstimateDocId } from '@/dal'
import {
  $canSendSecondApprove,
  $documentId,
  $isSecondApproving,
  $isWithComment,
  approveDocumentFx,
  approveForm,
  changeWithComment,
  closeModal,
  secondApprovingForm,
} from './private'
import { openApproveDocumentModal, openSecondApprovingModal } from './public'
import { updateDocumentInfo } from '../../shared-model'
import { getTableData } from '../../estimate-table/model'
import { WithComment } from './types'

$documentId
  .on([openApproveDocumentModal, openSecondApprovingModal], (_, id) => id)
  .reset(closeModal)

$isSecondApproving
  .on(openSecondApprovingModal, () => true)
  .reset(closeModal)

$isWithComment
  .on(changeWithComment, (_, val) => val)
  .reset(closeModal)

sample({
  clock: approveDocumentFx.doneData,
  target: updateDocumentInfo,
})

sample({
  clock: approveDocumentFx.doneData,
  target: [closeModal, getTableData],
})

sample({
  clock: [$isWithComment.updates, closeModal],
  target: approveForm.reset,
})

sample({
  clock: approveForm.formValidated,
  source: {
    isWithComment: $isWithComment,
    id: $documentId,
  },
  filter: ({ id }) => Boolean(id),
  fn: ({ id, isWithComment }, { comment }) => ({
    id: id as EstimateDocId,
    comment: isWithComment === WithComment.Yes ? comment : undefined,
  }),
  target: approveDocumentFx,
})

sample({
  clock: secondApprovingForm.formValidated,
  source: $documentId,
  filter: combine($documentId, $canSendSecondApprove, (id, canSend) => Boolean(id && canSend)),
  fn: (id, files) => ({
    id: id as EstimateDocId,
    ds_text_file: files.dsFile,
    gfpr_file: files.gfprFile,
    gpr_file: files.gprFile,
  }),
  target: approveDocumentFx,
})

createToast({
  effect: approveDocumentFx,
  doneText: 'Согласовано',
})
