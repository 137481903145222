import { attach, combine } from 'effector'
import { createGate } from 'effector-react'

import { createSingleEffect } from '@/lib/createSingleEffect'
import { createPagination } from '@/features/shared/createPagination'

import {
  AbortableRequest,
  ActTableRow,
  GetActTableParams,
  GetFlatTableResponse,
  GetWorkFields,
  fillByRemainingReqFx,
  getActWorkFolderContentReqFx,
  getFlatTableActWorkReqFx,
  getTreeTableActWorkReqFx,
  putActWorkVolumeReqFx,
  uploadActTableTemplateReqFx,
  ActTableFolder,
} from '@/dal'
import { createUpdateTreeItemsFx } from '@/lib/tree'
import { getFlatTree } from '@/lib/tree/getFlatTree'
import { d } from './domain'
import { TreeType } from './types'

export const ActWorkGate = createGate('ActWorkGate')

export const $isTree = d.store(false)
export const toggleIsTree = d.event<void>()

export const getTableData = d.event<void>()

export const $tableRoot = d.store<ActTableRow[] | null>(null)
export const $tableTree = d.store<TreeType>({})

export const setRootUpdate = d.event<ActTableRow[] | null>()
export const setMapUpdate = d.event<TreeType>()
export const closeFolders = d.event<TreeType>()

export const $tableInfo = d.store<GetWorkFields | null>(null)
export const $tableWorkTotal = d.store<null | number>(null)

export const templateSelected = d.event<File>()

export const $flatItems = combine({
  root: $tableRoot,
  tree: $tableTree,
  isTree: $isTree,
}, getFlatTree<ActTableRow>)

export const $itemToFocus = d.store<ActTableRow['id'] | null>(null)
export const setItemToFocus = d.event<ActTableRow['id'] | null>()
export const resetItemToFocus = d.event()
export const onEnterSubmitItem = d.event<ActTableRow['id']>()

export const $hiddenCellGroup = d.store<boolean>(true)
export const toggleHiddenGroup = d.event<void>()

export const onWorkInputError = d.event<void>()
export const onChangeSMRActWork = d.event<{ workId: ActTableRow['id'], value: string }>()
export const onChangePIRActWork = d.event<{ workId: ActTableRow['id'], value: boolean }>()

export const onDownloadTemplate = d.event<File[]>()

export const onFillByRemaining = d.event<boolean>()

export const openFolder = d.event<ActTableFolder['id']>()
export const onCloseFolder = d.event<ActTableFolder['id']>()

export const sendActWorkVolumeFx = attach({
  effect: putActWorkVolumeReqFx,
})

export const fillByRemainingFx = attach({
  effect: fillByRemainingReqFx,
})

export const $pendingFolderIds = d.store<ActTableFolder['id'][]>([])

export const onLoadMore = d.event<void>()
export const {
  $hasMore,
  initEffect,
  paginationEffect,
} = createPagination<AbortableRequest<GetActTableParams>, GetFlatTableResponse>({
  fetchEffect: getFlatTableActWorkReqFx,
  limit: 100,
  domain: d,
})

export const uploadTemplateFx = attach({
  effect: uploadActTableTemplateReqFx,
})

export const {
  requestFx: initFlatTableFx,
} = createSingleEffect(initEffect, { abortEvents: [ActWorkGate.close] })

export const {
  requestFx: paginateFlatTableFx,
} = createSingleEffect(paginationEffect, { abortEvents: [ActWorkGate.close] })

export const {
  requestFx: getTreeTableActWorkFx,
} = createSingleEffect(getTreeTableActWorkReqFx, { abortEvents: [ActWorkGate.close] })

export const getActWorkFolderContentFx = attach({
  effect: getActWorkFolderContentReqFx,
})

export const updateItemsFx = createUpdateTreeItemsFx<ActTableRow>({ hasOnlyTargetInFolder: true })

export const $isTableLoading = combine(
  fillByRemainingFx.pending,
  initFlatTableFx.pending,
  getTreeTableActWorkReqFx.pending,
  paginateFlatTableFx.pending,
  sendActWorkVolumeFx.pending,
  updateItemsFx.pending,
  uploadActTableTemplateReqFx.pending,
  (...args) => args.some((val) => val),
)

export const $isFillByRemainingPending = combine(
  $isTableLoading,
  getActWorkFolderContentFx.pending,
  (...args) => args.some((val) => val),
)
