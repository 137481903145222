import React from 'react'
import styled from 'styled-components'

import { AuthLayout } from '@/ui'
import { LoginForm } from '@/features/login/view'

export const LoginPage = () => (
  <AuthLayout
    contentMaxWidth={450}
    title="Добро пожаловать в личный кабинет подрядчика"
    textAlign="center"
    contentMargin={32}
  >
    <Wrapper>
      <LoginForm />
    </Wrapper>
  </AuthLayout>
)

const Wrapper = styled.div`
  padding: 0 25px;
`
