import React from 'react'
import { useForm } from 'effector-forms'

import { MultiSelectInput } from '@/ui'

import { SMR_REMAINED_FILTERS, PERIOD_COMPLETED_FILTERS } from '../../model/const'
import { filters } from '../../model/filters.private'
import { InputWrapper } from '../parts'

export const SMRFilters = React.memo(() => {
  const {
    fields: { remained, reportPeriod },
  } = useForm(filters)

  return (
    <>
      <InputWrapper>
        <MultiSelectInput
          items={SMR_REMAINED_FILTERS}
          selectedIds={remained.value}
          testId="act-remained"
          onAcceptClick={remained.onChange}
          placeholder="Остаток к выполнению"
          hasAcceptOnClear
        />
      </InputWrapper>

      <InputWrapper>
        <MultiSelectInput
          items={PERIOD_COMPLETED_FILTERS}
          selectedIds={reportPeriod.value}
          testId="act-remained"
          onAcceptClick={reportPeriod.onChange}
          placeholder="Сделано в отчетном периоде"
          hasAcceptOnClear
        />
      </InputWrapper>
    </>
  )
})
