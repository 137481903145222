import { sample } from 'effector'

import { PaymentInfo } from '@/dal'

import { createToast } from '@/features/toast-service/model'
import {
  $isOpen,
  $reasonCancel,
  cancelPaymentApproveFx,
  clear,
  close,
  onCancelApprove,
  openCancelApproveModal,
} from './cancel-approve.private'
import { $paymentInfo, updatePayment } from './private'

$isOpen
  .on(openCancelApproveModal, () => true)
  .reset(close)

sample({
  clock: cancelPaymentApproveFx.doneData,
  target: [close, updatePayment],
})

sample({
  clock: close,
  target: clear,
})

sample({
  clock: onCancelApprove,
  source: { info: $paymentInfo, message: $reasonCancel },
  filter: ({ info, message }) => Boolean(info && message.trim()),
  fn: ({ info, message }) => ({
    id: info?.id as PaymentInfo['id'],
    message,
  }),
  target: cancelPaymentApproveFx,
})

createToast({
  effect: cancelPaymentApproveFx,
  doneText: 'Согласование отменено',
})
