import React from 'react'
import { InputField } from './InputField'

type Props = {
  value: string
  onChange: (val: string) => void
  onClear?: () => void
  isInvalid?: boolean
  error?: string
  dataTestId?: string
  placeholder?: string
  name?: string
}

export const PasswordField = ({
  onChange,
  onClear,
  value,
  error,
  dataTestId,
  placeholder,
  name,
  isInvalid,
}: Props) => {
  const [showPassword, setShowPassword] = React.useState(false)

  return (
    <InputField
      dataTestId={dataTestId || ''}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      error={error}
      type={showPassword ? 'text' : 'password'}
      postfixIcon={showPassword ? 'openEye' : 'eye'}
      onPostfixIconClick={() => setShowPassword((flag) => !flag)}
      name={name}
      size="L"
      isRequired
      onClear={onClear}
      isInvalid={isInvalid}
    />
  )
}
