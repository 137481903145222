import { attach } from 'effector'

import {
  AbortableRequest,
  EstimateLotFiltersResponse,
  EstimateLotListItem,
  EstimateLotsFilterPayload,
  getEstimateLotFiltersReqFx,
  GetEstimateLotListData,
  getEstimateLotListReqFx,
} from '@/dal'
import { createFilters } from '@/features/shared/filterFactory/createFilters'
import { createPagination } from '@/features/shared/createPagination'
import { createSingleEffect } from '@/lib/createSingleEffect'
import { getStatusName } from '@/lib/getStatusName'

import { d } from './domain'
import { DocHeaderInfo } from './types'

export const $estimateLotList = d.store<EstimateLotListItem[] | null>(null)

export const $headerInfo = d.store<DocHeaderInfo | null>(null)
export const setHeaderInfo = d.event<DocHeaderInfo>()

export const $totalDocCount = $headerInfo.map((info) => info?.all_estimate_lots_count ?? 0)

export const loadMore = d.event<void>()
export const onDocListLoaded = d.event<DocHeaderInfo>()
export const onEstimateLotDeleted = d.event<number>()

export const $filterOptions = d.store<EstimateLotFiltersResponse | null>(null)

export const $docNames = $filterOptions.map((info) => (info?.available_names ?? [])
  .map((label) => ({
    label,
    id: label,
  })))

export const $statuses = $filterOptions.map((info) => (info?.available_statuses ?? [])
  .map((id) => ({
    id,
    label: getStatusName(id),
  })))

export const $projects = $filterOptions.map((info) => (info?.available_projects ?? [])
  .map((label) => ({
    label,
    id: label,
  })))

export const $directions = $filterOptions.map((info) => {
  const entries = Object.entries(info?.available_directions ?? {})
  return entries.map(([_, label]) => ({ id: label, label }))
})

export const $existingContractOptions = $filterOptions.map(
  (info) => (info?.available_contracts ?? [])
    .map((value) => ({
      id: String(value),
      label: value ? 'Да' : 'Нет',
    })))

export const {
  $filters,
  removeFilter,
  setFilter,
  setFilters,
  clearFilter,
} = createFilters<Omit<EstimateLotsFilterPayload, 'limit' | 'offset'>>({}, d)

export const {
  requestFx: getEstimateLotListFx,
  abortFx,
} = createSingleEffect(getEstimateLotListReqFx)

export const {
  $hasMore,
  $offset,
  $total,
  initEffect: initEstimateLotListFx,
  paginationEffect: loadMoreFx,
} = createPagination<AbortableRequest<EstimateLotsFilterPayload>, GetEstimateLotListData>({
  domain: d,
  fetchEffect: getEstimateLotListFx,
  limit: 30,
})

export const getFiltersFx = attach({
  effect: getEstimateLotFiltersReqFx,
})

export const $areDocumentsLoading = initEstimateLotListFx.pending
export const $areFiltersLoading = getFiltersFx.pending
