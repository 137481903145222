import { AppealTaskItem } from '@/dal'

export type TabItem = {
  label: string
  value: string | 'all'
}

export type TasksAndResponsesTabItem = {
  id: TasksAndResponsesTabId
  label: string
  count?: number
}

export const enum TasksAndResponsesTabId {
  ActiveTasks = 1,
  AppealResponses = 2,
}

export type SelectedAppeal = {
  id: AppealTaskItem['document_id']
  attachments: {name: string, url: string}[]
  request_number: AppealTaskItem['document_name']
  can_send_message?: boolean
  project_name?: string
  document_name?: string
  author?: string
}
