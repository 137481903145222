import React, { memo } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { HeadXL, ModalLayoutV2, NewButton } from '@/ui'
import { noop } from '@/lib/noop'

import { changePaymentTypeModal, setIsThirdPerson } from '../../../model/private'

export const ChangePaymentTypeModal = memo(() => {
  const info = useUnit(changePaymentTypeModal.$meta)

  if (!info) return null

  const onClose = changePaymentTypeModal.close

  const handleConfirm = () => {
    setIsThirdPerson(info.value)
    changePaymentTypeModal.close()
  }

  return (
    <ModalLayoutV2 hasCloseButton={false} closeModal={noop}>
      <Wrapper>
        <Title>При смене типа оплаты все вложенные файлы будут удалены</Title>

        <Buttons>
          <NewButton
            label="Сменить тип оплаты"
            dataTestId="submit-change-payment-type-btn"
            isFill
            onClick={handleConfirm}
          />
          <NewButton
            label="Отменить"
            buttonType="grey"
            dataTestId="cancel-change-payment-type-btn"
            isFill
            onClick={onClose}
          />
        </Buttons>
      </Wrapper>
    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 528px;
  padding: 32px;
`

const Title = styled.header`
  ${HeadXL}
  text-align: center;
`

const Buttons = styled.div`
  display: flex;
  gap: 16px;
`
