import React from 'react'

import { FeedbackModal } from './features/feedback/view'
import {
  CopyDocumentConfirm,
  CreateDocumentModal,
  DeleteDocumentConfirm,
} from './features/document-manager/view'
import { ActErrorModal } from './features/act-constructor'
import { CloseContractModal, GetRetensionModal } from './features/administrative/close-contract/view'
import { AdditionalModal } from './features/additional-request'
import { CreateAppealModal } from './features/support/support-appeals'
import { CancelAppealModal } from './features/support/cancel-appeal'
import { ErrorAsIssueModal } from './features/support/error-modal'

export const ModalProvider = () => (
  <>
    <CopyDocumentConfirm />
    <FeedbackModal />
    <ActErrorModal />
    <DeleteDocumentConfirm />
    <CloseContractModal />
    <GetRetensionModal />
    <CreateDocumentModal />
    <AdditionalModal />
    <CreateAppealModal />
    <CancelAppealModal />
    <ErrorAsIssueModal />
  </>
)
