import { AUTH_TOKEN } from './const'
import {
  $accessToken,
  clearAccessToken,
  loadAccessTokenFx,
  saveAccessTokenFx,
  setAccessToken,
} from './units'

$accessToken
  .on(setAccessToken, (_, value) => value)
  .on(loadAccessTokenFx.doneData, (_, value) => value)
  .on(saveAccessTokenFx, (_, token) => token)
  .reset(clearAccessToken)

loadAccessTokenFx.use(() => localStorage.getItem(AUTH_TOKEN))
saveAccessTokenFx.use((token) => localStorage.setItem(AUTH_TOKEN, token))
