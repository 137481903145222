import { combine } from 'effector'

import { createSingleEffect } from '@/lib/createSingleEffect'
import { TreeItem, getGroupsTreeReqFx } from '@/dal'

import { d } from './domain'
import { getSubTree } from './helpers'
import { $selectedGroupId } from './public'

export const $isOpen = d.store<boolean>(false)
export const openGroupsTree = d.event<void>()
export const closeModal = d.event<void>()

export const $groupsTree = d.store<null | TreeItem[]>(null)

export const $treeNodeWithoutParent = $groupsTree
  .map((tree) => tree?.filter((node) => !node.parent) ?? null)

export const selectGroup = d.event<TreeItem['id'] | null>()
export const clearSelectedGroup = d.event<void>()

export const $selectedIds = combine([
  $groupsTree,
  $selectedGroupId,
], ([tree, itemId]) => {
  if (!tree || !itemId) return null
  return getSubTree(tree, itemId)
})

export const $breadcrumbs = combine([
  $groupsTree,
  $selectedIds,
], ([tree, ids]) => {
  if (!tree || !ids) return null
  return ids.map((id) => {
    const item = tree.find((item) => item.id === id) as TreeItem
    return {
      id,
      name: `${item.code_1c} ${item.name}`,
    }
  })
})

export const $selectedNodeId = d.store<TreeItem['id'] | null>(null)
export const $expandedNodes = combine([
  $groupsTree,
  $selectedNodeId,
], ([tree, itemId]) => {
  if (!tree || !itemId) return null
  return getSubTree(tree, itemId)
})
export const selectNode = d.event<TreeItem['id'] | null>()
export const onAcceptSelectedNode = d.event<void>()

export const {
  abortFx,
  requestFx: getGroupsTreeFx,
} = createSingleEffect(getGroupsTreeReqFx)
