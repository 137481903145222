import React from 'react'
import { useUnit } from 'effector-react'
import styled, { css } from 'styled-components'

import { formatDateWordMonth } from '@/lib/date'
import {
  Icon,
  IconButton,
  Ruble,
  SmallTextCSS,
  themeVar,
} from '@/ui'
import {
  $isShowNoteCell,
  $sortPlanWorkListCriteria,
  changePlanWorkListSort,
  toggleShowNoteCell,
} from '../../model/private'
import { GridWrapper } from '../parts'
import { $openedFundPlan } from '../../../plan-info/model'

const $planStartDate = $openedFundPlan.map((plan) => plan?.date_start ?? null)

export const WorkListHeader = React.memo(() => {
  const isShowNote = useUnit($isShowNoteCell)
  const startDate = useUnit($planStartDate)
  const sortPlanWorkListCriteria = useUnit($sortPlanWorkListCriteria)

  const currentMonth = formatDateWordMonth(startDate)
  const previousMonth = formatDateWordMonth(startDate, true)

  return (
    <Wrapper isShowNote={isShowNote}>
      <PositionWrapper>
        <FirstCell>
          <SubCell
            onClick={() => changePlanWorkListSort('id')}
            data-testid="div-funding-sort-code"
          >
            <CellTitle active={sortPlanWorkListCriteria.column === 'id'}>ID</CellTitle>
            <IconWrapper>
              <Icon
                icon={
                  sortPlanWorkListCriteria.direction === 'desc'
                    && sortPlanWorkListCriteria.column === 'id'
                    ? 'arrow-up' : 'arrow-down'
                }
              />
            </IconWrapper>
          </SubCell>

          <SubCell
            onClick={() => changePlanWorkListSort('name')}
            data-testid="div-funding-sort-name"
          >
            <CellTitle active={sortPlanWorkListCriteria.column === 'name'}>
              <OverflowWrapper>
                Наименование работ, услуг, материалов, оборудования
              </OverflowWrapper>
            </CellTitle>
            <IconWrapper>
              <Icon
                icon={
                  sortPlanWorkListCriteria.direction === 'desc'
                  && sortPlanWorkListCriteria.column === 'name'
                    ? 'arrow-up' : 'arrow-down'
                }
              />
            </IconWrapper>
          </SubCell>

        </FirstCell>
        <ExpandIcon>
          <IconButton
            borderRadius="50%"
            size={20}
            onClick={toggleShowNoteCell}
            iconSize={12}
            iconColor="lightBackground"
            hoverIconColor="lightBackground"
            backgroundColor="main"
            dataTestId="expand-note-button-id"
            icon={isShowNote ? 'cross' : 'table-expand-icon'}
            tooltip={isShowNote ? 'Скрыть примечания к смете' : 'Показать примечания к смете'}
            tooltipPosition="bottom"
          />
        </ExpandIcon>
      </PositionWrapper>


      {isShowNote && (
        <>
          <NoteCell>
            <CellTitle>Примечание</CellTitle>
          </NoteCell>
          <NoteCell>
            <CellTitle>Статья затрат</CellTitle>
          </NoteCell>
        </>
      )}

      <Cell>
        <CellTitle>Ед. Изм</CellTitle>
      </Cell>

      <Cell
        onClick={() => changePlanWorkListSort('count_in_estimate')}
        data-testid="div-funding-sort-count-in-estimate"
      >
        <CellTitle active={sortPlanWorkListCriteria.column === 'count_in_estimate'}>
          Указано в смете
        </CellTitle>
        <IconWrapper>
          <Icon
            icon={
              sortPlanWorkListCriteria.direction === 'desc'
              && sortPlanWorkListCriteria.column === 'count_in_estimate'
                ? 'arrow-up' : 'arrow-down'
            }
          />
        </IconWrapper>
      </Cell>

      <SplittedCellWrapper>
        <SplittedTop
          onClick={() => changePlanWorkListSort('price')}
          data-testid="div-funding-sort-price"
        >
          <CellTitle
            active={sortPlanWorkListCriteria.column === 'price'}
          >
            Цена всего,
            {' '}
            <Ruble />
          </CellTitle>
          <IconWrapper>
            <Icon
              icon={
                sortPlanWorkListCriteria.direction === 'desc'
              && sortPlanWorkListCriteria.column === 'price'
                  ? 'arrow-up' : 'arrow-down'
              }
            />
          </IconWrapper>
        </SplittedTop>

        <SplittedRow>
          <SplittedRowLeft>Материал</SplittedRowLeft>
          <SplittedRowRight>Работы</SplittedRowRight>
        </SplittedRow>
      </SplittedCellWrapper>

      <Cell
        onClick={() => changePlanWorkListSort('residual_on_prev_mounth')}
        data-testid="div-funding-sort-residual_on_prev_mounth"
      >
        <CellTitle
          active={sortPlanWorkListCriteria.column === 'residual_on_prev_mounth'}
        >
          {`Остаток на начало ${previousMonth}`}
        </CellTitle>
        <IconWrapper>
          <Icon
            icon={
              sortPlanWorkListCriteria.direction === 'desc'
              && sortPlanWorkListCriteria.column === 'residual_on_prev_mounth'
                ? 'arrow-up' : 'arrow-down'
            }
          />
        </IconWrapper>
      </Cell>

      {!isShowNote && (
        <Cell
          onClick={() => changePlanWorkListSort('plan_on_prev_mounth')}
          data-testid="div-funding-sort-plan_on_prev_mounth"
        >
          <CellTitle
            active={sortPlanWorkListCriteria.column === 'plan_on_prev_mounth'}
          >
            {`План ${previousMonth}`}
          </CellTitle>
          <IconWrapper>
            <Icon
              icon={
                sortPlanWorkListCriteria.direction === 'desc'
                && sortPlanWorkListCriteria.column === 'plan_on_prev_mounth'
                  ? 'arrow-up' : 'arrow-down'
              }
            />
          </IconWrapper>
        </Cell>
      )}

      <Cell
        onClick={() => changePlanWorkListSort('prediction_on_prev_mounth')}
        data-testid="div-funding-sort-prediction_on_prev_mounth"
      >
        <CellTitle
          active={sortPlanWorkListCriteria.column === 'prediction_on_prev_mounth'}
        >
          {`Прогноз ${previousMonth}`}
        </CellTitle>
        <IconWrapper>
          <Icon
            icon={
              sortPlanWorkListCriteria.direction === 'desc'
              && sortPlanWorkListCriteria.column === 'prediction_on_prev_mounth'
                ? 'arrow-up' : 'arrow-down'
            }
          />
        </IconWrapper>
      </Cell>

      {!isShowNote && (
        <Cell
          onClick={() => changePlanWorkListSort('deviation_on_prev_mounth')}
          data-testid="div-funding-sort-deviation_on_prev_mounth"
        >
          <CellTitle
            active={sortPlanWorkListCriteria.column === 'deviation_on_prev_mounth'}
          >
            {`Отклонение прогноза от плана ${previousMonth}`}
          </CellTitle>
          <IconWrapper>
            <Icon
              icon={
                sortPlanWorkListCriteria.direction === 'desc'
                && sortPlanWorkListCriteria.column === 'deviation_on_prev_mounth'
                  ? 'arrow-up' : 'arrow-down'
              }
            />
          </IconWrapper>
        </Cell>
      )}

      <Cell
        onClick={() => changePlanWorkListSort('residual_on_mounth')}
        data-testid="div-funding-sort-residual_on_mounth"
      >
        <CellTitle
          active={sortPlanWorkListCriteria.column === 'residual_on_mounth'}
        >
          {`Остаток на начало ${currentMonth}`}

        </CellTitle>
        <IconWrapper>
          <Icon
            icon={
              sortPlanWorkListCriteria.direction === 'desc'
              && sortPlanWorkListCriteria.column === 'residual_on_mounth'
                ? 'arrow-up' : 'arrow-down'
            }
          />
        </IconWrapper>
      </Cell>

      <Cell
        onClick={() => changePlanWorkListSort('plan_on_mounth')}
        data-testid="div-funding-sort-plan_on_mounth"
      >
        <CellTitle
          active={sortPlanWorkListCriteria.column === 'plan_on_mounth'}
        >
          {`План на ${currentMonth}`}
        </CellTitle>
        <IconWrapper>
          <Icon
            icon={
              sortPlanWorkListCriteria.direction === 'desc'
              && sortPlanWorkListCriteria.column === 'plan_on_mounth'
                ? 'arrow-up' : 'arrow-down'
            }
          />
        </IconWrapper>
      </Cell>

      <Cell
        onClick={() => changePlanWorkListSort('plan')}
        data-testid="div-funding-sort-plan"
      >
        <CellTitle
          active={sortPlanWorkListCriteria.column === 'plan'}
        >
          Бюджет (план),
          {' '}
          <Ruble />
        </CellTitle>
        <IconWrapper>
          <Icon
            icon={
              sortPlanWorkListCriteria.direction === 'desc'
              && sortPlanWorkListCriteria.column === 'plan'
                ? 'arrow-up' : 'arrow-down'
            }
          />
        </IconWrapper>
      </Cell>
    </Wrapper>
  )
})

const Wrapper = styled(GridWrapper)`
  width: 100%;
  margin-bottom: 20px;
  min-height: 63px;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: ${themeVar('mainBackground')};
`

const PositionWrapper = styled.div`
  position: relative;
`

const OverflowWrapper = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const Cell = styled.div<{ onClick?: () => void }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px;
  border: 1px solid ${themeVar('line')};
  flex-grow: 1;
  &:not(:last-child) {
    border-right: none;
  }

  ${({ onClick }) => onClick && css`
    :hover {
      color: ${themeVar('gray2')};
      cursor: pointer;
    }
  `}
`

const CellTitle = styled.div<CellTitleProps>`
  ${SmallTextCSS}
  color: ${({ active }) => (active ? themeVar('main') : themeVar('textGray'))};
  display: flex;
  overflow: hidden;
  justify-content: center;
  text-align: center;
  flex-grow: 1;
`

const FirstCell = styled(Cell)`
  padding: 0;
  width: 100%;
  display: grid;
  align-items: unset;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  height: 100%;
`

const SubCell = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 6px;
  overflow: hidden;
  width: 100%;

  & ${CellTitle} {
    justify-content: unset;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${themeVar('line')};
  }

  ${({ onClick }) => onClick && css`
    cursor: pointer;
  `}
`

type CellTitleProps = {
  active?: boolean
}

const IconWrapper = styled.div`
`

const ExpandIcon = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const NoteCell = styled(Cell)`
  background-color: ${themeVar('orange5')};
  justify-content: center;
`

const ActCellText = styled.div<{active?: boolean}>`
  ${SmallTextCSS}
  color: ${({ active }) => (active ? themeVar('main') : themeVar('textGray'))};
`

const SplittedCellWrapper = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid ${themeVar('line')};
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    border-right: none;
  }
`

const SplittedTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  ${({ onClick }) => onClick
    && css`
      cursor: pointer;
    `};
`

const SplittedRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${themeVar('line')};
  flex-grow: 1;
`

const SplittedRowLeft = styled(ActCellText)`
  flex-grow: 1;
  height: 100%;
  border-right: 1px solid ${themeVar('line')};
  display: flex;
  align-items: center;
  justify-content: center;
`

const SplittedRowRight = styled(ActCellText)`
  flex-grow: 1;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`
