import {
  Contract,
  DefaultUpdateFileParams,
  DocumentFeatures,
  DocumentProgress,
  DocumentStatuses,
  EstimateLotId,
  FileDTO,
  PaginationRequestParams,
  PaginationType,
} from '../interfaces'

export type CostObject = {
  declared: string
  approved: string
  diff: string | null
}

export enum MorphologyType {
  Object = 'object',
  Section = 'section',
  Building = 'building',
}

export type MorphologyItem = {
  id: number
  type: MorphologyType
}

export type EstimateLot = {
  id: EstimateLotId
  name: string
  status: DocumentStatuses
  features: DocumentFeatures
  company_id: number
  project_id: number
  direction_id: number
  is_existing_contract: boolean
  contract_id: number | null
  morphology: MorphologyItem[]
  attachments: FileDTO[] | null
  documents_link: string // url
  advance_type_id: number
  type_of_security_id: number
  advance_size: string
  guarantee_retention_size: string
  construction_start: string | null // YYYY-MM-DD
  construction_end: string | null // YYYY-MM-DD
  updated_at: string // isoDate
  comment: string | null
  is_typical_form_of_contract: boolean
  summary: {
    cost_of_work: CostObject
    cost_of_materials: {
      cost_of_material: CostObject
      grand_tender: CostObject
      nominal: CostObject
      related: CostObject
    }
    cost_of_lot: CostObject
  }
  instruction_link: string // url
}

export type LotCatalogItem = {
  id: number
  name: string
}

export type EstimateLotsFilterPayload = PaginationRequestParams & {
  document_type?: string
  name?: string
  date_from?: string // YYYY-MM-DD
  date_to?: string // YYYY-MM-DD
  project_name?: string
  existing_contract?: boolean
  status?: DocumentStatuses
}

export type EstimateLotListItem = {
  id: EstimateLotId
  name: string
  sums: string
  date: string // YYYY-MM-DD
  period: {
    date_start: string // YYYY-MM-DD
    date_stop: string // YYYY-MM-DD
  }
  status: DocumentStatuses
  answer_required: boolean
  download_link: string | null
  progress: DocumentProgress | null
  project_name: string | null
  available_actions: {
    can_copy: boolean
    can_delete: boolean
    additional_request: boolean
  }
  // TODO уточнить enum
  document_type: string
  contract_id: Contract['id'] | null
  contract_name: string | null
}

export type UpdateEstimateLotPayload = {
  project?: number | null
  direction?: number | null
  contract?: number | null
  advance_type?: number | null
  type_of_security?: number | null
  is_existing_contract?: boolean
  is_typical_form_of_contract?: boolean
  advance_size?: string
  guarantee_retention_size?: string
  construction_start?: string
  construction_end?: string
  comment?: string
  files?: File[]
  morphology?: MorphologyItem[]
}

export type PostEstimateLotAttachmentsParams = {
  id: EstimateLotId
} & DefaultUpdateFileParams

export type GetEstimateLotListData = {
  items: EstimateLotListItem[]
  all_estimate_lots_count: number
  on_agreed_estimate_lots: number
}

export type GetEstimateLotListResponse = PaginationType<GetEstimateLotListData>

export type MorphologyObject = LotCatalogItem

export type MorphologyBuilding = {
  construction_object: MorphologyObject['id']
} & LotCatalogItem

export type MorphologySection = {
  building: MorphologyBuilding['id']
} & LotCatalogItem

export type GetEstimateLotMorphologyResponse = {
  construction_objects: MorphologyObject[]
  buildings: MorphologyBuilding[]
  construction_section: MorphologySection[]
}

export enum LotRowType {
  Work = 1,
  Material = 2,
  Additional = 3,
}

export type LotTableItem = {
  id: string // uuid
  name: string
  unit_id: {
    id: number
    value: string
  } | null
  count: null
  depth: number
  price_material: string // float
  price_work: string // float
  price_all: string // float
  cost_material: string // float
  cost_work: string // float
  cost_all: string // float
  comment: string | null
  attachments: FileDTO[] | null
  description: string | null
  hierarchy_code: string
  elements: null
  has_children: boolean
  is_folder: boolean
  row_type: LotRowType | null
  morphology_elements: {
    construction_object: string
    construction_building: string
    construction_section: string
  } | null
}

export type KerElement = {
  id: number
  name: string
  unit: number | null
  description: string | null
  kvr_id: KvrElement['id']
  is_ker_in_use: boolean
}

export type KvrElement = {
  id: number
  name: string
  parent_id: KvrElement['id'] | null
  ker_elements: KerElement[] | null
  is_kvr_in_use: boolean
}

export type GetLotTreeTableParams = {
  id: EstimateLotId
  group_id?: LotTableItem['id']
}

export type GetLotFlatTableParams = PaginationRequestParams & GetLotTreeTableParams & {
  is_show_elements_of_construction?: boolean
}

export type GetLotWorkStructureParams = {
  id: EstimateLotId
  workId: LotTableItem['id']
}

export type GetLotWorkMaterials = {
  workId: LotTableItem['id']
  group_id?: string
}

export type AddLotWorkMaterialsPayload = {
  workId: WorkTableItem['id']
  materials: string[]
}

export type DeleteLorWorkMaterialPayload = {
  id: EstimateLotId
  rowId: WorkTableItem['id']
  isEverywhere?: boolean
}

export type GetLotTreeRowsParams = Required<GetLotTreeTableParams>

export type GetLotFlatTableResponse = PaginationType<LotTableItem[]>

export type GetLotTreeTableResponse = {
  data: LotTableItem[]
  total: number
}

export type UpdateLotWorkCommentPayload = {
  id: LotTableItem['id']
  comment: string
  morphology?: WorkTableItem['morphology_elements']
}

export type EstimateLotFiltersResponse = {
  available_statuses: DocumentStatuses[]
  available_names: string[]
  available_projects: string[]
  available_contracts: boolean[]
  available_directions: {
    [key: number]: string
  }
}

export type UnitItem = {
  id: number
  short_name: string
  full_name: string | null
}

export type EditableValue = {
  value: string
  can_edit: boolean
}

export type WorkTableItem = {
  id: string
  name: string
  morphology_elements: {
    construction_object: string
    construction_building: string
    construction_section: string
  }
  row_type: LotRowType
  unit: {
    id: number
    value: string
  }
  count: EditableValue
  unit_price: EditableValue
  total_cost: EditableValue
  features: {
    can_delete: boolean
  }
  description: string | null
}

export type LorWorkMaterial = {
  id: string
  name: string
  can_delete: boolean
  count_in_lot: number | null
}

export type LotWorkStructureResponse = {
  items: WorkTableItem[]
  breadcrumbs: string[]
  materials: LorWorkMaterial[]
  morphology_item_count: number
}

export type UpdateLotWorkValuesPayload = {
  id: EstimateLotId
  items: {
    id: WorkTableItem['id']
    count?: number
    price?: number
    total_cost?: number
  }[]
}

export type UpdateAdditionalMaterialPayload = {
  id: LotTableItem['id']
  comment: string
  morphology?: WorkTableItem['morphology_elements']
}

export type LotWorkFiltersResponse = {
  objects: number[]
  buildings: number[]
  sections: number[]
  names: string[]
  units: UnitItem['id'][]
}

export type EstimateLotWorkElementsPayload = PaginationRequestParams & {
  object_id?: number
  building_id?: number
  section_id?: number
  name?: string
  description?: string
  row_type?: LotRowType
  unit_id?: number[]

  count_from?: string
  count_to?: string
  price_from?: string
  price_to?: string
  cost_from?: string
  cost_to?: string
}

export type DistributeAmountsPayload = {
  rowId: WorkTableItem['id']
  split_count_to_all?: string
  single_count_for_all?: string
  single_price_for_all?: string
}

export type CatalogMaterialGroup = {
  id: number
  name: string
}

export type CatalogMaterial = {
  uuid: string
  name: string
  is_required: boolean
}
