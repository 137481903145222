import { restore } from 'effector'
import { createGate } from 'effector-react'
import { once } from 'patronum'

import { EstimateLotId, LotTableItem } from '@/dal'

import { getLotWorkStructureFx } from './private'

export const EstimateLotWorkGate = createGate<{
  id: EstimateLotId
  workId: LotTableItem['id']
}>('EstimateLotWorkGate')

export const $lotId = EstimateLotWorkGate.state.map(({ id }) => id || null)

export const $isLotWorkLoaded = restore(
  once({ source: getLotWorkStructureFx.finally.map(Boolean) }),
  false,
)

export const $isLotWorkLoading = getLotWorkStructureFx.pending
