import React, { memo } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import {
  NewDateInput, NewDropDownInput, NewTextButton, palette, TextM,
} from '@/ui'

import { Cell, GridTemplate } from '../parts'
import {
  $directions,
  $docNames,
  $existingContractOptions,
  $filters,
  $projects,
  $statuses,
  clearFilter,
  getFiltersFx,
  removeFilter,
  setFilter,
} from '../../model/private'

export const EstimateLotsFilters = memo(() => {
  const filters = useUnit($filters)
  const areFiltersLoading = useUnit(getFiltersFx.pending)
  const [docNames, directions, statuses, projects, existingContractOptions] = useUnit([
    $docNames,
    $directions,
    $statuses,
    $projects,
    $existingContractOptions,
  ])

  const hasSetFilters = Object.values(filters).some(Boolean)

  return (
    <Wrapper>
      <Col>
        <Label>Название документа</Label>
        <NewDropDownInput
          options={docNames}
          selectedId={filters.name ?? null}
          placeholder="Документ"
          isLoading={areFiltersLoading}
          dataTestId="estimate-lots-name-select"
          hasSearch
          hasClear
          onOptionClick={(val) => setFilter({ key: 'name', val })}
        />
      </Col>

      <Col>
        <Label>Тип документа</Label>
        <NewDropDownInput
          options={directions}
          selectedId={filters.document_type ?? null}
          placeholder="Документ"
          dataTestId="estimate-lots-document-type-select"
          hasClear
          onOptionClick={(val) => setFilter({ key: 'document_type', val })}
        />
      </Col>

      <Col>
        <Label>Дата документа</Label>
        <NewDateInput
          placeholder="Начиная с"
          inputValue={filters.date_from ?? ''}
          dataTestId="estimate-lots-date-from-select"
          onChange={(val) => setFilter({ key: 'date_from', val })}
          onClear={() => removeFilter({ key: 'date_from' })}
        />
        <NewDateInput
          placeholder="Заканчивая по"
          inputValue={filters.date_to ?? ''}
          dataTestId="estimate-lots-date-to-select"
          onChange={(val) => setFilter({ key: 'date_to', val })}
          onClear={() => removeFilter({ key: 'date_to' })}
        />
      </Col>

      <Col>
        <Label>Проект</Label>
        <NewDropDownInput
          options={projects}
          selectedId={filters.project_name ?? null}
          placeholder="Выберите проект"
          isLoading={areFiltersLoading}
          dataTestId="estimate-lots-project-select"
          hasClear
          onOptionClick={(val) => setFilter({ key: 'project_name', val })}
        />
      </Col>

      <Col>
        <Label>Сроки работ</Label>
      </Col>

      <Col>
        <Label>К действующему договору</Label>
        <NewDropDownInput
          options={existingContractOptions}
          selectedId={filters.existing_contract ?? null}
          placeholder="Есть договор?"
          minWidth="150px"
          isLoading={areFiltersLoading}
          dataTestId="estimate-lots-existing-contract-select"
          hasClear
          onOptionClick={(val) => setFilter({ key: 'existing_contract', val })}
        />
      </Col>

      <Col align="right">
        <Label>Сумма документа</Label>
      </Col>

      <Col>
        <Label>Статус</Label>
        <NewDropDownInput
          options={statuses}
          selectedId={filters.status ?? null}
          placeholder="Статус"
          isLoading={areFiltersLoading}
          dataTestId="estimate-lots-status-select"
          hasClear
          onOptionClick={(val) => setFilter({ key: 'status', val })}
        />
      </Col>

      <Col>
        <Label>Действия</Label>
        <ClearFiltersWrapper>
          <NewTextButton
            label="Очистить фильтры"
            textStyle="L"
            isDisabled={!hasSetFilters}
            dataTestId="estimate-lots-clear-filters-btn"
            onClick={clearFilter}
          />
        </ClearFiltersWrapper>
      </Col>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  ${GridTemplate}
  border-bottom: 1px solid ${palette.grey20};
`

const Col = styled(Cell)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 8px;
`

const Label = styled.p`
  ${TextM}
`

const ClearFiltersWrapper = styled.div`
  padding: 7px 0;
`
