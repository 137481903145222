import { attach } from 'effector'

import { MetricsRequestParams, sendMetricsReqFx } from '@/dal/metrics'
import { d } from './domain'
import { UserAgentInfo } from './types'

export const readUserAgentInfoFx = d.effect<void, UserAgentInfo, Error>()

export const sendMetricsFx = attach({
  effect: sendMetricsReqFx,
  mapParams: ({
    isChargeable,
    isTouchable,
    screenHeight,
    screenWidth,
    userAgent,
    userGpu,
  }: UserAgentInfo): MetricsRequestParams => ({
    is_chargeable: isChargeable,
    is_touchable: isTouchable,
    screen_height: screenHeight,
    screen_width: screenWidth,
    user_agent: userAgent,
    user_gpu: userGpu,
  }),
})
