import React from 'react'
import { useUnit } from 'effector-react'

import { SelectItemButton } from '@/ui'
import { createAttachmentsModalView } from '@/features/factories/attach-modal'

import {
  onOpenAttachments,
  $isBlockChanges,
  $paymentInfoFiles,
  attachmentsModel,
  $canSendFiles,
  $areAttachmentsVisible,
} from '../../../model/private'

const AttacmentsModal = createAttachmentsModalView(attachmentsModel)

export const Attachments = React.memo(() => {
  const fileCount = useUnit($paymentInfoFiles)
  const isBlockChanges = useUnit($isBlockChanges)
  const canSendFiles = useUnit($canSendFiles)
  const areAttachmentsVisible = useUnit($areAttachmentsVisible)

  if (!areAttachmentsVisible) return null

  return (
    <>
      <SelectItemButton
        text="Мои вложения"
        additionalText={String(fileCount?.length || 0)}
        icon={canSendFiles ? 'plusWithCircle' : 'arrowRightM'}
        buttonStyle={canSendFiles ? 'secondary' : 'primary'}
        hasBorder={false}
        dataTestId="open-attachments"
        isDisabled={isBlockChanges}
        onClick={onOpenAttachments}
      />
      <AttacmentsModal />
    </>
  )
})
