import React from 'react'

import { NotificationItem, NotificationType } from '@/ui'
import { onChangeInfo } from '../../../model/private'

export const InfoNotification = () => {
  return (
    <NotificationItem
      body="Если реквизиты вашей компании указаны неверно, их можно изменить в разделе «Моя компания»"
      actionLabel="Изменить"
      onActionClick={onChangeInfo}
      type={NotificationType.Info}
      dataTestId="edit-my-company"
    />
  )
}
