import React from 'react'
import styled from 'styled-components'

import { themeVar } from '@/ui'

export const ForceAuth = () => <Container>Перенаправление</Container>

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: ${themeVar('mainBackground')};

  font-size: 30px;
  line-height: 30px;
  font-weight: 400;
  color: ${themeVar('text')};
`
