import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { HeadL, palette, StatusTracking } from '@/ui'

import { CounterReadings, InfoTable } from '../containers'
import { $documentInfo } from '../../model/private'

export const CompensationInfo = React.memo(() => {
  const info = useUnit($documentInfo)

  if (!info) return null

  return (
    <Wrapper>
      <Header>
        Информация по документу компенсации
      </Header>

      <Content>
        <InfoTable />
        <CounterReadings />
        <StatusTracking items={info.document_tracking} />
      </Content>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  border-radius: 20px;
  background-color: ${palette.white};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Header = styled.header`
  ${HeadL}
`
