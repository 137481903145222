import React from 'react'
import styled from 'styled-components'

import {
  DefaultButton,
  NewIconButton,
  TextM,
  TextMLight,
  palette,
} from '@/ui'

import { useShortItems } from '@/lib/hooks/useShortItems'
import { ContactItemType, ContactProject, RoleItemType } from '@/dal'
import { formatPhoneNumber } from '@/lib/formatPhoneNumber'

const SHORT_PROJECT_COUNT = 2

type Props = {
  roleId: RoleItemType['id']
  onClickEditButton: (id: ContactItemType['id']) => void
  onClickDeleteButton: (id: ContactItemType['id']) => void
  projectsList: ContactProject[]
} & ContactItemType

export const ContactItem = ({
  email,
  fio,
  id,
  onClickDeleteButton,
  onClickEditButton,
  phone_number,
  projectsList,
  roleId,
}: Props) => {
  const projectsWithRole = React.useMemo(
    () => projectsList.filter(({ roles }) => roles.some(({ id }) => id === roleId)),
    [projectsList],
  )

  const {
    setIsExpand,
    showButton,
    visibleItems,
  } = useShortItems({ source: projectsWithRole, shortCount: SHORT_PROJECT_COUNT })

  return (
    <Wrapper>
      <Info>
        <Col>
          <Names>
            {fio}
          </Names>
          <ProjectList>
            {visibleItems?.map((item) => (
              <ProjectItem key={item?.id}>
                {item?.name}
              </ProjectItem>
            ))}
            {showButton && (
              <>
                и
                {' '}
                <ProjectExpandButton
                  data-testid={`show-all-project-${id}`}
                  onClick={() => setIsExpand(true)}
                >
                  еще
                  {' '}
                  {(projectsList?.length ?? 0) - SHORT_PROJECT_COUNT}
                </ProjectExpandButton>
              </>
            )}
          </ProjectList>
        </Col>
        <Col>
          <Link href={`mailto:${email}`}>
            {email}
          </Link>
          <Link href={`tel:${phone_number}`}>
            {formatPhoneNumber(phone_number)}
          </Link>
        </Col>
      </Info>
      <Icons>
        <NewIconButton
          dataTestId={`open-edit-contact-modal-${id}`}
          padding="8px"
          color="accent100"
          size={16}
          icon="edit"
          onClick={() => onClickEditButton(id)}
        />
        <NewIconButton
          dataTestId={`open-delete-contact-modal-${id}`}
          padding="8px"
          icon="delete"
          color="grey60"
          size={16}
          onClick={() => onClickDeleteButton(id)}
        />
      </Icons>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 30px;
`

const Info = styled.div`
  display: grid;
  flex-grow: 1;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
`

const Icons = styled.div`
  display: flex;
  gap: 8px;
  flex: 0 0 72px;
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const Names = styled.p`
  ${TextM}
  margin-bottom: 6px;
`

const ProjectList = styled.div`
  padding: 0;
  margin: 0;
  max-width: 100%;
  ${TextMLight}
  color: ${palette.grey70};
`

const ProjectItem = styled.p``

const ProjectExpandButton = styled(DefaultButton)`
  ${TextMLight}
  color: ${palette.accent100};
  display: inline;

  @media(hover: hover) {
      &:hover {
        text-decoration: underline;
      }
  }
`

const Link = styled.a`
  ${TextMLight}
  text-decoration: none;
`
