export const MOBILE_WIDTH_PX = 900

export const LARGE_WIDTH_PX = 1600
export const MEDIUM_WIDTH_PX = 1280

export const WINDOW_WIDTH = {
  bigDesktop: 1920,
  desktop: 1440,
  smallDesktop: 1280,
  tablet: 768,
  mobile: 480,
}
