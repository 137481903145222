import React from 'react'
import styled from 'styled-components'

import { NewIconButton } from './NewIconButton'

type Props = {
  dataTestId: string
  onClick: () => void
}

export const CloseModalButton = ({
  dataTestId,
  onClick,
}: Props) => (
  <IconWrapper>
    <NewIconButton
      dataTestId={`${dataTestId}`}
      icon="cross"
      size={20}
      onClick={onClick}
      padding="6px"
      color="grey60"
      hoverBackgroundColor="grey20"
      borderRadius="50%"
      hoverColor="grey100"
    />
  </IconWrapper>
)


const IconWrapper = styled.div`
  display: flex;
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 11;
`
