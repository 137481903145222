import { sample } from 'effector'

import { onAdditionalRequestUpdated } from '@/features/additional-request/model'

import { EstimateLotListGate } from './public'
import {
  $headerInfo,
  initEstimateLotListFx,
  loadMoreFx,
  setHeaderInfo,
  $filterOptions,
  $filters,
  clearFilter,
  getFiltersFx,
  loadMore,
  removeFilter,
  setFilter,
  setFilters,
  onDocListLoaded,
  $estimateLotList,
  onEstimateLotDeleted,
} from './private'
import { createEstimateLot, createEstimateLotFx } from './actions.private'

import './actions.init'

$estimateLotList
  .on(initEstimateLotListFx.doneData, (_, { data }) => data.items)
  .on(loadMoreFx.doneData, (list, { data }) => (
    list ? [...list, ...data.items] : data.items
  ))
  .on(onEstimateLotDeleted, (list, deletedId) => (
    list?.filter(({ id }) => id !== deletedId) ?? null
  ))
  .reset(EstimateLotListGate.close)

$headerInfo
  .on(setHeaderInfo, (_, info) => info)
  .reset(EstimateLotListGate.close)

$filterOptions
  .on(getFiltersFx.doneData, (_, options) => options)
  .reset(EstimateLotListGate.close)

sample({
  clock: onDocListLoaded,
  target: setHeaderInfo,
})

sample({
  clock: [initEstimateLotListFx.doneData, loadMoreFx.doneData],
  fn: ({ data: { items, ...payload } }) => payload,
  target: onDocListLoaded,
})

sample({
  clock: [
    EstimateLotListGate.open,
    setFilters,
    removeFilter,
    setFilter,
    clearFilter,
    onAdditionalRequestUpdated,
  ],
  source: {
    gate: EstimateLotListGate.status,
    filters: $filters,
  },
  filter: ({ gate }) => gate,
  fn: ({ filters }) => filters,
  target: [initEstimateLotListFx, getFiltersFx],
})

sample({
  clock: loadMore,
  source: $filters,
  target: loadMoreFx,
})

sample({
  clock: createEstimateLot,
  fn: (id) => ({ id }),
  target: createEstimateLotFx,
})
