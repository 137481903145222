import React, {
  memo, useEffect, useMemo, useState,
} from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { KerElement } from '@/dal'
import {
  DefaultButton, Icon2, NewTextButton, palette, PaletteColor, TextL, TextLLight,
} from '@/ui'
import { $units } from '@/features/estimate-lot/table/model'

import {
  $areDescriptionsExpanded, $openedKvrId, addKerToLot, selectKerId,
} from '../../model/private'

type Props = KerElement & {
  isSelected: boolean
}

export const KerItem = memo(({
  id,
  name,
  unit,
  description,
  isSelected,
  is_ker_in_use: isKerInUse,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const [areDescriptionsExpanded, units, openedKvrId] = useUnit([
    $areDescriptionsExpanded,
    $units,
    $openedKvrId,
  ])

  const unitName = useMemo(() => {
    return units.find(({ id }) => id === unit)?.short_name ?? ''
  }, [unit, units])

  useEffect(() => {
    setIsExpanded(areDescriptionsExpanded)
  }, [areDescriptionsExpanded])

  const onAddKer = () => {
    if (isKerInUse || !openedKvrId) return
    addKerToLot({ kerId: id, kvrId: openedKvrId })
  }

  return (
    <Wrapper>
      <Row>
        <IconWrapper>
          {isKerInUse && (
            <Icon2
              icon="check"
              color="accent100"
            />
          )}
        </IconWrapper>

        <Name
          color={isSelected ? 'accent100' : isKerInUse ? 'grey60' : 'grey90'}
          onClick={() => selectKerId(id)}
          onDoubleClick={onAddKer}
        >
          {name}
        </Name>

        {(description || unit) && (
          <NewTextButton
            label="Описание"
            postfixIcon={isExpanded ? 'arrowRightS' : 'arrowDown'}
            textStyle="ML"
            dataTestId=""
            onClick={() => setIsExpanded(!isExpanded)}
          />
        )}
      </Row>

      {isExpanded && (
        <Description>
          <GreyText>
            {description}
          </GreyText>
          <Unit>
            {unitName}
          </Unit>
        </Description>
      )}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`

const Row = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`

const IconWrapper = styled.div`
  flex-shrink: 0;
  width: 16px;
  height: 16px;
`

const Name = styled(DefaultButton)<{ color: PaletteColor }>`
  ${TextL}
  flex-grow: 1;
  color: ${({ color }) => palette[color]};
  text-align: left;
`

const Description = styled.div`
  display: flex;
  gap: 4px;
  width: 100%;
`

const GreyText = styled.p`
  ${TextL}
  color: ${palette.grey70};
`

const Unit = styled.p`
  ${TextLLight}
  flex-shrink: 0;
  width: 100px;
  margin-left: auto;
  text-align: center;
`
