import { createForm } from 'effector-forms'

import { EstimateLot } from '@/dal'
import { rules, withDisableCondition } from '@/lib/form-validators'

export const isExistingContract = () => {
  const values = lotInfoForm.$values.getState()
  return values.is_existing_contract
}

export const lotInfoForm = createForm({
  validateOn: ['change'],
  fields: {
    project: {
      init: null as EstimateLot['project_id'] | null,
      rules: [rules.required()],
    },
    direction: {
      init: null as EstimateLot['direction_id'] | null,
      rules: [rules.required()],
    },
    is_existing_contract: {
      init: false,
    },
    is_typical_form_of_contract: {
      init: false,
    },
    contract: {
      init: null as EstimateLot['contract_id'] | null,
      rules: [withDisableCondition(isExistingContract, rules.required())],
    },
    advance_type: {
      init: null as EstimateLot['advance_type_id'] | null,
      rules: [rules.required()],
    },
    advance_size: {
      init: '',
    },
    type_of_security: {
      init: null as EstimateLot['type_of_security_id'] | null,
      rules: [rules.required()],
    },
    guarantee_retention_size: {
      init: '',
    },
    construction_start: {
      init: '',
    },
    construction_end: {
      init: '',
    },
    comment: {
      init: '',
    },
    files: {
      init: [] as File[],
    },
  },
})
