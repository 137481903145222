import { attach } from 'effector'
import { createGate } from 'effector-react'
import { createForm } from 'effector-forms'

import { rules } from '@/lib/form-validators'
import {
  PlanSubmissionMonth,
  createPlanReqFx,
  getSubmissionMonthsReqFx,
} from '@/dal'
import { createSingleEffect } from '@/lib/createSingleEffect'

import { d } from './domain'

export const CreatePlanGate = createGate('CreatePlanGate')

export const createPlanForm = createForm({
  domain: d,
  fields: {
    dateStart: {
      init: '',
      rules: [rules.required()],
    },
  },
})

export const $submissionMonths = d.store<PlanSubmissionMonth[] | null>(null)

export const $availableMonths = $submissionMonths.map((months) => (
  months?.reduce((acc: Date[], { month, is_free }) => {
    if (is_free) {
      const timestamp = new Date(month)
      acc.push(timestamp)
    }
    return acc
  }, [])) ?? null)


export const createPlanFx = attach({
  effect: createPlanReqFx,
})

export const {
  requestFx: getSubmissionMonthsFx,
} = createSingleEffect(
  getSubmissionMonthsReqFx,
  {
    abortEvents: [CreatePlanGate.close],
  },
)
