import React from 'react'
import { useUnit } from 'effector-react'
import styled from 'styled-components'

import {
  H2TitleCSS,
  HelpLegend,
  Ruble,
} from '@/ui'

import { numberWithSpaces } from '@/lib/number-represent'
import { GroupTree } from '@/features/group-tree/view'

import { useIsShowApprovedInfo } from '../../model/hooks'
import { $openedFundPlan } from '../../../plan-info/model'
import { $planWorkListTotal } from '../../model/private'

export const WorkNavigation = React.memo(() => {
  const openedFundPlan = useUnit($openedFundPlan)
  const showApprovedInfo = useIsShowApprovedInfo(openedFundPlan?.status)
  const planCount = useUnit($planWorkListTotal)

  if (!openedFundPlan) {
    return null
  }

  return (
    <Container>
      <Row>
        <GroupTree workCount={planCount} />

        <TotalSumm>
          Сумма бюджета:
          {' '}
          {numberWithSpaces(openedFundPlan.cost_all ?? '')}
          {' '}
          <Ruble />
        </TotalSumm>

        {showApprovedInfo && (
          <HelpWrapper>
            <HelpLegend />
          </HelpWrapper>
        )}
      </Row>

    </Container>
  )
})


const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const TotalSumm = styled.div`
  ${H2TitleCSS}
  margin-right: 0;
  margin-left: auto;
`

const HelpWrapper = styled.div`
  width: 150px;
`
