import React from 'react'
import styled from 'styled-components'

import { NewIconButton, palette, TextL } from '@/ui'

type Props = {
  title: string
  onOpen: () => void
  onClose: () => void
}

export const MinimizedChat = ({ title, onOpen, onClose }: Props) => {
  return (
    <Wrapper>
      <Title>{title}</Title>

      <Buttons>
        <NewIconButton
          backgroundColor="white"
          icon="plusButton"
          color="grey60"
          hoverColor="grey80"
          hoverBackgroundColor="grey20"
          size={20}
          dataTestId=""
          onClick={onOpen}
        />
        <NewIconButton
          backgroundColor="white"
          icon="cross"
          color="grey60"
          hoverColor="grey80"
          hoverBackgroundColor="grey20"
          size={20}
          dataTestId=""
          onClick={onClose}
        />
      </Buttons>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  right: 32px;
  bottom: 32px;
  z-index: 5;

  display: flex;
  justify-content: space-between;
  gap: 32px;
  width: 332px;
  padding: 20px;
  background-color: ${palette.white};
  border-radius: 12px;
  box-shadow: 0px 4px 20px 0px #1c1d1e26;
`

const Title = styled.header`
  ${TextL}
  flex-direction: column;
  gap: 4px;
`

const Buttons = styled.div`
  display: flex;
  gap: 8px;
  flex-shrink: 0;
`
