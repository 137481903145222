import { sample } from 'effector'

import { createToast } from '@/features/toast-service/model'
import { EstimateDoc, EstimateDocId, EstimateTableRow } from '@/dal'
import {
  $createdTableItem,
  $isCreateFolder,
  $isOpen,
  closeModal,
  createEstimateFolderFx,
  createEstimateRowFx,
  createFolderForm,
  createRowForm,
  getEstimateRowIndexFx,
  resetCreatedTableItem,
  setCreatedTableItem,
} from './create-table-item.private'
import { openCreateItemModal } from './create-table-item.public'
import {
  $documentId,
  EstimateCorrectionGate,
  onUpdateSummary,
} from '../../shared-model'
import {
  $isTreeTable,
  $offset,
  TableGate,
  addCreatedItemsFx,
  incrementItems,
  onLoadMore,
  paginateFlatTableFx,
  scrollToItemById,
} from './private'
import { onItemCreate } from './public'
import { $acceptedTypes, $searchInputValue } from './filters.private'
import { PAGINATION_LIMIT } from './const'

$isOpen
  .on(openCreateItemModal, () => true)
  .reset(closeModal)

$isCreateFolder
  .on(openCreateItemModal, (_, { isFolder }) => isFolder)
  .reset(closeModal)

$createdTableItem
  .on(setCreatedTableItem, (_, item) => item)
  .reset(resetCreatedTableItem, TableGate.close, scrollToItemById)

sample({
  clock: openCreateItemModal,
  filter: ({ parentFolder, isFolder }) => Boolean(parentFolder && isFolder),
  fn: ({ parentFolder }) => parentFolder,
  target: createFolderForm.fields.group_id.set,
})

sample({
  clock: openCreateItemModal,
  filter: ({ parentFolder, isFolder }) => Boolean(parentFolder && !isFolder),
  fn: ({ parentFolder }) => parentFolder,
  target: createRowForm.fields.group_id.set,
})

sample({
  clock: [EstimateCorrectionGate.close, EstimateCorrectionGate.state],
  target: closeModal,
})

sample({
  clock: closeModal,
  target: [createFolderForm.reset, createRowForm.reset],
})

sample({
  clock: [
    createRowForm.fields.material.$value,
    createRowForm.fields.work.$value,
  ],
  target: [
    createRowForm.fields.material.resetErrors,
    createRowForm.fields.work.resetErrors,
  ],
})

sample({
  clock: createRowForm.formValidated,
  source: {
    id: $documentId,
    isTree: $isTreeTable,
  },
  filter: ({ id }) => Boolean(id),
  fn: ({ id, isTree }, form) => ({
    documentId: id as EstimateDocId,
    is_tree: isTree,
    ...form,
    unit: form.unit as number,
  }),
  target: createEstimateRowFx,
})

sample({
  clock: createFolderForm.formValidated,
  source: {
    id: $documentId,
    isTree: $isTreeTable,
  },
  filter: ({ id }) => Boolean(id),
  fn: ({ id, isTree }, form) => ({
    documentId: id as EstimateDocId,
    is_tree: isTree,
    ...form,
  }),
  target: createEstimateFolderFx,
})

sample({
  clock: [createEstimateRowFx.done, createEstimateFolderFx.done],
  target: closeModal,
})

sample({
  clock: [createEstimateRowFx.done, createEstimateFolderFx.done],
  source: $isTreeTable,
  filter: Boolean,
  fn: (_, { params, result }) => ({
    ...result,
    documentId: params.documentId,
  }),
  target: onItemCreate,
})

sample({
  clock: [createEstimateRowFx.done, createEstimateFolderFx.done],
  filter: ({ result }) => Boolean(result.summary),
  fn: ({ params, result }) => ({ docId: params.documentId, summary: result.summary as EstimateDoc['summary_table'] }),
  target: onUpdateSummary,
})

sample({
  clock: createEstimateRowFx.doneData,
  source: {
    isTreeTable: $isTreeTable,
    types: $acceptedTypes,
    search: $searchInputValue,
    docId: $documentId,
  },
  filter: ({ isTreeTable, docId }, { target }) => (
    Boolean(!isTreeTable && target && docId)
  ),
  fn: ({ isTreeTable, docId, ...payload }, { target }) => ({
    id: target?.id as EstimateTableRow['id'],
    documentId: docId as EstimateDocId,
    ...payload,
  }),
  target: getEstimateRowIndexFx,
})

sample({
  clock: createEstimateRowFx.doneData,
  source: $isTreeTable,
  filter: (isTreeTable, data) => Boolean(!isTreeTable && data),
  fn: (_, { target }) => target as EstimateTableRow,
  target: setCreatedTableItem,
})

sample({
  clock: getEstimateRowIndexFx.done,
  source: { offset: $offset, target: $createdTableItem },
  filter: ({ offset, target }, { result: index }) => (
    Boolean(target && index >= 0 && index < offset)
  ),
  fn: ({ target }, { params, result: index }) => ({
    target: target as EstimateTableRow,
    documentId: params.documentId,
    index,
  }),
  target: onItemCreate,
})

sample({
  clock: getEstimateRowIndexFx.doneData,
  source: $offset,
  filter: (offset, index) => index >= 0 && index >= offset,
  fn: (offset, index) => Math.floor((index - offset) / PAGINATION_LIMIT) + 1,
  target: onLoadMore,
})

sample({
  clock: getEstimateRowIndexFx.doneData,
  filter: (index) => index < 0,
  target: resetCreatedTableItem,
})

sample({
  clock: getEstimateRowIndexFx.fail,
  target: resetCreatedTableItem,
})

sample({
  clock: [addCreatedItemsFx.done, paginateFlatTableFx.done],
  source: $createdTableItem,
  filter: Boolean,
  fn: ({ id }) => id,
  target: scrollToItemById,
})

sample({
  clock: addCreatedItemsFx.done,
  source: $isTreeTable,
  filter: (isTree) => !isTree,
  target: incrementItems,
})

createToast({
  effect: createEstimateRowFx,
  doneText: 'Строка создана',
})

createToast({
  effect: createEstimateFolderFx,
  doneText: 'Папка создана',
})
