import { sample } from 'effector'

import { createToast } from '@/features/toast-service/model'
import {
  $showPlanAgreedContractorAccept,
  agreedPlanContractorAcceptFx,
  closePlanAgreedContractorAccept,
  sendPlanAgreedContractorAccept,
} from './private'
import { openPlanAgreedContractorAccept } from './public'
import { $openedFundPlan } from '../../plan-info/model'

$showPlanAgreedContractorAccept
  .on(openPlanAgreedContractorAccept, () => true)
  .reset(closePlanAgreedContractorAccept)

sample({
  clock: sendPlanAgreedContractorAccept,
  source: $openedFundPlan,
  filter: Boolean,
  fn: (plan) => ({
    planId: plan.id,
  }),
  target: agreedPlanContractorAcceptFx,
})

sample({
  clock: agreedPlanContractorAcceptFx.done,
  target: closePlanAgreedContractorAccept,
})

sample({
  clock: agreedPlanContractorAcceptFx.doneData,
  target: $openedFundPlan,
})

createToast({
  effect: agreedPlanContractorAcceptFx,
})
