import React from 'react'
import styled from 'styled-components'

import { Icon2, TextMLight, palette } from '@/ui'
import { getNoun } from '@/lib/text-noun'

type Props = {
  daysCount: number | null
}

export const InfoItem = ({ daysCount }: Props) => {
  if (!daysCount) return null

  const dayNoun = getNoun(daysCount, 'день', 'дня', 'дней')
  return (
    <Wrapper>
      <Icon2 color="accent80" icon="lightning" />
      <Text>
        Вы можете подавать выполнения
        {' '}
        <TextSpan>
          каждые
          {` ${daysCount} ${dayNoun}`}
        </TextSpan>
        {' '}
        и получать расчет за выполненные работы
      </Text>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: ${palette.yellow20};
  padding: 16px;
  display: flex;
  border-radius: 20px;
  gap: 8px;
`

const Text = styled.p`
  ${TextMLight}
`

const TextSpan = styled.span`
  color: ${palette.accent70}
`
