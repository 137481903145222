import { sample, split } from 'effector'

import { DocumentAlias, DocumentItem } from '@/dal'
import { openAdditionalRequestModal } from '@/features/additional-request/model'
import { downloadBlobFileFx } from '@/features/download-files'
import {
  copyDocument, deleteDocument, onDocumentDeleted, TargetDocument,
} from '@/features/document-manager/model'

import { showToast } from '@/features/toast-service/model'
import { NotificationType } from '@/ui'
import { initDocumentsFx, loadMoreDocumentsFx } from '../../filters/model'
import {
  onAdditionalButtonClicked,
  downloadActOrEstimate,
  downloadActOrEstimateFx,
  downloadDocumentFlowFile,
  downloadFileByUrl,
  downloadFileByUrlFx,
  downloadPlan,
  downloadPlanFx,
  downloadPrintableForm,
  downloadPrintableFormFx,
  onClickCopyButton,
  onClickDeleteButton,
} from './private'
import {
  $documentList,
  $downloadInfo,
  DocumentFlowListGate,
  onSuccessDeleteDocumentFlow,
  resetDocumentList,
} from './public'

$documentList
  .on(initDocumentsFx.doneData, (_, { data }) => data.items)
  .on(loadMoreDocumentsFx.doneData, (list, { data }) => (
    list ? [...list, ...data.items] : data.items
  ))
  .reset(resetDocumentList)

$downloadInfo
  .on(
    [
      downloadActOrEstimate,
      downloadPrintableForm,
      downloadPlan,
      downloadFileByUrl,
    ],
    (store, d) => store.concat(d),
  )
  .on([downloadActOrEstimateFx.finally, downloadFileByUrlFx.finally],
    (store, final) => store.filter((item) => (
      item.id !== final.params.id && item.type === final.params.type
    )))
  .on(
    [downloadPrintableFormFx.finally, downloadPlanFx.finally],
    (store, final) => store.filter((item) => item.id !== final.params))

split({
  source: downloadDocumentFlowFile,
  match: {
    downloadActOrEstimate: (d) => [DocumentAlias.ACT, DocumentAlias.ESTIMATE].includes(d.type),
    downloadContract: (d) => [
      DocumentAlias.CONTRACT, DocumentAlias.ADDITIONAL_AGREEMENT,
    ].includes(d.type),
    downloadPrintableForm: (d) => d.type === DocumentAlias.KOR_DOC,
    downloadPlan: (d) => d.type === DocumentAlias.BUDGET,
  },
  cases: {
    downloadActOrEstimate,
    downloadPrintableForm,
    downloadPlan,
  },
})

sample({
  clock: onClickDeleteButton,
  source: $documentList,
  filter: (list, id) => Boolean(list?.some((item) => item.id === id)),
  fn: (list, id) => {
    const doc = list?.find((item) => item.id === id) as DocumentItem
    return {
      id: doc.id,
      type: doc.type,
      documentName: doc.name ?? doc.number_1c,
    } as TargetDocument
  },
  target: deleteDocument,
})

sample({
  clock: onClickCopyButton,
  source: $documentList,
  filter: (list, id) => Boolean(list?.some((item) => item.id === id)),
  fn: (list, id) => {
    const item = list?.find((item) => item.id === id) as DocumentItem
    return {
      id: item.id,
      type: item.type,
      documentName: item.name,
      draft: item.conflicting_draft_kor_doc,
    } as TargetDocument
  },
  target: copyDocument,
})

sample({
  clock: downloadActOrEstimate,
  target: downloadActOrEstimateFx,
})

sample({
  clock: downloadPrintableForm,
  fn: (d) => d.id,
  target: downloadPrintableFormFx,
})

sample({
  clock: downloadPlan,
  fn: (d) => d.id,
  target: downloadPlanFx,
})

sample({
  clock: downloadFileByUrl,
  fn: (payload) => ({ ...payload, url: payload.url.split('api')[1] }),
  target: downloadFileByUrlFx,
})

sample({
  clock: onAdditionalButtonClicked,
  target: openAdditionalRequestModal,
})

sample({
  clock: [
    downloadPrintableFormFx.fail,
    downloadActOrEstimateFx.fail,
    downloadPlanFx.fail,
    downloadFileByUrlFx.fail,
  ],
  fn: () => ({
    content: 'Ошибка скачивания',
    icon: NotificationType.Alert,
  }),
  target: showToast,
})

sample({
  clock: [
    downloadPrintableFormFx.doneData,
    downloadActOrEstimateFx.doneData,
    downloadPlanFx.doneData,
    downloadFileByUrlFx.doneData,
  ],
  fn: (blob) => ({
    fileName: 'attachment.zip',
    blob,
  }),
  target: downloadBlobFileFx,
})

sample({
  clock: onDocumentDeleted,
  filter: DocumentFlowListGate.status,
  target: onSuccessDeleteDocumentFlow,
})
