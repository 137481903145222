import React, { memo } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { FlexScrollbar, LoaderOverlay } from '@/ui'

import {
  $openedKvrChildren,
  $openedKvrKerItems,
  $selectedKvrId,
  getKvrAndKerFx,
  $selectedKerId,
} from '../../model/private'
import { KvrItem } from './KvrItem'
import { KerItem } from './KerItem'

export const CatalogList = memo(() => {
  const children = useUnit($openedKvrChildren)
  const kerItems = useUnit($openedKvrKerItems)
  const selectedKvrId = useUnit($selectedKvrId)
  const selectedKerId = useUnit($selectedKerId)
  const isLoading = useUnit(getKvrAndKerFx.pending)

  return (
    <FlexScrollbar>
      <Wrapper>
        {isLoading ? (
          <LoaderOverlay isShown={isLoading} />
        ) : (
          <>
            {children.map((item) => (
              <KvrItem key={`kvr-${item.id}`} {...item} isSelected={item.id === selectedKvrId} />
            ))}
            {kerItems.map((item) => (
              <KerItem key={`ker-${item.id}`} {...item} isSelected={item.id === selectedKerId} />
            ))}
          </>
        )}
      </Wrapper>
    </FlexScrollbar>
  )
})

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: 1;
  padding: 0 48px 0 24px;
`
