import { DocFlowDocSubtype, DocumentAlias } from '@/dal'

export const DEFAULT_LIMIT = 20
export const CONTRACT_ID_KEY = 'contract'
export const CONTRACT_NAME_KEY = 'name'

export const docSubtypeMapArray = [
  {
    type: DocumentAlias.CONTRACT,
    subtypes: [
      DocFlowDocSubtype.SMR,
      DocFlowDocSubtype.PD_RD,
      DocFlowDocSubtype.BANK_WARRANTY,
      DocFlowDocSubtype.CONTRACT_GUARANTEE,
    ],
  },
  {
    type: DocumentAlias.ADDITIONAL_AGREEMENT,
    subtypes: [DocFlowDocSubtype.CHANGE_CONDITION, DocFlowDocSubtype.ADDITIONAL_WORK],
  },
  {
    type: DocumentAlias.ACT,
    subtypes: [DocFlowDocSubtype.SMR, DocFlowDocSubtype.PD_RD],
  },
  {
    type: DocumentAlias.PAYMENT,
    subtypes: [
      DocFlowDocSubtype.TYPE_ACT,
      DocFlowDocSubtype.TYPE_SMR,
      DocFlowDocSubtype.TYPE_THIRD_PARTIES,
    ],
  },
  {
    type: DocumentAlias.BUDGET,
    subtypes: [DocFlowDocSubtype.SMR],
  },
  {
    type: DocumentAlias.KOR_DOC,
    subtypes: [DocFlowDocSubtype.SMR],
  },
]
