import React from 'react'
import styled from 'styled-components'

import { Icon2, TextL, palette } from '@/ui'

export const EmptyData = () => (
  <Wrapper>

    <Icon2
      icon="emptyChartIcon"
      height={170}
      size={210}
    />

    <Text>
      Здесь будет отображаться статистика по
      срокам согласования выполнений и оплат
    </Text>
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  margin-top: 46px;
  flex-direction: column;
  gap: 22px;
  justify-content: center;
  align-items: center;
`

const Text = styled.div`
  ${TextL}
  color: ${palette.grey60};
  max-width: 400px;
  text-align: center;
  margin-bottom: 52px;
`
