import React, { memo, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { combine } from 'effector'
import { useUnit } from 'effector-react'
import Scrollbars from 'react-custom-scrollbars-2'

import {
  DefaultButton,
  HeadL,
  LoaderOverlay,
  ModalLayoutV2,
  NewButton,
  NewCheckbox,
  NewInput,
  NewTextButton,
  palette,
  TextL,
  TextLLight,
  TextMLight,
} from '@/ui'
import { noop } from '@/lib/noop'
import { getMorphologyBreadcrumbs } from '@/features/estimate-lot/model/helpers'

import {
  $catalogMaterials,
  $materialGroups,
  $searchMaterialValue,
  $selectedMaterialGroups,
  $selectedMaterials,
  addLotWorkMaterials,
  addLotWorkMaterialsFx,
  addMaterialsModal,
  getLotWorkMaterialGroupsFx,
  getLotWorkMaterialsFx,
  resetSelectedGroups,
  setSearchMaterialValue,
  toggleSelectedMaterial,
  toggleSelectedMaterialGroup,
} from '../../model/private'
import { Breadcrumbs } from '../parts'

const $isLoading = combine(
  getLotWorkMaterialsFx.pending,
  getLotWorkMaterialGroupsFx.pending,
  (...args) => args.some((val) => val),
)

export const AddMaterialsModal = memo(() => {
  const [info, groups, materials, selectedGroups, selectedMaterials, search, isLoading] = useUnit([
    addMaterialsModal.$meta,
    $materialGroups,
    $catalogMaterials,
    $selectedMaterialGroups,
    $selectedMaterials,
    $searchMaterialValue,
    $isLoading,
  ])
  const isSending = useUnit(addLotWorkMaterialsFx.pending)

  const filteredMaterials = useMemo(() => (
    materials.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()))
  ), [materials, search])

  if (!info) return null

  const breadcrumbs = getMorphologyBreadcrumbs(info.morphology)
  const canResetFilters = groups.length !== selectedGroups.length

  const onClose = isSending ? noop : addMaterialsModal.close

  return (
    <ModalLayoutV2
      dataTestId=""
      closeModal={onClose}
    >
      <Wrapper>
        <Title>Добавить материалы</Title>

        {info.morphology ? (
          <Breadcrumbs items={breadcrumbs} />
        ) : (
          <Description>
            <p>
              Выбранные материалы будут добавлены к работе на каждый объект, корпус и секцию.
              Информация по объекту, корпусу и секции доступна на вкладке
              {' '}
              <BoldText>«Информация»</BoldText>
              .
            </p>
            <p>
              Если вам необходимо добавить материалы на конкретную секцию, вы можете это сделать
              через табличную часть.
            </p>
          </Description>
        )}

        <NewInput
          value={search}
          placeholder="Поиск по материалу"
          size="L"
          dataTestId=""
          onChange={setSearchMaterialValue}
          onClear={() => setSearchMaterialValue('')}
        />

        <Filters>
          {groups.map((item) => (
            <NewCheckbox
              key={item.id}
              value={item.id}
              label={item.name}
              isChecked={selectedGroups.includes(item.id)}
              checkPosition="left"
              disabled={isSending}
              dataTestId=""
              onChange={toggleSelectedMaterialGroup}
            />
          ))}

          <NewTextButton
            label="Сбросить фильтры"
            textStyle="M"
            isDisabled={!canResetFilters || isSending}
            dataTestId=""
            onClick={resetSelectedGroups}
          />
        </Filters>

        {Boolean(filteredMaterials.length) && (
          // 352px = 12 элементов
          <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={352} hideTracksWhenNotNeeded>
            <MaterialList>
              {filteredMaterials.map((item) => (
                <MaterialItem
                  key={item.uuid}
                  disabled={item.is_required || isSending}
                  onClick={() => toggleSelectedMaterial(item.uuid)}
                >
                  <NewCheckbox
                    disabled={item.is_required || isSending}
                    isChecked={selectedMaterials.includes(item.uuid) || item.is_required}
                    value={item.uuid}
                    dataTestId=""
                    onChange={toggleSelectedMaterial}
                  />
                  <MaterialName
                    isSelected={selectedMaterials.includes(item.uuid) && !item.is_required}
                  >
                    {item.name}
                  </MaterialName>
                </MaterialItem>
              ))}
            </MaterialList>
          </Scrollbars>
        )}

        <BottomText>
          Если вы не нашли нужную марку материала — направьте
          {' '}
          <NewTextButton
            url="mailto:mock@mock.mock"
            label="запрос на добавление"
            textStyle="ML"
            dataTestId=""
            isInline
            hasUnderline
          />
          .
        </BottomText>

        <Buttons>
          <NewButton
            label="Добавить"
            isDisabled={!selectedMaterials.length}
            isPending={isSending}
            dataTestId=""
            isFill
            onClick={addLotWorkMaterials}
          />
          <NewButton
            label="Отменить"
            buttonType="grey"
            isDisabled={isSending}
            dataTestId=""
            isFill
            onClick={onClose}
          />
        </Buttons>

        <LoaderOverlay inset={32} isShown={isLoading} />
      </Wrapper>
    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 732px;
  padding: 32px;
`

const Filters = styled.div`
  display: flex;
  align-items: center;
  gap: 8px 16px;
  flex-wrap: wrap;
  padding: 7px 0;
`

const MaterialList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const MaterialItem = styled(DefaultButton)`
  display: flex;
  align-items: center;
  gap: 16px;
  text-align: left;
`

const MaterialName = styled.p<{ isSelected: boolean }>`
  ${TextMLight}
  padding-top: 4px;
  transition: color 0.15s linear;

  ${({ isSelected }) => isSelected && css`
    color: ${palette.accent100};
  `}
`

const BottomText = styled.div`
  ${TextMLight}
`

const Buttons = styled.div`
  display: flex;
  gap: 16px;
`

const Title = styled.div`
  ${HeadL}
`

const Description = styled.div`
  ${TextLLight}
`

const BoldText = styled.span`
  ${TextL}
`
