import React from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { ExtendedRefs, ReferenceType } from '@floating-ui/react'

import { SimpleLoader } from '@/ui/Loader'

import { DialogSheetWrapper } from '../components/DialogSheetWrapper'
import { ActionOption, Option, OptionSize } from './types'
import { DropDownOption } from './DropDownOption'

type Props = {
  isOpen: boolean
  isDisabled: boolean
  isLoading: boolean
  listWidth?: string
  items: Option[] | null
  actionOptions?: ActionOption[] | null
  searchValue?: string
  size?: OptionSize
  isMultiple?: boolean
  dataTestId: string
  selectedId: Option['id'] | null
  handleOptionClick: (id: Option['id'] | null) => void
  onCloseDropDown: () => void
  refs: ExtendedRefs<ReferenceType>
  floatingStyles: React.CSSProperties
  getFloatingProps: (
    userProps?: React.HTMLProps<HTMLElement> | undefined
  ) => Record<string, unknown>
}

export const DropDownInputList = ({
  isDisabled,
  isLoading,
  isOpen,
  listWidth,
  items,
  size,
  searchValue,
  dataTestId,
  selectedId,
  actionOptions,
  isMultiple,
  handleOptionClick,
  onCloseDropDown,
  floatingStyles,
  getFloatingProps,
  refs,
}: Props) => {
  if (!isOpen || isDisabled) return null

  return (
    createPortal(
      <PopperWrapper
        ref={refs.setFloating}
        style={floatingStyles}
        {...getFloatingProps()}
        listWidth={listWidth}
      >
        <Scrollbars
          autoHeight
          autoHeightMin="0"
          autoHeightMax="290px"
        >
          <OptionsList
            data-testid={`${dataTestId}-options`}
          >
            {isLoading && (
              <LoaderWrapper>
                <SimpleLoader sizePx={16} borderWidth={2} />
              </LoaderWrapper>
            )}

            {!isLoading && items?.map(({
              id,
              label,
              subLabel,
              icon,
            }) => (
              <DropDownOption
                key={id}
                id={id}
                label={label}
                subLabel={subLabel}
                searchValue={searchValue}
                icon={icon}
                size={size}
                onClick={(id) => {
                  handleOptionClick(id)
                }}
                isActive={isMultiple ? selectedId.includes(id) : selectedId === id}
                isDisabled={id === undefined}
                hasCheckbox={isMultiple}
                dataTestId={dataTestId}
              />
            ))}
            {actionOptions?.map(({
              label,
              icon,
              id,
              onClick,
            }) => (
              <DropDownOption
                id={id}
                key={label}
                label={label}
                icon={icon}
                size={size}
                onClick={(id) => {
                  onClick?.(id)
                  onCloseDropDown()
                }}
                isAction
                dataTestId={dataTestId}
              />
            ))}
          </OptionsList>
        </Scrollbars>
      </PopperWrapper>,
      document.body,
    )
  )
}

const PopperWrapper = styled(DialogSheetWrapper) <{ listWidth?: string }>`
  width: ${({ listWidth }) => listWidth || '100%'};
`

const OptionsList = styled.ul`
  margin: 0;
  padding: 8px 0;
  list-style: none;
  border-radius: 8px;
  width: 100%;
`

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px 0;
`
