import { getDayJsObject } from './date'

export const getDateRange = (startDate: string, endDate: string) => {
  const result = { startRange: {}, endRange: {} }
  if (endDate) {
    result.startRange = {
      to: getDayJsObject(endDate).add(-1, 'day').toDate(),
    }
  }
  if (startDate) {
    result.endRange = {
      from: getDayJsObject(startDate).add(1, 'day').toDate(),
    }
  }
  return result
}
