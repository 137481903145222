import React from 'react'
import styled from 'styled-components'

import { TextM, palette } from '@/ui'
import { TableTemplate } from './TableTemplate'

export const ContractTableHeader = () => (
  <Wrapper>
    <Cell>
      <CellTitle>Название документа</CellTitle>
    </Cell>
    <Cell>
      <CellTitle>Запрос</CellTitle>
    </Cell>
    <Cell>
      <CellTitle>Даты</CellTitle>
    </Cell>
    <Cell>
      <CellTitle>Организация</CellTitle>
    </Cell>
    <Cell>
      <CellTitle>Стоимость договора</CellTitle>
    </Cell>
    <Cell>
      <CellTitle>Статус</CellTitle>
    </Cell>
    <Cell>
      <CellTitle>Процент закрытия</CellTitle>
    </Cell>
  </Wrapper>
)

const Wrapper = styled.div`
  width: 100%;
  position: sticky;
  top: 72px;
  ${TableTemplate}
  z-index: 9;
`

const Cell = styled.div`
  width: 100%;
  padding: 14px 12px;
  background-color: ${palette.grey10};

  &:first-child {
    padding-left: 38px;
  }
`

const CellTitle = styled.div`
  ${TextM}
`
