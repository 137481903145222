import React from 'react'
import styled from 'styled-components'
import { themeVar } from './theming'

type Props = {
  closeModal: () => void;
  children: React.ReactNode
  dataTestId?: string
  zIndex?: number
}

export const ModalLayout = ({
  children,
  closeModal,
  dataTestId,
  zIndex,
}: Props) => (
  <ModalOverlay
    zIndex={zIndex}
    data-testid={dataTestId}
    onClick={closeModal}
  >
    <ModalWrapper onClick={(e) => e.stopPropagation()}>
      {children}
    </ModalWrapper>
  </ModalOverlay>
)

const ModalOverlay = styled.div<{zIndex?: number}>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.8);
  color: ${themeVar('text')};
  z-index: ${({ zIndex }) => zIndex || 10};
`

const ModalWrapper = styled.div``
