
const reader = new FileReader()

const imagesExtensions = ['jpg', 'png', 'gif', 'gif']

export const getImageUrl = (
  file: File,
): Promise<string | null> => new Promise((resolve) => {
  if (!imagesExtensions.includes(getFileExtension(file.name))) {
    resolve(null)
  }
  reader.onload = (e) => {
    if (e.target) {
      resolve(e.target.result as string)
    }
  }
  reader.onerror = () => {
    resolve(null)
  }
  reader.readAsDataURL(file)
})

export const getFileExtension = (filename: string) => {
  const nameSplitted = filename.split('.')
  return nameSplitted[nameSplitted.length - 1]
}
