import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'
import Scrollbars from 'react-custom-scrollbars-2'

import {
  HeadXL,
  ModalLayoutV2,
  NewButton,
  NewTextButton,
  SectionTabs,
  TextM,
} from '@/ui'
import { SimpleLoader } from '@/ui/Loader'
import { noop } from '@/lib/noop'

import {
  $activeTabId,
  $docFilesInfo,
  $documentFiles,
  $hasSignedDocFiles,
  $isDocFilesModalOpen,
  changeActiveTab,
  closeDocFilesModal,
  getDocumentFilesFx,
} from '../../model/private'
import { DOCUMENT_FILES_TABS } from '../../model/const'
import { DocumentFilesTab } from '../../model/types'

type Props = {
  isDraft: boolean
}

export const DocumentFilesModal = ({ isDraft }: Props) => {
  const [isOpen, info, documentFiles, hasSignedDocFiles, activeTabId, isPending] = useUnit([
    $isDocFilesModalOpen,
    $docFilesInfo,
    $documentFiles,
    $hasSignedDocFiles,
    $activeTabId,
    getDocumentFilesFx.pending,
  ])

  if (!isOpen) return null

  const allDocsUrl = info?.download_template_files_url
  const signedDocsUrl = info?.download_signed_files_url
  const isTemplatesTab = activeTabId === DocumentFilesTab.Templates

  const tabs = DOCUMENT_FILES_TABS.filter(({ id }) => (
    hasSignedDocFiles || id !== DocumentFilesTab.Signed
  ))

  return (
    <ModalLayoutV2 closeModal={isPending ? noop : closeDocFilesModal}>
      <Wrapper>
        <Header>Документы</Header>

        <SectionTabs
          items={tabs}
          activeId={activeTabId}
          onItemClick={changeActiveTab}
        />

        {isPending ? (
          <LoaderWrapper>
            <SimpleLoader />
          </LoaderWrapper>
        ) : (
          <>
            <Scrollbars autoHeight autoHeightMin={0} autoHeightMax="60dvh">
              <DocumentList>
                <Row>
                  <HeaderLabel>Наименование документа</HeaderLabel>
                </Row>

                {documentFiles.map((item) => (
                  <Row key={item.id}>
                    <NewTextButton
                      label={item.name}
                      url={item.url}
                      textStyle="ML"
                      dataTestId={`${item.url}-download-link`}
                      isDownload
                      hasUnderline
                    />
                  </Row>
                ))}
              </DocumentList>
            </Scrollbars>

            {isTemplatesTab ? (
              <DownloadLink href={allDocsUrl} download>
                <NewButton
                  label="Скачать документы для согласования"
                  isDisabled={!allDocsUrl}
                  dataTestId="download-all-document-files-btn"
                  isFill
                />
              </DownloadLink>
            ) : signedDocsUrl && (
              <DownloadLink href={signedDocsUrl} download>
                <NewButton
                  label="Скачать подписанные документы"
                  isDisabled={!signedDocsUrl}
                  dataTestId="download-signed-document-files-btn"
                  isFill
                />
              </DownloadLink>
            )}
          </>
        )}
      </Wrapper>
    </ModalLayoutV2>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 564px;
  padding: 32px;
`

const Header = styled.header`
  ${HeadXL}
`

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const DocumentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Row = styled.div`
`

const HeaderLabel = styled.p`
  ${TextM}
`

const DownloadLink = styled.a`
  flex: 1 1 0;
`
