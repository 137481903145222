import { sample } from 'effector'

import { createToast } from '@/features/toast-service/model'
import { DeleteResetTableItemParams, EstimateDoc } from '@/dal'

import {
  $deleteTargetId,
  onConfirmDelete,
  deleteTableItemFx,
  openDeleteConfirm,
  rejectDelete,
  $deleteTarget,
} from './delete-table-item.private'
import { $isTreeTable, TableGate, decrementItems } from './private'
import { $documentId, onUpdateSummary } from '../../shared-model'
import { onItemDelete } from './public'

$deleteTargetId
  .on(openDeleteConfirm, (_, id) => id)
  .reset([TableGate.close, TableGate.state, rejectDelete, deleteTableItemFx.done])

sample({
  clock: onConfirmDelete,
  source: {
    documentId: $documentId,
    item: $deleteTarget,
    isTree: $isTreeTable,
  },
  filter: (params) => Boolean(
    params.documentId && params.item,
  ),
  fn: (params) => ({
    documentId: params.documentId,
    isTree: params.isTree,
    itemId: params.item?.id,
    isFolder: Boolean(params.item?.is_folder),
  }) as DeleteResetTableItemParams,
  target: deleteTableItemFx,
})

sample({
  clock: deleteTableItemFx.done,
  fn: ({ params, result }) => ({
    documentId: params.documentId,
    deletedId: params.itemId,
    ...result,
  }),
  target: onItemDelete,
})

sample({
  clock: deleteTableItemFx.done,
  filter: ({ result }) => Boolean(result.summary),
  fn: ({ params, result }) => ({ docId: params.documentId, summary: result.summary as EstimateDoc['summary_table'] }),
  target: onUpdateSummary,
})

sample({
  clock: deleteTableItemFx.done,
  source: $isTreeTable,
  filter: (isTree) => !isTree,
  target: decrementItems,
})

createToast({
  effect: deleteTableItemFx,
  doneText: 'Элемент удален',
})
