import { attach } from 'effector'
import { createForm } from 'effector-forms'

import {
  AppealComment,
  AppealTaskItem,
  completeTaskReqFx,
  getAdministrationCommentsReqFx,
  putMarkAppealAsReadReqFx,
} from '@/dal'
import { createSingleEffect } from '@/lib/createSingleEffect'

import { d } from './domain'
import { SelectedAppeal } from './types'

export const $selectedAppeal = d.store<SelectedAppeal | null>(null)
export const getAppealMessages = d.event<AppealTaskItem>()
export const resetSelectedAppeal = d.event()

export const $messages = d.store<AppealComment[] | null>(null)

export const markTaskAsRead = d.event<AppealTaskItem>()

export const {
  abortFx,
  requestFx: getMessagesFx,
} = createSingleEffect(
  getAdministrationCommentsReqFx,
)

export const commentForm = createForm({
  fields: {
    comment: {
      init: '',
    },
    files: {
      init: [] as File[],
    },
  },
})

export const completeTaskFx = attach({
  effect: completeTaskReqFx,
})

export const markTaskAsReadFx = attach({
  effect: putMarkAppealAsReadReqFx,
})
