import React, { Key } from 'react'
import styled from 'styled-components'

import { PillowSwiper, TabsProps } from '@/ui'

export const Tabs = <T extends Key>({ items, activeId, onItemClick }: TabsProps<T>) => {
  if (!items) return null

  return (
    <SwiperWrapper>
      <PillowSwiper
        activeId={activeId}
        items={items}
        onItemClick={onItemClick}
      />
    </SwiperWrapper>
  )
}

const SwiperWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 24px 32px 16px;
`
