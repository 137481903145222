import React, { memo, useMemo } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import {
  DefaultButton,
  palette,
  TextL,
  TextLLight,
} from '@/ui'

import {
  $documentInfo,
  $isCostCalculationModalOpen,
  closeCostCalculationModal,
  openCostCalculationModal,
} from '../../model/private'
import { CostCalculationModal, TableCell, HeaderTableCell } from '../parts'

export const CounterReadings = memo(() => {
  const info = useUnit($documentInfo)
  const isModalOpen = useUnit($isCostCalculationModalOpen)

  const hasLoses = useMemo(() => (
    Boolean(info?.counters_details.some((item) => parseFloat(item.loses ?? '0')))
  ), [info])

  if (!info?.counters_details.length) return null

  const { counters_details, counters_total } = info

  return (
    <Wrapper>
      <Header>
        <Title>Показания счётчиков</Title>
        <OpenModalButton onClick={() => openCostCalculationModal()}>
          Расчёт стоимости
        </OpenModalButton>
      </Header>

      <div>
        <TableRow hasLoses={hasLoses}>
          <HeaderTableCell value="№ счётчика" align="left" isBold />
          <HeaderTableCell value="КТ, кВ*ч" />
          {hasLoses && (
            <HeaderTableCell value="Потери, %" />
          )}

          <SplitCell>
            <HeaderTableCell value="Показания, кВ*ч" />
            <SplitCellRow>
              <HeaderTableCell value="Текущие" />
              <HeaderTableCell value="Предыдущие" />
            </SplitCellRow>
          </SplitCell>

          <HeaderTableCell value="Расход, кВ*ч" align="right" />
          <HeaderTableCell value="Тариф с НДС, руб" align="right" />
          {hasLoses && (
            <HeaderTableCell value="Сумма потерь, руб" align="right" />
          )}
          <HeaderTableCell value="Стоимость, включая потери, с НДС, руб" align="right" />
        </TableRow>

        {counters_details.map((item, i) => (
          <TableRow key={i} hasLoses={hasLoses}>
            <TableCell value={item.counter_number} valueType="text" align="left" />
            <TableCell value={item.coefficient_kwt_per_hour} />
            {hasLoses && (
              <TableCell value={item.loses} valueType="percent" />
            )}
            <TableCell value={item.current_indications} />
            <TableCell value={item.previous_indications} />
            <TableCell value={item.expenditure} align="right" />
            <TableCell value={item.cost_with_nds} align="right" />
            {hasLoses && (
              <TableCell value={item.amount_loses} align="right" />
            )}
            <TableCell value={item.cost_with_loses_and_nds} align="right" />
          </TableRow>
        ))}

        <TableRow hasLoses={hasLoses}>
          <TableCell
            value="ИТОГО"
            valueType="text"
            align="right"
            colSpan={hasLoses ? 8 : 6}
            isBold
          />
          <TableCell
            value={counters_total}
            align="right"
            isBold
          />
        </TableRow>
      </div>

      {isModalOpen && (
        <CostCalculationModal onClose={closeCostCalculationModal} />
      )}
    </Wrapper>
  )
})

const BORDER_STYLE = `1px solid ${palette.grey30}`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const Header = styled.header`
  display: flex;
  justify-content: space-between;
`

const Title = styled.p`
  ${TextL}
`

const OpenModalButton = styled(DefaultButton)`
  ${TextLLight}
  color: ${palette.lightBlue};
`

const TableRow = styled.div<{ hasLoses: boolean }>`
  display: grid;

  grid-template-columns: ${({ hasLoses }) => (hasLoses ? `
    minmax(0, 120fr) minmax(0, 60fr) minmax(0, 70fr) minmax(0, 120fr)
    minmax(0, 120fr) minmax(0, 110fr) minmax(0, 60fr) minmax(0, 80fr) minmax(0, 124fr);
  ` : `
    minmax(0, 162fr) minmax(0, 60fr) minmax(0, 132fr)
    minmax(0, 132fr) minmax(0, 140fr) minmax(0, 80fr) minmax(0, 160fr);
  `)};

  &:not(:last-child) {
    border-bottom: ${BORDER_STYLE};
  }
`

const SplitCell = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: span 2;

  & > * {
    height: 50%;
  }

  & > *:not(:last-child) {
    border-bottom: ${BORDER_STYLE};
  }
`

const SplitCellRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  border-right: ${BORDER_STYLE};
`
