import { Rule } from 'effector-forms'

// Если condition возвращает true, то валидатор срабатывает, если false, то мы скипаем валидацию
export const withDisableCondition = <V, F>(
  condition: (fields?: F) => boolean,
  validator: Rule<V, F>,
): Rule<V, F> => ({
    name: validator.name,
    validator: (value, fields) => (
      condition(fields) ? validator.validator(value, fields) : true),
    errorText: validator.errorText,
  })

export const rules = {
  allowSymbols: (reg: RegExp): Rule<string> => ({
    name: 'allowSymbols',
    validator: (value) => {
      if (value === '') return true
      return reg.test(value)
    },
    errorText: 'Недопустимые символы',
  }),

  confirmPassword: () => ({
    name: 'confirmation',
    validator: (confirmation: string, { password }: { password: string }) => (
      confirmation === password
    ),
    errorText: 'Пароли не совпадают',
  }),

  minLength: (length: number): Rule<string> => ({
    name: 'minLength',
    validator: (value) => {
      if (value === '') return true
      return value.length >= length
    },
    errorText: `Длина не менее ${length} символов`,
  }),

  inn: () => ({
    name: 'inn',
    validator: (value: string) => {
      if (value === '') return true
      return value.length === 10 || value.length === 12
    },
    errorText: 'ИНН должен быть длиной в 10 или 12 символов',
  }),

  maxLength: (length: number): Rule<string> => ({
    name: 'maxLength',
    validator: (value) => {
      if (!value) return true
      return value.length <= length
    },
    errorText: `Введено максимальное кол-во символов – ${length}`,
  }),

  exactLength: (length: number): Rule<string> => ({
    name: 'exactLength',
    validator: (value) => {
      if (!value) return true
      return value.length === length
    },
    errorText: `Требуется точное количество символов = ${length}`,
  }),

  required: (): Rule<string | number | null> => ({
    name: 'required',
    validator: (value) => (
      Boolean(typeof value === 'number' || value?.trim())
    ),
    errorText: 'Обязательное поле для заполнения',
  }),

  requiredArray: () => ({
    name: 'requiredArray',
    validator: (value: unknown[]) => Boolean(value.length),
    errorText: 'Обязательное поле для заполнения',
  }),

  email: () => ({
    name: 'email',
    validator: (value: string) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/
      return emailRegex.test(value)
    },
    errorText: 'Неправильный email',
  }),

  maxFiles: (length: number) => ({
    name: 'maxFiles',
    validator: (value: File[]) => value.length <= length,
    errorText: 'Превышено количество загруженных файлов',
  }),

  phoneNumber: () => ({
    name: 'phone',
    validator: (value: string) => {
      if (value === '') return true
      return `+7${value}`.length === 12
    },
    errorText: 'Неправильный номер телефона',
  }),

  fullName: () => ({
    name: 'fullName',
    validator: (value: string) => {
      if (value === '') return true
      return value.trim().split(' ').filter((s) => s).length === 3
    },
    errorText: 'ФИО должны быть верные',
  }),

  contactProject: () => ({
    name: '',
    validator: (value: { projectId: number | null, roles: unknown[] }[]) => Boolean(
      value.length && value.every((item) => Boolean(item.projectId && item.roles.length > 0)),
    ),
    errorText: 'Не выбран проект или роль',
  }),

  dateRange: <V extends string | null, F>(
    fieldName: keyof F,
    isStartDate?: boolean,
  ): Rule<V, F> => ({
    name: 'dateRange',
    validator: (value, fields) => {
      if (!value || !fields?.[fieldName]) return true
      return isStartDate
        ? value < fields[fieldName]
        : value > fields[fieldName]
    },
    errorText: 'Неверный период',
  }),

  greaterThanZero: () => ({
    name: 'greaterThanZero',
    validator: (value: string) => {
      if (!value) return true
      const number = parseFloat(value) || 0
      return number > 0
    },
    errorText: 'Указанный объем должен быть больше нуля',
  }),

  sumBothFieldsMustBeGreaterThanZero: <V extends string, F extends { [key in keyof F]: string }>(
    second: keyof F,
  ): Rule<V, F> => ({
    name: '',
    validator: (value, fields) => {
      const a = parseFloat(value)
      const b = parseFloat(fields?.[second] ?? '')
      const sum = (!Number.isNaN(a) ? a : 0) + (!Number.isNaN(b) ? b : 0)
      return sum > 0
    },
    errorText: 'Сумма должна быть больше 0',
  }),
}
