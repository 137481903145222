import { ActWorkStatus, RemainedFilter, RowFilterEnum } from '@/dal'
import { RowFilterItem } from './types'

export const PIR_STATUSES = {
  [ActWorkStatus.NotCompleted]: 'Не выполнено',
  [ActWorkStatus.Declared]: 'Заявлено',
  [ActWorkStatus.InterimAcceptance]: 'Промежуточная приемка',
  [ActWorkStatus.Completed]: 'Выполнено',
}

export const ROW_FILTERS: RowFilterItem[] = [
  { id: RowFilterEnum.IsCorrected, label: 'Скорректированы тех. надзором ' },
  { id: RowFilterEnum.HideDeleted, label: 'Скрыть удаленные' },
]

export const SMR_REMAINED_FILTERS = [
  { id: RemainedFilter.FilledJobs, label: 'Незакрытые работы > 0' },
  { id: RemainedFilter.UnFilledJobs, label: 'Выполненные работы = 0' },
  { id: RemainedFilter.CompletedJobs, label: 'Ранее сданные работы < 0' },
]

export const PERIOD_COMPLETED_FILTERS = [
  { id: RemainedFilter.FilledJobs, label: 'Заполненные работы > 0' },
  { id: RemainedFilter.UnFilledJobs, label: 'Незаполненные работы = 0' },
  { id: RemainedFilter.CompletedJobs, label: 'Ранее сданные работы < 0' },
]

export const PIR_REMAINED_FILTERS = [
  { id: RemainedFilter.FilledJobs, label: 'Незакрытые работы' },
  { id: RemainedFilter.UnFilledJobs, label: 'Выполненные работы' },
]

export const PIR_STATUS_FILTERS = [
  { id: ActWorkStatus.NotCompleted, label: 'Не выполнено' },
  { id: ActWorkStatus.Declared, label: 'Заявлено' },
  { id: ActWorkStatus.InterimAcceptance, label: 'Промежуточная приемка' },
  { id: ActWorkStatus.Completed, label: 'Выполнено' },
]
