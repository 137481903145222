import React from 'react'
import styled, { css } from 'styled-components'
import { createPortal } from 'react-dom'

import { useTooltip } from '@/lib/hooks'
import { mergeRefs } from '@/lib/mergeRefs'

import { ToolTipV2 } from './tooltip'
import { palette } from '../palette'
import { TextS } from '../typography'

type Props = {
  maxTooltipWidth?: number
  children: string | React.JSX.Element
  maxLines?: number
  sameComponentWidth?: boolean,
}

export const OverflowText = ({
  maxTooltipWidth,
  children,
  maxLines = 1,
  sameComponentWidth,
}: Props) => {
  const [isOverflowed, setIsOverflowed] = React.useState(false)
  const [tooltipText, setTooltipText] = React.useState<string | null>(null)

  const contentRef = React.useRef<HTMLDivElement | null>(null)

  const {
    floatingStyles,
    getFloatingProps,
    getReferenceProps,
    isOpen: isHovered,
    refs,
    arrowProps,
  } = useTooltip({ placement: 'bottom', tooltipOffset: 5, sameComponentWidth })

  React.useEffect(() => {
    if (!contentRef.current) return

    const isOverflowed = maxLines > 1
      ? contentRef.current.clientHeight < contentRef.current.scrollHeight
      : contentRef.current.clientWidth < contentRef.current.scrollWidth
    setIsOverflowed(isOverflowed)
  }, [isHovered])

  React.useEffect(() => {
    if (!contentRef.current) return
    setTooltipText(contentRef.current.textContent)
  }, [children, contentRef])

  return (
    <>
      <ContentWrapper
        maxLines={maxLines}
        ref={mergeRefs([contentRef, refs.setReference])}
        {...getReferenceProps()}
      >
        {children}
      </ContentWrapper>

      {createPortal(
        <ToolTipV2
          floatingStyles={floatingStyles}
          getFloatingProps={getFloatingProps}
          isOpen={isHovered && isOverflowed}
          refs={refs}
          arrowProps={arrowProps}
        >
          <TooltipText maxTooltipWidth={maxTooltipWidth}>
            {tooltipText}
          </TooltipText>
        </ToolTipV2>,
        document.body,
      )}
    </>

  )
}

const ContentWrapper = styled.div<{ maxLines: number }>`
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  width: 100%;

  ${({ maxLines }) => maxLines === 1 && css`
    white-space: nowrap;
  `}

  ${({ maxLines }) => maxLines > 1 && css`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${maxLines};
  `}

  & > * {
    display: inline;
  }
`

const TooltipText = styled.div<{ maxTooltipWidth?: number }>`
  word-break: break-word;
  ${TextS}
  color: ${palette.white};

  ${({ maxTooltipWidth }) => maxTooltipWidth && css`
    max-width: ${maxTooltipWidth}px;
  `}
`
