import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { formatNumberByFourDigits } from '@/lib/number-represent'

import { InfoItem } from '../../../shared-components'
import { InfoNotification, PaymentDocsNotification } from '../parts'
import {
  $isDraft,
  $isThirdPerson,
  $paymentInfo,
  $paymentRequisites,
  downloadTemplateLink,
} from '../../../model/private'

export const Requisites = React.memo(() => {
  const [info, requisites, isThirdPerson, isDraft] = useUnit([
    $paymentInfo,
    $paymentRequisites,
    $isThirdPerson,
    $isDraft,
  ])

  if (!info || !requisites) return null

  return (
    <Wrapper>
      {requisites && (
        <ColWrapper>
          <RequisitesCol>
            <InfoItem label="Плательщик" text={requisites.payer.name} />
            <InfoItem label="Р/с плательщика" text={formatNumberByFourDigits(requisites.payer.account_number)} />
            <InfoItem label="Банк плательщика" text={requisites.payer.bank_name} />
          </RequisitesCol>
          <RequisitesCol>
            <InfoItem label="Получатель" text={requisites.payee.name} />
            <InfoItem label="Р/с получателя" text={formatNumberByFourDigits(requisites.payee.account_number ?? '')} />
            <InfoItem label="Банк получателя" text={requisites.payee.bank_name ?? ''} />
          </RequisitesCol>

          {isDraft && !isThirdPerson && (
            <>
              <InfoNotification />
              <PaymentDocsNotification onDownload={downloadTemplateLink} />
            </>
          )}
        </ColWrapper>
      )}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
`

const ColWrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
`

const RequisitesCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
`
