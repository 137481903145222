import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'
import { useForm } from 'effector-forms'

import {
  HeadL,
  ModalLayoutV2,
  NewButton,
  RadioButtons,
  TextAreaField,
} from '@/ui'
import { useOnChangeLocation } from '@/lib/useOnChangeLocation'
import { noop } from '@/lib/noop'

import {
  $documentId,
  $isApprovingModalOpen,
  $isWithComment,
  $tabItems,
  approveCompensationFx,
  approveForm,
  changeWithComment,
  closeApprovingModal,
  MAX_COMMENT_LENGTH,
} from '../../model/private'
import { WithComment } from '../../model/types'

export const ApprovingModal = React.memo(() => {
  const [id, isOpen] = useUnit([
    $documentId,
    $isApprovingModalOpen,
  ])

  const isWithComment = useUnit($isWithComment)
  const tabsItem = useUnit($tabItems)
  const isPending = useUnit(approveCompensationFx.pending)

  const { fields, submit, isValid } = useForm(approveForm)

  useOnChangeLocation(closeApprovingModal)

  if (!id || !isOpen) return null

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    submit()
  }

  return (
    <ModalLayoutV2
      closeModal={isPending ? noop : closeApprovingModal}
      dataTestId={`approve-modal-${id}`}
    >
      <Wrapper>
        <Header>
          Согласование документа
        </Header>

        <RadioButtons
          items={tabsItem}
          checkedVal={isWithComment}
          onChange={changeWithComment}
          radioSetName="isComment"
        />

        <Form onSubmit={handleSubmit}>
          {isWithComment === WithComment.Yes && (
            <TextAreaField
              dataTestId="approve-comment-input"
              label="Комментарий"
              onChange={fields.comment.onChange}
              autoGrow
              value={fields.comment.value}
              currentSymbolCount={fields.comment.value.length}
              maxSymbolCount={MAX_COMMENT_LENGTH}
              required
              error={fields.comment.firstError?.errorText}
              height={100}
              maxHeight={350}
              disabled={isPending}
              placeholder="Укажите причину несогласия с новыми условиями"
            />
          )}
          <Row>
            <NewButton
              label="Согласовать"
              type="submit"
              dataTestId="submit-approve-form"
              isDisabled={!isValid}
              isPending={isPending}
              isFill
            />
            <NewButton
              label="Отменить"
              buttonType="grey"
              dataTestId="close-approve-modal"
              isDisabled={isPending}
              isFill
              onClick={closeApprovingModal}
            />
          </Row>
        </Form>
      </Wrapper>
    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 500px;
`

const Header = styled.div`
  ${HeadL}
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const Row = styled.div`
  display: flex;
  gap: 16px;
`
