import { createForm } from 'effector-forms'
import { attach } from 'effector'

import { rules } from '@/lib/form-validators'
import { repeatRegistrationLetterReqFx } from '@/dal'

export const repeatForm = createForm({
  fields: {
    email: {
      init: '',
      rules: [rules.required(), rules.email()],
    },
  },
})

export const repeatRegistrationLetterFx = attach({
  effect: repeatRegistrationLetterReqFx,
})
