import { createForm } from 'effector-forms'

import { rules } from '@/lib/form-validators'

import { FILE_MAX_COUNT } from '../../model'
import { d } from './domain'

export const appealForm = createForm({
  domain: d,
  validateOn: ['submit'],
  fields: {
    document: {
      init: null as number | null,
      rules: [
        rules.required(),
      ],
    },
    subject: {
      init: '',
      rules: [
        rules.required(), rules.minLength(4),
      ],
    },
    description: {
      init: '',
      rules: [
        rules.required(), rules.minLength(4),
      ],
    },
    files: {
      init: [] as File[],
      rules: [rules.maxFiles(FILE_MAX_COUNT)],
    },
  },
})
