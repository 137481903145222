import { sample } from 'effector'

import {
  isAcreditationFailedError,
  isAcreditationTimeOutError,
  isAccreditationInProccessError,
  isCompanyNotFoundError,
  isAlreadyExistsInLKPError,
  RequestForAccessHandledError,
} from '@/dal'
import { createToast } from '@/features/toast-service/model'
import {
  $currentStep,
  goToSecondStep,
  goToFirstStep,
  innForm,
  checkINNFx,
  RequestForAccessGate,
  $innError,
  setInnError,
  $companyInfo,
  sendCompanyInfoFx,
  companyInfoForm,
  getOrganizationsFx,
  $organizations,
} from './private'
import { INNError, Step } from './types'

$currentStep
  .on(goToFirstStep, () => Step.First)
  .on(goToSecondStep, () => Step.Second)
  .on(sendCompanyInfoFx.doneData, () => Step.Success)
  .reset(RequestForAccessGate.close)

$innError
  .on(setInnError, (_, error) => error)
  .reset(RequestForAccessGate.close, goToFirstStep)

$companyInfo
  .on(setInnError, (_, error) => ({
    inn: error.data.inn,
    name: error.data.company_name || null,
    projects: [],
  }))
  .on(checkINNFx.doneData, (_, info) => info)
  .reset(RequestForAccessGate.close, goToFirstStep)

$organizations
  .on(getOrganizationsFx.doneData, (_, data) => data)
  .reset(RequestForAccessGate.close)

sample({
  clock: innForm.formValidated,
  fn: ({ inn }) => inn,
  target: checkINNFx,
})

sample({
  clock: [
    checkINNFx.doneData,
    setInnError,
  ],
  target: [goToSecondStep, getOrganizationsFx],
})

sample({
  clock: RequestForAccessGate.close,
  target: [innForm.reset, companyInfoForm.reset],
})

sample({
  clock: companyInfoForm.formValidated,
  source: $companyInfo,
  filter: Boolean,
  fn: ({ inn }, form) => ({
    inn,
    ...form,
    company_fsk: form.company_fsk as number,
    project: form.project as number,
  }),
  target: sendCompanyInfoFx,
})

sample({
  clock: checkINNFx.failData,
  filter: (error: RequestForAccessHandledError): error is INNError => {
    return isCompanyNotFoundError(error)
      || isAcreditationTimeOutError(error)
      || isAccreditationInProccessError(error)
      || isAlreadyExistsInLKPError(error)
      || isAcreditationFailedError(error)
  },
  target: setInnError,
})

createToast({
  effect: sendCompanyInfoFx,
})
