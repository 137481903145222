import React from 'react'
import styled from 'styled-components'

import { CircleProgress } from '@/ui'
import { ClosurePercentage } from '@/dal'
import { UpdateClosurePercentage } from '@/features/administrative/share'

import { ProgressLabel } from './ProgressLabel'

type Props = {
  progress: ClosurePercentage['progress']
  hasAdvance?: boolean
  closureUpdateDate: string // ISODate
  isClosureUpdating: boolean
  isClosureUpdateDisabled: boolean
  onUpdateClosure: (e: React.MouseEvent) => void
}

export const Progress = ({
  progress,
  hasAdvance = true,
  closureUpdateDate,
  isClosureUpdating,
  isClosureUpdateDisabled,
  onUpdateClosure,
}: Props) => {
  if (!progress) return null

  const getFilledPercent = (val: string) => {
    const numberVal = parseInt(val, 10)
    if (!Number.isNaN(numberVal)) return numberVal
    return 0.5 // Дефолтное значение прогресса
  }

  const {
    act,
    advance_credited,
    paid_advance,
  } = progress

  return (
    <Wrapper>
      <CircleCol>
        {hasAdvance && paid_advance && (
          <CircleWrapper>
            <CircleProgress
              stroke="accent70"
              filledPercent={getFilledPercent(paid_advance.total_percent)}
              fixedSize={59}
              strokeWidth={6}
            />
          </CircleWrapper>
        )}

        <CircleWrapper>
          <CircleProgress
            stroke="pink100"
            filledPercent={getFilledPercent(act.total_percent)}
            fixedSize={39}
            strokeWidth={5.5}
          />
        </CircleWrapper>

        {hasAdvance && advance_credited && (
          <CircleWrapper>
            <CircleProgress
              stroke="violet"
              filledPercent={getFilledPercent(advance_credited.total_percent)}
              fixedSize={19}
              strokeWidth={5.5}
            />
          </CircleWrapper>
        )}
      </CircleCol>

      <InfoCol>
        <ProgressList>
          {hasAdvance && paid_advance && (
            <ProgressLabel
              label="аванс – выплачено"
              color="accent70"
              total_percent={paid_advance.total_percent}
              items={[
                {
                  label: 'Всего',
                  text: paid_advance.total_sum,
                },
                paid_advance.paid && {
                  label: 'выплачено',
                  color: 'accent70',
                  text: paid_advance.paid.value,
                  percent: paid_advance.paid.percentage,
                },
                paid_advance.remaining_paid && {
                  label: 'осталось',
                  color: 'grey30',
                  text: paid_advance.remaining_paid.value,
                  percent: paid_advance.remaining_paid.percentage,
                },
              ]}
            />
          )}
          <ProgressLabel
            label="выполнения"
            color="pink100"
            total_percent={act.total_percent}
            items={[
              {
                label: 'Всего',
                text: act.total_sum,
              },
              {
                label: 'выполнено',
                color: 'pink100',
                text: act.completed.value,
                percent: act.completed.percentage,
              },
              {
                label: 'осталось',
                color: 'grey30',
                text: act.remaining.value,
                percent: act.remaining.percentage,
              },
            ]}
          />
          {hasAdvance && advance_credited && (
            <ProgressLabel
              label="аванс – зачтено"
              color="violet"
              total_percent={advance_credited.total_percent}
              items={[
                {
                  label: 'Всего',
                  text: advance_credited.total_sum,
                },
                {
                  label: 'зачтено',
                  color: 'violet',
                  text: advance_credited.passed.value,
                  percent: advance_credited.passed.percentage,
                },
                advance_credited.remaining_passed && {
                  label: 'осталось',
                  color: 'grey30',
                  text: advance_credited.remaining_passed.value,
                  percent: advance_credited.remaining_passed.percentage,
                },
              ]}
            />
          )}
        </ProgressList>

        <UpdateClosurePercentage
          date={closureUpdateDate}
          size="S"
          isLoading={isClosureUpdating}
          isDisabled={isClosureUpdateDisabled}
          onUpdate={onUpdateClosure}
        />
      </InfoCol>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`

const CircleCol = styled.div`
  height: 59px;
  position: relative;
  width: 59px;
  flex-shrink: 0;
`

const CircleWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const InfoCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const ProgressList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`
