import React from 'react'
import styled from 'styled-components'

import {
  ContractDirectionLabel, NewTextButton, TextMLight, palette,
} from '@/ui'
import {
  ContractDirection,
  ContractDirectionItem,
  ContractItem,
  ContractVisibility,
  DocumentAlias,
  FeaturesForDocument,
} from '@/dal'
import { CreateDocumentButton } from '@/features/administrative/share'

import { TableTemplate } from './TableTemplate'
import { ChangeVisibilityParams } from '../../model/types'

type Props = {
  id: ContractItem['id']
  visibility: ContractItem['visibility']
  onCreateDocument: (type: DocumentAlias) => void
  onChangeVisibility: (params: ChangeVisibilityParams) => void
  onOpenContractReports: (id: ContractItem['id']) => void
  isVisibilityPending: boolean
  areActionsShown: boolean
  directionType: ContractDirection
  directions: ContractDirectionItem[] | null
  features: {
    canCreateAct: FeaturesForDocument['create']
    canCreateKorDoc: FeaturesForDocument['create']
    canCreateBudget: FeaturesForDocument['create']
    canCreatePayment: FeaturesForDocument['create']
    canChangeVisibility: boolean
    canGenerateReport: boolean
    canGoToAllDocuments: boolean
  }
}

const CHANGE_VISIBILITY_LABELS = {
  [ContractVisibility.visible]: 'Скрыть договор',
  [ContractVisibility.hidden]: 'Переместить в видимые',
}

export const BottomRow = ({
  id,
  visibility,
  features,
  isVisibilityPending,
  areActionsShown,
  directionType,
  directions,
  onCreateDocument,
  onChangeVisibility,
  onOpenContractReports,
}: Props) => {
  const preventClick = (e: React.MouseEvent | undefined) => {
    e?.preventDefault()
    e?.stopPropagation()
  }

  const handleChangeVisibility = (e: React.MouseEvent | undefined) => {
    preventClick(e)
    onChangeVisibility({ currentVal: visibility, id })
  }

  const handleCreateDocument = (type: DocumentAlias) => {
    onCreateDocument(type)
  }

  const {
    canChangeVisibility,
    canCreateAct,
    canCreateBudget,
    canCreateKorDoc,
    canCreatePayment,
    canGenerateReport,
  } = features

  return (
    <Container>
      <FirstCellWrapper>
        <ContractDirectionLabel type={directionType} directions={directions} />
      </FirstCellWrapper>

      {areActionsShown && (
        <Actions>
          <CreateDocumentButton
            id={id}
            canCreateFeature={canCreateAct}
            docType={DocumentAlias.ACT}
            onCreateDocument={handleCreateDocument}
          />
          <CreateDocumentButton
            id={id}
            canCreateFeature={canCreateKorDoc}
            docType={DocumentAlias.KOR_DOC}
            onCreateDocument={handleCreateDocument}
          />
          <CreateDocumentButton
            id={id}
            canCreateFeature={canCreateBudget}
            docType={DocumentAlias.BUDGET}
            onCreateDocument={handleCreateDocument}
          />
          <CreateDocumentButton
            id={id}
            canCreateFeature={canCreatePayment}
            docType={DocumentAlias.PAYMENT}
            onCreateDocument={handleCreateDocument}
            label="Аванс"
          />
          <span>|</span>
          <NewTextButton
            textStyle="ML"
            dataTestId={`generate-report-for-${id}`}
            isDisabled={!canGenerateReport}
            label="Сформировать отчет"
            onClick={(e) => {
              preventClick(e)
              onOpenContractReports(id)
            }}
          />
          {canChangeVisibility && (
            <NewTextButton
              textStyle="ML"
              isDisabled={isVisibilityPending}
              dataTestId={`change-visibility-for-${id}`}
              label={CHANGE_VISIBILITY_LABELS[visibility]}
              onClick={handleChangeVisibility}
            />
          )}
        </Actions>
      )}

    </Container>
  )
}

const Container = styled.div`
  ${TableTemplate}
  padding-bottom: 16px;
`

const FirstCellWrapper = styled.div`
  padding-left: 38px;
  width: max-content;
`

const Actions = styled.div`
  grid-column-start: 2;
  grid-column-end: -1;
  display: flex;
  gap: 12px;
  ${TextMLight};
  color: ${palette.accent100};
`
