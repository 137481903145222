import React from 'react'
import styled, { css } from 'styled-components'
import { Icon } from './Icon'
import { themeVar } from './theming'
import { BaseTheme } from './theming/themes'

type Props = {
  children: React.ReactNode
  headComponent: React.ReactNode
  isActive: boolean
  onClick: () => void
  backgroundColor?: keyof BaseTheme | 'tranparent'
  padding?: string
}

export const Accordion = ({
  children,
  headComponent,
  isActive,
  onClick,
  backgroundColor = 'lightBackground',
  padding,
}: Props) => (
  <Accrodion backgroundColor={backgroundColor}>
    <AccordionHead padding={padding} onClick={onClick}>
      {headComponent}
      <AccordionArrow isActive={isActive}>
        <Icon icon="accordion-arrow" />
      </AccordionArrow>
    </AccordionHead>
    {isActive && children}
  </Accrodion>
)

const Accrodion = styled.div<{backgroundColor: keyof BaseTheme | 'tranparent'}>`
  background-color: ${
  ({ backgroundColor }) => (backgroundColor !== 'tranparent' ? themeVar(backgroundColor) : backgroundColor)
};
`

const AccordionHead = styled.div<{padding?: string}>` 
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: ${({ padding }) => padding ?? '0px'};
`

const AccordionArrow = styled.div<Pick<Props, 'isActive'>>`
  color: ${themeVar('accordionArrowColor')};
  width: 18px;
  height: 16px;

  ${({ isActive }) => !isActive && css`
    transform: rotate(180deg);
  `}
`
