import { EstimateDoc, EstimateDocId, EstimateTableRow } from '@/dal'
import { DefaultUpdateItems, TreeType as Tree } from '@/lib/tree'
import { IconName2 } from '@/ui'

export type ActionsForItem = {
  icon?: IconName2,
  label: string
  onClick: () => void
}

export enum ItemAction {
  Edit = 'edit',
  Reset = 'reset',
  AddRow = 'addRow',
  AddFolder = 'addFolder',
  SplitVolume = 'splitVolume',
  Delete = 'delete'
}

export type OnOption = {
  id: EstimateTableRow['id'],
  type: ItemAction
  parent: EstimateTableRow['parent'] | null
  isFolder: boolean
}

export type OnUpdateItemsParams = {
  // updated root item, for update a root array
  root?: EstimateTableRow | undefined
  // tree updated items
  tree?: { [key in EstimateTableRow['id']]: EstimateTableRow[] } | undefined

  summary?: EstimateDoc['summary_table']
  target?: EstimateTableRow
  // doc id, for check which document was update
  documentId: EstimateDocId
  index?: number
  updatingItemId?: EstimateTableRow['id']
}

export type OnDeleteItemParams = OnUpdateItemsParams & {
  deletedId: EstimateTableRow['id']
}

export type DeleteItemParamsFx = DefaultUpdateItems<EstimateTableRow> & {
  currentRoot: EstimateTableRow[] | null
  currentTree: { [key in EstimateTableRow['id']]: EstimateTableRow[] }
  deletedId: EstimateTableRow['id']
}

export type TableUpdatePayload = {
  root: EstimateTableRow[] | null,
  tree?: TreeType
}

export type TreeType = Tree<EstimateTableRow>

export type CloseFoldersParams = {
  map: TreeType,
  ids: EstimateTableRow['id'][]
}

export enum CheckboxState {
  Checked = 'checked',
  Unchecked = 'unchecked',
  Indeterminate = 'indeterminate',
}

export type ResettingRowItem = {
  id: EstimateTableRow['id']
  parentId: EstimateTableRow['id'] | null
  children: EstimateTableRow['id'][] | null
  state: CheckboxState
  isFolder: boolean
  totalDescendantCount: number
}

export type ResettingRowsMap = {
  [key: EstimateTableRow['id']]: ResettingRowItem
}

export type FlatResettingRows = {
  [key: EstimateTableRow['id']]: {
    id: EstimateTableRow['id']
    state: CheckboxState
  }
}

export type SpecificFieldPayload<T = unknown> = {
  id: EstimateTableRow['id']
  value: T
  name: string
}

export type NonFormField<T = unknown> = {
  name: string
  value: T
  isValid: boolean
  onChange: (value: T) => T | void
}

export type ItemToFocus = {
  id: EstimateTableRow['id']
  fieldName: string
  isNewLines: boolean
  hasToScroll: boolean
}

export type Direction = 'up' | 'down' | 'left' | 'right'

export type OnEnterSubmitItem = Omit<ItemToFocus, 'hasToScroll'> & {
  direction: Direction
}
