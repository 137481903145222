import { ApprovementProcessStatus } from '@/dal'

export const getProcessStatusText = (status: ApprovementProcessStatus) => {
  switch (status) {
    case ApprovementProcessStatus.Approved:
      return 'Согласовано'
    case ApprovementProcessStatus.Rejected:
      return 'Отклонено'
    case ApprovementProcessStatus.SentToApproving:
    default:
      return 'Отправлено на согласование'
  }
}
