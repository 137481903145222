import React from 'react'
import { useUnit } from 'effector-react'

import { ConfirmLayout } from '../parts'
import { createPaymentFx, onConfirmCreatePayment } from '../../model/create-payment.private'
import { $createDocumentInfo, closeCreateDocumentModal } from '../../model/private'

export const CreatePayment = React.memo(() => {
  const isLoading = useUnit(createPaymentFx.pending)
  const info = useUnit($createDocumentInfo)

  return (
    <ConfirmLayout
      text="Запросить аванс"
      contractName={info?.contractName}
      isLoading={isLoading}
      confirmLabel="Запросить"
      onConfirm={onConfirmCreatePayment}
      onClose={closeCreateDocumentModal}
    />
  )
})

