import {
  AbortableRequest,
  Contract,
  FetchFilterPayload,
  GetDocFlowListData,
  GetDocFlowListResponse,
  getDocumentListReqFx,
} from '@/dal'
import { createPagination } from '@/features/shared/createPagination'
import { createSingleEffect } from '@/lib/createSingleEffect'

import { d } from './domain'
import { DEFAULT_LIMIT } from './const'
import { DocumentFlowGate, getFiltersFx } from './private'

export const $contractDocFlowList = d.store<Contract[]>([])

export const {
  $hasMore,
  paginationEffect: loadMoreFx,
  $offset,
  $total,
  initEffect: initDocumentsListFx,
} = createPagination<AbortableRequest<FetchFilterPayload>, GetDocFlowListData>({
  domain: d,
  fetchEffect: getDocumentListReqFx,
  limit: DEFAULT_LIMIT,
})

export const {
  requestFx: initDocumentsFx,
  abortFx,
} = createSingleEffect<
  AbortableRequest<FetchFilterPayload>,
  GetDocFlowListResponse
>(initDocumentsListFx)

export const {
  requestFx: loadMoreDocumentsFx,
  abortFx: abortLoadMoreDocumentsFx,
} = createSingleEffect(loadMoreFx)

export const $areDocumentsLoading = initDocumentsListFx.pending

export const $areFiltersLoading = getFiltersFx.pending

export const setDocumentsFlowFilters = d.event<FetchFilterPayload>()
export const onDocListLoaded = d.event<GetDocFlowListResponse>()

export const $contractInfo = DocumentFlowGate.state.map((state) => (
  state.contractId ? state : null
))
