import { createForm } from 'effector-forms'
import { attach } from 'effector'

import { rules } from '@/lib/form-validators'
import { createContragentReqFx } from '@/dal'

import { d } from './domain'
import { ContragentInfo } from './types'

export const $isCreateContragentOpen = d.store(false)
export const closeCreateContragent = d.event<void>()

export const $isPreview = d.store(false)
export const openCreateContragent = d.event<ContragentInfo>()
export const openNewContragentPreview = d.event<ContragentInfo>()

export const $isShowSuccess = d.store(false)

export const createContragentForm = createForm({
  fields: {
    inn: {
      init: '',
    },
    name: {
      init: '',
    },
    kpp: {
      init: '',
    },
    ogrn: {
      init: '',
    },
    bic: {
      init: '',
      rules: [rules.required(), rules.exactLength(9)],
    },
    account: {
      init: '',
      rules: [rules.required(), rules.exactLength(20)],
    },
  },
})

export const createContragentFx = attach({
  effect: createContragentReqFx,
})
