import { sample } from 'effector'

import { putUserInfoFx } from '@/features/login/model'

import {
  EditProfileGate,
  profileForm,
} from './private'
import { $userData } from './public'

sample({
  clock: [EditProfileGate.open, $userData.updates],
  source: $userData,
  filter: Boolean,
  target: profileForm.setInitialForm,
})

sample({
  clock: EditProfileGate.close,
  target: profileForm.reset,
})

sample({
  clock: profileForm.formValidated,
  fn: ({ fullName, phone, position }) => {
    const [last_name, first_name, patronymic] = fullName.split(' ')
    const phoneWithCode = `+7${phone}`
    return {
      last_name,
      first_name,
      patronymic,
      position,
      phone: phoneWithCode,
    }
  },
  target: putUserInfoFx,
})

sample({
  clock: putUserInfoFx.doneData,
  target: profileForm.resetTouched,
})
