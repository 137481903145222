import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { TextM, TextMLight } from '@/ui'
import { numberWithSpaces } from '@/lib/number-represent'

import { $documentInfo } from '../../model/private'

export const InfoTable = React.memo(() => {
  const info = useUnit($documentInfo)

  if (!info) return null

  return (
    <Wrapper>
      {info.act_number && (
        <>
          <Name>№ Акта</Name>
          <Value>{info.act_number}</Value>
        </>
      )}

      {info.reporting_date && (
        <>
          <Name>Период потребления</Name>
          <Value>{info.reporting_date.toLowerCase()}</Value>
        </>
      )}

      {info.summ && (
        <>
          <Name>Сумма</Name>
          <Value>{numberWithSpaces(info.summ)}</Value>
        </>
      )}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 137px));
  grid-auto-rows: auto;
  gap: 12px;
`

const Name = styled.p`
  ${TextM}
`

const Value = styled.p`
  ${TextMLight}
`
