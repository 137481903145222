import * as React from 'react'
import {
  ThemeProvider as StyledComponentThemeProvider,
} from 'styled-components'
import { useUnit } from 'effector-react'
import { $currentTheme } from './model/public'

type Props = {
  children: React.ReactChild
}

export const ThemeProvider = ({ children }: Props) => {
  const theme = useUnit($currentTheme)
  return (
    <StyledComponentThemeProvider theme={theme.variables}>
      {children}
    </StyledComponentThemeProvider>
  )
}
