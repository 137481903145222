import styled, { css } from 'styled-components'

import {
  TextM, TextS, palette, WINDOW_WIDTH,
} from '@/ui'

export const Wrapper = styled.div<{ isActive: boolean, isInDevelopment: boolean }>`
  ${TextM}
  padding: 7px 12px;
  color: ${palette.white};
  transition:  background-color 0.15s linear;
  border-radius: 8px;
  display: flex;
  gap: 4px;
  align-items: center;

  @media (max-width: ${WINDOW_WIDTH.smallDesktop}px) {
    ${TextS}
    color: ${palette.white};
  }

  ${({ isInDevelopment }) => isInDevelopment && css`
    cursor: not-allowed;
    opacity: 0.5;
  `}

  ${({ isInDevelopment, isActive }) => (!isInDevelopment && !isActive) && css`
    &:hover {
      background-color: rgba(${palette.rgbGrey}, 0.4);
    }
  `}

  ${({ isActive }) => isActive && css`
    background-color: ${palette.accent100};
  `}
`

export const MessageDot = styled.div`
  height: 6px;
  width: 6px;
  margin: 3px;
  border-radius: 50%;
  background-color: ${palette.white};
`
