import React from 'react'
import styled, { css } from 'styled-components'

import { numberWithSpaces } from '@/lib/number-represent'
import { TextM, TextMLight } from '../../typography'
import { palette } from '../../palette'
import { NewIconButton } from '../../buttons/NewIconButton'
import { Icon2 } from '../../icons'
import { NewCheckbox } from '../NewCheckbox'
import { TABLE_HEADER_BORDER_STYLE, TABLE_HEADER_THICK_BORDER_STYLE } from './const'

export type TableHeaderCellProps = {
  children?: React.ReactNode
  isHiddenCell?: boolean
  hasRightDivider?: boolean
  textAlign?: 'left' | 'center' | 'right'
  verticalAlign?: 'top' | 'center' | 'bottom'
  expandButtonAlign?: 'center' | 'bottom'
  label: string
  totalCost?: string
  groupNumber?: number
  isExpanded?: boolean
  info?: string
  checkboxLabel?: string
  checkboxPosition?: 'left' | 'right'
  isChecked?: boolean
  isDisableCheckbox?: boolean
  isFirst?: boolean
  expandButtonZIndex?: number
  padding?: string
  gap?: number
  isDividerTransparent?: boolean
  onToggle?: (isChecked: boolean) => void
  onExpandButton?: (groupNumber: number) => void
}

export const TableHeaderCell = ({
  children,
  hasRightDivider,
  isHiddenCell,
  label,
  textAlign = 'center',
  verticalAlign = 'center',
  expandButtonAlign = 'center',
  groupNumber,
  isExpanded,
  info,
  totalCost,
  checkboxLabel,
  checkboxPosition = 'right',
  isChecked,
  isDisableCheckbox,
  isFirst,
  expandButtonZIndex,
  padding,
  gap = 2,
  isDividerTransparent,
  onToggle,
  onExpandButton,
}: TableHeaderCellProps) => {
  return (
    <Cell
      isHiddenCell={isHiddenCell}
      hasRightDivider={hasRightDivider}
      isFirst={isFirst}
      verticalAlign={verticalAlign}
      padding={padding}
      gap={gap}
      isDividerTransparent={isDividerTransparent}
    >
      {groupNumber !== undefined && onExpandButton && (
        <ButtonWrapper
          isExpanded={Boolean(isExpanded)}
          zIndex={expandButtonZIndex}
          expandButtonAlign={expandButtonAlign}
        >
          <NewIconButton
            padding="4px"
            dataTestId={`expand-group-${groupNumber}`}
            icon={isExpanded ? 'arrowLeft' : 'arrowRight'}
            size={16}
            borderRadius="50%"
            color="white"
            backgroundColor={isExpanded ? 'grey80' : 'grey50'}
            hoverBackgroundColor={isExpanded ? 'grey80' : 'grey50'}
            onClick={() => onExpandButton(groupNumber)}
          />
        </ButtonWrapper>
      )}

      <LabelWrapper textAlign={textAlign}>
        {label}
        {info && <Icon2 icon="info" color="accent80" size={20} tooltipText={info} />}
      </LabelWrapper>

      {totalCost && (
        <SubLabel textAlign={textAlign}>
          {numberWithSpaces(totalCost)}
        </SubLabel>
      )}

      {onToggle && checkboxLabel && (
        <CheckboxWrapper>
          <NewCheckbox
            label={checkboxLabel}
            onChange={onToggle}
            isBoolean
            isChecked={isChecked}
            disabled={isDisableCheckbox}
            checkPosition={checkboxPosition}
          />
        </CheckboxWrapper>
      )}

      {children}
    </Cell>
  )
}

const verticalAlignments = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
}

type CellProps = Pick<
  TableHeaderCellProps,
  'isHiddenCell' | 'hasRightDivider' | 'isFirst' | 'verticalAlign' | 'padding' | 'gap' | 'isDividerTransparent'
>

const Cell = styled.div<CellProps>`
  position: relative;
  padding: 9px 12px;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: ${({ gap }) => `${gap}px`};
  background-color: ${({ isHiddenCell }) => (isHiddenCell ? palette.grey30 : palette.grey10)};

  &:not(:last-child) {
    border-right: ${TABLE_HEADER_BORDER_STYLE};
  
    ${({ hasRightDivider }) => hasRightDivider && css`
      border-right: ${TABLE_HEADER_THICK_BORDER_STYLE};
    `}
  }
  
  ${({ verticalAlign }) => verticalAlign && css`
    justify-content: ${verticalAlignments[verticalAlign]};
  `}
  
  ${({ padding }) => padding && css`
    padding: ${padding};
  `}
  
  ${({ isFirst }) => isFirst && css`
    padding-left: 20px;
  `}

  ${({ isDividerTransparent }) => isDividerTransparent && css`
    && {
      padding-right: 11px;
      border-width: 1px;
    }
  `}
`

const alignments = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
}

const LabelWrapper = styled.div<Required<Pick<TableHeaderCellProps, 'textAlign'>>>`
  ${TextM}
  display: flex;
  width: 100%;
  justify-content: ${({ textAlign }) => alignments[textAlign]};
  gap: 6px;
  text-align: ${({ textAlign }) => textAlign};
  white-space: pre-line;
`

const SubLabel = styled.div<Pick<TableHeaderCellProps, 'textAlign'>>`
  ${TextMLight}
  color: ${palette.grey70};
  text-align: ${({ textAlign }) => textAlign};
`

const ButtonWrapper = styled.div<{
  isExpanded: boolean
  zIndex?: number
  expandButtonAlign: TableHeaderCellProps['expandButtonAlign']
}>`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  transform: ${({ isExpanded }) => (isExpanded ? 'translateX(-50%)' : 'translateX(calc(-50% - 2px))')};
  align-items: center;

  ${({ zIndex }) => zIndex && css`
    z-index: ${zIndex};
  `}

  ${({ expandButtonAlign }) => expandButtonAlign === 'bottom' && css`
    align-items: flex-end;
    bottom: 12px;
  `}
`

const CheckboxWrapper = styled.div`
  width: max-content;
  margin-left: auto;
`
