import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { DocumentAlias } from '@/dal'
import { NotificationItem, NotificationType } from '@/ui'
import { RouterPaths } from '@/features/app/model'

type Props = {
  docType: DocumentAlias
  isRequestByContractor: boolean
  isSpecialVisa?: boolean
  onClose: () => void
}

export const OneMessageWarning = ({
  docType,
  isSpecialVisa,
  isRequestByContractor,
  onClose,
}: Props) => {
  const getNotificationTitle = () => {
    if (
      isRequestByContractor
      && [DocumentAlias.ACT, DocumentAlias.COMPENSATION_DOCUMENT].includes(docType)
    ) {
      return 'Отправить доп. запрос можно только одним сообщением!'
    }
    if (isRequestByContractor && docType === DocumentAlias.KOR_DOC) {
      return 'Отправить запрос можно только 1 раз!'
    }
    if (isSpecialVisa) {
      return (
        <>
          Направлен
          {' '}
          <UnderlineText>специальный</UnderlineText>
          {' '}
          доп. запрос
        </>
      )
    }
    return 'Отправить ответ можно только 1 раз!'
  }

  const getNotificationBody = () => {
    if (isRequestByContractor && docType === DocumentAlias.KOR_DOC) {
      return (
        <Body>
          <p>Проверьте сообщение перед отправкой, дальнейшее редактирование будет невозможно. </p>
          <p>
            Ваш доп. запрос по количеству (объемов) элементов сметы уйдет в Тех. надзор. По вопросам
            цены работ и материалов вы можете направить вопрос сметчику через
            {' '}
            {/* TODO SUPPORT было RouterPaths.NewAdministrationAppeal */}
            <Link to={RouterPaths.Support} target="_blank">
              запрос в администрацию проекта
            </Link>
            {' '}
            в разделе «Обращения».
          </p>
        </Body>
      )
    }
    if (isSpecialVisa) {
      return 'Сначала скорректируйте необходимые позиции, а затем напишите ответ. Отправить ответ можно только 1 раз, поэтому проверьте сообщение перед отправкой.'
    }
    return 'Проверьте сообщение перед отправкой, дальнейшее редактирование будет невозможно'
  }

  return (
    <NotificationItem
      type={NotificationType.Warning}
      title={getNotificationTitle()}
      body={getNotificationBody()}
      onClose={onClose}
      showCloseButton
    />
  )
}

const UnderlineText = styled.span`
  text-decoration: underline;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
