import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { Legent } from '../parts'
import { $data } from '../../model/private'

export const Legents = React.memo(() => {
  const data = useUnit($data)

  if (!data) return null

  return (
    <Wrapper>
      <Legent
        isApprove
        count={data.days_to_approving}
      />
      <Legent
        isApprove={false}
        count={data.days_to_paying}
      />
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 16px;
`
