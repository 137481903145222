import { sample } from 'effector'

import { createToast } from '@/features/toast-service/model'

import {
  restorePasswordForm,
  restorePasswordFx,
} from './restore-private'
import { RestoreAccessGate } from './public'

sample({
  clock: restorePasswordForm.formValidated,
  fn: ({ email }) => email,
  target: restorePasswordFx,
})

sample({
  clock: RestoreAccessGate.close,
  target: restorePasswordForm.reset,
})

createToast({
  effect: restorePasswordFx,
})
