import React from 'react'
import styled from 'styled-components'

import { formatDateString } from '@/lib/date'
import { numberWithSpaces } from '@/lib/number-represent'
import {
  Icon2,
  Ruble,
  TextM,
  TextMLight,
  ToolTipV2,
  palette,
} from '@/ui'
import { useTooltip } from '@/lib/hooks'

type Props = {
  number: string
  date: string
  sum: string
  url: string
}

export const PaymentOrderItem = ({
  date,
  url,
  number,
  sum,
}: Props) => {
  const {
    floatingStyles,
    getFloatingProps,
    getReferenceProps,
    isOpen,
    refs,
  } = useTooltip({})

  return (
    <Wrapper>
      <LeftCol>
        <BoldText>№ п/п</BoldText>
        <LightText>{number}</LightText>
        <Date>{formatDateString(date)}</Date>
      </LeftCol>
      <RightCol>
        <LightText>
          {numberWithSpaces(sum)}
          {' '}
          <Ruble />
        </LightText>
        <a href={url} download={`платежное поручение № ${number}`} ref={refs.setReference} {...getReferenceProps()}>
          <Icon2 icon="download" />
        </a>
        <ToolTipV2
          floatingStyles={floatingStyles}
          isOpen={isOpen}
          refs={refs}
          getFloatingProps={getFloatingProps}
          size="M"
        >
          Скачать
        </ToolTipV2>
      </RightCol>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 2px 0;
  background-color: ${palette.grey10};
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const LeftCol = styled.div`
  display: flex;
  gap: 8px;
`

const RightCol = styled.div`
  display: flex;
  gap: 24px;
`

const BoldText = styled.p`
  ${TextM}
`

const LightText = styled.p`
  ${TextMLight}  
`

const Date = styled.p`
  ${TextMLight}  
  color: ${palette.grey70};
`
