/* eslint-disable react-hooks/exhaustive-deps */
import React, { FormEvent } from 'react'
import styled, { css } from 'styled-components'

import { IconButton } from './buttons/iconButton'
import { Icon, IconName } from './Icon'
import { ThemedStyledProps, themeVar } from './theming'

type Props = {
  value: string
  onChange: (s: string) => void
  icon?: IconName
  fill?: boolean
  onClick?: () => void
  onSearch?: (s: string) => void
  onClear?: () => void
  placeholder?: string
  debounce?: number
  dataTestId: string
}

export const SearchInput = ({
  value,
  onChange,
  icon,
  fill = false,
  onClick,
  onSearch,
  onClear,
  placeholder,
  debounce,
  dataTestId,
}: Props) => {
  const inputRef = React.useRef<HTMLInputElement>(null)

  let time1: NodeJS.Timeout
  const [val, setVal] = React.useState<string>('')
  React.useEffect(() => setVal(value), [value])

  const disabled = value.length === 0

  const onClickHandler = () => {
    if (onClick) {
      onClick()
    }
    inputRef.current?.focus()
  }

  const onClearHandler = () => {
    if (onClear) {
      onClear()
      return
    }

    if (onSearch) {
      onSearch('')
      return
    }

    onChange('')
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    onChange(val)

    if (onSearch) {
      onSearch(val)
    }
  }

  const handleChange = React.useCallback(
    (newVal: string) => {
      setVal(newVal)
      if (debounce) {
        const request = () => onChange(newVal)
        clearTimeout(time1)
        time1 = setTimeout(request, debounce)
      } else {
        onChange(newVal)
      }
    },
    [debounce, onChange],
  )

  return (
    <Container disabled={disabled} fullWidth={fill} onSubmit={handleSubmit}>
      <Wrapper onClick={onClickHandler}>
        {icon && (
          <IconWrapper>
            <Icon icon={icon} />
          </IconWrapper>
        )}
        <Input
          ref={inputRef}
          value={val}
          placeholder={placeholder}
          onChange={(e) => handleChange(e.target.value)}
          data-testid={dataTestId}
        />

        {val.length > 0 && (
          <ClearWrapper onClick={onClearHandler}>
            <Icon icon="cross" />
          </ClearWrapper>
        )}
      </Wrapper>

      <IconButton
        isDisable={disabled}
        onClick={() => onSearch && onSearch(val)}
        size={45}
        dataTestId="button-simple-search"
        icon="action-search"
        borderColor="gray4"
      />
    </Container>
  )
}

type ContainerProps = {
  fullWidth: boolean
  disabled: boolean
}

const Container = styled.form<ThemedStyledProps & ContainerProps>`
  display: flex;
  flex-direction: row;
  height: 45px;
  box-sizing: border-box;

  ${({ fullWidth }) => Boolean(fullWidth)
    && fullWidth
    && css`
      flex: 1;
    `}

  ${({ disabled }) => Boolean(disabled)
    && disabled
    && css`
      svg {
        fill: ${themeVar('gray4')};
      }
    `}
`

const Wrapper = styled.div`
  cursor: text;
  padding: 8px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;

  border: 1px solid ${themeVar('line')};
  border-right: none;
`

const IconWrapper = styled.div`
  margin-right: 8px;
`

const ClearWrapper = styled.div`
  margin-left: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    fill: ${themeVar('gray4')};
  }
`

const Input = styled.input`
  background: none;
  height: 24px;
  border: none;
  font-weight: 300;
  font-size: 14px;
  font-family: ${themeVar('baseFont')};
  width: 100%;
  &::placeholder {
    color: ${themeVar('baseInputPlaceholderColor')};
  }
  &:focus {
    outline: none;
  }
`
