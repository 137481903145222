import { attach, combine } from 'effector'

import {
  EstimateTableRow,
  deleteTableItemReqFx,
} from '@/dal'

import { d } from './domain'
import { $flatItems } from './private'

export const $deleteTargetId = d.store<EstimateTableRow['id'] | null>(null)
export const openDeleteConfirm = d.event<EstimateTableRow['id']>()

export const $deleteTarget = combine(
  [$flatItems, $deleteTargetId],
  ([list, id]) => {
    if (!id) return null
    const item = list?.find((item) => item.id === id)
    return item || null
  },
)

export const rejectDelete = d.event()
export const onConfirmDelete = d.event()

export const deleteTableItemFx = attach({
  effect: deleteTableItemReqFx,
})
