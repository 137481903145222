import { useForm } from 'effector-forms'
import React, { FormEvent } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { NewButton, PasswordField } from '@/ui'
import { changePasswordForm, resetPasswordFx } from '../../model/change-private'

export const ChangePasswordForm = React.memo(() => {
  const {
    fields,
    isValid,
    errorText,
    submit,
  } = useForm(changePasswordForm)

  const isPending = useUnit(resetPasswordFx.pending)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    submit()
  }

  const hasValues = fields.password.value && fields.passwordConfirm.value

  return (
    <Form onSubmit={handleSubmit}>
      <Fields>
        <PasswordField
          dataTestId="password-input"
          onChange={fields.password.onChange}
          value={fields.password.value}
          error={errorText('password')}
          placeholder="Введите новый пароль"
        />
        <PasswordField
          dataTestId="password-confirm-input"
          onChange={fields.passwordConfirm.onChange}
          value={fields.passwordConfirm.value}
          error={errorText('passwordConfirm')}
          placeholder="Введите новый пароль повторно"
        />
      </Fields>
      <NewButton
        dataTestId="submit–button"
        label="Сохранить"
        isFill
        isDisabled={!hasValues || !isValid}
        isPending={isPending}
        type="submit"
      />
    </Form>
  )
})

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
