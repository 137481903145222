import { combine } from 'effector'
import { createGate } from 'effector-react'

import { $documentInfo, getCompensationInfoFx } from './private'

export const CompensationDocumentGate = createGate<{ id: number }>('CompensationDocumentGate')

export const $isCompensationPending = combine(
  getCompensationInfoFx.pending,
  (...args) => args.some((val) => val),
)

export const $compensationStatus = $documentInfo.map((info) => info?.status ?? null)
export const $compensationDocsLink = $documentInfo.map((info) => info?.documents_link ?? null)
