import React from 'react'
import styled from 'styled-components'

import {
  DefaultButton,
  Icon2,
  IconName2,
  PaletteColor,
} from '@/ui'

import { Cell, CellProps } from './Cell'

type Props = Omit<CellProps, 'value'> & {
  icon: IconName2
  color?: PaletteColor
  isDisabled?: boolean
  onClick: () => void
}

export const ButtonCell = ({
  icon,
  color = 'grey60',
  isDisabled,
  onClick,
  ...props
}: Props) => {
  return (
    <Cell align="center" padding="0" {...props}>
      <Wrapper disabled={isDisabled} onClick={onClick}>
        <Icon2 icon={icon} color={isDisabled ? 'grey50' : color} />
      </Wrapper>
    </Cell>
  )
}

const Wrapper = styled(DefaultButton)`
  width: 100%;
  height: 100%;
`
