import React, { memo } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import {
  HeadXL, ModalLayoutV2, NewButton, TextAreaField, TextLLight,
} from '@/ui'
import { noop } from '@/lib/noop'
import { getMorphologyBreadcrumbs } from '@/features/estimate-lot/model/helpers'

import {
  $additionalComment, editCommentModal, setAdditionalComment, updateComment, updateCommentFx,
} from '../../model/private'
import { Breadcrumbs } from '../parts'

export const EditCommentModal = memo(() => {
  const [info, comment, isPending] = useUnit([
    editCommentModal.$meta,
    $additionalComment,
    updateCommentFx.pending,
  ])

  if (!info) return null

  const { id, name, morphology } = info
  const breadcrumbs = getMorphologyBreadcrumbs(morphology)

  const onClose = isPending ? noop : editCommentModal.close

  return (
    <ModalLayoutV2
      dataTestId=""
      closeModal={onClose}
    >
      <Wrapper>
        <Container>
          <Title>Добавить комментарий</Title>
          {/* <Title>Изменить комментарий</Title> */}
          <Breadcrumbs items={breadcrumbs} />

          <WorkName>
            {name}
          </WorkName>

          {/* в дизайне без плейсхолдера */}
          <TextAreaField
            label="Комментарий"
            value={comment}
            placeholder="Введите ваш комментарий"
            currentSymbolCount={comment.length}
            maxSymbolCount={100}
            height={100}
            dataTestId=""
            onChange={setAdditionalComment}
          />
        </Container>

        <Buttons>
          <NewButton
            label="Сохранить"
            isDisabled={!comment}
            isPending={isPending}
            dataTestId=""
            isFill
            onClick={() => updateComment({ id, comment, morphology })}
          />
          <NewButton
            label="Отменить"
            buttonType="grey"
            isDisabled={isPending}
            dataTestId=""
            isFill
            onClick={onClose}
          />
        </Buttons>
      </Wrapper>
    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 500px;
  padding: 32px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Title = styled.div`
  ${HeadXL}
`

const WorkName = styled.div`
  ${TextLLight}
`

const Buttons = styled.div`
  display: flex;
  gap: 16px;
`
