import React, { memo } from 'react'
import styled from 'styled-components'
import { useGate, useUnit } from 'effector-react'

import {
  LoaderOverlay, NewButton, Switch, palette,
} from '@/ui'
import { GroupTree } from '@/features/group-tree/view'
import { $documentId } from '@/features/estimate-lot/model'
import { createAttachmentsModalView } from '@/features/factories/attach-modal'

import { EstimateLotTableGate } from '../../model'
import {
  $isByConstructionElements,
  $isTreeTable,
  $totalWorkCount,
  resetFilters,
  setIsByConstructionElements,
  setIsTreeTable,
  $isTablePending,
  rowAttachmentsModel,
} from '../../model/private'
import { Table, WorkOrFolderDeletionModal } from '../containers'

const RowAttachmentsModal = createAttachmentsModalView(rowAttachmentsModel)

export const EstimateLotTable = memo(() => {
  const [id, isTree, isByConstructionElements, workCount, isPending] = useUnit([
    $documentId,
    $isTreeTable,
    $isByConstructionElements,
    $totalWorkCount,
    $isTablePending,
  ])

  useGate(EstimateLotTableGate, id)

  if (!id) return null

  return (
    <>
      <Wrapper>
        <GroupTree workCount={workCount} />

        <Container>
          <UpperRow>
            <ActionsRow>
              <Switch
                label="Папки"
                isChecked={isTree}
                disabled={isByConstructionElements}
                dataTestId="estimate-lot-tree-table-switch"
                onChange={() => setIsTreeTable(!isTree)}
              />
              <Switch
                label="По элементам строительства"
                isChecked={isByConstructionElements}
                dataTestId="estimate-lot-construction-elements-switch"
                onChange={() => setIsByConstructionElements(!isByConstructionElements)}
              />
              <NewButton
                label="Очистить фильтры"
                buttonType="line-primary"
                size="S"
                isDisabled
                dataTestId="estimate-lot-reset-table-filters-btn"
                onClick={resetFilters}
              />
            </ActionsRow>
            <NewButton
              label="Скачать в Excel"
              buttonType="line-primary"
              size="S"
              dataTestId="estimate-lot-table-download-excel-btn"
              // TODO нет бэка
              onClick={() => ({})}
            />
          </UpperRow>

          <TableWrapper>
            <Table />
            <LoaderOverlay isShown={isPending} />
          </TableWrapper>
        </Container>
      </Wrapper>

      <WorkOrFolderDeletionModal />
      <RowAttachmentsModal />
    </>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${palette.white};
  border-radius: 12px;
`

const UpperRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 16px 24px;
`

const ActionsRow = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`

const TableWrapper = styled.div`
  position: relative;
  flex-grow: 1;

  & table {
    width: 100%;
  }
`
