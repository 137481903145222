import React from 'react'
import styled from 'styled-components'

import { formatDateString, formatTimeString } from '@/lib/date'

import { InlineDot } from './InlineDot'
import { themeVar } from './theming'
import { Icon } from './Icon'
import { SimpleLoader } from './Loader'

type Props = {
  date?: string
  isLoading: boolean
}

export const LastSyncInfo = ({ date, isLoading }: Props) => (
  <Wrapper>
    <Row>
      {isLoading ? <SimpleLoader sizePx={16} /> : <Icon icon="save-success" size={16} />}
      <ChangesText>
        {isLoading ? <>Синхронизация</> : <>Изменения сохранены</>}
      </ChangesText>
    </Row>

    {date && (
      <ChangesDate>
        {formatDateString(date)}
        {' '}
        <InlineDot />
        {' '}
        {formatTimeString(date)}
      </ChangesDate>
    )}
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const Row = styled.div`
  display: flex;
`

const ChangesText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 4px;

  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: ${themeVar('text')};
`

const ChangesDate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 16px;

  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: ${themeVar('textGray')};
`
