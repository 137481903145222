import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useUnit } from 'effector-react'

import { NewButton, palette } from '@/ui'
import { RouterPaths } from '@/features/app/model'
import Logo from '@/ui/v2/icons/assets/logo.svg'
import { $isReadOnlyAccess } from '@/features/login/model'

import {
  CreateRequestButton,
  NavMenu,
  Notifications,
  ReadOnlyModal,
  UserProfileBlock,
} from '../containers'
import { openReadOnlyModal } from '../../model/private'

export const TopBar = React.memo(() => {
  const isReadOnly = useUnit($isReadOnlyAccess)

  return (
    <Container>

      <NavigationWrapper>
        <Link to={RouterPaths.Home}>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
        </Link>
        <NavMenu />
        {isReadOnly && (
          <NewButton
            label="Вы находитесь в режиме чтения"
            size="S"
            dataTestId=""
            onClick={openReadOnlyModal}
          />
        )}
        <CreateRequestButton />
        <Notifications />
      </NavigationWrapper>

      <UserProfileBlock />

      <ReadOnlyModal />
    </Container>
  )
})

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;
`

const NavigationWrapper = styled.div`
  min-height: 56px;
  background-color: ${palette.grey100};
  border-radius: 16px;
  padding: 0 20px 0 16px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 24px;
`

const LogoWrapper = styled.div`
  height: 28px;
  width: 81px;

  & path {
    fill: white;
  }
`
