import React from 'react'
import { useParams } from 'react-router-dom'

import { createFileModalView } from '@/features/shared/filesModalFactory'

import { useGate } from 'effector-react'
import { Header, PlanBody } from '../containers'
import { PlanGate, filesModalModel } from '../../model/private'

export const FileModalView = createFileModalView(filesModalModel)

export const PlanInfo = React.memo(() => {
  const { id } = useParams<{ id: string }>()
  useGate(PlanGate, parseInt(id, 10))

  return (
    <>
      <Header />
      <PlanBody />

      <FileModalView />
    </>
  )
})

