import React from 'react'
import styled, { css } from 'styled-components'

import {
  NewIconButton,
  TextMLight,
  TextSLight,
  ToolTipV2,
  palette,
  rotating,
} from '@/ui'
import { getFullTime } from '@/lib/date'
import { useTooltip } from '@/lib/hooks'

type Props = {
  date: string // ISODate
  size?: 'M' | 'S'
  isDisabled: boolean
  isLoading: boolean
  onUpdate: (e: React.MouseEvent) => void
}

export const UpdateClosurePercentage = ({
  date,
  size = 'M',
  isDisabled,
  isLoading,
  onUpdate,
}: Props) => {
  const {
    floatingStyles,
    getFloatingProps,
    getReferenceProps,
    isOpen,
    refs,
  } = useTooltip({ tooltipOffset: 4 })

  return (
    <Wrapper>
      <UpdateDate
        size={size}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {getFullTime(date)}
      </UpdateDate>

      <ButtonWrapper isDisabled={isDisabled} isLoading={isLoading}>
        <NewIconButton
          icon="refresh"
          size={size === 'M' ? 16 : 12}
          padding={size === 'M' ? '6px' : '3px'}
          borderRadius="50%"
          color="accent100"
          disabledColor="accent100"
          backgroundColor="accent20"
          hoverBackgroundColor="accent20"
          tooltipText={!(isDisabled || isLoading) ? 'Обновить данные' : undefined}
          disabled={isDisabled || isLoading}
          dataTestId="update-closure-percentage-btn"
          onClick={onUpdate}
        />
      </ButtonWrapper>

      <ToolTipV2
        floatingStyles={floatingStyles}
        isOpen={isOpen}
        refs={refs}
        getFloatingProps={getFloatingProps}
        size="M"
      >
        Дата последнего обновления данных
      </ToolTipV2>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const UpdateDate = styled.p<Pick<Props, 'size'>>`
  ${({ size }) => (size === 'M' ? TextMLight : TextSLight)}
  padding-top: 2px;
  color: ${palette.grey70};
`

const ButtonWrapper = styled.div<Pick<Props, 'isDisabled' | 'isLoading'>>`
  ${({ isDisabled }) => isDisabled && css`
    opacity: 0.5;
  `}

  ${({ isLoading }) => isLoading && css`
    & button {
      animation: ${rotating} 1.5s linear infinite;
    }
  `}
`
