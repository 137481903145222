import React from 'react'
import styled from 'styled-components'

import { NewButton, TextLLight } from '@/ui'

import { getFormattedContractName } from '../mappers'

type Props = {
  text: string;
  contractName?: string
  confirmLabel: string
  isLoading: boolean;
  onConfirm: () => void;
  onClose: () => void
};

export const ConfirmLayout = ({
  text, contractName, confirmLabel, isLoading, onConfirm, onClose,
}: Props) => {
  return (
    <Wrapper>
      <Text>
        {text}
        {' '}
        по
        {' '}
        {getFormattedContractName(contractName)}
        ?
      </Text>
      <Actions>
        <NewButton
          dataTestId="submit-button"
          label={confirmLabel}
          buttonType="primary"
          isFill
          onClick={onConfirm}
          isPending={isLoading}
        />
        <NewButton
          dataTestId="cancel-button"
          label="Отменить"
          buttonType="grey"
          isDisabled={isLoading}
          isFill
          onClick={onClose}
        />
      </Actions>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Text = styled.div`
  ${TextLLight}
  margin-bottom: 24px;
`

const Actions = styled.div`
  display: flex;
  gap: 16px;
`
