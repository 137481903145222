import { attach, combine } from 'effector'

import {
  DocumentFilesInfo, GetDocumentFilesPayload, getDocumentFilesReqFx,
} from '@/dal'
import { createSectionTabsModel } from '@/features/factories'

import { d } from './domain'
import { DocumentFilesTab } from './types'

export const $docFilesInfo = d.store<DocumentFilesInfo | null>(null)
export const $hasSignedDocFiles = $docFilesInfo.map((items) => (
  Boolean(items?.document_files.some((item) => item.is_signed))
))

export const $isDocFilesModalOpen = d.store(false)
export const openDocFilesModal = d.event()
export const closeDocFilesModal = d.event()

export const getDocumentFiles = d.event<GetDocumentFilesPayload>()

export const {
  $activeTabId,
  $invalidActiveTabId,
  changeActiveTab,
  resetActiveTab,
  setInvalidActiveTab,
} = createSectionTabsModel<DocumentFilesTab>({
  defaultValue: DocumentFilesTab.Templates,
  clearEvent: closeDocFilesModal,
  domain: d,
})

export const $documentFiles = combine(
  ([$docFilesInfo, $activeTabId]),
  ([info, activeTabId]) => (
    info?.document_files.filter(({ is_signed }) => {
      return activeTabId === DocumentFilesTab.Signed ? is_signed : !is_signed
    }) ?? []
  ),
)

export const getDocumentFilesFx = attach({
  effect: getDocumentFilesReqFx,
})
