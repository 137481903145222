import React from 'react'
import { useUnit } from 'effector-react'

import { BudgetInfo } from '@/ui/BudgetInfo'
import { $openedFundPlan, loadPlanFx } from '../../model'

export const PlanBudget = () => {
  const isLoading = useUnit(loadPlanFx.pending)
  const plan = useUnit($openedFundPlan)

  return (plan
    && (
      <BudgetInfo
        title="Бюджет"
        allDeclarated={plan.cost_all}
        workDeclarated={plan.cost_work}
        materialDeclarated={plan.cost_material}
        allApproved={plan.cost_all_approved}
        workApproved={plan.cost_work_approved}
        materialApproved={plan.cost_material_approved}
        isLoading={isLoading}
        dataTestId="plan-budget"
      />
    )
  )
}
