import React, { FormEvent, memo } from 'react'
import styled from 'styled-components'
import { useForm } from 'effector-forms'

import { HeadL, NewDropDownField } from '@/ui'
import { LKP_SECTIONS } from '@/features/support/model/const'
import { ButtonsRow } from '@/features/support/shared'
import { useCheckFieldsDirty } from '@/lib/hooks/useCheckFieldsDirty'

import { createAppealModal } from '../../model/private'
import { lkpSectionProblemForm } from '../../model/forms'
import { BaseFormFieldsWithChildren } from '../parts'

export const LkpSectionProblem = memo(() => {
  const { fields, submit, isValid } = useForm(lkpSectionProblemForm)

  const areFieldsDirty = useCheckFieldsDirty(fields, ['files'])

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    submit()
  }

  return (
    <Wrapper onSubmit={handleSubmit}>
      <Title>Вопрос с разделом ЛКП</Title>

      <BaseFormFieldsWithChildren fields={fields}>
        <NewDropDownField
          dataTestId="document-type-input"
          error={fields.section.firstError?.errorText}
          options={LKP_SECTIONS}
          onOptionClick={fields.section.onChange}
          label="Раздел ЛКП"
          size="L"
          required
          placeholder="Выберите тип документа из списка"
          selectedId={fields.section.value}
        />
      </BaseFormFieldsWithChildren>

      <ButtonsRow
        isDisabled={!isValid || !areFieldsDirty}
        onClose={createAppealModal.close}
        onSubmit={submit}
      />
    </Wrapper>
  )
})

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;

  width: 542px;
  padding: 32px;
`

const Title = styled.p`
  ${HeadL}
`
