import React from 'react'

import { Administrative } from '@/features/administrative'
import { ContentContainer } from '@/ui'
import Scrollbars from 'react-custom-scrollbars-2'

export const AdministrativePage = () => (
  <Scrollbars autoHeight autoHeightMin={0} autoHeightMax="100%">
    <ContentContainer>
      <Administrative />
    </ContentContainer>
  </Scrollbars>
)
