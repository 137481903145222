import { sample, split } from 'effector'

import {
  AdministrationAppealDocType,
  ResponsibleAppealAlias,
  DocumentAlias,
  OtherAppealAlias,
  SendLkpSectionProblemPayload,
} from '@/dal'
import { $userData } from '@/features/edit-profile/model'
import { createToast } from '@/features/toast-service/model'

import {
  $createdUsers,
  $documents,
  $contactsStep,
  $selectedAppealType,
  abortGetJiraDocumentsFx,
  abortGetMyUsersFx,
  addUserFx,
  blockUserFx,
  createAppealModal,
  createProblemsWithApprovalFx,
  getJiraDocumentsFx,
  getMyUsersFx,
  selectAppealType,
  sendFormWithContacts,
  sendAppealForResponsible,
  sendDocumentProblemAppeal,
  selectLkpSectionProblem,
  setTechSupportAppealType,
  $techSupportAppealType,
  selectDocumentProblem,
  sendLkpSectionPromlemAppeal,
  createLkpSectionProblemAppealFx,
  resumeAppeal,
  resumeAppealFx,
  onSelectAppealTypeClicked,
} from './private'
import { $selectedDocType, selectResponsibleAlias, sendAppealForResponsibleFx } from '../../new-appeal/model/private'
import {
  onCreateAppealModalClosed, onNewAppealCreated, resetContactsStep, setContactsStep,
} from './public'
import {
  addUserForm, blockUserForm, contactsForm, documentProblemForm, lkpSectionProblemForm,
} from './forms'
import { responsibleAppealTypes } from '../../model'
import { SendFormWithContactsPayload } from './types'
import { appealForm } from '../../new-appeal/model/forms'

import './copy.init'

$selectedAppealType
  .on(selectAppealType, (_, type) => type)
  .reset(createAppealModal.close)

$contactsStep
  .on(setContactsStep, (_, val) => val)
  .reset(createAppealModal.close, selectAppealType, resetContactsStep)

$techSupportAppealType
  .on(setTechSupportAppealType, (_, val) => val)
  .reset(createAppealModal.close, selectAppealType)

$documents
  .on(getJiraDocumentsFx.doneData, (_, docs) => docs)
  .reset(createAppealModal.close, documentProblemForm.fields.documentType.$value)

$createdUsers
  .on(getMyUsersFx.doneData, (_, users) => users)
  .reset(createAppealModal.close)

sample({
  clock: onSelectAppealTypeClicked,
  target: selectAppealType,
})

sample({
  clock: selectAppealType,
  target: createAppealModal.open,
})

sample({
  clock: selectDocumentProblem,
  target: [
    documentProblemForm.fields.documentType.set,
    setTechSupportAppealType.prepend(() => 'document'),
  ],
})

sample({
  clock: selectLkpSectionProblem,
  target: [
    lkpSectionProblemForm.fields.section.set,
    setTechSupportAppealType.prepend(() => 'section'),
  ],
})

sample({
  clock: lkpSectionProblemForm.formValidated,
  fn: () => 'section' as const,
  target: setContactsStep,
})

sample({
  clock: selectAppealType,
  filter: (type) => responsibleAppealTypes.includes(type),
  target: selectResponsibleAlias,
})

sample({
  clock: createAppealModal.close,
  target: onCreateAppealModalClosed,
})

sample({
  clock: [$userData.updates, createAppealModal.close],
  source: $userData,
  filter: Boolean,
  fn: ({ fullName, phone }) => ({ phone, fullName }),
  target: contactsForm.setForm,
})

sample({
  clock: documentProblemForm.fields.documentType.$value,
  filter: Boolean,
  fn: (type) => ({ type }),
  target: getJiraDocumentsFx,
})

sample({
  clock: [createAppealModal.close, onSelectAppealTypeClicked],
  target: [
    documentProblemForm.reset,
    lkpSectionProblemForm.reset,
    addUserForm.reset,
    blockUserForm.reset,
    abortGetJiraDocumentsFx,
    abortGetMyUsersFx,
  ],
})

sample({
  clock: resumeAppeal,
  target: resumeAppealFx,
})

sample({
  clock: documentProblemForm.formValidated,
  fn: () => 'document' as const,
  target: setContactsStep,
})

sample({
  clock: lkpSectionProblemForm.formValidated,
  fn: () => 'section' as const,
  target: setContactsStep,
})

sample({
  clock: createProblemsWithApprovalFx.doneData,
  target: documentProblemForm.reset,
})

sample({
  clock: addUserForm.formValidated,
  fn: ({
    phone,
    ...data
  }) => {
    return {
      ...data,
      phone: `+7${phone}`,
    }
  },
  target: addUserFx,
})

sample({
  clock: $selectedAppealType,
  filter: (type) => type === OtherAppealAlias.BlockUser,
  target: getMyUsersFx,
})

sample({
  clock: blockUserForm.formValidated,
  fn: ({ userId }) => userId as number,
  target: blockUserFx,
})

sample({
  clock: contactsForm.formValidated,
  source: $contactsStep,
  filter: Boolean,
  fn: (type, contacts) => ({ contacts, type }),
  target: sendFormWithContacts,
})

split({
  source: sendFormWithContacts,
  match: {
    sendAppealForResponsible: ({ type }: SendFormWithContactsPayload) => type === 'responsible',
    sendDocumentProblemAppeal: ({ type }: SendFormWithContactsPayload) => type === 'document',
    sendLkpSectionPromlemAppeal: ({ type }: SendFormWithContactsPayload) => type === 'section',
  },
  cases: {
    sendAppealForResponsible,
    sendDocumentProblemAppeal,
    sendLkpSectionPromlemAppeal,
  },
})

sample({
  clock: sendAppealForResponsible,
  source: {
    form: appealForm.$values,
    doc_type: $selectedDocType,
    type: $selectedAppealType,
  },
  filter: ({ doc_type, type }) => Boolean(doc_type && type),
  fn: ({ form, doc_type, type }, { contacts }) => ({
    task_type: type as ResponsibleAppealAlias,
    doc_type: doc_type as AdministrationAppealDocType,
    target_document_id: form.document as number,
    subject: form.subject,
    description: form.description,
    files: form.files,
    contact_person_name: contacts.fullName,
    contact_person_phone: `+7${contacts.phone}`,
  }),
  target: sendAppealForResponsibleFx,
})

sample({
  clock: sendDocumentProblemAppeal,
  source: documentProblemForm.$values,
  fn: ({
    document,
    documentType,
    subject,
    ...form
  }, { contacts }) => ({
    ...contacts,
    ...form,
    related_document_type: documentType as DocumentAlias,
    related_document_id: document as number,
    title: subject,
    contact_person_name: contacts.fullName,
    contact_person_phone: `+7${contacts.phone}`,
  }),
  target: createProblemsWithApprovalFx,
})

sample({
  clock: sendLkpSectionPromlemAppeal,
  source: lkpSectionProblemForm.$values,
  fn: ({ subject, ...form }, { contacts }) => ({
    ...form,
    title: subject,
    contact_person_name: contacts.fullName,
    contact_person_phone: `+7${contacts.phone}`,
  } as SendLkpSectionProblemPayload),
  target: createLkpSectionProblemAppealFx,
})

sample({
  clock: [
    addUserFx.doneData,
    blockUserFx.doneData,
    createLkpSectionProblemAppealFx.doneData,
    createProblemsWithApprovalFx.doneData,
    sendAppealForResponsibleFx.doneData,
  ],
  target: [createAppealModal.close, onNewAppealCreated],
})

// TODO убрать дублирования
createToast({
  effect: addUserFx,
  doneText: ({ result }) => `Обращение ${result.number} создано`,
})

createToast({
  effect: blockUserFx,
  doneText: ({ result }) => `Обращение ${result.number} создано`,
})

createToast({
  effect: createLkpSectionProblemAppealFx,
  doneText: ({ result }) => `Обращение ${result.number} создано`,
})

createToast({
  effect: createProblemsWithApprovalFx,
  doneText: ({ result }) => `Обращение ${result.number} создано`,
})
