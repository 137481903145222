import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { RouterPaths } from '@/features/app/model'
import { TextMLight, TooltipProps, palette } from '@/ui'

type Props = TooltipProps & {
  childLinks: {
    name: string,
    link: RouterPaths,
  }[]
  isOpen: boolean
  onLinkClick: () => void
}

export const TooltipMenu = ({
  floatingStyles,
  getFloatingProps,
  onLinkClick,
  isOpen,
  childLinks,
  refs,
}: Props) => {
  if (!isOpen) return null

  return (
    <PopupMenu
      ref={refs.setFloating}
      style={floatingStyles}
      {...getFloatingProps()}
    >
      {childLinks.map((item) => (
        <Link key={item.link} to={item.link} onClick={onLinkClick}>
          <LinkItem>
            {item.name}
          </LinkItem>
        </Link>
      ))}
    </PopupMenu>

  )
}

const PopupMenu = styled.div`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 8px 0;
  background-color: ${palette.grey100};
  ${TextMLight};
`

const LinkItem = styled.div`
  padding: 8px 12px;
  transition: background-color 0.15s linear;
  cursor: pointer;
  color: ${palette.white};
  text-align: left;

  @media(hover: hover) {
    &:hover {
      background-color: rgba(${palette.rgbGrey}, 0.4);
    }
  }
`
