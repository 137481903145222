import React, { memo, useMemo } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'
import { useForm } from 'effector-forms'

import { LotRowType } from '@/dal'
import {
  DefaultButton,
  HeadL,
  ModalLayoutV2,
  NewButton,
  NumberInput,
  NumberInputField,
  Radio,
  TextL,
  TextM,
} from '@/ui'
import { noop } from '@/lib/noop'

import { $breadcrumbs, distributeAmountsModal, distributeLotWorkAmountsFx } from '../../model/private'
import { distributeAmountsForm } from '../../model/forms'
import { AmountDistributionType } from '../../model'
import { Breadcrumbs } from '../parts'

export const DistributeAmountsModal = memo(() => {
  const [info, breadcrumbs, isPending] = useUnit([
    distributeAmountsModal.$meta,
    $breadcrumbs,
    distributeLotWorkAmountsFx.pending,
  ])

  const { fields, submit } = useForm(distributeAmountsForm)

  const {
    amountDistributionType,
    split_count_to_all,
    single_count_for_all,
    single_price_for_all,
  } = fields

  const canSubmit = useMemo(() => (
    Boolean(amountDistributionType.value === AmountDistributionType.SplitForAll
      ? split_count_to_all.value
      : single_count_for_all.value)
    || Boolean(single_price_for_all.value)
  ), [fields])

  if (!info) return null

  const isWork = info.row_type === LotRowType.Work
  const isSplitForAll = amountDistributionType.value === AmountDistributionType.SplitForAll

  const onClose = isPending ? noop : distributeAmountsModal.close

  return (
    <ModalLayoutV2
      dataTestId=""
      closeModal={onClose}
    >
      <Wrapper>
        <Title>
          Распределить количество
          {' '}
          {isWork ? 'работы' : 'материала'}
        </Title>

        <Breadcrumbs items={breadcrumbs} isInline={false} />

        {!isWork && (
          <MaterialName>{info.name}</MaterialName>
        )}

        <Block>
          <BlockHeader>
            Установить количество
            {' '}
            {isWork ? 'работы' : 'материала'}
          </BlockHeader>

          <Block>
            <RadioButton
              onClick={() => amountDistributionType.onChange(AmountDistributionType.SplitForAll)}
            >
              <Radio
                value={AmountDistributionType.SplitForAll}
                isChecked={isSplitForAll}
                dataTestId=""
                onChange={noop}
              />
              <p>
                {isWork
                  ? 'Распределить количество работы равномерно по всем элементам'
                  : 'Распределить количество пропорционально по всем элементам'}
              </p>
            </RadioButton>
            <InputWrapper hasLeftMargin>
              <NumberInput
                placeholder={`Введите общее количество ${isWork ? 'работ' : 'материалов'}`}
                value={split_count_to_all.value}
                thousandSeparator=" "
                decimalScale={4}
                disabled={!isSplitForAll || isPending}
                dataTestId=""
                onChange={split_count_to_all.onChange}
                onClear={split_count_to_all.reset}
              />
            </InputWrapper>
          </Block>

          <Block>
            <RadioButton
              onClick={() => amountDistributionType.onChange(AmountDistributionType.OneForAll)}
            >
              <Radio
                value={AmountDistributionType.OneForAll}
                isChecked={!isSplitForAll}
                dataTestId=""
                onChange={noop}
              />
              <p>Установить для всех элементов единое количество</p>
            </RadioButton>
            <InputWrapper hasLeftMargin>
              <NumberInput
                placeholder="Введите количество для одного элемента"
                value={single_count_for_all.value}
                thousandSeparator=" "
                decimalScale={4}
                disabled={isSplitForAll || isPending}
                dataTestId=""
                onChange={single_count_for_all.onChange}
                onClear={single_count_for_all.reset}
              />
            </InputWrapper>
          </Block>
        </Block>

        {isWork && (
          <Block>
            <BlockHeader>Цена работы</BlockHeader>
            <InputWrapper>
              <NumberInputField
                label="Установить цену за единицу работы для всех элементов"
                placeholder="Введите цену"
                value={single_price_for_all.value}
                thousandSeparator=" "
                decimalScale={2}
                disabled={isPending}
                dataTestId=""
                onChange={single_price_for_all.onChange}
                onClear={single_price_for_all.reset}
              />
            </InputWrapper>
          </Block>
        )}

        <Buttons>
          <NewButton
            label="Применить"
            isDisabled={!canSubmit}
            isPending={isPending}
            dataTestId=""
            isFill
            onClick={submit}
          />
          <NewButton
            label="Закрыть"
            buttonType="grey"
            isDisabled={isPending}
            dataTestId=""
            isFill
            onClick={onClose}
          />
        </Buttons>
      </Wrapper>
    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 732px;
  padding: 32px;
`

const Title = styled.header`
  ${HeadL}
`

const MaterialName = styled.div`
  ${TextM}
`

const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const BlockHeader = styled.header`
  ${TextL}
`

const RadioButton = styled(DefaultButton)`
  ${TextM}
  display: flex;
  gap: 8px;
`

const InputWrapper = styled.div<{ hasLeftMargin?: boolean }>`
  margin-left: ${({ hasLeftMargin }) => (hasLeftMargin ? '26px' : '0')};

  .input-container {
    max-width: 300px;
  }
`

const Buttons = styled.div`
  display: flex;
  gap: 16px;
`
