import { attach } from 'effector'

import {
  ActDTO,
  PaymentOrder,
  postPaymentOrdersReqFx,
} from '@/dal'

import { d } from './domain'
import { EditingPaymentOrder } from './types'

export const $isPaymentOrdersModalOpen = d.store<boolean>(false)
export const onOpenPaymentOrdersModal = d.event<void>()
export const openPaymentOrdersModal = d.event<void>()
export const closePaymentOrdersModal = d.event<void>()

export const $editingPaymentOrders = d.store<EditingPaymentOrder[]>([])
export const setEditingPaymentOrders = d.event<PaymentOrder[]>()
export const addNewPaymentOrder = d.event<void>()
export const markPaymentOrderToDelete = d.event<EditingPaymentOrder['id']>()
export const editPaymentOrder = d.event<Pick<EditingPaymentOrder, 'id' | 'date' | 'number'>>()
export const onUpdatePaymentOrders = d.event<void>()

export const $deletingPaymentOrders = d.store<PaymentOrder['id'][]>([])
export const onDeleteSinglePaymentOrder = d.event<PaymentOrder['id']>()

export const postPaymentOrdersFx = attach({
  effect: postPaymentOrdersReqFx,
})

export const deletePaymentOrdersFx = attach({
  effect: postPaymentOrdersReqFx,
  mapParams: ({ id, toDeleteId }: { toDeleteId: PaymentOrder['id'], id: ActDTO['id'] }) => ({
    to_delete: [toDeleteId],
    id,
  }),
})
