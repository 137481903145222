import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { DocumentAlias, DocumentStatuses } from '@/dal'
import { getStatusName } from '@/lib/getStatusName'
import { RouterPaths, pushNavigate } from '@/features/app/model'
import { formatDateWordMonth } from '@/lib/date'
import { getDocumentNameByType } from '@/lib/documentResolvers'
import { showPlanEditPeriodModal } from '@/features/funding-schedule/plan-page/edit-plan-period/model'
import {
  Badge,
  IconButton,
  LastSyncInfo,
  RoundButton,
  themeVar,
  DocumentProgressBar,
  MediumLightTextCSS,
  H2TitleCSS,
  TextButton,
  Loader,
  RejectionComment,
} from '@/ui'

import { $openedFundPlan, loadPlanFx } from '../../model'
import { HeaderButtons } from './HeaderButtons'
import {
  onAdditionalButtonClicked,
  contractNameClicked,
  copyMailToClipboard,
  deletePlanClicked,
  reloadButtonClicked,
} from '../../model/private'

export const Header = React.memo(() => {
  const openedFundPlan = useUnit($openedFundPlan)
  const isPlanLoading = useUnit(loadPlanFx.pending)

  const customStyle = React.useMemo(() => {
    if (openedFundPlan?.status && [
      DocumentStatuses.AgreedContractor,
      DocumentStatuses.DocumentsWaiting,
    ].includes(openedFundPlan.status)) {
      return 'agreedContractor'
    }

    if (openedFundPlan?.additional_request) {
      return 'actHasMessage'
    }

    return 'actNoMessage'
  }, [openedFundPlan?.additional_request, openedFundPlan?.status])

  if (isPlanLoading) return <Loader />

  if (!openedFundPlan) return null

  const status = getStatusName(openedFundPlan?.status)
  const isDraft = openedFundPlan?.status === DocumentStatuses.Draft

  const isPlanToBeAgreed = [
    DocumentStatuses.ToBeAgreed, // 'На согласовании'
    DocumentStatuses.AgreedContractor, // 'На согласовании подрядчика'
    DocumentStatuses.DocumentsWaiting, // Ожидание документов
    DocumentStatuses.AgreedTechSupervisor, // 'На согласовании технадзора'
  ].includes(openedFundPlan.status)

  const showStatuses = [DocumentStatuses.Draft, DocumentStatuses.Declined].includes(
    openedFundPlan.status,
  )
  const name = getDocumentNameByType(DocumentAlias.BUDGET)
  return (
    <HeaderWrapper>
      <LeftRow>
        <InfoWrapper>
          <RoundButton
            style="buttonL"
            fixedSize="45px"
            iconSizePx={32}
            icon="back"
            onClick={() => pushNavigate(RouterPaths.Administrative)}
            dataTestId="button-funding-go-back"
          />
          <PlanInfo>
            <StatusRow>
              {showStatuses && <Badge>{status}</Badge>}
              <HeaderDate>
                {formatDateWordMonth(openedFundPlan.date_start)}
              </HeaderDate>
              {isDraft && (
                <IconButton
                  dataTestId="div-funding-open-edit-period"
                  icon="edit"
                  onClick={showPlanEditPeriodModal}
                  size={16}
                  iconSize={16}
                  tooltip="Редактировать"
                />
              )}
            </StatusRow>
            {openedFundPlan.reject_comment && (
              <RejectionComment>{openedFundPlan.reject_comment}</RejectionComment>
            )}
            <DocumentNameRow>
              <PlanName>
                {name}
                {' '}
                {openedFundPlan.number_1c}
              </PlanName>
              {isDraft && (
                <IconButton
                  dataTestId="div-funding-open-edit-period"
                  icon="act-delete"
                  tooltip="Удалить"
                  onClick={deletePlanClicked}
                  size={16}
                  tooltipPosition="bottom"
                  iconSize={16}
                />
              )}
              {isPlanToBeAgreed && (
                <WrappAdditionalButton>
                  <RoundButton
                    onClick={onAdditionalButtonClicked}
                    icon={
                      [
                        DocumentStatuses.AgreedContractor,
                        DocumentStatuses.DocumentsWaiting,
                      ].includes(openedFundPlan.status)
                        ? 'additional'
                        : 'act-dialog'
                    }
                    iconSizePx={12}
                    style={customStyle}
                    iconPosition="left"
                    dataTestId="button-funding-open-dialog"
                  >
                    Доп. запрос
                  </RoundButton>
                </WrappAdditionalButton>
              )}
            </DocumentNameRow>
            <DocumentTextRow>
              <ProjectName>
                {openedFundPlan.contract.project_name}
              </ProjectName>
              <TextButton
                fontWeight={300}
                dataTestId={`go-to-contract-${openedFundPlan.contract.contract_id}`}
                onClick={contractNameClicked}
                text={`(${openedFundPlan.contract.contract_name})`}
              />
            </DocumentTextRow>
          </PlanInfo>
        </InfoWrapper>

        <DocumentProgressBar
          showResponsible={openedFundPlan.show_responsible}
          status={openedFundPlan.status}
          progress={openedFundPlan.progress || null}
          onCopyToClipboard={copyMailToClipboard}
          onClickReloadButton={reloadButtonClicked}
          dataTestId="funding-schedule-progress-bar"
          isShowAdditionalRequest
        />

      </LeftRow>

      <SyncCol>
        <LastSyncInfo
          date={openedFundPlan.last_update}
          isLoading={false}
        />
        <ButtonRow>
          <HeaderButtons />
        </ButtonRow>
      </SyncCol>
    </HeaderWrapper>
  )
})

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const Row = styled.div`
  display: flex;
  width: 100%;
`

const DocumentNameRow = styled.div`
  display: flex;
  align-items: center;
`

const WrappAdditionalButton = styled.div`
  width: 120px; 

`

const Col = styled.div`
  display: flex;
  flex-direction: column;
`

const InfoWrapper = styled.div`
  display: flex;
  gap: 16px;
`

const StatusRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  z-index: 2;
`

const PlanInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const LeftRow = styled.div`
  display: flex;
  gap: 50px;
`

const HeaderDate = styled.div`
  ${MediumLightTextCSS}
  color: ${themeVar('textGray')};
`

const DocumentTextRow = styled.div`
  display: flex;  
  gap: 8px;
`

const PlanName = styled.div`
  ${H2TitleCSS}
  margin-right: 8px;
  white-space: nowrap;
`

const ProjectName = styled.div`
  ${MediumLightTextCSS}
  color: ${themeVar('textGray')};
  white-space: nowrap;
`

const SyncCol = styled(Col)`
  gap: 13px;
`

const ButtonRow = styled(Row)`
  gap: 8px;
  justify-content: flex-end;
`
