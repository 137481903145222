import React from 'react'
import styled from 'styled-components'

import {
  PaletteColor,
  Square,
  TextMLight,
  TextS,
  TextSLight,
  ToolTipWrapper,
  palette,
} from '@/ui'

type Props = {
  total_percent: string
  color: PaletteColor
  label: string

  items?: ({
    color?: PaletteColor,
    percent?: string,
    text: string,
    label: string
  } | null)[]
}

export const ProgressLabel = ({
  total_percent,
  label,
  color,
  items,
}: Props) => {
  return (
    <ToolTipWrapper
      bgColor="white"
      referenceComponent={(
        <Label>
          <LabelTitle>
            <Square color={color} />
            {label}
          </LabelTitle>
          <LabelPercent>
            {total_percent}
            %
          </LabelPercent>
        </Label>
      )}
    >
      <TooltipTitle>{label}</TooltipTitle>
      <TooltipItems>
        {items?.map((item) => item && (
          <TooltipItem key={item.label}>
            {item.color && (
              <Square color={item.color} />
            )}
            <TooltipLabel>
              {item.label}
            </TooltipLabel>
            {item.percent ? `(${item.percent}%) ` : ''}
            {item.text}
          </TooltipItem>
        ))}
      </TooltipItems>
    </ToolTipWrapper>
  )
}

const Label = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  ${TextMLight}
  line-height: 1;
`

const LabelTitle = styled.div`
  display: flex;
  gap: 4px;
  color: ${palette.grey70};
  align-items: center;
  flex-grow: 1;
`

const LabelPercent = styled.div``

const TooltipTitle = styled.p`
  ${TextS}
  margin-bottom: 8px;
  text-transform: uppercase;
`

const TooltipItems = styled.div`
  ${TextSLight};
  display: flex;
  flex-direction: column;
  gap: 2px;
`

const TooltipItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
`

const TooltipLabel = styled.div`
  color: ${palette.grey70};
  flex-grow: 1;
`
