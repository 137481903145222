import React from 'react'
import styled from 'styled-components'

import { TreeItem } from '@/dal'
import { TextMLight, palette } from '@/ui'

type Props = {
  id: TreeItem['id']
  name: string
  onClick?: (id: Props['id']) => void
}

export const BreadcrumbsItem = ({
  id,
  name,
  onClick,
}: Props) => (
  <Item onClick={() => onClick?.(id)}>
    {name}
  </Item>
)

const Item = styled.span`
  ${TextMLight}
  color: ${palette.grey70};
  cursor: pointer;
  display: inline;
  text-overflow: ellipsis;
  overflow: hidden;
`
