import {
  attach,
  createEffect,
  createStore,
  sample,
  Unit,
} from 'effector'

import { DefaultHandledError } from '@/dal'

export function createSingleEffect<Params, Done>(
  handler: (params: Params) => Promise<Done>,
  units?: { abortEvents?: Unit<unknown>[] },
) {
  const $signal = createStore(new AbortController())

  const abortFx = attach({
    source: $signal,
    effect(ctrl) {
      ctrl.abort()
      return new AbortController()
    },
  })

  const requestFx = createEffect<Params, Done, DefaultHandledError>(async (params) => {
    const { signal } = await abortFx()
    return handler({ ...params, signal })
  })

  $signal
    .on(abortFx.doneData, (_, ctrl) => ctrl)

  if (units?.abortEvents) {
    sample({
      clock: units.abortEvents,
      target: abortFx,
    })
  }

  return { requestFx, abortFx }
}
