import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { NewButton } from '@/ui'

import {
  $currStep,
  $selectedDocIds,
  closeContract,
  closeModal,
  nextStep,
  prevStep,
} from '../../model/private'

export const ButtonsRow = React.memo(() => {
  const step = useUnit($currStep)
  const ids = useUnit($selectedDocIds)

  return (
    <Buttons>
      <ButtonWrapper>
        <NewButton
          dataTestId={step === 1 ? 'cancel-button' : 'back-button'}
          label={step === 1 ? 'Отменить' : 'Назад'}
          buttonType="grey"
          onClick={step === 1 ? closeModal : prevStep}
          isFill
        />
      </ButtonWrapper>

      <ButtonWrapper>
        <NewButton
          dataTestId={step === 1 ? 'next-button' : 'send-button'}
          label={step === 1 ? 'Продолжить' : 'Отправить на подписание'}
          buttonType="primary"
          onClick={step === 1 ? nextStep : closeContract}
          isFill
          isDisabled={step === 2 && !ids.length}
        />
      </ButtonWrapper>
    </Buttons>
  )
})

const Buttons = styled.div`
  margin-top: 12px;
  display: flex;
  gap: 12px;
`

const ButtonWrapper = styled.div`
  flex-grow: 1;
`
