import { sample } from 'effector'

import { FileItem } from '@/dal'
import { getFileExtension, getImageUrl } from '@/lib/file-reader'
import { showToast } from '@/features/toast-service/model'
import { NotificationType } from '@/ui'

import {
  $feedbackForm,
  $modalFeedbackOpened,
  $selectedFeedbackFiles,
  addSelectedFeedbackFiles,
  closeFeedbackModal,
  deleteFeedbackFile,
  initFeedbackImagesFx,
  sendMailFeedbackFx,
} from './private'
import { openFeedbackModal } from './public'


$modalFeedbackOpened
  .on(openFeedbackModal, () => true)
  .reset(closeFeedbackModal, sendMailFeedbackFx.done)

$selectedFeedbackFiles
  .on(addSelectedFeedbackFiles, (s, a) => [...s, ...a.map((file) => (
    {
      file,
      extension: getFileExtension(file.name),
    }))],
  )
  .on(deleteFeedbackFile, (s, index) => s.filter((_, fileIndex) => fileIndex !== index))
  .on(initFeedbackImagesFx.doneData, (_, s) => s)
  .reset(sendMailFeedbackFx.done)

sample({
  clock: addSelectedFeedbackFiles,
  source: $selectedFeedbackFiles,
  target: initFeedbackImagesFx,
})

initFeedbackImagesFx.use(async (files) => {
  const newFiles: FileItem[] = []
  for (const fileItem of files) {
    const imgUrl = await getImageUrl(fileItem.file) || undefined
    newFiles.push({
      ...fileItem,
      imgUrl,
    })
  }
  return newFiles
})

sample({
  clock: $feedbackForm.formValidated,
  source: $selectedFeedbackFiles,
  fn: (files, { theme, message }) => ({
    theme,
    message,
    files: files.map(({ file }) => file),
  }),
  target: sendMailFeedbackFx,
})

sample({
  clock: sendMailFeedbackFx.done,
  fn: () => ({
    content: 'Сообщение успешно отправлено в службу поддержки!',
    icon: NotificationType.Success,
  }),
  target: [showToast, $feedbackForm.reset],

})
