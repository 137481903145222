import React, { memo, useEffect, useState } from 'react'
import styled from 'styled-components'

import {
  HeadXL, ModalLayoutV2, TextAreaField, TextLLight,
} from '@/ui'
import { noop } from '@/lib/noop'
import { ButtonsRow } from '@/features/support/shared'

import { cancelAppealModal, cancelSupportAppeal } from '../../model'
import { ModalProps } from '../../model/types'

export const CancelTechSupportAppeal = memo(({ info, isSending }: ModalProps) => {
  const [reason, setReason] = useState('')

  useEffect(() => setReason(''), [info])

  return (
    <ModalLayoutV2
      borderRadius={12}
      closeModal={isSending ? noop : cancelAppealModal.close}
    >
      <Wrapper>
        <Header>
          <Title>Отменить обращение</Title>
          <Text>
            Введите причину отмены обращения
            {' '}
            {info.number}
          </Text>
        </Header>

        <TextAreaField
          value={reason}
          label="Причина отмены обращения"
          placeholder="Введите причину отмены обращения"
          disabled={isSending}
          height={100}
          dataTestId="cancel-appeal-reason-textarea"
          required
          onChange={setReason}
        />

        <ButtonsRow
          submitLabel="Отменить обращение"
          isDisabled={!reason}
          isPending={isSending}
          onClose={cancelAppealModal.close}
          onSubmit={() => cancelSupportAppeal({ ...info, reason, appealType: info.issue_type })}
        />
      </Wrapper>
    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  width: 542px;
  padding: 32px;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Title = styled.p`
  ${HeadXL}
`

const Text = styled.p`
  ${TextLLight}
`
