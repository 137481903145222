import { sample } from 'effector'

import {
  $contractProjectList,
  $organizationList,
  $statusList,
  FiltersGate,
  filterForm,
  getFSKCompanyFx,
  getProjectsFx,
  getStatusFx,
} from './filters.private'
import { $selectedCompany } from './private'

$contractProjectList
  .on(getProjectsFx.doneData, (_, s) => s.map((item) => ({
    id: item.id,
    label: item.name,
  })))
  .reset(getProjectsFx)

$organizationList
  .on(getFSKCompanyFx.doneData, (_, s) => s.map((item) => ({
    id: item.id,
    label: item.name,
  })))
  .reset(getFSKCompanyFx)

$statusList
  .on(getStatusFx.doneData, (_, s) => s.map((item) => ({
    id: item.map_id,
    label: item.name,
  })))
  .reset(getStatusFx)

sample({
  clock: FiltersGate.close,
  target: filterForm.reset,
})

sample({
  clock: FiltersGate.open,
  fn: () => ({}),
  target: [getProjectsFx, getFSKCompanyFx],
})

sample({
  clock: $selectedCompany.map((company) => company?.id ?? null),
  filter: Boolean,
  fn: (company_id) => ({ company_id }),
  target: getStatusFx,
})
