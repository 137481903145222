import React from 'react'
import { useUnit } from 'effector-react'
import styled from 'styled-components'

import { ContractDirection } from '@/dal'

import { $contract, $hasActions } from '../../../model/private'
import {
  Description,
  Reports,
  ClosingProgress,
  ContractAttachments,
  ContractInfoBlock,
  SpecialContractInfo,
} from '../containers'
import { $contractDirection } from '../../../model'

const $hasContract = $contract.map(Boolean)

export const ContractInfo = React.memo(() => {
  const [hasContract, direction, hasActions] = useUnit([
    $hasContract,
    $contractDirection,
    $hasActions,
  ])

  if (!hasContract) return null

  const hasClosingProgress = direction && ![
    ContractDirection.BANK_WARRANTY,
    ContractDirection.CONTRACT_GUARANTEE,
  ].includes(direction)

  const hasSpecialBlock = !hasClosingProgress

  return (
    <Wrapper>
      <Col>
        <ContractInfoAndFiles>
          <ContractInfoBlock />
          <ContractAttachments />
        </ContractInfoAndFiles>
        {hasActions && <Description />}
      </Col>

      <Col>
        {hasClosingProgress && <ClosingProgress />}
        {hasActions && <Reports />}
        {hasSpecialBlock && <SpecialContractInfo />}
      </Col>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  gap: 16px;
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1 0;
`

const ContractInfoAndFiles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
