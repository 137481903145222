import React from 'react'
import styled from 'styled-components'

type Props = {
  children: React.ReactNode
}

export const TableWrapper = ({ children }: Props) => (
  <Wrapper>
    {children}
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
