import { attach } from 'effector'

import { sendPlanForApproveReqFx } from '@/dal'
import { d } from './domain'

export const agreedPlanContractorAcceptFx = attach({
  effect: sendPlanForApproveReqFx,
})

export const $showPlanAgreedContractorAccept = d.store(false)
export const closePlanAgreedContractorAccept = d.event()
export const sendPlanAgreedContractorAccept = d.event()
