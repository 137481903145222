import {
  combine,
  sample,
  split,
} from 'effector'
import { condition } from 'patronum'

import {
  $selectedGroupId,
  loadGroupTree,
  onSelectedGroupIdChanged,
  resetTree,
} from '@/features/group-tree/model'
import {
  DocumentAlias,
  DocumentStatuses,
  EstimateCorrectionType,
  EstimateDocId,
  EstimateTableRow,
  FileDTO,
} from '@/dal'
import { getUpdatedTree } from '@/lib/tree'
import { onAdditionalRequestUpdated } from '@/features/additional-request/model'
import { onDocumentFilesLoaded } from '@/features/document-files/model'

import {
  $isTreeTable,
  $tableRoot,
  TableGate,
  abortGetEstimateTreeTableFx,
  abortInitFlatTabelFx,
  abortPaginationFlatTabelFx,
  changeIsTree,
  getEstimateFolderRowsFx,
  getEstimateTreeTableFx,
  initFlatTableFx,
  onLoadMore,
  openFolder,
  paginateFlatTableFx,
  toggleCellVisibility,
  $pendingFolderIds,
  onOption,
  $scrollToItemId,
  resetScrollToId,
  resetTableData,
  $tableTree,
  onCloseFolder,
  closeFolders,
  scrollToItemById,
  addCreatedItemsFx,
  updateItemsFx,
  deleteTableItemFx,
  setRootUpdate,
  setMapUpdate,
  attachmentsModel,
  onOpenTableItemFiles,
  $openedTableItemId,
  postAttachForTableItemFx,
  updateItemFilesInTree,
  updateItemFilesInRoot,
  $flatItems,
  onOpenTableItemSplitRowFiles,
  splitRowAttachmentsModel,
  $openedTableItemSplitRowId,
  postAttachForTableItemSplitRowFx,
  updateSplitRowItemFiles,
  onCommentButton,
  readComments,
  $totalWorkCount,
  $isExitSpecialModeModalOpen,
  openExitSpecialModeModal,
  closeExitSpecialModeModal,
} from './private'
import {
  $hiddenCellGroups,
  onItemCreate,
  onItemDelete,
  onItemUpdate,
  getTableData,
  onSplitItemVolume,
} from './public'
import {
  OnOption,
  ItemAction,
  TreeType,
} from './types'
import {
  readCommentsMapHelper,
  updateFilterFn,
} from './helpers'
import {
  $acceptedTypes,
  $searchInputValue,
  acceptSearch,
  clearSearch,
} from './filters.private'
import {
  $documentId,
  $documentInfo,
  getUpdatedFeatures,
  updateDocumentInfo,
  updateEstimateDirectories,
} from '../../shared-model'

import { onApprovingDocumentsDownloaded } from '../../estimate-info/model'
import { openCreateItemModal } from './create-table-item.public'
import { resetFilters, setRowType } from './filters.public'
import { openSplitVolumeModal } from './split-volume.public'
import { editRow } from './edit-table-item.private'
import { onResetTable } from './reset-table-item.private'
import { openDeleteConfirm } from './delete-table-item.private'
import { openCommentsModal } from './comments-item.private'
import { resetKorDocRowsFx } from './reset-multiple-items.private'

import './filters.init'
import './create-table-item.init'
import './split-volume.init'
import './edit-table-item.init'
import './reset-table-item.init'
import './delete-table-item.init'
import './comments-item.init'
import './reset-multiple-items.init'
import './edit-multiple-items.init'

$scrollToItemId
  .on(scrollToItemById, (_, id) => id || null)
  .reset(resetScrollToId, resetTableData)

$isTreeTable
  .on(changeIsTree, (_, val) => val)
  .reset(TableGate.close)

$hiddenCellGroups
  .on(toggleCellVisibility, (groups, groupNumber) => {
    if (groups.includes(groupNumber)) return groups.filter((curr) => curr !== groupNumber)
    return [...groups, groupNumber]
  })
  .reset(TableGate.close)

$tableRoot
  .on(setRootUpdate, (_, root) => root)
  .on([initFlatTableFx.doneData, getEstimateTreeTableFx.doneData], (_, { data }) => data)
  .on(paginateFlatTableFx.doneData,
    (data, { data: newPage }) => (data ? [...data, ...newPage] : newPage))
  .on(updateItemFilesInRoot, (items, { id, files }) => (
    items?.map((item) => (item.id === id ? { ...item, attachments: files } : item))
  ) || null)
  .on(
    updateSplitRowItemFiles,
    (items, { id, files }) => (items?.map((item) => (item?.new_lines?.[0]?.id === id
      ? { ...item, new_lines: [{ ...item.new_lines[0], attachments: files }] }
      : item
    ),
    ) || null
    ),
  )
  .on(readComments, (items, id) => {
    const hasItem = items?.some((item) => item.id === id)
    if (!hasItem) return items
    return items?.map((item) => readCommentsMapHelper(item, id))
  })
  .reset(resetTableData)

$totalWorkCount
  .on([
    initFlatTableFx.doneData,
    getEstimateTreeTableFx.doneData,
  ], (_, response) => response.total)
  .on(onItemCreate, (total) => total && total + 1)
  .on(onItemDelete, (total) => total && total - 1)
  .reset(resetTableData)

$tableTree
  .on(getEstimateFolderRowsFx.done, (map, { params, result }) => ({
    ...map,
    [params.group_id]: result.data,
  }))
  .on(setMapUpdate, (map, update) => ({
    ...map,
    ...update,
  }))
  .on(updateItemFilesInTree, (map, { files, id, parent }) => {
    return {
      ...map,
      [parent]: map[parent]?.map(
        (item) => (item.id === id ? { ...item, attachments: files } : item),
      ),
    }
  })
  .on(readComments, (itemsMap, id) => {
    if (!itemsMap) return itemsMap
    let isChanged = false
    const newMap = { ...itemsMap }
    Object.keys(itemsMap).forEach((key) => {
      const items = itemsMap[key]
      const hasItem = items.some((item) => item.id === id)
      if (!hasItem) return
      isChanged = true
      newMap[key] = items.map((item) => readCommentsMapHelper(item, id))
    })
    return isChanged ? newMap : itemsMap
  })
  .on(closeFolders, (_, { map }) => map)
  .reset(resetTableData)

$pendingFolderIds
  .on(getEstimateFolderRowsFx, (arr, params) => [...arr, params.group_id])
  .on(
    getEstimateFolderRowsFx.finally,
    (arr, { params }) => arr.filter((id) => id !== params.group_id),
  )
  .reset(TableGate.close, getTableData)

$openedTableItemId
  .on(onOpenTableItemFiles, (_, { id }) => id)
  .reset(attachmentsModel.close)

$openedTableItemSplitRowId
  .on(onOpenTableItemSplitRowFiles, (_, { id }) => id)
  .reset(splitRowAttachmentsModel.close)

$isExitSpecialModeModalOpen
  .on(openExitSpecialModeModal, () => true)
  .reset(closeExitSpecialModeModal)

sample({
  clock: [TableGate.close, initFlatTableFx, getEstimateTreeTableFx],
  target: resetTableData,
})

sample({
  clock: TableGate.close,
  target: [
    resetTree,
    abortGetEstimateTreeTableFx,
    abortPaginationFlatTabelFx,
    abortInitFlatTabelFx,
  ],
})

condition<{
  id: EstimateTableRow['id']
  files: FileDTO[]
  parent: string | null
  isTree?: boolean
}>({
  source: sample({
    clock: postAttachForTableItemFx.done,
    source: { items: $flatItems, isTree: $isTreeTable },
    filter: ({ items }, { params }) => Boolean(items?.some((item) => item.id === params.id)),
    fn: ({ items, isTree }, { params, result }) => {
      const item = items?.find((item) => item.id === params.id) as EstimateTableRow
      return {
        id: item.id,
        parent: item.parent,
        files: result,
        isTree,
      }
    },
  }),
  if: ({ parent, isTree }) => Boolean(isTree && parent),
  then: updateItemFilesInTree,
  else: updateItemFilesInRoot,
})

sample({
  clock: postAttachForTableItemSplitRowFx.done,
  source: $flatItems,
  filter: (items, { params }) => Boolean(
    params.id && items?.some((item) => item.new_lines?.[0]?.id === params.id),
  ),
  fn: (_, { result, params }) => ({
    id: params.id,
    files: result,
  }),
  target: updateSplitRowItemFiles,
})

sample({
  clock: onOpenTableItemFiles,
  target: attachmentsModel.open,
})

sample({
  clock: onOpenTableItemSplitRowFiles,
  target: splitRowAttachmentsModel.open,
})

sample({
  clock: $documentId.updates,
  source: $documentInfo,
  filter: (info) => Boolean(
    info?.status !== DocumentStatuses.Draft && info?.features.is_special_visa,
  ),
  fn: () => [EstimateCorrectionType.NotApproved],
  target: setRowType,
})

sample({
  clock: $documentId.updates,
  source: $documentInfo,
  filter: (info) => !(info
    && info?.status === DocumentStatuses.ToBeAgreed
    && info.features.is_special_visa),
  target: getTableData,
})

sample({
  clock: [
    TableGate.open,
    $documentId.updates,
    onItemUpdate,
    onItemCreate,
    onItemDelete,
    onSplitItemVolume,
    resetKorDocRowsFx.done,
    onDocumentFilesLoaded,
  ],
  source: $documentId,
  filter: Boolean,
  fn: (id) => ({
    documentId: id,
    documentType: DocumentAlias.KOR_DOC,
  }),
  target: loadGroupTree,
})

sample({
  clock: [onSelectedGroupIdChanged, onDocumentFilesLoaded],
  filter: TableGate.status,
  target: [resetFilters, getTableData],
})

sample({
  clock: $isTreeTable.updates,
  target: [
    abortGetEstimateTreeTableFx,
    abortPaginationFlatTabelFx,
    abortInitFlatTabelFx,
  ],
})

condition({
  source: sample({
    clock: [
      $isTreeTable.updates,
      getTableData,
      acceptSearch,
      $acceptedTypes.updates,
      clearSearch,
      updateDocumentInfo,
      onAdditionalRequestUpdated,
      onApprovingDocumentsDownloaded,
      resetKorDocRowsFx.done,
    ],
    source: {
      search: $searchInputValue,
      types: $acceptedTypes,
      groupId: $selectedGroupId,
      documentId: $documentId,
    },
    fn: ({ groupId, search, ...map }) => ({
      ...map,
      group_id: groupId || undefined,
      search: search || undefined,
      types: map.types,
    }),
  }),
  if: $isTreeTable,
  then: [abortPaginationFlatTabelFx, getEstimateTreeTableFx] as any,
  else: [abortPaginationFlatTabelFx, initFlatTableFx] as any,
})

sample({
  clock: onLoadMore,
  source: {
    documentId: $documentId,
    isTree: $isTreeTable,
    search: $searchInputValue,
    types: $acceptedTypes,
    workId: $selectedGroupId,
  },
  filter: ({ isTree, documentId }) => Boolean(!isTree && documentId),
  fn: ({ workId, documentId, ...map }, pageCount) => ({
    ...map,
    documentId: documentId as EstimateDocId,
    workId: workId || undefined,
    pageCount,
  }),
  target: paginateFlatTableFx,
})

sample({
  clock: onCloseFolder,
  source: $tableTree,
  fn: (map, parentId) => {
    const copyMap = { ...map }
    const ids = [parentId]
    const closeChildren = (children: EstimateTableRow[] | null) => {
      children?.forEach((node) => {
        closeChildren(copyMap[node.id])
        ids.push(node.id)
        delete copyMap[node.id]
      })
    }

    closeChildren(copyMap[parentId])
    delete copyMap[parentId]

    return { map: copyMap, ids }
  },
  target: closeFolders,
})

sample({
  clock: openFolder,
  source: {
    search: $searchInputValue,
    types: $acceptedTypes,
    documentId: $documentId,
  },
  filter: combine([$isTreeTable, $documentId], ([a, b]) => Boolean(a && b)),
  fn: ({ search, types, documentId }, id) => ({
    search,
    types,
    group_id: id,
    documentId: documentId as EstimateDocId,
  }),
  target: getEstimateFolderRowsFx,
})

// handle menu options

const cases = {
  isCreateFolder: [
    openCreateItemModal
      .prepend(({ parent, isFolder, id }: OnOption) => ({
        isFolder: true,
        parentFolder: isFolder ? id : parent,
      })),
    resetFilters,
    changeIsTree.prepend(() => true),
  ],
  isCreateRow: [
    openCreateItemModal
      .prepend(({ parent, id, isFolder }: OnOption) => ({
        isFolder: false,
        parentFolder: isFolder ? id : parent,
      })),
    resetFilters,
  ],
  splitVolume: openSplitVolumeModal
    .prepend((params: OnOption) => params.id),
  editRow: editRow.prepend((params: OnOption) => params.id),
  resetRow: onResetTable.prepend(({ id }: OnOption) => id),
  deleteItem: openDeleteConfirm.prepend(({ id }: OnOption) => id),
}

split({
  source: onOption,
  match: {
    isCreateFolder: ({ type }) => ItemAction.AddFolder === type,
    isCreateRow: ({ type }) => ItemAction.AddRow === type,
    splitVolume: ({ type }) => ItemAction.SplitVolume === type,
    editRow: ({ type }) => ItemAction.Edit === type,
    resetRow: ({ type }) => ItemAction.Reset === type,
    deleteItem: ({ type }) => ItemAction.Delete === type,
  },
  cases,
})

// update table logic

sample({
  clock: [
    addCreatedItemsFx.doneData,
    updateItemsFx.doneData,
    deleteTableItemFx.doneData,
  ],
  fn: ({ root }) => root,
  target: setRootUpdate,
})

sample({
  clock: addCreatedItemsFx.done,
  filter: ({ params }) => Boolean(params.target?.id),
  fn: ({ params }) => params.target?.id as EstimateTableRow['id'],
  target: scrollToItemById,
})

sample({
  clock: [
    addCreatedItemsFx.doneData,
    updateItemsFx.doneData,
    deleteTableItemFx.doneData,
  ],
  filter: ({ tree }) => Boolean(tree),
  fn: ({ tree }) => tree as TreeType,
  target: setMapUpdate,
})

const updateSource = {
  currentIsTree: $isTreeTable,
  currDocId: $documentId,
  currentRoot: $tableRoot,
  currentTree: $tableTree,
}

sample({
  clock: [onSplitItemVolume, onItemUpdate],
  source: updateSource,
  filter: updateFilterFn,
  fn: (source, params) => ({
    ...source,
    ...params,
  }),
  target: updateItemsFx,
})

sample({
  clock: [onItemUpdate, onItemCreate, onItemDelete, onSplitItemVolume],
  source: $documentId,
  filter: Boolean,
  target: [updateEstimateDirectories, getUpdatedFeatures],
})

sample({
  clock: [updateItemFilesInTree, updateItemFilesInRoot, updateSplitRowItemFiles],
  source: $documentId,
  filter: Boolean,
  target: getUpdatedFeatures,
})

sample({
  clock: onItemCreate,
  source: updateSource,
  filter: updateFilterFn,
  fn: (source, params) => ({
    ...source,
    ...params,
  }),
  target: addCreatedItemsFx,
})

sample({
  clock: onItemDelete,
  source: updateSource,
  filter: updateFilterFn,
  fn: (source, params) => ({
    ...source,
    ...params,
  }),
  target: deleteTableItemFx,
})

sample({
  clock: deleteTableItemFx.done,
  fn: ({ params }) => params.deletedId,
  target: onCloseFolder,
})

sample({
  clock: onCommentButton,
  target: [openCommentsModal, readComments],
})

addCreatedItemsFx.use(({
  currentRoot,
  currentTree,
  root,
  target,
  tree,
  index,
}) => {
  if (root && tree) {
    return getUpdatedTree({
      currentRoot,
      currentTree,
      root,
      tree,
    })
  }

  if (target && index) {
    return {
      root: currentRoot ? currentRoot.toSpliced(index, 0, target) : [target],
    }
  }

  if (target) {
    return {
      root: currentRoot ? [...currentRoot, target] : [target],
    }
  }

  return {
    root: currentRoot,
  }
})

deleteTableItemFx.use(({
  currentRoot,
  currentTree,
  deletedId,
  root,
  tree,
}) => {
  if (root && tree) {
    return getUpdatedTree({
      currentRoot,
      currentTree,
      root,
      tree,
      deletedId,
    })
  }

  return {
    root: currentRoot?.filter((item) => item.id !== deletedId) ?? null,
  }
})
