import React from 'react'
import { useUnit } from 'effector-react'
import styled from 'styled-components'
import { useForm } from 'effector-forms'

import {
  MultiSelectInput,
  MultiSelectPopup,
  NewButton,
  NewInput,
  Switch,
  palette,
} from '@/ui'
import { RowFilterEnum } from '@/dal'

import { ROW_FILTERS } from '../../model/const'
import {
  $articleItems,
  $searchName,
  acceptSearch,
  changeSearchName,
  filters,
  resetSearchName,
} from '../../model/filters.private'
import { $isTree, toggleIsTree } from '../../model/private'
import { $isDraft, $isPIRAct } from '../../../model'
import { PIRFilters } from './PIRFilters'
import { SMRFilters } from './SMRFilters'

export const TableFilters = React.memo(() => {
  const isTree = useUnit($isTree)
  const articles = useUnit($articleItems)
  const isDraft = useUnit($isDraft)
  const isPIR = useUnit($isPIRAct)
  const search = useUnit($searchName)

  const {
    fields: {
      article,
      rowFilters,
    },
  } = useForm(filters)

  const additionalRowFilters = React.useMemo(() => {
    return ROW_FILTERS.filter((filter) => !(isDraft && filter.id === RowFilterEnum.IsCorrected))
  }, [isDraft])

  const rowFiltersCount = rowFilters.value.length

  const rowFilterComponent = React.useCallback(() => (
    <NewButton
      dataTestId=""
      label={!rowFiltersCount ? 'Показать все строки' : `Выбрано: ${rowFiltersCount}`}
      buttonType="grey"
      prefixIcon="filter"
      size="S"
    />
  ), [rowFiltersCount])

  return (
    <Wrapper>
      <Switch
        isChecked={isTree}
        label="Папки"
        onChange={toggleIsTree}
      />
      <VerticalLine />
      <Col>
        <InputWrapper>
          <NewInput
            dataTestId="search-work-in-table-input"
            onChange={changeSearchName}
            value={search}
            placeholder="Поиск по наименованию услуг и ID"
            postfixIcon="search"
            onEnterPress={acceptSearch}
            onClear={resetSearchName}
            onPostfixIconClick={acceptSearch}
          />
        </InputWrapper>

        <InputWrapper>
          <MultiSelectInput
            items={articles}
            selectedIds={article.value}
            testId="act-article"
            onAcceptClick={article.onChange}
            placeholder="Статья оборотов"
            hasAcceptOnClear
          />
        </InputWrapper>

        {isPIR ? <PIRFilters /> : <SMRFilters />}

        <MultiSelectPopup
          items={additionalRowFilters}
          selectedIds={rowFilters.value}
          testId="act-period"
          onAcceptClick={rowFilters.onChange}
          triggerComponent={rowFilterComponent}
          hasAcceptOnClear
        />

      </Col>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: ${palette.white};
  padding: 20px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`

const Col = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`

const InputWrapper = styled.div`
  width: 240px;
  cursor: pointer;

  &:first-child {
    width: 300px;
  }
`

const VerticalLine = styled.div`
  flex-shrink: 0;
  flex-grow: 1;
  width: 1px;
  max-width: 1px;
  height: 100%;
  background-color: ${palette.grey50};
`
