import React from 'react'
import { useGate, useUnit } from 'effector-react'
import styled from 'styled-components'
import { useForm } from 'effector-forms'

import { ContractVisibility } from '@/dal'
import {
  NewInput,
  NewDropDownInput,
  Icon2,
  DefaultButton,
  TextM,
  palette,
  SectionTabs,
  NewDateInput,
} from '@/ui'

import {
  $contractProjectList,
  $directionOptions,
  $organizationList,
  $statusList,
  $visibility,
  FiltersGate,
  changeVisibility,
  filterForm,
  getStatusFx,
} from '../../model/filters.private'

const RADIO_OPTIONS = [
  {
    label: 'Активные',
    id: ContractVisibility.visible,
  },
  {
    label: 'Скрытые',
    id: ContractVisibility.hidden,
  },
]

export const ContractFilter = React.memo(() => {
  useGate(FiltersGate)
  const {
    fields, submit, isDirty, reset,
  } = useForm(filterForm)

  const visibility = useUnit($visibility)
  const directionOptions = useUnit($directionOptions)
  const contractProjectList = useUnit($contractProjectList)
  const organizations = useUnit($organizationList)
  const statusList = useUnit($statusList)
  const isLoading = useUnit(getStatusFx.pending)

  const submitForm = () => {
    submit()
  }

  const handleChange = (key: string, val: unknown) => {
    (fields as any)[key].onChange(val)
    submitForm()
  }

  const handleClearFilter = () => {
    reset()
    submit()
  }

  return (
    <Wrapper>
      <Row>
        <NewDropDownInput
          placeholder="Тип договора"
          options={directionOptions}
          selectedId={fields.direction.value}
          dataTestId="contracts-type-select"
          onOptionClick={(val) => handleChange('direction', val)}
        />
        <NewDropDownInput
          dataTestId="administrative-select-project"
          options={contractProjectList}
          onOptionClick={(val) => handleChange('project_id', val)}
          selectedId={fields.project_id.value}
          placeholder="Выберите проект"
        />
        <NewDropDownInput
          dataTestId="administrative-select-organization"
          options={organizations}
          onOptionClick={(val) => handleChange('company_id', val)}
          selectedId={fields.company_id.value}
          placeholder="Выберите организацию"
        />
        <NewInput
          dataTestId="administrative-document-search"
          value={fields.name.value}
          placeholder="Поиск..."
          onChange={(val) => handleChange('name', val)}
          onPostfixIconClick={submitForm}
          onEnterPress={submitForm}
          postfixIcon="search"
        />
        <NewDropDownInput
          dataTestId="administrative-select-status"
          options={statusList}
          onOptionClick={(val) => handleChange('status', val)}
          selectedId={fields.status.value}
          placeholder="Статус документа"
          isLoading={isLoading}
        />
        <NewDateInput
          placeholder="Дата от"
          inputValue={fields.date_from.value}
          dataTestId="contracts-date-from-input"
          onChange={(val) => handleChange('date_from', val)}
          onClear={() => handleChange('date_from', '')}
        />
        <NewDateInput
          placeholder="Дата до"
          inputValue={fields.date_to.value}
          dataTestId="contracts-date-to-input"
          onChange={(val) => handleChange('date_to', val)}
          onClear={() => handleChange('date_to', '')}
        />

        {isDirty ? (
          <ClearFilters onClick={handleClearFilter}>
            <Icon2
              icon="cross"
              size={16}
            />
            Сбросить
          </ClearFilters>
        ) : (
          <div />
        )}

        <SectionTabs
          items={RADIO_OPTIONS}
          activeId={visibility}
          onItemClick={changeVisibility}
        />
      </Row>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  position: sticky;
  top: 0px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  background-color: ${palette.white};
  padding: 20px;
  border-radius: 12px;
  width: 100%;
  z-index: 2;
`

const Row = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns:
    repeat(3, minmax(190px, 220px)) minmax(210px, 270px) 190px
    repeat(2, 150px) 1fr max-content;
  gap: 12px;
  align-items: center;
`

const ClearFilters = styled(DefaultButton)`
  ${TextM};
  display: flex;
  align-items: center;
  gap: 4px;
  padding-left: 4px;
`
