import { EstimateTableRow } from '@/dal'

import { OnEnterSubmitItem } from '../types'

type FieldOrderItem = {
  name: 'count_in_estimate' | 'price_material' | 'price_work'
  formName: string
  newLinesFormName: string
}

const fieldOrder: FieldOrderItem[] = [
  {
    name: 'count_in_estimate',
    formName: 'count_in_estimate',
    newLinesFormName: 'new_count_in_estimate',
  },
  {
    name: 'price_material',
    formName: 'material',
    newLinesFormName: 'new_material',
  },
  {
    name: 'price_work',
    formName: 'work',
    newLinesFormName: 'new_work',
  },
]

export const getItemToFocus = (items: EstimateTableRow[], payload: OnEnterSubmitItem) => {
  const { direction, isNewLines } = payload

  let currIndex = items.findIndex((item) => item.id === payload.id)
  let fieldIndex = fieldOrder.findIndex((item) => (
    [item.formName, item.newLinesFormName].includes(payload.fieldName)
  ))

  if (currIndex < 0 || fieldIndex < 0) return null

  // вперёд или назад в целом
  const isForwardDirection = ['down', 'right'].includes(direction)
  const isHorizontalDirection = ['left', 'right'].includes(direction)
  const shift = isForwardDirection ? 1 : -1

  // 0 или 1, т. к. максимум две подстроки
  let lineIndex = isNewLines ? 1 : 0
  let hasReachedEnd = false

  while (!hasReachedEnd) {
    if (isHorizontalDirection) {
      fieldIndex += shift
      if (fieldIndex < 0 || fieldIndex > fieldOrder.length - 1) {
        fieldIndex = isForwardDirection ? 0 : fieldOrder.length - 1
        lineIndex += shift
      }
    } else {
      lineIndex += shift
    }

    if (lineIndex < 0) {
      lineIndex = 1
      currIndex -= 1
    }
    if (lineIndex > 1) {
      lineIndex = 0
      currIndex += 1
    }

    const item = items[currIndex] as EstimateTableRow | undefined
    const field = fieldOrder[fieldIndex] as FieldOrderItem | undefined
    const fieldsObj = lineIndex === 1 ? item?.new_lines?.[0] : item
    const isEditable = Boolean(field && fieldsObj?.[field.name]?.editable)

    if (isEditable && item && field) {
      const isNewLines = lineIndex === 1
      return {
        isNewLines,
        id: item.id,
        fieldName: isNewLines ? field.newLinesFormName : field.formName,
        hasToScroll: item.id !== payload.id,
      }
    }

    if (currIndex < 0 || currIndex >= items.length) {
      hasReachedEnd = true
    }
  }
  return null
}
