import { createForm } from 'effector-forms'
import { attach } from 'effector'
import { createGate } from 'effector-react'

import { rules } from '@/lib/form-validators'
import { resetPasswordReqFx, checkPasswordTokenReqFx } from '@/dal'

export const ChangePasswordGate = createGate<string>()

export const changePasswordForm = createForm({
  fields: {
    password: {
      init: '',
      rules: [rules.required()],
    },
    passwordConfirm: {
      init: '',
      rules: [rules.required(), rules.confirmPassword()],
    },
  },
})

export const checkPasswordTokenFx = attach({
  effect: checkPasswordTokenReqFx,
})

export const resetPasswordFx = attach({
  effect: resetPasswordReqFx,
})

