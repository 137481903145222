import {
  AccreditationFailedError,
  AccreditationInProccessError,
  AccreditationTimeOutError,
  CompanyAlreadyExistsInLKPError,
  CompanyNotFoundError,
} from '@/dal'

export enum Step {
  First,
  Second,
  Success,
}

export type CompanyInfo = {
  inn: string,
  name: string | null
  projects: { id: number, name: string }[]
}

export type INNError = CompanyNotFoundError
  | AccreditationTimeOutError
  | CompanyAlreadyExistsInLKPError
  | AccreditationInProccessError
  | AccreditationFailedError
