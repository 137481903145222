import { combine } from 'effector'

import {
  EstimateLot, GetEstimateLotMorphologyResponse, MorphologyItem, MorphologyType,
} from '@/dal'

import { d } from './domain'
import { MorphologyRow } from './types'

export const $morphologyCatalog = d.store<GetEstimateLotMorphologyResponse>({
  construction_objects: [],
  buildings: [],
  construction_section: [],
})

export const $selectedMorphology = d.store<MorphologyItem[]>([])
export const setSelectedMorphology = d.event<MorphologyItem[]>()

export const $isDeleteMorphologyModalOpen = d.store(false)
export const $morphologyToDelete = d.store<any>(null)
export const openDeleteMorphologyModal = d.event()
export const closeDeleteMorphologyModal = d.event()

export const $isNewRowAdded = d.store(false)
export const setIsNewRowAdded = d.event<boolean>()

type ChangeMorphologyRowPayload = {
  row: MorphologyRow
} & ({
  type: MorphologyType.Section
  value: number[]
} | {
  type: MorphologyType.Object | MorphologyType.Building
  value: number
})

export const changeMorphologyRow = d.event<ChangeMorphologyRowPayload>()
export const setMorphologyRows = d.event<EstimateLot['morphology']>()
export const addMorphologyRow = d.event()

export const $morphologyRows = combine(
  ([$morphologyCatalog, $selectedMorphology, $isNewRowAdded]),
  ([catalog, selected, hasNewEmptyRow]) => {
    if (!catalog) return []

    const arr = selected.reduce((acc, curr) => {
      if (curr.type === MorphologyType.Object) {
        acc.push({
          object: curr.id,
          building: null,
          section: [],
        })
      } else if (curr.type === MorphologyType.Building) {
        acc.push({
          object: catalog.buildings.find(({ id }) => id === curr.id)?.construction_object ?? null,
          building: curr.id,
          section: [],
        })
      } else {
        const buildingId = catalog.construction_section.find(
          ({ id }) => id === curr.id,
        )?.building ?? null
        const rowIndex = acc.findIndex(({ building }) => building === buildingId)

        if (rowIndex !== -1) {
          const row = acc[rowIndex]
          acc[rowIndex] = { ...row, section: [...row.section, curr.id] }
        } else {
          acc.push({
            object:
              catalog.buildings.find(({ id }) => id === buildingId)?.construction_object ?? null,
            building: buildingId,
            section: [curr.id],
          })
        }
      }
      return acc
    }, [] as MorphologyRow[])
    return hasNewEmptyRow ? [...arr, { object: null, building: null, section: [] }] : arr
  })

export const $hasFreeBuildings = combine(
  ([$morphologyCatalog, $morphologyRows]),
  ([catalog, rows]) => {
    return catalog.buildings.some((item) => !rows.some(({ building }) => building === item.id))
  },
)
