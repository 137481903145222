import { sample } from 'effector'

import { onErrorAsIssueOccured } from './public'
import { errorAsIssueModal } from './private'

sample({
  clock: onErrorAsIssueOccured,
  fn: ({ data }) => data,
  target: errorAsIssueModal.open,
})
