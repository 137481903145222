import styled from 'styled-components'

import { themeVar } from './theming'

export const PageTitle = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: ${themeVar('text')};
`
