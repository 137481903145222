import { attach } from 'effector'

import { Plan, loadPlanReqFx } from '@/dal'
import { d } from './domain'

export const $openedFundPlan = d.store<Plan | null>(null)
export const $openedFundPlanStatus = $openedFundPlan.map((item) => item?.status ?? null)
export const $openedFundPlanId = $openedFundPlan.map((item) => item?.id ?? null)
export const $openedFundPlanFiles = $openedFundPlan.map((item) => item?.files ?? [])
export const updateOpenedPlan = d.event<Plan>()
export const updateSums = d.event<Pick<Plan, 'avans_work' | 'cost_all' | 'cost_material' | 'cost_work'>>()

export const loadPlan = d.event<Plan['id']>()
export const clearPlan = d.event()

export const loadPlanFx = attach({
  effect: loadPlanReqFx,
})
