import React from 'react'
import styled from 'styled-components'

import { SimpleLoader } from '../../Loader'

type Props = {
  size?: number
  isShown: boolean
  position?: 'absolute' | 'fixed'
  inset?: number
}

export const LoaderOverlay = ({
  isShown, size = 64, position = 'absolute', inset = 0,
}: Props) => {
  if (!isShown) return null

  return (
    <Overlay position={position} inset={inset}>
      <SimpleLoader sizePx={size} />
    </Overlay>
  )
}

type OverlayProps = Required<Pick<Props, 'position' | 'inset'>>

const Overlay = styled.div<OverlayProps>`
  position: ${({ position }) => position};
  inset: ${({ inset }) => inset}px;
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
  background: rgba(255, 255, 255, 0.3);
`
