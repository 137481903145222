export enum StepType {
  Rating = 'rating',
  SingleChoice = 'single_choice',
  MultipleChoice = 'multiple_choice',
}

export type StepChoice = {
  id: number
  ordering: number
  title: string
  can_answer_with_text: boolean
  answer_textarea_placeholder_text: string | null
}

export type SurveyStep = {
  id: number
  title: string
  description: string
  ordering: number
  type: StepType
  rate_stars_signs: string[] | null
  choices: StepChoice[]
}

export type SurveyInfo = {
  id: number
  title: string
  description: string
  first_slide_picture: string
  last_slide_picture: string
  steps: SurveyStep[]
}

type RatingStepResult = {
  id: number
  stars: number
}

type SurveyChoiceResult = {
  id: number
  answer_text: string | null
}

type ChoiceStepResult = {
  id: number
  choices: SurveyChoiceResult[]
}

export type SendSurveyResultParams = {
  steps: (RatingStepResult | ChoiceStepResult)[]
}
