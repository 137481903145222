import { combine } from 'effector'
import { Field } from 'effector-forms'

import { EstimateTableRow, updateMultipleKorDocItemsReqFx } from '@/dal'
import { createChunkRequest } from '@/features/factories'

import { d } from './domain'
import {
  ItemToFocus, NonFormField, OnEnterSubmitItem, SpecificFieldPayload,
} from './types'
import { editItemForm } from './edit-table-item.private'

type EditItemFormFields = typeof editItemForm.fields
type EditItemForm = {
  [K in keyof EditItemFormFields]: EditItemFormFields[K] extends Field<infer U> ? U : never
}

export type RowsFormData = {
  [K in keyof EditItemForm]: NonFormField<EditItemForm[K]>
}

export type RowsFormDataMap = {
  [key: EstimateTableRow['id']]: RowsFormData
}

export const $isMultipleEditModeEnabled = d.store(false)
export const enableMultipleEditMode = d.event()
export const exitMultipleEditMode = d.event()

export const $itemToFocus = d.store<ItemToFocus | null>(null)
export const setItemToFocus = d.event<ItemToFocus | null>()
export const resetItemToFocus = d.event()
export const onEnterSubmitItem = d.event<OnEnterSubmitItem>()

export const $rowsFormDataMap = d.store<RowsFormDataMap>({})
export const updateRowsFormDataMap = d.event<EstimateTableRow[]>()
export const changeSpecificField = d.event<SpecificFieldPayload>()

export const $changedRowIds = d.store<EstimateTableRow['id'][]>([])
export const addChangedRowId = d.event<EstimateTableRow['id']>()

export const $invalidRowIds = d.store<EstimateTableRow['id'][]>([])
export const setInvalidRowId = d.event<EstimateTableRow['id']>()
export const removeInvalidRowId = d.event<EstimateTableRow['id']>()

export const $canSendEditedRows = combine(
  [$invalidRowIds, $changedRowIds],
  ([invalidRows, changedRows]) => Boolean(!invalidRows.length && changedRows.length),
)

export const $currentFormId = d.store<EstimateTableRow['id'] | null>(null)
export const setCurrentFormId = d.event<EstimateTableRow['id']>()
export const validationChecked = d.event<EstimateTableRow['id']>()

export const $lastChangedField = d.store<SpecificFieldPayload | null>(null)
export const setLastChangedField = d.event<SpecificFieldPayload>()

export const updateMultipleKorDocItems = d.event<void>()

export const {
  effectFx: updateMultipleKorDocItemsFx,
  requestEndedWithErrors,
  $counter,
  $totalItems,
} = createChunkRequest({
  fetchEffect: updateMultipleKorDocItemsReqFx,
  domain: d,
})
