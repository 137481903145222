import styled from 'styled-components'

export const InputWrapper = styled.div`
  width: 240px;
  cursor: pointer;

  &:first-child {
    width: 300px;
  }
`

export const DateInputWrapper = styled(InputWrapper)`
  width: 250px;
`
