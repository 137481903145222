import { Effect, sample } from 'effector'

import { CustomResponseError, isCancelError, isCustomError } from '@/dal'
import { NotificationType } from '@/ui'

import { showToast } from './public'

type Params<P, R, E> = {
  effect: Effect<P, R, E>
  doneText?: string | ((value: { params: P; result: R }) => string)
  errorText?: string
}

export const createToast = <P, R, E extends Error | CustomResponseError<Detail>, Detail>({
  doneText,
  effect,
  errorText,
}: Params<P, R, E>) => {
  sample({
    clock: effect.failData,
    filter: (error) => !isCustomError(error) && !isCancelError(error),
    fn: (error) => ({
      content: errorText || error.message || 'Произошла ошибка',
      icon: NotificationType.Alert,
    }),
    target: showToast,
  })

  sample({
    clock: effect.failData,
    filter: (error) => isCustomError(error) && typeof error.data.detail === 'string',
    fn: (error) => ({
      content: errorText || (error as CustomResponseError).data.detail,
      icon: NotificationType.Alert,
    }),
    target: showToast,
  })

  sample({
    clock: effect.done,
    filter: () => Boolean(doneText),
    fn: (data) => ({
      content: typeof doneText === 'function' ? doneText(data) : doneText,
      icon: NotificationType.Success,
    }),
    target: showToast,
  })
}
