import React from 'react'
import styled from 'styled-components'

import { PlanForm } from './PlanForm'
import { PlanBudget } from './PlanBudget'
import { PlanAdvance } from './PlanAdvance'

export const PlanBody = React.memo(() => (
  <Wrapper>
    <PlanBudget />
    <PlanAdvance />
    <FormWrapper>
      <PlanForm />
    </FormWrapper>
  </Wrapper>
))

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  gap: 47px;
`

const FormWrapper = styled.div`
  height: 91px;
`
