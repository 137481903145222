import React from 'react'
import styled, { css } from 'styled-components'

import {
  Icon2,
  TextL,
  TextLLight,
  palette,
} from '@/ui'

type Props = {
  items: string[]
  isInline?: boolean
}

export const Breadcrumbs = ({ items, isInline = true }: Props) => {
  if (!items.length) return null

  return (
    <Wrapper isInline={isInline}>
      {items.map((name, i) => (
        <BreadcrumbItem key={`${i}-${name}`} isDeepest={i === items.length - 1}>
          {i > 0 && isInline && (
            <Icon2
              icon="arrowRightS"
              size={12}
              color="accent100"
            />
          )}
          {name}
        </BreadcrumbItem>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ isInline: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 0 4px;

  ${({ isInline }) => !isInline && css`
    flex-direction: column;
    gap: 4px;
  `}
`

const BreadcrumbItem = styled.div<{ isDeepest: boolean }>`
  ${TextLLight}
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${palette.grey70};

  ${({ isDeepest }) => isDeepest && css`
    ${TextL}
  `}
`
