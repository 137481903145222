import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'
import { useForm } from 'effector-forms'
import Scrollbars from 'react-custom-scrollbars-2'

import {
  AttachmentsInfo,
  HeadXL,
  NewButton,
  NewTextButton,
  NotificationItem,
  NotificationType,
  TextMLight,
} from '@/ui'
import { GFPR_TEMPLATE_URL, GPR_TEMPLATE_URL } from '@/dal'

import {
  $canSendSecondApprove,
  approveDocumentFx,
  closeModal,
  secondApprovingForm,
} from '../../model/private'
import { FileSlotItem } from '../parts'

export const SecondApproving = React.memo(() => {
  const {
    fields,
    submit,
  } = useForm(secondApprovingForm)
  const canSendSecondApprove = useUnit($canSendSecondApprove)
  const isPending = useUnit(approveDocumentFx.pending)

  return (
    <>
      <HeadText>
        Согласование решения
      </HeadText>
      <ScrollWrapper>
        <Scrollbars autoHeight autoHeightMin={0} autoHeightMax="50dvh">
          <ScrollContent>

            <NotificationItem
              type={NotificationType.Warning}
              body={(
                <Text>
                  Для согласования решения приложите:
                  <ul>
                    <li>Файл с текстом дополнительного соглашения,</li>
                    <li>
                      <NewTextButton
                        dataTestId=""
                        textStyle="ML"
                        label="ГФПР (график финансирования производственных работ)"
                        url={GFPR_TEMPLATE_URL}
                        tooltip="Шаблон для заполнения ГФПР"
                        isDownload
                        hasUnderlineOnHover
                        tooltipWithArrow={false}
                      />
                      ,
                    </li>
                    <span>
                      <li>
                        <NewTextButton
                          dataTestId=""
                          textStyle="ML"
                          label="ГПР (график производственных работ)"
                          url={GPR_TEMPLATE_URL}
                          tooltip="Шаблон для заполнения ГПР"
                          tooltipWithArrow={false}
                          isDownload
                          hasUnderlineOnHover
                        />
                        {' '}
                        в случае, если у вас были добавлены
                        новые работы или изменились сроки выполнения работ.
                      </li>
                    </span>
                  </ul>
                  После согласования предварительной спецификации будет запущен процесс
                  формирования дополнительного соглашения на изменение условий.
                </Text>
              )}
            />

            <FileList>
              <FileSlotItem
                onChange={fields.dsFile.onChange}
                onClear={fields.dsFile.reset}
                title="Файл с текстом ДС"
                disable={isPending}
                value={fields.dsFile.value}
              />
              <FileSlotItem
                onChange={fields.gfprFile.onChange}
                onClear={fields.gfprFile.reset}
                disable={isPending}
                title="ГФПР"
                value={fields.gfprFile.value}
              />
              <FileSlotItem
                onChange={fields.gprFile.onChange}
                onClear={fields.gprFile.reset}
                disable={isPending}
                title="ГПР"
                value={fields.gprFile.value}
              />
              <AttachmentsInfoWrapper>
                <AttachmentsInfo />
              </AttachmentsInfoWrapper>
            </FileList>

          </ScrollContent>
        </Scrollbars>
      </ScrollWrapper>


      <ButtonRow>
        <NewButton
          isFill
          label="Согласовать"
          dataTestId="approve-button"
          onClick={submit}
          isPending={isPending}
          isDisabled={!canSendSecondApprove}
        />
        <NewButton
          dataTestId="close-modal"
          label="Назад"
          buttonType="grey"
          isFill
          onClick={closeModal}
        />
      </ButtonRow>
    </>
  )
})

const ScrollWrapper = styled.div`
  margin: 0 -32px 24px;
`

const ScrollContent = styled.div`
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const HeadText = styled.div`
  ${HeadXL}
`

const Text = styled.div`
  ${TextMLight}

  ul {
    padding-left: 8px;
    margin: 8px 0;
  }
  li * {
    display: inline-block;
  }
`

const FileList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const AttachmentsInfoWrapper = styled.div`
  margin-left: 140px;
`

const ButtonRow = styled.div`
  display: flex;
  gap: 16px;
`
