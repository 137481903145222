import { sample } from 'effector'
import { v4 as uuid4 } from 'uuid'

import { ActDTO, PaymentOrder } from '@/dal'

import {
  $deletingPaymentOrders,
  $editingPaymentOrders,
  $isPaymentOrdersModalOpen,
  addNewPaymentOrder,
  closePaymentOrdersModal,
  deletePaymentOrdersFx,
  editPaymentOrder,
  markPaymentOrderToDelete,
  onDeleteSinglePaymentOrder,
  onOpenPaymentOrdersModal,
  onUpdatePaymentOrders,
  openPaymentOrdersModal,
  postPaymentOrdersFx,
  setEditingPaymentOrders,
} from './payment-orders.private'
import {
  $documentId,
  $paymentOrders, ActGate,
} from './public'
import { EditingPaymentOrder } from './types'

$isPaymentOrdersModalOpen
  .on(openPaymentOrdersModal, () => true)
  .reset(ActGate.close, closePaymentOrdersModal)

$editingPaymentOrders
  .on(setEditingPaymentOrders, (_, orders) => (
    orders?.length ? orders : [{
      number: '',
      date: '',
      id: uuid4(),
      isNew: true,
    }]
  ))
  .on(addNewPaymentOrder, (orders) => ([
    ...orders,
    {
      number: '',
      date: '',
      id: uuid4(),
      isNew: true,
    },
  ]))
  .on(editPaymentOrder, (orders, data) => orders.map(
    (order) => (order.id === data.id ? { ...order, ...data } : order) as EditingPaymentOrder,
  ))
  .on(markPaymentOrderToDelete, (orders, id) => {
    const newOrders = orders.map((order) => {
      if (order.id === id) {
        return order.isNew ? null : {
          ...order,
          isMarkedToDelete: true,
        }
      }
      return order
    }).filter((order) => Boolean(order))
    return newOrders as EditingPaymentOrder[]
  })
  .reset(ActGate.close)

$deletingPaymentOrders
  .on(deletePaymentOrdersFx,
    (orders, { toDeleteId: toDelete }) => [...orders, toDelete],
  )
  .on(deletePaymentOrdersFx.done, (orders, { params }) => orders.filter((id) => (
    params.toDeleteId !== id
  )))
  .reset(ActGate.close)

sample({
  clock: onDeleteSinglePaymentOrder,
  source: $documentId,
  filter: Boolean,
  fn: (actId, orderId) => ({ id: actId, toDeleteId: orderId }),
  target: deletePaymentOrdersFx,
})

sample({
  clock: onOpenPaymentOrdersModal,
  source: $paymentOrders,
  target: [openPaymentOrdersModal, setEditingPaymentOrders],
})

sample({
  clock: onUpdatePaymentOrders,
  source: { id: $documentId, paymentOrders: $editingPaymentOrders },
  filter: ({ id, paymentOrders }) => Boolean(id && paymentOrders),
  fn: ({ id, paymentOrders }) => {
    const to_delete = paymentOrders.filter(({ isMarkedToDelete }) => isMarkedToDelete)
      .map((item) => item.id as PaymentOrder['id'])
    const to_update = paymentOrders.filter(({ isMarkedToDelete }) => !isMarkedToDelete)
      .map(({
        id, number, date, isNew,
      }) => ({ id: isNew ? null : id, number, date }))
    return { id: id as ActDTO['id'], to_delete, to_update }
  },
  target: postPaymentOrdersFx,
})

sample({
  clock: postPaymentOrdersFx.done,
  target: closePaymentOrdersModal,
})
