import React from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { Keymap } from './hotkeys'

export const useEnterButtonNavigate = () => {
  const [index, setIndex] = React.useState<number|null>(null)

  useHotkeys(Keymap.enter, () => { setIndex((currIndex) => (currIndex ?? 0) + 1) }, { enableOnTags: ['INPUT'] }, [index])

  return ({
    index,
    setIndex,
  })
}
