import {
  CustomResponseError, ErrorAsIssueError, RequestCancelError, TextError, UserNotFoundError,
} from './errors'
import { CustomResponseErrorData, DefaultErrorCode, DefaultErrorResponse } from './types'

export type DefaultHandledError =
  | RequestCancelError
  | TextError
  | UserNotFoundError
  | ErrorAsIssueError
  | CustomResponseError

export const defaultErrorHandler = <T>(
  error: DefaultErrorResponse | undefined,
): DefaultHandledError => {
  if (!error) {
    return new RequestCancelError({
      code: DefaultErrorCode.RequestCancel,
      detail: null,
    })
  }

  switch (error.code) {
    case DefaultErrorCode.TextError: {
      return new TextError(error)
    }
    case DefaultErrorCode.UserNotFound: {
      return new UserNotFoundError(error)
    }
    case DefaultErrorCode.ErrorAsIssue: {
      return new ErrorAsIssueError(error)
    }
    default: {
      return new CustomResponseError(error as CustomResponseErrorData<T>)
    }
  }
}
