import {
  Domain,
  Effect,
  Store,
  attach,
  sample,
} from 'effector'

import { DefaultHandledError, DefaultUpdateFileParams, FileDTO } from '@/dal'
import { root } from '@/root-domain'
import { createToast } from '@/features/toast-service/model'

import { createDeleteFilesModel } from '../createDeleteFilesModel'

type Params<T> = {
  domain?: Domain,
  $files: Store<FileDTO[] | null>
  $canSendFiles: Store<boolean>
  sendFx: Effect<
    {
      id: T,
    } & DefaultUpdateFileParams,
    FileDTO[],
    DefaultHandledError
  >
}

export const createAttachmentsModalModel = <T = number>({
  domain,
  $files,
  sendFx,
  $canSendFiles,
}: Params<T>) => {
  const d = domain || root.createDomain()

  const $entityId = d.store<T | null>(null)
  const open = d.event<{ id: T }>()
  const close = d.event<void>()

  const $isNotificationShown = d.store<boolean>(true)
  const closeNotification = d.event<void>()

  const $filesToSave = d.store<File[]>([])
  const onChangeFiles = d.event<File[]>()

  const sendFiles = d.event<void>()

  const sendAttacmentsFx = attach({
    effect: sendFx,
  })

  const {
    $filesToDelete,
    $visibleFiles,
    markToDelete,
    resetModel,
  } = createDeleteFilesModel({ domain: d, $files })

  const $filesCount = $files.map((files) => files?.length || 0)

  $entityId
    .on(open, (_, { id }) => id)
    .reset(close)

  $isNotificationShown
    .on(closeNotification, () => false)
    .reset(close)

  $filesToSave
    .on(onChangeFiles, (_, files) => files)
    .reset(sendAttacmentsFx.doneData, close)

  sample({
    clock: close,
    target: resetModel,
  })

  sample({
    clock: sendFiles,
    source: {
      id: $entityId,
      files_to_save: $filesToSave,
      canSendFiles: $canSendFiles,
      files_to_delete: $filesToDelete,
    },
    filter: ({ id, canSendFiles }) => Boolean(id && canSendFiles),
    fn: ({ files_to_save, files_to_delete, id }) => ({
      id: id as T,
      files_to_save,
      files_to_delete,
    }),
    target: sendAttacmentsFx,
  })

  const $isSendAttachmentsPending = sendAttacmentsFx.pending

  createToast({
    effect: sendAttacmentsFx,
    doneText: 'Вложения сохранены',
  })

  return {
    $isSendAttachmentsPending,
    $visibleFiles,
    $files: $filesToSave,
    $filesToDelete,
    $filesCount,
    $entityId,
    $isNotificationShown,
    close,
    open,
    closeNotification,
    onChangeFiles,
    markToDelete,
    sendFiles,
    $canSendFiles,
  }
}
