import React, { memo } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

import { AppealAlias } from '@/dal'
import {
  palette,
  TooltipProps,
} from '@/ui'
import { APPEAL_SECTIONS } from '@/features/support/model'

import { SelectAppealButton, SelectAppealFooter, TelegramLink } from '../parts'
import { onSelectAppealTypeClicked } from '../../model/private'

type Props = TooltipProps & {
  onClose: () => void
}

export const SelectAppealWindow = memo(({
  floatingStyles, refs, getFloatingProps, onClose,
}: Props) => {
  const handleSelectAppealType = (alias: AppealAlias) => {
    onSelectAppealTypeClicked(alias)
    onClose()
  }

  return createPortal(
    <>
      <Wrapper
        ref={refs.setFloating}
        style={floatingStyles}
        {...getFloatingProps()}
        onClick={(e) => e.stopPropagation()}
      >
        <Cards>
          {APPEAL_SECTIONS
            .filter(({ description }) => description)
            .map((item) => (
              <SelectAppealButton key={item.alias} {...item} onClick={handleSelectAppealType} />
            ))}

          {/* TODO! скрытие */}
          {/* <TelegramLink /> */}
        </Cards>

        <Separator />

        <SelectAppealFooter />
      </Wrapper>
      <Overlay onClick={onClose} />
    </>,
    document.body,
  )
},
)

const Overlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: 10;
  /* вне ui kit */
  background-color: #0000001a;
`

const Wrapper = styled.div`
  z-index: 11;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 820px;
  padding: 20px;
  background-color: ${palette.white};
  border-radius: 12px;
  box-shadow: 0px 4px 20px 0px #1c1d1e1f;
`

const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 12px;

  & > * {
    height: 100%;
    min-height: 154px;
  }
`

const Separator = styled.div`
  height: 2px;
  background-color: ${palette.grey30};
`
