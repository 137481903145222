import { attachWrapper } from '@42px/effector-extra'
import { AxiosError, AxiosResponse } from 'axios'

import {
  PaginationRequestParams,
  PaginationType,
  AbortableRequest,
} from '../interfaces'
import { Method, authRequestFx } from '../request'
import {
  ActiveTaskCount,
  AppealTaskItem,
  SystemActions,
  TaskFilter,
  TaskItem,
} from './type'
import { defaultErrorHandler } from '../error-handler'

export const getActiveTasksReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({
    type,
    signal,
    ...query
  }: AbortableRequest<PaginationRequestParams & {type: string}>) => ({
    method: Method.get,
    url: '/main/active-tasks/',
    query: {
      ...query,
      filter: type === 'all' ? undefined : type,
    },
    signal,
  }),
  mapResult: (
    { result } : {result: AxiosResponse<PaginationType<(TaskItem | AppealTaskItem)[]>>},
  ): PaginationType<(TaskItem | AppealTaskItem)[]> => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const getActiveTaskCountReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: () => ({
    method: Method.get,
    url: '/main/active-tasks/count/',
  }),
  mapResult: ({ result }: { result: AxiosResponse<ActiveTaskCount> }) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const getTaskFiltersReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ signal }: AbortableRequest) => ({
    method: Method.get,
    url: '/main/active-tasks/filters/',
    signal,
  }),
  mapResult: ({ result }: { result: AxiosResponse<TaskFilter[]> }) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const getSystemActionsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ signal }: AbortableRequest) => ({
    method: Method.get,
    url: '/main/menu-dots/',
    signal,
  }),
  mapResult: ({ result }: { result: AxiosResponse<SystemActions> }) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})
