import React from 'react'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'

import {
  palette,
} from '@/ui'

export const WideArticleCardSkeleton = () => (
  <Wrapper>
    <Row>
      <Title>
        <Skeleton width="45%" height={22} />
      </Title>
      <Description>
        <Skeleton width="80%" height={20} />
        <Skeleton width="70%" height={20} />
      </Description>
    </Row>
    <Footer>
      <Date>
        <Skeleton width={100} height={18} />
      </Date>
      <Skeleton width={30} height={18} />
    </Footer>
  </Wrapper>
)

const Title = styled.h5`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden; 
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background-color: ${palette.white};
  border-radius: 20px;
  border: 1px solid ${palette.grey40};
  padding: 24px;
  width: 100%;
  cursor: pointer;
`

const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const Description = styled.p`
  margin-bottom: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Date = styled.time``
