import React from 'react'

import { TableHeaderCell, TableHeaderCellProps } from '@/ui'

type Props = Omit<TableHeaderCellProps, 'label'> & ({
  label: string
  isEmpty?: false
} | {
  isEmpty: true
  label?: never
})

export const FilterCell = ({ label, isEmpty, ...props }: Props) => {
  return (
    <TableHeaderCell
      label={label ?? ''}
      textAlign="left"
      padding="16px 8px"
      gap={8}
      {...props}
    />
  )
}
