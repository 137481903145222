import PdfIcon from './pdf-icon.svg'
import PngFile from './png-file.svg'
import JPEGFile from './jpeg-file.svg'
import SVGFile from './svg-file.svg'
import GIFFile from './gif-file.svg'
import XslFile from './xsl-file.svg'
import ZipFile from './zip-file.svg'

export const fileIcons = {
  'pdf-icon': PdfIcon,
  'png-icon': PngFile,
  'jpeg-icon': JPEGFile,
  'jpg-icon': JPEGFile,
  'svg-icon': SVGFile,
  'gif-icon': GIFFile,
  'xsl-icon': XslFile,
  'zip-icon': ZipFile,
}
