import { attach } from 'effector'

import {
  ContractDirection,
  ContractItem,
  ContractType,
  getContractByIdReqFx,
  postContractAttachmentsReqFx,
  putContractVisibilityReqFx,
  updateClosurePercentageReqFx,
} from '@/dal'
import { createSingleEffect } from '@/lib/createSingleEffect'
import { createAttachmentsModalModel } from '@/features/factories/attach-modal'

import { d } from './domain'
import { ContractListGate } from '../../contract-list/model/private'

export const $contract = d.store<ContractItem | null>(null)
export const $canSendFiles = $contract.map((info) => Boolean(info?.features.can_add_attachments))
export const $hasActions = $contract.map(
  (info) => info
    && ![ContractType.ChangeConditions, ContractType.NotFinance].includes(info.type.map_id)
    && ![
      ContractDirection.BANK_WARRANTY,
      ContractDirection.CONTRACT_GUARANTEE,
    ].includes(info.direction),
)
export const $documentFeatures = $contract.map((item) => item?.features || null)
export const toggleVisibility = d.event<void>()

export const onOpenAdditionalModal = d.event<void>()

export const updateAttachments = d.event<ContractItem['attachments']>()

export const sendFilesFx = attach({
  effect: postContractAttachmentsReqFx,
})

export const attachmentsModel = createAttachmentsModalModel({
  domain: d,
  sendFx: sendFilesFx,
  $canSendFiles,
  $files: $contract.map((info) => info?.attachments || null),
})

export const openAttachmentsModal = d.event<void>()

export const changeVisibilityFx = attach({
  effect: putContractVisibilityReqFx,
})

export const onCloseButton = d.event<boolean>()
export const updateContract = d.event<ContractItem>()
export const onGetRetentionButton = d.event<void>()

export const onOpenCreateDocumentModal = d.event<void>()
export const onRequestAdvans = d.event<void>()

export const $isClosurePercentageUpdated = d.store<boolean>(false)
export const onUpdateClosurePercentage = d.event<ContractItem['id']>()

export const {
  abortFx,
  requestFx: getContractByIdFx,
} = createSingleEffect(getContractByIdReqFx, ({ abortEvents: [ContractListGate.close] }))

export const updateClosurePercentageFx = attach({
  effect: updateClosurePercentageReqFx,
})
