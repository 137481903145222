import React from 'react'
import styled from 'styled-components'

import { ButtonType } from './types'
import { loaderStyles } from './styles'
import { Icon2 } from '../../icons'
import { rotating } from '../../keyframes'

type Props = {
  buttonType: ButtonType
}

export const ButtonLoader = ({ buttonType }: Props) => {
  return (
    <Wrapper buttonType={buttonType}>
      <IconWrapper>
        <Icon2 icon="loaderIcon" />
      </IconWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div<{buttonType: ButtonType}>`
  ${({ buttonType }) => loaderStyles[buttonType]}
  position: absolute;
  inset: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const IconWrapper = styled.div`
  width: 16px;
  height: 16px;
  animation: ${rotating} 1s linear infinite;
`
