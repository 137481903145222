import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { FormModalWrapper, TextL, TextLLight } from '@/ui'

import {
  $isResetModeEnabled,
  exitResetMode,
} from '../../../model/reset-multiple-items.private'
import { $isExitSpecialModeModalOpen, closeExitSpecialModeModal } from '../../../model/private'
import { exitMultipleEditMode } from '../../../model/edit-multiple-items.private'

export const ConfirmExitModeModal = React.memo(() => {
  const [isModalOpen, isResetMode] = useUnit([
    $isExitSpecialModeModalOpen,
    $isResetModeEnabled,
  ])

  if (!isModalOpen) return null

  const handleSubmit = () => {
    if (isResetMode) {
      exitResetMode()
    } else {
      exitMultipleEditMode()
    }
    closeExitSpecialModeModal()
  }

  return (
    <FormModalWrapper
      title="Внимание"
      submitLabel="Все равно выйти"
      cancelLabel="Закрыть"
      width="500px"
      dataTestId="confirm-exit-kordoc-mode-modal"
      closeModal={closeExitSpecialModeModal}
      onSubmit={handleSubmit}
    >
      <Container>
        {isResetMode
          ? 'Вы находитесь в режиме обнуления строк.'
          : 'Вы находитесь в режиме редактирования полей.'}
        <br />
        Вы точно хотите выйти?
        {' '}
        <Strong>Изменения не сохранятся.</Strong>
      </Container>
    </FormModalWrapper>
  )
})

const Container = styled.div`
  ${TextLLight}
  margin-bottom: 12px;
`

const Strong = styled.strong`
  ${TextL}
`
