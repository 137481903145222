import { sample } from 'effector'

import { StepType, SurveyInfo } from '@/dal'
import { createToast } from '@/features/toast-service/model'

import {
  $currentStepNumber,
  $isModalOpen,
  $isSurveyCompleted,
  $stepForms,
  $surveyId,
  $surveyInfo,
  SurveyGate,
  changeStepFormField,
  changeStepFormRating,
  closeModal,
  getActualSurveyFx,
  goToNextStep,
  goToPrevStep,
  openModal,
  sendSurveyResult,
  sendSurveyResultFx,
  setStepForms,
} from './private'
import { requestActualSurvey } from './public'
import { mapSurveyFormsToParams } from './mappers'

$isModalOpen
  .on(openModal, () => true)
  .reset(SurveyGate.close, closeModal)

$surveyInfo
  .on(getActualSurveyFx.doneData, (_, info) => info || null)
  .reset(SurveyGate.close, closeModal)

$stepForms
  .on(setStepForms, (_, steps) => steps.map((step) => {
    if (step.type === StepType.Rating) {
      return {
        id: step.id,
        type: step.type,
        rating: 0,
        descriptions: step.rate_stars_signs as string[],
      }
    }

    const choices = step.choices.map((choice) => ({
      ...choice,
      isChecked: false,
      text: '',
    }))

    return {
      choices,
      type: step.type,
      id: step.id,
    }
  }))
  .on(changeStepFormField, (stepForms, { stepId, choiceId, text = null }) => (
    stepForms.map((stepForm) => {
      if (stepForm.id !== stepId || stepForm.type === StepType.Rating) {
        return stepForm
      }

      const choices = stepForm.choices.map((choice) => ({
        ...choice,
        text: choice.id === choiceId && text !== null ? text : choice.text,
        isChecked: choice.id === choiceId,
      }))

      return { ...stepForm, choices }
    })))
  .on(changeStepFormRating, (stepForms, { stepId, rating }) => stepForms.map((stepForm) => (
    ((stepForm.id !== stepId) ? stepForm : { ...stepForm, rating })
  )))

$currentStepNumber
  .on(goToNextStep, (step) => step + 1)
  .on(goToPrevStep, (step) => step - 1)
  .reset(SurveyGate.close, closeModal)

$isSurveyCompleted
  .on(sendSurveyResultFx.done, () => true)
  .reset(SurveyGate.close, closeModal)

sample({
  clock: requestActualSurvey,
  target: getActualSurveyFx,
})

sample({
  clock: getActualSurveyFx.doneData,
  filter: Boolean,
  target: openModal,
})

sample({
  clock: getActualSurveyFx.doneData,
  filter: Boolean,
  fn: ({ steps }) => steps,
  target: setStepForms,
})

sample({
  clock: sendSurveyResult,
  source: { id: $surveyId, stepForms: $stepForms },
  filter: ({ id, stepForms }) => Boolean(id && stepForms),
  fn: ({ id, stepForms }) => mapSurveyFormsToParams(id as SurveyInfo['id'], stepForms),
  target: sendSurveyResultFx,
})

createToast({
  effect: sendSurveyResultFx,
  errorText: 'Произошла ошибка при отправке результатов опроса',
})
