import React from 'react'
import styled from 'styled-components'
import { useGate, useUnit } from 'effector-react'

import { HeadL, SimpleLoader, palette } from '@/ui'

import { Graph, Legents } from '../container'
import { EmptyData, InfoItem } from '../parts'
import {
  $data, $hasData, ChartGate, getChartDataFx,
} from '../../model/private'

export const DateGraph = React.memo(() => {
  const hasData = useUnit($hasData)
  const data = useUnit($data)
  const isLoading = useUnit(getChartDataFx.pending)

  useGate(ChartGate)

  return (
    <Container>
      <ContentWrapper>
        <Title>
          Статистика сроков
        </Title>

        {isLoading && <LoadingWrapper><SimpleLoader sizePx={32} /></LoadingWrapper>}

        {hasData
          ? (
            <>
              <Graph />
              <Legents />
              <InfoItem daysCount={data?.days_to_approving ?? null} />
            </>
          )
          : !isLoading && <EmptyData />}

      </ContentWrapper>

    </Container>
  )
})

const Container = styled.div`
  padding: 24px 32px;
  border-radius: 20px;
  background: ${palette.white};
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
`

const Title = styled.h3`
  ${HeadL}
`
