import styled, { css } from 'styled-components'

import { TABLE_BORDER_STYLE } from '@/ui'

type Props = {
  isFirstGroupOpen: boolean
  isByConstructionElements: boolean
}

export const GridRow = styled.div<Props>`
  display: grid;
  padding: 0;
  border-bottom: ${TABLE_BORDER_STYLE};

  ${({ isByConstructionElements, isFirstGroupOpen }) => !isByConstructionElements && css`
    grid-template-columns: minmax(456px, 456fr) ${isFirstGroupOpen ? 'minmax(280px, 280fr)' : ''}
      minmax(90px, 90fr) minmax(120px, 120fr) minmax(140px, 140fr)
      repeat(2, minmax(381px, 381fr)) minmax(150px, 150fr) minmax(40px, 40fr);
  `}

  ${({ isByConstructionElements, isFirstGroupOpen }) => isByConstructionElements && css`
    grid-template-columns: minmax(577px, 577fr) ${isFirstGroupOpen ? 'minmax(280px, 280fr)' : ''}
      minmax(90px, 90fr) minmax(150px, 150fr) minmax(200px, 200fr) minmax(100px, 100fr) 
      repeat(2, minmax(321px, 321fr));
  `}
`
