import React from 'react'
import { useUnit } from 'effector-react'
import styled from 'styled-components'

import { RoleItemType } from '@/dal'
import {
  AccordionV2,
  NewTextButton,
  TextMLight,
  palette,
} from '@/ui'

import { ContactItem, RoleHeader } from '../parts'
import {
  $selectedRoleId,
  setSelectedRole,
  onAddContact,
  $projects,
  onEditContact,
  openDeleteContactConfirm,
  $visibleContacts,
} from '../../model/private'
import { $selectedProjectId } from '../../model'

const $hasSelectedProject = $selectedProjectId.map(Boolean)

type Props = {
  roleId: RoleItemType['id']
  roleName: string
  contactCount: number | null
  projectCount: number | null
}

export const RoleItem = React.memo(({
  roleId, roleName, contactCount, projectCount,
}: Props) => {
  const allProjects = useUnit($projects)
  const hasSelectedProject = useUnit($hasSelectedProject)
  const activeId = useUnit($selectedRoleId)

  const contacts = useUnit($visibleContacts)

  const allProjectsCount = allProjects?.length || 0
  const isActive = activeId === roleId

  return (
    <AccordionV2
      headSlot={(
        <RoleHeader
          title={roleName}
          projectCount={projectCount}
          contactCount={contactCount}
          allProjectsCount={allProjectsCount}
          isDisplayingProjects={!hasSelectedProject}
        />
      )}
      isActive={isActive}
      onClick={() => setSelectedRole(isActive ? null : roleId)}
    >
      <ContentWrapper>
        {contacts?.length ? (
          contacts?.map((contact) => (
            <ContactItem
              key={contact.id}
              {...contact}
              roleId={roleId}
              projectsList={contact.projects}
              onClickDeleteButton={openDeleteContactConfirm}
              onClickEditButton={onEditContact}
            />
          ))
        ) : (
          <EmptyList>
            Нет контактов
          </EmptyList>
        )}
        {hasSelectedProject && (
          <NewTextButton
            dataTestId="open-create-contact-modal"
            label="Добавить"
            iconSize={16}
            prefixIcon="plusButton"
            onClick={() => onAddContact(roleId)}
          />
        )}
      </ContentWrapper>
    </AccordionV2>
  )
})



const ContentWrapper = styled.div`
  padding: 24px 12px 24px 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const EmptyList = styled.div`
  ${TextMLight}
  color: ${palette.grey70};
`
