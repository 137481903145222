import React from 'react'
import styled from 'styled-components'

import { AppealAlias } from '@/dal'
import {
  DefaultButton, Icon2, palette, TextL, TextMLight,
} from '@/ui'
import { AppealSection } from '@/features/support/model/types'

import { WrapperStyle } from './styles'

type Props = {
  onClick: (alias: AppealAlias) => void
} & Omit<AppealSection, 'description'>

export const SelectAppealButton = ({
  alias, name, shortDescription, onClick,
}: Props) => {
  return (
    <Wrapper onClick={() => onClick(alias)}>
      <Header>
        <Title>
          {name}
        </Title>
        <IconWrapper>
          <Icon2 icon="arrowRightM" />
        </IconWrapper>
      </Header>

      <Description>
        {shortDescription}
      </Description>
    </Wrapper>
  )
}

const IconWrapper = styled.div`
  flex-shrink: 0;
  padding-top: 2px;
  color: ${palette.grey60};
  transition: color 0.15s linear;
`

const Wrapper = styled(DefaultButton)`
  ${WrapperStyle}
  width: 100%;
  text-align: left;

  &:hover {
    ${IconWrapper} {
      color: ${palette.grey100};
    }
  }
`

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
`

const Title = styled.div`
  ${TextL}
  flex-grow: 1;
`

const Description = styled.p`
  ${TextMLight}
  padding-right: 8px;
  color: ${palette.grey70};
`
