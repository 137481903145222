import { sample } from 'effector'

import {
  CompensationDocument, DocumentAlias, DocumentStatuses, VisasDocType,
} from '@/dal'
import { replaceNavigate, RouterPaths } from '@/features/app/model'
import { onAdditionalRequestUpdated, openAdditionalRequestModal } from '@/features/additional-request/model'
import { createToast } from '@/features/toast-service/model'

import { CompensationDocumentGate } from './public'
import {
  $documentId,
  $documentInfo,
  $isApprovingModalOpen,
  $isCostCalculationModalOpen,
  $isWithComment,
  approveCompensationFx,
  approveForm,
  attachmentsModel,
  changeWithComment,
  closeApprovingModal,
  closeCostCalculationModal,
  getCompensationInfoFx,
  openAdditionalModal,
  openApprovingModal,
  openAttachmentsModal,
  openCostCalculationModal,
  sendFilesFx,
  updateAttachments,
  updateDocumentInfo,
} from './private'
import { WithComment } from './types'

$documentInfo
  .on(getCompensationInfoFx.doneData, (_, info) => info)
  .on(updateDocumentInfo, (_, info) => info)
  .on(updateAttachments, (info, attachments) => (!info ? null : {
    ...info,
    attachments,
  }))
  .reset(CompensationDocumentGate.close)

$isApprovingModalOpen
  .on(openApprovingModal, () => true)
  .reset(closeApprovingModal)

$isCostCalculationModalOpen
  .on(openCostCalculationModal, () => true)
  .reset(closeCostCalculationModal)

$isWithComment
  .on(changeWithComment, (_, val) => val)
  .reset(closeApprovingModal)

sample({
  clock: CompensationDocumentGate.open,
  fn: ({ id }) => ({ id }),
  target: getCompensationInfoFx,
})

sample({
  clock: getCompensationInfoFx.fail,
  filter: CompensationDocumentGate.status,
  target: replaceNavigate.prepend(() => RouterPaths.Administrative),
})

sample({
  clock: openAdditionalModal,
  source: {
    isOpen: CompensationDocumentGate.status,
    doc: $documentInfo,
  },
  filter: ({ isOpen, doc }) => Boolean(isOpen && doc),
  fn: ({ doc }) => ({
    docType: DocumentAlias.COMPENSATION_DOCUMENT as VisasDocType,
    docId: doc?.id as CompensationDocument['id'],
    status: doc?.status as DocumentStatuses,
  }),
  target: openAdditionalRequestModal,
})

sample({
  clock: openAttachmentsModal,
  source: $documentInfo,
  filter: Boolean,
  fn: (doc) => ({
    id: doc.id,
    files: doc.attachments,
  }),
  target: attachmentsModel.open,
})

sample({
  clock: sendFilesFx.doneData,
  target: updateAttachments,
})

sample({
  clock: [$isWithComment.updates, closeApprovingModal],
  target: approveForm.reset,
})

sample({
  clock: approveForm.formValidated,
  source: {
    isWithComment: $isWithComment,
    id: $documentId,
  },
  filter: ({ id }) => Boolean(id),
  fn: ({ id, isWithComment }, { comment }) => ({
    id: id as number,
    comment: isWithComment === WithComment.Yes ? comment : undefined,
  }),
  target: approveCompensationFx,
})

sample({
  clock: approveCompensationFx.doneData,
  target: [updateDocumentInfo, closeApprovingModal],
})

sample({
  clock: onAdditionalRequestUpdated,
  source: $documentId,
  filter: Boolean,
  fn: (id) => ({ id }),
  target: getCompensationInfoFx,
})

createToast({
  effect: approveCompensationFx,
  doneText: 'Согласовано',
})
