import React, { FormEvent } from 'react'
import { useUnit } from 'effector-react'
import Scrollbars from 'react-custom-scrollbars-2'
import { useForm } from 'effector-forms'
import styled from 'styled-components'

import { DocumentStatuses } from '@/dal'
import { Dragger, PDFFileItem, TextArea2 } from '@/ui'

import { $openedFundPlanFiles, $openedFundPlanStatus } from '../../model'
import { editPlanFx, planForm } from '../../model/private'

export const PlanForm = React.memo(() => {
  const openedPlanStatus = useUnit($openedFundPlanStatus)
  const files = useUnit($openedFundPlanFiles)
  const isPending = useUnit(editPlanFx.pending)
  const { fields } = useForm(planForm)

  if (openedPlanStatus !== DocumentStatuses.Draft) return null

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
  }

  const handleFiles = (files: File[]) => {
    fields.files.onChange(files)
  }

  return (
    <Form onSubmit={handleSubmit}>
      <InputWrapper>
        <TextArea2
          dataTestId="plan-form-textarea"
          onChange={fields.comment.onChange}
          value={fields.comment.value}
          resizeBlock
          hiddenError
          label="Комментарий"
          placeholder="Введите текст..."
        />
      </InputWrapper>
      <Wrapper>
        <Scrollbars height="100%">
          <FileInputWrapper>

            {(fields.files.value?.length ?? 0) < 5 && (
              <Dragger
                disabled={isPending}
                isLoading={isPending}
                height="100%"
                onFiles={handleFiles}
              />
            )}

            {Boolean(files?.length) && (
              <FileWrapper>
                {files.map((file) => (
                  <PDFFileItem
                    file={file.file}
                    fileName={file.name}
                    key={file.file}
                  />
                ))}
              </FileWrapper>
            )}

          </FileInputWrapper>
        </Scrollbars>
      </Wrapper>
    </Form>
  )
})

const Form = styled.form`
  height: 100%;
  display: flex;
  gap: 16px;
  width: 100%;
`

const InputWrapper = styled.div`
  flex: 0 0 50%;
  height: 100%;
`

const Wrapper = styled.div`
  flex-grow: 1;
  height: 100%;
`

const FileInputWrapper = styled.div`
  padding-top: 22px;
  display: flex;
  flex-direction: column;
  height: 90px;
`

const FileWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  gap: 5px;
`
