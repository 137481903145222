import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { DocumentStatuses } from '@/dal'
import { NewButton, ToolTipV2 } from '@/ui'
import { $documentStatus } from '@/features/estimate-correction/shared-model'
import { useTooltip } from '@/lib/hooks'

import { openExitSpecialModeModal } from '../../../model/private'
import { $isResetModeEnabled } from '../../../model/reset-multiple-items.private'
import {
  $canSendEditedRows,
  $isMultipleEditModeEnabled,
  enableMultipleEditMode,
  updateMultipleKorDocItems,
  updateMultipleKorDocItemsFx,
} from '../../../model/edit-multiple-items.private'

export const MultipleEditControls = React.memo(() => {
  const [status, isMultipleEditMode, isResetMode, canSubmit, isPending] = useUnit([
    $documentStatus,
    $isMultipleEditModeEnabled,
    $isResetModeEnabled,
    $canSendEditedRows,
    updateMultipleKorDocItemsFx.pending,
  ])

  const {
    floatingStyles,
    getFloatingProps,
    getReferenceProps,
    isOpen,
    refs,
    arrowProps,
    close: closeTooltip,
  } = useTooltip({ tooltipOffset: 6, tooltipCrossOffset: -12, placement: 'top' })

  if (status !== DocumentStatuses.Draft || isResetMode) return null

  const handleClickMultipleEdit = () => {
    closeTooltip()
    enableMultipleEditMode()
  }

  return (
    <Wrapper>
      {!isMultipleEditMode ? (
        <div {...getReferenceProps()} ref={refs.setReference}>
          <NewButton
            label="Массовое редактирование"
            prefixIcon="editStroke"
            size="S"
            dataTestId="enable-multiple-edit-mode-btn"
            onClick={handleClickMultipleEdit}
          />
        </div>
      ) : (
        <>
          <NewButton
            label="Отменить"
            buttonType="grey"
            size="S"
            isDisabled={isPending}
            dataTestId="close-multiple-edit-mode-btn"
            onClick={openExitSpecialModeModal}
          />
          <NewButton
            label="Сохранить изменения"
            size="S"
            isDisabled={!canSubmit}
            isPending={isPending}
            dataTestId="save-multiple-rows-btn"
            onClick={updateMultipleKorDocItems}
          />
        </>
      )}

      <ToolTipV2
        floatingStyles={floatingStyles}
        getFloatingProps={getFloatingProps}
        arrowProps={arrowProps}
        isOpen={isOpen}
        refs={refs}
      >
        <TooltipText>
          <span>В режиме редактирования можно перемещаться в таблице при помощи клавиш:</span>
          <span>стрелки вверх и вниз — перемещение между строками</span>
          <span>клавиша Enter и сочетание Shift+Enter — перемещение между полями</span>
        </TooltipText>
      </ToolTipV2>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`

const TooltipText = styled.p`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 334px;
  text-align: center;
`
