import React, { memo } from 'react'
import styled from 'styled-components'
import { useStoreMap } from 'effector-react'

import {
  DocumentAlias,
  EstimateLotListItem,
  VisasDocType,
} from '@/dal'
import {
  DocumentActionButtons,
  DocumentStatusLabel,
  NewTextButton,
  TextMLight,
  palette,
} from '@/ui'
import { RouterPaths } from '@/features/app/model'
import { formatDateString } from '@/lib/date'
import { numberWithSpaces } from '@/lib/number-represent'

import { Cell, GridTemplate } from '../parts'
import {
  $downloadInfo,
  onAdditionalButtonClicked,
  onCopyButtonClicked,
  onDeleteButtonClicked,
} from '../../model/actions.private'

type Props = EstimateLotListItem

export const EstimateLotItem = memo(({
  answer_required,
  date, // YYYY-MM-DD
  id,
  name,
  period,
  status,
  sums,
  download_link,
  project_name,
  available_actions,
  progress,
  document_type,
  contract_id,
  contract_name,
}: Props) => {
  const isDownloading = useStoreMap({
    store: $downloadInfo,
    keys: [id],
    fn: (list, [id]) => list.includes(id),
  })

  const dates = React.useMemo(() => {
    if (period.date_start && period.date_stop) {
      return `${formatDateString(period.date_start)} – ${formatDateString(period.date_stop)}`
    }
    return ''
  }, [period])

  const canDownload = Boolean(download_link)

  return (
    <Row>
      <Cell>
        <NewTextButton
          url={`${RouterPaths.EstimateLots}/${id}`}
          label={name}
          textStyle="ML"
          dataTestId="estimate-lots-doc-link"
          hasUnderline
          isNewBlank
          isInline
        />
      </Cell>

      <Cell>
        {document_type}
      </Cell>

      <Cell>
        {formatDateString(date)}
      </Cell>

      <Cell>
        {project_name}
      </Cell>

      <Cell>
        {dates}
      </Cell>

      <Cell>
        {contract_name ? (
          <NewTextButton
            url={`${RouterPaths.Administrative}/${contract_id}`}
            label={contract_name}
            textStyle="M"
            dataTestId="estimate-lots-doc-link"
            color="lightBlue"
            hasUnderline
            isNewBlank
            isInline
          />
        ) : (
          <GreyText>Новый договор</GreyText>
        )}
      </Cell>

      <Cell align="right">
        {numberWithSpaces(sums)}
      </Cell>

      <StatusCell>
        <DocumentStatusLabel
          status={status}
          progress={progress}
          progressPosition="right"
          isShort
        />
      </StatusCell>

      <DocumentActionButtons
        id={id}
        availableActions={available_actions}
        canDownload={canDownload}
        isAnswerRequired={answer_required}
        isDownloading={isDownloading}
        onCopy={() => onCopyButtonClicked(id)}
        // TODO добавить скачивание
        onDownload={() => ({})}
        onDelete={() => onDeleteButtonClicked(id)}
        onAdditionalRequestClicked={() => onAdditionalButtonClicked({
          docId: id,
          docType: DocumentAlias.ESTIMATE_LOT as VisasDocType,
          status,
        })}
      />
    </Row>
  )
})

const Row = styled.div`
  ${GridTemplate}
  transition: background-color 0.3s linear;

  &:not(:first-child) {
    border-top: 1px solid ${palette.grey20};
  }

  &:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${palette.grey10};
    }
  }
`

const GreyText = styled.p`
  ${TextMLight}
  color: ${palette.grey70};
`

const StatusCell = styled(Cell)`
  padding: 12px;
`
