import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'
import { combine } from 'effector'
import Scrollbars from 'react-custom-scrollbars-2'

import {
  CloseModalButton,
  DrawerWrapper,
  NewButton,
  TextM,
} from '@/ui'

import {
  $isOpen,
  $selectedNodeId,
  $treeNodeWithoutParent,
  closeModal,
  onAcceptSelectedNode,
} from '../../model/private'
import { $selectedGroupId } from '../../model/public'
import { GroupTitle } from '../parts'
import { TreeNode } from './TreeNode'

const $isDisableAcceptButton = combine([
  $selectedGroupId,
  $selectedNodeId,
], ([work, node]) => work === node)

export const TreeList = React.memo(() => {
  const isOpen = useUnit($isOpen)
  const tree = useUnit($treeNodeWithoutParent)
  const isDisableAcceptButton = useUnit($isDisableAcceptButton)

  if (!isOpen) return null

  return (
    <DrawerWrapper closeModal={closeModal}>
      <Header>
        <GroupTitle />
        <CloseModalButton
          dataTestId="close-additional-modal"
          onClick={closeModal}
        />
        <CostRow>
          Стоимость
        </CostRow>
      </Header>

      <ScrollContainer>
        <Scrollbars height="100%">
          <ListWrapper>
            {tree?.map((node) => (
              <TreeNode id={node.id} key={node.id} />
            ))}
          </ListWrapper>
        </Scrollbars>
      </ScrollContainer>

      <Bottom>
        <NewButton
          dataTestId="cancel-tree-button"
          label="Отменить"
          buttonType="grey"
          onClick={closeModal}
        />
        <NewButton
          dataTestId="accept-tree-button"
          label="Выбрать"
          isDisabled={isDisableAcceptButton}
          onClick={onAcceptSelectedNode}
        />
      </Bottom>
    </DrawerWrapper>
  )
})

const ScrollContainer = styled.div`
  flex-grow: 1;
`

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 32px 16px;
`

const Header = styled.div`
  padding: 32px 32px 8px 32px;
  position: sticky;
  top: 0;
`

const Bottom = styled.div`
  padding: 24px 32px;
  position: sticky;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  bottom: 0;
`

const CostRow = styled.div`
  display: flex;
  justify-content: flex-end;
  ${TextM}
  margin-top: 4px;
`
