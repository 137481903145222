import React from 'react'
import styled from 'styled-components'

import { ContractDirection, ContractDirectionItem } from '@/dal'
import { useTooltip } from '@/lib/hooks'

import { Label, LabelSize } from './labels'
import { ToolTipV2 } from './tooltip'

type Props = {
  type: ContractDirection
  directions: ContractDirectionItem[] | null
  size?: LabelSize
}

export const ContractDirectionLabel = ({ type, directions, size = 'S' }: Props) => {
  const {
    getReferenceProps,
    refs,
    ...tooltip
  } = useTooltip({
    placement: 'right',
    tooltipOffset: 6,
  })

  const direction = directions?.find((item) => item.type === type)

  if (!direction) return null

  const { name, description } = direction

  return (
    <Wrapper
      ref={refs.setReference}
      {...getReferenceProps()}
    >
      <Label
        text={name}
        colorText="grey90"
        size={size}
        bgColor={size === 'M' ? 'grey10' : 'grey20'}
        borderColor={size === 'M' ? 'grey30' : 'grey10'}
      />

      {description && (
        <ToolTipV2 {...tooltip} refs={refs} size="M">
          {description}
        </ToolTipV2>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
`
