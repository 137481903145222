
import { sample } from 'effector'

import { PutDetailsPayload } from '@/dal'
import { createToast } from '@/features/toast-service/model'
import { companyDetailsChanged } from '@/features/login/model'
import { sanitizePhone } from '@/lib/sanitizePhone'

import {
  $isEditing,
  closeEditing,
  openEditing,
  credentialsForm,
  putCompanyDetailsFx,
  DetailsGate,
} from './private'
import { $selectedCompany, $selectedCompanyId } from '../../model'

$isEditing
  .on(openEditing, () => true)
  .reset(DetailsGate.close, closeEditing, $selectedCompanyId, putCompanyDetailsFx.done)

sample({
  clock: putCompanyDetailsFx.doneData,
  target: [companyDetailsChanged, credentialsForm.resetTouched],
})

sample({
  clock: [openEditing, $selectedCompanyId, companyDetailsChanged],
  source: $selectedCompany,
  filter: Boolean,
  fn: (s) => ({
    kpp: s.kpp ?? '',
    okpo: s.okpo ?? '',
    bank_bik: s.bank_bik ?? '',
    acc_number: s.acc_number ?? '',
    phone_number: s.phone_number ? sanitizePhone(s.phone_number) : '',
    sign_name: s.sign_name ?? '',
    sign_position: s.sign_position ?? '',
    legal_address: s.legal_address ?? '',
  }),
  target: credentialsForm.set,
})

sample({
  clock: [DetailsGate.close, closeEditing],
  target: credentialsForm.reset,
})

sample({
  clock: credentialsForm.fields.bank_bik.onChange,
  fn: () => '',
  target: credentialsForm.fields.acc_number.set,
})

sample({
  clock: credentialsForm.formValidated,
  source: {
    form: credentialsForm.$values,
    org: $selectedCompany,
    isValid: credentialsForm.$isValid,
  },
  filter: ({ org, isValid }) => Boolean(org) && isValid,
  fn: ({ form, org }) => {
    const result: PutDetailsPayload = {
      id: org?.id as number,
      params: form,
    }

    const phone = form.phone_number ? `+7${form.phone_number}` : ''
    result.params.phone_number = phone

    return result
  },
  target: putCompanyDetailsFx,
})

createToast({
  effect: putCompanyDetailsFx,
  doneText: 'Данные сохранены',
})
