export enum EstimateLotTab {
  Info = 'Info',
  CommercialOffer = 'CommercialOffer',
}

export type MorphologyRow = {
  object: number | null
  building: number | null
  section: number[]
}
