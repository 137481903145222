import { useUnit } from 'effector-react'
import React from 'react'
import styled from 'styled-components'

import { HeadXL } from '@/ui'


import { DocumentCreationButtons } from '../parts'
import {
  $selectedDocumentType, closeCreateDocumentModal,
} from '../../model/private'
import { SelectDocumentType } from './SelectDocumentType'
import { CreateDocumentContent } from './CreateDocumentContent'

export const CreateDocumentWithSelect = React.memo(() => {
  const selectedDocumentType = useUnit($selectedDocumentType)

  return (
    <Wrapper>
      <Title>
        Создание документа
      </Title>

      <ContentWrapper>
        <SelectDocumentType />
        <CreateDocumentContent />
      </ContentWrapper>

      {!selectedDocumentType && (
        <ButtonsWrapper>
          <DocumentCreationButtons isDisabled onClose={closeCreateDocumentModal} />
        </ButtonsWrapper>
      )}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  padding: 32px 32px 20px;
  width: 500px;
`

const Title = styled.div`
  ${HeadXL};
  margin-bottom: 16px;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const ButtonsWrapper = styled.div`
  margin-top: 24px;
`
