import React from 'react'
import styled from 'styled-components'

import { noop } from '@/lib/noop'
import { ArticleCard } from './ArticleCard'

export const ArticleSkeleton = () => (
  <Row>
    {[...Array(3)].map((_: void, i) => (
      <ArticleCard
        key={i}
        id={0}
        date={null}
        description=""
        title=""
        isLoading
        onCardClick={noop}
      />
    ))}
  </Row>
)

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
`
