import React from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import styled from 'styled-components'

import {
  NotificationItem,
  NotificationType,
  TextM,
  TextMLight,
} from '@/ui'

type Props = {
  users: string[]
}

export const AlreadyExistsInLKP = ({ users }: Props) => {
  return (
    <Scrollbars autoHeight autoHeightMin={0} autoHeightMax="35dvh">
      <NotificationItem
        type={NotificationType.Success}
        body={(
          <Wrapper>
            <Text>
              От вашей компании есть зарегистрированные пользователи в системе,
              свяжитесь с ними, чтобы получить доступ:
            </Text>
            <Users>
              {users.map((user) => (
                <UserItem key={user}>{user}</UserItem>
              ))}
            </Users>
          </Wrapper>
        )}
      />
    </Scrollbars>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Text = styled.div`
  ${TextMLight}
`

const Users = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
`

const UserItem = styled.div`
  ${TextM}
`
