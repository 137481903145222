import { attach, combine } from 'effector'
import { createForm } from 'effector-forms'
import { createGate } from 'effector-react'

import {
  getArticleCommentsReqFx,
  postArticleCommentReqFx,
  ArticleItem,
  getArticlesReqFx,
  sendArticleReactionReqFx,
  getNewArticlesReqFx,
  sendArticleToEmailReqFx,
  readArticleReqFx,
  GetArticlesParams,
} from '@/dal'

import { createPagination } from '@/features/shared/createPagination'
import { createCommentsModel } from '@/features/shared/createComments/createCommentsModel'
import { createPollingEffect } from '@/features/factories/createPollingEffect'
import { rules } from '@/lib/form-validators'
import { createSingleEffect } from '@/lib/createSingleEffect'
import { createFilters } from '@/features/shared/filterFactory/createFilters'
import { ArticlesFilter } from './type'

import { d } from './domain'

export const ArticleGate = createGate('Article')

export const $isArticlesModalOpen = d.store<boolean>(false)
export const openArticlesModal = d.event<void>()
export const closeArticlesModal = d.event<void>()

export const $isVisibleShareModal = d.store<boolean>(false)
export const openShareModal = d.event<void>()
export const closeShareModal = d.event<void>()

export const $tenActualArticles = d.store<number[]>([])
export const saveActualTenArticles = d.event<number[]>()

export const sendArticleForm = createForm({
  domain: d,
  fields: {
    email: {
      init: '',
      rules: [rules.email(), rules.required()],
    },
  },
})

const { requestFx: getCommentsFx } = createSingleEffect(getArticleCommentsReqFx)

export const commentsModel = createCommentsModel({
  domain: d,
  getCommentsFx,
  postCommentsFx: postArticleCommentReqFx,
})

export const incrementArticleComment = d.event<ArticleItem['id']>()

export const $articleTotal = d.store<number>(0)

export const $itemsToLoad = d.createStore<number | null>(null)

export const $articlesList = d.store<ArticleItem[] | null>(null)
export const $openedArticleId = d.store<ArticleItem['id'] | null>(null)
export const clearOpenedId = d.event<void>()
export const openArticle = d.event<ArticleItem['id']>()
export const closeArticle = d.event<void>()

export const onLoadMoreArticles = d.event<void>()
export const reactionButtonClicked = d.event<boolean>()

export const updateReactionsInfo = d
  .event<{id: ArticleItem['id'], reactions: ArticleItem['reactions']}>()

export const updateArticleById = d.event<ArticleItem>()

export const readArticleButtonClicked = d.event<void>()

export const readArticleFx = attach({
  effect: readArticleReqFx,
})

export const $openedArticle = combine([
  $articlesList,
  $openedArticleId,
],
([list, id]) => list?.find((item) => item.id === id) ?? null,
)

export const { requestFx: loadArticlesFx } = createSingleEffect(getArticlesReqFx)

export const {
  $hasMore,
  $offset,
  $total,
  initEffect: getArticlesFx,
  paginationEffect: getMoreArticlesFx,
  loadNewItemsFx,
} = createPagination<GetArticlesParams, ArticleItem[]>({
  fetchEffect: loadArticlesFx,
  domain: d,
  limit: 10,
})

export const { requestFx: loadNewArticlesFx } = createSingleEffect(loadNewItemsFx)

export const sendArticleReactionFx = attach({
  effect: sendArticleReactionReqFx,
})

export const {
  effect: checkNewArticleFx,
  startPolling,
  stopPolling,
} = createPollingEffect({
  domain: d,
  effect: getNewArticlesReqFx,
  ms: 30000,
})

export const sendArticleToEmailFx = attach({
  effect: sendArticleToEmailReqFx,
})

export const {
  $filters,
  removeFilter,
  setFilter,
  setFilters,
  clearFilter,
} = createFilters<ArticlesFilter>({ filter: 'newestFirst', search: '' }, d)
