import React, { memo, useState } from 'react'
import styled from 'styled-components'

import { KvrElement } from '@/dal'
import {
  DefaultButton, Icon2, palette, PaletteColor, TextL,
} from '@/ui'

import { selectKvrId, setOpenedKvrId } from '../../model/private'

type Props = KvrElement & {
  isSelected: boolean
}

export const KvrItem = memo(({
  id, name, is_kvr_in_use, isSelected,
}: Props) => {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)

  const handleClick = () => {
    if (timer) {
      clearTimeout(timer)
    }
    const timeout = setTimeout(() => {
      selectKvrId(id)
      // может успеть сработать, но сбросится при изменении открытого КВР
    }, 100)
    setTimer(timeout)
  }

  const handleDoubleClick = () => {
    if (timer) {
      clearTimeout(timer)
    }
    setOpenedKvrId(id)
  }

  return (
    <Wrapper onClick={handleClick} onDoubleClick={handleDoubleClick}>
      {is_kvr_in_use ? (
        <Icon2 icon="check" color="accent100" />
      ) : (
        <Icon2 icon={isSelected ? 'activeFolder' : 'folder'} />
      )}
      <Name color={isSelected ? 'accent100' : is_kvr_in_use ? 'grey60' : 'grey90'}>
        {name}
      </Name>
    </Wrapper>
  )
})

const Wrapper = styled(DefaultButton)`
  display: flex;
  gap: 8px;
  width: fit-content;
  text-align: left;
`

const Name = styled.div<{ color: PaletteColor }>`
  ${TextL}
  color: ${({ color }) => palette[color]};
`
