import React from 'react'
import styled from 'styled-components'

import { SimpleLoader } from '@/ui/Loader'

import { TextL } from '../typography'

type Props = {
  description: string | null
  isShown: boolean
}

export const DocumentBlockingOverlay = ({ description, isShown }: Props) => {
  if (!isShown) return null

  return (
    <>
      <Overlay />
      <Wrapper>
        <SimpleLoader />
        <Description>
          {description}
        </Description>
      </Wrapper>
    </>
  )
}

const Overlay = styled.div`
  position: absolute;
  inset: -8px;
  z-index: 10;

  // вне дизайн-системы
  background-color: #ededed1a;
  backdrop-filter: blur(4px);
`

const Wrapper = styled.div`
  position: fixed;
  inset: 0;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  pointer-events: none;
`

const Description = styled.p`
  ${TextL}
  text-align: center;
`
