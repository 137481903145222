import styled from 'styled-components'

import { themeVar } from './theming'
import { SmallTextCSS } from './Typography'

export const Badge = styled.div`
  ${SmallTextCSS}
  background-color: ${themeVar('badgeBgColor')};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${themeVar('badgeTextColor')};
  border-radius: 100px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
`
