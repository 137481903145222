import { attachWrapper } from '@42px/effector-extra'
import { AxiosError, AxiosResponse } from 'axios'
import { createForm } from 'effector-forms'
import { createGate } from 'effector-react'

import { Method, requestFx } from '@/dal/request'
import { rules } from '@/lib/form-validators'

import { defaultErrorHandler } from '@/dal'
import { d } from './domain'
import { LoginFxPayload, LoginFxResponse } from './type'

export const $credentialsError = d.store<boolean>(false)
export const setCredentialError = d.event<void>()
export const resetCredentialError = d.event<void>()

export const resetRedirectPath = d.event<void>()

export const LoginGate = createGate()

export const loginForm = createForm({
  domain: d,
  fields: {
    email: {
      init: '',
      rules: [
        rules.required(),
        rules.email(),
      ],
    },
    password: {
      init: '',
      rules: [rules.required()],
    },
  },
  validateOn: ['submit'],
})

export const loginFx = attachWrapper({
  effect: requestFx,
  mapParams: (payload: LoginFxPayload) => ({
    method: Method.post,
    body: payload,
    url: '/authorization/login/',
  }),
  mapResult: ({ result }: { result: AxiosResponse<LoginFxResponse> }) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const clearAppDataFx = d.effect<void, void, Error>()
