import React from 'react'
import styled from 'styled-components'

import { NewTextButton, palette } from '@/ui'

type Props = {
  isDisabled?: boolean
  onClick: () => void
}

export const AddWorkButtonRow = ({ isDisabled, onClick }: Props) => {
  return (
    <Wrapper>
      <NewTextButton
        label="Добавить работу из справочника"
        prefixIcon="plusWithCircle"
        textStyle="ML"
        iconSize={12}
        isDisabled={isDisabled}
        gap={8}
        dataTestId="estimate-lot-table-add-work-btn"
        onClick={onClick}
      />
    </Wrapper>
  )
}

const Wrapper = styled.td`
  padding: 12px 8px 12px 20px;
  border-bottom: 1px solid ${palette.grey30};
`
