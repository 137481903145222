import { Domain, Store, combine } from 'effector'

import { FileDTO } from '@/dal'
import { root } from '@/root-domain'

export type Params = {
  domain?: Domain
  $files: Store<FileDTO[] | null>
}

export const createDeleteFilesModel = ({
  domain,
  $files,
}: Params) => {
  const d = domain || root.domain()

  const $filesToDelete = d.store<FileDTO['id'][]>([])
  const markToDelete = d.event<FileDTO['id']>()
  const resetModel = d.event<void>()

  const $visibleFiles = combine($files, $filesToDelete, (files, ids) => {
    return files?.filter((item) => !ids.includes(item.id)) ?? null
  })

  $filesToDelete
    .on(markToDelete, (ids, id) => [...ids, id])
    .reset(resetModel, $files.updates)

  return {
    $visibleFiles,
    $filesToDelete,
    markToDelete,
    resetModel,
  }
}
