import styled, { css } from 'styled-components'

import { palette } from '@/ui'

type Props = {
  isFirstGroupOpen: boolean
  isEditMode: boolean
}

export const GridRow = styled.div<Props>`
  display: grid;
  padding: 0;
  background-color: ${palette.white};

  ${({ isEditMode, isFirstGroupOpen }) => css`
    grid-template-columns:
      repeat(3, minmax(130px, 130fr)) ${isEditMode ? 'minmax(461px, 461fr)' : 'minmax(508px, 508fr)'}
      ${isFirstGroupOpen ? 'minmax(280px, 280fr)' : ''} minmax(150px, 150fr) minmax(100px, 100fr)
      minmax(200px, 200fr) ${isEditMode ? 'minmax(48px, 48fr)' : ''}
      repeat(2, minmax(200px, 200fr)) repeat(2, minmax(48px, 48fr));
  `}
`
