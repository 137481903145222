import React from 'react'

import { FeaturesForDocument, isReasonWithLink } from '@/dal'
import { getRouterPathByDocumentAlias } from '@/lib/documentResolvers'
import { getTextWithLinks } from '@/lib/textParsers'

enum CreateDocumentTooltipType {
  Tooltip = 1,
  Hint = 2,
}

const getCreateDocumentTooltipObj = ({
  can: canCreate,
  reason,
}: FeaturesForDocument['create']): {
  type: CreateDocumentTooltipType.Tooltip
  text: (React.ReactFragment | string)[]
  hasDocumentLink: boolean
} | {
  type: CreateDocumentTooltipType.Hint
  text: string
  hasDocumentLink: false
} | null => {
  if (canCreate) return null

  if (!isReasonWithLink(reason)) {
    return {
      type: CreateDocumentTooltipType.Hint,
      text: reason?.info ?? 'Невозможно создать',
      hasDocumentLink: false,
    }
  }

  const hasDocumentLink = 'doc_type' in reason

  const link = {
    text: reason.name,
    href: hasDocumentLink
      ? `${getRouterPathByDocumentAlias(reason.doc_type)}/${reason.id}`
      : reason.link,
  }
  const tooltipText = getTextWithLinks({
    text: reason.info,
    isDownload: !hasDocumentLink,
    links: [link],
  })

  return {
    type: CreateDocumentTooltipType.Tooltip,
    text: tooltipText,
    hasDocumentLink,
  }
}

export const useCreateDocumentTooltip = (feature: FeaturesForDocument['create']) => {
  const tooltipConfig = React.useMemo(() => {
    if (feature.can) return null
    return getCreateDocumentTooltipObj(feature)
  }, [feature])

  return {
    tooltipConfig,
    isHint: tooltipConfig?.type === CreateDocumentTooltipType.Hint,
    hasDocumentLink: Boolean(tooltipConfig?.hasDocumentLink),
  }
}
