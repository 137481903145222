import React from 'react'
import { useUnit } from 'effector-react'

import {
  FirstTableCell,
  TableCell,
} from '@/ui'
import { ContractDirection, ActWorkSMR } from '@/dal'
import { noop } from '@/lib/noop'

import {
  $hiddenCellGroup,
  onWorkInputError,
  onChangeSMRActWork,
  $isTree,
  onEnterSubmitItem,
  $itemToFocus,
  resetItemToFocus,
} from '../../model/private'
import {
  CellRow, FirstCellWrapper, SplitCellWrapper, TableRowWrapper,
} from '../parts'
import { $searchName } from '../../model/filters.private'

type Props = {
  item: ActWorkSMR
  isScrolled?: boolean
}

export const TableRow = React.memo(({
  item,
  isScrolled,
}: Props) => {
  const [isHiddenGroup, isTree, searchName, itemToFocus] = useUnit([
    $hiddenCellGroup,
    $isTree,
    $searchName,
    $itemToFocus,
  ])

  const {
    id,
    article,
    name,
    note,
    number_1c,
    completed,
    estimate,
    period_cost,
    project,
    remainder,
    total_cost,
    unit,
    depth,
  } = item

  const handleChange = (value: string) => {
    onChangeSMRActWork({ workId: id, value })
  }

  return (
    <TableRowWrapper actType={ContractDirection.SMR} hiddenFirstGroup={isHiddenGroup} as="td">
      <FirstCellWrapper hasShadow={isScrolled}>
        <FirstTableCell
          hierarchyCode={number_1c}
          depth={depth}
          hasChildren={false}
          isFolder={false}
          isExpanded={false}
          isTree={isTree}
          name={name}
          project={project}
          searchValue={searchName}
          hasRightDivider={isHiddenGroup}
          onToggleExpand={noop}
        />
      </FirstCellWrapper>

      {!isHiddenGroup && (
        <>
          <TableCell isGray isText text={note} />
          <TableCell isGray isText text={article} />
        </>
      )}

      <TableCell
        value={estimate.current}
        decimalScale={4}
      />

      <TableCell value={remainder.current} decimalScale={4} />
      <TableCell
        onError={onWorkInputError}
        value={completed.current}
        canEdit={completed.can_edit}
        diff={completed.diff}
        filledValue={completed.filled_value}
        approveValue={completed.corrected.value}
        approveText={completed.corrected.comment}
        decimalScale={4}
        hasToFocus={itemToFocus === item.id}
        allowNegative
        onChange={handleChange}
        onEnter={() => onEnterSubmitItem(id)}
        onFocus={resetItemToFocus}
      />
      <TableCell text={unit} isText align="center" />

      <SplitCellWrapper>
        <TableCell value={total_cost.total} align="center" />
        <CellRow>
          <TableCell value={total_cost.material} align="center" />
          <TableCell value={total_cost.work} align="center" />
        </CellRow>
      </SplitCellWrapper>

      <SplitCellWrapper>
        <TableCell value={period_cost.total} align="center" />
        <CellRow>
          <TableCell value={period_cost.material} align="center" />
          <TableCell value={period_cost.work} align="center" />
        </CellRow>
      </SplitCellWrapper>

    </TableRowWrapper>
  )
})
