import { LotRowType, WorkTableItem } from '@/dal'
import { IconName2, PaletteColor } from '@/ui'

export type DeleteMaterialInfo = {
  id: string
  name: string
  morphology?: WorkTableItem['morphology_elements']
}

export type EditCommentInfo = {
  id: string
  name: string
  morphology?: WorkTableItem['morphology_elements']
  comment?: string
}

export type DistributeAmountsInfo = {
  id: string
  name: string
  row_type: LotRowType
}

export enum AmountDistributionType {
  SplitForAll,
  OneForAll,
}

export type ActionsForItem = {
  label: string
  icon?: IconName2
  iconColor?: PaletteColor
  onClick: () => void
}

export enum ItemAction {
  AddMaterial,
  Edit,
  Reset,
  AddComment,
  EditComment,
}

export type ActionPayload = {
  item: WorkTableItem
  type: ItemAction
}

export type EditingRow = {
  id: WorkTableItem['id']
  count?: string
  price?: string
  total_cost?: string
}
