import React from 'react'
import styled, { css } from 'styled-components'

import { NewIconButton, SquareButton } from '../buttons'
import { palette } from '../palette'
import { FlexScrollbar } from '../components/FlexScrollbar'
import { IconName2 } from '../icons'

type Props = {
  children: React.ReactNode
  dataTestId?: string
  zIndex?: number
  isHidden?: boolean
  hasCloseButton?: boolean
  borderRadius?: number
  closeModal: () => void
  onGoBack?: () => void
  onMinimize?: () => void
}

type ModalButtonProps = {
  icon: IconName2
  dataTestId: string
  onClick: () => void
}

const ModalButton = ({ icon, dataTestId, onClick }: ModalButtonProps) => (
  <NewIconButton
    borderRadius="50%"
    backgroundColor="white"
    icon={icon}
    color="grey60"
    hoverColor="grey80"
    hoverBackgroundColor="grey20"
    size={20}
    dataTestId={dataTestId}
    padding="6px"
    onClick={onClick}
  />
)

export const ModalLayoutV2 = ({
  children,
  dataTestId,
  zIndex,
  isHidden,
  hasCloseButton = true,
  borderRadius = 20,
  closeModal,
  onGoBack,
  onMinimize,
}: Props) => {
  return (
    <ModalOverlay
      zIndex={zIndex}
      data-testid={dataTestId}
      isHidden={isHidden}
    >
      <ModalWrapper borderRadius={borderRadius} onMouseDown={(e) => e.stopPropagation()}>
        <FlexScrollbar>
          {children}
          <Buttons>
            {onMinimize && (
              <ModalButton
                icon="minimize"
                dataTestId="minimize-modal-btn"
                onClick={onMinimize}
              />
            )}
            {hasCloseButton && (
              <ModalButton
                icon="cross"
                dataTestId="close-modal-btn"
                onClick={closeModal}
              />
            )}
          </Buttons>
        </FlexScrollbar>

        {onGoBack && (
          <GoBack>
            <SquareButton
              icon="arrowLeftM"
              buttonType="grey"
              dataTestId="go-back-modal-btn"
              onClick={onGoBack}
            />
          </GoBack>
        )}
      </ModalWrapper>
    </ModalOverlay>
  )
}

const Buttons = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 3;
  display: flex;
`

const ModalOverlay = styled.div<Pick<Props, 'zIndex' | 'isHidden'>>`
  position: fixed;
  inset: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
  z-index: ${({ zIndex }) => zIndex || 10};

  ${({ isHidden }) => isHidden && css`
    display: none;
  `}
`

const ModalWrapper = styled.div<{ borderRadius: number, isVisible?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  max-height: 100%;
  border-radius: ${({ borderRadius }) => borderRadius}px;
  background-color: ${palette.white};
`

const GoBack = styled.div`
  position: absolute;
  top: 0;
  right: calc(100% + 12px)
`
