import { attach } from 'effector'
import { createForm } from 'effector-forms'

import { rules } from '@/lib/form-validators'
import { EstimateDocId, sendEstimateToApproveReqFx } from '@/dal'
import { $userData } from '@/features/edit-profile/model'

import { d } from './domain'

export const $targetDocumentId = d.store<EstimateDocId | null>(null)
export const closeModal = d.event<void>()

export const $contactInfo = $userData.map((data) => (data ? ({
  phone: data.phone.replace('+7', ''),
  names: data.fullName,
}) : null))

export const approveForm = createForm({
  fields: {
    comment: {
      init: '',
      rules: [rules.required()],
    },
  },
})

export const sendEstimateToApproveFx = attach({
  effect: sendEstimateToApproveReqFx,
})
