import React, { Key } from 'react'
import styled, { css } from 'styled-components'

import { useWindowWidth } from '@/lib/useWindowWidth'
import { themeVar } from './theming'
import { IconButton } from './buttons/iconButton'

type ArrowProps = {
  isShow: boolean
  isDisabled: boolean
}

const ARROW_DEFAULT = {
  isShow: false,
  isDisabled: false,
}

type ListItem<T> = {
  label: string
  tooltip?: string | null
  value: T
}

type Props<T> = {
  list: ListItem<T>[]
  selectedItem?: T
  onSelect: (value: T) => void
  dataTestId: string
}

export const HorisontalList = <T extends Key, >({
  list,
  selectedItem,
  onSelect,
  dataTestId,
}: Props<T>) => {
  const [arrowLeft, setArrowLeft] = React.useState<ArrowProps>(ARROW_DEFAULT)
  const [arrowRight, setArrowRight] = React.useState<ArrowProps>(ARROW_DEFAULT)
  const [leftElementIndex, setLeftElementIndex] = React.useState<number>(0)
  const ref = React.useRef<HTMLDivElement>(null)
  const width = useWindowWidth()

  React.useEffect(() => {
    const isShowArrow = Boolean(ref.current && ref.current?.offsetWidth < ref.current.scrollWidth)
    setLeftElementIndex(0)
    setArrowRight((state) => ({ ...state, isShow: isShowArrow }))
    setArrowLeft((state) => ({ ...state, isShow: isShowArrow }))
  }, [width])

  React.useEffect(() => {
    if (!ref.current) return

    if (leftElementIndex === 0) {
      setArrowRight((state) => ({ ...state, isDisabled: false }))
      setArrowLeft((state) => ({ ...state, isDisabled: true }))
      return
    }
    if (leftElementIndex === (ref.current.childElementCount - 1)) {
      setArrowRight((state) => ({ ...state, isDisabled: true }))
      setArrowLeft((state) => ({ ...state, isDisabled: false }))
      return
    }
    setArrowLeft((state) => ({ ...state, isDisabled: false }))
    setArrowRight((state) => ({ ...state, isDisabled: false }))
  }, [leftElementIndex])

  React.useEffect(() => {
    if (!ref.current) return
    const elem = ref.current.children.item(leftElementIndex)
    if (!elem) return
    const { left: elemLeft } = elem.getBoundingClientRect()
    const { left: refLeft } = ref.current.getBoundingClientRect()
    const scrollBy = elemLeft - refLeft
    ref.current.scrollBy({ left: scrollBy, behavior: 'smooth' })
  }, [leftElementIndex])

  const handleClickArrow = (direction: 'prev' | 'next') => {
    if (direction === 'prev') {
      setLeftElementIndex((state) => state - 1)
    }
    if (direction === 'next') {
      setLeftElementIndex((state) => state + 1)
    }
  }
  const handleSelect = (
    isActive: boolean,
    value: T,
    index: number,
  ) => {
    if (isActive) return

    setLeftElementIndex(index)
    onSelect(value)
  }

  return (
    <Wrapper>
      {arrowLeft.isShow && (
        <IconButton
          isDisable={arrowLeft.isDisabled}
          onClick={() => handleClickArrow('prev')}
          dataTestId={`${dataTestId}-left-arrow`}
          icon="slider-prev-arrow"
        />
      )}
      <Items ref={ref}>
        {list.map((item, index) => {
          const isActive = selectedItem === item.value

          return (
            <Item
              key={item.value}
              isActive={isActive}
              onClick={() => handleSelect(isActive, item.value, index)}
              data-testid={`${dataTestId}-${item.value}`}
            >
              {item.label}
            </Item>
          )
        })}
      </Items>
      {arrowRight.isShow && (
        <IconButton
          isDisable={arrowRight.isDisabled}
          onClick={() => handleClickArrow('next')}
          dataTestId={`${dataTestId}-right-arrow`}
          icon="slider-next-arrow"
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  height: 45px;
`

const Items = styled.div`
  width: 100%;
  height: 100%;
  max-width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 24px;
  overflow: hidden;
`

type ItemProps = {
  isActive: boolean
}

const Item = styled.div<ItemProps>`
  height: 45px;
  padding: 12px 0;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;

  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;

  color: ${themeVar('textGray')};

  ${({ isActive }) => isActive
    && css`
      border-bottom: 2px solid ${themeVar('linkColor')};
      color: ${themeVar('linkColor')};
      padding: 12px 0 10px 0;
      cursor: default;
    `}

  &:hover {
    color: ${themeVar('main')};
  }
`
