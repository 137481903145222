import React, { memo } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { palette, TextM, TextMLight } from '@/ui'
import { $documentInfo } from '@/features/estimate-lot/model'
import { $advanceTypes, $typesOfSecurity } from '@/features/estimate-lot/model/private'
import { getDayJsObject } from '@/lib/date'

import { Row } from '../parts'

const formatDate = (date: string | null) => (date ? getDayJsObject(date).format('DD/MM/YYYY') : '')

export const CommercialInfoData = memo(() => {
  const [info, advanceTypes, typesOfSecurity] = useUnit([
    $documentInfo,
    $advanceTypes,
    $typesOfSecurity,
  ])

  if (!info) return null

  return (
    <>
      <Row>
        <Col>
          <ColLabel>Вид аванса</ColLabel>
          <p>
            {advanceTypes.find(({ id }) => id === info.advance_type_id)?.label ?? ''}
          </p>
        </Col>

        <Col>
          <ColLabel>Размер аванса в %</ColLabel>
          <p>
            {info.advance_size}
            %
          </p>
        </Col>

        <Col>
          <ColLabel>Размер гарантийного удержания в %</ColLabel>
          <p>
            {info.guarantee_retention_size}
            %
          </p>
        </Col>
      </Row>

      <Row>
        <Col>
          <ColLabel>Вид обеспечения</ColLabel>
          <p>
            {typesOfSecurity.find(({ id }) => id === info.type_of_security_id)?.label ?? ''}
          </p>
        </Col>

        <Col>
          <ColLabel>Сроки строительства</ColLabel>
          <p>
            <GreyText>с</GreyText>
            {' '}
            {formatDate(info.construction_start)}
            {' '}
            <GreyText>по</GreyText>
            {' '}
            {formatDate(info.construction_end)}
          </p>
        </Col>
      </Row>
    </>
  )
})

const Col = styled.div`
  ${TextMLight}
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const ColLabel = styled.p`
  ${TextM}
`

const GreyText = styled.span`
  color: ${palette.grey70};
`
