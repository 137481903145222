import { DocumentActions } from '@/dal'
import { IconName2 } from '../../ui/v2/icons'

type ResolvedAction = {
  text: string
  icon: IconName2
}

export const getDocumentActionByAlias = (
  action: DocumentActions | undefined | null,
): ResolvedAction | null => {
  switch (action) {
    case DocumentActions.answer_on_additional_request: {
      return {
        text: 'Ответить на доп. запрос',
        icon: 'visaTask',
      }
    }
    case DocumentActions.sign_documents: {
      return {
        text: 'Подписать документы',
        icon: 'signInDiadoc',
      }
    }
    default: return null
  }
}
