import React from 'react'
import Scrollbars from 'react-custom-scrollbars-2'

export const useScrollListToBottom = (deps: unknown[]) => {
  const refList = React.useRef<Scrollbars>(null)

  React.useLayoutEffect(() => {
    if (!refList.current) return
    refList.current.scrollToBottom()
  }, deps)

  return refList
}
