import { createForm } from 'effector-forms'
import { attach } from 'effector'

import { rules } from '@/lib/form-validators'
import { sendRestorePasswordReqFx } from '@/dal'

export const restorePasswordForm = createForm({
  fields: {
    email: {
      init: '',
      rules: [rules.required(), rules.email()],
    },
  },
})

export const restorePasswordFx = attach({
  effect: sendRestorePasswordReqFx,
})
