type Options = {
  separator?: string
  decimalScale?: number
  dot?: string
}

export function numberWithSpaces(
  x = '0',
  { separator = ' ', decimalScale = 2, dot = '.' }: Options = {},
): string {
  const parts = x.replace(',', '.').split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator)


  if (!parts[1]) {
    parts[1] = ''
  }
  const partsDecimalLength = parts[1]?.length ?? 0

  if (partsDecimalLength < decimalScale) {
    const zeros = new Array(decimalScale - partsDecimalLength + 1).join('0')
    parts[1] = `${parts[1]}${zeros}`
  }

  if (partsDecimalLength > decimalScale) {
    parts[1] = parts[1].slice(0, decimalScale)
  }

  return decimalScale ? parts.join(dot) : parts[0]
}

export function formatNumberByFourDigits(x = '0000'): string {
  return x.replace(/\B(?=(\d{4})+(?!\d))/g, ' ')
}
