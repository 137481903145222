import { attachWrapper } from '@42px/effector-extra'
import { AxiosResponse } from 'axios'


import { authRequestFx, Method } from '../request'
import {
  QuestionAlias,
  QuestionItem,
} from './type'

export const getQuestionItemByAliasReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ alias }: {alias: QuestionAlias}) => ({
    url: `/faq/qa/by_alias/${alias}`,
    method: Method.get,
  }),
  mapResult: ({ result }: {result: AxiosResponse<QuestionItem>}) => result.data,
})
