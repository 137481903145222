import { defaultErrorHandler } from '../error-handler'
import {
  ActCompensationsError, ActHandledError, ApproveActError, CreateActConflictError,
  CreateActReqiredFieldError,
} from './errors'
import { ActErrorResponse, ActErrorCode } from './types'

export const actErrorHandler = (error: ActErrorResponse | undefined): ActHandledError => {
  switch (error?.code) {
    case ActErrorCode.ApproveError: {
      return new ApproveActError(error)
    }
    case ActErrorCode.CreateConflictError: {
      return new CreateActConflictError(error)
    }
    case ActErrorCode.CreateErrorRequiredFields: {
      return new CreateActReqiredFieldError(error)
    }
    case ActErrorCode.CompensationsError: {
      return new ActCompensationsError(error)
    }
    default: {
      return defaultErrorHandler(error)
    }
  }
}
