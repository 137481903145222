import React from 'react'
import styled from 'styled-components'
import { palette } from '../palette'

export const NewDivider = () => (
  <Line />
)

const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${palette.grey20};
`
