import { AppealItem } from '@/dal'

import { d } from './domain'
import { AppealTypeForSending } from './types'

export const onCreateAppealModalClosed = d.event()
export const setContactsStep = d.event<AppealTypeForSending>()
export const resetContactsStep = d.event()

export const onNewAppealCreated = d.event<AppealItem>()
export const onAppealResumed = d.event<AppealItem>()
