import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'
import { combine } from 'effector'
import Scrollbars from 'react-custom-scrollbars-2'

import {
  HeadXL, ModalLayoutV2, NewButton, SimpleLoader, TextL, TextLLight,
} from '@/ui'
import { noop } from '@/lib/noop'

import {
  $nestedWorks,
  $workToDelete,
  deleteLotFolder,
  deleteLotFolderFx,
  deleteLotWork,
  deleteLotWorkFx,
  getFolderNestedWorksFx,
  resetWorkToDelete,
} from '../../model/private'

const $isDeletionPending = combine(
  deleteLotWorkFx.pending,
  deleteLotFolderFx.pending,
  (...args) => args.some((val) => val),
)

export const WorkOrFolderDeletionModal = React.memo(() => {
  const [work, nestedWorks, areWorksLoading, isDeletionPending] = useUnit([
    $workToDelete,
    $nestedWorks,
    getFolderNestedWorksFx.pending,
    $isDeletionPending,
  ])

  if (!work) return null

  const isFolder = work.is_folder

  const handleDelete = () => {
    if (isFolder) {
      deleteLotFolder(work.id)
    } else {
      deleteLotWork(work.id)
    }
  }

  return (
    <ModalLayoutV2
      dataTestId=""
      closeModal={isDeletionPending ? noop : resetWorkToDelete}
    >
      <Wrapper>
        <Container>
          <Title>
            Удаление
            {' '}
            {isFolder ? 'папки' : 'работы'}
          </Title>

          <Body>
            Вы уверены, что хотите удалить
            {' '}
            {isFolder ? 'папку' : 'работу'}
            {' '}
            <BoldText>{work.name}</BoldText>
            ?
            {isFolder && ' Если внутри этой папки были добавлены работы, то они удалятся тоже.'}
          </Body>

          {isFolder && (
            <div>
              <NestedWorksTitle>
                Работы, которые находятся внутри папки:
              </NestedWorksTitle>
              {areWorksLoading ? (
                <LoaderWrapper>
                  <SimpleLoader />
                </LoaderWrapper>
              ) : (
                <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={480}>
                  <NestedWorkList>
                    {nestedWorks.map((name) => <li>{name}</li>)}
                  </NestedWorkList>
                </Scrollbars>
              )}
            </div>
          )}
        </Container>

        <Buttons>
          <NewButton
            label="Удалить"
            isPending={isDeletionPending}
            dataTestId=""
            isFill
            onClick={handleDelete}
          />
          <NewButton
            label="Отменить"
            buttonType="grey"
            isDisabled={isDeletionPending}
            dataTestId=""
            isFill
            onClick={resetWorkToDelete}
          />
        </Buttons>
      </Wrapper>
    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 500px;
  padding: 32px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Buttons = styled.div`
  display: flex;
  gap: 16px;
`

const Title = styled.div`
  ${HeadXL}
`

const Body = styled.div`
  ${TextLLight}
`

const BoldText = styled.span`
  ${TextL}
`

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const NestedWorksTitle = styled.p`
  ${TextLLight}
  margin-bottom: 4px;
`

const NestedWorkList = styled.ul`
  ${TextL}
  margin: 0;
  padding-left: 24px;
`
