import { sample } from 'effector'

import { fetchAuthDataFx } from '@/features/login/model'
import { createToast } from '@/features/toast-service/model'

import {
  $checkedRequest,
  $isSubscribe,
  getSubscribe,
  postMaintenanceFx,
  resetCheckedRequest,
  setCheckedRequest,
} from './private'

$isSubscribe.on(getSubscribe, () => true)

$checkedRequest
  .on(setCheckedRequest, () => true)
  .reset(resetCheckedRequest)

sample({
  clock: getSubscribe,
  target: postMaintenanceFx,
})

sample({
  clock: fetchAuthDataFx.done,
  target: setCheckedRequest,
})

createToast({
  effect: postMaintenanceFx,
})
