import React from 'react'
import styled, { css } from 'styled-components'
import { useUnit } from 'effector-react'

import { TextM, TextMLight, palette } from '@/ui'
import { numberWithSpaces } from '@/lib/number-represent'

import { $contract } from '../../../model/private'

const $data = $contract.map((contract) => (contract ? contract.analyst : null))

export const FinanceGraph = React.memo(() => {
  const data = useUnit($data)
  if (!data) return null

  return (
    <>
      <TableHeader>
        <HeaderCell>
          <TableHeaderLabel>
            Статья оборотов
          </TableHeaderLabel>
        </HeaderCell>

        <HeaderCell>
          <TableHeaderLabel>
            Объект строительства
          </TableHeaderLabel>
        </HeaderCell>

        <HeaderCell>
          <TableHeaderLabel>
            Сумма
          </TableHeaderLabel>
          <TableHeaderSubLabel>
            {numberWithSpaces(data.total_sum)}
          </TableHeaderSubLabel>
        </HeaderCell>

        <HeaderCell>
          <TableHeaderLabel>
            Сумма аванса
          </TableHeaderLabel>
          <TableHeaderSubLabel>
            {numberWithSpaces(data.total_avans)}
          </TableHeaderSubLabel>
        </HeaderCell>

        <HeaderCell>
          <TableHeaderLabel>
            Сумма гарантийных уд.
          </TableHeaderLabel>
          <TableHeaderSubLabel>
            {numberWithSpaces(data.total_guarante)}
          </TableHeaderSubLabel>
        </HeaderCell>

      </TableHeader>

      {data.table.map((row) => (
        <Row key={`${row.cost_item}-${row.sum}`}>
          <Cell>
            {row.cost_item}
          </Cell>
          <Cell>
            {row.const_obj}
          </Cell>
          <Cell>
            {row.sum}
          </Cell>
          <Cell>
            {row.avans}
          </Cell>
          <Cell>
            {row.guarante}
          </Cell>
        </Row>
      ))}

    </>
  )
})


const GridTemplate = css`
  display: grid;
  grid-template-columns: 400fr repeat(4, 308fr);
`

const Cell = styled.div`
  padding: 12px;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-left: 1px solid ${palette.grey40};

  ${TextMLight}

  &:first-child {
    text-align: left;
    border-left: none;
    padding-left: 20px;
  }

  &:last-child {
    padding-right: 20px;
  }
`

const HeaderCell = styled(Cell)`
  display: flex;
  flex-direction: column;
  gap: 2px;

  padding: 14px 12px;
  justify-content: center;
  min-height: 65px;
`

const TableHeader = styled.div`
  position: sticky;
  ${GridTemplate}
  top: 0;
  background-color: ${palette.grey20};
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
`

const TableHeaderLabel = styled.div`
  ${TextM}
`

const TableHeaderSubLabel = styled.div`
  ${TextM}
  color: ${palette.grey70};
`

const Row = styled.div`
  ${GridTemplate};
  background-color: ${palette.white};
  border-bottom: 1px solid ${palette.grey40};

  &:last-child { 
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
`

