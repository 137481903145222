import React from 'react'
import styled, { css } from 'styled-components'
import { usePopperTooltip } from 'react-popper-tooltip'
import { Placement } from '@popperjs/core'

import { ResetStyleButton } from '@/ui/ResetStyleButton'
import { Icon, IconName } from '@/ui/Icon'
import { themeVar } from '@/ui/theming'
import { BaseTheme } from '@/ui/theming/themes'
import { noop } from '@/lib/noop'
import { SimpleLoader } from '@/ui/Loader'
import { PopperToolTip } from '@/ui/PopperToolTip'

type Props = {
  borderColor?: keyof BaseTheme | 'transparent'
  iconColor?: keyof BaseTheme
  backgroundColor?: keyof BaseTheme | 'transparent'
  icon: IconName
  size?: number
  borderRadius?: string
  isDisable?: boolean
  onClick: () => void
  dataTestId: string
  tooltip?: string | JSX.Element,
  tooltipPosition?: Placement
  isLoading?: boolean
  loaderSize?: number
  iconSize?: number
  hoverIconColor?: keyof BaseTheme
}

export const IconButton = ({
  backgroundColor = 'transparent',
  borderRadius = '0%',
  icon,
  borderColor = 'transparent',
  iconColor = 'gray4',
  size,
  isDisable,
  onClick,
  dataTestId,
  tooltip,
  tooltipPosition = 'top',
  isLoading,
  loaderSize = 24,
  iconSize = 30,
  hoverIconColor = 'gray8',
}: Props) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    placement: tooltipPosition,
  })

  return (
    <Button
      ref={setTriggerRef}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      disabled={isDisable}
      size={size}
      iconColor={iconColor}
      borderColor={borderColor}
      onClick={(isDisable || isLoading) ? noop : onClick}
      data-testid={dataTestId}
      hoverIconColor={hoverIconColor}
      type="button"
    >
      {!isLoading && (
        <IconWrapper iconSize={iconSize}>
          <Icon icon={icon} />
        </IconWrapper>
      )}
      {isLoading && <SimpleLoader sizePx={loaderSize} />}
      {tooltip && (
        <PopperToolTip
          getArrowProps={getArrowProps}
          getTooltipProps={getTooltipProps}
          setTooltipRef={setTooltipRef}
          tooltip={tooltip}
          visible={visible}
        />
      )}
    </Button>
  )
}

type ButtonProps = {
  borderColor?: keyof BaseTheme | 'transparent'
  iconColor: keyof BaseTheme
  backgroundColor?: keyof BaseTheme | 'transparent'
  hoverIconColor: keyof BaseTheme
  size?: number
  borderRadius?: string
}

const Button = styled(ResetStyleButton)`
  ${({
    backgroundColor,
    borderColor,
    borderRadius,
    iconColor,
    size,
  }:ButtonProps) => (
    css`
      max-height: ${`${size || 45}px`};
      max-width: ${`${size || 45}px`};
      min-height: ${`${size || 45}px`};
      min-width: ${`${size || 45}px`};
      color: ${themeVar(iconColor)};
      border: 1px solid ${borderColor && borderColor !== 'transparent' ? themeVar(borderColor) : borderColor};
      border-radius: ${borderRadius};
      background-color: ${backgroundColor && backgroundColor !== 'transparent' ? themeVar(backgroundColor) : backgroundColor};
    `
  )}
  z-index: 1;
  display: block;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity .15s linear, color .15s linear;

  &:disabled {
    opacity: 0.6;
  }

  &:hover:not(:disabled) {
    color: ${({ hoverIconColor }) => themeVar(hoverIconColor)};
  }
`

const IconWrapper = styled.div<{iconSize: number}>`
  min-width: ${({ iconSize }) => `${iconSize}px`};
  min-height: ${({ iconSize }) => `${iconSize}px`};
  max-width: ${({ iconSize }) => `${iconSize}px`};
  max-height: ${({ iconSize }) => `${iconSize}px`};;
  color: inherit;

  & > svg {
    min-width: ${({ iconSize }) => `${iconSize}px`};
    min-height: ${({ iconSize }) => `${iconSize}px`};
    max-width: ${({ iconSize }) => `${iconSize}px`};
    max-height: ${({ iconSize }) => `${iconSize}px`};
  }
`

