import React, { FormEvent } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'
import { useForm } from 'effector-forms'

import { LegalType } from '@/dal'
import { NewButton } from '@/ui'
import { NO_DATA } from '@/features/app/model/const'
import { $selectedCompany } from '@/features/my-company/model'
import { noop } from '@/lib/noop'

import {
  closeEditing, credentialsForm, putCompanyDetailsFx,
} from '../../model/private'
import { EditDetailsField } from '../parts'

export const EditDetailsForm = React.memo(() => {
  const selectedCompany = useUnit($selectedCompany)
  const isPending = useUnit(putCompanyDetailsFx.pending)
  const {
    fields, isValid, isTouched, submit,
  } = useForm(credentialsForm)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (!isPending) submit()
  }

  const isKPPRequired = Boolean(selectedCompany && selectedCompany.legal_type !== LegalType.IP)

  return (
    <Form onSubmit={handleSubmit}>
      <Wrapper>
        <EditDetailsField
          label="ИНН"
          value={selectedCompany?.inn ?? NO_DATA}
          onChange={noop}
          dataTestId="company-details-inn-input"
          disabled
        />
        <EditDetailsField
          label="КПП"
          placeholder="000000000"
          value={fields.kpp.value}
          onChange={fields.kpp.onChange}
          onClear={fields.kpp.reset}
          error={fields.kpp.firstError?.errorText}
          dataTestId="company-details-kpp-input"
          disabled={isPending}
          format="#########"
          required={isKPPRequired}
          inputType="number"
        />
        <EditDetailsField
          label="БИК Банка"
          placeholder="000000000"
          value={fields.bank_bik.value}
          onChange={fields.bank_bik.onChange}
          onClear={fields.bank_bik.reset}
          error={fields.bank_bik.firstError?.errorText}
          format="#########"
          dataTestId="company-details-bik-input"
          disabled={isPending}
          required
          inputType="number"
        />
        <EditDetailsField
          label="Расчетный счет"
          placeholder="0000 0000 0000 0000 0000"
          value={fields.acc_number.value}
          onChange={fields.acc_number.onChange}
          onClear={fields.acc_number.reset}
          error={fields.acc_number.firstError?.errorText}
          format="#### #### #### #### ####"
          dataTestId="company-details-acc-number-input"
          disabled={isPending}
          inputType="number"
          required
        />
        <EditDetailsField
          label="Юридический адрес"
          value={fields.legal_address.value}
          onChange={fields.legal_address.onChange}
          error={fields.legal_address.firstError?.errorText}
          placeholder="Заполните адрес по примеру справа"
          dataTestId="company-details-legal-address-textarea"
          disabled={isPending}
          required
          isAddress
          inputType="textArea"
        />
        <EditDetailsField
          label="ОКПО"
          placeholder="00000000"
          value={fields.okpo.value}
          onChange={fields.okpo.onChange}
          onClear={fields.okpo.reset}
          error={fields.okpo.firstError?.errorText}
          format="########"
          dataTestId="company-details-okpo-input"
          disabled={isPending}
          required
          inputType="number"
        />
        <EditDetailsField
          label="ФИО"
          value={fields.sign_name.value}
          onChange={fields.sign_name.onChange}
          onClear={() => fields.sign_name.onChange('')}
          error={fields.sign_name.firstError?.errorText}
          placeholder="Введите данные"
          dataTestId="company-details-full-name-input"
          disabled={isPending}
        />
        <EditDetailsField
          label="Должность"
          value={fields.sign_position.value || 'Генеральный директор'}
          onChange={noop}
          dataTestId="company-details-position-input"
          disabled
        />
        <EditDetailsField
          label="Телефон"
          value={fields.phone_number.value}
          onChange={fields.phone_number.onChange}
          onClear={fields.phone_number.reset}
          error={fields.phone_number.firstError?.errorText}
          placeholder="+7"
          format="+7 ### ### ## ##"
          allowEmptyFormatting
          dataTestId="company-details-phone-input"
          disabled={isPending}
          inputType="number"
        />
      </Wrapper>

      <Buttons>
        <NewButton
          label="Сохранить"
          type="submit"
          isPending={isPending}
          isDisabled={!isValid || isPending || !isTouched}
          dataTestId="company-details-submit"
        />
        <NewButton
          label="Отменить"
          buttonType="grey"
          onClick={closeEditing}
          isDisabled={isPending}
          dataTestId="company-details-cancel"
        />
      </Buttons>
    </Form>
  )
})

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Buttons = styled.div`
  display: flex;
  gap: 16px;
`
