import { useUnit } from 'effector-react'
import React from 'react'
import styled from 'styled-components'

import { TextM, palette } from '@/ui'

import { $currStep } from '../../model/private'


export const Steps = React.memo(() => {
  const step = useUnit($currStep)

  const text = step === 2
    ? 'шаг 2. Выберите документы для подписания'
    : 'шаг 1. Ознакомьтесь с документами'

  return (
    <Wrapper>
      <Text>{text}</Text>
      <Lines>
        <Line isActive={step === 1} />
        <Line isActive={step === 2} />
      </Lines>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  width: 100%; 
`

const Text = styled.div`
  ${TextM}
  margin-bottom: 6px;
  text-transform: uppercase;
`

const Lines = styled.div`
  width: 100%;
  display: flex;
  gap: 4px;
`

const Line = styled.div<{ isActive?: boolean }>`
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background: ${({ isActive }) => (isActive ? palette.accent80 : palette.accent10)};
`
