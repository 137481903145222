import React from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import styled, { css } from 'styled-components'

import { RouterPaths } from '@/features/app/model'

import { WINDOW_WIDTH } from '@/ui/theming/const'
import { palette } from '../palette/palette'
import { ContentContainer } from './ContentWrapper'
import { AUTO_SCROLL_PARAMS } from '../const'
import { LinkTab } from '../components/Tabs/LinkTab'

type Props = {
  navHeaderSlot: React.ReactFragment
  links: { label: string, path: RouterPaths }[]
  children: React.ReactFragment
}

export const PageWithNavigation = ({
  navHeaderSlot,
  links,
  children,
}: Props) => {
  return (
    <ContentContainer isAutoHeight>
      <Template>

        <NavBar>
          <HeaderSlotWrapper>
            {navHeaderSlot}
          </HeaderSlotWrapper>

          {links.map((link) => (
            <LinkTab key={link.path} {...link} />
          ))}
        </NavBar>

        <ContentWrapper>
          <Scrollbars autoHeight autoHeightMax={AUTO_SCROLL_PARAMS}>
            <ScrollContent>
              {children}
            </ScrollContent>
          </Scrollbars>
        </ContentWrapper>

      </Template>
    </ContentContainer>
  )
}

const Template = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 24px;
`

const CardStyles = css`
  background-color: ${palette.white};
  border-radius: 20px;
`

const HeaderSlotWrapper = styled.div`
  padding: 0px 16px;
  margin-bottom: 24px;
`

const NavBar = styled.div`
  flex: 0 1 326px;
  height: max-content;
  padding: 32px 16px;
  ${CardStyles}

  @media(min-width: ${WINDOW_WIDTH.desktop}px) {
    flex: 0 1 446px;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  flex-grow: 1;
  ${CardStyles}
`

const ScrollContent = styled.div`
  padding: 32px;
`
