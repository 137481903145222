import { combine } from 'effector'

import { FIRST_GROUP_ID, SECOND_GROUP_ID, THIRD_GROUP_ID } from './const'
import { d } from './domain'
import { OnDeleteItemParams, OnUpdateItemsParams } from './types'
import { $isResetModeEnabled } from './reset-multiple-items.private'
import { $isMultipleEditModeEnabled } from './edit-multiple-items.private'

export const $hiddenCellGroups = d.store<number[]>([
  FIRST_GROUP_ID,
  SECOND_GROUP_ID,
  THIRD_GROUP_ID,
])

export const onItemUpdate = d.event<OnUpdateItemsParams>()
export const onItemCreate = d.event<OnUpdateItemsParams>()
export const onItemDelete = d.event<OnDeleteItemParams>()
export const onSplitItemVolume = d.event<OnUpdateItemsParams>()

export const getTableData = d.event<void>()

export const $isSpecialMode = combine(
  ([$isResetModeEnabled, $isMultipleEditModeEnabled]),
  ([isResetMode, isMultipleEditMode]) => isResetMode || isMultipleEditMode,
)
