import React from 'react'
import styled, { css } from 'styled-components'
import { ConnectedFields } from 'effector-forms'

import {
  EstimateNumberCell,
  EstimateStringCell,
  EstimateTableRow,
  FileDTO,
} from '@/dal'
import { TABLE_BORDER_STYLE } from '../../../model/const'
import { TableCell } from './TableCell'
import { ItemToFocus, NonFormField, OnEnterSubmitItem } from '../../../model'

type Props = {
  rowId: EstimateTableRow['id']
  item: {
    count_in_estimate: EstimateNumberCell | null
    approved: EstimateNumberCell | null
    on_approving: EstimateNumberCell | null
    remain: EstimateNumberCell | null
    price_material: EstimateNumberCell | null
    price_work: EstimateNumberCell | null
    price_all: EstimateNumberCell | null
    cost_material: EstimateNumberCell | null
    cost_work: EstimateNumberCell | null
    cost_all: EstimateNumberCell | null
    justification_of_changes: EstimateStringCell | null
    attachments: FileDTO[] | null
    additional_requisites?: {
      provider: EstimateStringCell | null
      specifications: EstimateStringCell | null
      stamp: EstimateStringCell | null
    } | null
  }
  isEditMode: boolean
  isFolder: boolean
  hiddenSecondGroup: boolean
  hiddenThirdGroup: boolean
  fields: ConnectedFields<any> | { [key: string]: NonFormField<any> }
  cellColor: string | null
  itemToFocus?: ItemToFocus | null
  onOpenAttachments: () => void
  onFocus?: () => void
  onEnter?: (item: Omit<OnEnterSubmitItem, 'isNewLines'>) => void
}

export const VolumeRow = ({
  fields,
  rowId,
  hiddenSecondGroup,
  hiddenThirdGroup,
  isEditMode,
  isFolder,
  item,
  cellColor,
  itemToFocus,
  onOpenAttachments,
  onFocus,
  onEnter,
}: Props) => {
  return (
    <Wrapper
      hiddenSecondGroup={hiddenSecondGroup}
      hiddenThirdGroup={hiddenThirdGroup}
    >

      <TableCell
        itemId={rowId}
        isEmpty={isFolder}
        isNumber
        cellInfo={item.count_in_estimate}
        isEditMode={isEditMode}
        field={fields.count_in_estimate}
        placeholder="Кол-во по смете"
        isActive
        decimalScale={4}
        cellColor={cellColor}
        hasRightDivider={hiddenSecondGroup}
        itemToFocus={itemToFocus}
        onEnter={onEnter}
        onFocus={onFocus}
      />

      {!hiddenSecondGroup && (
        <>
          <TableCell
            itemId={rowId}
            isEmpty={isFolder}
            isNumber
            cellInfo={item.approved}
            isGray
            cellColor={cellColor}
            decimalScale={4}
          />
          <TableCell
            itemId={rowId}
            isEmpty={isFolder}
            isNumber
            cellInfo={item.on_approving}
            cellColor={cellColor}
            decimalScale={4}
            isGray
          />
          <TableCell
            itemId={rowId}
            isEmpty={isFolder}
            isNumber
            cellInfo={item.remain}
            isGray
            cellColor={cellColor}
            decimalScale={4}
          />
        </>
      )}

      <TableCell
        itemId={rowId}
        isEmpty={isFolder}
        isNumber
        cellInfo={item.price_material}
        placeholder="Цена материала"
        isEditMode={isEditMode}
        field={fields.material}
        cellColor={cellColor}
        isActive
        itemToFocus={itemToFocus}
        onEnter={onEnter}
        onFocus={onFocus}
      />
      <TableCell
        itemId={rowId}
        isEmpty={isFolder}
        isNumber
        cellInfo={item.price_work}
        placeholder="Цена работы"
        isEditMode={isEditMode}
        field={fields.work}
        isActive
        cellColor={cellColor}
        itemToFocus={itemToFocus}
        onEnter={onEnter}
        onFocus={onFocus}
      />
      <TableCell
        itemId={rowId}
        isEmpty={isFolder}
        isNumber
        cellInfo={item.price_all}
        isActive
        cellColor={cellColor}
        hasRightDivider={hiddenThirdGroup}
      />

      {!hiddenThirdGroup && (
        <>
          <TableCell
            itemId={rowId}
            isNumber
            cellInfo={item.cost_material}
            isGray
            cellColor={cellColor}
          />
          <TableCell
            itemId={rowId}
            isNumber
            cellInfo={item.cost_work}
            isGray
            cellColor={cellColor}
          />
        </>
      )}

      <TableCell
        itemId={rowId}
        isNumber
        cellInfo={item.cost_all}
        isActive
        cellColor={cellColor}
      />

      <TableCell
        itemId={rowId}
        isActive
        cellInfo={item.justification_of_changes}
        hasFileAttach
        isEmpty={isFolder}
        fileCount={item.attachments?.length ?? undefined}
        onOpenAttachments={onOpenAttachments}
        isEditMode={isEditMode}
        field={fields.justification_of_changes_current}
        placeholder="Основание изменений"
        cellColor={cellColor}
      />
      <TableCell
        itemId={rowId}
        isActive
        isEmpty={isFolder}
        cellInfo={item.additional_requisites?.provider}
        isEditMode={isEditMode}
        field={fields.provider}
        placeholder="Поставщик"
        cellColor={cellColor}
      />
      <TableCell
        itemId={rowId}
        isActive
        isEmpty={isFolder}
        cellInfo={item.additional_requisites?.specifications}
        isEditMode={isEditMode}
        field={fields.specifications}
        placeholder="Характеристики"
        cellColor={cellColor}
      />
      <TableCell
        itemId={rowId}
        isActive
        isEmpty={isFolder}
        cellInfo={item.additional_requisites?.stamp}
        isEditMode={isEditMode}
        field={fields.stamp}
        placeholder="Марка"
        cellColor={cellColor}
      />
    </Wrapper>
  )
}


type WrapperProps = {
  hiddenSecondGroup: boolean,
  hiddenThirdGroup: boolean,
}

const Wrapper = styled.div<WrapperProps>`
  display: grid;

  ${({ hiddenSecondGroup, hiddenThirdGroup }) => css`
    grid-template-columns: 
      repeat(${hiddenSecondGroup ? 4 : 7},  minmax(145px, 145fr))
      repeat(${hiddenThirdGroup ? 5 : 7},  minmax(150px, 150fr));
  `}

  &:last-child:not(:first-child) {
    border-top: ${TABLE_BORDER_STYLE};
  }
`
