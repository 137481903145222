import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { ContractDirection } from '@/dal'
import {
  HeadL, palette, StatusTracking, TextM, TextMLight,
} from '@/ui'
import { numberWithSpaces } from '@/lib/number-represent'
import { formatDateString } from '@/lib/date'

import { $contract } from '../../../model/private'

export const SpecialContractInfo = React.memo(() => {
  const info = useUnit($contract)

  const title = useMemo(() => {
    const docName = info?.direction === ContractDirection.BANK_WARRANTY
      ? 'банковской гарантии'
      : 'договора поручительства'
    return `Информация по документу ${docName}`
  }, [info])

  if (!info) return null

  const { direction, period } = info

  return (
    <Wrapper>
      <Title>
        {title}
      </Title>

      <Container>
        {direction === ContractDirection.CONTRACT_GUARANTEE && (
          <>
            <Name>Кредитор</Name>
            <Value>{info.company_fsk}</Value>

            <Name>Поручитель</Name>
            <Value>{info.company?.name}</Value>
          </>
        )}

        {direction === ContractDirection.BANK_WARRANTY && (
          <>
            <Name>Банк</Name>
            <Value>{info.bank}</Value>
          </>
        )}

        <Name>Сумма обеспечения</Name>
        <Value>
          {numberWithSpaces(info.sum_of_security ?? '0')}
          {' '}
          руб.
        </Value>

        <Name>Срок обеспечения</Name>
        {period && (
          <Value>
            {formatDateString(period.date_start)}
            {' — '}
            {formatDateString(period.date_end)}
          </Value>
        )}
      </Container>

      <StatusTracking items={info.document_tracking} />
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px;
  border-radius: 20px;
  background-color: ${palette.white};
`

const Title = styled.header`
  ${HeadL}
  margin-bottom: 4px;
`

const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(0,137px) 1fr;
  grid-auto-rows: auto;
  gap: 16px 12px;
`

const Name = styled.p`
  ${TextM}
`

const Value = styled.p`
  ${TextMLight}
`
