import { LotRowType, WorkTableItem } from '@/dal'

import { ActionsForItem, ItemAction } from './types'

type Params = WorkTableItem & {
  comment?: string
  handleOptionClick: (val: ItemAction) => void
}

export const getActionsForItem = ({
  row_type: rowType,
  comment,
  handleOptionClick,
}: Params) => {
  const items = [] as ActionsForItem[]

  if (rowType === LotRowType.Work) {
    items.push(
      {
        label: 'Добавить материал',
        icon: 'plusWithCircle',
        onClick: () => handleOptionClick(ItemAction.AddMaterial),
      },
    )
  }
  if (rowType === LotRowType.Additional) {
    items.push(
      {
        label: comment ? 'Редактировать комментарий' : 'Оставить комментарий',
        icon: 'message',
        iconColor: comment ? 'accent100' : undefined,
        onClick: () => handleOptionClick(comment ? ItemAction.EditComment : ItemAction.AddComment),
      },
    )
  }
  items.push(
    {
      label: 'Редактировать строку',
      icon: 'editStroke',
      onClick: () => handleOptionClick(ItemAction.Edit),
    },
    {
      label: 'Очистить строку',
      icon: 'eraser',
      onClick: () => handleOptionClick(ItemAction.Reset),
    },
  )
  return items
}
