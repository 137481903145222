import { attachWrapper } from '@42px/effector-extra'
import { AxiosError, AxiosResponse } from 'axios'

import { authRequestFx, Method } from '../request'
import { defaultErrorHandler } from '../error-handler'

export const postMaintenanceReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: () => ({
    method: Method.post,
    url: '/system/notify_about_finish_maintenance/',
  }),
  mapResult: ({ result }: { result: AxiosResponse }) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})
