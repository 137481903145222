import styled from 'styled-components'
import { themeVar } from './theming'

export const ListTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 16px;
  font-size: 20px;
  font-weight: 700;
  color: ${themeVar('actBlockFontColor')};
`
