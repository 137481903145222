import { IconName2 } from '@/ui'

export type Service = {
  alias: ServiceAlias
  name: string
  icon: IconName2
}

export type ServiceReference = {
  url?: string
  links?: {
    url: string
    name?: string
  }[]
}

export type ServiceReferences = { [key in ServiceAlias]: ServiceReference }

export enum ServiceAlias {
  TechSupervision = 'TechSupervision',
  BuildDocs = 'BuildDocs',
  GStation = 'GStation',
  ISUP = 'ISUP',
  Accreditation = 'Accreditation',
  B2B = 'B2B',
  GrandTenders = 'GrandTenders',
}
