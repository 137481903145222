import { AxiosError, AxiosResponse } from 'axios'
import { attachWrapper } from '@42px/effector-extra'
import { attach, combine } from 'effector'

import {
  CompanyItem, User, putUserInfoReqFx,
} from '@/dal'
import { $accessToken } from '@/dal/auth'
import { authRequestFx, Method } from '@/dal/request'
import { d } from './domain'

export const logout = d.event()
export const logoutByUser = d.event()
export const $userAuthorised = $accessToken.map((token) => token !== null)
export const $authorizedUserData = d.store<User | null>(null)
export const $isReadOnlyAccess = $authorizedUserData.map((user) => (
  Boolean(user?.is_read_only_access)
))

export const $redirectPathAfterLogin = d.store<string | null>(null)
export const setRedirectPathAfterLogin = d.event<string>()

export const companyDetailsChanged = d.event<CompanyItem>()

export const tokenApply = d.event<string>()

export const fetchAuthDataFx = attachWrapper({
  effect: authRequestFx,
  mapParams: () => ({
    method: Method.get,
    url: '/user-info/',
  }),
  mapResult: ({ result }: { result: AxiosResponse<User> }) => result.data,
  mapError: ({ error }: { error: AxiosError }) => error.response?.data,
})
// @TODO странно, когда в фиче login, есть putUserInfoFx. Надо будет поправить
export const putUserInfoFx = attach({
  effect: putUserInfoReqFx,
})

export const $isAuthorizedUserDataPending = combine([
  putUserInfoFx.pending,
  fetchAuthDataFx.pending,
], ([a, b]) => a || b)
