import { useUnit } from 'effector-react'
import React from 'react'
import styled from 'styled-components'
import Scrollbars from 'react-custom-scrollbars-2'
import { useForm } from 'effector-forms'
import { Link } from 'react-router-dom'

import {
  HeadL,
  InputField,
  ModalLayoutV2,
  NewButton,
  NotificationItem,
  NotificationType,
  NumberInputField,
  TextAreaField,
} from '@/ui'

import { noop } from '@/lib/noop'
import { useOnChangeLocation } from '@/lib/useOnChangeLocation'
import { RouterPaths } from '@/features/app/model'
import {
  $contactInfo,
  $targetDocumentId,
  approveForm,
  closeModal,
  sendEstimateToApproveFx,
} from '../../model/private'

export const SendToApproveModal = React.memo(() => {
  const docId = useUnit($targetDocumentId)
  const {
    submit, isValid, fields, isDirty,
  } = useForm(approveForm)
  const contactInfo = useUnit($contactInfo)
  const isPending = useUnit(sendEstimateToApproveFx.pending)

  useOnChangeLocation(closeModal)

  if (!docId) return null

  return (
    <ModalLayoutV2
      closeModal={closeModal}
      dataTestId={`send-to-approve-modal-${docId}`}
    >
      <Wrapper>
        <Header>
          Отправка документа на согласование
        </Header>
        <Body>
          <Scrollbars>
            <BodyContent>
              <NotificationItem
                body={(
                  <>
                    Проверьте поля ФИО и телефон, информация должна совпадать с
                    реальными данными пользователя ЛКП.
                    Изменить данные можно в разделе
                    {' '}
                    <Link to={RouterPaths.UserInfo}>
                      «Профиль»
                    </Link>
                  </>
                )}
                type={NotificationType.Info}
              />
              <InputField
                label="Контактное лицо"
                value={contactInfo?.names || ''}
                disabled
                dataTestId="contact-name-field"
                onChange={noop}
              />
              <NumberInputField
                label="Телефон"
                value={contactInfo?.phone || ''}
                onChange={noop}
                dataTestId="contact-phone-field"
                format="+7 (###) ###-##-##"
                disabled
              />
              <TextAreaField
                onChange={fields.comment.onChange}
                value={fields.comment.value}
                autoGrow
                maxHeight={240}
                currentSymbolCount={fields.comment.value.length}
                maxSymbolCount={1000}
                dataTestId="comment-for-approve"
                label="Комментарий"
                required
                error={fields.comment.firstError?.errorText}
                placeholder="Укажите краткую причину корректировки суммы договора"
                height={100}
                disabled={isPending}
              />
            </BodyContent>
          </Scrollbars>
        </Body>
        <Footer>
          <NewButton
            label="Отправить"
            dataTestId="send-approve-button"
            isDisabled={!isValid || !isDirty}
            isPending={isPending}
            onClick={submit}
          />
          <NewButton
            label="Отменить"
            dataTestId="close-approve-modal-button"
            buttonType="grey"
            onClick={closeModal}
          />
        </Footer>
      </Wrapper>
    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  width: 500px;
  height: 580px;
  max-height: 750px;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  padding: 32px 32px 16px 32px;
  ${HeadL}
`

const Body = styled.div`
  flex-grow: 1;
`

const BodyContent = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 16px 32px;
`

const Footer = styled.div`
  display: flex;
  gap: 12px;
  padding: 16px 32px 20px 32px;
`
