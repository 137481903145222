import React, { memo } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import {
  HeadL,
  NewButton,
  NewIconButton,
  NewInput,
  Switch,
} from '@/ui'

import {
  $areDescriptionsExpanded,
  $searchValue,
  setAreDescriptionsExpanded,
  changeSearchValue,
  closeCatalog,
  $openedKvr,
  setOpenedKvrId,
  addKvrToLot,
  acceptSearch,
  addKerToLot,
  $selectedKvr,
  $selectedKer,
} from '../../model/private'
import { CatalogBreadcrumbs } from './CatalogBreadcrumbs'

export const CatalogHeader = memo(() => {
  const [openedKvr, searchValue, areDescriptionsExpanded, selectedKvr, selectedKer] = useUnit([
    $openedKvr,
    $searchValue,
    $areDescriptionsExpanded,
    $selectedKvr,
    $selectedKer,
  ])

  const isRoot = !openedKvr
  const parentId = openedKvr?.parent_id ?? null
  const isSingleWorkButton = Boolean(openedKvr?.ker_elements?.length && !selectedKvr)

  const canAddKvr = selectedKvr && !selectedKvr.is_kvr_in_use
  const canAddKer = selectedKer && !selectedKer.is_ker_in_use && openedKvr

  const onAddKvr = () => {
    if (!canAddKvr) return
    addKvrToLot(selectedKvr.id)
  }

  const onAddKer = () => {
    if (!canAddKer) return
    addKerToLot({ kerId: selectedKer.id, kvrId: openedKvr.id })
  }

  const onClearSearch = () => {
    changeSearchValue('')
    acceptSearch()
  }

  return (
    <Wrapper>
      <Row>
        <Title>Справочник работ</Title>
        <NewIconButton
          icon="cross"
          size={20}
          color="grey90"
          dataTestId=""
          onClick={() => closeCatalog()}
        />
      </Row>

      <Content>
        <NewInput
          value={searchValue}
          placeholder="Поиск"
          postfixIcon="search"
          dataTestId=""
          onChange={changeSearchValue}
          onClear={onClearSearch}
          onEnterPress={acceptSearch}
          onPostfixIconClick={acceptSearch}
        />

        <Row>
          <Buttons>
            <NewButton
              label="Назад"
              buttonType="line-grey"
              size="S"
              prefixIcon="arrowLeftM"
              isDisabled={isRoot}
              dataTestId=""
              onClick={() => setOpenedKvrId(parentId)}
            />
            {isSingleWorkButton ? (
              <NewButton
                label="Добавить работу"
                buttonType="line-primary"
                size="S"
                isDisabled={!canAddKer}
                dataTestId=""
                onClick={onAddKer}
              />
            ) : (
              <NewButton
                label="Добавить все работы из раздела"
                buttonType="line-primary"
                size="S"
                isDisabled={!canAddKvr}
                dataTestId=""
                onClick={onAddKvr}
              />
            )}
          </Buttons>
          <Switch
            label="Раскрыть все описания"
            isChecked={areDescriptionsExpanded}
            dataTestId=""
            onChange={() => setAreDescriptionsExpanded(!areDescriptionsExpanded)}
          />
        </Row>

        <CatalogBreadcrumbs />
      </Content>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 24px 16px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`

const Buttons = styled.div`
  display: flex;
  gap: 8px;
`

const Title = styled.p`
  ${HeadL}
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
