import React from 'react'
import styled from 'styled-components'

import { LARGE_WIDTH_PX, themeVar } from './theming'

export const Footer = () => (
  <Container>
    <Wrapper>
      <Col>© 2015 - 2022. ФСК</Col>
      <Col>
        <MailLink href="mailto:info@anlider.info" data-testid="a-maito-footer">
          info@anlider.info
        </MailLink>
      </Col>
    </Wrapper>
  </Container>
)

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #e8e8e8;
  height: 32px;
  box-sizing: border-box;
  display: flex;
  border-top: 1px solid ${themeVar('line')};
  background-color: ${themeVar('lightBackground')};
`

const Wrapper = styled.div`
  max-width: ${LARGE_WIDTH_PX}px;
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`

const Col = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${themeVar('textGray')};
`
const MailLink = styled.a`
  font-size: 12px;
  line-height: 16px;
  color: ${themeVar('textGray')};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`
