import React from 'react'
import styled, { css } from 'styled-components'

import {
  DocumentAlias,
  TaskItem as TaskItemType,
} from '@/dal'
import {
  HeadM,
  TextMLight,
  TextS,
  palette,
} from '@/ui'
import { numberWithSpaces } from '@/lib/number-represent'
import { getRouterPathByDocumentAlias } from '@/lib/documentResolvers'

import { ItemWrapper, TaskTypeLabel } from '../parts'

type Props = TaskItemType

export const TaskItem = React.memo(({
  created_at,
  document_id,
  document_type,
  project_name,
  total_sum,
  contract_name,
  is_visa,
  document_name,
  document_status,
  subcontract_id,
}: Props) => {
  const link = getRouterPathByDocumentAlias(document_type)
  const isContract = document_type === DocumentAlias.CONTRACT
  const isSubContract = isContract && Boolean(subcontract_id)

  return (
    <ItemWrapper
      link={`${link}/${document_id}`}
    >
      <Wrapper>
        <TaskTypeLabel date={created_at} docStatus={document_status} isVisa={is_visa} />

        <Body>
          <Body>
            <BodyTitle>
              {document_name}
            </BodyTitle>

            <BodyRow>
              <Col>
                <BodySubText>
                  {project_name}
                </BodySubText>
                {(!isContract || isSubContract) && (
                  <BodySubText>
                    {contract_name}
                  </BodySubText>
                )}
              </Col>

              {total_sum && (
                <Col>
                  <SumLabel>
                    Сумма документа, руб
                  </SumLabel>
                  <DocumentPrice>
                    {numberWithSpaces(total_sum, { dot: ',' })}
                  </DocumentPrice>
                </Col>
              )}
            </BodyRow>

          </Body>
        </Body>
      </Wrapper>
    </ItemWrapper>
  )
})

const OneTextLineCSS = css`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`

const BodyTitle = styled.h3`
  ${HeadM}
  transition: color 0.15s linear;
  max-height: 20px;
  ${OneTextLineCSS}
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 32px;
  transition: background-color 0.15s linear;

  &:hover {
    background-color: ${palette.yellow10};

    ${BodyTitle} {
      color: ${palette.accent100};
    }
  }

  &:active {
    background-color: ${palette.yellow20};

    ${BodyTitle} {
      color: ${palette.accent100};
    }
  }

`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

const BodyRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

const BodySubText = styled.p`
  ${TextMLight}
  color: ${palette.grey70};
  max-height: 16px;
  ${OneTextLineCSS}

  ${({ onClick }) => onClick && css`
    cursor: pointer;
  `}
`

const SumLabel = styled(BodySubText)`
  ${TextS}
  color: ${palette.grey60};
  text-transform: uppercase;
`

const DocumentPrice = styled.p`
  ${TextMLight};
  text-align: right;
`
