import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { FileItem } from '@/ui'
import { $selectedFeedbackFiles, deleteFeedbackFile } from '../../model/private'

export const FileList = () => {
  const selectedFeedbackFiles = useUnit($selectedFeedbackFiles)

  if (selectedFeedbackFiles.length === 0) {
    return null
  }

  return (
    <Container>
      {selectedFeedbackFiles.map((item, fileIndex) => (
        <FileItem
          key={item.file.name}
          extension={item.extension}
          filename={item.file.name}
          onDel={deleteFeedbackFile}
          imagePreview={item.imgUrl}
          fileIndex={fileIndex}
        />
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`
