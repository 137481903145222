import { palette } from '@/ui'
import React from 'react'
import styled, { css } from 'styled-components'

type RowType = 'default' | 'header' | 'summary'

type Props = {
  children: React.ReactNode
  type?: RowType
}

export const TableRow = ({ children, type = 'default' }: Props) => (
  <Wrapper type={type}>
    {children}
  </Wrapper>
)

const Wrapper = styled.div<{ type: RowType }>`
  display: flex;
  gap: 12px;

  ${({ type }) => type === 'header' && css`
    margin-bottom: 4px;
  `}

  ${({ type }) => type === 'summary' && css`
    padding: 2px 0;
    background-color: ${palette.grey10};
  `}
`
