import React from 'react'
import styled, { css } from 'styled-components'

import { Icon2, IconName2 } from '../icons'
import { TextL, TextMLight } from '../typography'
import { palette } from '../palette'
import { DefaultButton } from './DefaultButton'

type ButtonStyle = 'primary' | 'secondary' | 'multiline'

type Props = {
  text: string
  additionalText?: string
  icon?: IconName2
  isDisabled?: boolean
  buttonStyle?: ButtonStyle
  hasBorder?: boolean
  dataTestId: string
  onClick?: () => void
}

export const SelectItemButton = ({
  icon,
  text,
  additionalText,
  isDisabled,
  hasBorder = true,
  buttonStyle = 'primary',
  dataTestId,
  onClick,
}: Props) => (
  <Wrapper
    disabled={isDisabled}
    hasBorder={hasBorder}
    buttonStyle={buttonStyle}
    data-testid={dataTestId}
    onClick={onClick}
  >
    <ContentWrapper buttonStyle={buttonStyle}>
      <TextWrapper isDisabled={isDisabled}>
        {text}
      </TextWrapper>

      {additionalText && (
        <AdditionalTextWrapper buttonStyle={buttonStyle} isDisabled={isDisabled}>
          {additionalText}
        </AdditionalTextWrapper>
      )}
    </ContentWrapper>
    {icon && (
      <IconWrapper buttonStyle={buttonStyle}>
        <Icon2 icon={icon} />
      </IconWrapper>
    )}
  </Wrapper>
)

const TextWrapper = styled.p<Pick<Props, 'isDisabled'>>`
  ${TextL}
  color: ${({ isDisabled }) => (isDisabled ? palette.grey60 : palette.grey90)};
  transition: color 0.15s linear;
`

const AdditionalTextWrapper = styled.p<Pick<Props, 'buttonStyle' | 'isDisabled'>>`
  ${TextL}
  color: ${palette.grey60};

  ${({ buttonStyle, isDisabled }) => buttonStyle === 'multiline' && css`
    ${TextMLight}
    color: ${isDisabled ? palette.grey60 : palette.grey70};
  `}
`

const IconWrapper = styled.div<Pick<Props, 'buttonStyle'>>`
  width: 16px;
  height: 16px;
  transition: color 0.15s linear;

  ${({ buttonStyle }) => buttonStyle === 'multiline' && css`
    height: 18px;
    padding-top: 2px;
  `};
`

const Wrapper = styled(DefaultButton)<Pick<Props, 'hasBorder' | 'buttonStyle'>>`
  display: flex;
  justify-content: space-between;
  align-items: ${({ buttonStyle }) => (buttonStyle === 'multiline' ? 'flex-start' : 'center')};
  gap: 12px;

  width: 100%;
  padding: 14px 19px;
  border: 1px solid transparent;
  border-radius: 20px;
  background-color: ${palette.white};
  text-align: left;
  cursor: pointer;
  transition: border-color 0.15s linear;

  ${({ hasBorder }) => hasBorder && css`
    border-color: ${palette.grey40};

    @media(hover: hover) {
      &:hover {
        border-color: ${palette.grey50};
      }
    }

    &:disabled {
      border-color: ${palette.grey30};
    }
  `}
    
  ${IconWrapper} {
    color: ${({ buttonStyle }) => (buttonStyle === 'secondary' ? palette.accent100 : palette.grey60)};
  }

  @media(hover: hover) {
    &:hover:not(:disabled) {
      ${TextWrapper} {
        color: ${palette.accent100}
      }
      
      ${IconWrapper} {
        color: ${({ buttonStyle }) => (buttonStyle === 'secondary' ? palette.accent90 : palette.accent100)};
      }
    }
  }

  &:disabled {
    ${IconWrapper} {
      color: ${({ buttonStyle }) => (buttonStyle === 'secondary' ? palette.accent40 : palette.grey50)};
    }
  }
`

const ContentWrapper = styled.div<Pick<Props, 'buttonStyle'>>`
  ${({ buttonStyle }) => buttonStyle !== 'multiline' && css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
  `}
`
