import React from 'react'
import styled from 'styled-components'

import { Icon } from './Icon'
import { themeVar } from './theming'

type Props = {
    onClick: () => void
    testId: string
    disabled?: boolean
}

export const RefreshButton = ({
  onClick,
  testId,
  disabled,
}: Props) => (
  <IconWrapper
    data-testid={testId}
    onClick={onClick}
    disabled={disabled}
  >
    <Icon icon="refresh-icon" />
  </IconWrapper>
)

const IconWrapper = styled.button`
    border: unset;
    background-color: transparent;
    padding: 5px;
    color: ${themeVar('refreshIconColor')};
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    color: ${themeVar('textGray')};
    border-radius: 50%;
    background-color: ${themeVar('gray5')};
    
    &:disabled {
        cursor: not-allowed;
    }

    svg {
        width: 14px;
        height: 14px;
    }
`
