import React from 'react'
import styled from 'styled-components'

import { noop } from '@/lib/noop'
import { useTooltip } from '@/lib/hooks'

import { Icon2, IconName2 } from '../../icons'
import { styles } from './styles'
import { ButtonSize, ButtonType } from './types'
import { AnimationWrapper } from './wrappers'
import { DefaultButton } from '../DefaultButton'
import { ToolTipV2 } from '../../components/tooltip'
import { ButtonLoader } from './ButtonLoader'

type Props = {
  ariaLabel?: string
  size?: ButtonSize
  isPending?: boolean
  buttonType?: ButtonType
  icon: IconName2
  isDisabled?: boolean
  type?: 'button' | 'submit'
  tooltip?: string
  dataTestId: string
  onClick: () => void
}

export const SquareButton = ({
  isPending,
  ariaLabel,
  size = 'M',
  buttonType = 'primary',
  type = 'button',
  icon,
  isDisabled,
  tooltip,
  onClick,
  dataTestId,
}: Props) => {
  const {
    floatingStyles,
    getFloatingProps,
    getReferenceProps,
    isOpen,
    refs,
  } = useTooltip({})

  const handleClick = isPending ? noop : onClick

  return (
    <AnimationWrapper
      {...getReferenceProps()}
      ref={refs.setReference}
    >
      <Button
        disabled={Boolean(isDisabled || isPending)}
        onClick={() => handleClick?.()}
        size={size}
        buttonType={buttonType}
        type={type}
        data-testid={dataTestId}
        aria-label={ariaLabel}
        data-pending={isPending || undefined}
      >
        <Icon2 size={16} icon={icon} />
      </Button>
      {isPending && <ButtonLoader buttonType={buttonType} />}

      {tooltip && (
        <ToolTipV2
          floatingStyles={floatingStyles}
          getFloatingProps={getFloatingProps}
          isOpen={isOpen}
          refs={refs}
          size="M"
        >
          {tooltip}
        </ToolTipV2>
      )}
    </AnimationWrapper>
  )
}

type ButtonProps = {
  size: ButtonSize
  buttonType: ButtonType
}

const Button = styled(DefaultButton)<ButtonProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: max-content;
  height: max-content;
  border: 1px solid transparent;
  padding: ${({ size }) => (size === 'M' ? '11px' : '7px')};
  border-radius: 8px;
  transition: color 0.15s linear, background-color 0.15s linear, border 0.15s linear;

  ${({ buttonType: type }) => styles[type]}
`
