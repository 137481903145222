import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import {
  ExpandableText,
  NewDivider,
  TextM,
  TextMLight,
  palette,
} from '@/ui'
import { $isPaymentAct, $paymentInfo } from '../../../model/private'

const $comment = $paymentInfo.map((info) => info?.comment || null)

export const CommentContainer = React.memo(() => {
  const comment = useUnit($comment)
  const isPaymentAct = useUnit($isPaymentAct)

  if (isPaymentAct && !comment) return null

  return (
    <>
      <NewDivider />
      <Comment>
        <CommentLabel>
          {isPaymentAct ? 'Назначение платежа' : 'Комментарий'}
        </CommentLabel>
        {comment ? (
          <ExpandableText
            lineCount={3}
            text={comment}
          />
        ) : (
          <NoCommentWrapper>
            Комментарий не оставлен
          </NoCommentWrapper>
        )}
      </Comment>
    </>
  )
})

const Comment = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const CommentLabel = styled.div`
  ${TextM}
`

const NoCommentWrapper = styled.p`
  ${TextMLight}
  color: ${palette.grey70};
`
