import React from 'react'

import { FloatingArrow, FloatingContext } from '@floating-ui/react'

import { PaletteColor } from '../../palette'

type Props = {
  context: FloatingContext
  fill?: PaletteColor
  size?: 'L' | 'S'
}

export const TooltipArrow = React.forwardRef<SVGSVGElement, Props>(
  ({ context, size = 'L', fill }, ref) => (
    <FloatingArrow
      ref={ref}
      context={context}
      fill={fill || 'grey100'}
      width={size === 'L' ? 12 : 8}
      height={size === 'L' ? 6 : 4}
    />
  ),
)
