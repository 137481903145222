import { CustomResponseError } from '../error-handler'
import { CheckInnErrorPayload, NeedChangeTableErrorPayload, PutAvansItemErrorData } from './types'

export class CheckInnNotFoundError extends CustomResponseError {
  data: CheckInnErrorPayload

  constructor(data: CheckInnErrorPayload, message = '') {
    super(message)
    this.name = 'CheckInnNotFoundError'
    this.data = data
  }
}

export class NeedChangeTableError extends CustomResponseError {
  data: NeedChangeTableErrorPayload

  constructor(data: NeedChangeTableErrorPayload, message = '') {
    super(message)
    this.name = 'NeedChangeTableError'
    this.data = data
  }
}

export class PutAvansItemError extends CustomResponseError {
  data: PutAvansItemErrorData & {
    // code не приходит с бэка
    code: null
  }

  constructor(data: PutAvansItemErrorData, message = '') {
    super(message)
    this.name = 'PutAvansItemError'
    this.data = {
      code: null,
      ...data,
    }
  }
}
