import { defaultErrorHandler, DefaultHandledError } from '../error-handler'
import { HasInvalidTableItemsError, HasNewDocConflictError } from './errors'
import { EstimateCorrectionErrorCode, EstimateCorrectionErrorData } from './types'

export type KorDocHandledError =
  | HasInvalidTableItemsError
  | HasNewDocConflictError
  | DefaultHandledError

export const estimateCorrectionErrorHandler = (
  error: EstimateCorrectionErrorData | undefined,
): KorDocHandledError => {
  switch (error?.code) {
    case EstimateCorrectionErrorCode.VALIDATION_ERROR: {
      return new HasInvalidTableItemsError(error)
    }
    case EstimateCorrectionErrorCode.KOR_DOC_NEW_CONFLICT: {
      return new HasNewDocConflictError(error)
    }
    default: {
      return defaultErrorHandler(error)
    }
  }
}
