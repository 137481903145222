import { attach } from 'effector'
import { createGate } from 'effector-react'

import { ChartData, getGraphDataReqFx } from '@/dal'

import { d } from './domain'
import { CHART_COUNT } from './const'

export const $data = d.store<null | ChartData>(null)
export const $visibleDataSets = $data.map((data) => data && data.dataset.slice(-CHART_COUNT))

export const $hasData = $data.map((data) => Boolean(data))

export const ChartGate = createGate('chart-gate')

export const getChartDataFx = attach({
  effect: getGraphDataReqFx,
})
