import { createGate } from 'effector-react'

import {
  ActDTO,
  ContractDirection,
  DocumentStatuses,
  KS2Data,
  KS3Data,
} from '@/dal'

import { d } from './domain'
import { ActDocInfo } from './types'

export const ActGate = createGate<ActDTO['id']>()

export const $allDocumentInfo = d.store<ActDTO | null>(null)
export const $documentId = $allDocumentInfo.map((info) => info?.id ?? null)
export const $documentInfo = $allDocumentInfo.map((info) => (info ? ({
  ...info,
  summary_table: undefined,
} as ActDocInfo) : null))
export const $documentStatus = $documentInfo.map((info) => info?.status ?? null)
export const $isDraft = $documentStatus.map((status) => status === DocumentStatuses.Draft)
export const $documentFeatures = $documentInfo.map((info) => info?.features ?? null)
export const $actType = $documentInfo.map((info) => info?.act_type ?? null)
export const $isPIRAct = $documentInfo.map((info) => info?.act_type === ContractDirection.PDRD)
export const $editableFields = $documentFeatures.map(
  (features) => features?.editable_fields ?? null,
)
export const $canEditPaymentOrders = $documentFeatures.map((features) => (
  Boolean(features?.can_edit_payment_orders)
))
export const $canDeleteLastPaymentOrder = $documentFeatures.map((features) => (
  Boolean(features?.can_delete_last_payment_order)
))
export const $actExclusiveBlock = $documentFeatures.map((features) => (
  features?.document_exclusive_block ?? null
))
export const $paymentOrders = $documentInfo.map((info) => info?.payment_orders ?? [])

export const updateFeatures = d.event<ActDTO['id']>()

export const updateKSData = d.event<{
  ks2: KS2Data | null
  ks3: KS3Data
  is_fill_by_remaining?: boolean
}>()

export const $isDocumentPending = d.store<boolean>(false)

export const $currentKS2 = $allDocumentInfo.map((info) => {
  if (!info?.ks2) return null
  const { ks2 } = info
  const isDraft = info.status === DocumentStatuses.Draft
  return {
    material: isDraft ? ks2.material.declared : ks2.material.approved,
    total: isDraft ? ks2.total.declared : ks2.total.approved,
    work: isDraft ? ks2.work.declared : ks2.work.approved,
  }
})
