import { attach } from 'effector'

import { SummaryFieldAlias, sendSummaryInfoReqFx } from '@/dal'

import { $allDocumentInfo } from '../../shared-model'
import { d } from './domain'

type AvansChangedParams = {
  field: SummaryFieldAlias,
  value: string
}

export const onFieldSubmit = d.event<AvansChangedParams>()

export const $summary = $allDocumentInfo.map((info) => info?.summary_table ?? null)

export const sendSummaryInfoFx = attach({
  effect: sendSummaryInfoReqFx,
})
