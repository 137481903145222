import React from 'react'
import styled from 'styled-components'
import { useGate, useUnit } from 'effector-react'

import {
  HeadM, Icon2, SimpleLoader, palette,
} from '@/ui'
import { DocumentPaginationItem } from '@/features/document-flow/filters/view'
import { $totalDocCount } from '@/features/document-flow/header/model'

import {
  $areDocumentsLoading,
  $areFiltersLoading,
  abortFx,
} from '../../../filters/model'
import {
  $documentList,
  DocumentFlowListGate,
} from '../../model'

import { DocumentListItem } from '../containers'

export const DocumentFlowList = () => {
  const documentList = useUnit($documentList)
  const totalDocCount = useUnit($totalDocCount)
  const areDocumentsLoading = useUnit($areDocumentsLoading)
  const areFiltersLoading = useUnit($areFiltersLoading)

  const isLoading = areDocumentsLoading || areFiltersLoading

  React.useEffect(() => () => {
    abortFx()
  }, [])

  useGate(DocumentFlowListGate)

  return (
    <Wrapper>
      {!documentList?.length && !isLoading
        ? (
          <EmptyList>
            <Icon2 icon="emptyTasks" size={170} />
            <EmptySubtitle>
              {totalDocCount
                ? 'Документы не найдены, измените параметры фильтрации'
                : 'У вас нет документов'}
            </EmptySubtitle>
          </EmptyList>
        ) : !isLoading && (
          <ListContainer>
            {documentList?.map((item) => (
              <DocumentListItem
                key={`${item.type}-${item.id}`}
                {...item}
              />
            ))}
            <DocumentPaginationItem />
          </ListContainer>
        )}
      {isLoading
        && (
        <LoaderWrapper>
          <SimpleLoader />
        </LoaderWrapper>
        )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 380px;
`

const LoaderWrapper = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ListContainer = styled.div`
  width: 100%;
  height: 100%;
`

const EmptyList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-grow: 1;
`

const EmptySubtitle = styled.p`
  ${HeadM}
  color: ${palette.grey60};
`
