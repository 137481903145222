import React, { memo } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'
import { useForm } from 'effector-forms'

import { NewDropDownField, Switch } from '@/ui'
import { $canEdit } from '@/features/estimate-lot/model'
import { lotInfoForm } from '@/features/estimate-lot/model/forms'
import {
  $contracts, $directions, $projects, getContractsFx, getDirectionsFx, getProjectsFx,
} from '@/features/estimate-lot/model/private'
import { $isActionPending } from '@/features/estimate-lot/model/header.private'

export const LotMainInfo = memo(() => {
  const { fields } = useForm(lotInfoForm)

  const [projects, directions, contracts, canEdit, isPending] = useUnit([
    $projects,
    $directions,
    $contracts,
    $canEdit,
    $isActionPending,
  ])
  const areContractsLoading = useUnit(getContractsFx.pending)
  const areProjectsLoading = useUnit(getProjectsFx.pending)
  const areDirectionsLoading = useUnit(getDirectionsFx.pending)

  const isDisabled = !canEdit || isPending
  const isExistingContract = fields.is_existing_contract.value

  const handleChangeIsExistingContract = () => {
    fields.is_existing_contract.onChange(!fields.is_existing_contract.value)
  }

  return (
    <>
      <Row>
        <NewDropDownField
          label="Проект строительства"
          placeholder="Выбрать проект строительства"
          options={projects}
          selectedId={fields.project.value}
          size="L"
          isDisabled={isDisabled}
          isLoading={areProjectsLoading}
          dataTestId="lot-project-select"
          required
          hasClear
          onOptionClick={fields.project.onChange}
        />
        <NewDropDownField
          label="Направление деятельности"
          placeholder="Выберите направление деятельности"
          options={directions}
          selectedId={fields.direction.value}
          size="L"
          isDisabled={isDisabled}
          isLoading={areDirectionsLoading}
          dataTestId="lot-direction-select"
          required
          hasClear
          onOptionClick={fields.direction.onChange}
        />
      </Row>

      <Switch
        label="К существующему договору"
        isChecked={isExistingContract}
        disabled={isDisabled}
        dataTestId="lot-existing-contract-switch"
        onChange={handleChangeIsExistingContract}
      />

      {isExistingContract && (
        <NewDropDownField
          label="Договор"
          placeholder="Выбрать договор"
          options={contracts}
          selectedId={fields.contract.value}
          size="L"
          isDisabled={isDisabled}
          isLoading={areContractsLoading}
          dataTestId="lot-contract-select"
          required
          hasClear
          onOptionClick={fields.contract.onChange}
        />
      )}
    </>
  )
})

const Row = styled.div`
  display: flex;
  gap: 8px;
`
