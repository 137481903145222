import React from 'react'

import { ActWorkStatus } from '@/dal'
import { Label, PaletteColor } from '@/ui'

import { PIR_STATUSES } from '../../model/const'

type Props = {
  status: ActWorkStatus
}

type StatusColors = {
  text: PaletteColor
  bgColor: PaletteColor
  borderColor?: PaletteColor
}

export const WorkStatusLabel = ({
  status,
}: Props) => {
  const { bgColor, text, borderColor }: StatusColors = React.useMemo(() => {
    switch (status) {
      case ActWorkStatus.NotCompleted: {
        return {
          text: 'grey90',
          bgColor: 'grey10',
          borderColor: 'grey30',
        }
      }
      case ActWorkStatus.Declared: {
        return {
          text: 'violet100',
          bgColor: 'violet10',
        }
      }
      case ActWorkStatus.InterimAcceptance: {
        return {
          text: 'accent100',
          bgColor: 'accent10',
        }
      }
      case ActWorkStatus.Completed:
      default: {
        return {
          text: 'green',
          bgColor: 'lightGreen10',
        }
      }
    }
  }, [status])

  return (
    <Label
      text={PIR_STATUSES[status]}
      bgColor={bgColor}
      colorText={text}
      borderColor={borderColor}
    />
  )
}
