import { useUnit } from 'effector-react'
import React from 'react'
import styled, { css } from 'styled-components'

import { DocumentStatuses } from '@/dal'
import { numberWithSpaces } from '@/lib/number-represent'
import { getHighlightedText } from '@/lib/getHighlightedText'
import {
  Icon,
  InlineDot,
  MediumTextCSS,
  PopperToolTip,
  themeVar,
  useTheme,
} from '@/ui'
import { usePopperTooltip } from 'react-popper-tooltip'
import {
  $isShowNoteCell,
  $planSearchText,
  getPopupData,
  resetPopupData,
} from '../../model/private'
import { GridWrapper } from '../parts'
import { PrepaidPopup } from './PrepaidPopup'
import { WorkListItemInput } from './WorkListItemInput'
import { DECIMAL_COUNT } from '../../model/const'
import { $openedFundPlanStatus } from '../../../plan-info/model'

type Props = {
  id: number
  name: string
  code: string
  unit: string
  costInEstimate: string
  costActInPast: string
  saldoCount: string
  planPast: string
  deltaForecast: number
  buildingName?: string
  isLast?: boolean
  isSelected: boolean
  priceAll: string
  priceMaterial: string
  priceWork: string
  note: string
  totalPlanSum: string
  actualPlanCountDeclarated: string
  forecast: number
  articleOutlayName: string | null
  reasons_rejection: string | null
  actualPlanCountDeclatedApproved: string
  showActualPlanCountDeclaratedApproved: boolean
  onInputFocus: () => void
}

export const WorkListItem = (props: Props) => {
  const {
    id,
    name,
    code,
    unit,
    costInEstimate,
    buildingName,
    planPast,
    deltaForecast,
    costActInPast,
    saldoCount,
    isLast,
    isSelected,
    note,
    priceAll,
    priceMaterial,
    priceWork,
    totalPlanSum,
    actualPlanCountDeclarated,
    forecast,
    articleOutlayName,
    reasons_rejection,
    actualPlanCountDeclatedApproved,
    showActualPlanCountDeclaratedApproved,
    onInputFocus,
  } = props
  const planStatus = useUnit($openedFundPlanStatus)
  const isShowNote = useUnit($isShowNoteCell)
  const planSearchText = useUnit($planSearchText)
  const theme = useTheme()

  const nameHighlighted = planSearchText === ''
    ? name
    : getHighlightedText(
      name,
      planSearchText,
      theme.selectionBgColor,
      theme.selectionTextColor,
    )

  const showUnit = !!unit

  const approvedInfo = usePopperTooltip({
    placement: 'top',
  })

  const params = usePopperTooltip({
    placement: 'top',
    closeOnOutsideClick: true,
    trigger: 'click',
    onVisibleChange(state) {
      if (!state) resetPopupData()
    },
  })

  const getInputCell = () => {
    switch (true) {
      case showActualPlanCountDeclaratedApproved: {
        return (
          <Cell>
            <ApprovedContainer>
              <ApprovedLabel>
                {numberWithSpaces(actualPlanCountDeclatedApproved)}
              </ApprovedLabel>
              <CellTitle>
                {numberWithSpaces(actualPlanCountDeclarated)}
              </CellTitle>
              {reasons_rejection && (
              <>
                <InfoIconWrapper ref={approvedInfo.setTriggerRef}>
                  <Icon icon="act-inform" />
                </InfoIconWrapper>
                <PopperToolTip
                  getArrowProps={approvedInfo.getArrowProps}
                  getTooltipProps={approvedInfo.getTooltipProps}
                  setTooltipRef={approvedInfo.setTooltipRef}
                  visible={approvedInfo.visible}
                  tooltip={reasons_rejection}
                />
              </>
              )}
            </ApprovedContainer>
          </Cell>
        )
      }
      case planStatus === DocumentStatuses.Draft: {
        return (
          <Cell>
            <WorkListItemInput
              actualPlanCountDeclarated={actualPlanCountDeclarated}
              forecast={forecast}
              id={id}
              isSelected={isSelected}
              onInputFocus={onInputFocus}
            />
          </Cell>
        )
      }
      default: {
        return (
          <Cell>
            <CellTitle>{numberWithSpaces(actualPlanCountDeclarated)}</CellTitle>
          </Cell>
        )
      }
    }
  }

  return (
    // Id and names
    <Wrapper isShowNote={isShowNote} isLast={isLast}>
      <Cell noCenter>
        <CellContent>
          <CellTitle row>
            {buildingName ? (
              <>
                {code}
                <InlineDot />
                {buildingName}
              </>
            ) : (
              code
            )}
          </CellTitle>
          <NameWrapper>{nameHighlighted}</NameWrapper>
        </CellContent>
      </Cell>

      {isShowNote && (
        <>
          <NoteCell>
            <CellTitle>{note}</CellTitle>
          </NoteCell>
          <NoteCell>
            <CellTitle>{articleOutlayName}</CellTitle>
          </NoteCell>
        </>
      )}

      <Cell>
        {/* Ед. Изм */}
        <CellTitle>
          { showUnit ? unit : <>-</>}
        </CellTitle>
      </Cell>
      {/* Указано в смете */}
      <Cell>
        <CellTitle>
          {numberWithSpaces(parseFloat(costInEstimate).toFixed(DECIMAL_COUNT))}
        </CellTitle>
      </Cell>

      {/* Цена всего */}
      <SplittedCellWrapper>
        <SplittedTop>
          <CellTitle>{numberWithSpaces(priceAll)}</CellTitle>
        </SplittedTop>

        <SplittedRow>
          <SplittedRowLeft>
            <CellTitle>{numberWithSpaces(priceMaterial)}</CellTitle>
          </SplittedRowLeft>

          <SplittedRowRight>
            <CellTitle>{numberWithSpaces(priceWork)}</CellTitle>
          </SplittedRowRight>
        </SplittedRow>
      </SplittedCellWrapper>

      {/* Остаток с предыдущего месяца */}
      <Cell>
        <CellTitle>{numberWithSpaces(parseFloat(saldoCount).toFixed(DECIMAL_COUNT))}</CellTitle>
      </Cell>

      {/* План на начало предыдущего месяца */}
      {!isShowNote && (
        <Cell>
          <CellTitle>
            {numberWithSpaces(parseFloat(planPast).toFixed(DECIMAL_COUNT))}
          </CellTitle>
        </Cell>
      )}

      {/* Прогноз на предыдущий месяц */}
      <Cell>
        <SelectableNameWrapper>
          {numberWithSpaces(parseFloat(costActInPast).toFixed(DECIMAL_COUNT))}
        </SelectableNameWrapper>
        {Number(costActInPast) !== 0 && (
          <>
            <IconWrapper ref={params.setTriggerRef}>
              <Icon
                icon="pdb-information"
                onClick={() => getPopupData(id)}
                data-testid="icon-funding-pdf-info"
              />
            </IconWrapper>
            <PrepaidPopup {...params} />
          </>
        )}
      </Cell>

      {/* Отклонение от прогноза на предыдущий месяц */}
      {!isShowNote && (
        <Cell>
          <CellTitle>{numberWithSpaces(deltaForecast?.toFixed(DECIMAL_COUNT))}</CellTitle>
        </Cell>
      )}

      {/* Остаток на начало текущего месяца */}
      <Cell>
        <CellTitle>{numberWithSpaces(forecast?.toFixed(DECIMAL_COUNT))}</CellTitle>
      </Cell>

      {/* План на текущий месяц */}
      {getInputCell()}

      {/* Бюджет */}
      <Cell>
        <CellTitle>{numberWithSpaces(totalPlanSum)}</CellTitle>
      </Cell>
    </Wrapper>
  )
}

type WrapperProps = {
  isLast?: boolean
}

const Wrapper = styled(GridWrapper)<WrapperProps>`
  width: 100%;

  &:nth-child(even) {
    background-color: ${themeVar('lightBackground')};
  }

  min-height: 64px;

  ${({ isLast }) => isLast
    && css`
      border-bottom: 1px solid ${themeVar('line')};
    `}
`

type CellProps = {
  noCenter?: boolean
}

const Cell = styled.div<CellProps>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ noCenter }) => (noCenter ? 'space-between' : 'center')};
  align-items: center;

  border-left: 1px solid ${themeVar('line')};
  border-top: 1px solid ${themeVar('line')};

  &:last-child {
    border-right: 1px solid ${themeVar('line')};
  }
  padding: 8px;
`

const CellContent = styled.div`
  display: flex;
  flex-direction: column;
`

const NameWrapper = styled.span`
  ${MediumTextCSS}
`

type CellTitleProps = {
  row?: boolean
}

const CellTitle = styled.span<CellTitleProps>`
  ${MediumTextCSS}

  ${({ row }) => row
    && css`
      display: flex;
      justify-content: flex-start;
      align-items: center;
    `}
`

const SelectableNameWrapper = styled(CellTitle)`
  margin-right: 4px;
`

const IconWrapper = styled.span`
  cursor: pointer;
  width: 24px;
  height: 24px;
`

const NoteCell = styled(Cell)`
  background-color: ${themeVar('orange5')};
`

const ApprovedContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`

const ApprovedLabel = styled.div`
  ${MediumTextCSS}
  background-color: ${themeVar('main')};
  color: ${themeVar('white')};
  padding: 3px;
  border-radius: 4px;
`

const InfoIconWrapper = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
`

const SplittedCellWrapper = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid ${themeVar('line')};
  border-bottom: none;
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    border-right: none;
  }
`

const SplittedTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 8px;

  ${({ onClick }) => onClick
    && css`
      cursor: pointer;
    `};
`

const SplittedRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${themeVar('line')};
  flex-grow: 1;
`

const SplittedRowLeft = styled('div')`
  flex-grow: 1;
  height: 100%;
  border-right: 1px solid ${themeVar('line')};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
`

const SplittedRowRight = styled('div')`
  flex-grow: 1;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 8px;
`
