import { combine } from 'effector'
import { createForm } from 'effector-forms'

import {
  ContragentDTO,
  checkINNReqFx,
  putPaymentInfoReqFx,
} from '@/dal'
import { rules, withDisableCondition } from '@/lib/form-validators'
import { createSingleEffect } from '@/lib/createSingleEffect'
import { formatNumberByFourDigits } from '@/lib/number-represent'

import { d } from './domain'
import { PaymentGate } from './public'
import { ContragentInfo } from './types'
import { $isThirdPerson } from './private'

export const $contragents = d.store<ContragentDTO[] | null>(null)
export const $isInnNotFoundError = d.store<boolean>(false)
export const setInnNotFoundError = d.event<void>()

export const $openContragentInfo = d.store<ContragentInfo | null>(null)
export const setContragentInfo = d.event<ContragentInfo>()
export const onCreateContragent = d.event<void>()
export const onShowContragentRequest = d.event<void>()

export const showErrorInEditForm = d.event<void>()

export const onCheckINN = d.event<void>()

const condition = () => $isThirdPerson.getState()

export const editPaymentForm = createForm({
  domain: d,
  fields: {
    number: {
      init: '',
      rules: [withDisableCondition(condition, rules.required())],
    },
    payment_date: {
      init: '',
      rules: [
        withDisableCondition(condition, rules.required()),
        withDisableCondition(condition, rules.dateRange('avans_date', true)),
      ],
    },
    avans_date: {
      init: '',
      rules: [
        rules.required(),
        withDisableCondition(condition, rules.dateRange('payment_date')),
      ],
    },
    comment: {
      init: '',
    },
    inn: {
      init: '',
      rules: [
        withDisableCondition(condition, rules.required()),
        withDisableCondition(condition, rules.inn()),
      ],
    },
    contragent: {
      init: null as null | string,
    },
    current_account: {
      init: null as null | string,
    },
    outgoing_mail_number: {
      init: '',
    },
    outgoing_mail_date: {
      init: '',
    },
  },
  validateOn: ['change'],
})

export const $contragentOptions = $contragents.map((items) => items?.map((item) => ({
  id: item.uuid,
  label: item.full_name,
})) || null)

export const $bankAccountOptions = combine({
  selectedId: editPaymentForm.fields.contragent.$value,
  list: $contragents,
}, ({ list, selectedId }) => {
  if (!selectedId) return null
  const contragent = list?.find((item) => item.uuid === selectedId)
  if (!contragent) return null
  return contragent.bank_accounts.map((bank) => ({
    id: bank.uuid,
    label: `${formatNumberByFourDigits(bank.number)}, ${bank.bank_name}`,
  }))
})

export const {
  requestFx: putPaymentInfoFx,
} = createSingleEffect(putPaymentInfoReqFx, { abortEvents: [PaymentGate.close] })

export const {
  requestFx: changePaymentTypeAndInfoFx,
} = createSingleEffect(putPaymentInfoFx, { abortEvents: [PaymentGate.close] })

export const {
  requestFx: checkINNFx,
} = createSingleEffect(checkINNReqFx, { abortEvents: [PaymentGate.close] })
