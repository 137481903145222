import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { ContractDirection } from '@/dal'
import {
  TableHeaderCell,
} from '@/ui'
import { numberWithSpaces } from '@/lib/number-represent'

import {
  CellRow, FirstHeaderCellWrapper, HeaderRowWrapper, SplitCellWrapper,
} from '../parts'
import {
  $hiddenCellGroup,
  $isFillByRemainingPending,
  $tableInfo,
  onFillByRemaining,
  toggleHiddenGroup,
} from '../../model/private'
import { $currentKS2 } from '../../../model'

type Props = {
  isScrolled?: boolean
}

export const TableHeader = React.memo(({ isScrolled }: Props) => {
  const [info, isDataPending] = useUnit([
    $tableInfo,
    $isFillByRemainingPending,
  ])
  const ks2 = useUnit($currentKS2)

  const isHiddenGroup = useUnit($hiddenCellGroup)
  const canFillByRemaining = Boolean(info?.can_fill_by_remaining)

  return (
    <Wrapper>
      <HeaderRowWrapper actType={ContractDirection.SMR} hiddenFirstGroup={isHiddenGroup} as="th">
        <FirstHeaderCellWrapper hasRightDivider={isHiddenGroup} hasShadow={isScrolled}>
          <TableHeaderCell
            label="Наименование услуг, работ и&nbsp;материалов"
            textAlign="left"
            isFirst
          />
        </FirstHeaderCellWrapper>

        {!isHiddenGroup && (
          <>
            <TableHeaderCell label="Примечание" isHiddenCell />
            <TableHeaderCell label="Статья оборотов" isHiddenCell />
          </>
        )}

        <TableHeaderCell
          label="Указано в&nbsp;смете"
          textAlign="right"
          groupNumber={1}
          isExpanded={!isHiddenGroup}
          expandButtonZIndex={isScrolled ? undefined : 10}
          onExpandButton={() => toggleHiddenGroup()}
        />
        <TableHeaderCell label="Остаток к&nbsp;выполнению" textAlign="right" />
        <TableHeaderCell
          label="Сделано в&nbsp;отчетном периоде"
          textAlign="right"
          info={`Общий объем – ${numberWithSpaces(info?.total_volume || '', { decimalScale: 4 })}`}
          checkboxLabel={canFillByRemaining ? 'Заполнить по остатку' : undefined}
          onToggle={canFillByRemaining ? onFillByRemaining : undefined}
          isDisableCheckbox={!info?.can_fill_by_remaining || isDataPending}
          isChecked={info?.fill_by_remaining}
        />
        <TableHeaderCell label="Ед. изм" />

        <SplitCellWrapper>
          <TableHeaderCell label="Общая цена" />
          <CellRow>
            <TableHeaderCell label="Цена материалов" />
            <TableHeaderCell label="Цена работ" />
          </CellRow>
        </SplitCellWrapper>

        <SplitCellWrapper>
          <TableHeaderCell label="Стоимость за&nbsp;отчетный период" totalCost={ks2?.total} />
          <CellRow>
            <TableHeaderCell label="Стоим. материалов" totalCost={ks2?.material} />
            <TableHeaderCell label="Стоим. работ" totalCost={ks2?.work} />
          </CellRow>
        </SplitCellWrapper>
      </HeaderRowWrapper>
    </Wrapper>
  )
})

const Wrapper = styled.tr`
  display: block;
`
