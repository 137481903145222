import React from 'react'
import styled, { css } from 'styled-components'

import { EstimateLot } from '@/dal'
import { palette } from '@/ui'

import { CostValues } from './CostValues'
import { Cell } from './Cell'

type Props = {
  summary: EstimateLot['summary']
}

export const SummaryTable = ({ summary }: Props) => {
  const { cost_of_lot, cost_of_materials, cost_of_work } = summary
  const {
    cost_of_material, grand_tender, nominal, related,
  } = cost_of_materials

  return (
    <Wrapper>
      <Row isHeader>
        <Cell isHeaderCell />
        <Cell align="right" isHeaderCell>Заявлено</Cell>
        <Cell align="right" isHeaderCell>Принято</Cell>
        <Cell align="right" isHeaderCell>Разница</Cell>
      </Row>

      <Row>
        <Cell isBold>Стоимость работ</Cell>
        <CostValues cost={cost_of_work} />
      </Row>

      <Row>
        <Cell isBold>Стоимость материалов</Cell>
        <CostValues cost={cost_of_material} />
      </Row>

      <Row hasGreyBackground>
        <Cell hasIndent>Гранд тендеры</Cell>
        <CostValues cost={grand_tender} />
      </Row>

      <Row hasGreyBackground>
        <Cell hasIndent>Номинированные</Cell>
        <CostValues cost={nominal} />
      </Row>

      <Row hasGreyBackground>
        <Cell hasIndent>Сопутствующие</Cell>
        <CostValues cost={related} />
      </Row>

      <Row>
        <Cell isBold size="L">Стоимость коммерческого предложения</Cell>
        <CostValues cost={cost_of_lot} />
      </Row>
    </Wrapper>
  )
}

const Wrapper = styled.div`
`

const Row = styled.div<{ hasGreyBackground?: boolean, isHeader?: boolean }>`
  display: grid;
  grid-template-columns: minmax(0, 415fr) repeat(3, minmax(0, 200fr));

  ${({ isHeader }) => isHeader && css`
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  `}

  ${({ hasGreyBackground, isHeader }) => (hasGreyBackground || isHeader) && css`
    background-color: ${palette.grey10};
  `}
`
