import { sample } from 'effector'

import { createToast } from '@/features/toast-service/model'

import { appealForm } from './forms'
import {
  $documentList,
  $requestTypes,
  $selectedDocType,
  $selectedRequestType,
  $selectedResponsibleAlias,
  NewAppealGate,
  getDocumentListFx,
  getRequestTypes,
  selectResponsibleAlias,
  sendAppealForResponsibleFx,
} from './private'
import { onCreateAppealModalClosed, setContactsStep } from '../../support-appeals/model'
import { onSelectAppealTypeClicked } from '../../support-appeals/model/private'

$selectedResponsibleAlias
  .on(selectResponsibleAlias, (_, alias) => alias)
  .reset(NewAppealGate.close)

$requestTypes
  .on(getRequestTypes.doneData, (_, types) => types)
  .reset(NewAppealGate.close, getRequestTypes)

$documentList
  .on(getDocumentListFx.doneData, (_, list) => list)
  .reset(NewAppealGate.close, getDocumentListFx)

sample({
  clock: [onSelectAppealTypeClicked, onCreateAppealModalClosed],
  target: appealForm.reset,
})

sample({
  clock: $selectedRequestType.updates,
  filter: Boolean,
  fn: (requestType) => ({ task_type: requestType }),
  target: getDocumentListFx,
})

sample({
  clock: appealForm.formValidated,
  source: [$selectedRequestType, $selectedDocType],
  filter: ([requestType, docType]) => Boolean(requestType && docType),
  fn: () => 'responsible' as const,
  target: setContactsStep,
})

createToast({
  effect: sendAppealForResponsibleFx,
  doneText: ({ result }) => `Обращение ${result.number} создано`,
})
