import React, { memo } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { DocumentAlias } from '@/dal'
import { DocumentInfoBlock, LayoutGrid, WINDOW_WIDTH } from '@/ui'
import { $documentInfo } from '@/features/estimate-lot/model'
import { openAdditionalModal } from '@/features/estimate-lot/model/private'
import { DocumentFilesButton } from '@/features/document-files'

import {
  ConfirmDeleteModal,
  LotAttachments,
  LotCommercialInfo,
  LotMainInfo,
  LotMorphology,
} from '../containers'

export const EstimateLotInfo = memo(() => {
  const info = useUnit($documentInfo)

  if (!info) return null

  return (
    <>
      <LayoutGrid>
        <Col>
          <DocumentInfoBlock
            status={info.status}
            features={info.features}
            bottomSlot={<LotMainInfo />}
            // TODO ещё нет progress, т. к. пока только черновик
            progress={null}
            onOpenAdditional={openAdditionalModal}
          />

          <LotMorphology />

          <Row>
            <LotAttachments />
            <DocumentFilesButton
              id={info.id}
              docType={DocumentAlias.ESTIMATE_LOT}
              status={info.status}
              downloadLink={info.documents_link}
            />
          </Row>
        </Col>

        <CommercialInfoWrapper>
          <LotCommercialInfo />
        </CommercialInfoWrapper>
      </LayoutGrid>

      <ConfirmDeleteModal />
    </>
  )
})

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  grid-column: span 5;
`

const Row = styled.div`
  display: grid;
  gap: 16px;

  @media (min-width: ${WINDOW_WIDTH.desktop}px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`

const CommercialInfoWrapper = styled.div`
  grid-column: span 7;
`
