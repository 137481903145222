import React from 'react'

import { GridWrapper, Label } from './sharedCSS'
import { CurrencyCol } from './CurrencyCol'

type Props = {
  current: string,
  adjusted: string
  approved: string | null
  adjusted_delta: string | null
  approved_delta: string | null
  is_edited?: boolean
  label: string
  isTotal?: boolean
  is_changeable?: boolean
  isDraft?: boolean
  type?: 'currency' | 'percent'
  onSubmit?: (val: string) => void
}

export const CurrencyRow = ({
  adjusted,
  approved,
  current,
  adjusted_delta,
  is_changeable,
  is_edited,
  label,
  isTotal,
  approved_delta,
  isDraft,
  type = 'currency',
  onSubmit,
}: Props) => (
  <GridWrapper isTotal={isTotal}>
    <Label isBold={isTotal}>{label}</Label>
    <CurrencyCol
      value={current}
      isBold={isTotal}
      deltaValue={null}
      type={type}
    />
    <CurrencyCol
      value={adjusted}
      deltaValue={adjusted_delta}
      isBold={isTotal}
      isChangeable={is_changeable}
      currentValue={current}
      type={type}
      onSubmit={onSubmit}
    />
    <CurrencyCol
      value={approved ?? '0'}
      isBold={isTotal}
      deltaValue={approved_delta}
      isEdited={is_edited && !isDraft}
      currentValue={current}
      isGrey={isDraft}
      type={type}
    />
  </GridWrapper>
)
