import React from 'react'
import styled, { css } from 'styled-components'

import { numberWithSpaces } from '@/lib/number-represent'
import {
  Ruble, SimpleLoader, themeVar, WINDOW_WIDTH,
} from '@/ui'

type Props = {
  title: string
  allDeclarated: string
  workDeclarated: string
  materialDeclarated: string
  allApproved: string
  workApproved: string
  materialApproved: string
  isLoading?: boolean
  dataTestId: string
  onToggleAccordion?: () => void
}

export const BudgetInfo = ({
  title,
  allDeclarated,
  workDeclarated,
  materialDeclarated,
  allApproved,
  workApproved,
  materialApproved,
  isLoading,
  dataTestId,
  onToggleAccordion,
}: Props) => (
  <Wrapper>
    <Row
      isBorderBottom
      onClick={onToggleAccordion}
      data-testid="act-info-expand-collapse"
    >
      <HeaderPeriod>
        {title}
        {isLoading && <SimpleLoader sizePx={16} />}
      </HeaderPeriod>
      <div />
      <Cell isGrayColor>Заявлено</Cell>
      <Cell isGrayColor>Принято</Cell>
    </Row>
    <Row>
      <NamePeriod>Стоимость всего</NamePeriod>
      <div />
      <Cell data-testid={`${dataTestId}-sums-cost-all`}>
        {numberWithSpaces(allDeclarated)}
        <Ruble />
      </Cell>
      <Cell data-testid={`${dataTestId}-sums-cost-all-approved`}>
        {numberWithSpaces(allApproved)}
        <Ruble />
      </Cell>
    </Row>
    <Row>
      <NamePeriod>Материалы</NamePeriod>
      <div />
      <Cell data-testid={`${dataTestId}-materials-declarated`}>
        {numberWithSpaces(materialDeclarated)}
        <Ruble />
      </Cell>
      <Cell data-testid={`${dataTestId}-materials-approved`}>
        {numberWithSpaces(materialApproved)}
        <Ruble />
      </Cell>
    </Row>
    <Row>
      <NamePeriod>Работы</NamePeriod>
      <div />
      <Cell data-testid={`${dataTestId}-works-declarated`}>
        {numberWithSpaces(workDeclarated)}
        <Ruble />
      </Cell>
      <Cell data-testid={`${dataTestId}-works-approved`}>
        {numberWithSpaces(workApproved)}
        <Ruble />
      </Cell>
    </Row>
  </Wrapper>
)


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

type ItemProps = {
  isBorderBottom?: boolean
  isGrayColor?: boolean
}

const Row = styled.div<ItemProps>`
  width: 100%;
  display: grid;
  grid-template-columns: 242fr 62fr 152fr 152fr;
  gap: 8px;

  ${({ onClick }) => onClick
    && css`
      cursor: pointer;
    `}

  ${({ isBorderBottom }) => isBorderBottom
    && css`
      border-bottom: 1px solid ${themeVar('inlineInputBorder')};
      padding-bottom: 8px;
      margin-bottom: 8px;
    `}
`

const HeaderPeriod = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  white-space: nowrap;
  color: ${themeVar('text')};

  @media (max-width: ${WINDOW_WIDTH.desktop - 1}px) {
    font-size: 12px;
  }
`

const Cell = styled.div<ItemProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
  color: ${themeVar('text')};

  ${({ isGrayColor }) => isGrayColor
    && css`
      color: ${themeVar('textGray')};
    `}

  @media (max-width: ${WINDOW_WIDTH.desktop - 1}px) {
    font-size: 12px;
  }
`

const NamePeriod = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;

  color: ${themeVar('textGray')};

  @media (max-width: ${WINDOW_WIDTH.desktop - 1}px) {
    font-size: 12px;
  }
`
