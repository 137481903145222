import { combine } from 'effector'
import { createGate } from 'effector-react'

import {
  AdministrationAppealDocument,
  ResponsibleAppealAlias,
  AdministrativeRequestType,
  getAdministrativeRequestTypesReqFx,
  getAppealDocumentListReqFx,
  postAdministrativeAppealReqFx,
} from '@/dal'
import { createSingleEffect } from '@/lib/createSingleEffect'
import { getStatusName } from '@/lib/getStatusName'
import { Option, DOCUMENT_STATUS_COLOR_MAP } from '@/ui'

import { d } from './domain'
import { AppealSection } from '../../model/types'
import { $appealSections } from '../../model'
import { appealForm } from './forms'

export const NewAppealGate = createGate('NewAppeal')
export const NewAppealFormGate = createGate<ResponsibleAppealAlias>('NewAppealForm')

// TODO ренейм
export const $selectedResponsibleAlias = d.store<AppealSection['alias'] | null>(null)
export const selectResponsibleAlias = d.event<AppealSection['alias']>()

export const $requestTypes = d.store<AdministrativeRequestType[] | null>(null)

export const $documentList = d.store<AdministrationAppealDocument[]>([])

export const $documentOptions = $documentList
  .map((items) => items?.map(({ id, name, status }) => {
    const statusName = getStatusName(status)
    return {
      id,
      label: name,
      subLabel: {
        text: statusName,
        color: DOCUMENT_STATUS_COLOR_MAP[status] || 'accent100',
      },
    } as Option
  }) ?? null)

export const $selectedResponsibleRole = combine(
  [$appealSections, $selectedResponsibleAlias],
  ([roles, selectedAlias]) => roles.find(
    ({ alias }) => selectedAlias === alias) ?? null,
)

export const $selectedRequestType = combine(
  [$appealSections, $requestTypes, $selectedResponsibleAlias],
  ([roles, requestTypes, selectedRoleAlias]) => {
    const roleRequestType = roles?.find(
      ({ alias }) => selectedRoleAlias === alias)?.alias
    return roleRequestType ?? requestTypes?.[0].name ?? null
  },
)

export const $selectedDocType = combine(
  [$documentList, appealForm.$values],
  ([documents, form]) => documents.find((doc) => doc.id === form.document)?.type ?? null,
)

export const {
  requestFx: sendAppealForResponsibleFx,
} = createSingleEffect(postAdministrativeAppealReqFx)

export const {
  requestFx: getDocumentListFx,
} = createSingleEffect(getAppealDocumentListReqFx)

export const {
  requestFx: getRequestTypes,
} = createSingleEffect(getAdministrativeRequestTypesReqFx)
