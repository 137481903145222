import { useForm } from 'effector-forms'
import React, { FormEvent } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { NewButton, NumberInput } from '@/ui'

import { checkINNFx, innForm } from '../../model/private'

export const FirstStep = React.memo(() => {
  const isPending = useUnit(checkINNFx.pending)

  const {
    fields,
    isValid,
    errorText,
    submit,
  } = useForm(innForm)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    submit()
  }

  return (
    <Form onSubmit={handleSubmit}>
      <NumberInput
        dataTestId="inn-field"
        onChange={fields.inn.onChange}
        value={fields.inn.value}
        onClear={fields.inn.reset}
        maxLength={12}
        error={errorText('inn')}
        disabled={isPending}
        size="L"
        allowLeadingZeros
        placeholder="Введите ИНН"
      />
      <NewButton
        dataTestId="submit-button"
        label="Проверить"
        isFill
        isDisabled={!isValid || !fields.inn.value}
        isPending={isPending}
        type="submit"
      />
    </Form>
  )
})

const Form = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 12px;
`
