import { attach, createDomain } from 'effector'
import { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'
import { $accessToken } from '../auth'

export enum Method {
  get = 'GET',
  post = 'POST',
  put = 'PUT',
  delete = 'DELETE',
  patch = 'PATCH',
}

type Request = {
  method: AxiosRequestConfig['method']
  url: AxiosRequestConfig['url']
  headers?: AxiosRequestConfig['headers']
  accessToken?: string
  query?: AxiosRequestConfig['params']
  body?: AxiosRequestConfig['data']
  baseUrl?: AxiosRequestConfig['baseURL']
  responseType?: AxiosRequestConfig['responseType']
  signal?: AbortSignal
}

export type AccessToken = string | null

export const restApi = createDomain('rest-api')

export const requestFx = restApi.effect<Request, AxiosResponse, AxiosError>()
export const authRequestFx = attach({
  source: $accessToken,
  effect: requestFx,
  mapParams: (request: Omit<Request, 'accessToken'>, accessToken) => ({
    ...request,
    accessToken: accessToken || undefined,
  }),
})

export const mockRequestFx = restApi.effect<Request, AxiosResponse, AxiosError>()

type QueryParamValue = string | number | (string | number)[]

type FetchFileType = {
  accessToken?: string,
  url: string,
  query?: Record<string, QueryParamValue>
}

export const fetchFileFx = restApi.effect<FetchFileType, Blob, Error>()

export const downloadFilesFx = attach({
  effect: fetchFileFx,
  source: $accessToken,
  mapParams: (params: Omit<FetchFileType, 'accessToken'>, accessToken) => ({
    ...params,
    accessToken: accessToken || undefined,
  }),
})
