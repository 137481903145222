import React, { forwardRef, memo, useCallback } from 'react'
import { useUnit } from 'effector-react'
import { TableVirtuoso, TableVirtuosoHandle } from 'react-virtuoso'

import { WorkTableItem as Item } from '@/dal'

import { TableFilters } from './TableFilters'
import { WorkTableItem } from './WorkTableItem'
import { $isDescriptionExpanded, $isEditMode, $workItems } from '../../model/private'

export const WorkTable = memo(
  forwardRef((_, ref: React.ForwardedRef<TableVirtuosoHandle>) => {
    const [items, isEditMode, isFirstGroupOpen] = useUnit([
      $workItems,
      $isEditMode,
      $isDescriptionExpanded,
    ])

    const MemoHeader = useCallback(() => {
      return <TableFilters />
    }, [])

    const MemoComponent = useCallback((_: number, item: Item) => {
      return (
        <WorkTableItem
          item={item}
          isEditMode={isEditMode}
          isFirstGroupOpen={isFirstGroupOpen}
        />
      )
    }, [isEditMode, isFirstGroupOpen])

    return (
      <TableVirtuoso
        style={{ height: '100%' }}
        ref={ref}
        data={items}
        fixedHeaderContent={MemoHeader}
        itemContent={MemoComponent}
      />
    )
  }),
)
