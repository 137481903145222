import styled, { css } from 'styled-components'

export const AnimationWrapper = styled.div<{ isFill?: boolean }>`
  position: relative;
  overflow: hidden;
  width: fit-content;
  height: fit-content;
  border-radius: 8px;

  ${({ isFill }) => isFill && css`
    width: 100%;
    white-space: nowrap;
  `}
`

