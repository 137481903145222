import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import { Icon } from './Icon'
import { themeVar } from './theming'
import { KeyTheme } from './theming/themes'

type Props = {
  items: {
    title: string;
    color?: KeyTheme,
    path?: string
  }[]
}

export const Breadcrumbs = ({ items }: Props) => (
  <Container>
    {items.map(({ title, color, path }, index) => (
      path ? (
        <Link key={index} to={path}>
          <Item path={path} isLast={index === items.length - 1} color={color}>
            {title}
            {index !== items.length - 1 && <Icon icon="arrow-right" />}
          </Item>
        </Link>
      ) : (
        <Item key={index} isLast={index === items.length - 1} color={color}>
          {title}
          {index !== items.length - 1 && <Icon icon="arrow-right" />}
        </Item>
      )
    ))}
  </Container>
)

const Container = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & a {
    text-decoration: none;
  }
`

type ItemProps = {
  isLast: boolean
  color?: KeyTheme
  path?: string
}

const Item = styled.div<ItemProps>`
  display: flex;
  align-items: center;

  svg {
    margin: 0 4px;
  }

  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: ${themeVar('breadCrumbsText')};

  ${({ isLast }) => isLast
    && css`
      color: ${themeVar('textGray')};
    `}

  ${({ color }) => color
    && css`
      color: ${themeVar(color)};
    `}

  ${({ path }) => path && css`
    @media (hover: hover) {
      transition: color 0.15s linear;
      &:hover {
        color: ${themeVar('linkColor')}
      }
    }
  `}
`
