import { CustomResponseError } from '../error-handler'
import {
  RequestForAccessError,
} from './types'

export class CompanyNotFoundError extends CustomResponseError {
  data: RequestForAccessError

  constructor(data: RequestForAccessError, message = '') {
    super(message)
    this.name = 'CompanyNotFoundError'
    this.data = data
  }
}

export class AccreditationTimeOutError extends CustomResponseError {
  data: RequestForAccessError

  constructor(data: RequestForAccessError, message = '') {
    super(message)
    this.name = 'AccreditationTimeOut'
    this.data = data
  }
}

export class AccreditationInProccessError extends CustomResponseError {
  data: RequestForAccessError

  constructor(data: RequestForAccessError, message = '') {
    super(message)
    this.name = 'AccreditationInProccessError'
    this.data = data
  }
}

export class AccreditationFailedError extends CustomResponseError {
  data: RequestForAccessError

  constructor(data: RequestForAccessError, message = '') {
    super(message)
    this.name = 'AccreditationFailedError'
    this.data = data
  }
}
export class CompanyAlreadyExistsInLKPError extends CustomResponseError {
  data: RequestForAccessError

  constructor(data: RequestForAccessError, message = '') {
    super(message)
    this.name = 'CompanyAlreadyExistsInLKPError'
    this.data = data
  }
}
