import { attach } from 'effector'

import { createAvansDocumentReqFx } from '@/dal'

import { d } from './domain'

export const onConfirmCreatePayment = d.event<void>()

export const createPaymentFx = attach({
  effect: createAvansDocumentReqFx,
})
