import React, { memo } from 'react'
import styled from 'styled-components'
import { useStoreMap } from 'effector-react'

import {
  DocumentAlias,
  DocumentItem,
  VisasDocType,
} from '@/dal'
import { formatDateString } from '@/lib/date'
import { numberWithSpaces } from '@/lib/number-represent'
import {
  DocumentActionButtons,
  DocumentStatusLabel,
  NewTextButton,
  TextMLight,
  palette,
} from '@/ui'
import { getDocumentNameByType, getRouterPathByDocumentAlias } from '@/lib/documentResolvers'
import { GridTemplate, Cell } from '@/features/document-flow/shared'

import {
  $downloadInfo,
  getDocSubtypeNameByAlias,
} from '../../model'
import {
  onAdditionalButtonClicked,
  downloadDocumentFlowFile,
  downloadFileByUrl,
  onClickCopyButton,
  onClickDeleteButton,
} from '../../model/private'

export const DocumentListItem = memo(({
  answer_required,
  date, // YYYY-MM-DD
  id,
  name,
  period,
  type,
  status,
  sums,
  doc_type,
  download_link,
  project_name,
  organization_name,
  incoming_number,
  incoming_date,
  available_actions,
  progress,
  document_type,
}: DocumentItem) => {
  const isDownloading = useStoreMap({
    store: $downloadInfo,
    keys: [id, type],
    fn: (list, [id, type]) => list.some((item) => item.id === id && item.type === type),
  })

  const typeName = React.useMemo(() => (
    getDocumentNameByType(type, doc_type)
  ), [type])

  const dates = React.useMemo(() => {
    if (period.date_start && period.date_stop) {
      return `${formatDateString(period.date_start)} – ${formatDateString(period.date_stop)}`
    }
    if (period.date_start && type === DocumentAlias.CONTRACT) {
      return `${formatDateString(period.date_start)} / бессрочный`
    }
    return ''
  }, [period, type])

  const canDownload = [
    DocumentAlias.ADDITIONAL_AGREEMENT,
    DocumentAlias.ESTIMATE,
    DocumentAlias.KOR_DOC,
    DocumentAlias.BUDGET,
  ].includes(type) || ([
    DocumentAlias.ACT,
    DocumentAlias.CONTRACT,
  ].includes(type) && Boolean(download_link))

  const documentLink = React.useMemo(() => {
    if (!type) return null
    return getRouterPathByDocumentAlias(type)
  }, [type])

  const handleDownloadFile = () => {
    if (download_link) {
      downloadFileByUrl({ id, type, url: download_link })
    } else {
      downloadDocumentFlowFile({ id, type })
    }
  }

  return (
    <Row>
      <Cell>
        <Col>
          <div>
            {typeName}
          </div>
          {document_type && (
            <GreyText>
              {getDocSubtypeNameByAlias(document_type)}
            </GreyText>
          )}
        </Col>
      </Cell>

      <Cell>
        <Col>
          <NewTextButton
            url={`${documentLink}/${id}`}
            label={name}
            textStyle="ML"
            dataTestId="doc-flow-doc-link"
            hasUnderline
            isInline
          />

          <GreyText>
            {project_name}
          </GreyText>
        </Col>
      </Cell>

      <Cell>
        {organization_name}
      </Cell>

      <Cell>
        {formatDateString(date)}
      </Cell>

      <Cell>
        {dates}
      </Cell>

      <Cell>
        <Col>
          <DocumentStatusLabel
            status={status}
            progress={progress}
            progressPosition="right"
            isShort
          />
          {progress?.responsible && (
            <GreyText>
              Ответственный —
              {' '}
              {progress.responsible}
            </GreyText>
          )}
        </Col>
      </Cell>

      <Cell align="center">
        {incoming_number}
      </Cell>

      <Cell>
        {incoming_date}
      </Cell>

      <Cell align="right">
        {numberWithSpaces(sums?.toString())}
      </Cell>

      <DocumentActionButtons
        id={id}
        availableActions={available_actions}
        canDownload={canDownload}
        isAnswerRequired={answer_required}
        isDownloading={isDownloading}
        isEndAlign
        onCopy={() => onClickCopyButton(id)}
        onDownload={handleDownloadFile}
        onDelete={() => onClickDeleteButton(id)}
        onAdditionalRequestClicked={() => onAdditionalButtonClicked({
          docId: id,
          docType: type as VisasDocType,
          status,
        })}
      />
    </Row>
  )
})

const Row = styled.div`
  ${GridTemplate}
  transition: background-color 0.3s linear;

  &:not(:first-child) {
    border-top: 1px solid ${palette.grey20};
  }

  &:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${palette.grey10};
    }
  }
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const GreyText = styled.p`
  ${TextMLight}
  color: ${palette.grey70};
`
