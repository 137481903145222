import { Accept, FileRejection, useDropzone } from 'react-dropzone'

import { showTooLargeFileError } from '@/features/shared/file-toasts/model'

import { ACCEPTABLE_FILES, FILE_MAX_SIZE } from '../const'

type Params = {
  accept?: Accept
  maxSize?: number
  disabled?: boolean
  multiple?: boolean
  onDrop?: (files: File[]) => void
}

const TOO_LARGE_ERROR_CODE = 'file-too-large'

export const useDefaultDropzone = ({
  accept = ACCEPTABLE_FILES,
  maxSize = FILE_MAX_SIZE,
  disabled,
  multiple,
  onDrop,
}: Params = {}) => {
  const onDropRejected = (e: FileRejection[]) => {
    const tooLargeFileErrors = e.filter(({ errors }) => {
      return errors.some(({ code }) => code === TOO_LARGE_ERROR_CODE)
    })
    if (!tooLargeFileErrors.length) return
    const fileNames = tooLargeFileErrors.map(({ file }) => file.name)
    showTooLargeFileError(fileNames)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    maxSize,
    disabled,
    multiple,
    onDrop,
    onDropRejected,
  })

  return {
    getRootProps,
    getInputProps,
    isDragActive,
  }
}
