import React from 'react'
import styled from 'styled-components'
import { useForm } from 'effector-forms'
import { useUnit } from 'effector-react'

import {
  DefaultButton,
  FileInput,
  Icon2,
  NewFileItem,
  NewTextArea,
  palette,
  useDefaultDropzone,
} from '@/ui'
import { handleFileChangeHelper } from '@/lib/handleFileChangeHelper'
import { handleDeleteFileHelper } from '@/lib/handleDeleteFileHelper'

import {
  $additionalModalInfo, $isPendingCreateMessage, $isRequestByUser, additionalForm,
} from '../../model/private'
import { OneMessageWarning } from '../parts'

export const MessageForm = React.memo(() => {
  const { fields, submit } = useForm(additionalForm)
  const [info, isRequestByUser, isCreateMessagePending] = useUnit([
    $additionalModalInfo,
    $isRequestByUser,
    $isPendingCreateMessage,
  ])
  const [show, setShow] = React.useState(true)

  const isValid = Boolean(fields.message.value.trim() || fields.files.value.length)

  if (!info) return null

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    submit()
  }

  const handleFileChange = (files: File[]) => {
    handleFileChangeHelper({
      onChange: fields.files.onChange,
      currValue: fields.files.value,
      newFiles: files,
    })
  }

  const handleDeleteFile = (fileName: File['name']) => {
    handleDeleteFileHelper({
      value: fields.files.value,
      fileName,
      onChange: fields.files.onChange,
    })
  }

  const { getInputProps } = useDefaultDropzone()

  return (
    <Wrapper>
      {show && (
        <OneMessageWarning
          docType={info.docType}
          isSpecialVisa={info.isSpecialVisa}
          isRequestByContractor={isRequestByUser}
          onClose={() => setShow(false)}
        />
      )}

      <Form onSubmit={handleSubmit}>
        {Boolean(fields.files.value.length) && (
          <AttachetFiles>
            {fields.files.value.map(({ name }) => (
              <NewFileItem key={name} name={name} onDelete={handleDeleteFile} />
            ))}
          </AttachetFiles>
        )}
        <Row>
          <FileInput
            {...getInputProps()}
            multiple
            onFile={handleFileChange}
          >
            <AttachIcon>
              <Icon2
                icon="attach"
                size={24}
                data-testid="attach-file-additional-request"
              />
            </AttachIcon>
          </FileInput>

          <NewTextArea
            dataTestId="send-additional-request-input"
            maxHeight={350}
            height={42}
            autoGrow
            value={fields.message.value}
            onChange={fields.message.onChange}
            placeholder="Введите сообщение..."
            disabled={isCreateMessagePending}
          />
          <SubmitButton
            data-testid="send-additional-request"
            type="submit"
            disabled={!isValid || isCreateMessagePending}
          >
            <Icon2
              icon="sendIcon"
              size={24}
            />
          </SubmitButton>
        </Row>
      </Form>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 16px 24px 20px;
`

const Form = styled.form`
  max-height: 350px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const AttachetFiles = styled.div`
  padding: 0 44px;
  display: grid;
  gap: 0px 16px;
  grid-template-columns: 1fr 1fr;
`

const Row = styled.div`
  display: flex;
  align-items: flex-end;
`

const AttachIcon = styled.div`
  color: ${palette.grey60};
  padding: 8px;
  cursor: pointer;
  display: inline-flex;
  transition: color 0.15s linear;
  @media (hover: hover) {
    &:hover {
      color: ${palette.grey90};
    }
  }
`

const SubmitButton = styled(DefaultButton)`
  color: ${palette.accent100};
  padding: 8px;
  display: inline-flex;
  cursor: pointer;

  @media (hover: hover) {
    color: ${palette.accent90}
  }

  &:disabled {
    color: ${palette.accent40};
    cursor: not-allowed;
  }
`
