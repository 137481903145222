import React, { memo } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useUnit } from 'effector-react'

import {
  HeadL,
  Icon2,
  palette,
} from '@/ui'
import { RouterPaths } from '@/features/app/model'

import { $breadcrumbs } from '../../model/private'
import { $lotId } from '../../model'
import { Breadcrumbs } from '../parts'

export const LotWorkHeader = memo(() => {
  const [lotId, breadcrumbs] = useUnit([
    $lotId,
    $breadcrumbs,
  ])

  return (
    <Wrapper>
      <GoBackButton to={`${RouterPaths.EstimateLots}/${lotId}`}>
        <Icon2 icon="arrowLeft" size={24} color="grey90" />
        <Title>
          Информация по элементам структуры объекта строительства
        </Title>
      </GoBackButton>

      <BreadcrumbsWrapper>
        <Breadcrumbs items={breadcrumbs} />
      </BreadcrumbsWrapper>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  padding: 20px;
  background-color: ${palette.white};
  border-radius: 20px;
`

const GoBackButton = styled(Link)`
  display: flex;
  gap: 6px;
`

const Title = styled.h1`
  ${HeadL}
  padding-top: 2px;
`

const BreadcrumbsWrapper = styled.div`
  padding-left: 30px;
`
