import { css } from 'styled-components'

export const GridTemplate = css`
  display: grid;
  grid-template-columns:
    minmax(200px, 308fr) minmax(150px, 150fr) minmax(150px, 200fr) minmax(150px, 250fr)
    minmax(150px, 170fr) minmax(150px, 164fr) minmax(150px, 160fr)
    minmax(150px, 270fr) minmax(150px, 160fr);
  padding: 0 12px;
`
