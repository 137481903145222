import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { DocumentStatuses } from '@/dal'
import {
  HeadXL, NewButton, palette, SpecificDocumentCounter,
} from '@/ui'

import { $headerInfo, setFilter } from '../../model/private'
import { $contragentList, createEstimateLot, openLotCreationModal } from '../../model/actions.private'
import { LotCreationModal } from '../containers'

export const EstimateLotsHeader = React.memo(() => {
  const [info, contragentList] = useUnit([
    $headerInfo,
    $contragentList,
  ])

  const handleCreateLot = () => {
    if (!contragentList?.length) return
    if (contragentList.length > 1) {
      openLotCreationModal()
    } else {
      createEstimateLot(contragentList[0].id)
    }
  }

  return (
    <>
      <Wrapper>
        <TitleWrapper>
          <p>
            Коммерческое предложение
          </p>
          {info && (
            <Count>
              {info.all_estimate_lots_count}
            </Count>
          )}
        </TitleWrapper>

        <Buttons>
          {info && (
            <SpecificDocumentCounter
              text="На согласовании"
              count={info.on_agreed_estimate_lots}
              onClick={() => setFilter({ key: 'status', val: DocumentStatuses.ToBeAgreed })}
            />
          )}

          <NewButton
            label="Создать новый лот"
            prefixIcon="plusButton"
            buttonType="line-primary"
            size="S"
            isDisabled={!contragentList?.length}
            dataTestId="create-new-lot-btn"
            onClick={handleCreateLot}
          />
        </Buttons>
      </Wrapper>

      <LotCreationModal />
    </>
  )
})

const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`

const TitleWrapper = styled.div`
  ${HeadXL}
  display: flex;
  gap: 12px;
`

const Count = styled.div`
  color: ${palette.grey70};
`

const Buttons = styled.div`
  display: flex;
  gap: 8px;
`
