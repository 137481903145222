import { sample } from 'effector'

import { $accessToken } from '@/dal/auth'
import { onAdditionalRequestUpdated } from '@/features/additional-request/model'
import { SubSystemId, appInit } from '@/features/app/model'
import { downloadBlobFileFx } from '@/features/download-files'
import { onClickNavItem } from '@/features/header/model'

import { onSuccessDeleteDocumentFlow } from '../../list/model'
import {
  $filterOptions,
  $filters,
  $statusList,
  DocumentFlowGate,
  clearFilter,
  downloadDocFlowReport,
  downloadDocFlowReportFx,
  getFiltersFx,
  getStatusFx,
  loadContractsFx,
  onClearFilter,
  onClickRefreshDocs,
  onLoadMore,
  removeFilter,
  setFilter,
  setFilters,
} from './private'
import {
  $contractDocFlowList,
  initDocumentsFx,
  loadMoreDocumentsFx,
  onDocListLoaded,
  setDocumentsFlowFilters,
} from './public'
import { ContractInfo } from './types'

$contractDocFlowList
  .on(loadContractsFx.doneData, (_, d) => d)

$statusList
  .on(getStatusFx.doneData, (_, statuses) => statuses)

$filterOptions
  .on(getFiltersFx.doneData, (_, options) => options)

sample({
  clock: appInit,
  target: [loadContractsFx, getStatusFx],
})

sample({
  clock: onClearFilter,
  source: $filters,
  fn: ({ under_contract }) => ({ under_contract }),
  target: setFilters,
})

sample({
  clock: [initDocumentsFx.doneData, loadMoreDocumentsFx.doneData],
  target: onDocListLoaded,
})

sample({
  clock: DocumentFlowGate.state,
  fn: ({ contractId }: ContractInfo) => ({
    under_contract: contractId,
  }),
  target: setFilters,
})

sample({
  clock: [
    setFilters,
    onClearFilter,
    removeFilter,
    setFilter,
    onClickRefreshDocs,
    onSuccessDeleteDocumentFlow,
    onAdditionalRequestUpdated,
  ],
  source: {
    gate: DocumentFlowGate.status,
    filters: $filters,
    token: $accessToken,
  },
  filter: ({ gate, token }) => gate && token !== null,
  fn: ({ filters }) => filters,
  target: [initDocumentsFx, getFiltersFx],
})

sample({
  clock: setDocumentsFlowFilters,
  target: setFilters,
})

sample({
  clock: onLoadMore,
  source: $filters,
  target: loadMoreDocumentsFx,
})

sample({
  clock: onClickNavItem,
  filter: ({ subsystemId }) => subsystemId === SubSystemId.DocumentFlow,
  target: clearFilter,
})

sample({
  clock: downloadDocFlowReport,
  source: $filters,
  target: downloadDocFlowReportFx,
})

sample({
  clock: downloadDocFlowReportFx.doneData,
  fn: (blob) => ({
    blob,
    fileName: 'docflow-report.xlsx',
  }),
  target: downloadBlobFileFx,
})
