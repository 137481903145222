import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'
import { EventCallable, Store } from 'effector'

import {
  ResetStyleButton,
  Modal,
  themeVar,
  Icon,
} from '@/ui'
import {
  CreateFilesListParams,
  CreateUploadFiles,
  createFileList,
  createUploadFiles,
} from '../containers'

type Props<P, R, E> = {
  $isOpen: Store<boolean>
  closeModal: EventCallable<void>
} & CreateUploadFiles<P, R, E> & CreateFilesListParams

export const createFileModalView = <P, R, E>({
  $uploadedFiles,
  $files,
  $isOpen,
  closeModal,
  onFiles,
  onRemove,
  sendFiles,
  sendFilesFx,
} : Props<P, R, E>) => {
  const FilesList = createFileList({
    $files,
  })

  const UploadFiles = createUploadFiles({
    $uploadedFiles,
    onFiles,
    onRemove,
    sendFiles,
    sendFilesFx,
  })

  return React.memo(() => {
    const isOpen = useUnit($isOpen)

    const onClose = React.useCallback(() => closeModal(), [])

    return (
      <Modal
        visible={isOpen}
        onClose={onClose}
        padding={24}
        fillWidthPx={754}
        dataTestId="act-info-files-modal"
      >
        <ModalTop>
          <ModalTitle>
            Прикрепленные файлы
          </ModalTitle>

          <CloseIcon
            data-testid="act-files-modal-close"
            onClick={onClose}
          >
            <Icon icon="close" />
          </CloseIcon>

        </ModalTop>

        <ModalBody>
          <FilesList />
        </ModalBody>

        <ModalFooter>
          <UploadFiles />
        </ModalFooter>
      </Modal>
    )
  })
}

const ModalTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 17px;
`

const ModalTitle = styled.div`
  font-size: 22px;
  line-height: 1.36;
  color: ${themeVar('text')};
  font-weight: 600;
`

const CloseIcon = styled(ResetStyleButton)`
  width: 30px;
`

const ModalBody = styled.div``

const ModalFooter = styled.div`
  margin-top: 20px; 
`

