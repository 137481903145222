import { useGate, useUnit } from 'effector-react'
import React from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import { ContractDirection } from '@/dal'
import { ContentContainer, Loader, Tabs } from '@/ui'
import { ContractHeader, ContractInfo } from '@/features/administrative'
import {
  $activeTab,
  $contractDirection,
  $isContractPending,
  ContractInfoGate,
  changeContractTab,
} from '@/features/administrative/contract/model'
import { FinanceGraph } from '@/features/administrative/contract/view'
import { ContractTab } from '@/features/administrative/contract/model/types'

const TABS = [
  {
    id: ContractTab.MainInfo,
    label: 'Основная информация',
  },
  {
    id: ContractTab.Graph,
    label: 'График финансирования работ',
  },
]

export const ContractPage = () => {
  const router = useRouteMatch<{ id: string }>()
  const isPending = useUnit($isContractPending)
  const activeTab = useUnit($activeTab)
  const direction = useUnit($contractDirection)

  useGate(ContractInfoGate, { id: router.params.id })

  const hideScroll = React.useCallback(() => <div style={{ display: 'none' }} />, [])

  const hasFinanceGraph = direction && ![
    ContractDirection.BANK_WARRANTY,
    ContractDirection.CONTRACT_GUARANTEE,
  ].includes(direction)

  if (isPending) {
    return (
      <ContentContainer>
        <Loader />
      </ContentContainer>
    )
  }

  return (
    <Wrapper>
      <Scrollbars renderThumbHorizontal={hideScroll} renderTrackHorizontal={hideScroll}>
        <ContentContainer isAutoHeight>
          <HeaderWrapper>
            <ContractHeader />
          </HeaderWrapper>

          {hasFinanceGraph && (
            <Tabs
              activeId={activeTab}
              onItemClick={changeContractTab}
              items={TABS}
              size="M"
            />
          )}
          {activeTab === ContractTab.MainInfo ? <ContractInfo /> : <FinanceGraph />}
        </ContentContainer>
      </Scrollbars>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: 10px;
  flex-grow: 1;
`

const HeaderWrapper = styled.div`
  margin-bottom: 24px;
`
