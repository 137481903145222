import React from 'react'
import styled from 'styled-components'

import { Icon2, palette, TextL } from '@/ui'

import { WrapperStyle } from './styles'

export const TelegramLink = () => {
  return (
    // TODO тестовый бот
    <Wrapper href="https://t.me/LKPHelperBot" target="_blank">
      <IconsRow>
        <Icon2 icon="telegram" size={24} />
        <IconWrapper>
          <Icon2 icon="linkIcon" />
        </IconWrapper>
      </IconsRow>

      <Title>
        Создать обращение через телеграм
      </Title>
    </Wrapper>
  )
}

const IconWrapper = styled.div`
  flex-shrink: 0;
  color: ${palette.grey60};
  transition: color 0.15s linear;
`

const Wrapper = styled.a`
  ${WrapperStyle}
  gap: 12px;

  &:hover {
    ${IconWrapper} {
      color: ${palette.grey100};
    }
  }
`

const IconsRow = styled.header`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`

const Title = styled.div`
  ${TextL}
`
