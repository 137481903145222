import { attach } from 'effector'
import { createForm } from 'effector-forms'

import {
  FundingSortColumn,
  FundingSortCriteria,
  getPopupDataReqFx,
  loadPlanWorksReqFx,
  ResponsePopupDataItem,
  SetFundingWorkVolumePayload,
  putPlanWorkVolumeReqFx,
  WorkPlanItem,
  getAnalystReqFx,
  GetAnalystResponse,
  FUNDING_WORKS_BY_LIST,
  FetchFundingWorkPayload,
} from '@/dal'

import { createPagination } from '@/features/shared/createPagination'

import { d } from './domain'

export const $isShowNoteCell = d.store<boolean>(false)
export const toggleShowNoteCell = d.event<void>()

export const $analystArticleItems = d.store<GetAnalystResponse |null>(null)

export const $planWorkList = d.store<WorkPlanItem[]>([])
export const onPaginate = d.event<void>()

export const {
  $hasMore,
  $offset,
  $total: $planWorkListTotal,
  initEffect: loadPlanWorksFx,
  paginationEffect: paginatePlanWorksFx,
} = createPagination<FetchFundingWorkPayload, WorkPlanItem[]>({
  fetchEffect: loadPlanWorksReqFx,
  domain: d,
  limit: FUNDING_WORKS_BY_LIST,
})

export const $sortPlanWorkListCriteria = d.store<FundingSortCriteria>({
  direction: 'asc',
  column: 'id',
})

export const changePlanWorkListSort = d.event<FundingSortColumn>()
export const clearPlanFilter = d.event()

export const $planSearchText = d.store('')
export const setPlanWorkListSearchText = d.event<string>()
export const resetSearchText = d.event()

export const planSearchWorks = d.event()

export const setPlanWorkVolume = d.event<SetFundingWorkVolumePayload>()
export const setPlanWorkVolumeFx = attach({
  effect: putPlanWorkVolumeReqFx,
})

export const getPopupData = d.event<number>()
export const $popupData = d.store<ResponsePopupDataItem['detail']>([])
export const setPopupData = d.event<ResponsePopupDataItem['detail']>()
export const resetPopupData = d.event()

export const onWorkInputError = d.event<void>()

export const filterWorkForm = createForm({
  fields: {
    forecast: {
      init: null as string | null,
    },
    analystArticle: {
      init: null as string | null,
    },
    residue: {
      init: null as string | null,
    },
    plannedMonth: {
      init: null as string | null,
    },
  },
})

export const getPopupDataFx = attach({
  effect: getPopupDataReqFx,
})

export const getAnalystFx = attach({
  effect: getAnalystReqFx,
})
