import React from 'react'
import styled from 'styled-components'

import {
  ButtonType, HeadXL, NewButton, TextLLight,
} from '@/ui'

type Props = {
  title: string
  description?: string
  imgUrl: string
  buttonLabel: string
  buttonType?: ButtonType
  onClickButton: () => void
}

export const InfoStep = ({
  title, description, imgUrl, buttonLabel, buttonType, onClickButton,
}: Props) => (
  <Wrapper>
    <ImgWrapper>
      <Img src={imgUrl} alt="" />
    </ImgWrapper>
    <TextWrapper>
      <Title>
        {title}
      </Title>
      {Boolean(description) && (
        <Description>
          {description}
        </Description>
      )}
    </TextWrapper>
    <NewButton label={buttonLabel} buttonType={buttonType} dataTestId="survey-modal-btn" isFill onClick={onClickButton} />
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px;
`

const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
`

const Img = styled.img`
  display: block;
  width: 100%;
`

const TextWrapper = styled.div``

const Title = styled.p`
  ${HeadXL}
  text-align: center;
`

const Description = styled.p`
  ${TextLLight}
  margin-top: 8px;
  text-align: center;
`
