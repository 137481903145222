import {
  Domain,
  Effect,
  attach,
  sample,
} from 'effector'
import { AxiosError } from 'axios'

import { root } from '@/root-domain'

type Params<P, R, E> = {
  effect: Effect<P, R, E>
  domain?: Domain
  ms: number
}

export const createPollingEffect = <P, R, E = AxiosError>({
  effect,
  domain,
  ms,
}: Params<P, R, E>) => {
  const d = domain || root.domain()
  const attachedEffectFx = attach({ effect })

  const $intervalId = d.store<NodeJS.Timeout | null>(null)

  const startPollingFx = d.effect<P, NodeJS.Timeout>()
  const stopPollingFx = d.effect<NodeJS.Timeout, void>()
  const startPolling = d.event<P>()
  const stopPolling = d.event<void>()

  $intervalId
    .on(startPollingFx.doneData, (_, id) => id)
    .reset(stopPollingFx.done)

  sample({
    clock: startPolling,
    source: $intervalId,
    filter: (id) => !id,
    fn: (_, params) => params,
    target: startPollingFx,
  })

  sample({
    clock: stopPolling,
    source: $intervalId,
    filter: (id: NodeJS.Timeout | null): id is NodeJS.Timeout => Boolean(id),
    target: stopPollingFx,
  })

  startPollingFx.use((params) => {
    const id = setTimeout(() => {
      attachedEffectFx(params)
      startPollingFx(params)
    }, ms)
    return id
  })

  stopPollingFx.use((id) => {
    clearTimeout(id)
  })

  return {
    startPolling,
    stopPolling,
    effect: attachedEffectFx,
  }
}
