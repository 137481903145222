import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useGate } from 'effector-react'

import { AuthLayout } from '@/ui'
import { ChangePasswordForm } from '../containers'
import { ChangePasswordGate } from '../../model/change-private'

export const ChangePassword = React.memo(() => {
  const { token } = useRouteMatch<{ token: string }>().params
  useGate(ChangePasswordGate, token)

  return (
    <AuthLayout title="Восстановление пароля">
      <ChangePasswordForm />
    </AuthLayout>
  )
})
