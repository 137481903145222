import { sample } from 'effector'

import { createToast } from '@/features/toast-service/model'

import {
  $docFilesInfo,
  $isDocFilesModalOpen,
  closeDocFilesModal,
  getDocumentFiles,
  getDocumentFilesFx,
  openDocFilesModal,
} from './private'
import { onDocumentFilesLoaded } from './public'

$docFilesInfo
  .on(getDocumentFilesFx.doneData, (_, info) => info)
  .reset(openDocFilesModal)

$isDocFilesModalOpen
  .on(openDocFilesModal, () => true)
  .reset(closeDocFilesModal)

sample({
  clock: getDocumentFiles,
  target: [getDocumentFilesFx, openDocFilesModal],
})

sample({
  clock: getDocumentFilesFx.failData,
  target: closeDocFilesModal,
})

sample({
  clock: getDocumentFilesFx.done,
  target: onDocumentFilesLoaded,
})

createToast({
  effect: getDocumentFilesFx,
})
