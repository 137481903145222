import { attach } from 'effector'

import { createEstimateCorrectionReqFx } from '@/dal'

import { d } from './domain'

export const onConfirmCreateKorDoc = d.event<void>()

export const createKorDocFx = attach({
  effect: createEstimateCorrectionReqFx,
})

export const onCopyKorDoc = d.event<void>()
