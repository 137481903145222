import React from 'react'
import styled from 'styled-components'
import { useGate } from 'effector-react'

import {
  ContentContainer,
  FlexScrollbar,
  HeadXL,
  ServiceLink,
  TextL,
  TextLLight,
  palette,
} from '@/ui'
import { SupportFilters } from '@/features/support/filters'
import { RequestHistory } from '@/features/support/request-history'
import { SupportAppealsGate } from '@/features/support/model'

export const SupportPage = () => {
  useGate(SupportAppealsGate)

  return (
    <FlexScrollbar>
      <ContentContainer>
        <Container>
          <Title>Обращения</Title>

          <Cols>
            <Col>
              <RequestHistory />
            </Col>

            <RightCol>
              <SupportFilters />

              <Contacts>
                <ContactsHeader>
                  <p>Контакты</p>
                  <ContactsSubtitle>
                    Отвечаем в рабочие дни с 9:00 до 18:00
                  </ContactsSubtitle>
                </ContactsHeader>

                <Phone>
                  +7 (495) 660-15-55, доб.: 4444, нажмите 3
                </Phone>

                {/* TODO SUPPORT тестовый бот */}
                {/* TODO! скрытие */}
                {/* <Link target="_blank" href="https://t.me/LKPHelperBot">
                  <ServiceLink
                    text="Telegram"
                    icon="telegram"
                    linkStyle="secondary"
                    dataTestId="service-link-telegram"
                  />
                </Link> */}
              </Contacts>
            </RightCol>
          </Cols>
        </Container>
      </ContentContainer>
    </FlexScrollbar>
  )
}

const Title = styled.h1`
  ${HeadXL}
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-grow: 1;
`

const Cols = styled.div`
  display: flex;
  gap: 16px;
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
  min-width: 0;
`

const RightCol = styled(Col)`
  position: sticky;
  top: 20px;
  width: 332px;
  height: fit-content;
  flex-grow: initial;
  flex-shrink: 0;
`

const Phone = styled.p`
  ${TextLLight}
`

const Link = styled.a`
  text-decoration: none;
`

const Contacts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  border-radius: 12px;
  background-color: ${palette.white};
`

const ContactsHeader = styled.div`
  ${TextL}
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const ContactsSubtitle = styled.div`
  ${TextLLight}
  color: ${palette.grey60};
`
