import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { PaginationItem, SimpleLoader, themeVar } from '@/ui'
import { useEnterButtonNavigate } from '@/lib/useEnterButtonNavigate'
import {
  $hasMore,
  $planWorkList,
  loadPlanWorksFx,
  onPaginate,
  paginatePlanWorksFx,
} from '../../model/private'
import { WorkListItem } from './WorkListItem'

export const WorkListBody = React.memo(() => {
  const planWorkList = useUnit($planWorkList)
  const hasMore = useUnit($hasMore)
  const loadPlanPending = useUnit(loadPlanWorksFx.pending)
  const isNextPageLoading = useUnit(paginatePlanWorksFx.pending)
  const { index, setIndex } = useEnterButtonNavigate()

  return (
    <>
      {loadPlanPending && (
        <LoaderContainer>
          <SimpleLoader />
        </LoaderContainer>
      )}
      {planWorkList.length > 0
        && !loadPlanPending
        && planWorkList.map(
          (
            {
              work,
              cost_in_acts_past_count,
              cost_in_estimate,
              plan_past_count,
              delta_forecast,
              id,
              saldo_count,
              actual_plan_count_declarated,
              forecast,
              actual_plan_declarated,
              reasons_rejection,
              actual_plan_count_declarated_approved,
              show_actual_plan_count_declarated_approved,
            },
            itemIndex,
          ) => (
            <WorkListItem
              id={id}
              key={id}
              name={work.name}
              code={work.code_1c}
              planPast={plan_past_count}
              forecast={forecast}
              deltaForecast={delta_forecast}
              unit={work.unit}
              costInEstimate={cost_in_estimate}
              costActInPast={cost_in_acts_past_count}
              saldoCount={saldo_count}
              buildingName={work.building?.name}
              onInputFocus={() => setIndex(itemIndex)}
              priceAll={work.price_all}
              priceMaterial={work.price_material}
              priceWork={work.price_work}
              note={work.note}
              articleOutlayName={work.article_outlay?.name ?? null}
              totalPlanSum={actual_plan_declarated}
              actualPlanCountDeclarated={actual_plan_count_declarated}
              reasons_rejection={reasons_rejection}
              actualPlanCountDeclatedApproved={actual_plan_count_declarated_approved}
              showActualPlanCountDeclaratedApproved={
                show_actual_plan_count_declarated_approved
              }
              isLast={planWorkList.length - 1 === itemIndex}
              isSelected={index === itemIndex}
            />
          ),
        )}
      {hasMore && (
        <PaginationItem
          onLoadMore={onPaginate}
          isLoading={isNextPageLoading}
        >
          <SimpleLoader sizePx={25} />
        </PaginationItem>
      )}
    </>
  )
})

const LoaderContainer = styled.div`
  background-color: ${themeVar('actEditTableBgColor')};
  box-sizing: border-box;
  padding: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
