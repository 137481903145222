import React, { memo } from 'react'
import { useUnit } from 'effector-react'

import { SelectItemButton } from '@/ui'
import { createAttachmentsModalView } from '@/features/factories/attach-modal'
import { $canSendFiles, $documentAttachmentsCount, attachmentsModel } from '@/features/estimate-lot/model'
import { openAttachmentsModal } from '@/features/estimate-lot/model/private'

const AttachmentsModalView = createAttachmentsModalView(attachmentsModel)

export const LotAttachments = memo(() => {
  const [count, canSendFiles] = useUnit([
    $documentAttachmentsCount,
    $canSendFiles,
  ])

  return (
    <>
      <SelectItemButton
        text="Мои вложения"
        additionalText={String(count || 0)}
        icon={canSendFiles ? 'plusWithCircle' : 'arrowRightM'}
        buttonStyle={canSendFiles ? 'secondary' : 'primary'}
        hasBorder={false}
        dataTestId="estimate-lot-open-attachments-btn"
        onClick={openAttachmentsModal}
      />
      <AttachmentsModalView />
    </>
  )
})
