import React from 'react'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import styled from 'styled-components'

import { mergeRefs } from '@/lib/mergeRefs'
import { BaseInputStyle } from './BaseInputStyle'
import { InputWrapper } from './InputWrapper'
import { DefaultInputProps } from './types'

type Props = DefaultInputProps & {
  allowNegative?: boolean
  decimalScale?: number
  thousandSeparator?: string
  decimalSeparator?: string
  format?: string
  allowEmptyFormatting?: boolean
  mask?: string
  isAllowed?: (val: NumberFormatValues) => boolean
  textAlign?: string
  allowedDecimalSeparators?: string[]
}

export const InputNumber = ({
  onChange,
  onClick,
  onBlur,
  onClear,
  disabled,
  fontSize,
  isPointer,
  noOpenOnClear = false,
  dataTestId,
  allowNegative,
  decimalScale,
  placeholder,
  value,
  asDropdown,
  error,
  errorText,
  icon,
  iconColor = 'gray4',
  isErrorSoft,
  label,
  labelColor,
  labelSize,
  noNumberStyle,
  required,
  tooltip,
  inputStyle = 'second',
  thousandSeparator = '',
  decimalSeparator = '.',
  hiddenError,
  postFixIcon,
  onIconClick,
  onPostFixIconClick,
  isAllowed,
  allowEmptyFormatting,
  format,
  mask,
  hoverIconColor = 'main',
  elementRef,
  padding,
  textAlign = 'left',
  onFocus,
  allowedDecimalSeparators,
}: Props) => {
  const inputRef = React.createRef<HTMLInputElement>()

  const handleClick = () => {
    if (onClick) {
      onClick()
    }
    inputRef.current?.focus()
  }

  const handleClear = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (noOpenOnClear) {
      e.stopPropagation()
    }
    onClear?.()
  }

  return (
    <InputWrapper
      type={inputStyle}
      onClear={onClear ? handleClear : undefined}
      disabled={disabled}
      asDropdown={asDropdown}
      onClick={handleClick}
      error={error}
      errorText={errorText}
      hasVal={Boolean(value)}
      icon={icon}
      iconColor={iconColor}
      isErrorSoft={isErrorSoft}
      label={label}
      labelColor={labelColor}
      labelSize={labelSize}
      noNumberStyle={noNumberStyle}
      required={required}
      tooltip={tooltip}
      isPointer={isPointer}
      hiddenError={hiddenError}
      onIconClick={onIconClick}
      onPostFixIconClick={onPostFixIconClick}
      postFixIcon={postFixIcon}
      dataTestId={dataTestId}
      hoverIconColor={hoverIconColor}
      padding={padding}
    >
      <Input
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disabled}
        readOnly={disabled}
        getInputRef={mergeRefs([elementRef ?? null, inputRef])}
        placeholder={placeholder}
        thousandSeparator={thousandSeparator}
        value={value}
        onValueChange={(e) => onChange(e.value)}
        decimalSeparator={decimalSeparator}
        allowNegative={Boolean(allowNegative)}
        decimalScale={decimalScale}
        fontSize={fontSize}
        isPointer={isPointer}
        data-testid={dataTestId}
        format={format}
        mask={mask}
        allowEmptyFormatting={Boolean(allowEmptyFormatting)}
        isAllowed={isAllowed}
        isNumericString
        textAlign={textAlign}
        allowedDecimalSeparators={allowedDecimalSeparators}
      />
    </InputWrapper>

  )
}

const Input = styled(NumberFormat)`
  ${BaseInputStyle}
`
