import { createForm } from 'effector-forms'
import { createGate } from 'effector-react'

import { rules } from '@/lib/form-validators'
import { $isAuthorizedUserDataPending } from '@/features/login/model'

import { d } from './domain'

export const EditProfileGate = createGate('EditProfile')

export const profileForm = createForm({
  domain: d,
  validateOn: ['change', 'submit'],
  fields: {
    fullName: {
      init: '',
      rules: [rules.required(), rules.fullName()],
    },
    phone: {
      init: '',
      rules: [rules.required(), rules.phoneNumber()],
    },
    position: {
      init: '',
      rules: [rules.required()],
    },
    email: {
      init: '',
      rules: [rules.required(), rules.email()],
    },
  },
})

export const $isFormPending = $isAuthorizedUserDataPending.map((isPending) => isPending)
