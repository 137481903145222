import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import {
  ContractDirectionLabel,
  HeadL,
  Icon2,
  NewButton,
  Switch,
  palette,
} from '@/ui'
import { RouterPaths } from '@/features/app/model'
import { $contractDirections } from '@/features/administrative/contract-list/model'
import { ContractVisibility } from '@/dal'

import {
  $contract,
  $hasActions,
  changeVisibilityFx,
  onOpenCreateDocumentModal,
  toggleVisibility,
} from '../../../model/private'

const $headerInfo = $contract.map((contract) => (contract ? {
  name: contract.name,
  isChecked: contract.visibility === ContractVisibility.hidden,
  canChangeVisibility: Boolean(contract.features.can_change_visibility),
  directionType: contract.direction,
} : null))

export const ContractHeader = React.memo(() => {
  const [info, hasActions, directions] = useUnit([
    $headerInfo,
    $hasActions,
    $contractDirections,
  ])
  const isVisibilityChanging = useUnit(changeVisibilityFx.pending)

  if (!info) return null

  return (
    <Wrapper>

      <Col>
        <TitleWrapper>
          <GoBack to={RouterPaths.Administrative}>
            <Icon2
              icon="arrowLeft"
              size={24}
              color="grey90"
            />
            <Title>
              {info.name}
            </Title>
          </GoBack>

          <DirectionWrapper>
            <ContractDirectionLabel type={info.directionType} directions={directions} size="M" />
          </DirectionWrapper>
        </TitleWrapper>

        {info.canChangeVisibility && (
          <Switch
            label="Скрыть договор"
            disabled={isVisibilityChanging}
            isChecked={info.isChecked}
            onChange={toggleVisibility}
            dataTestId="change-visibility"
          />
        )}
      </Col>

      {hasActions && (
        <NewButton
          label="Создать документ"
          dataTestId="open-create-document-modal"
          buttonType="grey"
          prefixIcon="plusButton"
          size="S"
          onClick={onOpenCreateDocumentModal}
        />
      )}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  background-color: ${palette.white};
  display: flex;
  justify-content: space-between;
  padding: 14px 20px;
  border-radius: 20px;
`

const GoBack = styled(Link)`
  display: flex;
  gap: 6px;
  align-items: center;
`

const Col = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`

const TitleWrapper = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`

const Title = styled.h1`
  ${HeadL}
  padding-top: 3px;
`

const DirectionWrapper = styled.div`
  padding-top: 3px;
`
