import { useUnit, useStoreMap } from 'effector-react'
import React from 'react'

import { ConfirmModalLayout } from '@/ui'

import {
  $deleteTargetId,
  onConfirmDelete,
  deleteTableItemFx,
  rejectDelete,
} from '../../../model/delete-table-item.private'
import { $flatItems } from '../../../model/private'

export const DeleteItemConfirm = React.memo(() => {
  const targetId = useUnit($deleteTargetId)
  const isPending = useUnit(deleteTableItemFx.pending)

  const isFolder = useStoreMap({
    store: $flatItems,
    keys: [targetId],
    fn: (items, [id]) => items?.find((item) => item.id === id)?.is_folder || false,
  })

  const title = isFolder ? 'Удаление папки' : 'Удаление строки'

  const bodyText = isFolder
    ? 'Все содержимое папки будет удалено вместе с ней. Удалить папку?'
    : 'Вы действительно хотите удалить строку?'

  if (!targetId) return null

  return (
    <ConfirmModalLayout
      title={title}
      cancelLabel="Отменить"
      confirmLabel="Да, удалить"
      dataTestId="delete-confirm"
      isPending={isPending}
      body={bodyText}
      closeModal={rejectDelete}
      isOpen={Boolean(targetId)}
      onCancel={rejectDelete}
      onConfirm={onConfirmDelete}
    />
  )
})
