import React from 'react'
import styled from 'styled-components'
import { ConnectedFields } from 'effector-forms'

import {
  DropArea, InputField, NewFileItem, TextAreaField,
} from '@/ui'

type Props<T> = {
  children: React.ReactNode
  fields: T
  isDisabled?: boolean
}

type BaseFields = {
  subject: string
  description: string
  files: File[]
}

export const BaseFormFieldsWithChildren = <T extends ConnectedFields<BaseFields>>({
  children,
  fields,
  isDisabled,
}: Props<T>) => {
  const onFiles = (files: File[]) => {
    fields.files.onChange([...fields.files.value, ...files])
  }

  const onDeleteFile = (fileName: string) => {
    const files = fields.files.value.filter(({ name }) => fileName !== name)
    fields.files.onChange(files)
  }

  return (
    <Wrapper>
      {children}

      <InputField
        value={fields.subject.value}
        error={fields.subject.firstError?.errorText}
        label="Тема"
        placeholder="Введите тему обращения"
        size="L"
        disabled={isDisabled}
        dataTestId="appeal-subject-input"
        isRequired
        onChange={fields.subject.onChange}
        onClear={() => fields.subject.set('')}
      />

      <TextAreaField
        value={fields.description.value}
        error={fields.description.firstError?.errorText}
        label="Текст обращения"
        placeholder="Введите текст..."
        disabled={isDisabled}
        height={100}
        dataTestId="appeal-description-textarea"
        required
        onChange={fields.description.onChange}
      />

      <FilesContainer>
        <DropArea isDisabled={isDisabled} onFiles={onFiles} />
        {fields.files.value.length ? (
          <FileList>
            {/* TODO мб что-то лучше name в качестве ключа */}
            {fields.files.value.map(({ name }) => (
              <NewFileItem key={name} size="M" name={name} onDelete={onDeleteFile} isDense />
            ))}
          </FileList>
        ) : null}
      </FilesContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 8px;
`

const FileList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
