import React from 'react'
import styled, { css } from 'styled-components'

import { palette } from '../palette'
import { TextMLight } from '../typography'

type Props = {
  isChecked: boolean
  disabled?: boolean
  label: string
  dataTestId?: string
  onChange: () => void
}

export const Switch = ({
  isChecked,
  disabled,
  label,
  dataTestId,
  onChange,
}: Props) => (
  <Wrapper disabled={disabled}>
    <HiddenCheckbox
      onChange={() => onChange()}
      disabled={disabled}
      checked={Boolean(isChecked)}
      data-testid={dataTestId}
    />
    <SwitchWrapper>
      <SwitchCircle />
    </SwitchWrapper>
    {label}
  </Wrapper>
)

const HiddenCheckbox = styled.input.attrs({
  type: 'checkbox',
})`
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  z-index: -1;
`

const SwitchCircle = styled.div`
  border-radius: 50%;
  width: 18px;
  height: 18px;
  transform: translate(-1px, -1px);
  transition: 0.15s linear;
  background-color: ${palette.white};
  border: 1px solid transparent;
  transition-property: transform, border-color;
`

const TRACK_WIDTH = 32

const SwitchWrapper = styled.div`
  width: ${TRACK_WIDTH}px;
  height: 18px;
  border-radius: 20px;
  background-color: ${palette.grey10};
  border: 1px solid ${palette.grey50};
  flex-shrink: 0;

  display: flex;
  justify-content: flex-start;
  transition: 0.15s linear;
  transition-property: border-color, background-color;

  ${SwitchCircle} {
    border-color: ${palette.grey50};
  }

  ${HiddenCheckbox}:disabled + & {
    background-color: ${palette.grey30};

    &, ${SwitchCircle} {
      border-color: ${palette.grey40};
    }
  }

  ${HiddenCheckbox}:disabled:checked + & {
    background-color: ${palette.accent40};

    &, ${SwitchCircle} {
      border-color: ${palette.accent40};
    }
  }

  ${HiddenCheckbox}:checked + & {
    background-color: ${palette.accent100};
    border-color: ${palette.accent100};

    ${SwitchCircle} {
      border-color: ${palette.accent100};
      transform: translate(${TRACK_WIDTH / 2 - 3}px, -1px);
    }
  }

  ${HiddenCheckbox}:focus-visible + & {
    ${SwitchCircle} {
      border: 2px solid ${palette.grey80};
    }
  }
`

const Wrapper = styled.label<{ disabled?: boolean }>`
  display: flex;
  position: relative;
  cursor: pointer;
  align-items: center;
  gap: 6px;
  user-select: none;

  ${TextMLight}

  ${({ disabled }) => disabled && css`
    cursor: not-allowed;
  `}

  @media (hover: hover) {
    &:hover {
      ${HiddenCheckbox}:not(:disabled) ~ ${SwitchWrapper} {
        background-color: ${palette.grey20};

        &, ${SwitchCircle} {
          border-color: ${palette.grey60};
        }
      }

      ${HiddenCheckbox}:not(:disabled):checked ~ ${SwitchWrapper} {
        background-color: ${palette.accent90};

        &, ${SwitchCircle} {
          border-color: ${palette.accent90};
        }
      }
    }
  }

  &:active {
    ${HiddenCheckbox}:not(:disabled):not(:checked) ~ ${SwitchWrapper} {
      background-color: ${palette.grey50};
      border-color: ${palette.grey60};

      &, ${SwitchCircle} {
        border-color: ${palette.grey60};
      }
    }

    ${HiddenCheckbox}:not(:disabled):checked ~ ${SwitchWrapper} {
      background-color: ${palette.accent80};

      &, ${SwitchCircle} {
        border-color: ${palette.accent80};
      }
    }
  }
`
