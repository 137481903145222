import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { noop } from '@/lib/noop'
import { RouterPaths } from '@/features/app/model'

import { TextL, TextMLight, palette } from '@/ui'
import { UserPopupButton } from './UserPopupButton'

type Props = {
  userName: string,
  email: string
  onLogout: () => void
}

export const UserProfilePopup = ({
  email,
  onLogout,
  userName,
}: Props) => (
  <Wrapper>

    <UserProfileInfo>
      <UserName>
        {userName}
      </UserName>

      <UserContact>
        {email}
      </UserContact>
    </UserProfileInfo>


    <Link to={RouterPaths.UserInfo}>
      <UserPopupButton
        icon="userInfo"
        label="Личные данные"
        dataTestId="user-info-btn"
        onClick={noop}
      />
    </Link>

    <UserPopupButton
      icon="logout"
      label="Выйти"
      dataTestId="button-user-logout"
      onClick={onLogout}
    />
  </Wrapper>
)

const Wrapper = styled.div`
  width: 300px;
  padding: 8px 0px;
  border-radius: 8px;
  background-color: ${palette.white};
  box-shadow: 0px 2px 10px 0px ${palette.shadow};
`

const UserProfileInfo = styled.address`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-grow: 1;
  word-break: break-word;
  padding: 8px 16px;
`

const UserName = styled.p`
  ${TextL}
`

const UserContact = styled.p`
  ${TextMLight}
  color: ${palette.grey70};
`
