import React from 'react'
import styled from 'styled-components'

import {
  HeadXL,
  Icon2,
  ModalLayoutV2,
  NewButton,
  TextLLight,
} from '@/ui'


type Props = {
  type?: 'contragent' | 'bankAccount'
  onClose: () => void
}

export const Success = ({ type = 'contragent', onClose }: Props) => {
  return (
    <ModalLayoutV2
      closeModal={onClose}
    >
      <Wrapper>
        <TopContent>
          <Icon2 icon="successIcon" size={50} />
          <TextWrapper>
            <Title>
              Заявка на
              {' '}
              {type === 'contragent' ? 'создание нового контрагента' : 'добавление расчетного счета'}
              {' '}
              отправлена
            </Title>
            <SubTitle>
              Когда
              {' '}
              {type === 'contragent' ? 'новый контрагент' : 'расчетный счет'}
              {' '}
              будет добавлен в систему,
              мы пришлем вам оповещение на почту и вы сможете закончить работу с документом.
            </SubTitle>
          </TextWrapper>
        </TopContent>
        <NewButton
          dataTestId="close-create-contragent"
          label="ОК"
          onClick={onClose}
          isFill
        />
      </Wrapper>
    </ModalLayoutV2>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 500px;
  padding: 40px 32px 32px;
`

const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

const Title = styled.div`
  ${HeadXL}
  text-align: center;
`

const SubTitle = styled.div`
  ${TextLLight}
  text-align: center;
`
