import { attachWrapper } from '@42px/effector-extra'
import { AxiosError } from 'axios'

import { authRequestFx, Method } from '../request'
import {
  AddLotWorkMaterialsPayload,
  CatalogMaterial,
  CatalogMaterialGroup,
  DeleteLorWorkMaterialPayload,
  DistributeAmountsPayload,
  GetLotWorkMaterials,
  GetLotWorkStructureParams,
  LotWorkFiltersResponse,
  LotWorkStructureResponse,
  UpdateAdditionalMaterialPayload,
  UpdateLotWorkValuesPayload,
  WorkTableItem,
} from './types'
import { AbortableRequest } from '../interfaces'
import { defaultErrorHandler } from '../error-handler'

export const getLotWorkStructureReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({
    signal, id, workId, ...query
  }: AbortableRequest<GetLotWorkStructureParams>) => ({
    method: Method.get,
    url: `/estimate_lots/${id}/construction_objects_structure/${workId}/`,
    signal,
    query,
  }),
  mapResult: ({ result }): LotWorkStructureResponse => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const updateLotWorkValuesReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, items }: UpdateLotWorkValuesPayload) => ({
    method: Method.put,
    url: `/estimate_lots/${id}/element/bulk_update/`,
    body: {
      items,
    },
  }),
  mapResult: ({ result }): { items: WorkTableItem[] } => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const updateAdditionalMaterialCommentReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, comment }: UpdateAdditionalMaterialPayload) => ({
    method: Method.patch,
    url: `/estimate_lots/lot_element/${id}/update/`,
    body: {
      comment,
    },
  }),
  mapResult: ({ result }): string => result.data.comment,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const getLotWorkMaterialGroupsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({
    signal, id, workId, ...query
  }: AbortableRequest<GetLotWorkStructureParams>) => ({
    method: Method.get,
    url: `/estimate_lots/ker_of_lot/${workId}/groups/`,
    signal,
    query,
  }),
  mapResult: ({ result }): CatalogMaterialGroup[] => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const getLotWorkMaterialsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({
    signal, workId, ...query
  }: AbortableRequest<GetLotWorkMaterials>) => ({
    method: Method.get,
    url: `/estimate_lots/ker_of_lot/${workId}/materials/`,
    signal,
    query,
  }),
  mapResult: ({ result }): CatalogMaterial[] => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

// TODO
export const addLotWorkMaterialsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ workId, materials }: AddLotWorkMaterialsPayload) => ({
    method: Method.post,
    url: `/estimate_lots/ker_of_lot/${workId}/materials/add/`,
    body: materials,
  }),
  mapResult: ({ result }): void => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const deleteLotWorkMaterialReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, rowId, isEverywhere }: DeleteLorWorkMaterialPayload) => ({
    method: Method.delete,
    url: `/estimate_lots/${id}/element/${rowId}/`,
    body: {
      delete_in_work: isEverywhere,
    },
  }),
  mapResult: ({ result }): void => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const getLotWorkFiltersReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({
    signal, id, workId, ...query
  }: AbortableRequest<GetLotWorkStructureParams>) => ({
    method: Method.get,
    url: `/estimate_lots/${id}/construction_objects_structure/${workId}/filters/`,
    signal,
    query,
  }),
  mapResult: ({ result }): LotWorkFiltersResponse => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const resetLotWorkRowReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, rowId }: { id: number, rowId: string }) => ({
    method: Method.put,
    url: `/estimate_lots/${id}/element/bulk_update/`,
    body: {
      items: [{
        id: rowId,
        count: 0,
        price: 0,
        total_cost: 0,
      }],
    },
  }),
  mapResult: ({ result }): WorkTableItem[] => result.data.items,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const distributeLotWorkAmountsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ rowId, ...body }: DistributeAmountsPayload) => ({
    method: Method.put,
    url: `/estimate_lots/fill_volumes/${rowId}/`,
    body,
  }),
  mapResult: ({ result }): WorkTableItem[] => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})
