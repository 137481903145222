import { attachWrapper } from '@42px/effector-extra'
import { AxiosError } from 'axios'

import { Method, authRequestFx } from '../request'
import { Response } from '../interfaces'
import { ResetPasswordPayload } from './types'
import { DefaultErrorResponse, defaultErrorHandler } from '../error-handler'

export const sendRestorePasswordReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (email: string) => ({
    method: Method.post,
    url: '/authorization/request-password-reset/',
    body: { email },
  }),
  mapResult: ({ result }: Response<void>) => result.data,
  mapError: ({ error }: { error: AxiosError<DefaultErrorResponse> }) => (
    defaultErrorHandler(error.response?.data)
  ),
})

export const checkPasswordTokenReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (token: string) => ({
    method: Method.post,
    url: '/authorization/reset-password/validate_token/',
    body: {
      token,
    },
  }),
  mapResult: ({ result }: Response<void>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const resetPasswordReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ password, token }: ResetPasswordPayload) => ({
    method: Method.post,
    url: '/authorization/reset-password/confirm/',
    body: {
      password, token,
    },
  }),
  mapResult: ({ result }: Response<void>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const repeatRegistrationLetterReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (email: string) => ({
    method: Method.post,
    url: '/authorization/request-reregistration-email/',
    body: {
      email,
    },
  }),
  mapResult: ({ result }: Response<void>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})
