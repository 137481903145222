import { useUnit } from 'effector-react'
import React from 'react'
import { Scrollbar } from 'react-scrollbars-custom'
import styled from 'styled-components'

import { PDFFileItem } from '@/ui'
import { Store } from 'effector'

export type CreateFilesListParams = {
  $files: Store<{file: string, name: string}[]>
}

export const createFileList = ({
  $files,
}: CreateFilesListParams) => React.memo(() => {
  const files = useUnit($files)

  return (
    <FileWrapper>
      <Scrollbar>
        <ContentWrapper>
          {files?.map(({ file, name }) => (
            <PDFFileItem key={file} fileName={name} file={file} />
          ))}
        </ContentWrapper>
      </Scrollbar>
    </FileWrapper>
  )
})


const FileWrapper = styled.div`
  margin-top: 20px;
  height: 200px;
`

const ContentWrapper = styled.div`
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`
