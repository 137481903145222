import { SupportAppealsFilters } from '@/dal'
import { createFilters } from '@/features/shared/filterFactory/createFilters'

import { d } from './domain'

export const {
  $filters,
  removeFilter,
  setFilter,
  clearFilter,
  $hasAcceptedFilters,
} = createFilters<Omit<SupportAppealsFilters, 'status'>>({
  contragent_id: [],
  project_id: [],
  issue_type: [],
}, d)

export const setHasUpdatedFilters = d.event<boolean>()

export const acceptFilters = d.event()
