import { attachWrapper } from '@42px/effector-extra'
import { AxiosError, AxiosResponse } from 'axios'

import { Method, authRequestFx } from '../request'
import { defaultErrorHandler } from '../error-handler'
import { PutUserInfoParams, PutUserInfoResponse } from './type'

export const putUserInfoReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (params: PutUserInfoParams) => ({
    method: Method.put,
    url: '/user-info/',
    body: params,
  }),
  mapResult: ({ result }: {result: AxiosResponse<PutUserInfoResponse>}) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})
