import { attach } from 'effector'
import { createForm } from 'effector-forms'

import {
  countAdvanceRemainderReqFx,
  downloadApprovingDocumentsReqFx,
  postActAttachmentsReqFx,
  postActReportingDatesReqFx,
  setDeductionOfPaidAdvanceReqFx,
  updateActCommentReqFx,
} from '@/dal'
import { createAttachmentsModalModel } from '@/features/factories/attach-modal'
import { rules } from '@/lib/form-validators'
import { createSingleEffect } from '@/lib/createSingleEffect'

import { $documentFeatures, $documentInfo, ActGate } from './public'
import { d } from './domain'

export const $documentAttachmentsCount = $documentInfo
  .map((info) => info?.attachments?.length ?? null)

export const onOpenAttachmentsModal = d.event<void>()

export const onOpenAdditionalModal = d.event<void>()

export const $isReportingDatesModalOpen = d.store<boolean>(false)
export const openDateModal = d.event<void>()
export const closeDateModal = d.event<void>()

export const $isCompensationsModalOpen = d.store(false)
export const openCompensationsModal = d.event()
export const closeCompensationsModal = d.event()

export const onCountAdvanceRemainder = d.event<void>()
export const onSetDeductionOfPaidAdvance = d.event<number>()

export const $canSendFiles = $documentFeatures.map((features) => (
  Boolean(features?.can_add_attachments)),
)

export const sendFilesFx = attach({
  effect: postActAttachmentsReqFx,
})

export const getApproveDocumentFx = attach({
  effect: downloadApprovingDocumentsReqFx,
})

export const {
  requestFx: updateActCommentFx,
} = createSingleEffect(updateActCommentReqFx, { abortEvents: [ActGate.close] })

export const postActReportingDatesFx = attach({
  effect: postActReportingDatesReqFx,
})

export const countAdvanceRemainderFx = attach({
  effect: countAdvanceRemainderReqFx,
})

export const setDeductionOfPaidAdvanceFx = attach({
  effect: setDeductionOfPaidAdvanceReqFx,
})

export const attachmentsModel = createAttachmentsModalModel({
  domain: d,
  sendFx: sendFilesFx,
  $canSendFiles,
  $files: $documentInfo.map((info) => info?.attachments || null),
})

export const actForm = createForm({
  fields: {
    comment: {
      init: '',
    },
  },
})

export const actReportingDatesForm = createForm({
  fields: {
    date_start: {
      init: '',
    },
    date_end: {
      init: '',
      rules: [rules.required()],
    },
  },
})
