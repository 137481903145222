import { attach } from 'effector'

import {
  EstimateDoc,
  getEstimateInfoByIdReqFx,
  getKorDocDirectoriesReqFx,
  updateFeaturesInfoReqFx,
} from '@/dal'
import { createSingleEffect } from '@/lib/createSingleEffect'

import { d } from './domain'

export const {
  abortFx: abortGetDocumentFx,
  requestFx: getEstimateInfoByIdFx,
} = createSingleEffect(getEstimateInfoByIdReqFx)

export const {
  requestFx: getKorDocDirectoriesFx,
} = createSingleEffect(getKorDocDirectoriesReqFx)

export const updateFeaturesInfoFx = attach({ effect: updateFeaturesInfoReqFx })

export const updateSummary = d.event<EstimateDoc['summary_table']>()
