import React from 'react'
import styled from 'styled-components'
import { useGate } from 'effector-react'

import { HeadXL, palette } from '@/ui'

import {
  ContractFilter, CompanyList, ContractTableBody, ReportsModal,
} from '../containers'
import { ContractListGate } from '../../model/private'
import { ContractTableHeader } from '../parts'

export const Administrative = () => {
  useGate(ContractListGate)

  return (
    <Wrapper>
      <Header>
        Договоры
        {' '}
      </Header>

      <BodyWrapper>
        <CompanyList />

        <Card>
          <ContractFilter />
          <ContractTableHeader />
          <ContractTableBody />
        </Card>

      </BodyWrapper>

      <ReportsModal />
    </Wrapper>
  )
}


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
`

const Header = styled.div`
  ${HeadXL}
`

const BodyWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const Card = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${palette.white};
  border-radius: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`
