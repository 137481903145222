import { attach } from 'effector'

import { cancelPlanApproveReqFx } from '@/dal'
import { d } from './domain'

export const agreedPlanContractorCancelFx = attach({
  effect: cancelPlanApproveReqFx,
})

export const $showPlanAgreedContractorCancel = d.store(false)
export const closePlanAgreedContractorCancel = d.event()
export const sendPlanAgreedContractorCancel = d.event()

export const $reasonPlanAgreedContractorCancelText = d.store<string>('')
export const setPlanAgreedContractorCancelText = d.event<string>()
