import { useStoreMap, useUnit } from 'effector-react'
import { Link } from 'react-router-dom'
import React from 'react'
import styled, { css } from 'styled-components'
import { useField } from 'effector-forms'

import {
  ContractDirection, ContractItem, ContractType, DocumentAlias,
} from '@/dal'
import { palette } from '@/ui'
import { RouterPaths } from '@/features/app/model'
import { $isClosing } from '@/features/administrative/close-contract/model'

import {
  $pendingIds,
  onCreateDocument,
  onCloseButton,
  onGetRetentionButton,
  onChangeVisibility,
  openContractReports,
  onUpdateClosurePercentage,
  $pendingClosureUpdateIds,
  $updatedClosureIds,
} from '../../model/private'
import { BottomRow, TopRow } from '../parts'
import { $contractDirections } from '../../model'
import { filterForm } from '../../model/filters.private'

type Props = {
  contract: ContractItem | Omit<ContractItem, 'child'>
  isSubItem?: boolean
  isOpen?: boolean
}

export const ContractTableItem = React.memo(({
  contract,
  isSubItem,
  isOpen,
}: Props) => {
  const [isOnHover, setIsOnHover] = React.useState(false)

  const [isClosing, directions] = useUnit([$isClosing, $contractDirections])

  const isVisibilityPending = useStoreMap({
    store: $pendingIds,
    keys: [contract.id],
    fn: (list, [id]) => list.includes(id),
  })
  const isClosureUpdating = useStoreMap({
    store: $pendingClosureUpdateIds,
    keys: [contract.id],
    fn: (list, [id]) => list.includes(id),
  })
  const isClosureUpdateDisabled = useStoreMap({
    store: $updatedClosureIds,
    keys: [contract.id],
    fn: (list, [id]) => list.includes(id),
  })
  const { value: contractName } = useField(filterForm.fields.name)

  const onAction = React.useCallback((type: DocumentAlias) => {
    onCreateDocument({
      type,
      contractId: contract.id,
      contractName: contract.name,
      contractDirection: contract.direction,
      draft: contract.conflicting_draft_kor_doc,
      discardedDoc: contract.canceled_or_not_relevant_kor_doc,
    })
  }, [contract, onCreateDocument])

  const hasBottomRow = React.useMemo(() => (
    ![ContractType.ChangeConditions, ContractType.NotFinance].includes(contract.type.map_id)
  ), [contract])

  const hasActions = ![
    ContractDirection.BANK_WARRANTY,
    ContractDirection.CONTRACT_GUARANTEE,
  ].includes(contract.direction)

  return (
    <ContractLink to={`${RouterPaths.Administrative}/${contract.id}`}>
      <Container
        active={Boolean(isOpen || isSubItem)}
        onMouseEnter={() => setIsOnHover(true)}
        onMouseLeave={() => setIsOnHover(false)}
      >
        <TopRow
          isSubItem={Boolean(isSubItem)}
          contract={contract}
          searchValue={contractName}
          isOpen={Boolean(isOpen)}
          onCloseButton={onCloseButton}
          isClosing={isClosing}
          isRetentionPending={false}
          isClosureUpdating={isClosureUpdating}
          isClosureUpdateDisabled={isClosureUpdateDisabled}
          onGetRetention={onGetRetentionButton}
          onUpdateClosure={() => onUpdateClosurePercentage(contract.id)}
        />
        {hasBottomRow && (
          <BottomRow
            id={contract.id}
            visibility={contract.visibility}
            onCreateDocument={onAction}
            onChangeVisibility={onChangeVisibility}
            isVisibilityPending={isVisibilityPending}
            areActionsShown={isOnHover && hasActions}
            directionType={contract.direction}
            directions={directions}
            onOpenContractReports={openContractReports}
            features={{
              canCreateAct: contract.features.act.create,
              canCreateBudget: contract.features.budget.create,
              canCreateKorDoc: contract.features.kor_doc.create,
              canCreatePayment: contract.features.payment_document.create,
              canChangeVisibility: contract.features.can_change_visibility,
              canGenerateReport: contract.features.can_generate_report,
              canGoToAllDocuments: contract.features.can_get_all_documents,
            }}
          />
        )}
      </Container>
    </ContractLink>
  )
})
type ActiveItemProps = {
  active: boolean
}

const Container = styled.div<ActiveItemProps>`
  width: 100%;
  min-height: 78px;
  background-color: ${palette.white};
  transition: background-color 0.2s linear;
  color: ${({ active }) => (active ? palette.accent90 : palette.grey70)};
  cursor: pointer;

  ${({ active }) => active && css`
    background-color: ${palette.yellow10};  
  `}

  @media(hover: hover) {
    &:hover {
      background-color: ${palette.grey10};
      color: ${palette.accent90};

      & .contract__name {
        transition: color 0.2 linear;
        color: ${palette.accent100};
        text-decoration: underline;
      }
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${palette.grey20};
  }
`

const ContractLink = styled(Link)`
  &:not(:last-child) ${Container} {
    border-bottom: 1px solid ${palette.grey20};
  }

  &:last-child ${Container} {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
`
