import React from 'react'

import { PaletteColor } from '@/ui'
import { numberWithSpaces } from '@/lib/number-represent'

import { Cell } from './Cell'
import { TableRow } from './TableRow'
import { DiffCell } from './DiffCell'

type Props = {
  approveColor: PaletteColor
  title: string
  declared: string
  approved: string
  diff: string | null
}

export const SumsRow = ({
  approveColor, title, declared, approved, diff,
}: Props) => {
  return (
    <TableRow>
      <Cell isFill isUppercase>
        {title}
      </Cell>
      <Cell color="grey90">
        {numberWithSpaces(declared)}
      </Cell>
      <Cell>
        <Cell color={approveColor}>
          {numberWithSpaces(approved)}
        </Cell>
        {diff && (
          <DiffCell>
            {diff}
          </DiffCell>
        )}
      </Cell>
    </TableRow>
  )
}
