import React, { memo, useState } from 'react'
import styled, { css } from 'styled-components'
import { useUnit } from 'effector-react'

import {
  NewButton, NewTextButton, palette, TagButton, TextM,
} from '@/ui'

import {
  $isEditMode,
  $materials,
  $morphologyItemCount,
  addMaterialsModal,
  deleteMaterialModal,
} from '../../model/private'

export const LotWorkSelectedMaterials = memo(() => {
  const [isExpanded, setIsExpanded] = useState(false)

  const [materials, morphologyItemCount, isEditMode] = useUnit([
    $materials,
    $morphologyItemCount,
    $isEditMode,
  ])

  return (
    <Wrapper>
      <StepOne isGrey={isEditMode}>Шаг 1.</StepOne>

      <NewButton
        label="Добавить материалы"
        size="S"
        postfixIcon="plusWithCircle"
        isDisabled={isEditMode}
        dataTestId=""
        onClick={addMaterialsModal.open}
      />

      {Boolean(materials.length) && (
        <ExpandButtonWrapper>
          <NewTextButton
            label={isExpanded ? 'Свернуть материалы' : 'Развернуть материалы'}
            textStyle="M"
            postfixIcon={isExpanded ? 'arrowRightS' : 'arrowDown'}
            dataTestId=""
            onClick={() => setIsExpanded(!isExpanded)}
          />
        </ExpandButtonWrapper>
      )}

      {isExpanded && materials.map((item) => (
        <TagButton
          key={item.id}
          label={item.name}
          canClose={item.can_delete}
          count={item.count_in_lot}
          totalCount={morphologyItemCount}
          isDisabled={isEditMode}
          dataTestId=""
          onClose={() => deleteMaterialModal.open(item)}
        />
      ))}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
`

const StepOne = styled.p<{ isGrey: boolean }>`
  ${TextM}
  text-transform: uppercase;

  ${({ isGrey }) => isGrey && css`
    color: ${palette.grey70};
  `}
`

const ExpandButtonWrapper = styled.div`
  padding: 7px 8px;
`
