import { sample } from 'effector'

import { PaymentId } from '@/dal'
import { createToast } from '@/features/toast-service/model'

import {
  $isNewBankAccountSuccess,
  $isOpenCreateBankModal,
  closeCreateBank,
  createBankAccountForm,
  createBankAccountFx,
  openCreateBank,
} from './create-bank-private'
import { editPaymentForm } from './edit-private'
import { $paymentDocumentId } from './private'

$isOpenCreateBankModal
  .on(openCreateBank, () => true)
  .reset(closeCreateBank)

$isNewBankAccountSuccess
  .on(createBankAccountFx.done, () => true)
  .reset(closeCreateBank)

sample({
  clock: closeCreateBank,
  target: createBankAccountForm.reset,
})

sample({
  clock: createBankAccountForm.formValidated,
  source: { form: editPaymentForm.$values, id: $paymentDocumentId },
  filter: ({ form, id }) => Boolean(form.contragent && id),
  fn: ({ form, id }, fields) => ({
    inn: form.inn,
    payment: id as PaymentId,
    contragent_uuid: form.contragent as string,
    ...fields,
  }),
  target: createBankAccountFx,
})

createToast({
  effect: createBankAccountFx,
})
