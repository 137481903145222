import React from 'react'
import styled from 'styled-components'

export const LayoutGrid: React.FC = ({ children }) => {
  return (
    <Grid>
      {children}
    </Grid>
  )
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: 16px;
  flex-grow: 1;
`
