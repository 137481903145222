import React from 'react'
import styled from 'styled-components'

import {
  NewIconButton,
  NotificationItem,
  NotificationType,
} from '@/ui'

type Props = {
  hasDocs?: boolean,
  link?: string
}

export const WorkCompleted = ({
  hasDocs,
  link,
}: Props) => {
  return (
    <Wrapper>
      <NotificationItem isFill padding="8px" type={NotificationType.Success} body={<>Работы завершены</>} />
      {hasDocs && (
        <a href={link} target="_blank" rel="noreferrer">
          <NewIconButton
            dataTestId="download-closure-docs"
            icon="download"
            size={16}
            padding="10px"
            borderRadius="4px"
            backgroundColor="white"
            borderColor="grey40"
            color="grey90"
            hoverBackgroundColor="grey40"
          />
        </a>
      )}
    </Wrapper>
  )
}
const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`
