import React from 'react'
import { useUnit } from 'effector-react'
import styled from 'styled-components'

import { PaginationItem, SimpleLoader } from '@/ui'
import { onLoadMore } from '../../model/private'
import { $hasMore, loadMoreDocumentsFx } from '../../model'

export const DocumentPaginationItem = React.memo(() => {
  const isLoading = useUnit(loadMoreDocumentsFx.pending)
  const hasMore = useUnit($hasMore)

  return (
    hasMore
      ? (
        <PaginationWrapper>
          <PaginationItem
            onLoadMore={onLoadMore}
            isLoading={isLoading}
          >
            <SimpleLoader sizePx={24} />
          </PaginationItem>
        </PaginationWrapper>

      )
      : null
  )
})

const PaginationWrapper = styled.div`
  height: 24px;
  width: 100%;
`
