import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Scrollbars from 'react-custom-scrollbars-2'

import Logo from '@/ui/assets/LogoWhite.svg'
import bg from '@/ui/assets/login-bg.jpg'
import { RouterPaths } from '@/features/app/model'
import { WINDOW_WIDTH } from '@/ui/theming'

import { palette } from '../palette'
import { HeadXXL, TextLLight } from '../typography'
import { NewTextButton } from '../buttons'
import { SuccessComponent } from './SuccessComponent'

type Props = {
  contentMaxWidth?: number
  hasBackButton?: boolean
  title: string
  subText?: string
  isSuccess?: boolean
  successTitle?: string,
  successText?: string
  textAlign?: 'left' | 'center'
  contentMargin?: number
}

export const AuthLayout: React.FC<Props> = ({
  children,
  contentMaxWidth = 400,
  title,
  hasBackButton,
  successText,
  successTitle,
  isSuccess,
  subText,
  contentMargin = 24,
  textAlign = 'left',
}) => {
  const Content = isSuccess ? (
    <SuccessComponent
      text={successText}
      title={successTitle}
    />
  ) : (
    <>
      <Header>
        {hasBackButton && (
          <BackButton>
            <NewTextButton
              dataTestId="go-to-login"
              label="Вернуться к авторизации"
              prefixIcon="arrowLeft"
              url={RouterPaths.Login}
            />
          </BackButton>
        )}
        <Title textAlign={textAlign}>
          {title}
        </Title>
      </Header>


      {subText && (
        <SubText>
          {subText}
        </SubText>
      )}
      <ChildrenWrapper contentMargin={contentMargin}>
        {children}
      </ChildrenWrapper>
    </>
  )

  return (
    <Wrapper>
      <ContentWrapper>
        <LeftCol>
          <img src={bg} alt="background" />
        </LeftCol>
        <Scrollbars>
          <RightCol>
            <RightColHead>
              <Link to={RouterPaths.Home}>
                <Logo />
              </Link>
            </RightColHead>
            <RightColContent contentMaxWidth={contentMaxWidth}>
              {Content}
            </RightColContent>
          </RightCol>
        </Scrollbars>
      </ContentWrapper>
    </Wrapper>
  )
}

const Header = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  background-color: ${palette.white};
`

const Wrapper = styled.div`
  padding: 60px;
  height: 100%;
  width: 100%;
  background-color: ${palette.grey20};
`

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${palette.white};
  border-radius: 40px;
  overflow: hidden;
`

const LeftCol = styled.div`
  flex: 0 0 40%;
  width: 100%;

  @media(min-width: ${WINDOW_WIDTH.desktop}px) {
    flex: 0 0 50%;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-top-right-radius: 40px;
  }
`

const RightCol = styled.div`
  flex: 1 0 60%;
  padding: 50px;
  height: 100%;
  width: 100%;

  @media(min-width: ${WINDOW_WIDTH.desktop}px) {
    flex: 0 0 50%;
  }
`

const ChildrenWrapper = styled.div<{ contentMargin: number }>`
  margin-top: ${({ contentMargin }) => contentMargin}px;
  width: 100%;
`

const SubText = styled.div`
  margin-top: 8px;
  ${TextLLight}
`

const RightColHead = styled.div`
  text-align: right;
`

const RightColContent = styled.div<{ contentMaxWidth: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  max-width: ${({ contentMaxWidth }) => contentMaxWidth}px;
  margin: -38px auto 0;
`

const BackButton = styled.div`
  margin: 0 auto 24px 0;
`

const Title = styled.div<{ textAlign: 'left' | 'center' }>`
  ${HeadXXL}
  max-width: 450px;
  text-align: ${({ textAlign }) => textAlign};
  width: 100%;
`
