import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { DataSetType } from '@/dal'
import { Label } from '../parts'
import { $visibleDataSets } from '../../model/private'

const quarterMap = {
  1: 'I',
  2: 'II',
  3: 'III',
  4: 'IV',
}

export const Labels = React.memo(() => {
  const data = useUnit($visibleDataSets)

  if (!data) return null

  return (
    <Wrapper>
      {data.map(({ type, quarter, year }, i) => (
        <Label
          key={i}
          isCurrent={type === DataSetType.Current}
          isPrediction={type === DataSetType.Predict}
          text={`${quarterMap[quarter]} кв. ${year}`}
        />
      ))}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
`

