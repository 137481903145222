import React from 'react'
import styled, { css } from 'styled-components'

import { ActionButton } from './ActionButton'

type Props<T> = {
  id: T
  availableActions: {
    can_copy: boolean
    can_delete: boolean
    additional_request: boolean
  }
  canDownload: boolean
  isEndAlign?: boolean
  isAnswerRequired: boolean | string
  isDownloading: boolean
  onAdditionalRequestClicked: () => void
  onCopy: () => void
  onDownload: () => void
  onDelete: () => void
}

export const DocumentActionButtons = <T extends string | number>({
  id,
  availableActions,
  canDownload,
  isEndAlign,
  isAnswerRequired,
  isDownloading,
  onAdditionalRequestClicked,
  onCopy,
  onDownload,
  onDelete,
}: Props<T>) => {
  const canCopy = availableActions.can_copy
  const canDelete = availableActions.can_delete
  const canOpenAdditionalRequest = availableActions.additional_request
  const hasBadge = Boolean(canOpenAdditionalRequest && isAnswerRequired)

  return (
    <Wrapper isEndAlign={isEndAlign}>
      <ActionButton
        icon={hasBadge ? 'mailWithDot' : 'mail'}
        color={hasBadge ? 'accent100' : 'grey60'}
        isDisabled={!canOpenAdditionalRequest}
        dataTestId={`${id}-open-additional-request-btn`}
        onClick={onAdditionalRequestClicked}
      />
      <ActionButton
        icon="copyDoc"
        isDisabled={!canCopy}
        dataTestId={`${id}-copy-document-btn`}
        onClick={onCopy}
      />
      <ActionButton
        icon="download"
        isDisabled={!canDownload || isDownloading}
        dataTestId={`${id}-download-document-btn`}
        onClick={onDownload}
      />
      <ActionButton
        icon="delete"
        isDisabled={!canDelete}
        dataTestId={`${id}-delete-document-btn`}
        onClick={onDelete}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div<{ isEndAlign?: boolean }>`
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 8px;

  ${({ isEndAlign }) => isEndAlign && css`
    justify-content: flex-end;
  `}
`
