import React, { memo } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { PaginationItem, SimpleLoader } from '@/ui'

import { $hasMore, loadMore, loadMoreFx } from '../../model/private'

export const LotPaginationItem = memo(() => {
  const [hasMore, isLoading] = useUnit([
    $hasMore,
    loadMoreFx.pending,
  ])

  return hasMore ? (
    <PaginationWrapper>
      <PaginationItem isLoading={isLoading} onLoadMore={loadMore}>
        <SimpleLoader sizePx={24} />
      </PaginationItem>
    </PaginationWrapper>
  ) : null
})

const PaginationWrapper = styled.div`
  height: 24px;
  width: 100%;
`
