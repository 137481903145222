import React, { FormEvent } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'
import { useForm } from 'effector-forms'

import { InputField, NewDropDownField } from '@/ui'
import { ButtonsRow, ModalHeader } from '@/features/support/shared'
import { noop } from '@/lib/noop'

import {
  $selectedUser,
  $usersOptions,
  blockUserFx,
  createAppealModal,
  getMyUsersFx,
} from '../../model/private'
import { blockUserForm } from '../../model/forms'

export const BlockUserForm = React.memo(() => {
  const [selectedUser, options, isListPending, isPending] = useUnit([
    $selectedUser,
    $usersOptions,
    getMyUsersFx.pending,
    blockUserFx.pending,
  ])

  const { fields, isValid, submit } = useForm(blockUserForm)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    submit()
  }

  return (
    <Wrapper onSubmit={handleSubmit}>
      <ModalHeader
        title="Отключить пользователя в ЛКП"
        subtitle="Обращение по вопросам отключения пользователей в ЛКП"
      />

      <Content>
        <NewDropDownField
          label="Пользователь"
          placeholder="Выберите пользователя из списка"
          dataTestId="user-select"
          options={options}
          onOptionClick={fields.userId.onChange}
          selectedId={fields.userId.value}
          error={fields.userId.firstError?.errorText}
          isDisabled={isPending}
          size="L"
          isLoading={isListPending}
          required
        />
        <InputField
          label="E-mail"
          disabled
          dataTestId="user-email-input"
          value={selectedUser?.email || ''}
          placeholder="Почта пользователя"
          size="L"
          onChange={noop}
        />
      </Content>

      <ButtonsRow
        submitLabel="Отправить"
        isPending={isPending}
        isDisabled={!isValid || !fields.userId.value}
        hasScreenshotButton={false}
        onClose={createAppealModal.close}
        onSubmit={submit}
      />
    </Wrapper>
  )
})

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;

  width: 542px;
  padding: 32px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
