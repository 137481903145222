import { attach, createApi } from 'effector'

import { cancelPaymentApproveReqFx } from '@/dal'
import { d } from './domain'

export const $isOpen = d.store<boolean>(false)

export const close = d.event<void>()

export const onCancelApprove = d.event<void>()

export const $reasonCancel = d.store('')

export const openCancelApproveModal = d.event<void>()

export const { change, clear } = createApi($reasonCancel, {
  change: (_, newVal: string) => newVal,
  clear: () => '',
})

export const cancelPaymentApproveFx = attach({
  effect: cancelPaymentApproveReqFx,
})

export const $isCancelPending = cancelPaymentApproveFx.pending
