import React from 'react'
import styled from 'styled-components'

import { TextL, TextMLight, palette } from '@/ui'
import { getNoun } from '@/lib/text-noun'

type Props = {
  title: string
  projectCount: number | null
  contactCount: number | null
  allProjectsCount: number
  isDisplayingProjects: boolean
}

export const RoleHeader = ({
  title,
  contactCount = 0,
  projectCount = 0,
  allProjectsCount,
  isDisplayingProjects,
}: Props) => {
  const infoString = isDisplayingProjects
    ? `заведен по ${projectCount} / ${allProjectsCount} ${getNoun(allProjectsCount, 'проекта', 'проектов', 'проектов')}`
    : `${getNoun(contactCount ?? 0, 'заведен', 'заведено', 'заведено')} ${contactCount}`

  return (
    <Wrapper>
      <Title>{title}</Title>
      <Info>
        {infoString}
      </Info>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const Title = styled.h5`
  ${TextL}
  color: inherit;
`

const Info = styled.p`
  ${TextMLight}
  color: ${palette.grey70};
`
