import React from 'react'
import { useUnit } from 'effector-react'

import { NotificationItem, NotificationType } from '@/ui'

import { $paymentInfo, closeBankWarrantySplashFx } from '../../../model/private'

export const BankWarrantySplash = React.memo(() => {
  const info = useUnit($paymentInfo)

  if (!info?.features.show_bank_warranty_splash) return null

  return (
    <NotificationItem
      body="Обращаем внимание, отсутствует обеспечение банковской гарантии и/или договора поручительства"
      type={NotificationType.Warning}
      showCloseButton
      onClose={() => closeBankWarrantySplashFx(info.id)}
    />
  )
})
