import {
  Domain, Effect, sample, Store, Unit,
} from 'effector'
import { debounce } from 'patronum'

import { root } from '@/root-domain'
import { DocumentExclusiveBlock } from '@/dal'

type Params<T, F> = {
  domain?: Domain
  getFeaturesFx: Effect<T, F>
  $docId: Store<T | null>
  $features: Store<F | null>
  triggerUnits?: Unit<unknown>[]
}

export const createDocumentBlocking = <
  T,
  F extends { document_exclusive_block: DocumentExclusiveBlock },
>({
    domain,
    getFeaturesFx,
    $docId,
    $features,
    triggerUnits = [],
  }: Params<T, F>) => {
  const d = domain ?? root.domain()

  const checkDocumentBlocking = d.event()
  const fetchFeatures = d.event()

  sample({
    clock: [
      ...triggerUnits,
      checkDocumentBlocking,
      debounce({ source: fetchFeatures, timeout: 1000 }),
    ],
    source: $docId,
    filter: Boolean,
    target: getFeaturesFx,
  })

  sample({
    clock: $features,
    filter: (features) => Boolean(features?.document_exclusive_block.is_blocked),
    target: fetchFeatures,
  })

  sample({
    clock: getFeaturesFx.fail,
    target: fetchFeatures,
  })

  return {
    /** @deprecated */
    checkDocumentBlocking,
  }
}
