import React from 'react'
import styled from 'styled-components'

import { MyCompany } from '@/features/my-company/view'
import { HeadXL, PageWithNavigation } from '@/ui'
import { RouterPaths } from '@/features/app/model'

export const MyCompanyPage = () => (
  <PageWithNavigation
    navHeaderSlot={<Title>Моя компания</Title>}
    links={[
      { label: 'Реквизиты', path: RouterPaths.MyCompany },
      { label: 'Справочник контактов', path: RouterPaths.Contacts },
    ]}
  >
    <MyCompany />
  </PageWithNavigation>

)


const Title = styled.div`
  ${HeadXL}
`
