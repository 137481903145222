import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { palette } from '@/ui'

type Props = {
  children: React.ReactElement
  link: string
}

export const ItemWrapper = ({
  children,
  link,
}: Props) => {
  return (
    <LinkWrapper target="_blank" to={link}>
      {children}
    </LinkWrapper>
  )
}

const LinkWrapper = styled(Link)`
  display: block;
  text-decoration: none;

  &:not(:first-child) {
    border-top: 1px solid ${palette.grey30};
  }
`
