import React from 'react'
import styled from 'styled-components'

import {
  DropAreaWithList,
} from '@/ui'
import { FileDTO } from '@/dal'


type Props = {
  onChange: (val: File[]) => File[]
  currValue: File[]
  uploadedFiles?: FileDTO[] | null
  markUploadedFileToDelete?: (id: FileDTO['id']) => void
  isLoading?: boolean
  disabled?: boolean,
}

export const FileField = ({
  currValue,
  uploadedFiles,
  disabled,
  onChange,
  markUploadedFileToDelete,
  isLoading,
}: Props) => {
  return (
    <Wrapper>
      <DropAreaWithList
        dataTestId="file-input"
        items={currValue}
        markUploadedFileToDelete={markUploadedFileToDelete}
        uploadedFiles={uploadedFiles}
        onChange={onChange}
        isLoading={isLoading}
        isDisabled={disabled}
        height={92}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
`
