import { useUnit } from 'effector-react'
import React from 'react'

import { SelectItemButton } from '@/ui'
import { createAttachmentsModalView } from '@/features/factories/attach-modal'

import {
  $canSendFiles,
  $documentAttachmentsCount,
  attachmentsModel,
  onOpenModal,
} from '../../model/private'

const AttachmentsModalView = createAttachmentsModalView(attachmentsModel)

export const EstimateAttachments = React.memo(() => {
  const count = useUnit($documentAttachmentsCount)
  const canSendFiles = useUnit($canSendFiles)
  return (
    <>
      <SelectItemButton
        text="Мои вложения"
        additionalText={String(count || 0)}
        icon={canSendFiles ? 'plusWithCircle' : 'arrowRightM'}
        buttonStyle={canSendFiles ? 'secondary' : 'primary'}
        hasBorder={false}
        dataTestId="open-attachments"
        onClick={onOpenModal}
      />
      <AttachmentsModalView />
    </>
  )
})
