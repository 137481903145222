import React from 'react'
import { Field } from './Field'
import { NewDropDownInput } from './NewDropDownInput'
import { ActionOption, InputSize, Option } from './types'

type Props = {
  label: string
  error?: string | null
  options: Option[] | null
  actionOptions?: ActionOption[] | null
  size?: InputSize
  selectedId: Option['id'] | null
  hasSearch?: boolean
  placeholder?: string
  isDisabled?: boolean
  dataTestId: string,
  required?: boolean
  isLoading?: boolean
  defaultOptionText?: string
  isInvalid?: boolean,
  hasClear?: boolean
  isMultiple?: boolean
  onOptionClick: (id: Option['id'] | null) => void
}

export const NewDropDownField = ({
  label,
  onOptionClick,
  actionOptions,
  dataTestId,
  options,
  selectedId,
  error,
  hasSearch,
  placeholder,
  size,
  isDisabled,
  required,
  isLoading,
  defaultOptionText,
  isInvalid,
  hasClear,
  isMultiple,
}: Props) => (
  <Field
    label={label}
    isLabel={false}
    required={required}
  >
    <NewDropDownInput
      isDisabled={isDisabled}
      dataTestId={dataTestId}
      onOptionClick={onOptionClick}
      actionOptions={actionOptions}
      options={options}
      selectedId={selectedId}
      hasSearch={hasSearch}
      placeholder={placeholder}
      error={error}
      size={size}
      isLoading={isLoading}
      defaultOptionText={defaultOptionText}
      isInvalid={isInvalid}
      hasClear={hasClear}
      isMultiple={isMultiple}
    />
  </Field>
)
