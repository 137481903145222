import React from 'react'
import { useUnit } from 'effector-react'

import { createKorDocFx, onConfirmCreateKorDoc, onCopyKorDoc } from '../../model/create-kor-doc.private'
import { CreateKorDocConfirm, EstimateCreateOrCopyConfirm } from '../parts'
import { $createDocumentInfo, closeCreateDocumentModal, copyDocumentFx } from '../../model/private'

export const CreateKorDoc = React.memo(() => {
  const [isCreatePending, isCopyPending] = useUnit([
    createKorDocFx.pending,
    copyDocumentFx.pending,
  ])
  const info = useUnit($createDocumentInfo)

  const isNeedChoice = Boolean(info?.discardedDoc)

  return isNeedChoice ? (
    <EstimateCreateOrCopyConfirm
      id={info?.discardedDoc?.id as number}
      name={info?.discardedDoc?.name || ''}
      draft={info?.draft}
      onConfirmCreateKorDoc={onConfirmCreateKorDoc}
      onCopy={onCopyKorDoc}
      onClose={closeCreateDocumentModal}
      isCreatePending={isCreatePending}
      isCopyPending={isCopyPending}
    />
  ) : (
    <CreateKorDocConfirm
      contractName={info?.contractName}
      isPending={isCreatePending}
      onConfirm={onConfirmCreateKorDoc}
      onClose={closeCreateDocumentModal}
    />
  )
})

