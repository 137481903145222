import React, { FormEvent, memo } from 'react'
import styled from 'styled-components'
import { useForm } from 'effector-forms'
import { useUnit } from 'effector-react'

import { InputField, NumberInputField } from '@/ui'
import { $isAuthorizedUserDataPending } from '@/features/login/model'

import { ButtonsRow, ModalHeader } from '@/features/support/shared'
import { $isAppealSending, createAppealModal } from '../../model/private'
import { contactsForm } from '../../model/forms'

export const ContactsForm = memo(() => {
  const [isUserDataPending, isSending] = useUnit([
    $isAuthorizedUserDataPending,
    $isAppealSending,
  ])

  const { fields, submit, isValid } = useForm(contactsForm)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    submit()
  }

  return (
    <Wrapper onSubmit={handleSubmit}>
      <ModalHeader title="С кем связаться при необходимости?" />

      <Fields>
        <InputField
          label="Контактное ФИО"
          value={fields.fullName.value}
          error={fields.fullName.firstError?.errorText}
          size="L"
          dataTestId=""
          disabled={isUserDataPending || isSending}
          isRequired
          onChange={fields.fullName.onChange}
          onClear={() => fields.fullName.set('')}
        />
        <NumberInputField
          label="Номер телефона"
          dataTestId=""
          disabled={isUserDataPending || isSending}
          size="L"
          placeholder="+7"
          type="tel"
          format="+7 ### ### ## ##"
          allowEmptyFormatting
          mask="_"
          isRequired
          value={fields.phone.value.replace('+7', '')}
          error={fields.phone.firstError?.errorText}
          onChange={fields.phone.onChange}
          onClear={() => fields.phone.set('')}
        />
      </Fields>

      <ButtonsRow
        submitLabel="Отправить"
        isPending={isSending}
        isDisabled={isUserDataPending || !isValid}
        hasScreenshotButton={false}
        onClose={createAppealModal.close}
        onSubmit={submit}
      />
    </Wrapper>
  )
})

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;

  // TODO лучше перенести на верхний wrapper, если возможно
  width: 542px;
  padding: 32px;
`

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
