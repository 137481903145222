import { sample, split } from 'effector'

import { DocumentAlias, DocumentStatuses } from '@/dal'
import { createToast } from '@/features/toast-service/model'
import { $currentSystemAlias } from '@/features/app/model'

import {
  $additionalModalInfo,
  $messageList,
  $requestInfo,
  additionalForm,
  closeModal,
  postMessageFx,
  putMessageFx,
  getRequestInfoFx,
  markAdditionalRequestAnswerAsReadFx,
} from './private'
import {
  openAdditionalRequestModal,
  onAdditionalRequestUpdated,
  onAdditionalRequestAnswerRead,
} from './public'

$additionalModalInfo
  .on(openAdditionalRequestModal, (_, docInfo) => ({
    ...docInfo,
    isAgreedContractor: [
      DocumentStatuses.AgreedContractor, DocumentStatuses.DocumentsWaiting,
    ].includes(docInfo.status),
  }))
  .reset(closeModal)

$requestInfo
  .on([getRequestInfoFx.doneData, onAdditionalRequestUpdated], (_, data) => data)
  .reset(closeModal)

sample({
  clock: openAdditionalRequestModal,
  source: $additionalModalInfo,
  filter: Boolean,
  target: getRequestInfoFx,
})

sample({
  clock: openAdditionalRequestModal,
  target: markAdditionalRequestAnswerAsReadFx,
})

sample({
  clock: markAdditionalRequestAnswerAsReadFx.done,
  target: onAdditionalRequestAnswerRead,
})

const summary = sample({
  clock: additionalForm.formValidated,
  source: {
    docInfo: $additionalModalInfo,
    messages: $messageList,
  },
  filter: Boolean,
  fn: (docInfo, form) => ({
    ...docInfo,
    ...form,
  }),
})

// TODO условия дополнялись на скорую руку, надо будет прибраться
const { putMessage, postMessage, administrativeMessage } = split(summary, {
  putMessage: ({ docInfo, messages }) => Boolean(
    (
      !docInfo?.isAgreedContractor
      && docInfo?.docType !== DocumentAlias.CONTRACT
    ) || (
      docInfo?.isAgreedContractor
      && messages?.some((item) => item.answer_required)
    ),
  ),
  postMessage: ({ docInfo, messages }) => Boolean(
    (
      docInfo?.isAgreedContractor
      && docInfo?.docType !== DocumentAlias.CONTRACT
    ) || (
      docInfo?.isAgreedContractor
      && !messages?.some((item) => item.answer_required)
    ),
  ),
  administrativeMessage: (s) => s.docInfo?.docType === DocumentAlias.CONTRACT,
})

sample({
  clock: [putMessage, administrativeMessage],
  fn: ({ message, files, messages }) => ({
    id: messages?.find((item) => item.answer_required)?.id ?? '',
    answer: message,
    files,
  }),
  target: putMessageFx,
})

sample({
  clock: postMessage,
  source: $currentSystemAlias,
  filter: Boolean,
  fn: (system, {
    docInfo,
    message,
    files,
  }) => ({
    id: docInfo?.docId ?? '',
    question: message,
    files,
    docType: docInfo?.docType ?? DocumentAlias.ACT,
    created_from_place: system,
  }),
  target: postMessageFx,
})

sample({
  clock: [putMessageFx.doneData, postMessageFx.doneData],
  target: onAdditionalRequestUpdated,
})

sample({
  clock: [closeModal, onAdditionalRequestUpdated],
  target: additionalForm.reset,
})

createToast({
  effect: postMessageFx,
})

createToast({
  effect: putMessageFx,
})
