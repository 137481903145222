import React from 'react'
import {
  Route,
  Switch,
} from 'react-router-dom'
import { useGate } from 'effector-react'

import {
  RestorePassword,
  ChangePassword,
  RepeatRegistrationLetter,
} from '@/features/restore-access'
import { RouterPaths } from '@/features/app/model'
import { RestoreAccessGate } from '@/features/restore-access/model'

export const RestoreAccessPage = () => {
  useGate(RestoreAccessGate)

  return (
    <Switch>
      <Route path={`${RouterPaths.ChangePassword}/:token`} component={ChangePassword} exact />
      <Route path={RouterPaths.RestorePassword} component={RestorePassword} exact />
      <Route
        path={RouterPaths.RepeatRegistrationLetter}
        component={RepeatRegistrationLetter}
        exact
      />
    </Switch>
  )
}
