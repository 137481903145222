import { sample } from 'effector'
import { getSubmissionMonthsFx } from './private'
import { $submissionMonths, onGetSubmissionMonths, resetSubmissionMonths } from './public'

$submissionMonths
  .on(getSubmissionMonthsFx.doneData, (_, months) => months)
  .reset(resetSubmissionMonths)

sample({
  clock: onGetSubmissionMonths,
  filter: Boolean,
  fn: (id) => ({ id }),
  target: getSubmissionMonthsFx,
})
