import styled from 'styled-components'

export const DefaultButton = styled.button`
  display: block;
  padding: 0;
  margin: 0;
  background-color: transparent;
  cursor: pointer;
  color: inherit;
  border: none;
  font-family: 'FuturaFuturis';
  width: fit-content;
  height: fit-content;

  &:disabled {
    cursor: not-allowed;
  }
`
