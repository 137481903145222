import Home from './home-icon.svg'
import AdministrativeSystem from './subsystem-icon.svg'
import ActConstructor from './act-constructor-icon.svg'
import PaymentSystem from './payment-system-icon.svg'
import FundingSystem from './funding-system-icon.svg'
import EditSmeta from './edit-smeta.svg'
import DocumentFlow from './document-flow-system-icon.svg'
import ReportingSystem from './reporting-system-icon.svg'
import SettingsSystem from './setting-system-icon.svg'
import Help from './help-system-icon.svg'
import PhysicalSystemIcon from './physical-system-icon.svg'

export const subsystemIcons = {
  'home-icon': Home,
  'subsystem-icon': AdministrativeSystem,
  'act-constructor-icon': ActConstructor,
  'payment-system-icon': PaymentSystem,
  'funding-system-icon': FundingSystem,
  'edit-smeta': EditSmeta,
  'document-flow-system-icon': DocumentFlow,
  'reporting-system-icon': ReportingSystem,
  'setting-system-icon': SettingsSystem,
  'help-system-icon': Help,
  'physical-system-icon': PhysicalSystemIcon,
}
