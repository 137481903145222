import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { AppealRequestStatus, AppealTaskItem as AppealTaskItemType } from '@/dal'
import {
  TextMLight, TextS, Icon2, palette, HeadM, Label, PaletteColor, DefaultButton,
} from '@/ui'
import { numberWithSpaces } from '@/lib/number-represent'
import { getRouterPathByDocumentAlias } from '@/lib/documentResolvers/getRouterPathByDocumentAlias'

import { TaskTypeLabel } from '../parts'
import { getAppealMessages, markTaskAsRead } from '../../model/appeals.private'

type Props = AppealTaskItemType

export const AppealTaskItem = ({ task_data: taskData, ...item }: Props) => {
  const labelInfo: {
    text: string
    bgColor: PaletteColor
    colorText: PaletteColor
  } = useMemo(() => {
    switch (taskData.task_status) {
      case AppealRequestStatus.NoAnswer:
        return {
          text: 'Без ответа',
          bgColor: 'yellow30',
          colorText: 'accent90',
        }
      case AppealRequestStatus.Unread:
        return {
          text: 'Непрочитанное',
          bgColor: 'accent10',
          colorText: 'accent100',
        }
      case AppealRequestStatus.Closed:
      default:
        return {
          text: 'Закрытый',
          bgColor: 'grey10',
          colorText: 'grey90',
        }
    }
  }, [taskData])

  const DocumentItem = React.useMemo(() => {
    if (!taskData.related_document_id || !taskData.related_document_name) return null

    const link = getRouterPathByDocumentAlias(taskData.related_document_type)

    if (link) {
      return (
        <Link onClick={(e) => e.stopPropagation()} to={`${link}/${taskData.related_document_id}`}>
          <DocumentName>
            {taskData.related_document_name}
          </DocumentName>
        </Link>
      )
    }
    return (
      <DocumentName>
        {taskData.related_document_name}
      </DocumentName>
    )
  }, [taskData])

  const handleClick = () => {
    markTaskAsRead({ ...item, task_data: taskData })
    getAppealMessages({ ...item, task_data: taskData })
  }

  return (
    <Wrapper onClick={handleClick}>
      <Header>
        <TaskTypeLabel date={item.created_at} docStatus={null} isRequestToContractor />
        <HeaderRow>
          <Title>
            {item.document_name}
          </Title>
          <LabelWrapper>
            <Label {...labelInfo} />
          </LabelWrapper>
          <Icon2
            icon="visaTask"
            color={taskData.task_status === AppealRequestStatus.Unread ? 'accent80' : 'grey60'}
          />
        </HeaderRow>
      </Header>

      <RowsContainer>
        <Row>
          <RowName>Тема</RowName>
          <RowValue>
            {taskData.description}
          </RowValue>
        </Row>

        <Row>
          <RowName>Автор</RowName>
          <RowValue>
            {taskData.author}
          </RowValue>
        </Row>

        <Row>
          <RowName>Исполнитель</RowName>
          <RowValue>
            {taskData.executor}
          </RowValue>
        </Row>
      </RowsContainer>

      {DocumentItem && (
        <DocumentInfo>
          <RowsContainer>
            <Row>
              <RowName>Проект</RowName>
              <RowValue>
                {item.project_name}
              </RowValue>
            </Row>

            <Row>
              <RowName>Документ</RowName>
              <RowValue>
                {DocumentItem}
              </RowValue>
            </Row>
          </RowsContainer>

          {item.total_sum && (
            <SumCol>
              <SumLabel>
                Сумма документа, руб
              </SumLabel>
              <DocumentPrice>
                {numberWithSpaces(item.total_sum, { dot: ',' })}
              </DocumentPrice>
            </SumCol>
          )}
        </DocumentInfo>
      )}
    </Wrapper>
  )
}

const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const Title = styled.p`
  ${HeadM}
  transition: color 0.15s linear;
`

const LabelWrapper = styled.div`
  flex-shrink: 0;
`

const Wrapper = styled(DefaultButton)`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 20px 32px 24px;
  transition: background-color 0.15s linear;
  cursor: pointer;

  & > * {
    width: 100%;
    text-align: left;
}

  &:not(:first-child) {
    border-top: 1px solid ${palette.grey30};
  }

  @media (hover: hover) {
    &:hover  {
      background-color: ${palette.yellow10};

      ${Title} {
        color: ${palette.accent100};
      }
    }
  }
`

const RowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Row = styled.div`
  display: flex;
  gap: 4px;
`

const RowName = styled.p`
  ${TextMLight}
  width: 88px;
  flex-shrink: 0;
  color: ${palette.grey70};
`

const RowValue = styled.p`
  ${TextMLight}
  flex-grow: 1;
`

const DocumentInfo = styled.div`
  display: flex;
  gap: 8px;
`

const DocumentName = styled.p<{ isGrey?: boolean }>`
  ${TextMLight}
  color: ${({ isGrey }) => (isGrey ? palette.grey70 : palette.accent100)};
`

const SumCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-left: auto;
`

const SumLabel = styled.div`
  ${TextS}
  color: ${palette.grey70};
  max-height: 16px;
  color: ${palette.grey60};
  text-transform: uppercase;
`

const DocumentPrice = styled.p`
  ${TextMLight};
  text-align: right;
`
