import React from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import styled from 'styled-components'

export const ScrollableContainer: React.FC = ({ children }) => (
  <Scrollbars autoHeight autoHeightMax={570}>
    <Wrapper>
      {children}
    </Wrapper>
  </Scrollbars>
)

const Wrapper = styled.div`
  padding: 0 32px;
`
