import React from 'react'
import styled from 'styled-components'
import { useGate, useUnit } from 'effector-react'

import { LoaderOverlay, ModalLayoutV2 } from '@/ui'
import { StepType } from '@/dal'
import { noop } from '@/lib/noop'

import {
  OpeningStep, SingleChoiceStep, RatingStep, EndStep,
} from '../containers'
import {
  $currentStep,
  $currentStepNumber,
  $isModalOpen,
  $isSurveyCompleted,
  SurveyGate,
  sendSurveyResultFx,
} from '../../model/private'

export const SurveyModal = React.memo(() => {
  const isPending = useUnit(sendSurveyResultFx.pending)
  const [isOpen, currentStep, currentStepNumber, isSurveyCompleted] = useUnit([
    $isModalOpen,
    $currentStep,
    $currentStepNumber,
    $isSurveyCompleted,
  ])

  useGate(SurveyGate)

  const stepComponent = React.useMemo(() => {
    // бэком предусмотрен также multiple_choice, но на фронте это не реализовано
    switch (currentStep?.type) {
      case StepType.SingleChoice:
        return <SingleChoiceStep />
      case StepType.Rating:
      default:
        return <RatingStep />
    }
  }, [currentStep])

  if (!isOpen) return null

  return (
    <ModalLayoutV2 hasCloseButton={false} closeModal={noop}>
      <Wrapper>
        {isSurveyCompleted ? (
          <EndStep />
        ) : (
          <>
            {!currentStepNumber && <OpeningStep />}
            {currentStepNumber > 0 && stepComponent}
          </>
        )}
        <LoaderOverlay isShown={isPending} />
      </Wrapper>
    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  position: relative;
  width: 500px;
  max-width: 100%;
`
