import React from 'react'
import styled from 'styled-components'

import { TextM, palette } from '@/ui'

import { Star } from './Star'

type Props = {
  rating: number
  descriptions: string[]
  onRate: (number: number) => void
}

export const StarScale = ({ rating, descriptions, onRate }: Props) => {
  const [hoveredNumber, setHoveredNumber] = React.useState(0)

  const currentDescription = React.useMemo(() => {
    const number = hoveredNumber || rating
    return number ? descriptions[number - 1] : null
  }, [hoveredNumber, rating])

  const onClick = (number: number) => {
    onRate(number)
  }
  const onHover = (number: number) => {
    setHoveredNumber(number)
  }

  const onUnhover = () => {
    setHoveredNumber(0)
  }

  return (
    <Wrapper>
      <Stars>
        {[...Array(5)].map((_, i) => (
          <Star
            key={i}
            number={i + 1}
            isFilled={rating > i}
            isHovered={hoveredNumber > i}
            onClick={onClick}
            onMouseEnter={onHover}
            onMouseLeave={onUnhover}
          />
        ))}
      </Stars>
      <Description>
        {currentDescription}
      </Description>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Stars = styled.div`
  display: flex;
  border: 1px solid ${palette.accent100};
  margin-bottom: 8px;
  border-radius: 4px;
  overflow: hidden;
`

const Description = styled.p`
  ${TextM}
  min-height: 32px;
  line-height: 16px;
  color: ${palette.accent100};
`
