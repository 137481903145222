import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { DocumentAlias } from '@/dal'
import { WINDOW_WIDTH } from '@/ui'
import { DocumentFilesButton } from '@/features/document-files'

import {
  Attachments, BankWarrantySplash, DocumentApproveInfo, GuaranteeContracts,
} from '../containers'
import { $paymentInfo } from '../../../model/private'

export const DocumentStatusAndAttacments = React.memo(() => {
  const info = useUnit($paymentInfo)

  return (
    <Wrapper>
      <DocumentApproveInfo />
      <BankWarrantySplash />
      <GuaranteeContracts />
      <AttachmentsContainer>
        <Attachments />
        {info && (
          <DocumentFilesButton
            buttonLabel="Документы для оплаты"
            id={info.id}
            docType={DocumentAlias.PAYMENT}
            status={info.status}
            downloadLink={info.printable_form_link}
          />
        )}
      </AttachmentsContainer>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

const AttachmentsContainer = styled.div`
  display: flex;
  gap: 16px;

  & > * {
    flex: 0 1 50%;
  }

  @media (max-width: ${WINDOW_WIDTH.smallDesktop - 1}px) {
    flex-direction: column;
  }
`
