import { sample } from 'effector'

import {
  $isModalOpen,
  $openedService,
  $services,
  changeOpenedService,
  closeModal,
  openModal,
  openServiceLinks,
  resetOpenedService,
} from './private'

$isModalOpen
  .on([openModal, openServiceLinks], () => true)
  .reset(closeModal)

$openedService
  .on(changeOpenedService, (_, service) => service ?? null)
  .reset([resetOpenedService, closeModal])

sample({
  clock: openServiceLinks,
  source: $services,
  fn: (services, openedAlias) => services.find(({ alias }) => alias === openedAlias),
  target: changeOpenedService,
})
