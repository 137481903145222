import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { GroupTree } from '@/features/group-tree/view'
import { noop } from '@/lib/noop'
import { FileInput, NewButton } from '@/ui'

import { $tableInfo, $tableWorkTotal, templateSelected } from '../../model/private'
import { $documentFeatures } from '../../../model'

const $templateLink = $tableInfo
  .map((data) => data?.download_template_link || null)

export const WorksHeader = React.memo(() => {
  const ref = React.useRef<HTMLInputElement>(null)
  const link = useUnit($templateLink)
  const features = useUnit($documentFeatures)
  const total = useUnit($tableWorkTotal)

  const handleClickUploadButton = () => {
    ref.current?.click()
  }
  const LinkButton = (
    <NewButton
      prefixIcon="downloadTemplate"
      dataTestId="download-template"
      label="Скачать шаблон"
      buttonType="line-grey"
      size="S"
      isDisabled={!link}
      tooltip="Скачать Excel-шаблон таблицы для заполнения"
      onClick={noop}
    />
  )
  return (
    <Container>
      <GroupTree workCount={total} />

      {features?.can_process_templates && (
        <TemplateButtons>
          {link ? (
            <a href={link} download>
              {LinkButton}
            </a>
          ) : LinkButton}

          <FileInput
            accept=".xls, .xlsx"
            ref={ref}
            multiple={false}
            onFile={templateSelected}
          >
            <NewButton
              prefixIcon="uploadTemplate"
              dataTestId="upload-template"
              label="Загрузить шаблон"
              size="S"
              buttonType="line-grey"
              tooltip="Загрузить заполненный Excel-шаблон таблицы"
              onClick={handleClickUploadButton}
            />
          </FileInput>
        </TemplateButtons>
      )}

    </Container>
  )
})

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`

const TemplateButtons = styled.div`
  display: flex;
  gap: 12px;
`
