import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { RouterPaths } from '@/features/app/model'
import { Icon2 } from '../icons'
import { NewButton } from '../buttons'
import { HeadXXL, TextLLight } from '../typography'


type Props = {
  title?: string
  text?: string
}

export const SuccessComponent = ({
  text = 'Информация по восстановлению доступа будет направлена на указанную электронную почту.',
  title = 'Запрос выполнен',
}: Props) => {
  return (
    <Wrapper>

      <Icon2 size={50} icon="successIcon" />

      <TextContent>
        <Title>
          {title}
        </Title>
        <Text>
          {text}
        </Text>
      </TextContent>

      <Link to={RouterPaths.Login}>
        <NewButton dataTestId="go-login" label="ОК" isFill />
      </Link>

    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & a {
    width: 100%;
  }
`

const TextContent = styled.div`
  margin-top: 24px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Title = styled.div`
  ${HeadXXL}
  text-align: center;
`

const Text = styled.div`
  ${TextLLight}
  text-align: center;
`
