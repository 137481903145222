import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { NewButton } from '@/ui'
import { INVALID_DATE, NO_DATA } from '@/features/app/model/const'
import { formatDateString } from '@/lib/date'

import { formatPhoneNumber } from '@/lib/formatPhoneNumber'
import { sanitizePhone } from '@/lib/sanitizePhone'
import { formatNumberByFourDigits } from '@/lib/number-represent'
import { DetailItem } from '../parts'
import { DaysUntilExpiration } from './DaysUntilExpiration'
import { $selectedCompany } from '../../../model'
import { openEditing } from '../../model/private'

export const DetailsInfo = React.memo(() => {
  const selectedCompany = useUnit($selectedCompany)

  const formattedSolutionDate = React.useMemo(() => {
    const formattedDate = formatDateString(selectedCompany?.solution?.date ?? '')
    return formattedDate === INVALID_DATE ? '' : formattedDate
  }, [selectedCompany])


  if (!selectedCompany) return null

  const { bank_bik, bank_name, phone_number } = selectedCompany

  const bikAndBank = bank_bik
    ? `${bank_bik}, ${bank_name ?? NO_DATA}`
    : null

  const phoneNumber = phone_number
    ? formatPhoneNumber(`+7${sanitizePhone(phone_number)}`)
    : ''

  return (
    <Wrapper>

      <FlexWrapper>
        <DaysUntilExpiration />

        <DetailItem
          name="Дата аккредитации"
          value={formattedSolutionDate}
        />

        <DetailItem
          name="ИНН"
          value={selectedCompany.inn}
        />
        <DetailItem
          name="КПП"
          value={selectedCompany.kpp}
        />
        <DetailItem
          name="БИК Банка"
          value={bikAndBank}
        />
        <DetailItem
          name="Расчетный счет"
          value={selectedCompany.acc_number && formatNumberByFourDigits(selectedCompany.acc_number)}
        />
        <DetailItem
          name="Юридический адрес"
          value={selectedCompany.legal_address}
        />
        <DetailItem
          name="ОКПО"
          value={selectedCompany.okpo}
        />
        <DetailItem
          name="ФИО"
          value={selectedCompany.sign_name}
        />
        <DetailItem
          name="Должность"
          value={selectedCompany.sign_position}
        />
        <DetailItem
          name="Телефон"
          value={phoneNumber}
        />
      </FlexWrapper>

      <NewButton
        label="Редактировать"
        onClick={openEditing}
        dataTestId="company-details-edit-btn"
      />
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 500px;
`

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
