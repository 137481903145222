import React from 'react'
import styled from 'styled-components'
import { useGate } from 'effector-react'
import Scrollbars from 'react-custom-scrollbars-2'

import { ContentContainer, palette } from '@/ui'
import {
  EstimateLotList,
  EstimateLotsFilters,
  EstimateLotsHeader,
  EstimateLotListGate,
} from '@/features/estimate-lot/list'

export const EstimateLotListPage = () => {
  useGate(EstimateLotListGate)

  return (
    <Scrollbars autoHeight autoHeightMin={0} autoHeightMax="100%">
      <ContentContainer gap={16}>
        <EstimateLotsHeader />

        <TableWrapper>
          <EstimateLotsFilters />
          <EstimateLotList />
        </TableWrapper>
      </ContentContainer>
    </Scrollbars>
  )
}

const TableWrapper = styled.div`
  width: fit-content;
  background-color: ${palette.white};
`
