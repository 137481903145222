import { sample } from 'effector'

import { CompanyItem } from '@/dal'
import { writeQueryFx } from '@/features/shared/query-string/model'

import {
  $myCompanyList,
  MyCompanyGate,
  readMyCompanyQueryFx,
  selectFirstCompany,
} from './private'
import {
  $allRoles,
  $companyProjects,
  $selectedCompanyId,
  cancelGetCompanyFx,
  cancelGetRolesFx,
  cancelUpdateRolesFx,
  contactsUpdated,
  getCompanyProjectFx,
  getRolesFx,
  selectCompany,
  updateRolesFx,
} from './public'
import { $selectedProjectId } from '../contacts/model'

$selectedCompanyId
  .on(selectCompany, (_, id) => id)
  .reset(MyCompanyGate.close)

$companyProjects
  .on(getCompanyProjectFx.doneData, (_, projects) => projects)
  .reset(MyCompanyGate.close, getCompanyProjectFx)

$allRoles
  .on([getRolesFx.doneData, updateRolesFx.doneData], (_, roles) => roles)
  .reset(MyCompanyGate.close, getRolesFx)

sample({
  clock: MyCompanyGate.open,
  target: readMyCompanyQueryFx,
})

sample({
  clock: readMyCompanyQueryFx.doneData,
  filter: ({ id }) => Boolean(id),
  fn: ({ id }) => Number(id),
  target: [selectCompany, writeQueryFx.prepend(() => ({}))],
})

sample({
  clock: $selectedCompanyId.updates,
  source: $selectedProjectId,
  filter: (_, companyId) => Boolean(companyId),
  fn: (projectId, companyId) => ({
    companyId: companyId as CompanyItem['id'],
    projectId: projectId || undefined,
  }),
  target: getRolesFx,
})

sample({
  clock: [contactsUpdated, $selectedProjectId.updates],
  source: { companyId: $selectedCompanyId, projectId: $selectedProjectId },
  filter: ({ companyId }) => Boolean(companyId),
  fn: ({ projectId, companyId }) => ({
    companyId: companyId as CompanyItem['id'],
    projectId: projectId || undefined,
  }),
  target: updateRolesFx,
})

sample({
  clock: $myCompanyList.updates,
  source: $selectedCompanyId,
  filter: (selectedId, list) => !(list?.some(({ id }) => id === selectedId)),
  target: selectFirstCompany,
})

sample({
  clock: MyCompanyGate.close,
  target: [cancelGetCompanyFx, cancelGetRolesFx, cancelUpdateRolesFx],
})

sample({
  clock: selectFirstCompany,
  source: $myCompanyList,
  filter: (companies) => Boolean(companies && companies.length),
  fn: (companies) => companies?.[0].id as number,
  target: selectCompany,
})
