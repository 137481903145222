import React from 'react'
import styled from 'styled-components'
import Scrollbars from 'react-custom-scrollbars-2'

import { TasksAndResponses } from '@/features/tasks-and-responses'
import {
  Articles, OpenedArticle, ShareArticleModal, AllArticlesModal,
} from '@/features/articles/view'
import { DateGraph } from '@/features/date-chart/view'
import { Services } from '@/features/services'
import { ContentContainer, HeadXL } from '@/ui'

export const MainPage = () => (
  <>
    <Scrollbars>
      <ContentContainer>
        <Page>
          <PageTitle>
            Главная
          </PageTitle>

          <FirstBlock>
            <Articles />
            <Services />
          </FirstBlock>

          <Container>
            <ItemWrapper>
              <TasksAndResponses />
            </ItemWrapper>
            <ItemWrapper>
              <DateGraph />
            </ItemWrapper>
          </Container>

        </Page>
      </ContentContainer>
    </Scrollbars>
    <AllArticlesModal />
    <OpenedArticle />
    <ShareArticleModal />
  </>

)

const Page = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const PageTitle = styled.h1`
  ${HeadXL}
`

const FirstBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 32px;
  margin-bottom: 32px;
  margin-top: 24px;
`

const Container = styled.div`
  display: flex;
  gap: 32px;
  flex-grow: 1;
`

const ItemWrapper = styled.div`
  width: 50%;
  overflow: hidden;
`
