import React from 'react'
import styled from 'styled-components'

import {
  NewIconButton,
  SimpleLoader,
  TextM,
  TextMLight,
  palette,
} from '@/ui'
import { formatDateString } from '@/lib/date'
import { PaymentOrder } from '@/dal'

type Props = PaymentOrder & {
  canDelete: boolean
  isDisabled: boolean
  isLoading: boolean
  onDelete: (id: PaymentOrder['id']) => void
}

export const PaymentOrderItem = ({
  id, number, date, canDelete, isDisabled, isLoading, onDelete,
}: Props) => (
  <Wrapper>
    <PaymentNumberName>№ п/п</PaymentNumberName>
    <PaymentNumberValue>{number}</PaymentNumberValue>
    <PaymentDate>{formatDateString(date)}</PaymentDate>
    <DeleteButtonWrapper>
      {isLoading && (
        <SimpleLoader sizePx={16} borderWidth={2} />
      )}
      {!isLoading && canDelete && (
        <NewIconButton
          icon="delete"
          color="grey60"
          hoverColor="grey90"
          size={16}
          tooltipText="Удалить платежное поручение"
          disabled={isDisabled}
          dataTestId="act-delete-payment-order-btn"
          onClick={() => onDelete(id)}
        />
      )}
    </DeleteButtonWrapper>
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 2px 0;
  background-color: ${palette.grey10};
`

const PaymentNumberName = styled.p`
  ${TextM}
`

const PaymentNumberValue = styled.p`
  ${TextMLight}
  flex-grow: 1;
`

const PaymentDate = styled.p`
  ${TextMLight}
  color: ${palette.grey70};
`

const DeleteButtonWrapper = styled.div`
  height: 16px;
`
