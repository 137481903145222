import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { palette } from '@/ui'

import {
  Cell, SumsRow, TableRow, TableWrapper,
} from '../parts'
import { $documentInfo, $isDraft } from '../../../model'

export const PirActSums = React.memo(() => {
  const info = useUnit($documentInfo)
  const isDraft = useUnit($isDraft)

  if (!info?.ks3) return null

  const { ks3 } = info
  const approveColor = isDraft ? 'grey70' : 'grey90'

  return (
    <Wrapper>
      <Container>
        <TableWrapper>
          <TableRow type="header">
            <Cell color="grey90" isBold isUppercase isFill>
              Сумма
            </Cell>
            <Cell>Заявлено, руб</Cell>
            <Cell>Принято, руб</Cell>
          </TableRow>

          {ks3.sum_total && (
            <SumsRow
              {...ks3.sum_total}
              title="Сумма всего"
              approveColor={approveColor}
            />
          )}

          {ks3.total_cost && (
            <SumsRow
              {...ks3.total_cost}
              title="Сумма к оплате"
              approveColor={approveColor}
            />
          )}
        </TableWrapper>
      </Container>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  border-radius: 20px;
  overflow: hidden;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px 20px;
  background-color: ${palette.white};
`
