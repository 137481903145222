import React, { Key } from 'react'
import styled, { css } from 'styled-components'

import { TabsProps } from './types'
import { palette } from '../../palette'
import { TextM } from '../../typography'
import { DefaultButton } from '../../buttons'

type Props<Id> = TabsProps<Id> & {
  invalidTabId?: Id | null
}

export const SectionTabs = <T extends Key>({
  items,
  activeId,
  invalidTabId,
  onItemClick,
}: Props<T>) => {
  if (!items.length) return null

  return (
    <Wrapper>
      {items.map(({ id, label }) => (
        <TabItem
          key={id}
          isActive={activeId === id}
          inValid={invalidTabId === id}
          onClick={() => onItemClick(id)}
        >
          {label}
        </TabItem>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  width: fit-content;
  padding: 6px;
  border: 1px solid ${palette.grey40};
  border-radius: 12px;
  background-color: ${palette.white};
`

type TabItemProps = {
  isActive: boolean
  inValid: boolean
}

const TabItem = styled(DefaultButton)<TabItemProps>`
  ${TextM}
  padding: 5px 12px;
  background-color: ${palette.white};
  border-radius: 8px;
  transition: 0.15s linear;
  transition-property: background-color, color;
  user-select: none;
  border: 1px solid transparent;

  ${({ inValid }) => inValid && css`
    border-color: ${palette.red100};
  `}

  ${({ isActive }) => isActive && css`
    background-color: ${palette.grey80};
    color: ${palette.white};
  `}

  ${({ isActive }) => !isActive && css`
    @media (hover: hover) {
      &:hover {
        background-color: ${palette.grey40};
      }
    }
  `}
`
