import React from 'react'
import { AnyFormValues, ConnectedFields } from 'effector-forms'

export const useCheckFieldsDirty = <T extends ConnectedFields<AnyFormValues>>(
  fields: T,
  optionalFields?: (keyof T | null)[],
) => {
  const areFieldsDirty = React.useMemo(() => {
    let areFieldsDirty = true

    Object.keys(fields).forEach((key) => {
      if (optionalFields?.includes(key)) return
      if (!fields[key].isDirty) {
        areFieldsDirty = false
      }
    })

    return areFieldsDirty
  }, [fields, optionalFields])

  return areFieldsDirty
}
