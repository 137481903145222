import {
  AccreditationFailedError,
  AccreditationTimeOutError,
  AccreditationInProccessError,
  CompanyNotFoundError,
  CompanyAlreadyExistsInLKPError,
} from './errors'

export const isCompanyNotFoundError = (error: Error):
  error is CompanyNotFoundError => error instanceof CompanyNotFoundError

export const isAcreditationTimeOutError = (error: Error):
  error is AccreditationTimeOutError => error instanceof AccreditationTimeOutError

export const isAccreditationInProccessError = (error: Error):
  error is AccreditationInProccessError => error instanceof AccreditationInProccessError

export const isAcreditationFailedError = (error: Error):
  error is AccreditationFailedError => error instanceof AccreditationFailedError

export const isAlreadyExistsInLKPError = (error: Error):
  error is CompanyAlreadyExistsInLKPError => error instanceof CompanyAlreadyExistsInLKPError
