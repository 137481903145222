import { EstimateCorrectionType } from '@/dal'
import { palette } from '@/ui'

export const PAGINATION_LIMIT = 100

export const FIRST_GROUP_ID = 1
export const SECOND_GROUP_ID = 2
export const THIRD_GROUP_ID = 3

export const TABLE_BORDER_STYLE = `1px solid ${palette.grey40}`

export const TYPE_FILTER_ITEMS = [
  {
    label: 'Скорректированные',
    id: EstimateCorrectionType.Corrected,
  },
  {
    label: 'Неподтвержденные',
    id: EstimateCorrectionType.NotApproved,
  },
  {
    label: 'Ошибка в строке',
    id: EstimateCorrectionType.WithError,
  },
  {
    label: 'Гранд-тендер',
    id: EstimateCorrectionType.GrandTender,
  },
  {
    label: 'Выполненные',
    id: EstimateCorrectionType.Done,
  },
  {
    label: 'Новые добавленные',
    id: EstimateCorrectionType.New,
  },
  {
    label: 'С нулевыми ценами',
    id: EstimateCorrectionType.WithZero,
  },
]

export const ERROR_FOR_FOLDER = 'Пожалуйста, заполните все поля'
export const ERROR_FOR_ROW = 'Пожалуйста, заполните все поля. Если вы хотите обнулить или указать ноль, то в пустых полях введите число 0'
