import React from 'react'
import { useUnit } from 'effector-react'

import { InfoStep } from '../parts'
import { $surveyInfo, goToNextStep } from '../../model/private'

export const OpeningStep = React.memo(() => {
  const surveyInfo = useUnit($surveyInfo)

  if (!surveyInfo) return null

  const { title, description, first_slide_picture } = surveyInfo

  return (
    <InfoStep
      title={title}
      description={description}
      imgUrl={first_slide_picture}
      buttonLabel="Пройти опрос"
      onClickButton={goToNextStep}
    />
  )
})
