import { sample } from 'effector'

import { createToast } from '@/features/toast-service/model'

import { $createDocumentInfo } from './private'
import { onCreatedDocument } from './public'
import { createPaymentFx, onConfirmCreatePayment } from './create-payment.private'

createToast({
  effect: createPaymentFx,
  doneText: 'Платеж создан',
})

sample({
  clock: onConfirmCreatePayment,
  source: $createDocumentInfo,
  filter: Boolean,
  fn: ({ contractId }) => contractId,
  target: createPaymentFx,
})

sample({
  clock: createPaymentFx.doneData,
  target: onCreatedDocument,
})
