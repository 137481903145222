import React from 'react'
import styled from 'styled-components'

import { TextMLight, TextSLight } from '../../typography'
import { PaletteColor, palette } from '../../palette'
import { TooltipArrow } from './TooltipArrow'
import { TooltipProps } from '../../types'

type Props = TooltipProps & {
  isOpen: boolean
  children: React.ReactNode
  bgColor?: PaletteColor
  size?: 'L' | 'M'
  padding?: string
}

export const ToolTipV2 = ({
  refs,
  floatingStyles,
  isOpen,
  children,
  bgColor,
  size = 'L',
  padding,
  arrowProps,
  getFloatingProps,
}: Props) => (
  <>
    {isOpen && (
      <ToolTip
        ref={refs.setFloating}
        style={floatingStyles}
        {...getFloatingProps()}
        bgColor={bgColor}
        padding={padding}
        size={size}
      >
        {children}
        {arrowProps && <TooltipArrow {...arrowProps} />}
      </ToolTip>
    )}
  </>
)

type ToolTipProps = {
  size: Props['size']
  bgColor?: PaletteColor,
  padding?: string
}

const ToolTip = styled.div<ToolTipProps>`
  background-color: ${({ bgColor }) => bgColor || palette.grey100};
  padding: ${({ size, padding }) => (padding ?? (size === 'L' ? '12px 14px' : '8px'))};
  border-radius: 8px;
  ${({ size }) => (size === 'L' ? TextMLight : TextSLight)};
  color: ${palette.white};
  z-index: 900;
`
