import React from 'react'
import { FirstInputWrapper, SecondInputWrapper } from './Wrappers'
import { InputTypes, InputWrapperProps } from './types'

type Props = {
  type: InputTypes
} & InputWrapperProps

export const InputWrapper = ({
  type,
  ...props
}: Props) => {
  switch (type) {
    case 'second': return <SecondInputWrapper {...props} />
    case 'first': return <FirstInputWrapper {...props} />
    default: return null
  }
}
