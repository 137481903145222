import { attach } from 'effector'

import {
  EstimateLot,
  getCompanyContractReqFx,
  getEstimateLotAdvanceTypesReqFx,
  getEstimateLotDirectionsReqFx,
  getEstimateLotMorphologyReqFx,
  getEstimateLotReqFx,
  getEstimateLotTypesOfSecurityReqFx,
  getProjectsReqFx,
  postEstimateLotAttachmentsReqFx,
  updateEstimateLotReqFx,
} from '@/dal'
import { Option } from '@/ui'

import { d } from './domain'

export const openAdditionalModal = d.event()
export const openAttachmentsModal = d.event()

export const onLotInfoChanged = d.event()

export const updateDocumentInfo = d.event<EstimateLot>()

export const $projects = d.store<Option[]>([])
export const $directions = d.store<Option[]>([])
export const $contracts = d.store<Option[]>([])
export const $advanceTypes = d.store<Option[]>([])
export const $typesOfSecurity = d.store<Option[]>([])

export const getEstimateLotFx = attach({
  effect: getEstimateLotReqFx,
})

export const getDirectionsFx = attach({
  effect: getEstimateLotDirectionsReqFx,
})

export const getTypesOfSecurityFx = attach({
  effect: getEstimateLotTypesOfSecurityReqFx,
})

export const getProjectsFx = attach({
  effect: getProjectsReqFx,
})

export const getEstimateLotAdvanceTypesFx = attach({
  effect: getEstimateLotAdvanceTypesReqFx,
})

export const getContractsFx = attach({
  effect: getCompanyContractReqFx,
})

export const getMorphologyFx = attach({
  effect: getEstimateLotMorphologyReqFx,
})

export const updateEstimateLotFx = attach({
  effect: updateEstimateLotReqFx,
})

export const sendFilesFx = attach({
  effect: postEstimateLotAttachmentsReqFx,
})
