import { combine } from 'effector'

import { serviceLinks, services } from './const'
import { d } from './domain'
import { Service, ServiceAlias, ServiceReferences } from './types'

export const $isModalOpen = d.createStore<boolean>(false)

export const openModal = d.createEvent<void>()
export const closeModal = d.createEvent<void>()

export const $services = d.createStore<Service[]>(services)
export const $openedService = d.createStore<Service | null>(null)

export const openServiceLinks = d.createEvent<ServiceAlias>()
export const changeOpenedService = d.createEvent<Service | undefined>()
export const resetOpenedService = d.createEvent<void>()

export const $serviceLinks = d.createStore<ServiceReferences>(serviceLinks)

export const $importantServices = $services.map((services) => services.slice(0, 4))

export const $openedServiceLinks = combine(
  [$openedService, $serviceLinks],
  ([openedService, links]) => {
    if (!openedService) return null
    return links[openedService.alias].links ?? null
  },
)
