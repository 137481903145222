import React from 'react'
import {
  Placement,
  Strategy,
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react'

type Params = {
  sameComponentWidth?: boolean
  sameComponentHeight?: boolean
  strategy?: Strategy
  placement?: Placement,
  offsetNumber?: number,
  isDisable?: boolean
  escapeKey?: boolean
  minWidth?: string
  onOpen?: () => void
  onClose?: () => void
  onToggle?: (val: boolean) => void
}

export const usePopup = ({
  onOpen,
  onClose,
  onToggle,
  sameComponentWidth,
  sameComponentHeight,
  strategy = 'fixed',
  placement = 'bottom-start',
  offsetNumber = 10,
  isDisable,
  escapeKey = true,
  minWidth,
}: Params = {}) => {
  const arrowRef = React.useRef<SVGSVGElement>(null)
  const [isOpen, setIsOpen] = React.useState(false)
  const close = () => {
    setIsOpen(false)
    onClose?.()
  }

  const forceClose = () => {
    setIsOpen(false)
  }

  React.useEffect(() => {
    if (isDisable) setIsOpen(false)
  }, [isDisable])

  const open = () => {
    if (isDisable) return
    setIsOpen(true)
    onOpen?.()
  }

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: (isOpen) => {
      onToggle?.(isOpen)
      if (isOpen) {
        open()
      } else {
        close()
      }
    },
    middleware: [
      offset(offsetNumber),
      flip(),
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: sameComponentWidth && `${rects.reference.width}px`,
            minWidth,
            height: sameComponentHeight && `${rects.reference.height}px`,
          })
        },
      }),
      shift(),
      arrow({
        element: arrowRef,
      }),
    ],
    whileElementsMounted: autoUpdate,
    placement,
    strategy,
  })

  const click = useClick(context, {
    keyboardHandlers: false,
  })
  const dismiss = useDismiss(context, {
    escapeKey,
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ])

  const arrowProps = {
    ref: arrowRef,
    context,
  }

  return {
    refs,
    floatingStyles,
    getReferenceProps,
    getFloatingProps,
    open,
    close,
    forceClose,
    isOpen,
    arrowProps,
  }
}
