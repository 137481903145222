import { sample } from 'effector'

import { createToast } from '@/features/toast-service/model'
import { getDocumentNameByType } from '@/lib/documentResolvers'
import { DocumentAlias, EstimateDocId } from '@/dal'

import { $createDocumentInfo, copyDocumentFx } from './private'
import { onCreatedDocument } from './public'
import { createKorDocFx, onConfirmCreateKorDoc, onCopyKorDoc } from './create-kor-doc.private'

createToast({
  effect: createKorDocFx,
  doneText: `${getDocumentNameByType(DocumentAlias.KOR_DOC)} создана`,
})

sample({
  clock: onConfirmCreateKorDoc,
  source: $createDocumentInfo,
  filter: Boolean,
  fn: ({ contractId }) => contractId,
  target: createKorDocFx,
})

sample({
  clock: createKorDocFx.doneData,
  target: onCreatedDocument,
})

sample({
  clock: onCopyKorDoc,
  source: $createDocumentInfo,
  filter: (info) => Boolean(info?.discardedDoc),
  fn: (info) => ({
    id: info?.discardedDoc?.id as EstimateDocId,
    type: DocumentAlias.KOR_DOC as const,
  }),
  target: copyDocumentFx,
})
