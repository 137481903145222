import React from 'react'

import {
  NotificationItem,
  NotificationType,
} from '@/ui'

export const AccreditationTimeOut = () => (
  <NotificationItem
    body={(
      <>
        Получить доступ в личный кабинет временно невозможно,
        у вашей компании закончилась аккредитация.
        Подать документы на прохождение аккредитации можно на
        {' '}
        <a href="https://fsk.ru/about/tenders" target="_blank" rel="noreferrer">сайте</a>
        .
      </>
    )}
    type={NotificationType.Alert}
  />
)
