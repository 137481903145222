import { sample } from 'effector'

import { AppealRequestStatus } from '@/dal'
import { createToast } from '@/features/toast-service/model'

import {
  $messages,
  $selectedAppeal,
  getMessagesFx,
  resetSelectedAppeal,
  getAppealMessages,
  commentForm,
  completeTaskFx,
  markTaskAsRead,
  markTaskAsReadFx,
} from './appeals.private'
import { TasksAndResponsesGate } from './private'

$selectedAppeal
  .on(getAppealMessages, (_, data) => ({
    id: data.document_id,
    attachments: [],
    request_number: data.document_name,
    can_send_message: data.task_data.can_send_message,
    document_name: data.task_data.related_document_name ?? '',
    project_name: data.project_name ?? '',
    author: data.task_data.author ?? '',
  }))
  .reset(TasksAndResponsesGate.close, resetSelectedAppeal)

$messages
  .on(getMessagesFx.doneData, (_, data) => data)
  .reset(TasksAndResponsesGate.close, resetSelectedAppeal)

sample({
  clock: getAppealMessages,
  fn: ({ document_id }) => ({ id: document_id }),
  target: getMessagesFx,
})

sample({
  clock: markTaskAsRead,
  filter: ({ task_data }) => task_data.task_status === AppealRequestStatus.Unread,
  fn: ({ document_id }) => ({ id: document_id }),
  target: markTaskAsReadFx,
})

sample({
  clock: commentForm.formValidated,
  source: {
    item: $selectedAppeal,
    form: commentForm.$values,
  },
  filter: ({ item, form }) => Boolean(item?.id && (form.comment.trim() || form.files.length)),
  fn: ({ form, item }) => ({
    id: item?.id as number,
    result: form.comment,
    files: form.files,
  }),
  target: completeTaskFx,
})

sample({
  clock: completeTaskFx.done,
  target: resetSelectedAppeal,
})

sample({
  clock: resetSelectedAppeal,
  target: commentForm.reset,
})

createToast({
  effect: completeTaskFx,
})
