import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'
import { Link, useHistory } from 'react-router-dom'

import { $accessToken } from '@/dal/auth'
import {
  HeadXL,
  NewButton,
  palette,
  TextLLight,
  WINDOW_WIDTH,
} from '@/ui'
import Logo from '@/ui/assets/LogoWhite.svg'
import { RouterPaths } from '@/features/app/model'

import {
  $checkedRequest,
  $isSubscribe,
  getSubscribe,
  postMaintenanceFx,
  resetCheckedRequest,
} from '../../model/private'

export const MaintenancePage = () => {
  const isSubscribe = useUnit($isSubscribe)
  const loading = useUnit(postMaintenanceFx.pending)
  const accessToken = useUnit($accessToken)

  const checkedRequest = useUnit($checkedRequest)
  const history = useHistory()

  React.useEffect(() => {
    if (checkedRequest) {
      history.replace(RouterPaths.Home)
      return resetCheckedRequest()
    }
  }, [history, checkedRequest])

  return (
    <>
      <NavBarContainer to={RouterPaths.Login}>
        <LogoLink to={RouterPaths.Home}>
          <Logo />
        </LogoLink>
      </NavBarContainer>
      <Container>
        <Window>
          <Title>Технические работы</Title>
          <Text>
            В настоящее время на сайте проводятся технические работы. Попробуйте
            зайти позже.
          </Text>
          <NewButton
            label="Оповестить меня об окончании работ"
            isDisabled={isSubscribe || loading || !accessToken}
            dataTestId="maintenance-button"
            isFill
            onClick={getSubscribe}
          />
        </Window>
      </Container>
    </>
  )
}

const NavBarContainer = styled(Link)`
  position: absolute;
  top: 20px;
  left: 40px;
`

const LogoLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  height: 22px;
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Window = styled.div`
  max-width: 572px;
  padding: 32px;
  text-align: center;
  background-color: ${palette.white};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  margin: 0px 10px;
  border-radius: 20px;

  @media (max-width: ${WINDOW_WIDTH.tablet - 1}px) {
    padding: 24px;
  }
`

const Title = styled.div`
  ${HeadXL}
  margin-bottom: 14px;
`

const Text = styled.div`
  ${TextLLight}
  max-width: 492px;
  margin-bottom: 24px;
`
