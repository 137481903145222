import { attachWrapper } from '@42px/effector-extra'
import { AxiosError } from 'axios'
import { attach } from 'effector'

import { prepareFormData } from '@/lib/form-data'
import {
  Method,
  authRequestFx,
  downloadFilesFx,
} from '../request'
import {
  AbortableRequest,
  Contract,
  CopyDocumentResponse,
  DocumentAlias,
  EstimateDocId,
  FileDTO,
  PaginationRequestParams,
  PaginationType,
  Response,
} from '../interfaces'
import { defaultErrorHandler } from '../error-handler'
import {
  EstimateDoc,
  GetEstimateTableParams,
  PostAttachmentsParams,
  SendSummaryInfoParams,
  EstimateTableRow,
  CreateEstimateRowParams,
  CreateEstimateFolderParams,
  SplitVolumeParams,
  GetInfoForCreateRowResponse,
  UpdateEstimateRowParams,
  GetEstimateRowCommentsParams,
  EstimateRowCommentDTO,
  DefaultChangedTableResponse,
  DeleteResetTableItemParams,
  EstimateCorrectionErrorData,
  GetEstimateFolderRowsParams,
  PostTableItemAttachmentsParams,
  SendEstimateToApproveParams,
  GetEstimateTreeTableResponse,
  ResetKorDocRowsPayload,
  UpdateMultipleEstimateRowsParams,
  UpdateMultipleEstimateRowsResponse,
} from './types'
import { estimateCorrectionErrorHandler } from './error-handler'

export const getEstimateCorrectionsByContractIdReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, signal }: AbortableRequest<{ id: Contract['id'] }>) => ({
    method: Method.get,
    url: `/kor_doc/kor_doc_by_contract/${id}`,
    signal,
  }),
  mapResult: ({ result }: Response<EstimateDoc[]>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const createEstimateCorrectionReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (contractId: Contract['id']) => ({
    method: Method.post,
    url: '/kor_doc/doc/',
    query: {
      contract: contractId,
    },
  }),
  mapResult: ({ result }: Response<EstimateDoc>) => result.data,
  mapError: ({ error }: { error: AxiosError<EstimateCorrectionErrorData> }) => (
    estimateCorrectionErrorHandler(error.response?.data)
  ),
})

export const getEstimateInfoByIdReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, signal }: AbortableRequest<{ id: EstimateDocId }>) => ({
    method: Method.get,
    url: `/kor_doc/doc/${id}`,
    signal,
  }),
  mapResult: ({ result }: Response<EstimateDoc>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const postEstimateAttachmentsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, ...body }: PostAttachmentsParams) => ({
    method: Method.post,
    url: `/files/by_document/${DocumentAlias.KOR_DOC}/${id}/`,
    body: prepareFormData({ ...body, files: undefined }),
  }),
  mapResult: ({ result }: Response<FileDTO[]>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const sendEstimateToApproveReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, ...body }: SendEstimateToApproveParams) => ({
    method: Method.post,
    url: `/kor_doc/doc/approve/${id}`,
    body: prepareFormData({ ...body, files: undefined }),
  }),
  mapResult: ({ result }: Response<EstimateDoc>) => result.data,
  mapError: ({ error }: { error: AxiosError<EstimateCorrectionErrorData> }) => (
    estimateCorrectionErrorHandler(error.response?.data)
  ),
})

export const cancelEstimateApproveReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, reason }: { id: EstimateDocId, reason?: string }) => ({
    method: Method.post,
    url: `/kor_doc/reject/${id}`,
    body: { reason },
  }),
  mapResult: ({ result }: Response<EstimateDoc>) => result.data,
  mapError: ({ error }: { error: AxiosError<EstimateCorrectionErrorData> }) => (
    estimateCorrectionErrorHandler(error.response?.data)
  ),
})

export const deleteEstimateCorrectionReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (id: EstimateDocId) => ({
    method: Method.delete,
    url: `/kor_doc/doc/${id}`,
  }),
  mapResult: ({ result }: Response<void>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const downloadApprovingDocumentsReqFx = attach({
  effect: downloadFilesFx,
  mapParams: (id: EstimateDocId) => ({
    url: `/kor_doc/doc/download/${id}/`,
  }),
})

export const donwloadComercialOfferReqFx = attach({
  effect: downloadFilesFx,
  mapParams: (id: EstimateDocId) => ({
    url: `/download/?id=${id}`,
  }),
})

export const sendSummaryInfoReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, ...body }: SendSummaryInfoParams) => ({
    method: Method.post,
    url: `/kor_doc/doc/${id}/update-fields/`,
    body,
  }),
  mapResult: ({ result }: Response<EstimateDoc['summary_table']>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const updateFeaturesInfoReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (id: EstimateDocId) => ({
    method: Method.get,
    url: `/kor_doc/doc/${id}/features/`,
  }),
  mapResult: ({ result }: Response<{ features: EstimateDoc['features'] }>) => result.data.features,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const getEstimateTreeTableReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({
    signal,
    documentId,
    types,
    ...query
  }: AbortableRequest<GetEstimateTableParams>) => ({
    method: Method.get,
    url: `/kor_doc/hierarchy/${documentId}/`,
    query: {
      types: types?.join(',') || undefined,
      ...query,
    },
    signal,
  }),
  mapResult: ({
    result,
  }: Response<GetEstimateTreeTableResponse>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const getEstimateFlatTableReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({
    signal,
    documentId,
    types,
    ...query
  }: AbortableRequest<GetEstimateTableParams & PaginationRequestParams>) => ({
    method: Method.get,
    url: `/kor_doc/flat/${documentId}/`,
    query: {
      types: types?.join(',') || undefined,
      ...query,
    },
    signal,
  }),
  mapResult: ({
    result,
  }: Response<PaginationType<EstimateTableRow[]>>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const getEstimateFolderRowsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({
    signal,
    documentId,
    types,
    ...query
  }: AbortableRequest<GetEstimateFolderRowsParams>) => ({
    method: Method.get,
    url: `/kor_doc/hierarchy/${documentId}/`,
    query: {
      types: types?.join(',') || undefined,
      ...query,
    },
    signal,
  }),
  mapResult: ({ result }: Response<GetEstimateTreeTableResponse>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const createEstimateRowReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({
    documentId,
    is_tree,
    ...body
  }: CreateEstimateRowParams) => ({
    method: Method.post,
    url: `/kor_doc/doc/${documentId}/item/`,
    query: {
      is_tree,
    },
    body: prepareFormData(body),
  }),
  mapResult: ({ result }: Response<DefaultChangedTableResponse>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const createEstimateFolderReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({
    documentId,
    is_tree,
    ...body
  }: CreateEstimateFolderParams) => ({
    method: Method.post,
    url: `/kor_doc/doc/${documentId}/create-group/`,
    query: {
      is_tree,
    },
    body,
  }),
  mapResult: ({ result }: Response<DefaultChangedTableResponse>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const getEstimateRowIndexReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, types, ...query }: GetEstimateTableParams & { id: EstimateTableRow['id'] }) => ({
    method: Method.get,
    url: `/kor_doc/flat-view/get-item-offset/${id}/`,
    query: {
      types: types?.join(',') || undefined,
      ...query,
    },
  }),
  mapResult: ({ result }: Response<{ index_number: number }>) => result.data.index_number,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const splitVolumeReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({
    itemId,
    is_tree,
    ...body
  }: SplitVolumeParams) => ({
    method: Method.post,
    url: `/kor_doc/item/${itemId}/split-volume/`,
    query: {
      is_tree,
    },
    body: prepareFormData({ ...body, files: undefined }),
  }),
  mapResult: ({ result }: Response<DefaultChangedTableResponse>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const getKorDocDirectoriesReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, signal }: AbortableRequest<{ id: EstimateDocId }>) => ({
    method: Method.get,
    url: `/kor_doc/doc/${id}/directories/`,
    signal,
  }),
  mapResult: ({ result }: Response<GetInfoForCreateRowResponse>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const updateEstimateItemReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({
    documentId,
    itemId,
    isTree: is_tree,
    isFolder,
    ...body
  }: UpdateEstimateRowParams) => ({
    method: Method.put,
    url: isFolder ? `/kor_doc/doc/${documentId}/groups/${itemId}/` : `/kor_doc/doc/${documentId}/item/${itemId}/`,
    query: {
      is_tree,
    },
    body,
  }),
  mapResult: ({ result }: Response<DefaultChangedTableResponse>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const updateMultipleKorDocItemsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ documentId, items }: UpdateMultipleEstimateRowsParams) => ({
    method: Method.put,
    url: `/kor_doc/doc/${documentId}/item/bulk_update/`,
    body: {
      items,
    },
  }),
  mapResult: ({ result }: Response<UpdateMultipleEstimateRowsResponse>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const getEstimateItemCommentsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ documentId, itemId, signal }: GetEstimateRowCommentsParams) => ({
    method: Method.get,
    url: `/kor_doc/doc/${documentId}/item/${itemId}/comments/`,
    signal,
  }),
  mapResult: ({ result }: Response<EstimateRowCommentDTO[]>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const resetEstimateTableReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (id: EstimateDocId) => ({
    method: Method.put,
    url: `/kor_doc/doc/${id}/item/reset/`,
  }),
  mapResult: ({ result }: Response<void>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const resetEstimateTableItemReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ documentId, itemId, isTree }: Omit<DeleteResetTableItemParams, 'isFolder'>) => ({
    method: Method.put,
    url: `/kor_doc/doc/${documentId}/item/${itemId}/reset/`,
    query: {
      is_tree: isTree,
    },
  }),
  mapResult: ({ result }: Response<DefaultChangedTableResponse>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const deleteTableItemReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({
    documentId,
    itemId,
    isTree,
    isFolder,
  }: DeleteResetTableItemParams) => ({
    method: Method.delete,
    url: isFolder ? `/kor_doc/doc/${documentId}/groups/${itemId}/` : `/kor_doc/doc/${documentId}/item/${itemId}/`,
    query: {
      is_tree: isTree,
    },
  }),
  mapResult: ({ result }: Response<DefaultChangedTableResponse>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const checkValidationBeforeApproveReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, signal }: AbortableRequest<{ id: EstimateDocId }>) => ({
    method: Method.get,
    url: `/kor_doc/doc/check_validae/${id}`,
    signal,
  }),
  mapResult: ({ result }: Response<void>) => result.data,
  mapError: ({ error }: { error: AxiosError<EstimateCorrectionErrorData> }) => (
    estimateCorrectionErrorHandler(error.response?.data)
  ),
})

export const postAttachmentsForTableItemReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, ...body }: PostTableItemAttachmentsParams) => ({
    method: Method.post,
    url: `/files/by_document/kor_doc_str/${id}/`,
    body: prepareFormData({ ...body, files: undefined }),
  }),
  mapResult: ({ result }: Response<FileDTO[]>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const copyKorDocReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (id: EstimateDocId) => ({
    method: Method.post,
    url: `/kor_doc/doc/${id}/copy/`,
  }),
  mapResult: ({ result }: Response<CopyDocumentResponse<EstimateDoc>>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const resetKorDocRowsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, ...body }: ResetKorDocRowsPayload) => ({
    method: Method.put,
    url: `/kor_doc/doc/${id}/item/items_reset/`,
    body,
  }),
  mapResult: ({ result }: Response<void>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})
