import React from 'react'
import styled from 'styled-components'

import { getNoun } from '@/lib/text-noun'

import { palette, PaletteColor } from '../palette'
import { DefaultButton } from '../buttons'
import { TextL } from '../typography'

type Props = {
  text: string
  count: number
  countColor?: PaletteColor
  onClick: () => void
}

export const SpecificDocumentCounter = ({
  text, count, countColor = 'accent100', onClick,
}: Props) => {
  const docNoun = getNoun(count ?? 0, 'документ', 'документа', 'документов')

  return (
    <Wrapper disabled={!count} onClick={onClick}>
      <span>
        {text}
      </span>
      <Count color={countColor}>
        {count}
      </Count>
      <span>
        {docNoun}
      </span>
    </Wrapper>
  )
}

const Wrapper = styled(DefaultButton)<{ disabled: boolean }>`
  ${TextL}
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  padding: 6px 12px;
  border-radius: 4px;
  background-color: ${palette.grey40};

  &:disabled {
    opacity: 0.5;
    cursor: unset;
  }
`

const Count = styled.div<{ color: PaletteColor }>`
  ${TextL}
  color: ${({ color }) => palette[color]};
`
