import { DocumentAlias } from '@/dal'
import { RouterPaths } from '@/features/app/model'

const map: { [key in RouterPaths]?: DocumentAlias } = {
  [RouterPaths.ActPage]: DocumentAlias.ACT,
  [RouterPaths.Payment]: DocumentAlias.PAYMENT,
  [RouterPaths.FundingSchedule]: DocumentAlias.BUDGET,
  [RouterPaths.PlanPage]: DocumentAlias.BUDGET,
  [RouterPaths.EstimateCorrection]: DocumentAlias.KOR_DOC,
  [RouterPaths.Administrative]: DocumentAlias.CONTRACT,
  [RouterPaths.AdditionalAgreement]: DocumentAlias.ADDITIONAL_AGREEMENT,
  [RouterPaths.Compensation]: DocumentAlias.COMPENSATION_DOCUMENT,
}

export const getDocumentAliasByRouterPath = (path: RouterPaths) => map[path] ?? null
