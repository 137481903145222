import { sample } from 'effector'

import { showToast } from '@/features/toast-service/model'
import { NotificationType } from '@/ui'
import { onGetSubmissionMonths, resetSubmissionMonths } from '@/features/funding-schedule/shared-model'

import {
  $modalPlanEditPeriodVisible,
  planEditPeriodForm,
  closePlanEditPeriodModal,
  editPlanPeriodFx,
} from './private'
import { showPlanEditPeriodModal } from './public'
import { $openedFundPlan, updateOpenedPlan } from '../../plan-info/model'

$modalPlanEditPeriodVisible
  .on(showPlanEditPeriodModal, () => true)
  .reset(closePlanEditPeriodModal)

sample({
  clock: showPlanEditPeriodModal,
  source: $openedFundPlan,
  fn: (payload) => ({
    date: payload?.date_start,
  }),
  target: planEditPeriodForm.set,
})

sample({
  clock: sample({
    clock: planEditPeriodForm.formValidated,
    source: $openedFundPlan,
    filter: Boolean,
  }),
  source: planEditPeriodForm.$values,
  fn: ({ date }, { id }) => ({
    date_start: date,
    id,
  }),
  target: editPlanPeriodFx,
})

sample({
  clock: editPlanPeriodFx.failData,
  fn: (data: any) => ({
    content: data[0],
    icon: NotificationType.Alert,
  }),
  target: showToast,
})

sample({
  clock: editPlanPeriodFx.done,
  target: closePlanEditPeriodModal,
})

sample({
  clock: editPlanPeriodFx.doneData,
  fn: (payload) => payload,
  target: updateOpenedPlan,
})

sample({
  clock: showPlanEditPeriodModal,
  source: $openedFundPlan,
  filter: Boolean,
  fn: ({ estimate }) => estimate,
  target: onGetSubmissionMonths,
})

sample({
  clock: closePlanEditPeriodModal,
  target: resetSubmissionMonths,
})
