import React from 'react'
import { useUnit } from 'effector-react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { $newArticleCount } from '@/features/articles'
import { $activeTaskCount } from '@/features/tasks-and-responses/model'
import { Icon2, palette } from '@/ui'
import { RouterPaths } from '@/features/app/model'

export const Notifications = React.memo(() => {
  const notificationCount = useUnit($newArticleCount)
  const activeTaskCount = useUnit($activeTaskCount)

  return (
    <ActionsContainer>
      <IconButtonsContainer>
        <Link to={RouterPaths.Home} target="_blank">
          <NotificationIconWrapper>
            <Icon2 icon="listChecks" color="white" size={24} />
            {Boolean(activeTaskCount) && (
              <NotificationCountWrapper>
                <NotificationCount>
                  {activeTaskCount}
                </NotificationCount>
              </NotificationCountWrapper>
            )}
          </NotificationIconWrapper>
        </Link>

        <Link to={RouterPaths.Home} target="_blank">
          <NotificationIconWrapper>
            <Icon2 icon="bell" color="white" size={24} />
            {Boolean(notificationCount) && (
              <NotificationCountWrapper>
                <NotificationCount>
                  {notificationCount}
                </NotificationCount>
              </NotificationCountWrapper>
            )}
          </NotificationIconWrapper>
        </Link>
      </IconButtonsContainer>
    </ActionsContainer>
  )
})


const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  margin-left: auto;
`

const IconButtonsContainer = styled.div`
  display: flex;
  gap: 12px;
`

const NotificationCountWrapper = styled.div`
  position: absolute;
  top: -2px;
  left: calc(50% + 1px);
  padding: 3px 4px 2px;
  border-radius: 999px;
  background-color: ${palette.red100};
`

const NotificationIconWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  transition: background-color 0.15s linear;

  @media (hover: hover) {
    &:hover {
      background-color: rgba(${palette.rgbGrey}, 0.4);
    }
  }
`

const NotificationCount = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 1;
  color: ${palette.white};
`
