import { attach } from 'effector'
import { createForm } from 'effector-forms'

import {
  getCompensationInfoReqFx,
  CompensationDocument,
  postCompensationAttachmentsReqFx,
  approveCompensationReqFx,
} from '@/dal'
import { createAttachmentsModalModel } from '@/features/factories/attach-modal'
import { rules, withDisableCondition } from '@/lib/form-validators'

import { d } from './domain'
import { WithComment } from './types'

export const $documentInfo = d.store<CompensationDocument | null>(null)
export const updateDocumentInfo = d.event<CompensationDocument>()

export const $documentId = $documentInfo.map((info) => info?.id ?? null)

export const openAdditionalModal = d.event()

export const $documentAttachmentsCount = $documentInfo
  .map((info) => info?.attachments?.length ?? null)

export const openAttachmentsModal = d.event()
export const updateAttachments = d.event<CompensationDocument['attachments']>()

export const $canSendFiles = $documentInfo.map((info) => (
  Boolean(info?.features.can_add_attachments)),
)

export const $isApprovingModalOpen = d.store(false)
export const openApprovingModal = d.event()
export const closeApprovingModal = d.event()

export const $isCostCalculationModalOpen = d.store(false)
export const openCostCalculationModal = d.event()
export const closeCostCalculationModal = d.event()

export const $tabItems = d.store([{ label: 'Без комментария', value: WithComment.No }, { label: 'С комментарием', value: WithComment.Yes }])
export const $isWithComment = d.store<WithComment>(WithComment.No)
export const changeWithComment = d.event<WithComment>()

export const MAX_COMMENT_LENGTH = 1000

const isCommentRequired = () => {
  const isWithComment = $isWithComment.getState()
  return isWithComment === WithComment.Yes
}

export const approveForm = createForm({
  fields: {
    comment: {
      init: '',
      rules: [
        withDisableCondition(isCommentRequired, rules.required()),
        rules.maxLength(MAX_COMMENT_LENGTH),
      ],
    },
  },
})

export const sendFilesFx = attach({
  effect: postCompensationAttachmentsReqFx,
})

export const attachmentsModel = createAttachmentsModalModel({
  domain: d,
  sendFx: sendFilesFx,
  $canSendFiles,
  $files: $documentInfo.map((info) => info?.attachments ?? null),
})

export const getCompensationInfoFx = attach({
  effect: getCompensationInfoReqFx,
})

export const approveCompensationFx = attach({
  effect: approveCompensationReqFx,
})
