import styled from 'styled-components'

import { TABLE_BORDER_STYLE, palette } from '@/ui'

import { FirstCellWrapper } from './cellWrappers'
import { TableGridWrapper } from './TableGridWrapper'

export const HeaderRowWrapper = styled(TableGridWrapper)`
  background-color: ${palette.white};
`

export const TableRowWrapper = styled(TableGridWrapper)`
  position: relative;
  background-color: ${palette.white};
  border-bottom: ${TABLE_BORDER_STYLE};
  transition: background-color 0.2s linear;

  &:hover {
    &, ${FirstCellWrapper} {
      background-color: ${palette.grey10};
    }
  }
`
