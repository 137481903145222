import React from 'react'
import styled from 'styled-components'

import { EstimateTableRow } from '@/dal'
import { CellTextArea, OverflowText, palette } from '@/ui'

type Props = {
  id: EstimateTableRow['id']
  isEditMode: boolean
  value: string
  isEditable: boolean
  name: string | React.JSX.Element
  onChange: (val: string) => void
  isValid: boolean
}

export const NameField = ({
  isEditMode,
  isEditable,
  isValid,
  value,
  id,
  name,
  onChange,
}: Props) => {
  if (isEditMode && onChange && isEditable) {
    return (
      <TextAreaWrapper>
        <CellTextArea
          dataTestId={`row-name-${id}`}
          value={value}
          onChange={onChange}
          isInvalid={!isValid}
          maxHeight={350}
          autoGrow
          popupWidth={345}
          placeholder="Введите наименование"
          maxLines={2}
        />
      </TextAreaWrapper>
    )
  }
  return (
    <OverflowText
      maxTooltipWidth={350}
      maxLines={2}
    >
      {name}
    </OverflowText>
  )
}

const TextAreaWrapper = styled.div`
  background-color: ${palette.white};
  position: relative;
  z-index: 2;
`
