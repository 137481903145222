import { EstimateRowCommentDTO, EstimateTableRow, getEstimateItemCommentsReqFx } from '@/dal'

import { createSingleEffect } from '@/lib/createSingleEffect'
import { d } from './domain'

export const $openedCommentsRowId = d.store<EstimateTableRow['id'] | null>(null)
export const openCommentsModal = d.event<EstimateTableRow['id']>()
export const closeCommentsModal = d.event<void>()

export const $commentsList = d.store<EstimateRowCommentDTO[]>([])

export const $oldCommentsCount = $commentsList.map((comments) => comments.reduce((acc, item) => {
  if (item.is_new) return acc
  return acc + 1
}, 0))

export const $newCommentsCount = $commentsList
  .map((comments) => comments.reduce((acc, item) => {
    if (!item.is_new) return acc
    return acc + 1
  }, 0))

export const {
  requestFx: getEstimateItemCommentsFx,
} = createSingleEffect(
  getEstimateItemCommentsReqFx,
  { abortEvents: [closeCommentsModal] },
)

export const resetComments = d.event<void>()
