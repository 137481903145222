import React from 'react'
import styled from 'styled-components'

import { PaletteColor, palette } from '../../palette'
import { TextSLight } from '../../typography'

type Props = {
  items: {
    color: PaletteColor
    percent: number
  }[] | null
}

export const ProgressBar = ({ items }:Props) => {
  if (!items) return null
  const mappedItem = React.useMemo(() => items
    .slice().sort((a, b) => b.percent - a.percent), [items])

  const percentString = React.useMemo(() => items.reduce((val, { percent }, index) => {
    if (index !== 0) return `${val}/${Math.round(percent)}%`
    return `${Math.round(percent)}%`
  }, ''), [items])

  return (
    <Wrapper>
      <BarTrack>
        {mappedItem.map((item) => <Bar key={item.color} {...item} />)}
      </BarTrack>
      <Percentages>
        {percentString}
      </Percentages>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const BarTrack = styled.div`
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  height: 8px;
  border-radius: 100px;
  background-color: ${palette.grey20};
`

const Bar = styled.div<{color: PaletteColor, percent: number}>`
  border-radius: 100px;
  background-color: ${({ color }) => palette[color]};
  width: ${({ percent }) => `${percent}%`};
  left: 0;
  height: 100%;
  top: 0;
  position: absolute;
`

const Percentages = styled.div`
  ${TextSLight}
  color: ${palette.grey70};
`
