type Params = {
  value: File[],
  fileName: File['name']
  onChange: (v: File[]) => File[]
}

export const handleDeleteFileHelper = ({
  value,
  fileName,
  onChange,
}: Params) => {
  const newVal = [
    ...value.filter((item) => item.name !== fileName),
  ]
  onChange(newVal)
}
