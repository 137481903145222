import React, { FormEvent, memo } from 'react'
import styled from 'styled-components'
import { useForm } from 'effector-forms'
import { useUnit } from 'effector-react'

import { InputField, NumberInputField } from '@/ui'
import { useCheckFieldsDirty } from '@/lib/hooks/useCheckFieldsDirty'
import { ButtonsRow, ModalHeader } from '@/features/support/shared'

import { addUserFx, createAppealModal } from '../../model/private'
import { addUserForm } from '../../model/forms'

export const AddUserForm = memo(() => {
  const isPending = useUnit(addUserFx.pending)

  const { fields, submit, isValid } = useForm(addUserForm)

  const areFieldsDirty = useCheckFieldsDirty(fields)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    submit()
  }

  return (
    <Wrapper onSubmit={handleSubmit}>
      <ModalHeader
        title="Добавить пользователя в ЛКП"
        subtitle="После добавления пользователя на его эл. почту придет письмо о регистрации в ЛКП"
      />

      <Fields>
        <InputField
          label="Фамилия"
          placeholder="Введите фамилию пользователя"
          value={fields.last_name.value}
          error={fields.last_name.firstError?.errorText}
          size="L"
          disabled={isPending}
          dataTestId=""
          isRequired
          onChange={fields.last_name.onChange}
          onClear={() => fields.last_name.set('')}
        />
        <InputField
          label="Имя"
          placeholder="Введите имя пользователя"
          value={fields.first_name.value}
          error={fields.first_name.firstError?.errorText}
          size="L"
          disabled={isPending}
          dataTestId=""
          isRequired
          onChange={fields.first_name.onChange}
          onClear={() => fields.first_name.set('')}
        />
        <InputField
          label="Отчетство"
          placeholder="Введите отчество пользователя"
          value={fields.patronymic.value}
          error={fields.patronymic.firstError?.errorText}
          size="L"
          disabled={isPending}
          dataTestId=""
          isRequired
          onChange={fields.patronymic.onChange}
          onClear={() => fields.patronymic.set('')}
        />
        <InputField
          label="Должность"
          placeholder="Введите название должности"
          value={fields.user_info.value}
          error={fields.user_info.firstError?.errorText}
          size="L"
          disabled={isPending}
          dataTestId=""
          isRequired
          onChange={fields.user_info.onChange}
          onClear={() => fields.user_info.set('')}
        />
        <NumberInputField
          label="Телефон"
          dataTestId=""
          disabled={isPending}
          size="L"
          placeholder="+7"
          type="tel"
          format="+7 ### ### ## ##"
          allowEmptyFormatting
          mask="_"
          isRequired
          value={fields.phone.value.replace('+7', '')}
          error={fields.phone.firstError?.errorText}
          onChange={fields.phone.onChange}
          onClear={() => fields.phone.set('')}
        />
        <InputField
          label="E-mail"
          placeholder="Введите почту"
          value={fields.email.value}
          error={fields.email.firstError?.errorText}
          size="L"
          disabled={isPending}
          dataTestId=""
          isRequired
          onChange={fields.email.onChange}
          onClear={() => fields.email.set('')}
        />
      </Fields>

      <ButtonsRow
        submitLabel="Отправить"
        isPending={isPending}
        isDisabled={!isValid || !areFieldsDirty}
        hasScreenshotButton={false}
        onClose={createAppealModal.close}
        onSubmit={submit}
      />
    </Wrapper>
  )
})

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;

  width: 542px;
  padding: 32px;
`

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
