import React from 'react'
import { usePopperTooltip } from 'react-popper-tooltip'
import styled, { css } from 'styled-components'

import { Icon } from '@/ui/Icon'
import { PopperToolTip } from '@/ui/PopperToolTip'
import { ResetStyleButton } from '@/ui/ResetStyleButton'
import { themeVar } from '@/ui/theming'

type Props = {
  isOpen: boolean
  onClick: () => void
  tooltip?: string | JSX.Element
}

export const ExpandButton = ({ isOpen, tooltip, onClick }: Props) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    placement: 'top',
  })

  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()
      onClick()
    },
    [],
  )

  return (
    <>
      {tooltip && (
        <PopperToolTip
          getArrowProps={getArrowProps}
          getTooltipProps={getTooltipProps}
          setTooltipRef={setTooltipRef}
          tooltip={tooltip}
          visible={visible}
        />
      )}
      <Button isOpen={isOpen} ref={setTriggerRef} onClick={handleClick}>
        <Icon icon="accordion-arrow" />
      </Button>
    </>
  )
}

const ActiveButtonStyle = css`
  background-color: ${themeVar('main')};
  color: ${themeVar('whiteText')};

  & svg {
    transform: rotate(180deg);
  }
`

const Button = styled(ResetStyleButton)<{ isOpen: boolean }>`
  width: 19px;
  height: 11px;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${themeVar('orange3')};
  color: ${themeVar('main')};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  transition: all 0.15s linear;

  & svg {
    width: 9px;
    height: 5px;
    transition: transform 0.15s linear;
  }

  ${({ isOpen }) => isOpen && ActiveButtonStyle}

  &:hover {
    ${ActiveButtonStyle}
  }
`
