import { sample } from 'effector'
import { $data, ChartGate, getChartDataFx } from './private'

$data
  .on(getChartDataFx.doneData, (_, data) => data)
  .reset(ChartGate.close)

sample({
  clock: ChartGate.open,
  target: getChartDataFx,
})
