import styled from 'styled-components'
import { PaletteColor, getColor } from '../palette'

export const Square = styled.div<{ color: PaletteColor | 'transparent' }>`
  min-height: 8px;
  min-width: 8px;
  max-height: 8px;
  max-width: 8px;
  background-color: ${({ color }) => getColor(color)};
  border-radius: 1px;
`
