import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'

import {
  OverflowText, palette, PaletteColor, TABLE_BORDER_STYLE, TextMLight,
} from '@/ui'
import { numberWithSpaces } from '@/lib/number-represent'

import { LIGHT_GREY_COLOR } from './const'

export type CellProps = {
  hasLightGreyBackground?: boolean
  align?: 'left' | 'center' | 'right'
  isGrey?: boolean
  valueType?: 'string' | 'number'
  decimalScale?: number
  bgColor?: PaletteColor
  padding?: string | number
  hasRightDivider?: boolean
  borderBottomColor?: PaletteColor
} & ({
  children?: React.ReactNode
  value?: never
} | {
  value: string | null
  children?: never
})

export const Cell = ({
  children,
  value,
  hasLightGreyBackground = false,
  align = 'center',
  isGrey = false,
  valueType = 'number',
  decimalScale = 2,
  bgColor = 'white',
  padding = 12,
  hasRightDivider = false,
  borderBottomColor,
}: CellProps) => {
  const isZero = useMemo(() => (
    valueType === 'number' && parseFloat(value ?? '0') <= 0
  ), [valueType, value])

  const content = useMemo(() => {
    if (valueType === 'string') {
      return value ?? ''
    }
    return !value || isZero
      ? '0'
      : numberWithSpaces(value, { dot: ',', decimalScale })
  }, [value, valueType, isZero])

  return (
    <Wrapper
      align={align}
      bgColor={bgColor}
      hasLightGreyBackground={hasLightGreyBackground}
      padding={padding}
      hasRightDivider={hasRightDivider}
      borderBottomColor={borderBottomColor}
    >
      {value !== undefined ? (
        <Value isGrey={isGrey || isZero}>
          <OverflowText maxLines={4} maxTooltipWidth={500}>
            {content}
          </OverflowText>
        </Value>
      ) : children}
    </Wrapper>
  )
}

type WrapperProps = Required<
  Pick<CellProps, 'align' | 'hasLightGreyBackground' | 'bgColor' | 'padding' | 'hasRightDivider'>
> &
  Pick<CellProps, 'borderBottomColor'>

const Wrapper = styled.div<WrapperProps>`
  ${TextMLight}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: ${({ padding }) => (typeof padding === 'string' ? padding : `${padding}px`)};
  border-bottom: ${TABLE_BORDER_STYLE};
  color: ${palette.grey70};
  text-align: center;

  &:not(:last-child) {
    border-right: ${TABLE_BORDER_STYLE};
  }

  ${({ align }) => align === 'left' && css`
    align-items: flex-start;
    text-align: left;
  `}

  ${({ align }) => align === 'right' && css`
    align-items: flex-end;
    text-align: right;
  `}

  ${({ bgColor }) => bgColor && css`
    background-color: ${palette[bgColor]};
  `}

  ${({ hasLightGreyBackground }) => hasLightGreyBackground && css`
    background-color: ${LIGHT_GREY_COLOR};
  `}

  ${({ hasRightDivider }) => hasRightDivider && css`
    border-right-width: 4px !important;
  `}

  ${({ borderBottomColor }) => borderBottomColor && css`
    border-bottom-color: ${palette[borderBottomColor]};
  `}
`

const Value = styled.div<{ isGrey: boolean }>`
  ${TextMLight}

  ${({ isGrey }) => isGrey && css`
    color: ${palette.grey70};
  `}
`
