import { ContractItem } from '@/dal'

import { d } from './domain'
import { closeContractFx } from './private'
import { CloseContractParams } from './types'

export const onCloseContract = d.event<CloseContractParams>()
export const onGetRetention = d.event<ContractItem['id']>()

export const $isClosing = closeContractFx.pending
export const onUpdatedContractItem = d.event<ContractItem>()
