import React, { memo } from 'react'
import { useUnit } from 'effector-react'

import { NewDropDownInput, NewInput } from '@/ui'

import {
  $availableBuildings,
  $availableNames,
  $availableObjects,
  $availableSections,
  $availableUnits,
  $filters,
  $isDescriptionExpanded,
  $isEditMode,
  removeFilter,
  setFilter,
  setIsDescriptionExpanded,
} from '../../model/private'
import { FilterCell, GridRow, RangeFilterCell } from '../parts'
import { ROW_TYPE_OPTIONS } from '../../model/const'

export const TableFilters = memo(() => {
  const [filters, isEditMode, isFirstGroupOpen] = useUnit([
    $filters,
    $isEditMode,
    $isDescriptionExpanded,
  ])

  const [names, units, objects, buildings, sections] = useUnit([
    $availableNames,
    $availableUnits,
    $availableObjects,
    $availableBuildings,
    $availableSections,
  ])

  const isDisabled = isEditMode

  return (
    <GridRow isEditMode={isEditMode} isFirstGroupOpen={isFirstGroupOpen}>
      <FilterCell label="Объект">
        <NewDropDownInput
          placeholder="Объект"
          options={objects}
          selectedId={filters.object_id}
          minWidth="200px"
          isDisabled={isDisabled}
          dataTestId=""
          hasClear
          onOptionClick={(val) => setFilter({ key: 'object_id', val })}
        />
      </FilterCell>

      <FilterCell label="Корпус">
        <NewDropDownInput
          placeholder="Корпус"
          options={buildings}
          selectedId={filters.building_id}
          minWidth="200px"
          isDisabled={isDisabled}
          dataTestId=""
          hasClear
          onOptionClick={(val) => setFilter({ key: 'building_id', val })}
        />
      </FilterCell>

      <FilterCell label="Секция">
        <NewDropDownInput
          placeholder="Секция"
          options={sections}
          selectedId={filters.section_id}
          minWidth="200px"
          isDisabled={isDisabled}
          dataTestId=""
          hasClear
          onOptionClick={(val) => setFilter({ key: 'section_id', val })}
        />
      </FilterCell>

      <FilterCell
        label="Наименование работы и материалов"
        isDividerTransparent={isFirstGroupOpen}
        hasRightDivider
      >
        <NewDropDownInput
          placeholder="Поиск по названию работы или материала"
          options={names}
          selectedId={filters.name}
          isDisabled={isDisabled}
          dataTestId=""
          isFill
          hasClear
          hasSearch
          onOptionClick={(val) => setFilter({ key: 'name', val })}
        />
      </FilterCell>

      {isFirstGroupOpen && (
        <FilterCell label="Описание" isHiddenCell>
          <NewInput
            placeholder="Поиск по описанию"
            value={filters.description ?? ''}
            postfixIcon="search"
            disabled={isDisabled}
            dataTestId=""
            onChange={(val) => setFilter({ key: 'description', val })}
            onClear={() => removeFilter({ key: 'description' })}
          />
        </FilterCell>
      )}

      <FilterCell
        label={'Признак\nРабота/Материалы'}
        verticalAlign="top"
        groupNumber={1}
        isExpanded={isFirstGroupOpen}
        expandButtonAlign="bottom"
        onExpandButton={() => setIsDescriptionExpanded(!isFirstGroupOpen)}
      >
        <NewDropDownInput
          placeholder="Р/М"
          options={ROW_TYPE_OPTIONS}
          selectedId={filters.row_type}
          isDisabled={isDisabled}
          minWidth="134px"
          dataTestId=""
          hasClear
          onOptionClick={(val) => setFilter({ key: 'row_type', val })}
        />
      </FilterCell>

      <FilterCell label="Единицы измерения" verticalAlign="top">
        <NewDropDownInput
          placeholder=""
          options={units}
          selectedId={filters.unit_id ?? []}
          isDisabled={isDisabled}
          minWidth="150px"
          dataTestId=""
          isMultiple
          hasClear
          onOptionClick={(val) => setFilter({ key: 'unit_id', val })}
        />
      </FilterCell>

      <RangeFilterCell
        label="Количество"
        isDisabled={isDisabled}
        fromValue={filters.count_from ?? ''}
        toValue={filters.count_to ?? ''}
        decimalScale={4}
        testIdPrefix="lot-work-count"
        onChangeFrom={(val) => setFilter({ key: 'count_from', val })}
        onChangeTo={(val) => setFilter({ key: 'count_to', val })}
      />

      {isEditMode && (
        <FilterCell isEmpty />
      )}

      <RangeFilterCell
        label="Цена за единицу"
        isDisabled={isDisabled}
        fromValue={filters.price_from ?? ''}
        toValue={filters.price_to ?? ''}
        testIdPrefix="lot-work-price"
        onChangeFrom={(val) => setFilter({ key: 'price_from', val })}
        onChangeTo={(val) => setFilter({ key: 'price_to', val })}
      />
      <RangeFilterCell
        label="Стоимость"
        isDisabled={isDisabled}
        fromValue={filters.cost_from ?? ''}
        toValue={filters.cost_to ?? ''}
        testIdPrefix="lot-work-cost"
        onChangeFrom={(val) => setFilter({ key: 'cost_from', val })}
        onChangeTo={(val) => setFilter({ key: 'cost_to', val })}
      />

      <FilterCell isEmpty />
      <FilterCell isEmpty />
    </GridRow>
  )
})
