import { attachWrapper } from '@42px/effector-extra'
import { AxiosError, AxiosResponse } from 'axios'

import { prepareFormData } from '@/lib/form-data'

import { Method, authRequestFx } from '../request'
import {
  AbortableRequest,
  DocumentAlias,
  FileDTO,
  Response,
  PaginationRequestParams,
  PaginationType,
  ActId,
} from '../interfaces'
import {
  ActDTO,
  GetFlatTableResponse,
  GetTreeTableResponse,
  PostActAttachmentsParams,
  PostPaymentOrdersParams,
  ResponseWithKSData,
  ArticleOutlay,
  FillByRemainingParams,
  FillByRemainingResponse,
  GetActTableParams,
  SendWorkVolumeFxPayload,
  UpdateTableResponse,
  GetFolderContentParams,
  UploadActTableTemplate,
  ActErrorResponse,
  CreateActFxPayload,
  PaymentOrder,
  UploadActTemplateResponse,
} from './types'
import { defaultErrorHandler } from '../error-handler'
import { actErrorHandler } from './error-handler'
import { mapQueryFilters } from './mappers'
import { ContractDirection } from '../administrative'

export const getActInfoReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ signal, actId }: AbortableRequest<{ actId: ActId }>) => ({
    method: Method.get,
    url: `/act/${actId}`,
    signal,
  }),
  mapResult: ({ result }: Response<ActDTO>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => actErrorHandler(error.response?.data),
})

export const fillByRemainingReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ actId, isFill }: FillByRemainingParams) => ({
    method: Method.post,
    url: `/act/${actId}/set_fill_by_remaining/`,
    body: {
      is_fill_by_remaining: isFill,
    },
  }),
  mapResult: ({ result }: { result: AxiosResponse<FillByRemainingResponse> }) => result.data,
  mapError: ({ error }: { error: AxiosError }) => actErrorHandler(error.response?.data),
})

export const getTreeTableActWorkReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({
    signal,
    actId,
    ...query
  }: AbortableRequest<GetActTableParams>) => ({
    method: Method.get,
    url: `/act/${actId}/hierarchy-view/`,
    query: mapQueryFilters(query),
    signal,
  }),
  mapResult: ({
    result,
  }: Response<GetTreeTableResponse>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const getFlatTableActWorkReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({
    signal,
    actId,
    limit,
    offset,
    ...query
  }: AbortableRequest<GetActTableParams & PaginationRequestParams>) => ({
    method: Method.get,
    url: `/act/${actId}/flat-view/`,
    query: {
      limit,
      offset,
      ...mapQueryFilters(query),
    },
    signal,
  }),
  mapResult: ({
    result,
  }: Response<PaginationType<GetFlatTableResponse>>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const getActWorkFolderContentReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({
    signal,
    actId,
    ...query
  }: AbortableRequest<GetFolderContentParams>) => ({
    method: Method.get,
    url: `/act/${actId}/hierarchy-view/`,
    query: mapQueryFilters(query),
    signal,
  }),
  mapResult: ({ result }: Response<GetTreeTableResponse>) => result.data.root,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const putActWorkVolumeReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({
    actType,
    actId,
    workId,
    value,
    isTree,
    ...query
  }: SendWorkVolumeFxPayload) => ({
    method: Method.put,
    url: '/work/',
    body: {
      act: actId,
      work: workId,
      count_declared: actType === ContractDirection.SMR ? value : undefined,
      is_completion_declared: actType === ContractDirection.PDRD ? value : undefined,
      is_tree: isTree,
      ...mapQueryFilters({ actType, ...query }),
    },
  }),
  mapResult: ({ result }: { result: AxiosResponse<UpdateTableResponse> }) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const getArticlesOutlayReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({
    documentType,
    id,
    signal,
  }: AbortableRequest<{ documentType: DocumentAlias, id: number | string }>) => ({
    method: Method.get,
    url: `/article_outlay/${documentType}/${id}/`,
    signal,
  }),
  mapResult: ({ result }: { result: AxiosResponse<ArticleOutlay[]> }) => result.data,
  mapError: ({ error }: { error: AxiosError }) => error.response?.data,
})

export const uploadActTableTemplateReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ actId, file }: UploadActTableTemplate) => ({
    method: Method.post,
    url: `/act/act_template/${actId}/`,
    body: prepareFormData({ file, files: undefined }),
  }),
  mapResult: ({ result }: { result: AxiosResponse<UploadActTemplateResponse> }) => result.data,
  mapError: ({ error }: { error: AxiosError }) => actErrorHandler(error.response?.data),
})

export const sendActToApproveReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, comment }: { id: ActId, comment?: string }) => ({
    method: Method.post,
    url: `/approve_act/${id}`,
    body: {
      comment,
    },
  }),
  mapResult: ({ result }: Response<ActDTO>) => result.data,
  mapError: ({ error }: { error: AxiosError<ActErrorResponse> }) => (
    actErrorHandler(error.response?.data)
  ),
})

export const cancelActApproveReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, comment }: { id: ActId, comment: string }) => ({
    method: Method.post,
    url: `/reject_act/${id}`,
    body: {
      message: comment,
    },
  }),
  mapResult: ({ result }: Response<ActDTO>) => result.data,
  mapError: ({ error }: { error: AxiosError<ActErrorResponse> }) => (
    actErrorHandler(error.response?.data)
  ),
})

export const deleteActReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (id: ActId) => ({
    method: Method.delete,
    url: `/act_del/${id}`,
  }),
  mapResult: ({ result }: { result: AxiosResponse<unknown> }) => result.data,
  mapError: ({ error }: { error: AxiosError<ActErrorResponse> }) => (
    actErrorHandler(error.response?.data)
  ),
})

export const postPaymentOrdersReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, ...body }: PostPaymentOrdersParams) => ({
    method: Method.post,
    url: `/act/${id}/payment_orders/`,
    body,
  }),
  mapResult: ({ result }: Response<PaymentOrder[]>) => result.data,
  mapError: ({ error }: { error: AxiosError<ActErrorResponse> }) => (
    actErrorHandler(error.response?.data)
  ),
})

export const getActFeaturesReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (id: ActId) => ({
    method: Method.get,
    url: `/act/${id}/features/`,
  }),
  mapResult: ({ result }: Response<{ features: ActDTO['features'] }>) => result.data.features,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const postActReportingDatesReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, date_end }: { id: ActId, date_end: string }) => ({
    method: Method.post,
    url: `/act/${id}/set_reporting_period/`,
    body: {
      date_to: date_end,
    },
  }),
  mapResult: ({ result }: Response<{ date_to: string }>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const updateActCommentReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, comment, signal }: AbortableRequest<{ id: ActId, comment: string }>) => ({
    method: Method.post,
    url: `/act/${id}/update_comment/`,
    signal,
    body: {
      comment,
    },
  }),
  mapResult: ({ result }: Response<{ comment: string | null }>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const postActAttachmentsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, ...body }: PostActAttachmentsParams) => ({
    method: Method.post,
    url: `/files/by_document/${DocumentAlias.ACT}/${id}/`,
    body: prepareFormData({ ...body, files: undefined }),
  }),
  mapResult: ({ result }: Response<FileDTO[]>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const countAdvanceRemainderReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (id: ActId) => ({
    method: Method.post,
    url: `/act/${id}/set-off-remainder/`,
  }),
  mapResult: ({ result }: Response<ResponseWithKSData>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const setDeductionOfPaidAdvanceReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, sum }: { id: ActId, sum: number }) => ({
    method: Method.post,
    url: `/act/${id}/set-deduction-of-paid-advance/`,
    body: {
      to: sum,
    },
  }),
  mapResult: ({ result }: Response<ResponseWithKSData>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

// @TODO актуализировать позже
export const postActReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ contractId, reporting_period }: CreateActFxPayload) => ({
    method: Method.post,
    url: '/act/',
    body: {
      id: contractId,
      reporting_period,
    },
  }),
  mapResult: ({ result }: { result: AxiosResponse<ActDTO> }) => result.data,
  mapError: ({ error }: { error: AxiosError<ActErrorResponse> }) => (
    actErrorHandler(error.response?.data)
  ),
})

export const copyActReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (id: ActId) => ({
    method: Method.get,
    url: `/copy_act/${id}`,
  }),
  mapResult: ({ result }: { result: AxiosResponse<{ id: ActId }> }) => result.data,
  mapError: ({ error }: { error: AxiosError }) => actErrorHandler(error.response?.data),
})

export const getStartDateActReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, signal }: AbortableRequest<{ id: number }>) => ({
    method: Method.get,
    url: '/act/get_reporting_period_bounds/',
    query: {
      contract_id: id,
    },
    signal,
  }),
  // YYYY-MM-DD date format
  mapResult: ({ result }: { result: AxiosResponse<{ date_start: string }> }) => result.data,
  mapError: ({ error }: { error: AxiosError }) => error.response?.data,
})
