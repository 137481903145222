import React from 'react'
import styled, { css } from 'styled-components'

import {
  PaletteColor, TextMLight, TextSLight, palette,
} from '@/ui'

type Props = {
  children?: React.ReactNode
  color?: PaletteColor
  isFill?: boolean
  isBold?: boolean
  isUppercase?: boolean
  textSize?: 'M' | 'S'
}

export const Cell = ({
  children,
  color = 'grey70',
  textSize = 'M',
  isBold = false,
  isFill = false,
  isUppercase = false,
}: Props) => (
  <Wrapper
    color={color}
    textSize={textSize}
    isBold={isBold}
    isFill={isFill}
    isUppercase={isUppercase}
  >
    {children}
  </Wrapper>
)

const Wrapper = styled.div<Required<Pick<Props, 'color' | 'textSize' | 'isBold' | 'isUppercase' | 'isFill'>>>`
  width: 100%;
  max-width: 120px;
  text-align: right;

  ${({ textSize }) => (textSize === 'M' ? TextMLight : TextSLight)}

  ${({ isBold }) => (isBold && css`
    font-weight: 400;
  `)}

  ${({ isFill }) => isFill && css`
    max-width: 100%;
    text-align: left;
  `}

  ${({ color }) => css`
    color: ${palette[color]};
  `}

  ${({ isUppercase }) => isUppercase && css`
    text-transform: uppercase;
  `}
`
