import { sample } from 'effector'

import { Plan } from '@/dal'
import { createToast } from '@/features/toast-service/model'
import {
  $reasonPlanAgreedContractorCancelText,
  $showPlanAgreedContractorCancel,
  agreedPlanContractorCancelFx,
  closePlanAgreedContractorCancel,
  sendPlanAgreedContractorCancel,
  setPlanAgreedContractorCancelText,
} from './private'
import { openPlanAgreedContractorCancel } from './public'
import { $openedFundPlanId, updateOpenedPlan } from '../../plan-info/model'

$showPlanAgreedContractorCancel
  .on(openPlanAgreedContractorCancel, () => true)
  .reset(closePlanAgreedContractorCancel)

$reasonPlanAgreedContractorCancelText
  .on(setPlanAgreedContractorCancelText, (_, s) => s)
  .reset(closePlanAgreedContractorCancel)

sample({
  clock: sendPlanAgreedContractorCancel,
  source: {
    id: $openedFundPlanId,
    reason: $reasonPlanAgreedContractorCancelText,
  },
  filter: ({ id }) => Boolean(id),
  fn: ({ id, reason }) => ({
    id: id as Plan['id'],
    message: reason,
  }),
  target: agreedPlanContractorCancelFx,
})

sample({
  clock: agreedPlanContractorCancelFx.done,
  target: closePlanAgreedContractorCancel,
})

createToast({
  effect: agreedPlanContractorCancelFx,
  doneText: 'Согласование отменено',
})

sample({
  clock: agreedPlanContractorCancelFx.doneData,
  target: updateOpenedPlan,
})
