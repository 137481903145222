import { attach, combine } from 'effector'

import {
  addEstimateLotKerReqFx,
  addEstimateLotKvrReqFx,
  getEstimateLotKvrAndKerReqFx,
  KerElement,
  KvrElement,
} from '@/dal'

import { d } from './domain'
import { BreadcrumbsItem, CatalogMap } from './types'

export const $searchValue = d.store('')
export const changeSearchValue = d.event<string>()
export const acceptSearch = d.event()

// export const $searchResults = d.store<KvrElement[] | null>(null)
// export const setSearchResults = d.event<KvrElement[] | null>()
// export const $isSearchAccepted = d.store(false)
// export const setIsSearchAccepted = d.event<boolean>()

export const $areDescriptionsExpanded = d.store(false)
export const setAreDescriptionsExpanded = d.event<boolean>()

export const $isCatalogOpen = d.store(false)
export const closeCatalog = d.event()

export const $catalogMap = d.store<CatalogMap>({})
export const addKvrToLot = d.event<KvrElement['id']>()
export const addKerToLot = d.event<{ kerId: KerElement['id'], kvrId: KvrElement['id'] }>()

export const $kvrsWithLoadedChildren = d.store<KvrElement['id'][]>([])
export const addKvrWithLoadedChildren = d.event<KvrElement['id']>()

export const $openedKvrId = d.store<KvrElement['id'] | null>(null)
export const setOpenedKvrId = d.event<KvrElement['id'] | null>()
export const $openedKvr = combine(([$catalogMap, $openedKvrId]), ([map, id]) => (
  id && map[id] ? map[id] : null
))
export const $openedKvrChildren = combine(([$catalogMap, $openedKvrId]), ([map, id]) => {
  return map ? Object.values(map).filter((item) => item.parent_id === id) : []
})
export const $openedKvrKerItems = $openedKvr.map((info) => info?.ker_elements ?? [])

export const getOpenedKvr = d.event<KvrElement['id'] | null>()

export const $selectedKvrId = d.store<KvrElement['id'] | null>(null)
export const selectKvrId = d.event<KvrElement['id']>()
export const $selectedKvr = combine(([$catalogMap, $selectedKvrId]), ([map, id]) => (
  id && map[id] ? map[id] : null
))

export const $selectedKerId = d.store<KerElement['id'] | null>(null)
export const selectKerId = d.event<KerElement['id']>()
export const $selectedKer = combine(([$openedKvr, $selectedKerId]), ([kvr, kerId]) => (
  kvr?.ker_elements?.find(({ id }) => id === kerId) ?? null
))

export const $breadcrumbs = combine(([$catalogMap, $openedKvrId]), ([map, id]) => {
  const items: BreadcrumbsItem[] = []
  let currId = id
  while (currId) {
    const currItem = map[currId]
    items.unshift({ id: currId, name: currItem.name })
    currId = currItem.parent_id
  }

  return [{ id: null, name: '...' }, ...items]
})

export const getKvrAndKerFx = attach({
  effect: getEstimateLotKvrAndKerReqFx,
})

export const addKerToLotFx = attach({
  effect: addEstimateLotKerReqFx,
})

export const addKvrToLotFx = attach({
  effect: addEstimateLotKvrReqFx,
})
