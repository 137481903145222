import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { Icon2 } from '../icons'
import { TextL } from '../typography'
import { palette } from '../palette'

type Props = {
  label: string
  url: string
  isNewTab?: boolean
  isDisabled?: boolean
  isExternal?: boolean
}

export const NewLinkButton = ({
  label,
  url,
  isNewTab,
  isDisabled,
  isExternal,
}: Props) => {
  const child = (
    <Wrapper disabled={Boolean(isDisabled)}>
      <Label>
        {label}
      </Label>
      <Icon2 color={isDisabled ? 'accent50' : 'accent100'} icon="linkIcon" size={20} />
    </Wrapper>
  )

  if (isDisabled) return child

  return isExternal ? (
    <ExternalLink href={url} target={isNewTab ? '_blank' : undefined} rel="noopener noreferrer">
      {child}
    </ExternalLink>
  ) : (
    <InnerLink to={url} target={isNewTab ? '_blank' : undefined}>
      {child}
    </InnerLink>
  )
}

const InnerLink = styled(Link)`
  min-width: 0;
  text-decoration: none;
`

const ExternalLink = styled.a`
  min-width: 0;
  text-decoration: none;
`

const Wrapper = styled.div<{disabled: boolean}>`
  text-decoration: none;
  padding: 16px;
  background-color: ${palette.white};
  border-radius: 20px;
  display: flex;
  gap: 16px;
  transition: color 0.15s linear;
  color: ${palette.grey90};
  justify-content: space-between;

  &:hover {
    color: ${palette.accent100};
  }

  ${({ disabled }) => disabled && css`
    cursor: not-allowed;
    color: ${palette.grey60};
    &:hover { 
      cursor: not-allowed;
      color: ${palette.grey60};
    }
  `}
`

const Label = styled.div`
  ${TextL}
  color: inherit;
  text-overflow: ellipsis;
  overflow: hidden;
`

