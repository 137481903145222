import React, { useState } from 'react'

import { RejectionReason } from '@/dal'

import { NotificationItem } from './NotificationItem'
import { NotificationType } from '../types'

type Props = {
  rejectReason?: RejectionReason
}

export const RejectReasonNotification = ({ rejectReason }: Props) => {
  const [isShown, setIsShown] = useState(true)

  const hasMessage = typeof rejectReason === 'object' && rejectReason?.message

  if (!isShown || !hasMessage) return null

  return (
    <NotificationItem
      type={NotificationType.Alert}
      body="Отмена согласования, документ переведён на статус черновик."
      showCloseButton
      onClose={() => setIsShown(false)}
    />
  )
}
