import { attach, combine } from 'effector'

import { createSingleEffect } from '@/lib/createSingleEffect'
import {
  ClosureDocument,
  ContractItem,
  QUERY_FOR_CLOSURE_IDS,
  closeContractReqFx,
  getClosureDocumentsByIdReqFx,
} from '@/dal'

import { d } from './domain'
import { ClosureDocumentInfo } from './types'

export const $closingContractId = d.store<ContractItem['id'] | null>(null)
export const openClosestDocumentModal = d.event<ContractItem['id']>()
export const closeModal = d.event<void>()

export const $closureDocumentInfo = d.store<ClosureDocumentInfo | null>(null)

export const $selectedDocIds = d.store<ClosureDocument['id'][]>([])
export const toggleDocId = d.event<ClosureDocument['id']>()

export const $currStep = d.store<number>(1)
export const nextStep = d.event<void>()
export const prevStep = d.event<void>()

export const closeContract = d.event<void>()

export const $downloadClosureDocumentLink = combine(
  $closureDocumentInfo,
  $selectedDocIds,
  (info, docIds) => {
    if (!info || !docIds.length) return ''
    return `${info.link}?${QUERY_FOR_CLOSURE_IDS}=${docIds.join(',')}`
  })

export const {
  requestFx: getClosureDocumentsByIdFx,
} = createSingleEffect(getClosureDocumentsByIdReqFx, { abortEvents: [closeModal] })

export const closeContractFx = attach({
  effect: closeContractReqFx,
})
