import { sample } from 'effector'

import { createToast } from '@/features/toast-service/model'

import {
  repeatForm,
  repeatRegistrationLetterFx,
} from './repeat-registration-letter.private'
import { RestoreAccessGate } from './public'

sample({
  clock: repeatForm.formValidated,
  fn: ({ email }) => email,
  target: repeatRegistrationLetterFx,
})

createToast({
  effect: repeatRegistrationLetterFx,
})

sample({
  clock: RestoreAccessGate.close,
  target: repeatForm.reset,
})
