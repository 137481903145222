import React, { memo } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import {
  HeadXL, ModalLayoutV2, NewButton, NewCheckbox, TextL, TextLLight,
} from '@/ui'
import { noop } from '@/lib/noop'
import { getMorphologyBreadcrumbs } from '@/features/estimate-lot/model/helpers'

import {
  $isDeletingMaterialEverywhere,
  deleteLotWorkMaterial,
  deleteLotWorkMaterialFx,
  deleteMaterialModal,
  setIsDeletingMaterialEverywhere,
} from '../../model/private'
import { Breadcrumbs } from '../parts'

export const DeleteMaterialModal = memo(() => {
  const [info, isDeletingEverywhere, isDeletionPending] = useUnit([
    deleteMaterialModal.$meta,
    $isDeletingMaterialEverywhere,
    deleteLotWorkMaterialFx.pending,
  ])

  if (!info) return null

  const { morphology } = info

  const handleDelete = () => {
    deleteLotWorkMaterial(info.id)
  }
  const onClose = isDeletionPending ? noop : deleteMaterialModal.close

  const breadcrumbs = getMorphologyBreadcrumbs(morphology)

  return (
    <ModalLayoutV2
      dataTestId=""
      closeModal={isDeletionPending ? noop : onClose}
    >
      <Wrapper>
        <Container>
          <Title>Удаление материала</Title>

          {morphology && (
            <Breadcrumbs items={breadcrumbs} />
          )}

          <Body>
            Вы уверены, что хотите удалить материал
            {' '}
            <BoldText>{info.name}</BoldText>
            {morphology
              ? ' для данного элемента строительства?'
              : '? Он удалится из всех объектов, корпусов и секций. Данное действие будет необратимо.'}
          </Body>

          {morphology && (
            <NewCheckbox
              label="Удалить со всех элементов структуры объекта строительства"
              isChecked={isDeletingEverywhere}
              checkPosition="left"
              dataTestId=""
              isBoolean
              onChange={setIsDeletingMaterialEverywhere}
            />
          )}
        </Container>

        <Buttons>
          <NewButton
            label="Удалить"
            isPending={isDeletionPending}
            dataTestId=""
            isFill
            onClick={handleDelete}
          />
          <NewButton
            label="Отменить"
            buttonType="grey"
            isDisabled={isDeletionPending}
            dataTestId=""
            isFill
            onClick={onClose}
          />
        </Buttons>
      </Wrapper>
    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 500px;
  padding: 32px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Buttons = styled.div`
  display: flex;
  gap: 16px;
`

const Title = styled.div`
  ${HeadXL}
`

const Body = styled.div`
  ${TextLLight}
`

const BoldText = styled.span`
  ${TextL}
`
