import React from 'react'
import styled from 'styled-components'

import { TextM, TextSLight } from '../typography'
import { palette } from '../palette'

type Props = {
  children: React.ReactElement
  label?: string
  maxSymbolCount?: number
  currentSymbolCount?: number
  isLabel?: boolean
  required?: boolean
  hasLengthError?: boolean
}

export const Field = ({
  children,
  label,
  currentSymbolCount,
  maxSymbolCount,
  isLabel = true,
  required = false,
  hasLengthError = false,
}: Props) => {
  const content = (
    <>
      {Boolean(label) && (
        <Label>
          <LabelText>
            <span>
              {label}
            </span>
            {required && <RequiredAsterisk>*</RequiredAsterisk>}
          </LabelText>
          {Boolean(maxSymbolCount !== undefined && currentSymbolCount !== undefined) && (
            <SymbolsInfo isInvalid={hasLengthError}>
              {currentSymbolCount}
              /
              {maxSymbolCount}
            </SymbolsInfo>
          )}
        </Label>
      )}
      {children}
    </>
  )

  if (isLabel) {
    return (
      <WrapperLabel>{content}</WrapperLabel>
    )
  }

  return (
    <Wrapper>
      {content}
    </Wrapper>
  )
}

const RequiredAsterisk = styled.span`
  color: ${palette.accent100};
  user-select: none;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`

const WrapperLabel = styled.label`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`

const Label = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LabelText = styled.div`
  ${TextM}
  display: flex;
  gap: 4px;
`

const SymbolsInfo = styled.div<{ isInvalid: boolean }>`
  ${TextSLight}
  color: ${({ isInvalid }) => palette[isInvalid ? 'red100' : 'grey60']};
`
