import React from 'react'
import styled from 'styled-components'

import { DropSlot, TextM } from '@/ui'

type Props = {
  value: File | null
  title: string
  disable?: boolean
  onChange: (value: File | null) => void
  onClear: () => void
}

export const FileSlotItem = ({
  onChange,
  onClear,
  title,
  disable,
  value,
}: Props) => {
  return (
    <Row>
      <Text>
        {title}
      </Text>
      <DropSlot
        file={value}
        onFile={onChange}
        onClearField={onClear}
        isDisabled={disable}
      />
    </Row>
  )
}

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
`

const Text = styled.div`
  ${TextM};
  flex: 0 0 120px;
`
