import React from 'react'
import styled from 'styled-components'
import { useForm } from 'effector-forms'
import { useGate, useUnit } from 'effector-react'

import { ContractDirection } from '@/dal'
import {
  LoaderOverlay,
  NewDateInput,
  NotificationItem,
  NotificationType,
  TextLLight,
  TextM,
} from '@/ui'
import { getDayJsObject } from '@/lib/date'

import { $createDocumentInfo, closeCreateDocumentModal } from '../../model/private'
import {
  CreateActGate,
  createActForm,
  createActFx,
  getStartDateActFx,
  onConfirmCreatePIRAct,
} from '../../model/create-act.private'
import { getFormattedContractName } from '../mappers'
import { ConfirmLayout, DocumentCreationButtons } from '../parts'

export const CreateAct = React.memo(() => {
  const form = useForm(createActForm)
  const isLoading = useUnit(createActFx.pending)
  const isLoadingStartDate = useUnit(getStartDateActFx.pending)
  const info = useUnit($createDocumentInfo)

  const isPDRD = info?.contractDirection === ContractDirection.PDRD

  useGate(CreateActGate)

  if (isPDRD) {
    return (
      <ConfirmLayout
        text="Создать выполнение"
        contractName={info?.contractName}
        isLoading={isLoading}
        confirmLabel="Создать"
        onConfirm={onConfirmCreatePIRAct}
        onClose={closeCreateDocumentModal}
      />
    )
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    form.submit()
  }

  return (
    <Wrapper>
      <NotificationItem
        type={NotificationType.Info}
        body={(
          <>
            <AccentWrapper>Для первого выполнения: </AccentWrapper>
            начало отчетного периода = дате начала строительства;
            <br />
            <AccentWrapper>для последующих выполнений: </AccentWrapper>
            начало отчетного периода = окончанию отчетного периода в предыдущем акте + 1 день.
          </>
        )}
      />

      <Description>
        Чтобы создать выполнение по
        {' '}
        {getFormattedContractName(info?.contractName)}
        , выберите конец отчетного периода.
      </Description>

      <Form onSubmit={handleSubmit}>
        <InputRow>
          <NewDateInput
            label="Начало отчетного периода"
            inputValue={form.values.date_start}
            onChange={form.fields.date_start.onChange}
            errorText={form.fields.date_start.errorText()}
            disabled
            dataTestId="act-create-period-select-from-date"
          />
          <NewDateInput
            label="Конец отчетного периода"
            inputValue={form.values.date_stop}
            initialOpenedDateValue={new Date().toDateString()}
            onChange={form.fields.date_stop.onChange}
            errorText={form.fields.date_stop.errorText()}
            disabled={isLoading || isLoadingStartDate}
            dataTestId="act-create-period-select-to-date"
            activeDateRange={{
              from: getDayJsObject(form.values.date_start).add(1, 'day').toDate(),
            }}
            required
          />
          <LoaderOverlay size={40} isShown={isLoadingStartDate} />
        </InputRow>

        <DocumentCreationButtons
          isDisabled={!form.isValid || !form.isTouched || isLoadingStartDate}
          isPending={isLoading}
          onClose={closeCreateDocumentModal}
        />
      </Form>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const AccentWrapper = styled.span`
  ${TextM}
`

const Description = styled.div`
  ${TextLLight}
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const InputRow = styled.div`
  position: relative;
  display: flex;
  gap: 24px;
`
