import { ContractItem } from '../administrative'
import { ProjectItemType } from '../contract-filters'
import { DefaultErrorResponse } from '../error-handler'
import {
  AbortableRequest,
  ApprovementProcessItem,
  DefaultUpdateFileParams,
  DocumentAlias,
  DocumentExclusiveBlock,
  DocumentFeatures,
  DocumentProgress,
  DocumentStatuses,
  EstimateDocId,
  ExistingKorDoc,
  FileDTO,
  RejectionReason,
} from '../interfaces'

export type SendEstimateToApproveParams = {
  id: EstimateDocId,
} & (
    {
      comment?: string,
    } | {
      ds_text_file?: File
      gfpr_file?: File
      gpr_file?: File
    }
  )

export type EstimateSumInfo = {
  current: string
  adjusted: string
  approved: string | null
  adjusted_delta: string | null
  approved_delta: string | null
  is_changeable?: boolean
  is_edited?: boolean
}

export type EstimateDoc = {
  id: EstimateDocId
  name: string
  last_update: string // isoDate
  status: DocumentStatuses
  type: DocumentAlias.KOR_DOC
  contract: {
    name: string,
    id: ContractItem['id'],
    project: {
      id: ProjectItemType['id']
      name: string
    }
  }
  summary_table: {
    sum_material: EstimateSumInfo
    sum_work: EstimateSumInfo
    sum_all: EstimateSumInfo
    nds: EstimateSumInfo
    avans: {
      percentage_current: string
      current: string
      percentage_adjusted: string
      adjusted: string
      percentage_approved: string
      approved: string | null
      is_changeable?: boolean
      is_edited?: boolean
      paid_advance: string
    }
    date_end_work: {
      current: string | null // yyyy-mm-dd
      adjusted: string // yyyy-mm-dd
      approved: string | null // yyyy-mm-dd
      is_changeable?: boolean
      is_edited?: boolean
    }
  }
  features: DocumentFeatures & {
    show_business_offer?: boolean
    is_document_changed?: boolean
    document_exclusive_block: DocumentExclusiveBlock
  }
  status_text?: string
  approving_additional_agreement: {
    name: string
    type: DocumentAlias
    id: number
    status: DocumentStatuses
  } | null
  progress?: DocumentProgress | null
  attachments: FileDTO[] | null
  approve_comment?: string | null
  reject_comment: RejectionReason
  cancel_comment: RejectionReason
  is_valid: boolean,
  has_splitting_objects: boolean
  conflicting_draft_kor_doc: ExistingKorDoc
  approvement_process_log: ApprovementProcessItem[] | null
}

export type PostAttachmentsParams = {
  id: EstimateDocId
} & DefaultUpdateFileParams

export type PostTableItemAttachmentsParams = {
  id: EstimateTableRow['id']
} & DefaultUpdateFileParams

export enum SummaryFieldAlias {
  AVANS = 'avans',
  WORK = 'work',
  DATE = 'date',
  MATERIAL = 'material',
  TOTAL = 'total'
}

export type SendSummaryInfoParams = {
  field: SummaryFieldAlias
  value: string
  id: EstimateDocId
}

export enum EstimateCorrectionType {
  Corrected = 'Corrected',
  NotApproved = 'NotApproved',
  WithError = 'WithError',
  Done = 'Done',
  New = 'New',
  WithZero = 'WithZero',
  GrandTender = 'GrandTender'
}

export type GetEstimateTableParams = {
  documentId: EstimateDocId
  search?: string
  types?: EstimateCorrectionType[]
  group_id?: EstimateTableRow['id']
}

export type GetEstimateFolderRowsParams = GetEstimateTableParams & {
  group_id: EstimateTableRow['id']
}

export type EstimateStringCell = {
  value: string,
  editable: boolean,
  valid: boolean
}

export type EstimateNumberCell = {
  value_current: string | null
  value_adjusted: string | null
  value_approved: string | null
  value_delta: string | null
  editable: boolean,
  valid: boolean,
}

export type GetEstimateTreeTableResponse = {
  data: EstimateTableRow[],
  total: number
}

export type EstimateTableRow = {
  id: string,
  is_changed: boolean,
  name: EstimateStringCell
  depth: number
  project: {
    id: number,
    name: string
    editable: boolean
  } | null
  children: string[] | null
  parent: EstimateTableRow['id'] | null
  is_folder: boolean
  is_grand_tender: boolean
  code_1c: string
  note: EstimateStringCell | null,
  cost_item: EstimateStringCell | null,
  unit: {
    value: string,
    editable: boolean,
    valid: boolean
    id: number
  } | null,
  is_valid: boolean,
  count_in_estimate: EstimateNumberCell | null,
  approved: EstimateNumberCell | null,
  on_approving: EstimateNumberCell | null,
  remain: EstimateNumberCell | null,
  price_material: EstimateNumberCell | null,
  price_work: EstimateNumberCell | null,
  price_all: EstimateNumberCell | null,
  cost_material: EstimateNumberCell | null,
  cost_work: EstimateNumberCell | null,
  cost_all: EstimateNumberCell | null,
  comment_info: {
    new_count: number,
    total: number
  } | null
  total_items_count: number | null

  justification_of_changes: EstimateStringCell | null,

  additional_requisites: {
    provider: EstimateStringCell,
    specifications: EstimateStringCell,
    stamp: EstimateStringCell
  } | null,

  features?: {
    can_delete?: boolean
    can_split_volume?: boolean
    can_edit?: boolean
    can_reset?: boolean
    can_add_attachments?: boolean
  }

  attachments: FileDTO[] | null,

  new_lines: [Pick<EstimateTableRow,
  'id'
  | 'cost_all'
  | 'cost_work'
  | 'cost_material'
  | 'price_all'
  | 'price_work'
  | 'price_material'
  | 'remain'
  | 'on_approving'
  | 'approved'
  | 'note'
  | 'cost_item'
  | 'unit'
  | 'count_in_estimate'
  | 'justification_of_changes'
  | 'additional_requisites'
  | 'attachments'
  | 'features'
  >] | null
}

export type CreateEstimateRowParams = {
  is_tree: boolean
  documentId: EstimateDocId
  group_id: EstimateTableRow['id'] | null,
  name: string,
  project: number | null,

  unit: number | null
  count_in_estimate: string
  material: string
  work: string

  justification_of_changes_current: string
  files: File[],
  provider: string,
  specifications: string,
  stamp: string,
}

export type UpdateEstimateRowItem = {
  project: number | null
  unit: number | null
  count_in_estimate: string
  material: string
  work: string
  justification_of_changes_current: string
  provider: string
  specifications: string
  stamp: string
  name: string

  new_count_in_estimate: string
  new_justification_of_changes_current: string
  new_material: string
  new_work: string
  new_provider: string
  new_stamp: string
  new_specifications: string
}

export type UpdateEstimateRowItemWithId = {
  id: EstimateTableRow['id']
} & UpdateEstimateRowItem

export type UpdateEstimateRowParams = {
  isFolder: boolean

  // path params
  documentId: EstimateDocId
  itemId: EstimateTableRow['id']

  // query params
  isTree: boolean

  // body params
} & UpdateEstimateRowItem

export type UpdateMultipleEstimateRowsParams = {
  documentId: EstimateDocId
  items: UpdateEstimateRowItemWithId[]
}

export type UpdateMultipleEstimateRowsResponse = {
  items: EstimateTableRow[]
  features: EstimateDoc['features']
  summary: EstimateDoc['summary_table']
}

export type CreateEstimateFolderParams = {
  // path params
  documentId: EstimateDocId

  // query params
  is_tree: boolean
  group_id: EstimateTableRow['id'] | null,

  // body params
  name: string,
}

export type SplitVolumeParams = {
  // path params
  documentId: EstimateDocId

  // query params
  itemId: EstimateTableRow['id']
  is_tree: boolean

  // body params
  old_count_in_estimate: string
  old_files_to_save: File[]
  old_files_to_delete: FileDTO['id'][]
  old_justification_of_changes_current: string

  new_material: string
  new_work: string
  new_count_in_estimate: string
  new_files_to_save: File[]
  new_provider: string,
  new_specifications: string,
  new_stamp: string,
  new_justification_of_changes_current: string
}

export type DefaultChangedTableResponse = {
  // updated root item, for update a root array
  root?: EstimateTableRow
  // tree updated items
  tree?: { [key in EstimateTableRow['id']]: EstimateTableRow[] }
  // updated summary table date
  summary?: EstimateDoc['summary_table']
  // target item or created item
  target?: EstimateTableRow
}

export type DeleteResponse = {
  summary?: EstimateDoc['summary_table']
}

export type DeleteResetTableItemParams = {
  isFolder: boolean

  // path params
  documentId: EstimateDocId
  itemId: EstimateTableRow['id']

  // query params
  isTree: boolean
}

export type GroupListItem = {
  id: number,
  name: string
  code_1c: string
}

export type UnitListItem = {
  id: number
  short_name: string
  full_name: string
}

export type ProjectListItem = {
  id: number,
  name: string
  code_1c: string
}

export type GetInfoForCreateRowResponse = {
  groups: GroupListItem[]
  units: UnitListItem[]
  projects: ProjectListItem[]
}

export type GetEstimateRowCommentsParams = AbortableRequest<{
  // path params
  documentId: EstimateDocId
  itemId: EstimateTableRow['id']
}>

export type EstimateRowCommentDTO = {
  id: number
  author: string,
  date_time: string,
  body: string,
  is_new: boolean
}

export type ResetKorDocRowsPayload = {
  id: EstimateDocId
  uuids?: EstimateTableRow['id'][]
  groups?: EstimateTableRow['id'][]
  clear_all?: boolean
}

export enum EstimateCorrectionErrorCode {
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  KOR_DOC_NEW_CONFLICT = 'KOR_DOC_NEW_CONFLICT',
}

export type KorDocValidationErrorData = {
  code: EstimateCorrectionErrorCode.VALIDATION_ERROR,
  detail: string
}

export type EstimateNewConflictErrorData = {
  code: EstimateCorrectionErrorCode.KOR_DOC_NEW_CONFLICT,
  detail: string
  doc_type: DocumentAlias
  id: string | number
  name: string
}

export type EstimateCorrectionErrorData =
  | KorDocValidationErrorData
  | EstimateNewConflictErrorData
  | DefaultErrorResponse
