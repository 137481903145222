import React from 'react'
import styled from 'styled-components'

import { DocumentAlias, FeaturesForDocument } from '@/dal'
import { getDocumentNameByType } from '@/lib/documentResolvers'
import { useTooltip } from '@/lib/hooks'
import {
  Icon2,
  LightTooltip,
  Radio,
  ToolTipV2,
} from '@/ui'
import { useCreateDocumentTooltip } from '@/lib/hooks/useCreateDocumentTooltip'

type Props = {
  type: DocumentAlias,
  onChange: (type: DocumentAlias) => void
  currentType: DocumentAlias | null
  canCreateFeature: FeaturesForDocument['create']
  isPending: boolean
  label?: string | null
}

export const CreateDocumentRadio = ({
  onChange,
  type,
  currentType,
  label,
  canCreateFeature,
  isPending,
}: Props) => {
  const name = label || getDocumentNameByType(type)
  const isChecked = currentType === type

  const {
    tooltipConfig,
    isHint,
  } = useCreateDocumentTooltip(canCreateFeature)

  const isDisabled = !canCreateFeature.can || isPending

  const {
    refs,
    arrowProps,
    ...tooltip
  } = useTooltip({
    placement: isHint ? 'bottom-start' : 'right-start',
    tooltipOffset: isHint ? 4 : 10,
    tooltipCrossOffset: isHint ? 0 : -10,
    hasCloseDelay: !isHint,
  })

  return (
    <>
      <Wrapper ref={refs.setReference}>
        <Radio
          dataTestId={type}
          label={name || ''}
          value={type}
          isDisabled={isDisabled}
          isChecked={isChecked}
          name="document-type"
          onChange={(type) => onChange(type)}
        />

        {isDisabled && !isPending && !isHint && (
          <Icon2 icon="infoCircle" color="accent100" />
        )}
      </Wrapper>

      {tooltipConfig && !isPending && (
      <>
        {isHint ? (
          <ToolTipV2 {...tooltip} refs={refs} size="M">
            {tooltipConfig.text}
          </ToolTipV2>
        ) : (
          <LightTooltip
            {...tooltip}
            refs={refs}
            arrowProps={arrowProps}
          >
            {tooltipConfig.text}
          </LightTooltip>
        )}
      </>
      )}
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`
