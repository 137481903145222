import React from 'react'
import styled from 'styled-components'
import { useGate, useUnit } from 'effector-react'
import { useRouteMatch } from 'react-router-dom'

import { EstimateLotId } from '@/dal'
import {
  ContentContainer, FlexScrollbar, Loader, LoaderOverlay,
} from '@/ui'
import {
  AddMaterialsModal,
  DeleteMaterialModal,
  LotWorkHeader,
  LotWorkSelectedMaterials,
  LotWorkTable,
} from '@/features/estimate-lot/work'
import { $isLotWorkLoaded, $isLotWorkLoading, EstimateLotWorkGate } from '@/features/estimate-lot/work/model'

export const EstimateLotWorkPage = () => {
  const router = useRouteMatch<{ id: string, workId: string }>()

  const [isLoaded, isPending] = useUnit([
    $isLotWorkLoaded,
    $isLotWorkLoading,
  ])

  const id = parseInt(router.params.id, 10) as EstimateLotId
  const { workId } = router.params

  useGate(EstimateLotWorkGate, { id, workId })

  if (!isLoaded) return <Loader />

  return (
    <FlexScrollbar>
      <ContentContainer>
        <Wrapper>
          <LotWorkHeader />
          <LotWorkSelectedMaterials />
          <LotWorkTable />
        </Wrapper>
      </ContentContainer>

      <AddMaterialsModal />
      <DeleteMaterialModal />
      <LoaderOverlay isShown={isPending} />
    </FlexScrollbar>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
`
