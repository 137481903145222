import React from 'react'
import styled from 'styled-components'
import { useForm } from 'effector-forms'
import { useUnit } from 'effector-react'

import { HeadM, Icon2, palette } from '@/ui'
import { ContractVisibility } from '@/dal'

import { $visibility, filterForm } from '../../model/filters.private'

export const EmptyList = React.memo(() => {
  const { fields, isDirty } = useForm(filterForm)
  const visibility = useUnit($visibility)

  const text = React.useMemo(() => {
    if (isDirty) return 'Договоры не найдены, измените параметры фильтрации'
    return visibility === ContractVisibility.visible ? 'У вас нет активных договоров' : 'У вас нет скрытых договоров'
  }, [fields])

  return (
    <Wrapper>
      <Icon2 icon="emptyTasks" size={170} />
      <Label>
        {text}
      </Label>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  padding-top: 86px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
`

const Label = styled.div`
  ${HeadM};
  color: ${palette.grey70}
`
