import { attach } from 'effector'

import { postMaintenanceReqFx } from '@/dal'
import { d } from './domain'

export const getSubscribe = d.event()
export const $isSubscribe = d.store<boolean>(false)

export const postMaintenanceFx = attach({
  effect: postMaintenanceReqFx,
})

export const $checkedRequest = d.store<boolean>(false)
export const setCheckedRequest = d.event()
export const resetCheckedRequest = d.event()
