import { CustomResponseError } from '../error-handler'
import { KorDocValidationErrorData, EstimateNewConflictErrorData } from './types'

export class HasInvalidTableItemsError extends CustomResponseError {
  data: KorDocValidationErrorData

  constructor(data: KorDocValidationErrorData, message = '') {
    super(message)
    this.name = 'EstimateInvalidTableItemsError'
    this.data = data
  }
}

export class HasNewDocConflictError extends CustomResponseError {
  data: EstimateNewConflictErrorData

  constructor(data: EstimateNewConflictErrorData, message = '') {
    super(message)
    this.name = 'EstimateNewConflictError'
    this.data = { ...data, detail: data.detail.replace('**link**', data.name) }
  }
}
