import React from 'react'
import styled, { css } from 'styled-components'

import { noop } from '@/lib/noop'
import { useWindowWidth } from '@/lib/useWindowWidth'
import {
  positiveFloatNumberRegexp,
  positiveFloatNumberRegexpFourDigits,
  negativeFloatNumberRegexp,
  negativeFloatNumberRegexpFourDigits,
  floatNumberRegexp,
  floatNumberRegexpFourDigits,
} from '@/lib/input-sanitizer'
import { Icon } from './Icon'
import { themeVar, WINDOW_WIDTH } from './theming'

type Props = {
  onSubmit: (str: number) => void
  value: number | string
  disabled?: boolean
  fullVolume?: string
  exactWidth?: number
  onChange?: (str: string) => void
  onFocus?: () => void
  defaultValue?: string
  maxValueAction?: {
    onError: () => void
    value: number
  }
  fourDigits?: boolean
  dataTestId: string
  isAllowNegativeNumber?: boolean
  onlyNegative?: boolean
  padding?: string
}

export const InlineInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      onSubmit,
      value,
      disabled = false,
      fullVolume,
      maxValueAction,
      exactWidth,
      onChange,
      defaultValue,
      fourDigits = false,
      dataTestId,
      isAllowNegativeNumber,
      onlyNegative,
      onFocus,
      padding,
    },
    ref,
  ) => {
    const [val, setVal] = React.useState<string | number>('')
    const [originalValue, setOriginalValue] = React.useState<string | number>(
      value,
    )
    const [isTouched, setTouched] = React.useState(false)

    const isMaxValueEmpty = !maxValueAction
    const width = useWindowWidth()

    React.useEffect(() => {
      setVal(value)
    }, [value])

    const handleChange = React.useCallback(
      (newVal: string) => {
        const valChanged = newVal.replaceAll(',', '.')
        if (isMaxValueEmpty) {
          return
        }
        setTouched(true)
        if (!newVal) {
          setVal(valChanged)
          if (onChange) {
            onChange('0')
          }
        }
        let regexp = /(?:)/
        if (fourDigits) {
          if (isAllowNegativeNumber) {
            regexp = floatNumberRegexpFourDigits
          } else if (onlyNegative) {
            regexp = negativeFloatNumberRegexpFourDigits
          } else {
            regexp = positiveFloatNumberRegexpFourDigits
          }
        } else if (isAllowNegativeNumber) {
          regexp = floatNumberRegexp
        } else if (onlyNegative) {
          regexp = negativeFloatNumberRegexp
        } else {
          regexp = positiveFloatNumberRegexp
        }
        if (regexp.test(valChanged)) {
          setVal(valChanged)
          if (onChange) {
            onChange(valChanged)
          }
        }
      },
      [fourDigits, isMaxValueEmpty, onChange],
    )

    const handleSubmit = React.useCallback(() => {
      if (!isTouched) return
      if (maxValueAction && Number(val) > maxValueAction.value) {
        maxValueAction.onError()
        setVal(value)
        return
      }

      setTouched(false)

      if (originalValue !== val) {
        onSubmit(Number(val))
        setOriginalValue(val)
      }

      if (val === '') {
        if (defaultValue) {
          setVal(defaultValue)
        } else {
          setVal('0.0000')
        }
      }
    }, [defaultValue, maxValueAction, onSubmit, originalValue, val, value])

    const handleFullVolume = React.useCallback(
      (vol: string) => {
        setVal(vol)
        onSubmit(Number(vol))

        if (onChange) {
          onChange(vol)
        }
      },
      [onChange, onSubmit],
    )

    return (
      <InputWrapper exactWidth={exactWidth} padding={padding}>
        <Input
          onFocus={onFocus}
          onBlur={handleSubmit}
          ref={ref}
          type="text"
          value={val}
          disabled={disabled}
          readOnly={disabled}
          onChange={(e) => (!disabled ? handleChange(e.target.value) : noop)}
          step="0.0100"
          lang="nl"
          data-testid={dataTestId}
        />
        {fullVolume && (
          <IconWrapper
            disabled={disabled}
            onClick={() => !disabled && handleFullVolume(fullVolume)}
            data-testid={`set-full-volume-${dataTestId}`}
          >
            <Icon
              icon={
                width !== null && width < WINDOW_WIDTH.smallDesktop
                  ? 'accept-volume-16'
                  : 'accept-volume'
              }
            />
          </IconWrapper>
        )}
      </InputWrapper>
    )
  },
)

type InputWrapperProps = {
  disabled?: boolean
  exactWidth?: number
  padding?: string
}

const InputWrapper = styled.div<InputWrapperProps>`
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid ${themeVar('line')};
  display: flex;
  justify-content: space-between;

  ${({ exactWidth }) => exactWidth
    && css`
      width: ${exactWidth}px;
    `}

  ${({ padding }) => padding
    && css`
      padding: ${padding};
    `}
`

const IconWrapper = styled.div<{disabled: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  fill: ${themeVar('gray3')};
  cursor: not-allowed;

  ${({ disabled }) => !disabled && css`
    cursor: pointer;

    svg:hover {
      fill: ${themeVar('main')};
    }
  `}
`

const Input = styled.input`
  background: rgba(0, 0, 0, 0);
  width: 100%;
  border: none;
  cursor: text;
  font-family: ${themeVar('baseFont')};

  &:disabled {
    opacity: 0.6;
  }

  &:focus {
    outline: none;
  }
`
