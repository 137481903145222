import { attach, combine } from 'effector'
import { createGate } from 'effector-react'

import {
  SurveyInfo, SurveyStep, getActualSurveyReqFx, sendSurveyResultReqFx,
} from '@/dal'

import { d } from './domain'
import { StepFormObject } from './types'

export const SurveyGate = createGate('SurveyGate')

export const $isModalOpen = d.store<boolean>(false)

export const openModal = d.event()
export const closeModal = d.event()

export const $surveyInfo = d.store<SurveyInfo | null>(null)
export const $surveyId = $surveyInfo.map((info) => info?.id ?? null)
export const $isSurveyCompleted = d.store<boolean>(false)
export const $stepsCount = $surveyInfo.map((info) => info?.steps.length ?? 0)

export const $currentStepNumber = d.store<number>(0)

export const goToNextStep = d.event()
export const goToPrevStep = d.event()
export const sendSurveyResult = d.event()

export const $stepForms = d.store<StepFormObject[]>([])

export const setStepForms = d.event<SurveyStep[]>()
export const changeStepFormField = d.event<{ stepId: number, choiceId: number, text?: string }>()
export const changeStepFormRating = d.event<{ stepId: number, rating: number }>()

export const $currentStep = combine(
  [$currentStepNumber, $surveyInfo],
  ([currentStepNumber, surveyInfo]) => surveyInfo?.steps.find(
    (step) => step.ordering === currentStepNumber,
  ) ?? null,
)

export const $isLastStep = combine(
  [$currentStepNumber, $stepsCount],
  ([currentStep, stepsCount]) => currentStep === stepsCount,
)

export const $currentStepForm = combine(
  [$stepForms, $currentStepNumber],
  ([stepForms, currentStep]) => stepForms[currentStep - 1] ?? null,
)

export const getActualSurveyFx = attach({
  effect: getActualSurveyReqFx,
})

export const sendSurveyResultFx = attach({
  effect: sendSurveyResultReqFx,
})
