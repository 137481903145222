import { createEffect } from 'effector'

import {
  DefaultUpdateItems,
  TableUpdatePayload,
  TableTreeItem,
  TreeType,
} from './types'

export const getUpdatedTree = <R extends TableTreeItem>({
  currentRoot,
  currentTree,
  tree,
  root,
  target,
  hasOnlyTargetInFolder,
  deletedId,
  updatingItemId,
}: {
  currentRoot: R[] | null,
  currentTree: TreeType<R>
  tree: TreeType<R>,
  root: R
  target?: R
  hasOnlyTargetInFolder?: boolean
  deletedId?: string | number
  // может быть ситуация, когда обновлённый элемент в ответе имеет уже другой id
  updatingItemId?: R['id']
}) => {
  const groupId = target?.group_id
  const updatedTree = {
    ...currentTree,
    ...tree,
  }

  if (deletedId) {
    delete updatedTree[deletedId]
  }

  return {
    root: currentRoot?.map((item) => (
      [root.id, updatingItemId].includes(item.id) ? root : item
    )) ?? null,
    tree: hasOnlyTargetInFolder && groupId
      ? {
        ...updatedTree,
        [groupId]: currentTree[groupId]?.map((item) => (
          [target.id, updatingItemId].includes(item.id) ? target : item
        )),
      }
      : updatedTree,
  }
}

export function createUpdateTreeItemsFx<T extends TableTreeItem>({
  hasOnlyTargetInFolder = false,
} = {}) {
  const effectFx = createEffect<DefaultUpdateItems<T>, TableUpdatePayload<T>, Error>()

  effectFx.use(({
    currentRoot,
    currentTree,
    root,
    target,
    tree,
    updatingItemId,
  }) => {
    if (root && tree) {
      return getUpdatedTree({
        currentRoot,
        currentTree,
        root,
        tree,
        target,
        updatingItemId,
        hasOnlyTargetInFolder,
      })
    }
    if (target) {
      return {
        root: currentRoot?.map((item) => (
          [target.id, updatingItemId].includes(item.id) ? target : item
        )) ?? null,
      }
    }
    return {
      root: currentRoot,
    }
  })
  return effectFx
}
