import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import { DocumentAlias } from '@/dal'
import { numberWithSpaces } from '@/lib/number-represent'
import { getFullTime } from '@/lib/date'
import { getRouterPathByDocumentAlias } from '@/lib/documentResolvers/getRouterPathByDocumentAlias'

import { Icon2 } from '../icons'
import { palette } from '../palette'
import { TextM, TextMLight, TextS } from '../typography'

type Props = {
  id: number
  isSelected?: boolean
  title: string
  dateTime: string
  docInfo: {
    projectName: string
    documentId: number
    documentType: DocumentAlias
    documentSum: number
    documentName: string
  } | null
  description: string
  responsible: string
  attachmentsCount: number
  hasAnswer: boolean
  isRead: boolean
  toWhom?: string
  type?: 'primary' | 'secondary'
  onSelect: (id: number) => void
  onMarkAsRead: (id: number) => void
}

export const AppealItem = ({
  isSelected = false,
  attachmentsCount,
  dateTime,
  id,
  onSelect,
  onMarkAsRead,
  title,
  docInfo,
  description,
  responsible,
  isRead,
  hasAnswer,
  toWhom,
  type = 'primary',
}: Props) => {
  const isTitleGrey = type === 'secondary'

  const DocumentItem = React.useMemo(() => {
    if (!docInfo) return null

    const link = getRouterPathByDocumentAlias(docInfo.documentType)

    if (link) {
      return (
        <Link onClick={(e) => e.stopPropagation()} to={`${link}/${docInfo.documentId}`}>
          <DocumentName isGrey={isTitleGrey}>
            {docInfo.documentName}
          </DocumentName>
        </Link>
      )
    }
    return (
      <DocumentName isGrey={isTitleGrey}>
        {docInfo.documentName}
      </DocumentName>
    )
  }, [docInfo, isTitleGrey])

  const handleSelect = () => {
    if (!isRead) onMarkAsRead(id)
    onSelect(id)
  }

  return (
    <Wrapper
      isSelected={isSelected}
      onClick={handleSelect}
    >
      <Header>
        <Title isSelected={isSelected}>
          {title}
        </Title>
        {type === 'primary' && !isRead && hasAnswer && <Icon2 icon="visaTask" color="accent80" />}
        <Date>
          {getFullTime(dateTime)}
        </Date>
      </Header>

      <AppealInfo>
        <AppealInfoRow>
          <AppealInfoLabel>
            Тема
          </AppealInfoLabel>
          <AppealInfoValue>
            {description}
          </AppealInfoValue>
        </AppealInfoRow>

        <AppealInfoRow>
          <AppealInfoLabel>
            {hasAnswer ? 'Ответил' : 'Кому'}
          </AppealInfoLabel>
          <AppealInfoValue>
            {hasAnswer ? responsible : toWhom}
          </AppealInfoValue>

          {attachmentsCount ? (
            <AttachWrapper>
              <Icon2
                icon="attach"
                color="grey60"
              />
              <AttachSpan>
                {attachmentsCount}
              </AttachSpan>
            </AttachWrapper>
          ) : null}
        </AppealInfoRow>
      </AppealInfo>

      {docInfo && (
        <DocumentInfo>
          <Row>
            <ProjectName isGrey={isTitleGrey}>
              {docInfo.projectName}
            </ProjectName>
            <SumLabel>
              Сумма документа, руб
            </SumLabel>
          </Row>

          <Row>
            {DocumentItem}
            <Sum>
              {numberWithSpaces(docInfo.documentSum.toString() || '0')}
            </Sum>
          </Row>
        </DocumentInfo>
      )}
    </Wrapper>
  )
}

const Title = styled.p<{ isSelected: boolean }>`
  ${TextM}
  transition: color 0.15s linear;

  ${({ isSelected }) => isSelected && css`
    color: ${palette.accent100};
  `}
`

const Wrapper = styled.div<{ isSelected: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 20px 32px;
  background-color: ${palette.white};
  cursor: pointer;
  transition: background-color 0.15s linear;

  &:not(:first-child) {
    border-top: 1px solid ${palette.grey30};
  }

  @media (hover: hover) {
    &:hover  {
      background-color: ${palette.grey10};

      ${Title} {
        color: ${palette.accent100};
      }
    }
  }

  ${({ isSelected }) => isSelected && css`
    background-color: ${palette.yellow10};

    @media (hover: hover) {
      &:hover {
        background-color: ${palette.yellow10};
      }
    }
  `}
`

const Header = styled.header`
  display: flex;
  align-items: center;
  gap: 8px;
`

const Date = styled.p`
  ${TextMLight}
  margin-left: auto;
  color: ${palette.grey70};
`

const AppealInfo = styled.div`
  ${TextMLight}
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const AppealInfoRow = styled.div`
  display: flex;
  gap: 4px;
`

const AppealInfoLabel = styled.div`
  flex-shrink: 0;
  width: 60px;
  color: ${palette.grey70};
`

const AppealInfoValue = styled.div``

const AttachWrapper = styled.div`
  display: flex;
  gap: 4px;
  margin-left: auto;
  color: ${palette.grey60};
`

const AttachSpan = styled.span`
  ${TextMLight}
  color: ${palette.grey60};
`

const DocumentInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`

const ProjectName = styled.p<{ isGrey: boolean }>`
  ${TextMLight}

  ${({ isGrey }) => isGrey && css`
    color: ${palette.grey70};
  `};
`

const SumLabel = styled.div`
  ${TextS}
  color: ${palette.grey60};
  text-align: right;
  text-transform: uppercase;
`

const DocumentName = styled.p<{ isGrey: boolean }>`
  ${TextMLight}
  color: ${({ isGrey }) => (isGrey ? palette.grey70 : palette.accent100)};
`

const Sum = styled.div`
  ${TextMLight}
`
