import { css } from 'styled-components'

import { WINDOW_WIDTH } from '@/ui'

export const TableTemplate = css`
  display: grid;
  grid-template-columns: 300fr 72px repeat(4, 250fr) 266fr;

  @media (max-width: ${WINDOW_WIDTH.bigDesktop - 1}px) {
    grid-template-columns: 300fr 72px repeat(4, 200fr) 275fr;
  }
/* @TODO кастомное разрешение */
  @media (max-width: 1710px) {
    grid-template-columns: 300fr 72px repeat(4, 127fr) 275fr;
  }
`
