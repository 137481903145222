import { attachWrapper } from '@42px/effector-extra'
import { AxiosError, AxiosResponse } from 'axios'
import { attach } from 'effector'

import { prepareFormData } from '@/lib/form-data'
import { Method, authRequestFx, downloadFilesFx } from '../request'
import {
  CancelPayload,
  CreatePlanFxPayload,
  FetchFundingWorkPayload,
  FetchFundingWorkResponse,
  FetchPopupData,
  Plan,
  ResponsePopupDataItem,
  SendPlanForApproveWithFilesPayload,
  SetFundingWorkVolumePayload,
  PutPlanParams,
  PutWorkItemResponse,
  GetAnalystResponse,
  GetSubmissionMonthsResponse,
} from './type'
import {
  AbortableRequest, DocumentAlias, Estimate, PaginationRequestParams, Response,
} from '../interfaces'
import { defaultErrorHandler } from '../error-handler'


export const loadPlanReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (planId: Plan['id']) => ({
    method: Method.get,
    url: `/budget/plan/${planId}`,
  }),
  mapResult: ({ result }: { result: AxiosResponse<Plan> }) => result.data,
  mapError: ({ error }: { error: AxiosError }) => error.response?.data,
})

export const createPlanReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ contractId, dateStart }: CreatePlanFxPayload) => ({
    method: Method.post,
    url: `/budget/contract_plans/${contractId}`,
    body: {
      date_start: dateStart,
    },
  }),
  mapResult: ({ result }: { result: AxiosResponse<Plan> }) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const loadPlanWorksReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (payload: FetchFundingWorkPayload & PaginationRequestParams) => ({
    method: Method.get,
    url: `/budget/plan_works/${payload.planId}`,
    query: {
      name: payload.name,
      group_id: payload.groupId,
      limit: payload.limit,
      offset: payload.offset,
      sort: payload.sort,
      order: payload.order,
      planned_month: payload.plannedMonth,
      analyst_article: payload.analystArticle,
      forecast: payload.forecast,
      residue: payload.residue,
    },
  }),
  mapResult: ({ result }: { result: AxiosResponse<FetchFundingWorkResponse> }) => ({
    total: result.data.count,
    data: result.data.results,
  }),
  mapError: ({ error }: { error: AxiosError }) => error.response?.data,
})

export const putPlanWorkVolumeReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ workId, count }: SetFundingWorkVolumePayload) => ({
    method: Method.put,
    url: `/budget/plan_works/${workId}`,
    body: {
      actual_plan_count_declarated: count,
    },
  }),
  mapResult: ({ result }: { result: AxiosResponse<PutWorkItemResponse> }) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const getPopupDataReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ work_id }: FetchPopupData) => ({
    method: Method.get,
    url: `/budget/plan_work_detail/${work_id}`,
  }),
  mapResult: ({ result }: { result: AxiosResponse<ResponsePopupDataItem> }) => result.data.detail,
  mapError: ({ error }: { error: AxiosError }) => error.response?.data,
})

export const uploadTableTemplateReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, files }: {id: Plan['id'], files: File[]}) => ({
    method: Method.post,
    url: `/budget/template/${id}/`,
    body: prepareFormData({ files }),
  }),
  mapResult: ({ result }: { result: AxiosResponse<FetchFundingWorkResponse> }) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const downloadFileTemplateReqFx = attach({
  effect: downloadFilesFx,
  mapParams: (id: Plan['id']) => ({
    method: Method.get,
    url: `/budget/template/${id}/`,
  }),
})

export const downloadPlanFilesReqFx = attach({
  effect: downloadFilesFx,
  mapParams: (id: Plan['id']) => ({
    method: Method.get,
    url: `/finplan/finplans/${id}/works`,
  }),
})

export const downloadPlanReportFileReqFx = attach({
  effect: downloadFilesFx,
  mapParams: (planId: Plan['id']) => ({
    method: Method.get,
    url: `/budget/plan/${planId}/report`,
  }),
})

export const putPlanReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ signal, id, ...body }: AbortableRequest<PutPlanParams>) => ({
    method: Method.put,
    url: `/budget/plan/${id}`,
    body: prepareFormData(body),
    signal,
  }),
  mapResult: ({ result }: { result: AxiosResponse<Plan> }) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const sendPlanForApproveReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ planId }: SendPlanForApproveWithFilesPayload) => ({
    method: Method.get,
    url: `/budget/budget_approve/${planId}`,
  }),
  mapResult: ({
    result,
  }: {
    result: AxiosResponse<Plan>
  }) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const cancelPlanApproveReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (payload: CancelPayload) => ({
    method: Method.post,
    url: `/budget/budget_reject/${payload.id}`,
    body: { message: payload.message },
  }),
  mapResult: ({ result }: { result: AxiosResponse<Plan> }) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const deletePlanReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (planId: Plan['id']) => ({
    method: Method.delete,
    url: `/budget/plan/${planId}`,
  }),
  mapResult: ({ result }: { result: AxiosResponse<[]> }) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const copyPlanReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (planId: Plan['id']) => ({
    method: Method.post,
    url: `/budget/plan/${planId}/create_copy/`,
  }),
  mapResult: ({ result }: {result: AxiosResponse<Plan> }) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const getAnalystReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (planId: Plan['id']) => ({
    method: Method.get,
    url: `/article_outlay/${DocumentAlias.BUDGET}/${planId}`,
  }),
  mapResult: ({ result }: { result: AxiosResponse<GetAnalystResponse> }) => result.data,
  mapError: ({ error }: { error: AxiosError }) => error.response?.data,
})

export const getSubmissionMonthsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, signal }: AbortableRequest<{ id: Estimate['id'] }>) => ({
    method: Method.get,
    url: '/budget/plan/get_submission_months/',
    query: {
      contract_id: id,
    },
    signal,
  }),
  mapResult: ({ result }: Response<GetSubmissionMonthsResponse>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => error.response?.data,
})
