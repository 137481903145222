import React, { FC } from 'react'
import styled from 'styled-components'

import { Icon } from './Icon'
import { MediumTextCSS } from './Typography'

type RejectionCommentProps = {
  children: React.ReactNode
}

export const RejectionComment: FC<RejectionCommentProps> = ({ children }) => (
  <Wrapper>
    <IconWrapper>
      <Icon icon="attention" />
    </IconWrapper>
    <TextWrapper>{children}</TextWrapper>
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
  max-width: 400px;
`

const IconWrapper = styled.div`
  min-width: 24px;
`

const TextWrapper = styled.div`
  ${MediumTextCSS}
`
