import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'
import { usePopper } from 'react-popper'
import FocusTrap from 'focus-trap-react'
import { DayContent, DayContentProps, DayPicker } from 'react-day-picker'
import ru from 'date-fns/locale/ru'
import { PositioningStrategy } from '@popperjs/core'

import { noop } from '@/lib/noop'

import { formatDateForBackend, getDayJsObject } from '@/lib/date'
import { themeVar } from './theming'
import { TextInput } from './inputs/TextInput'
import { InputTypes } from './inputs/types'

function DateTime(props: DayContentProps) {
  const date = getDayJsObject(props.date).format('DD-MM-YYYY')
  return (
    <div data-testid={date}>
      <DayContent {...props} />
    </div>
  )
}


type Props = {
  placeholder?: string
  onChange: (e: string) => void
  onClear?: () => void
  inputValue: string // YYYY-mm-dd
  initialOpenedDateValue?: string // YYYY-mm-dd
  dataTestId: string
  disabled?: boolean
  label?: string
  error?: boolean
  required?: boolean
  errorText?: string
  fullWidth?: boolean
  exactWidth?: number
  isErrorSoft?: boolean
  labelSize?: number
  noOpenOnClear?: boolean
  hiddenError?: boolean
  activeDateRange?: {
    from?: Date
    to?: Date
  }
  popperStrategy?: PositioningStrategy
  inputStyle?: InputTypes
  padding?: string
  height?: string
  fontSize?: number
  hideIcon?: boolean
  clearIconSize?: number
  isPreventKeyDown?: boolean
  tooltip?: string
}

export const DateInput = ({
  placeholder,
  onChange,
  inputValue,
  initialOpenedDateValue,
  dataTestId,
  disabled,
  label,
  error,
  required,
  errorText,
  fullWidth,
  exactWidth,
  isErrorSoft,
  labelSize,
  noOpenOnClear,
  activeDateRange,
  popperStrategy,
  onClear,
  hiddenError,
  inputStyle = 'second',
  padding,
  height,
  fontSize,
  hideIcon,
  clearIconSize,
  isPreventKeyDown,
  tooltip,
}: Props) => {
  const [isPopperOpen, setIsPopperOpen] = React.useState(false)
  const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null)
  const popperRef = React.useRef<HTMLDivElement>(null)

  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-start',
    strategy: popperStrategy,
  })

  const formattedValue = React.useMemo(() => {
    if (!inputValue) return ''
    return getDayJsObject(inputValue).format('DD.MM.YYYY')
  }, [inputValue])

  const isDateValid = React.useMemo(() => getDayJsObject(inputValue).isValid(), [inputValue])

  const date = React.useMemo(() => {
    if (!inputValue || !isDateValid) return undefined
    return new Date(inputValue)
  }, [inputValue])

  const initialOpenedDate = initialOpenedDateValue && new Date(initialOpenedDateValue)

  const handleBaseInputChange = useCallback(
    (e: string) => {
      if (isPreventKeyDown) return false
      const val = new Date(e)
      const date = formatDateForBackend(val)
      onChange(date)
    },
    [onChange],
  )

  const closePopper = () => {
    setIsPopperOpen(false)
  }

  const openPopper = () => {
    setIsPopperOpen(true)
  }

  const handleDaySelect = useCallback(
    (_, newValue: Date) => {
      const date = formatDateForBackend(newValue)
      onChange(date)
      closePopper()
    },
    [onChange],
  )

  return (
    <Wrapper
      fullWidth={fullWidth}
      exactWidth={exactWidth}
    >
      <PopperTarget ref={popperRef}>
        <TextInput
          padding={padding}
          onClear={onClear}
          inputStyle={inputStyle}
          isPointer={!disabled}
          label={label}
          labelSize={labelSize}
          placeholder={placeholder}
          iconColor={isPopperOpen ? 'calendarHighlightColor' : undefined}
          onChange={handleBaseInputChange}
          value={formattedValue}
          icon={hideIcon ? undefined : 'calendar'}
          onClick={disabled ? noop : openPopper}
          disabled={disabled}
          error={error}
          errorText={errorText}
          required={required}
          isErrorSoft={isErrorSoft}
          noOpenOnClear={noOpenOnClear}
          dataTestId={`${dataTestId}-text-input-date`}
          hiddenError={hiddenError}
          height={height}
          fontSize={fontSize}
          clearIconSize={clearIconSize}
          tooltip={tooltip}
        />
      </PopperTarget>
      {isPopperOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onDeactivate: closePopper,
          }}
        >
          <div
            tabIndex={-1}
            style={{
              marginTop: -1,
              ...popper.styles.popper,
            }}
            className="dialog-sheet"
            {...popper.attributes.popper}
            ref={setPopperElement}
            role="dialog"
          >
            <DayPicker
              components={{
                DayContent: DateTime,
              }}
              disabled={[
                (day) => {
                  if (!activeDateRange) return false
                  let result = false
                  if (activeDateRange.from) {
                    result = day < activeDateRange.from
                  }
                  if (!result && activeDateRange.to) {
                    result = day > activeDateRange.to
                  }
                  return result
                },
              ]}
              initialFocus={isPopperOpen}
              mode="single"
              defaultMonth={date || initialOpenedDate || activeDateRange?.from}
              selected={date}
              locale={ru}
              onSelect={handleDaySelect}
              data-testid={`${dataTestId}-select-input-date`}
            />
          </div>
        </FocusTrap>
      )}
    </Wrapper>
  )
}

const PopperTarget = styled.div`
  display: flex;
  flex-direction: column;
`

type WrapperProps = {
  fullWidth?: boolean
  exactWidth?: number
}

const Wrapper = styled.div<WrapperProps>`
  ${({ fullWidth }) => fullWidth
    && css`
      width: 100%;
      flex: 1;
    `}

  ${({ exactWidth }) => exactWidth
    && css`
      width: ${exactWidth}px;
    `}

  .dialog-sheet {
    background-color: #fff;
    border: 1px solid ${themeVar('calendarBorderColor')};
    z-index: 4;
    color: initial;
  }
`
