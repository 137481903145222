import React from 'react'
import styled from 'styled-components'

import { palette } from './v2/palette'

type Props = {
  sizePx?: number
  borderWidth?: number
}

export const Loader = ({ sizePx, borderWidth }: Props) => (
  <LoaderWrapper>
    <LoaderIcon sizePx={sizePx} borderWidth={borderWidth} />
  </LoaderWrapper>
)

export const SimpleLoader = ({ sizePx, borderWidth }: Props) => (
  <LoaderIcon sizePx={sizePx} borderWidth={borderWidth} />
)

const LoaderWrapper = styled.div`
  position: relative;
  top: 50%;
  left: 50%;
  width: min-content;
  height: min-content;
  transform: translate(-50%, -50%);
`

const LoaderIcon = ({ sizePx = 40, borderWidth = 4 }: Props) => {
  const center = React.useMemo(() => (
    sizePx / 2
  ), [sizePx])

  const offset = React.useMemo(() => (
    center - borderWidth / 2
  ), [borderWidth, center])

  const path = React.useMemo(() => (
    `M${center} ${borderWidth / 2}a${offset} ${offset} 0 0 1 ${0.64714285714 * offset} ${1.76228571429 * offset}`
  ), [borderWidth, center, offset])

  return (
    <SvgWrapper width={sizePx} height={sizePx} viewBox={`0 0 ${sizePx} ${sizePx}`} fill="none">
      <circle
        cx={center}
        cy={center}
        r={offset}
        stroke={palette.accent20}
        strokeWidth={borderWidth}
      />
      <path d={path} stroke={palette.accent100} strokeWidth={borderWidth} strokeLinecap="round" />
    </SvgWrapper>
  )
}

const SvgWrapper = styled.svg`
  display: block;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
