import { sample, split } from 'effector'

import { AppealItem, OtherAppealAlias } from '@/dal'
import { EXISTING_FILE_FLAG } from '@/lib/form-data'

// TODO устранить возможные циклические зависимости в будущем (здесь и не только)
import { $appeals } from '../../request-history/model/private'
import { appealForm } from '../../new-appeal/model/forms'
import {
  copyAppeal,
  copyDocumentProblemAppeal,
  copyLkpProblemAppeal,
  setAppealIdToCopy,
} from './copy.private'
import { selectAppealType, setTechSupportAppealType } from './private'
import { documentProblemForm, lkpSectionProblemForm } from './forms'

sample({
  clock: setAppealIdToCopy,
  source: $appeals,
  filter: (appeals, id) => appeals.some((item) => item.id === id),
  fn: (appeals, id) => appeals.find((item) => item.id === id) as AppealItem,
  target: copyAppeal,
})

split({
  source: copyAppeal,
  match: {
    copyDocumentProblemAppeal: ({ issue_type, section_data }) => (
      issue_type === OtherAppealAlias.TechSupport && !section_data
    ),
    copyLkpProblemAppeal: ({ issue_type, section_data }) => (
      issue_type === OtherAppealAlias.TechSupport && Boolean(section_data)
    ),
  },
  cases: {
    copyDocumentProblemAppeal,
    copyLkpProblemAppeal,
  },
})

sample({
  clock: copyAppeal,
  fn: (appeal) => {
    // TODO в обращениях ответственному пока без копирования файлов
    const files = appeal.issue_type === OtherAppealAlias.TechSupport
      ? appeal.messages[0]?.files.map((item) => new File([''], item.name, {
        type: `${EXISTING_FILE_FLAG}/${item.id}`,
      })) ?? []
      : []
    return {
      files,
      document: appeal.related_document_id,
      documentType: appeal.related_document_type,
      subject: appeal.subject,
      description: appeal.messages[0]?.content ?? '',
      section: appeal.section_data?.type ?? null,
    }
  },
  target: [appealForm.setForm, documentProblemForm.setForm, lkpSectionProblemForm.setForm],
})

sample({
  clock: copyAppeal,
  fn: ({ issue_type }) => issue_type,
  target: selectAppealType,
})

sample({
  clock: copyDocumentProblemAppeal,
  fn: () => 'document' as const,
  target: setTechSupportAppealType,
})

sample({
  clock: copyLkpProblemAppeal,
  fn: () => 'section' as const,
  target: setTechSupportAppealType,
})
