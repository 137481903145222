import { sample } from 'effector'

import { onDocListLoaded } from '../../filters/model'
import { $headerInfo, setHeaderInfo } from './private'
import { DocumentFlowListGate } from '../../list/model'

$headerInfo
  .on(setHeaderInfo, (_, info) => info)
  .reset(DocumentFlowListGate.close)

sample({
  clock: onDocListLoaded,
  fn: ({ data: { items, ...payload } }) => payload,
  target: setHeaderInfo,
})
