import React from 'react'
import { useUnit, useStoreMap } from 'effector-react'

import { Work } from '@/dal'
import { CellNumberInput } from '@/ui'
import { numberWithSpaces } from '@/lib/number-represent'

import {
  $disabledRowIds,
  onAvansInputChanged,
  onMaxValueError,
  putAvansItemFx,
} from '../../../model/table.private'
import { $isBlockChanges } from '../../../model/private'

type Props = {
  sumAvansRequest: string
  id: Work['id']
  avansRemain: string
}

export const TableRowInput = React.memo(({
  avansRemain,
  id,
  sumAvansRequest,
}: Props) => {
  const [inputVal, setInputVal] = React.useState<string>(sumAvansRequest)
  const isBlockChanges = useUnit($isBlockChanges)

  const isLoading = useStoreMap({
    store: $disabledRowIds,
    keys: [id],
    fn: (ids, [itemId]) => ids.includes(itemId),
  })

  React.useEffect(() => {
    setInputVal(sumAvansRequest)
  }, [sumAvansRequest])

  const sendValue = () => {
    if (inputVal === sumAvansRequest) return
    if (parseFloat(inputVal) > parseFloat(avansRemain)) {
      onMaxValueError()
      setInputVal(avansRemain)
    } else {
      onAvansInputChanged({
        id,
        value: inputVal,
      })
    }
  }

  const handleFill = () => {
    if (inputVal === avansRemain) return
    onAvansInputChanged({
      id,
      value: avansRemain,
    })
  }

  React.useEffect(() => {
    const unWatch = putAvansItemFx.fail.watch(({ params }) => {
      if (params.id === id) {
        setInputVal(sumAvansRequest)
      }
    })
    return () => {
      unWatch()
    }
  }, [])

  return (
    <CellNumberInput
      displayValue={numberWithSpaces(inputVal)}
      onChange={setInputVal}
      inputValue={inputVal}
      decimalScale={2}
      dataTestId={`avans-input-${id}`}
      onBlur={sendValue}
      textAlign="right"
      customIcon="copy"
      onIconClick={handleFill}
      isDisable={isLoading || isBlockChanges}
      iconTooltip="Заполнить по остатку"
    />
  )
})
