import {
  DocumentAlias,
  DocumentId,
  DocumentProgress,
  DocumentStatuses,
  DocumentSubType,
  ExistingKorDoc,
  PaginationRequestParams,
  PaginationType,
} from '../interfaces'
import { PaymentSubTypes } from '../payment'

export type GetDocFlowListData = {
  items: DocumentItem[]
  all_documents_count: number
  active_tasks: number
  approved_tasks: number
}

export type GetDocFlowListResponse = PaginationType<GetDocFlowListData>

export type GetDocFlowFiltersResponse = {
  available_statuses: DocumentStatuses[]
  available_names: string[]
  available_projects: string[]
  available_organizations: string[]
  available_estimate_lot_directions: {
    [key: number]: string
  }
}

export type FetchFilterPayload = PaginationRequestParams & {
  filter?: DocumentAlias
  document_type?: DocFlowDocSubtype // и значеня с бэка для лотов
  name?: string
  project_name?: string
  organization_name?: string
  date_from?: string // YYYY-MM-DD
  date_to?: string // YYYY-MM-DD
  status?: DocumentStatuses[]
  incoming_number?: string
  incoming_date_from?: string
  incoming_date_to?: string
  under_contract?: string
}

export enum DocFlowDocSubtype {
  SMR = 'smr', // СМР
  PD_RD = 'pd_rd', // ПД+РД
  BANK_WARRANTY = 'bank_warranty', // Банковская гарантия
  CONTRACT_GUARANTEE = 'contract_guarantee', // Договор поручительства

  ADDITIONAL_WORK = 'additional_work', // Дополнительные работы
  CHANGE_CONDITION = 'change_condition', // Изменение условий

  TYPE_ACT = 'type_act', // Постоплата
  TYPE_SMR = 'type_smr', // Аванс на СМР
  TYPE_THIRD_PARTIES = 'type_third_parties', // Аванс на материалы
}

export type DocumentItem = {
  answer_required: boolean | string
  available_files: boolean
  code_1c: string
  date: string // YYYY-MM-DD
  id: DocumentId
  name: string
  number_1c: string
  period: {
    date_start: string | null // YYYY-MM-DD
    date_stop: string | null // YYYY-MM-DD
  }
  doc_type?: DocumentSubType | null
  status: DocumentStatuses
  sums: number
  type: DocumentAlias
  uuid_1c?: string
  pay_type?: PaymentSubTypes // Только для платежей
  conflicting_draft_kor_doc: ExistingKorDoc
  download_link: string | null // absolute url
  available_actions: {
    can_copy: boolean
    can_delete: boolean
    additional_request: boolean
  }
  progress: Pick<DocumentProgress, 'responsible' | 'agreed_by_default' | 'total_visas_default'> | null
  incoming_number: string | null
  incoming_date: string | null
  project_name: string
  document_type: DocFlowDocSubtype | null
  organization_name: string
}
