import React from 'react'
import { Link } from 'react-router-dom'

import { DocumentAlias } from '@/dal'
import { NotificationItem, NotificationType } from '@/ui'
import { getRouterPathByDocumentAlias } from '@/lib/documentResolvers'

type Props = {
  id: string | number
  name: string
  docType: DocumentAlias
}

export const ConflictingDraftWarning = ({ id, name, docType }: Props) => {
  return (
    <NotificationItem
      type={NotificationType.Warning}
      title="Внимание!"
      body={(
        <>
          У вас есть сформированный черновик
          {' '}
          <Link target="_blank" to={`${getRouterPathByDocumentAlias(docType)}/${id}`}>
            {name}
          </Link>
          , он будет автоматически удален при создании копии документа.
        </>
      )}
    />
  )
}
