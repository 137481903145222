import { sample } from 'effector'
import { v4 as uuidv4 } from 'uuid'

import { NotificationType } from '@/ui'

import {
  $toasts, closeToastFx, onCloseButton, setTimeoutFx, setToastTimeout,
} from './private'
import { ToastItem } from './types'
import { showToast } from './public'

$toasts
  .on(showToast, (toasts, toast) => [...toasts, {
    ...toast,
    id: uuidv4(),
    showClose: true,
  }])
  .on(setTimeoutFx.doneData, (toasts, { id, timeoutId }) => (
    toasts.map((toast) => (toast.id === id ? {
      ...toast,
      timeoutId,
      showClose: toast.icon === NotificationType.Alert,
    } : toast))
  ))
  .on(closeToastFx.doneData, (toasts, id) => toasts.filter((item) => item.id !== id))

sample({
  clock: onCloseButton,
  source: $toasts,
  filter: (toasts, id) => toasts.some((item) => item.id === id),
  fn: (toasts, id) => toasts.find((item) => item.id === id) as ToastItem,
  target: closeToastFx,
})

sample({
  clock: setToastTimeout,
  target: setTimeoutFx,
})

setTimeoutFx.use(({ id, ms }) => {
  const timeoutId = setTimeout(() => {
    closeToastFx({ id, timeoutId })
  }, ms)

  return {
    id,
    timeoutId,
  }
})

closeToastFx.use(({ id, timeoutId }) => {
  clearTimeout(timeoutId)
  return id
})
