import {
  Domain,
  Effect,
  Store,
  attach,
  sample,
} from 'effector'

import { root } from '@/root-domain'
import { CustomResponseError } from '@/dal'
import { createToast } from '@/features/toast-service/model'

type CreateFileModalParams<Id, Done, E> = {
  domain?: Domain
  sendFilesFx: Effect<{ id: Id, files?: File[], [key: string]: unknown }, Done, E>
  $documentId: Store<Id | null>
  $files: Store<{file: string, name: string}[]>
}

export const createFileModalModel = <T, R, E extends CustomResponseError>({
  domain,
  sendFilesFx,
  $documentId,
  $files,
}: CreateFileModalParams<T, R, E>) => {
  const d = domain || root.domain()

  const $isOpen = d.store<boolean>(false)
  const openModal = d.event<void>()
  const closeModal = d.event<void>()

  const $uploadedFiles = d.store<File[]>([])
  const onRemove = d.event<string>()
  const onFiles = d.event<File[]>()
  const sendFiles = d.event<void>()

  const innerSendFilesFx = attach({
    effect: sendFilesFx,
  })

  $isOpen
    .on(openModal, () => true)
    .reset(closeModal)

  $uploadedFiles
    .on(onFiles, (prevFiles, file) => (prevFiles ? [...prevFiles, ...file] : file))
    .on(onRemove, (files, fileName) => files.filter((file) => file.name !== fileName))
    .reset(closeModal, innerSendFilesFx.doneData)

  sample({
    clock: sendFiles,
    source: {
      files: $uploadedFiles,
      id: $documentId,
    },
    filter: (
      params: {id: T | null, files: File[]},
    ): params is {id: T, files: File[]} => Boolean(params.id),
    target: innerSendFilesFx,
  })

  createToast({
    effect: innerSendFilesFx,
    doneText: 'Файлы отправлены',
  })

  return {
    sendFiles,
    openModal,
    closeModal,
    onFiles,
    onRemove,
    sendFilesFx: innerSendFilesFx,

    $uploadedFiles,
    $isOpen,
    $files,
  }
}
