import React, { memo } from 'react'
import { useUnit } from 'effector-react'

import { OtherAppealAlias } from '@/dal'

import { cancelAppealModal } from '../../model'
import { cancelSupportAppealFx } from '../../model/private'
import { CancelResponsibleAppeal, CancelTechSupportAppeal } from '../containers'

export const CancelAppealModal = memo(() => {
  const [info, isSending] = useUnit([
    cancelAppealModal.$meta,
    cancelSupportAppealFx.pending,
  ])

  if (!info) return null

  const modalProps = { info, isSending }

  return info.issue_type === OtherAppealAlias.TechSupport ? (
    <CancelTechSupportAppeal {...modalProps} />
  ) : (
    <CancelResponsibleAppeal {...modalProps} />
  )
})
