import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { HeadL, TabSwiper } from '@/ui'

import { $selectedCompanyId, selectCompany } from '../../model'
import { $myCompanyList } from '../../model/private'

type Props = {
  title: string
  children: React.ReactNode
}

export const RouteWrapper = React.memo(({ title, children }: Props) => {
  const list = useUnit($myCompanyList)
  const selectedCompanyId = useUnit($selectedCompanyId)

  return (
    <Wrapper>
      <Title>
        {title}
      </Title>
      <TabsWrapper>
        <TabSwiper
          items={list}
          activeId={selectedCompanyId}
          onItemClick={selectCompany}
          disableArrow
        />
      </TabsWrapper>
      {children}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const Title = styled.div`
  ${HeadL}
`

const TabsWrapper = styled.div`
  // TODO временное решение
  clip-path: inset(0 0 1px 0);
`
