import { attachWrapper } from '@42px/effector-extra'
import { AxiosError, AxiosResponse } from 'axios'
import { attach } from 'effector'

import {
  AbortableRequest,
  DownloadDocumentFilePayload,
  StatusResponse,
  Response,
} from '../interfaces'
import { Method, authRequestFx, downloadFilesFx } from '../request'
import { FetchFilterPayload, GetDocFlowFiltersResponse, GetDocFlowListResponse } from './type'
import { defaultErrorHandler } from '../error-handler'

export const getDocumentListReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ signal, status, ...query }: AbortableRequest<FetchFilterPayload>) => ({
    method: Method.get,
    url: '/doc-flow/docs/',
    query: {
      status: status?.join(',') || undefined,
      ...query,
    },
    signal,
  }),
  mapResult: ({ result }: Response<GetDocFlowListResponse>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const getDocumentFlowStatusReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: () => ({
    method: Method.get,
    url: '/status/',
    query: { 'sub-system': 'doc-flow' },
  }),
  mapResult: ({ result }: {result: AxiosResponse<StatusResponse[]>}) => result.data.map((item) => ({
    value: item.map_id.toString(),
    text: item.name,
  })),
})

export const getDocumentFlowFiltersReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ signal, status, ...payload }: AbortableRequest<FetchFilterPayload>) => ({
    method: Method.get,
    url: '/doc-flow/docs/filters/',
    query: {
      status: status?.join(',') || undefined,
      ...payload,
    },
    signal,
  }),
  mapResult: ({ result }: Response<GetDocFlowFiltersResponse>) => result.data,
})

export const downloadDocFlowReportReqFx = attach({
  effect: downloadFilesFx,
  mapParams: (payload: FetchFilterPayload) => ({
    url: '/doc-flow/docs/report/',
    query: { ...payload },
  }),
})

export const getDocumentsFileReqFx = attach({
  effect: downloadFilesFx,
  mapParams: ({ id, type }: DownloadDocumentFilePayload) => ({
    url: `/doc-flow/${type}_file/${id}`,
  }),
})

export const getDocumentFileByUrlReqFx = attach({
  effect: downloadFilesFx,
  mapParams: ({ url }: DownloadDocumentFilePayload & { url: string }) => ({
    url,
  }),
})
