import { attach } from 'effector'

import {
  AdditionalRequestModalInfo,
  createEstimateLotReqFx,
  downloadLotFilesByUrlReqFx,
} from '@/dal'
import { $authorizedUserData } from '@/features/login/model'

import { d } from './domain'

export const $contragentList = $authorizedUserData.map((info) => (info
  ? info.company.map((item) => ({
    id: item.id,
    label: item.name,
  }))
  : null),
)
export const $selectedContragentId = d.store<number | null>(null)
export const selectContragent = d.event<number>()

export const $downloadInfo = d.store<number[]>([])

export const $isLotCreationModalOpen = d.store(false)
export const openLotCreationModal = d.event()
export const closeLotCreationModal = d.event()

export const createEstimateLot = d.event<number>()

export const onAdditionalButtonClicked = d.event<AdditionalRequestModalInfo>()
export const onCopyButtonClicked = d.event<number>()
export const onDeleteButtonClicked = d.event<number>()

export const downloadEstimateLotDocs = d.event<{ id: number, url: string }>()

export const createEstimateLotFx = attach({
  effect: createEstimateLotReqFx,
})

export const downloadEstimateLotDocsFx = attach({
  effect: downloadLotFilesByUrlReqFx,
})
