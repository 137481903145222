import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'
import { useForm } from 'effector-forms'

import {
  HeadXL,
  ModalLayoutV2,
  NewButton,
  NewDateInput,
  NotificationItem,
  NotificationType,
  TextM,
} from '@/ui'
import { getDayJsObject } from '@/lib/date'
import { noop } from '@/lib/noop'

import {
  $isReportingDatesModalOpen, actReportingDatesForm, closeDateModal, postActReportingDatesFx,
} from '../../../model/info.private'

export const ActReportingDatesModal = React.memo(() => {
  const [isOpen, isPending] = useUnit([
    $isReportingDatesModalOpen,
    postActReportingDatesFx.pending,
  ])

  const {
    fields, isValid, isTouched, submit,
  } = useForm(actReportingDatesForm)

  if (!isOpen) return null

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    submit()
  }

  return (
    <ModalLayoutV2 closeModal={closeDateModal}>
      <Wrapper>
        <Head>
          Изменение отчетного периода
        </Head>

        <NotificationItem
          type={NotificationType.Info}
          body={(
            <>
              <AccentWrapper>Для первого выполнения: </AccentWrapper>
              начало отчетного периода = дате начала строительства;
              <br />
              <AccentWrapper>для последующих выполнений: </AccentWrapper>
              начало отчетного периода = окончанию отчетного периода в предыдущем акте + 1 день.
            </>
          )}
        />

        <Form onSubmit={handleSubmit}>
          <InputRow>
            <NewDateInput
              label="Начало отчетного периода"
              inputValue={fields.date_start.value}
              onChange={noop}
              disabled
              dataTestId="act-edit-period-select-from-date"
            />
            <NewDateInput
              label="Конец отчетного периода"
              inputValue={fields.date_end.value}
              initialOpenedDateValue={new Date().toDateString()}
              onChange={fields.date_end.onChange}
              errorText={fields.date_end.errorText()}
              disabled={isPending}
              dataTestId="act-edit-period-select-to-date"
              activeDateRange={{
                from: getDayJsObject(fields.date_start.value).add(1, 'day').toDate(),
              }}
              required
            />
          </InputRow>

          <Row>
            <NewButton
              dataTestId="submit-button"
              label="Сохранить"
              buttonType="primary"
              isFill
              type="submit"
              isDisabled={isPending || !isValid || !isTouched}
            />
            <NewButton
              dataTestId="cancel-button"
              label="Отменить"
              buttonType="grey"
              isDisabled={isPending}
              isFill
              onClick={closeDateModal}
            />
          </Row>
        </Form>
      </Wrapper>
    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  width: 470px;
  max-width: 100%;
  padding: 32px;
`

const Head = styled.div`
  ${HeadXL}
`

const AccentWrapper = styled.span`
  ${TextM}
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const InputRow = styled.div`
  display: flex;
  gap: 24px;
`

const Row = styled.div`
  display: flex;
  gap: 16px;
`
