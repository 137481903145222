import { createSectionTabsModel } from '@/features/factories'

import { d } from './domain'
import { EstimateLotGate } from './public'
import { EstimateLotTab } from './types'

export const {
  $activeTabId,
  $invalidActiveTabId,
  changeActiveTab,
  resetActiveTab,
  setInvalidActiveTab,
} = createSectionTabsModel<EstimateLotTab>({
  defaultValue: EstimateLotTab.Info,
  clearEvent: EstimateLotGate.close,
  domain: d,
})
