import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { HeadM, NewFileItem, palette } from '@/ui'
import { $contract } from '../../../model/private'

const $contractReports = $contract.map((contract) => contract?.reports || null)

export const Reports = React.memo(() => {
  const reports = useUnit($contractReports)
  if (!reports) return null

  return (
    <Wrapper>
      <Title>Отчеты</Title>
      <ReportsList>
        {reports.map((item) => (
          <NewFileItem
            isDense
            extension={item.extension}
            key={item.id}
            name={item.name}
            url={item.url}
          />
        ))}
      </ReportsList>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  background-color: ${palette.white};
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 20px;
`

const Title = styled.div`
  ${HeadM}
`

const ReportsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`
