import React, { memo, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { useStoreMap, useUnit } from 'effector-react'

import { LotRowType, LotTableItem } from '@/dal'
import {
  FirstTableCell,
  NewTextButton,
  SimpleLoader,
  TABLE_BORDER_STYLE,
} from '@/ui'
import { RouterPaths } from '@/features/app/model'
import { $documentId } from '@/features/estimate-lot/model'
import { ButtonCell, Cell } from '@/features/estimate-lot/shared'
import { getMorphologyString, getRowTypeName } from '@/features/estimate-lot/model/helpers'

import {
  $editingWork,
  $isByConstructionElements,
  $isFirstGroupOpen,
  $isTreeTable,
  $pendingFolderIds,
  $tableTree,
  $updatingWorkIds,
  changeLorWorkComment,
  closeFolder,
  openFolder,
  openTableItemFiles,
  setEditingWork,
  setWorkOrFolderToDelete,
} from '../../model/private'
import { $units } from '../../model'
import { GridRow, CommentAndFilesCell } from '../parts'

type Props = {
  item: LotTableItem
}

export const TableItem = memo(({ item }: Props) => {
  const {
    id,
    name,
    description,
    unit_id,
    count,
    price_all: priceAll,
    price_material: priceMaterial,
    price_work: priceWork,
    cost_all: costAll,
    cost_material: costMaterial,
    cost_work: costWork,
    comment,
    is_folder: isFolder,
    hierarchy_code,
    has_children: hasChildren,
    attachments,
    depth,
    row_type: rowType,
    morphology_elements: morphology,
  } = item

  const [units, isTree, isFirstGroupOpen, editingWork, isByConstructionElements, docId] = useUnit([
    $units,
    $isTreeTable,
    $isFirstGroupOpen,
    $editingWork,
    $isByConstructionElements,
    $documentId,
  ])

  const areChildrenLoading = useStoreMap({
    store: $pendingFolderIds,
    keys: [id, isFolder],
    fn: (arr, [id, isFolder]) => isFolder && arr.includes(id),
  })

  const isExpanded = useStoreMap({
    store: $tableTree,
    keys: [id],
    fn: (map, [rowId]) => Boolean(map[rowId]),
  })

  const isCommentUpdating = useStoreMap({
    store: $updatingWorkIds,
    keys: [id],
    fn: (arr, [rowId]) => arr.includes(rowId),
  })

  const isEditing = useMemo(() => (
    editingWork?.id === id
  ), [editingWork, id])

  const unitName = useMemo(() => (
    units.find(({ id }) => id === unit_id?.id)?.short_name || ''
  ), [unit_id, units])

  const rowTypeName = useMemo(() => {
    if (!rowType || isFolder) return ''
    return getRowTypeName(rowType)
  }, [rowType, isFolder])

  const elementName = useMemo(() => (
    getMorphologyString(morphology ?? undefined)
  ), [morphology])

  const onItemClick = useCallback(() => {
    if (!isFolder || !hasChildren || areChildrenLoading) return
    if (isExpanded) {
      closeFolder(id)
    } else {
      openFolder(id)
    }
  }, [id, isExpanded, isFolder, hasChildren, areChildrenLoading])

  const handleToggleComment = (val: boolean) => {
    if (val) {
      setEditingWork({ id, comment: comment ?? '' })
    } else {
      changeLorWorkComment({ id, comment: editingWork?.comment ?? '' })
    }
  }

  const isMaterial = rowType && rowType !== LotRowType.Work
  const itemName = hierarchy_code ? `${hierarchy_code} ${name}` : name

  return (
    <>
      <GridRow
        as="td"
        isFirstGroupOpen={isFirstGroupOpen}
        isByConstructionElements={isByConstructionElements}
      >
        <FirstCellWrapper span={isFolder ? 4 : 1}>
          <FirstTableCell
            name={itemName}
            depth={depth}
            hasChildren={hasChildren}
            isFolder={isFolder}
            isExpanded={isExpanded}
            isTree={isTree}
            hasRightDivider={!isFolder && !isFirstGroupOpen}
            isAlignCenter
            onToggleExpand={onItemClick}
          />
        </FirstCellWrapper>

        {isFirstGroupOpen && (
          <Cell>
            {description}
          </Cell>
        )}

        {!isFolder && (
          <>
            <Cell
              value={unitName}
              valueType="string"
              hasLightGreyBackground
              isGrey
            />

            {!isByConstructionElements ? (
              <Cell hasLightGreyBackground>
                <NewTextButton
                  url={`${RouterPaths.EstimateLots}/${docId}/work/${id}`}
                  label="Добавить"
                  prefixIcon="plusWithCircle"
                  textStyle="M"
                  dataTestId=""
                />
              </Cell>
            ) : (
              <>
                <Cell value={rowTypeName} valueType="string" />
                <Cell value={elementName} valueType="string" align="left" />
              </>
            )}

            <Cell value={count} align="right" decimalScale={4} hasLightGreyBackground />
          </>
        )}

        <SplitCell>
          <Cell value={priceAll} hasLightGreyBackground={isFolder} />
          <CellRow>
            {isMaterial ? (
              <Cell bgColor="grey10" />
            ) : (
              <Cell value={priceWork} hasLightGreyBackground={isFolder} />
            )}
            <Cell value={priceWork} hasLightGreyBackground={isFolder} />
            <Cell value={priceMaterial} hasLightGreyBackground={isFolder} />
          </CellRow>
        </SplitCell>

        <SplitCell>
          <Cell value={costAll} hasLightGreyBackground={isFolder} />
          <CellRow>
            {isMaterial ? (
              <Cell bgColor="grey10" />
            ) : (
              <Cell value={costWork} hasLightGreyBackground={isFolder} />
            )}
            <Cell value={costMaterial} hasLightGreyBackground={isFolder} />
          </CellRow>
        </SplitCell>

        {!isByConstructionElements && (
          <>
            {isFolder ? (
              <Cell />
            ) : (
              <CommentAndFilesCell
                commentValue={(isEditing ? editingWork?.comment : comment) ?? ''}
                fileCount={attachments?.length ?? 0}
                isDisabled={isCommentUpdating}
                onChangeComment={(comment) => setEditingWork({ id, comment })}
                onOpenAttachments={() => openTableItemFiles(item)}
                onToggle={handleToggleComment}
              />
            )}
            <ButtonCell icon="delete" onClick={() => setWorkOrFolderToDelete(item)} />
          </>
        )}
      </GridRow>

      {areChildrenLoading && (
        <LoaderWrapper>
          <SimpleLoader sizePx={40} />
        </LoaderWrapper>
      )}
    </>
  )
})

const FirstCellWrapper = styled.div<{ span: number }>`
  width: 100%;
  height: 100%;
  text-align: left;
  grid-column: span ${({ span }) => span};
  outline: none;
`

const SplitCell = styled.div`
  display: flex;
  flex-direction: column;

  & > *:first-child {
    height: 50%;
  }
`

const CellRow = styled.div`
  display: flex;
  border-top: ${TABLE_BORDER_STYLE};
  border-right: ${TABLE_BORDER_STYLE};
  height: 50%;
`

const LoaderWrapper = styled.td`
  display: flex;
  justify-content: center;
  padding: 12px 0;
  border-bottom: ${TABLE_BORDER_STYLE};
`
