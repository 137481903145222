import React from 'react'
import styled from 'styled-components'

import { HeadL, palette, TextMLight } from '@/ui'

type Props = {
  title: string
  subtitle?: string
}

export const ModalHeader = ({ title, subtitle }: Props) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      {subtitle && (
        <Subtitle>{subtitle}</Subtitle>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.header`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Title = styled.p`
  ${HeadL}
`

const Subtitle = styled.p`
  ${TextMLight}
  color: ${palette.grey70};
`
