import React from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import styled from 'styled-components'

import { IconName2 } from '../icons'
import { PaletteColor } from '../palette'
import { NewIconButton } from '../buttons'

type Props = {
  icon?: IconName2
  iconColor?: PaletteColor,
  hoverIconColor?: PaletteColor,
  onClick?: () => void
  treshold?: number
  scrollContainerRef: React.RefObject<Scrollbars>
  bgColor?: PaletteColor
  hoverBgColor?: PaletteColor
}

export const ScrollTopButton = ({
  icon = 'arrowUp',
  treshold = 100,
  bgColor = 'white',
  iconColor = 'grey60',
  hoverIconColor,
  scrollContainerRef,
  hoverBgColor,
  onClick,
}: Props) => {
  const [show, setShow] = React.useState(false)

  const handleScroll = () => {
    if ((scrollContainerRef.current?.getScrollTop() || 0) > treshold) {
      setShow(true)
    } else {
      setShow(false)
    }
  }

  React.useLayoutEffect(() => {
    if (!scrollContainerRef.current) return
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    scrollContainerRef.current.view?.addEventListener('scroll', handleScroll)
    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      scrollContainerRef.current?.view?.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll, treshold])

  if (!show) return null

  const handleClick = () => {
    scrollContainerRef.current?.scrollTop(0)
    onClick?.()
  }

  return (
    <Wrapper>
      <NewIconButton
        dataTestId="scroll-top"
        icon={icon}
        size={16}
        backgroundColor={bgColor}
        borderRadius="50%"
        padding="16px"
        onClick={handleClick}
        color={iconColor}
        hoverColor={hoverIconColor || iconColor}
        hoverBackgroundColor={hoverBgColor || bgColor}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  right: 16px;
  bottom: 16px;
  z-index: 999;
`
