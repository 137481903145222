import React from 'react'
import styled from 'styled-components'

import { WINDOW_WIDTH } from '@/ui/theming'

import { palette } from '../palette'
import { HEADER_HEIGHT } from '../const'

type Props = {
  closeModal: () => void
  children: React.ReactFragment | React.ReactFragment[]
  maxWidth?: number
}

export const DrawerWrapper = ({
  children,
  closeModal,
  maxWidth = 600,
}: Props) => {
  React.useEffect(() => {
    document.body.style.overscrollBehaviorY = 'none'
    return () => {
      document.body.style.removeProperty('overscroll-behavior-y')
    }
  }, [])

  return (
    <>
      <Overlay onClick={() => closeModal()} />
      <Wrapper maxWidth={maxWidth}>
        {children}
      </Wrapper>
    </>
  )
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 11;
`

const Wrapper = styled.div<{ maxWidth: number }>`
  position: fixed;
  top: 0;
  top: ${HEADER_HEIGHT}px;
  bottom: 0;
  right: 0;
  background-color: ${palette.white};
  max-width: ${({ maxWidth }) => maxWidth}px;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  box-shadow: 3px 10px 15px 0px ${palette.shadow};

  @media (min-width: ${WINDOW_WIDTH.bigDesktop}px) {
    right: calc((100dvw - ${WINDOW_WIDTH.bigDesktop}px) / 2);
  }
`
