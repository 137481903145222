import { attach, combine } from 'effector'
import { createGate } from 'effector-react'

import {
  AbortableRequest,
  CompanyItem,
  ContractItem,
  GetCompanyContractsParams,
  getCompanyContractReqFx,
  getContractDirectionsReqFx,
  putContractVisibilityReqFx,
  updateClosurePercentageReqFx,
} from '@/dal'
import { createSingleEffect } from '@/lib/createSingleEffect'
import { CreateDocumentPayload } from '@/features/document-manager/model'

import { d } from './domain'
import { $companyList } from './public'
import { ChangeVisibilityParams, OnAction, OpenAdditionalModal } from './types'

export const setCompany = d.event<CompanyItem>()

export const $openedContractIds = d.store<number[]>([])
export const toggleOpenContract = d.event<number>()
export const openAllContracts = d.event<number[]>()

export const ContractListGate = createGate('ContractListGate')

export const $companyContractList = d.store<ContractItem[]>([])

export const $pendingIds = d.store<number[]>([])

export const resetSelectedCompanyContract = d.event()

export const $selectedCompany = d.store<CompanyItem | null>(null)
export const selectCompany = d.event<number>()

export const onChangeVisibility = d.event<ChangeVisibilityParams>()

export const onClickActionButton = d.event<OnAction>()
export const openAdditionalModal = d.event<OpenAdditionalModal>()

export const $reportsContractId = d.store<ContractItem['id'] | null>(null)
export const openContractReports = d.event<ContractItem['id']>()
export const closeContractReports = d.event()

export const onCloseButton = d.event<{ needDocs: boolean, id: ContractItem['id'] }>()
export const updateContractItem = d.event<ContractItem>()
export const onGetRetentionButton = d.event<ContractItem['id']>()

export const onCreateDocument = d.event<CreateDocumentPayload>()

export const $companyItems = combine(
  $companyList,
  (list) => list
    ?.map((item) => ({
      label: item.name,
      id: item.id,
    })) || null,
)

export const $pendingClosureUpdateIds = d.store<ContractItem['id'][]>([])
export const $updatedClosureIds = d.store<ContractItem['id'][]>([])
export const onUpdateClosurePercentage = d.event<ContractItem['id']>()

export const changeContractVisiblityFx = attach({
  effect: putContractVisibilityReqFx,
})

export const updateClosurePercentageFx = attach({
  effect: updateClosurePercentageReqFx,
})

export const getContractDirectionsFx = attach({
  effect: getContractDirectionsReqFx,
})

export const {
  requestFx: getCompanyContractFx,
  abortFx,
} = createSingleEffect<
  AbortableRequest<GetCompanyContractsParams>, ContractItem[]
    >(getCompanyContractReqFx, { abortEvents: [ContractListGate.close] })
