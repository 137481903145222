import React from 'react'
import styled from 'styled-components'
import Scrollbars from 'react-custom-scrollbars-2'

type Props = {
  children: React.ReactNode
}

export const FlexScrollbar = ({ children }: Props) => {
  return (
    <Scrollbars
      renderView={(props) => <Wrapper {...props} className="view" />}
      style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
      autoHeight
      autoHeightMin={0}
      autoHeightMax="100%"
      hideTracksWhenNotNeeded
    >
      {children}
    </Scrollbars>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`
