import {
  FetchFundingWorkPayload,
  FundingSortCriteria,
  PaginationRequestParams,
  Plan,
  TreeItem,
} from '@/dal'

export const mapWorkParams = ({
  plan,
  name,
  sort,
  group,
  filter,
  offset,
}: {
  plan: Plan | null
  name: string
  sort: FundingSortCriteria
  group: TreeItem['id'] | null
  offset?: number,
  filter: {
    forecast: string | null;
    analystArticle: string | null;
    residue: string | null;
    plannedMonth: string | null;
}
}) => {
  const payload: FetchFundingWorkPayload & PaginationRequestParams = {
    name,
    planId: (plan as Plan).id,
    offset,
  }

  if (sort.column) {
    // payload.sort_code_1c = sort.direction
    payload.sort = sort.column
    payload.order = sort.direction
  }

  if (group) {
    payload.groupId = group
  }
  Object.keys(filter).forEach((key) => {
    // eslint-disable-next-line
    // @ts-ignore
    if (filter[key]) {
      // eslint-disable-next-line
      // @ts-ignore
      payload[key] = filter[key]
    }
  })
  return payload
}
