import React from 'react'
import styled from 'styled-components'

import {
  ButtonType,
  NewButton,
  TextMLight,
  palette,
  usePopup,
} from '@/ui'
import { ContractItem } from '@/dal'

export type Props = {
  type: ButtonType
  contractId: ContractItem['id']
  isPending?: boolean
  onButton: (flag: boolean) => void
}

export const CloseButton = ({
  type,
  contractId,
  isPending,
  onButton,
}: Props) => {
  const {
    refs,
    floatingStyles,
    getFloatingProps,
    getReferenceProps,
    close,
    isOpen,
  } = usePopup({
    isDisable: isPending,
    strategy: 'fixed',
  })

  const preventClick = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleButton = (e: React.MouseEvent, flag: boolean) => {
    preventClick(e)
    onButton?.(flag)
    close()
  }

  return (
    <>
      <Wrapper onClick={preventClick}>
        <NewButton
          size="S"
          dataTestId={`close-contract-${contractId}`}
          label="Закрыть договор"
          buttonType={type}
          postfixIcon={isOpen ? 'arrowUp' : 'arrowDown'}
          isFill
          isPending={isPending}
          ref={refs.setReference}
          {...getReferenceProps()}
        />
      </Wrapper>
      {isOpen && (
        <Overlay
          onClick={close}
        >
          <Popup
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            В соответствии с условиями договора нужны ли вам закрывающие документы?
            <ButtonRow>
              <NewButton
                dataTestId="need-closest-docs"
                label="Нужны"
                buttonType="secondary"
                onClick={(e) => handleButton(e, true)}
              />
              <NewButton
                dataTestId="need-closest-docs"
                label="Не нужны"
                buttonType="grey"
                onClick={(e) => handleButton(e, false)}
              />
            </ButtonRow>
          </Popup>
        </Overlay>
      )}
    </>

  )
}

const Wrapper = styled.div`
  width: 100%;
`

const Overlay = styled.div`
  position: fixed;
  inset: 0px;
  z-index: 10;
`

const Popup = styled.div`
  background-color: ${palette.white};
  border-radius: 8px;
  box-shadow: 0px 2px 10px 0px ${palette.shadow};
  padding: 12px 12px 8px;
  ${TextMLight}
  max-width: 300px;
`

const ButtonRow = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 12px;
`
