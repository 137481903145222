import { createGate } from 'effector-react'
import { $contract, getContractByIdFx } from './private'
import { d } from './domain'
import { ContractTab } from './types'

export const ContractInfoGate = createGate<{ id: string }>('ContractInfo')

export const $isContractPending = getContractByIdFx.pending

export const $activeTab = d.store(ContractTab.MainInfo)
export const changeContractTab = d.event<ContractTab>()

export const $contractDirection = $contract.map((info) => info?.direction ?? null)
