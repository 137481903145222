import React from 'react'

import { EditProfile, ProfileInfo } from '@/features/edit-profile'
import { PageWithNavigation } from '@/ui'
import { RouterPaths } from '@/features/app/model'

export const EditProfilePage = () => {
  return (
    <PageWithNavigation
      links={[{ label: 'Личные данные', path: RouterPaths.UserInfo }]}
      navHeaderSlot={<ProfileInfo />}
    >
      <EditProfile />
    </PageWithNavigation>
  )
}
