import React from 'react'
import styled from 'styled-components'
import { themeVar } from './theming'

export const BadgeCount = styled.span`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  font-size: 10px;
  width: 18px;
  height: 18px;
  background-color: ${themeVar('main')};
  color: ${themeVar('notificationFontColor')};
`

type Props = {
  children: React.ReactNode
}

export const BadgeCountInline = ({ children }: Props) => (
  <BadgeCountContainer>
    <BadgeCountInlineWrapper>
      {children}
    </BadgeCountInlineWrapper>
  </BadgeCountContainer>
)

const BadgeCountContainer = styled.span`
  display: inline-block;
  margin-right: 4px;
`

export const BadgeCountInlineWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  font-size: 10px;
  width: 18px;
  height: 18px;
  background-color: ${themeVar('main')};
  color: ${themeVar('notificationFontColor')};
`

export const BadgeCountRounded = styled.div`
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  background-color: ${themeVar('main')};
  color: ${themeVar('lightBackground')};
  font-size: 14px;
  padding: 2px;
  border-radius: 4px;
  width: min-content;
`
