import { AppealItem, CancelAppealPayload } from '@/dal'
import { createModal } from '@/features/factories'

import { d } from './domain'
import { CancelAppealModalInfo } from './types'

export const cancelSupportAppeal = d.event<CancelAppealPayload>()
export const onAppealCanceled = d.event<AppealItem>()

export const cancelAppealModal = createModal<CancelAppealModalInfo>()
