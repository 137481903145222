import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import {
  HeadXL,
  NewLinkButton,
  DefaultButton,
  Icon2,
} from '@/ui'
import {
  $openedService,
  $openedServiceLinks,
  resetOpenedService,
} from '../../model/private'

export const ServiceLinks = React.memo(() => {
  const openedService = useUnit($openedService)
  const links = useUnit($openedServiceLinks)

  if (!links) return null

  return (
    <>
      <BackButton onClick={() => resetOpenedService()} data-testid="services-back-button">
        <Icon2 icon="arrowLeft" color="grey90" size={24} />
        <Title>{openedService?.name}</Title>
      </BackButton>

      <Container>
        {links.map(({ url }) => (
          <NewLinkButton key={url} label={url} url={url} isExternal isNewTab />
        ))}
      </Container>
    </>
  )
})

const BackButton = styled(DefaultButton)`
  display: flex;
  align-items: center;
  gap: 8px;
`

const Title = styled.h3`
  ${HeadXL}
`

const Container = styled.div`
  display: grid;
  gap: 8px;
`
