import React from 'react'
import styled from 'styled-components'
import { useForm } from 'effector-forms'
import { useGate, useUnit } from 'effector-react'
import { useCheckFieldsDirty } from '@/lib/hooks/useCheckFieldsDirty'

import {
  InputField,
  NewButton,
  NewTextButton,
  PasswordField,
} from '@/ui'
import { RouterPaths } from '@/features/app/model'

import {
  $credentialsError,
  LoginGate,
  loginForm,
  loginFx,
} from '../../model/private'

export const LoginForm = React.memo(() => {
  const { fields, submit } = useForm(loginForm)
  const isCredentialError = useUnit($credentialsError)
  const isPending = useUnit(loginFx.pending)
  useGate(LoginGate)
  const isDirty = useCheckFieldsDirty(fields)
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    submit()
  }
  const loginErrorText = fields.email.firstError?.errorText
  const passwordErrorText = fields.password.firstError?.errorText

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <FieldWrapper>
          <InputField
            dataTestId="input-login-email"
            value={fields.email.value}
            onChange={fields.email.onChange}
            onClear={fields.email.reset}
            placeholder="Введите логин, он же e-mail"
            error={loginErrorText}
            type="email"
            name="login"
            size="L"
            isRequired
            isInvalid={isCredentialError}
          />
          <PasswordField
            dataTestId="input-login-password"
            onChange={fields.password.onChange}
            value={fields.password.value}
            error={passwordErrorText}
            placeholder="Введите пароль"
            name='"password"'
            isInvalid={isCredentialError}
          />
        </FieldWrapper>

        <NewButton
          dataTestId="button-security-login"
          size="M"
          type="submit"
          label="Войти"
          isPending={isPending}
          isDisabled={!isDirty}
          isFill
        />
      </Form>

      <Links>
        <NewTextButton
          dataTestId=""
          label="Забыли пароль"
          url={RouterPaths.RestorePassword}
          postfixIcon="arrowRight"
          textStyle="L"
        />
        <NewTextButton
          dataTestId=""
          label="Потеряли письмо о регистрации"
          url={RouterPaths.RepeatRegistrationLetter}
          postfixIcon="arrowRight"
          textStyle="L"
        />
        <NewTextButton
          dataTestId=""
          label="Запросить доступ в личный кабинет"
          url={RouterPaths.RequestForAccess}
          postfixIcon="arrowRight"
          textStyle="L"
        />
      </Links>
    </>
  )
})

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 400px;
`

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 8px;
`

const Links = styled.div`
  margin-top: 24px;
  width: 100%;
  text-align: left;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`
