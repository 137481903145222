import { attach } from 'effector'

import {
  CompanyItem,
  DocumentStatuses,
  FileDTO,
  PaymentInfo,
  PaymentSubTypes,
  closeBankWarrantySplashReqFx,
  downloadTemplateLinkReqFx,
  getPaymentFeaturesReqFx,
  getPaymentItemReqFx,
  getPaymentTemplateLinkReqFx,
  postPaymentFilesReqFx,
  sendPaymentToApproveReqFx,
} from '@/dal'
import { createAttachmentsModalModel } from '@/features/factories/attach-modal'
import { createModal } from '@/features/factories'

import { d } from './domain'

export const $isThirdPerson = d.store(false)
export const setIsThirdPerson = d.event<boolean>()

export const onDelete = d.event<void>()
export const onAdditionalRequestClick = d.event<void>()

export const openCompany = d.event<CompanyItem['id']>()
export const onOpenAttachments = d.event<void>()

export const $showConfirm = d.store(false)
export const onChangeInfo = d.event<void>()
export const onConfirm = d.event<void>()
export const closeConfirm = d.event<void>()

export const deleteFile = d.event<FileDTO['id']>()
export const sendFiles = d.event<File[]>()

export const $paymentInfo = d.store<PaymentInfo | null>(null)
export const $paymentStatus = $paymentInfo.map((info) => info?.status ?? null)
export const $isDraft = $paymentStatus.map((status) => status === DocumentStatuses.Draft)
export const $paymentType = $paymentInfo.map((info) => info?.pay_type ?? null)
export const $isPaymentAct = $paymentInfo.map((info) => info?.pay_type === PaymentSubTypes.ACT)
export const $paymentInfoFiles = $paymentInfo.map((info) => info?.attachments ?? null)
export const $canSendFiles = $paymentInfo
  .map((info) => Boolean(info?.features.can_add_attachments))
export const $paymentDocumentId = $paymentInfo.map((info) => info?.id ?? null)
export const $paymentFeatures = $paymentInfo.map((info) => info?.features ?? null)
export const $paymentRequisites = $paymentInfo.map((info) => (info ? ({
  payer: info.requisites.payer,
  payee: info.requisites.payee,
}) : null))
export const $canEdit = $paymentInfo.map((info) => Boolean(info?.features.can_edit))
export const $areAttachmentsVisible = $paymentInfo.map((info) => (
  Boolean(info?.features.are_attachments_visible)
))
export const $contragentInfo = $paymentInfo.map((info) => info?.contragent_request || null)

export const updatePayment = d.event<PaymentInfo>()
export const onApproveButton = d.event<void>()

export const downloadTemplateLink = d.event()

export const changePaymentTypeModal = createModal<{ value: boolean }>()

export const postPaymentToApproveFx = attach({
  effect: sendPaymentToApproveReqFx,
})

export const $isBlockChanges = postPaymentToApproveFx.pending

export const postPaymentFilesFx = attach({
  effect: postPaymentFilesReqFx,
})

export const getPaymentItemFx = attach({
  effect: getPaymentItemReqFx,
})

export const closeBankWarrantySplashFx = attach({
  effect: closeBankWarrantySplashReqFx,
})

export const getPaymentFeaturesFx = attach({
  effect: getPaymentFeaturesReqFx,
})

export const getTemplateLinkFx = attach({
  effect: getPaymentTemplateLinkReqFx,
})

export const downloadTemplateLinkFx = attach({
  effect: downloadTemplateLinkReqFx,
})

export const attachmentsModel = createAttachmentsModalModel({
  domain: d,
  sendFx: postPaymentFilesFx,
  $canSendFiles,
  $files: $paymentInfoFiles,
})
