import React, { useCallback } from 'react'

import { formatDateForBackend, getDayJsObject } from '@/lib/date'

import { DateInputCommonProps } from '../types'
import { DateInputWrapper } from './DateInputWrapper'

type Props = DateInputCommonProps &{
  inputValue: string
  onChange: (e: string) => void
}

export const NewDateInput = ({
  onChange,
  inputValue,
  onClear,
  ...props
}: Props) => {
  const formattedValue = React.useMemo(() => {
    if (!inputValue) return ''
    return getDayJsObject(inputValue).format('DD/MM/YYYY')
  }, [inputValue])

  const isDateValid = React.useMemo(
    () => inputValue && getDayJsObject(inputValue).isValid(),
    [inputValue],
  )

  const date = React.useMemo(() => {
    if (!inputValue || !isDateValid) return undefined
    return new Date(inputValue)
  }, [inputValue, isDateValid])

  const handleDaySelect = useCallback(
    (_, newValue: Date) => {
      const date = formatDateForBackend(newValue)
      onChange(date)
    },
    [onChange],
  )

  return (
    <DateInputWrapper
      {...props}
      mode="single"
      formattedValue={formattedValue}
      inputValue={date}
      selected={date}
      noOpenOnClear
      onDaySelect={handleDaySelect}
      onClear={onClear}
    />
  )
}
