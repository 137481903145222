import React from 'react'
import styled from 'styled-components'
import { useGate } from 'effector-react'

import {
  HeadL,
} from '@/ui'

import { EditProfileForm } from '../containers'
import { EditProfileGate } from '../../model/private'

export const EditProfile = React.memo(() => {
  useGate(EditProfileGate)

  return (
    <Wrapper>
      <Header>
        Личные данные
      </Header>

      <EditProfileForm />
    </Wrapper>
  )
})

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 400px;
`


const Header = styled.div`
  ${HeadL}
`
