import React from 'react'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { Icon, IconName } from './Icon'
import { ButtonTheme } from './theming/themes/main'
import { themeVar } from './theming/theme-var-helper'
import { ThemedStyledProps, useTheme } from './theming'

type Props = {
  icon?: IconName
  size?: 'sm' | 'md' | 'lg'
  children?: JSX.Element | React.ReactNode
  disabled?: boolean
  color?: string
  bgColor?: string
  hoverColor?: string
  borderColor?: string
  hoverBgColor?: string
  title?: string
  style?:
    | 'primary'
    | 'secondary'
    | 'buttonL'
    | 'crossFiltre'
    | 'buttonS'
    | 'third'
    | 'actHasMessage'
    | 'actNoMessage'
    | 'agreedContractor'
  onClick?: () => void
  fixedSize?: string
  iconSizePx?: number
  iconPosition?: 'right' | 'left'
  customStyleTitle?: FlattenSimpleInterpolation
  dataTestId: string
}

export const RoundButton = ({
  disabled = false,
  style = 'primary',
  size = 'md',
  onClick,
  icon,
  children,
  title,
  color,
  bgColor,
  hoverBgColor,
  hoverColor,
  fixedSize,
  borderColor,
  iconSizePx = 24,
  iconPosition = 'right',
  customStyleTitle,
  dataTestId,
}: Props) => {
  const theme = useTheme()

  let targetTheme: ButtonTheme = theme.buttonPrimary
  switch (style) {
    case 'secondary':
      targetTheme = theme.buttonSecondary
      break
    case 'third':
      targetTheme = theme.buttonThird
      break
    case 'buttonL':
      targetTheme = theme.buttonL
      break
    case 'crossFiltre':
      targetTheme = theme.buttonCrossFiltre
      break
    case 'actHasMessage':
      targetTheme = theme.actHasMessage
      break
    case 'actNoMessage':
      targetTheme = theme.actNoMessage
      break
    case 'agreedContractor':
      targetTheme = theme.agreedContractor
      break
    default: break
  }

  const themeCopy = {
    static: { ...targetTheme.static },
    hover: { ...targetTheme.hover },
    disabled: { ...targetTheme.disabled },
  }

  if (color) {
    themeCopy.static.textColor = color
  }

  if (borderColor) {
    themeCopy.static.borderColor = borderColor
    themeCopy.hover.borderColor = borderColor
  }

  if (bgColor) {
    themeCopy.static.bgColor = bgColor
  }

  if (hoverBgColor) {
    themeCopy.hover.bgColor = hoverBgColor
    themeCopy.hover.borderColor = hoverBgColor
  }

  if (hoverColor) {
    themeCopy.hover.textColor = hoverColor
  }

  const IconComponent = icon && (
    <IconWrapper position={iconPosition}>
      <Icon icon={icon} size={iconSizePx} />
    </IconWrapper>
  )

  return (
    <Wrapper
      fixedSize={fixedSize}
      title={title}
      disabled={disabled}
      buttonTheme={themeCopy}
      size={size}
      onClick={onClick}
      iconSizePx={iconSizePx}
      data-testid={dataTestId}
    >
      {iconPosition === 'left' && <>{ IconComponent }</>}
      <TextWrapper customStyleTitle={customStyleTitle}>{children}</TextWrapper>
      {iconPosition === 'right' && <>{ IconComponent }</>}
      {style === 'actHasMessage' && !disabled && <HasMessage />}
    </Wrapper>
  )
}

type WrapperProps = {
  buttonTheme: ButtonTheme
  disabled: boolean
  size: 'sm' | 'md' | 'lg'
  fixedSize?: string
  iconColor?: string
  iconSizePx?: number
}

const Wrapper = styled.button<ThemedStyledProps & WrapperProps>`
  position: relative;
  border-radius: 90px;
  padding-left: 12px;
  padding-right: 12px;
  height: 30px;
  width: 100%;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;

  svg {
    width: ${({ iconSizePx }) => iconSizePx}px;
    height: ${({ iconSizePx }) => iconSizePx}px;
  }

  ${({ size }) => size === 'lg'
    && css`
      padding-left: 16px;
      padding-right: 16px;
      height: 40px;
    `}

  ${({ buttonTheme, disabled }) => disabled
    && css`
      color: ${buttonTheme.disabled.textColor};
      border: 1px solid ${buttonTheme.disabled.borderColor};
      background-color: ${buttonTheme.disabled.bgColor};
      cursor: default;
      svg {
        fill: ${buttonTheme.disabled.iconColor};
      }
      &:hover {
        svg {
          fill: ${buttonTheme.disabled.iconColor};
        }
      }
    `}

  ${({ buttonTheme, disabled }) => !disabled
    && css`
      color: ${buttonTheme.static.textColor};
      border: 1px solid ${buttonTheme.static.borderColor};
      background-color: ${buttonTheme.static.bgColor};
      &:hover {
        color: ${buttonTheme.hover.textColor};
        border: 1px solid ${buttonTheme.hover.borderColor};
        background-color: ${buttonTheme.hover.bgColor};
        svg {
          fill: ${buttonTheme.hover.textColor};
        }
      }
    `}

  ${({ fixedSize }) => Boolean(fixedSize)
    && css`
      width: ${fixedSize};
      height: ${fixedSize};
      padding: 0;
      box-sizing: border-box;
    `}

  ${({ buttonTheme, disabled }) => !disabled
    && css`
      svg {
        fill: ${buttonTheme.static.iconColor};
      }
      &:hover {
        svg {
          fill: ${buttonTheme.hover.iconColor};
        }
      }
    `}

  &:hover {
    span {
      background-color: ${themeVar('lightBackground')};
      border: 1px solid ${themeVar('linkColor')};
    }
  }
`
type TextProps = {
  customStyleTitle?: FlattenSimpleInterpolation
}

const TextWrapper = styled.div<TextProps>`
  display: flex;
  align-items: center;
  font-family: ${themeVar('baseFont')};
  white-space: nowrap;

  ${({ customStyleTitle }) => customStyleTitle}
`

type IconWrapperProps = {
  position: 'right' | 'left'
}

const IconWrapper = styled.div<IconWrapperProps>`
  margin-left: 4px;
  ${({ position }) => position === 'left'
    && css`
      margin-right: 4px;
      margin-left: 0px;
    `}
  display: flex;
  align-items: center;
`

const HasMessage = styled.span`
  position: absolute;
  top: -1px;
  right: -1px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  border: 0;
  background-color: ${themeVar('linkColor')};
`
