import React from 'react'

import { usePopup, useSearch } from '../hooks'
import { Option } from './types'

type Params = {
  options: Option[] | null
  hasSearch?: boolean,
  sameComponentWidth?: boolean,
  offsetNumber?: number
  defaultOptionText?: string
  minWidth?: string
  onToggle?: (val: boolean) => void
} & ({
  isMultiple?: false
  selectedId: Option['id'] | null
  onOptionClick: (id: Option['id'] | null) => void
} | {
  isMultiple: true
  selectedId: Option['id'][]
  onOptionClick: (id: Option['id'][]) => void
})

const areArraysEqual = (a: unknown[], b: unknown[]) => {
  const setA = new Set(a)
  const setB = new Set(b)
  return !setA.symmetricDifference(setB).size
}

export const useDropDownInput = ({
  onToggle,
  onOptionClick,
  defaultOptionText = 'Ничего не найдено',
  minWidth,
  options,
  selectedId,
  hasSearch,
  sameComponentWidth = true,
  offsetNumber,
  isMultiple,
}: Params) => {
  const [isFocus, setFocus] = React.useState(false)
  const [selectedOptions, setSelectedOptions] = React.useState<Option['id'][]>([])

  const onToggleDropDown = React.useCallback((val: boolean) => {
    onToggle?.(val)
    setFocus(val)
  }, [onToggle, setFocus])

  const {
    setValue,
    filteredItems,
    value,
  } = useSearch<Option>({
    defaultItem: { id: 0, label: defaultOptionText, subLabel: undefined },
    items: options,
    keyForSearch: 'label',
    hasSearch,
  })

  const {
    close: closeDropDown,
    forceClose,
    floatingStyles,
    getFloatingProps,
    getReferenceProps,
    isOpen,
    open: openDropDown,
    refs,
  } = usePopup({
    sameComponentWidth,
    strategy: 'fixed',
    onToggle: onToggleDropDown,
    onClose: () => {
      setValue('')
      if (isMultiple && !areArraysEqual(selectedId, selectedOptions)) {
        onOptionClick(selectedOptions)
      }
    },
    offsetNumber,
    minWidth,
  })

  const activeLabel = React.useMemo(() => {
    if (isMultiple) {
      const { length } = selectedOptions
      return length ? `Выбрано: ${length}` : ''
    }
    const item = options?.find(({ id }) => id === selectedId)
    return item?.label || ''
  }, [selectedId, options, selectedOptions])

  const sortedItems = React.useMemo(() => {
    if (!filteredItems) return null
    const items = [] as Option[]
    filteredItems.forEach((item) => {
      if (item.id === selectedId) {
        items.unshift(item)
      } else {
        items.push(item)
      }
    })
    return items
  }, [filteredItems, selectedId])

  const handleOptionClick = (id: Option['id'] | null) => {
    if (isMultiple) {
      if (selectedOptions.includes(id)) {
        setSelectedOptions(selectedOptions.filter((item) => item !== id))
      } else {
        setSelectedOptions([...selectedOptions, id])
      }
    } else {
      if (id !== selectedId) {
        onOptionClick(id)
      }
      closeDropDown()
    }
  }

  const handleInputChange = (val: string) => {
    if (!hasSearch) return
    if (!isOpen) openDropDown()
    setValue(val)
  }

  const handleClear = () => {
    if (hasSearch && isOpen) {
      setValue('')
    } else if (isMultiple) {
      onOptionClick([])
      forceClose()
    } else {
      handleOptionClick(null)
    }
  }

  React.useEffect(() => {
    if (isMultiple) {
      setSelectedOptions(selectedId ?? [])
    }
  }, [selectedId])

  return {
    activeLabel,
    closeDropDown,
    floatingStyles,
    getFloatingProps,
    getReferenceProps,
    handleClear,
    handleInputChange,
    handleOptionClick,
    isOpen,
    isFocus,
    refs,
    sortedItems,
    value,
    selectedOptions,
  }
}
