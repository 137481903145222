import { sample } from 'effector'

import { createToast } from '@/features/toast-service/model'

import {
  $submissionMonths,
  CreatePlanGate,
  createPlanForm,
  createPlanFx,
  getSubmissionMonthsFx,
} from './create-plan.private'

import { $createDocumentInfo } from './private'
import { onCreatedDocument } from './public'

$submissionMonths
  .on(getSubmissionMonthsFx.doneData, (_, months) => months)
  .reset(CreatePlanGate.close)

createToast({
  effect: createPlanFx,
})

sample({
  clock: CreatePlanGate.close,
  target: createPlanForm.reset,
})

sample({
  clock: createPlanForm.formValidated,
  source: $createDocumentInfo,
  filter: Boolean,
  fn: ({ contractId }, fields) => ({
    contractId,
    ...fields,
  }),
  target: createPlanFx,
})

sample({
  clock: createPlanFx.doneData,
  target: onCreatedDocument,
})

sample({
  clock: CreatePlanGate.open,
  source: $createDocumentInfo,
  filter: Boolean,
  fn: ({ contractId }) => ({ id: contractId }),
  target: getSubmissionMonthsFx,
})
