import React from 'react'
import styled from 'styled-components'

import { palette } from '@/ui'

type Props = {
  children: React.ReactNode
  closeModal: () => void
}

export const MessagesModalLayout = ({ children, closeModal }: Props) => (
  <ModalOverlay onMouseDown={closeModal}>
    <ModalWrapper onMouseDown={(e) => e.stopPropagation()}>
      {children}
    </ModalWrapper>
  </ModalOverlay>
)

const ModalOverlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: 10;

  display: flex;
  justify-content: flex-end;

  padding: 96px 40px 12px;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
`

const ModalWrapper = styled.div`
  position: relative;
  width: 540px;
  border-radius: 20px;
  background-color: ${palette.white};
`
