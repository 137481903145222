import React from 'react'
import styled from 'styled-components'

import { palette } from '@/ui'

import { FilterLabels } from '../parts'
import { FilterInputs } from '../containers'

export const DocumentFlowFilters = React.memo(() => {
  return (
    <Wrapper>
      <FilterLabels />
      <FilterInputs />
    </Wrapper>
  )
})

const Wrapper = styled.div`
  border-bottom: 2px solid ${palette.grey20};
`
