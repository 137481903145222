import React from 'react'
import styled, { css } from 'styled-components'
import { usePopperTooltip } from 'react-popper-tooltip'

import { noop } from '@/lib/noop'
import { IconButton } from '@/ui/buttons/iconButton'
import { PopperToolTip } from '@/ui/PopperToolTip'

import { Icon } from '../../Icon'
import { ThemedStyledProps, themeVar } from '../../theming'
import { InputWrapperProps } from '../types'

export const FirstInputWrapper = ({
  children,
  error = false,
  errorText,
  isErrorSoft,
  noNumberStyle = false,
  tooltip,
  asDropdown,
  icon,
  iconColor,
  hasVal,
  disabled,
  isPointer,
  hiddenError,
  padding,
  clearIconSize = 16,
  postFixIcon,
  dataTestId,
  hoverIconColor,
  onClick,
  onClear,
  onIconClick,
  onPostFixIconClick,
}: InputWrapperProps) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    placement: 'top',
  })

  return (
    <Container>
      <Wrapper
        ref={setTriggerRef}
        onClick={onClick}
        error={error}
        disabled={disabled}
        isPointer={isPointer}
        noNumberStyle={noNumberStyle}
        padding={padding}
      >
        {icon && (
          <IconWrapper>
            <IconButton
              dataTestId={`${dataTestId}-button`}
              onClick={() => onIconClick?.()}
              iconColor={iconColor}
              hoverIconColor={hoverIconColor}
              iconSize={24}
              size={24}
              icon={icon}
            />
          </IconWrapper>
        )}

        {children}

        {onClear && (
          <ClearIconWrapper>
            {hasVal && !disabled && (
              <div onClick={(e) => onClear(e)}>
                <IconButton
                  onClick={noop}
                  size={clearIconSize}
                  iconSize={clearIconSize}
                  dataTestId="clear-input-button"
                  icon="cross"
                />
              </div>
            )}
          </ClearIconWrapper>
        )}

        {postFixIcon && (
          <IconButton
            dataTestId={`${dataTestId}-post-fix`}
            onClick={() => onPostFixIconClick?.()}
            iconColor={iconColor}
            hoverIconColor={hoverIconColor}
            iconSize={24}
            size={24}
            icon={postFixIcon}
          />
        )}

        {asDropdown && <Icon icon="arrow-down" />}
      </Wrapper>

      {!hiddenError && (
        <ErrorTextWrapper>
          {(error || isErrorSoft) && (
            <ErrorText isErrorSoft={isErrorSoft} error={error}>
              {errorText}
            </ErrorText>
          )}
        </ErrorTextWrapper>
      )}
      {tooltip && (
        <PopperToolTip
          getArrowProps={getArrowProps}
          getTooltipProps={getTooltipProps}
          setTooltipRef={setTooltipRef}
          tooltip={tooltip}
          visible={visible}
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;

  &:hover {
    span {
      display: block;
    }
  }
`

type WrapperProps = {
  error?: boolean
  disabled?: boolean
  isPointer?: boolean
  noNumberStyle?: boolean
  padding?: string
}

const Wrapper = styled.div<ThemedStyledProps & WrapperProps>`
  cursor: ${({ isPointer }) => (isPointer ? 'pointer' : 'text')};
  padding: ${({ padding }) => (padding || '10px 0px')};
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${themeVar('baseInputBorderColor')};
  width: 100%;

  ${({ error }) => error
    && css`
      border: 1px solid ${themeVar('main')};
    `}

  ${({ disabled }) => Boolean(disabled)
    && css`
      input {
        opacity: 0.6;
      }
    `}

    ${({ noNumberStyle }) => noNumberStyle
    && css`
      input[type='number']::-webkit-outer-spin-button,
      input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      input[type='number'],
      input[type='number']:hover,
      input[type='number']:focus {
        appearance: none;
        -moz-appearance: textfield;
      }
    `}
`

const IconWrapper = styled.div`
  margin-right: 8px;
`

type ErrorTextProps = {
  isErrorSoft?: boolean
  error?: boolean
}

const ErrorTextWrapper = styled.div`
  height: 16px;
  margin-top: 4px;
`

const ErrorText = styled.div<ErrorTextProps>`
  color: ${themeVar('main')};

  color: ${({ isErrorSoft }) => isErrorSoft
    && css`
      ${themeVar('textGray')}
    `};

  color: ${({ error }) => error
    && css`
      ${themeVar('inputErrorColor')}
    `};

  font-size: 12px;
`

const ClearIconWrapper = styled.div`
  width: 20px;
`

