import React from 'react'
import styled, { css } from 'styled-components'
import { IconButton } from './buttons/iconButton'

import { themeVar } from './theming'

type Props = {
  value: string
  onFocus?: () => void
  onChange: (s: string) => void
  onBlur?: () => void
  onClear?: () => void
  placeholder?: string
  isError?: boolean
  errorText?: string
  disabled?: boolean
  label?: string
  resizeBlock?: boolean
  dataTestId: string
  hiddenError?: boolean
}
export const TextArea2: React.FC<Props> = ({
  placeholder,
  value,
  onChange,
  onFocus,
  onClear,
  disabled,
  isError = false,
  errorText,
  label,
  resizeBlock,
  dataTestId,
  hiddenError,
  onBlur,
}) => {
  const inputRef = React.useRef<HTMLTextAreaElement | null>(null)

  const handleClick = () => {
    if (onFocus) {
      onFocus()
    }
    inputRef.current?.focus()
  }

  return (
    <Container>
      {label && <Label>{label}</Label>}
      <BoxWrapper onClick={handleClick} isError={isError} disabled={disabled}>
        <TextAreaInput
          onBlur={onBlur}
          disabled={disabled}
          readOnly={disabled}
          ref={inputRef}
          value={value}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
          resizeBlock={resizeBlock}
          data-testid={`${dataTestId}-textarea`}
        />

        {value.length > 0 && !disabled && onClear && (
          <IconButton
            onClick={onClear}
            size={24}
            iconSize={24}
            dataTestId={`${dataTestId}-clear-input-button`}
            icon="cross"
          />
        )}
      </BoxWrapper>
      {!hiddenError && <ErrorText data-testid={`${dataTestId}-error`}>{errorText}</ErrorText>}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Label = styled.label`
  font-weight: 400;
  line-height: 18px;
  font-size: 14px;
  color: ${themeVar('text')};
  margin-bottom: 4px;
`

type BoxWrapperProps = {
  isError?: boolean
  disabled?: boolean
}

const BoxWrapper = styled.div<BoxWrapperProps>`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: text;
  padding: 8px;
  gap: 8px;
  border: 1px solid ${themeVar('baseInputBorderColor')};

  ${({ isError }) => isError
    && css`
      border-color: ${themeVar('main')};
    `}

  ${({ disabled }) => Boolean(disabled)
    && css`
      border: 1px solid ${themeVar('line')};
      background-color: ${themeVar('mainBackground')};
      textarea {
        opacity: 0.6;
      }
    `}
`

type TextAreaInputProps = {
  resizeBlock?: boolean
}

const TextAreaInput = styled.textarea<TextAreaInputProps>`
  background: none;
  border: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  height: 100%;
  font-family: ${themeVar('baseFont')};

  &::placeholder {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${themeVar('gray4')};
    font-family: ${themeVar('baseFont')};
  }

  &:focus {
    outline: none;
  }

  ${({ resizeBlock }) => resizeBlock
    && css`
      resize: none;
    `}
`

const ErrorText = styled.div`
  color: ${themeVar('main')};
  margin-top: 4px;
  font-size: 12px;
  line-height: 16px;
  height: 16px;
`
