import React from 'react'
import styled from 'styled-components'

import { themeVar } from './theming'

type Props = {
  children: React.ReactElement | string
  onClick?: () => void
  dataTestId: string
}

export const LinkButton = ({ children, onClick, dataTestId }: Props) => (
  <Wrapper onClick={onClick} data-testid={dataTestId}>
    {children}
  </Wrapper>
)

const Wrapper = styled.span`
  color: ${themeVar('linkColor')};
  cursor: pointer;
  font-size: 16px;

  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`

export const Link = styled(Wrapper)
