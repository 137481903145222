import React from 'react'
import styled from 'styled-components'

import { formatDateString } from '@/lib/date'

import { Icon2 } from '../icons'
import { palette } from '../palette'
import { TextMLight } from '../typography'

type Props = {
  name: string
  date: string | null // YYYY-MM-DD
}

export const StatusTrackingItem = ({ name, date }: Props) => {
  return (
    <Wrapper>
      <IconWrapper>
        <Icon2 icon="statusCircle" size={15} color={date ? 'accent80' : 'grey50'} />
      </IconWrapper>
      <Date>{date && formatDateString(date)}</Date>
      <Name>{name}</Name>
    </Wrapper>
  )
}

const IconWrapper = styled.div`
  svg {
    position: relative;
    z-index: 1;
  }

  &::after {
    display: none;
    position: absolute;
    content: '';
    left: 7px;
    top: 13px;

    width: 1px;
    height: 100%;
    background-color: ${palette.grey50};
  }
`

const Wrapper = styled.div`
  ${TextMLight}
  position: relative;
  display: flex;
  gap: 8px;

  &:not(:last-child) ${IconWrapper}::after {
    display: block;
  }
`

const Date = styled.p`
  width: 70px;
  flex-shrink: 0;
`

const Name = styled.p`
  color: ${palette.grey70};
`
