import { ContactItemType } from '@/dal'

import { d } from './domain'
import { OpenCreateContactModalParams } from './types'

export const openCreateContact = d.event<OpenCreateContactModalParams>()
export const openEditeContact = d.event<ContactItemType>()

export const onContactCreated = d.event<ContactItemType>()
export const onContactEdited = d.event<ContactItemType>()
