import React from 'react'
import styled from 'styled-components'

import { NewButton } from '@/ui'

type Props = {
  isPending?: boolean
  isDisabled?: boolean
  onSubmit?: () => void
  onClose: () => void
}

export const DocumentCreationButtons = ({
  isPending, isDisabled, onSubmit, onClose,
}: Props) => {
  return (
    <Row>
      <NewButton
        dataTestId="submit-button"
        label="Создать"
        buttonType="primary"
        isFill
        type="submit"
        isPending={isPending}
        isDisabled={isDisabled}
        onClick={onSubmit}
      />
      <NewButton
        dataTestId="cancel-button"
        label="Отменить"
        buttonType="grey"
        isDisabled={isPending}
        isFill
        onClick={onClose}
      />
    </Row>
  )
}

const Row = styled.div`
  display: flex;
  gap: 16px;
`
