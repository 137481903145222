import React from 'react'
import styled, { css } from 'styled-components'

import { NewTextButton } from '../buttons'
import { TextSize } from '../types'
import { TEXT_SIZE_MAP } from '../const'

type Props = {
  text: string
  lineCount: number
  textSize?: TextSize
  expandLabel?: string
  canCollapse?: boolean
}

export const ExpandableText = ({
  lineCount,
  text,
  textSize = 'ML',
  expandLabel = 'Развернуть все',
  canCollapse = true,
}: Props) => {
  const [isOverflowed, setIsOverflowed] = React.useState(false)
  const [isExpanded, setIsExpanded] = React.useState(false)
  const commentRef = React.useRef<HTMLParagraphElement>(null)

  const onResize = () => {
    if (!commentRef.current) return

    const fullHeight = commentRef.current.scrollHeight
    const { lineHeight } = getComputedStyle(commentRef.current)
    const fullLineCount = Math.round(fullHeight / (parseInt(lineHeight, 10) || 18))

    setIsOverflowed(fullLineCount > lineCount)
  }

  React.useLayoutEffect(() => {
    onResize()
  }, [text])

  React.useEffect(() => {
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  })

  return (
    <Wrapper>
      <Text
        isExpanded={isExpanded}
        ref={commentRef}
        lineCount={lineCount}
        textSize={textSize}
      >
        {text}
      </Text>

      {text && isOverflowed && (canCollapse || !isExpanded) && (
        <NewTextButton
          label={isExpanded ? 'Свернуть' : expandLabel}
          textStyle={textSize}
          dataTestId={isExpanded ? 'collapse-comment' : 'expand-comment'}
          onClick={() => setIsExpanded(!isExpanded)}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

const Text = styled.p<{ isExpanded: boolean, lineCount: number, textSize: TextSize }>`
  ${({ textSize }) => TEXT_SIZE_MAP[textSize]}

  ${({ isExpanded, lineCount }) => !isExpanded && css`
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${lineCount};
  `}
`
