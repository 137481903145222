import React from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { MessageItem } from '@/ui'
import { useScrollListToBottom } from '@/lib/hooks/useScrollListToBottom'
import { getAuthorAndText } from '@/lib/text-splitter'

import { $messageList } from '../../model/private'

export const MessageList = React.memo(() => {
  const messages = useUnit($messageList)

  const refList = useScrollListToBottom([messages])

  if (!messages) return null

  return (
    <Scrollbars ref={refList}>
      <Wrapper>
        {messages.map(({
          answer,
          answer_required,
          date_answer,
          date_question,
          files,
          id,
          question,
          question_right,
        }, i) => {
          const prevMessageAnswerDate = messages[i - 1]?.date_answer
          const [questionAuthor, questionText] = getAuthorAndText(question || '')
          const [answerAuthor, answerText] = getAuthorAndText(answer || '')
          return (
            <React.Fragment key={id}>
              <MessageItem
                author={questionAuthor || ''}
                body={questionText || ''}
                date_time={date_question ?? ''}
                prevMessageDate={prevMessageAnswerDate}
                files={question_right ? files : undefined}
                isRight={question_right}
                avatarIcon={question_right ? 'builderCircle' : 'fsk'}
              />
              {!answer_required && answer && (
                <MessageItem
                  author={answerAuthor || ''}
                  body={answerText || ''}
                  date_time={date_answer ?? ''}
                  prevMessageDate={date_question}
                  files={question_right ? undefined : files}
                  isRight={!question_right}
                  avatarIcon={question_right ? 'fsk' : 'builderCircle'}
                />
              )}
            </React.Fragment>
          )
        },
        )}

      </Wrapper>
    </Scrollbars>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
  padding: 16px 24px 32px;
`
