import { attach, restore } from 'effector'
import { createForm } from 'effector-forms'

import { rules } from '@/lib/form-validators'
import { cancelEstimateApproveReqFx } from '@/dal'

import { d } from './domain'
import { openRejectAprovingModal } from './public'

export const $estimateDocInfo = restore(openRejectAprovingModal, null)

export const $isRejectModalOpen = $estimateDocInfo.map(Boolean)
export const closeModal = d.event()

export const onAdditionalRequest = d.event()

export const rejectForm = createForm({
  fields: {
    comment: {
      init: '',
      rules: [rules.required()],
    },
  },
})

export const rejectApprovingFx = attach({
  effect: cancelEstimateApproveReqFx,
})
