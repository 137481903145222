import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'
import { useField } from 'effector-forms'

import {
  ExpandableText,
  NewTextButton,
  StatusTracking,
  TextAreaField,
  TextM,
  TextMLight,
} from '@/ui'
import { formatDateString } from '@/lib/date'

import { $documentInfo, $editableFields } from '../../../model'
import { actForm, openDateModal } from '../../../model/info.private'
import { $isActionPending } from '../../../model/header.private'

export const ActDataForm = React.memo(() => {
  const [info, editableFields, isActionPending] = useUnit([
    $documentInfo,
    $editableFields,
    $isActionPending,
  ])

  const { value: comment, onChange } = useField(actForm.fields.comment)

  if (!info) return null

  const { number, report_period } = info

  return (
    <>
      {(number || report_period) && (
        <UpperBlock>
          <CellsWrapper>
            {number && (
              <>
                <Cell isBold>№ Акта</Cell>
                <Cell>
                  {number}
                </Cell>
              </>
            )}

            {report_period && (
              <>
                <Cell isBold>Отчетный период</Cell>
                <Cell>
                  <p>
                    {formatDateString(report_period.date_start)}
                    {' – '}
                    {formatDateString(report_period.date_end)}
                  </p>
                  {editableFields?.reporting_period__date_end && (
                  <NewTextButton
                    label="Изменить"
                    textStyle="M"
                    dataTestId="act-open-reporting-dates-modal-btn"
                    isDisabled={isActionPending}
                    onClick={openDateModal}
                  />
                  )}
                </Cell>
              </>
            )}
          </CellsWrapper>
        </UpperBlock>
      )}

      <StatusTracking items={info.document_tracking} />

      {editableFields?.comment ? (
        <TextAreaField
          value={comment}
          label="Комментарий"
          placeholder="Введите ваш комментарий"
          height={100}
          currentSymbolCount={comment.length}
          maxSymbolCount={800}
          dataTestId="act-comment-textarea"
          disabled={isActionPending}
          autoGrow
          onChange={onChange}
        />
      ) : (
        info.comment && (
          <div>
            <CommentTitle>Комментарий</CommentTitle>
            <ExpandableText text={info.comment} lineCount={3} />
          </div>
        )
      )}
    </>
  )
})

const UpperBlock = styled.div`
`

const CellsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  gap: 6px 12px;
`

const Cell = styled.div<{ isBold?: boolean }>`
  display: flex;
  gap: 8px;

  ${({ isBold }) => (isBold ? TextM : TextMLight)}
`

const CommentTitle = styled.p`
  ${TextM}
  margin-bottom: 4px;
`
