import React, { memo } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { NewButton, palette, TextM } from '@/ui'

import {
  WorkTable, DistributeAmountsModal, EditCommentModal, MultipleEditPendingOverlay,
} from '../containers'
import {
  $editingRowId,
  $editingRows,
  $isEditMode,
  clearFilter,
  exitEditMode,
  saveChanges,
  setIsEditMode,
} from '../../model/private'

export const LotWorkTable = memo(() => {
  const [isEditMode, editingRowId, editingRows] = useUnit([
    $isEditMode,
    $editingRowId,
    $editingRows,
  ])

  const isEditing = Boolean(isEditMode || editingRowId)
  const canSave = Boolean(editingRows.length)

  return (
    <>
      <Wrapper>
        <Header>
          <NewButton
            label="Очистить фильтры"
            size="S"
            buttonType="line-primary"
            isDisabled={isEditing}
            dataTestId=""
            onClick={clearFilter}
          />
          {isEditing ? (
            <Buttons>
              <NewButton
                label="Отменить"
                size="S"
                buttonType="grey"
                dataTestId=""
                onClick={exitEditMode}
              />
              <NewButton
                label="Сохранить изменения"
                size="S"
                isDisabled={!canSave}
                dataTestId=""
                onClick={saveChanges}
              />
            </Buttons>
          ) : (
            <EditModeButtonWrapper>
              <StepTwo>Шаг 2.</StepTwo>
              <NewButton
                label="Внести цены и количество"
                size="S"
                prefixIcon="editStroke"
                dataTestId=""
                onClick={() => setIsEditMode(true)}
              />
            </EditModeButtonWrapper>
          )}
        </Header>

        <WorkTable />
        <MultipleEditPendingOverlay />
      </Wrapper>

      <DistributeAmountsModal />
      <EditCommentModal />
    </>
  )
})

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-radius: 12px;
  background-color: ${palette.white};
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 4px;
  padding: 24px;
`

const Buttons = styled.div`
  display: flex;
  gap: 16px;
`

const EditModeButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const StepTwo = styled.p`
  ${TextM}
  text-transform: uppercase;
`
