import { css } from 'styled-components'

import { themeVar } from '../theming'

type InputProps = {
  fontSize?: number
  formatFor?: string
  isPointer?: boolean
  height?: string
  textAlign?: string
}

export const BaseInputStyle = css<InputProps>`
  background: none;
  height: ${({ height }) => height || '24px'};
  text-align: ${({ textAlign }) => textAlign || 'left'};
  border: none;
  font-family: ${themeVar('baseFont')};
  font-weight: 300;
  font-size: ${({ fontSize }) => (fontSize || 14)}px;
  width: 100%;

  ${({ isPointer }) => isPointer
    && css`
      cursor: pointer;
    `}

  ${({ formatFor }) => formatFor === 'month-word'
    && css`
      text-transform: capitalize;
    `}

  &::placeholder {
    font-size: ${({ fontSize }) => (fontSize || 14)}px;
    color: ${themeVar('baseInputPlaceholderColor')};
    text-transform: none;
  }

  &:focus {
    outline: none;
  }
`
