import React from 'react'

import { WideArticleCardSkeleton } from './WideArticleCardSkeleton'

export const AllArticlesSkeleton = ({ count }: {count: number}) => (
  <>
    {Array.from(Array(count)).map((_, i) => (
      <WideArticleCardSkeleton key={i} />
    ))}
  </>
)
