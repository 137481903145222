import { FileInfo } from '../interfaces'

export enum ArticleType {
  Regular = 'Regular',
  Important = 'Important'
}

export type ArticleItem = {
  id: number
  title: string
  description: string
  content: string
  created_at: string // isoDate
  modified_at: string // isoDate
  reactions: {
    my: string // emoji
    summary: {
      [key in string]: number // key emodji
    }
  },
  comments_count: number
  is_read: boolean,
  files: FileInfo[] | null,
  total_views: number
  publication_type: ArticleType,
}

export type GetNewArticlesResponse = {
  last_ten_publications: number[]
  all_unattended_publications: number[]
}

export type GetArticlesParams = {
  search?: string
  publication_type?: ArticleType
  ordering?: 'created_at' | '-created_at'
  unread_only?: boolean
}
