import React from 'react'

import { NumberInput } from '@/ui'

import { FilterCell } from './FilterCell'

type Props = {
  label: string
  isDisabled: boolean
  fromValue: string
  toValue: string
  decimalScale?: number
  testIdPrefix: string
  onChangeFrom: (val: string) => void
  onChangeTo: (val: string) => void
}

export const RangeFilterCell = ({
  label,
  isDisabled,
  fromValue,
  toValue,
  decimalScale = 2,
  testIdPrefix,
  onChangeFrom,
  onChangeTo,
}: Props) => {
  const isInvalid = Boolean(fromValue && toValue && parseFloat(fromValue) > parseFloat(toValue))

  return (
    <FilterCell label={label} textAlign="right">
      <NumberInput
        placeholder="от"
        value={fromValue}
        disabled={isDisabled}
        thousandSeparator=" "
        decimalScale={decimalScale}
        isInvalid={isInvalid}
        placeholderAlign="right"
        dataTestId={`${testIdPrefix}-from-filter-input`}
        isFill
        onChange={onChangeFrom}
        onClear={() => onChangeFrom('')}
      />
      <NumberInput
        placeholder="до"
        value={toValue}
        disabled={isDisabled}
        thousandSeparator=" "
        decimalScale={decimalScale}
        isInvalid={isInvalid}
        placeholderAlign="right"
        dataTestId={`${testIdPrefix}-to-filter-input`}
        isFill
        onChange={onChangeTo}
        onClear={() => onChangeTo('')}
      />
    </FilterCell>
  )
}
