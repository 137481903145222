import { mainTheme, ThemeItem } from './main'


// Fixme: избавиться от деструктуризации
export const darkTheme = {
  ...mainTheme,

}

export const createDarkTheme = (): ThemeItem => ({
  name: 'dark',
  variables: darkTheme,
})
