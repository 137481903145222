import React from 'react'
import { useUnit } from 'effector-react'
import styled, { css } from 'styled-components'

import {
  NewDropDownInput,
  NewIconButton,
  NewInput,
} from '@/ui'

import {
  $filters,
  removeFilter,
  setFilter,
} from '../../model/private'
import { filterOptions } from '../../model/const'

export const AllArticlesFilter = React.memo(() => {
  const filters = useUnit($filters)

  const ref = React.useRef<HTMLDivElement>(null)

  const [searchValue, setSearchValue] = React.useState('')
  const [isSearchFocused, setIsSearchFocused] = React.useState(false)

  const onSearch = () => {
    setFilter({ key: 'search', val: searchValue })
    setIsSearchFocused(false)
  }

  const onSearchClear = () => {
    setSearchValue('')
    setIsSearchFocused(false)
    removeFilter({ key: 'search' })
  }

  React.useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        if (isSearchFocused) {
          setIsSearchFocused(false)
        }
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isSearchFocused, setIsSearchFocused])

  return (
    <Wrapper>
      {!isSearchFocused && (
        <NewDropDownInput
          selectedId={filters.filter ?? ''}
          options={filterOptions}
          hasClear={false}
          dataTestId="articles-filter-dropdown"
          onOptionClick={(val) => setFilter({ key: 'filter', val })}
        />
      )}
      <SearchWrapper
        className="search-wrapper"
        ref={ref}
        isFull={isSearchFocused}
      >
        {isSearchFocused ? (
          <NewInput
            value={searchValue}
            postfixIcon="search"
            dataTestId="articles-search-input"
            onChange={(val) => setSearchValue(val)}
            onClear={onSearchClear}
            isSelect={isSearchFocused}
            onEnterPress={onSearch}
            onPostfixIconClick={onSearch}
            isSelectAfterClear
          />
        ) : (
          <NewIconButton
            icon="search"
            color="grey60"
            size={16}
            dataTestId="articles-search-toggle-btn"
            padding="7px"
            borderColor="grey40"
            hoverBorderColor="grey60"
            borderRadius="4px"
            onClick={() => setIsSearchFocused(true)}
          />
        )}
      </SearchWrapper>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  flex-grow: 1;
`

const SearchWrapper = styled.div<{isFull: boolean}>`
  ${({ isFull }) => isFull && css`
    flex-grow: 1;
  `}
`
