import { sample } from 'effector'

import {
  DocumentAlias,
  DocumentStatuses,
  EstimateDocId,
  VisasDocType,
} from '@/dal'
import { createToast } from '@/features/toast-service/model'
import { downloadBlobFileFx } from '@/features/download-files'
import { openAdditionalRequestModal } from '@/features/additional-request/model'

import {
  attachmentsModel,
  getApproveDocumentFx,
  onOpenAdditionalModal,
  onOpenModal,
  sendFilesFx,
} from './private'
import { onApprovingDocumentsDownloaded, downloadApprovingDocuments } from './public'

import {
  $documentInfo,
  EstimateCorrectionGate,
  refetchEstimateDocument,
  updateAttachments,
} from '../../shared-model'

sample({
  clock: onOpenModal,
  source: $documentInfo,
  filter: Boolean,
  fn: (estimate) => ({
    id: estimate.id,
    files: estimate.attachments,
  }),
  target: attachmentsModel.open,
})

sample({
  clock: downloadApprovingDocuments,
  source: $documentInfo,
  filter: Boolean,
  fn: (info) => info.id,
  target: getApproveDocumentFx,
})

sample({
  clock: getApproveDocumentFx.doneData,
  fn: (blob) => ({
    blob,
    fileName: 'approve_documents.zip',
  }),
  target: downloadBlobFileFx,
})

sample({
  clock: getApproveDocumentFx.done,
  target: [onApprovingDocumentsDownloaded, refetchEstimateDocument],
})

sample({
  clock: onOpenAdditionalModal,
  source: {
    isOpen: EstimateCorrectionGate.status,
    documentInfo: $documentInfo,
  },
  filter: ({ isOpen, documentInfo }) => Boolean(isOpen && documentInfo),
  fn: ({ documentInfo }) => ({
    docType: DocumentAlias.KOR_DOC as VisasDocType,
    docId: documentInfo?.id as EstimateDocId,
    status: documentInfo?.status as DocumentStatuses,
    isSpecialVisa: Boolean(documentInfo?.features.is_special_visa),
  }),
  target: openAdditionalRequestModal,
})

createToast({
  effect: getApproveDocumentFx,
})

sample({
  clock: sendFilesFx.doneData,
  target: updateAttachments,
})
