import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { HeadL, NewTextButton } from '@/ui'
import { ServiceItem, ServicesModal } from '../containers'
import { ServicesContainer } from '../parts'
import { $importantServices, openModal } from '../../model/private'

export const Services = React.memo(() => {
  const importantServices = useUnit($importantServices)

  return (
    <Wrapper>
      <Header>
        <Title>Сервисы для работы</Title>
        <NewTextButton
          label="Все сервисы"
          postfixIcon="arrowRightS"
          onClick={openModal}
          dataTestId="open-services-btn"
        />
      </Header>

      <ServicesContainer>
        {importantServices.map(({ alias, name, icon }) => (
          <ServiceItem key={alias} alias={alias} name={name} icon={icon} />
        ))}
      </ServicesContainer>

      <ServicesModal />
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: grid;
  gap: 24px;
`

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.h3`
  ${HeadL}
`
