import { sample } from 'effector'

import { openAdditionalRequestModal } from '@/features/additional-request/model'
import { DocumentAlias, AdditionalRequestModalInfo } from '@/dal'

import {
  $estimateDocInfo,
  closeModal,
  onAdditionalRequest,
  rejectApprovingFx,
  rejectForm,
} from './private'
import { updateDocumentInfo } from '../../shared-model'

$estimateDocInfo
  .reset(closeModal)

sample({
  clock: onAdditionalRequest,
  source: $estimateDocInfo,
  filter: Boolean,
  fn: ({ id, status }): AdditionalRequestModalInfo => ({
    docType: DocumentAlias.KOR_DOC,
    docId: id,
    status,
  }),
  target: [openAdditionalRequestModal, closeModal],
})

sample({
  clock: rejectForm.formValidated,
  source: $estimateDocInfo,
  filter: Boolean,
  fn: ({ id }, { comment }) => ({
    id,
    reason: comment,
  }),
  target: rejectApprovingFx,
})

sample({
  clock: rejectApprovingFx.doneData,
  target: [closeModal, updateDocumentInfo],
})
