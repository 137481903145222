import { EstimateNumberCell, EstimateTableRow } from '@/dal'

export const getFormValuesFromRow = (row: EstimateTableRow) => {
  const newLine = row.new_lines?.[0]

  const getValue = (col?: EstimateNumberCell | null) => {
    return (col?.value_adjusted || col?.value_current) ?? ''
  }

  return {
    project: row.project?.id ?? null,
    count_in_estimate: getValue(row.count_in_estimate),
    material: getValue(row.price_material),
    work: getValue(row.price_work),
    justification_of_changes_current: row.justification_of_changes?.value ?? '',
    provider: row.additional_requisites?.provider.value ?? '',
    specifications: row.additional_requisites?.specifications.value ?? '',
    stamp: row.additional_requisites?.stamp.value ?? '',
    name: row.name.value,
    unit: row.unit?.id ?? null,

    new_count_in_estimate: getValue(newLine?.count_in_estimate),
    new_material: getValue(newLine?.price_material),
    new_work: getValue(newLine?.price_work),
    new_justification_of_changes_current: newLine?.justification_of_changes?.value ?? '',
    new_provider: newLine?.additional_requisites?.provider.value ?? '',
    new_specifications: newLine?.additional_requisites?.specifications.value ?? '',
    new_stamp: newLine?.additional_requisites?.stamp.value ?? '',
  }
}
