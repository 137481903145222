import React from 'react'
import styled from 'styled-components'

import {
  DefaultButton,
  Icon2,
  TextM,
  palette,
} from '@/ui'

type Props = {
  onClick: () => void
}

export const ShareButton = ({ onClick }: Props) => (
  <Button
    onClick={onClick}
    data-testid="share-article-button"
  >
    <Icon2 icon="shareArrow" size={20} />
    Поделиться
  </Button>
)

const Button = styled(DefaultButton)`
  ${TextM}
  padding: 8px 0;
  display: flex;
  gap: 8px;
  transition: color 0.15s linear;
  color: ${palette.accent100};
  align-items: center;

  &:hover {
    color: ${palette.accent90};
  }

  &:active {
    color: ${palette.accent80};
  }
`
