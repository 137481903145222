import React from 'react'
import { useUnit } from 'effector-react'

import { InfoStep } from '../parts'
import { $surveyInfo, closeModal } from '../../model/private'

export const EndStep = React.memo(() => {
  const surveyInfo = useUnit($surveyInfo)

  if (!surveyInfo) return null

  const { last_slide_picture } = surveyInfo

  return (
    <InfoStep
      title="Спасибо за уделенное время!"
      imgUrl={last_slide_picture}
      buttonLabel="Закрыть"
      buttonType="grey"
      onClickButton={closeModal}
    />
  )
})
