import { useGate, useUnit } from 'effector-react'
import React from 'react'
import styled from 'styled-components'

import { AuthLayout, NotificationItem, NotificationType } from '@/ui'

import { $currentStep, $innError, RequestForAccessGate } from '../../model/private'
import { StepInfo } from '../parts'
import { FirstStep, SecondStep } from '../containers'
import { Step } from '../../model/types'

const $hasINNError = $innError.map(Boolean)

export const RequestForAccessComponent = React.memo(() => {
  const currStep = useUnit($currentStep)
  const hasError = useUnit($hasINNError)
  const isForm = Boolean(currStep === Step.Second && !hasError)

  useGate(RequestForAccessGate)

  return (
    <AuthLayout
      title="Запрос доступа в личный кабинет"
      hasBackButton
      isSuccess={currStep === Step.Success}
      successTitle="Запрос отправлен"
      successText="С вами свяжется координатор ГК ФСК, когда предоставленные данные будут проверены."
      contentMargin={isForm ? 8 : undefined}
    >
      <ContentWrapper>

        {isForm && (
          <NotificationItem
            type={NotificationType.Warning}
            body={<>Все поля обязательны для заполнения!</>}
          />
        )}

        <StepInfo currStep={currStep} />

        {Step.First === currStep && <FirstStep />}
        {Step.Second === currStep && <SecondStep />}

      </ContentWrapper>

    </AuthLayout>
  )
})

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 24px;
`
