import { sample } from 'effector'

import { SendSummaryInfoParams } from '@/dal'
import { createToast } from '@/features/toast-service/model'

import { onFieldSubmit, sendSummaryInfoFx } from './private'
import {
  $documentId,
  getUpdatedFeatures,
  onUpdateSummary,
} from '../../shared-model'

sample({
  clock: onFieldSubmit,
  source: $documentId,
  filter: Boolean,
  fn: (id, field) => ({
    id, ...field,
  }),
  target: sendSummaryInfoFx,
})

sample({
  clock: sendSummaryInfoFx.done,
  fn: ({ params, result }) => ({ docId: params.id, summary: result }),
  target: [
    onUpdateSummary,
    getUpdatedFeatures.prepend(({ docId }: { docId: SendSummaryInfoParams['id'] }) => docId),
  ],
})

createToast({
  effect: sendSummaryInfoFx,
})
