import { ArticleType, GetArticlesParams } from '@/dal'
import { ArticlesFilter } from './type'

export const mapFilters = (filters: ArticlesFilter) => {
  const query: GetArticlesParams = { search: filters.search }

  switch (filters.filter) {
    case 'newestFirst':
      query.ordering = '-created_at'
      break
    case 'oldestFirst':
      query.ordering = 'created_at'
      break
    case 'importantOnly':
      query.publication_type = ArticleType.Important
      break
    case 'unreadOnly':
    default:
      query.unread_only = true
      break
  }

  return query
}
