import { useUnit } from 'effector-react'
import React from 'react'
import styled from 'styled-components'

import {
  HeadXL,
  ModalLayoutV2,
  NewButton,
  NotificationItem,
  NotificationType,
  TextAreaField,
} from '@/ui'
import {
  $isOpen,
  $reasonCancel,
  cancelPaymentApproveFx,
  change,
  close,
  onCancelApprove,
} from '../../../model/cancel-approve.private'

export const CancelPaymentApproveModal = React.memo(() => {
  const isOpen = useUnit($isOpen)
  const value = useUnit($reasonCancel)
  const isPending = useUnit(cancelPaymentApproveFx.pending)

  if (!isOpen) return null

  return (
    <ModalLayoutV2
      closeModal={close}
      dataTestId="cancel-payment-approve"
    >
      <Wrapper>
        <Title>Отмена согласования</Title>
        <NotificationItem
          type={NotificationType.Info}
          body="После отмены согласования документ вернется в статус – черновик"
        />
        <TextAreaField
          dataTestId="cancel-reason-field"
          label="Комментарий"
          required
          onChange={change}
          placeholder="Укажите краткую причину отмены согласования"
          value={value}
          autoGrow
          height={100}
          maxHeight={250}
          disabled={isPending}
          currentSymbolCount={value.length}
          maxSymbolCount={1000}
        />
        <ButtonsRow>
          <NewButton
            dataTestId="cancel-approve-button"
            label="Отменить согласование"
            isPending={isPending}
            isDisabled={!value}
            onClick={onCancelApprove}
          />
          <NewButton
            dataTestId="close-modal"
            onClick={close}
            label="Назад"
            buttonType="grey"
          />
        </ButtonsRow>
      </Wrapper>
    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  width: 500px;
  padding: 32px 32px 20px;
`

const Title = styled.h5`
  ${HeadXL}
`

const ButtonsRow = styled.div`
  display: flex;
  gap: 16px;
`
