import React from 'react'
import styled, { css } from 'styled-components'

import { TextM, TextMLight, palette } from '@/ui'

type Props = {
  label: string
  text?: string | null
  isHighlight?: boolean
}

export const InfoItem = ({
  label,
  text,
  isHighlight,
}: Props) => {
  return (
    <Item>
      <Label>{label}</Label>
      <Text>
        <Span isHighlight={isHighlight}>
          {text || '—'}
        </Span>
      </Text>
    </Item>
  )
}

const Item = styled.div`
  display: flex;
  flex: 1 1;
  gap: 12px;
`

const Label = styled.p`
  flex: 0 0 138px;
  ${TextM}
`
const Text = styled.p`
  flex-grow: 1;
  ${TextMLight}
`

const Span = styled.span<{ isHighlight?: boolean }>`
  ${({ isHighlight }) => isHighlight && css`
    color: ${palette.accent100};
    background-color: ${palette.accent10};
  `}
`
