import { Domain, Unit } from 'effector'

import { root } from '@/root-domain'

type Params = {
  domain?: Domain
  closeEvents?: Unit<unknown>[]
}

export const createModal = <T = void>({ domain, closeEvents = [] }: Params = {}) => {
  const d = domain ?? root.domain()

  const $isOpen = d.store(false)
  const $meta = d.store<T | null>(null)
  const open = d.event<T>()
  const close = d.event()

  const $isMinimized = d.store(false)
  const minimize = d.event()
  const unfold = d.event()

  $isOpen
    .on([open, unfold], () => true)
    .reset([close, minimize, ...closeEvents])

  $meta
    .on(open, (_, payload) => payload)
    .reset([close, ...closeEvents])

  $isMinimized
    .on(minimize, () => true)
    .on(unfold, () => false)
    .reset([open, close, ...closeEvents])

  return {
    $isOpen,
    $meta,
    $isMinimized,
    open,
    close,
    minimize,
    unfold,
  }
}
