import React from 'react'
import { useUnit } from 'effector-react'
import styled from 'styled-components'

import {
  ModalLayout,
  themeVar,
} from '@/ui'
import {
  $modalPlanEditPeriodVisible,
  closePlanEditPeriodModal,
} from '../../model/private'
import { PlanEditPeriodForm } from '../containers'

export const PlanEditPeriodModal = () => {
  const modalVisible = useUnit($modalPlanEditPeriodVisible)

  if (!modalVisible) {
    return null
  }

  return (
    <ModalLayout
      closeModal={closePlanEditPeriodModal}
      dataTestId="fs-edit-plan-period-modal"
    >
      <ModalOverlay>
        <PlanEditPeriodForm />
      </ModalOverlay>
    </ModalLayout>
  )
}

const ModalOverlay = styled.div`
  background-color: ${themeVar('lightBackground')};
  padding: 24px;
  width: 650px;
`
