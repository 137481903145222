import {
  $searchInputValue,
  changeSearch,
  clearSearch,
  TableFiltersGate,
  $acceptedTypes,
  setAcceptedTypes,
} from './filters.private'
import { resetFilters, setRowType } from './filters.public'

$searchInputValue
  .on(changeSearch, (_, val) => val)
  .reset(resetFilters, clearSearch, TableFiltersGate.close)

$acceptedTypes
  .on([setAcceptedTypes, setRowType], (_, types) => types)
  .reset(resetFilters, TableFiltersGate.close)
