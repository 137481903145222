
import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'
import FocusTrap from 'focus-trap-react'
import { DayContent, DayContentProps, DayPicker } from 'react-day-picker'
import ru from 'date-fns/locale/ru'
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react'
import { createPortal } from 'react-dom'

import { formatDateForBackend, getDayJsObject } from '@/lib/date'
import { noop } from '@/lib/noop'
import { palette } from '../../palette'
import { TextMLight } from '../../typography'
import { Icon2 } from '../../icons'
import { DialogSheetWrapper } from '../../components/DialogSheetWrapper'

function DateTime(props: DayContentProps) {
  const date = getDayJsObject(props.date).format('DD-MM-YYYY')
  return (
    <div data-testid={date}>
      <DayContent {...props} />
    </div>
  )
}

type Props = {
  placeholder?: string
  onChange: (e: string) => void
  onCloseDropDown?: () => void
  inputValue: string // YYYY-mm-dd
  initialOpenedDateValue?: string // YYYY-mm-dd
  dataTestId: string
  disabled?: boolean
  isInvalid?: boolean
  activeDateRange?: {
    from?: Date
    to?: Date
  }
  textAlign?: 'right' | 'center' | 'left'
}

export const CellDateInput = ({
  placeholder,
  onChange,
  onCloseDropDown,
  inputValue,
  initialOpenedDateValue,
  dataTestId,
  disabled,
  isInvalid,
  activeDateRange,
  textAlign,
}: Props) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const inputRef = React.useRef<HTMLInputElement>(null)

  const closeDropDown = () => {
    setIsOpen(false)
    onCloseDropDown?.()
  }

  const openDropDown = () => {
    setIsOpen(true)
  }

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: (isOpen) => (
      isOpen ? openDropDown() : closeDropDown()
    ),
    middleware: [offset(10), flip(), shift()],
    whileElementsMounted: autoUpdate,
    placement: 'bottom-start',
    strategy: 'fixed',
  })

  const click = useClick(context)
  const dismiss = useDismiss(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ])

  const formattedValue = React.useMemo(() => {
    if (!inputValue) return ''
    const dayObj = getDayJsObject(inputValue)
    return dayObj.format(isOpen ? 'DD/MM/YYYY' : 'DD.MM.YYYY')
  }, [inputValue, isOpen])

  const isDateValid = React.useMemo(() => getDayJsObject(inputValue).isValid(), [inputValue])

  const date = React.useMemo(() => {
    if (!inputValue || !isDateValid) return undefined
    return new Date(inputValue)
  }, [inputValue, isDateValid])

  const initialOpenedDate = initialOpenedDateValue && new Date(initialOpenedDateValue)

  const handleDaySelect = useCallback(
    (_, newValue: Date) => {
      const date = formatDateForBackend(newValue)
      onChange(date)
      closeDropDown()
    },
    [onChange],
  )

  return (
    <Wrapper>
      <PopperTarget
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        <InputWrapper>
          {!isOpen
            ? (
              <Value textAlign={textAlign}>
                {formattedValue}
              </Value>
            )
            : (
              <Input
                data-testid={`${dataTestId}-input`}
                value={formattedValue}
                onChange={noop}
                placeholder={placeholder || 'дд/мм/гггг'}
                disabled={disabled}
                isInvalid={Boolean(isInvalid)}
                ref={inputRef}
                readOnly
                textAlign={textAlign}
              />
            )}
          <IconWrapper>
            <Icon2
              icon="edit"
              color={isOpen ? 'grey40' : 'inherit'}
              size={16}
            />
          </IconWrapper>
        </InputWrapper>


      </PopperTarget>
      {isOpen && !disabled && (
        createPortal(
          <FocusTrap
            active
            focusTrapOptions={{
              initialFocus: false,
              allowOutsideClick: true,
              clickOutsideDeactivates: true,
            }}
          >
            <DialogSheetWrapper
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              <DayPicker
                components={{
                  DayContent: DateTime,
                }}
                captionLayout="dropdown-buttons"
                disabled={[
                  (day) => {
                    if (!activeDateRange) return false
                    let result = false
                    if (activeDateRange.from) {
                      result = day < activeDateRange.from
                    }
                    if (!result && activeDateRange.to) {
                      result = day > activeDateRange.to
                    }
                    return result
                  },
                ]}
                initialFocus
                mode="single"
                defaultMonth={date || initialOpenedDate || activeDateRange?.from}
                selected={date}
                locale={ru}
                onSelect={handleDaySelect}
                data-testid={`${dataTestId}-select-input-date`}
              />
            </DialogSheetWrapper>
          </FocusTrap>,
          document.body,
        )
      )}
    </Wrapper>
  )
}

const PopperTarget = styled.div`
  display: flex;
  flex-direction: column;
`

const IconWrapper = styled.div`
  color: ${palette.accent80};
  transition: color 0.15s linear;
`

const Wrapper = styled.div`
  width: 100%;

  @media (hover: hover) {
    &:hover ${IconWrapper} {
      color: ${palette.accent100};
    }
  }
`

type InputProps = {
  textAlign?: 'right' | 'center' | 'left'
  isInvalid: boolean
}

const Input = styled.input<InputProps>`
  background-color: transparent;
  height: fit-content;
  border: none;
  outline: none;
  transition: color 0.15s linear;
  ${TextMLight}
  width: 100%;
  ${({ textAlign }) => textAlign && css`
    text-align: ${textAlign};
  `}

  ${({ isInvalid }) => isInvalid && css`
    color: ${palette.red100};
    &::placeholder {
      color: ${palette.red100};
    }
  `}
`

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  cursor: pointer;
  transition: border-color 0.15s linear;
`

export const Value = styled.span<{textAlign?: 'right' | 'center' | 'left'}>`
  ${TextMLight};
  ${({ textAlign }) => textAlign && css`
    text-align: ${textAlign};
  `}
  width: 100%;
`
