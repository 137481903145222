import { sample } from 'effector'

import { DocumentAlias, EstimateLotListItem } from '@/dal'
import { NotificationType } from '@/ui'
import { openAdditionalRequestModal } from '@/features/additional-request/model'
import { downloadBlobFileFx } from '@/features/download-files'
import { copyDocument, deleteDocument, onDocumentDeleted } from '@/features/document-manager/model'
import { showToast } from '@/features/toast-service/model'
import { pushNavigate, RouterPaths } from '@/features/app/model'

import {
  EstimateLotListGate,
} from './public'
import {
  $downloadInfo,
  $isLotCreationModalOpen,
  $selectedContragentId,
  closeLotCreationModal,
  createEstimateLotFx,
  downloadEstimateLotDocs,
  downloadEstimateLotDocsFx,
  onAdditionalButtonClicked,
  onCopyButtonClicked,
  onDeleteButtonClicked,
  openLotCreationModal,
  selectContragent,
} from './actions.private'
import { $estimateLotList, onEstimateLotDeleted } from './private'

$isLotCreationModalOpen
  .on(openLotCreationModal, () => true)
  .reset(closeLotCreationModal)

$selectedContragentId
  .on(selectContragent, (_, id) => id)
  .reset(closeLotCreationModal)

$downloadInfo
  .on(downloadEstimateLotDocs,
    (ids, { id }) => ([...ids, id]),
  )
  .on(downloadEstimateLotDocsFx.finally,
    (ids, final) => ids.filter((id) => (id !== final.params.id)),
  )

sample({
  clock: EstimateLotListGate.close,
  target: closeLotCreationModal,
})

sample({
  clock: createEstimateLotFx.doneData,
  fn: ({ id }) => `${RouterPaths.EstimateLots}/${id}`,
  target: [pushNavigate, closeLotCreationModal],
})

sample({
  clock: onDeleteButtonClicked,
  source: $estimateLotList,
  filter: (list, id) => Boolean(list?.some((item) => item.id === id)),
  fn: (list, id) => {
    const doc = list?.find((item) => item.id === id) as EstimateLotListItem
    return {
      id: doc.id,
      type: DocumentAlias.ESTIMATE_LOT as const,
      documentName: doc.name,
    }
  },
  target: deleteDocument,
})

sample({
  clock: onCopyButtonClicked,
  source: $estimateLotList,
  filter: (list, id) => Boolean(list?.some((item) => item.id === id)),
  fn: (list, id) => {
    const doc = list?.find((item) => item.id === id) as EstimateLotListItem
    return {
      id: doc.id,
      type: DocumentAlias.ESTIMATE_LOT as const,
      documentName: doc.name,
    }
  },
  target: copyDocument,
})

// TODO проверить с бэком
sample({
  clock: downloadEstimateLotDocs,
  fn: (payload) => ({ ...payload, url: payload.url.split('api')[1] }),
  target: downloadEstimateLotDocsFx,
})

sample({
  clock: onAdditionalButtonClicked,
  target: openAdditionalRequestModal,
})

sample({
  clock: downloadEstimateLotDocsFx.fail,
  fn: () => ({
    content: 'Ошибка скачивания',
    icon: NotificationType.Alert,
  }),
  target: showToast,
})

sample({
  clock: downloadEstimateLotDocsFx.doneData,
  fn: (blob) => ({
    fileName: 'attachment.zip',
    blob,
  }),
  target: downloadBlobFileFx,
})

sample({
  clock: onDocumentDeleted,
  filter: EstimateLotListGate.status,
  fn: ({ id }) => id,
  target: onEstimateLotDeleted,
})
