import React from 'react'
import styled from 'styled-components'
import Scrollbars from 'react-custom-scrollbars-2'
import { createPortal } from 'react-dom'

import { usePopup } from '../hooks'
import { Icon2, IconName2 } from '../icons'
import { palette, PaletteColor } from '../palette'
import { TextMLight } from '../typography'
import { DialogSheetWrapper } from './DialogSheetWrapper'

type Props = {
  options: {
    icon?: IconName2
    iconColor?: PaletteColor
    label: string
    onClick: () => void
  }[]
  children: React.ReactFragment | React.ReactFragment[]
  sameComponentWidth?: boolean
  onClose?: () => void
  onOpen?: () => void
  onToggle?: (val: boolean) => void
}

export const DropDownList = ({
  children,
  options,
  sameComponentWidth,
  onClose,
  onOpen,
  onToggle,
}: Props) => {
  const {
    floatingStyles,
    getFloatingProps,
    getReferenceProps,
    close: closeDropDown,
    isOpen,
    refs,
  } = usePopup({
    onClose,
    onOpen,
    onToggle,
    sameComponentWidth,
    strategy: 'fixed',
  })

  return (
    <>
      <ButtonWrapper
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {children}
      </ButtonWrapper>
      {isOpen && (
        createPortal(
          <DropDown
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <Scrollbars
              autoHeight
              autoHeightMin="0"
              autoHeightMax="290px"
            >
              {options.map(({
                label, icon, iconColor, onClick,
              }) => (
                <ListItem
                  key={label + icon}
                  onClick={() => {
                    closeDropDown()
                    onToggle?.(false)
                    onClick()
                  }}
                >
                  {icon && (
                    <Icon2
                      size={16}
                      icon={icon}
                      color={iconColor}
                    />
                  )}
                  {label}
                </ListItem>
              ))}
            </Scrollbars>

          </DropDown>,
          document.body,
        )
      )}
    </>
  )
}

const DropDown = styled(DialogSheetWrapper)`
  padding: 8px 0px;
  min-width: 200px;
  margin: 0;
`

const ButtonWrapper = styled.div``

const ListItem = styled.li`
  display: flex;
  gap: 8px;
  padding: 8px 12px;
  transition: 0.15s linear;
  transition-property: background-color;
  cursor: pointer;
  ${TextMLight}

  @media (hover: hover) {
    &:hover {
      background-color: ${palette.grey10};
    }
  }
  &:active {
    background-color: ${palette.grey20};
  }
`
