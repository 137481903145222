import { attach } from 'effector'
import { createForm } from 'effector-forms'

import { createContragentReqFx } from '@/dal'
import { rules } from '@/lib/form-validators'

import { d } from './domain'

export const $isOpenCreateBankModal = d.store(false)
export const openCreateBank = d.event<void>()
export const closeCreateBank = d.event<void>()

export const $isNewBankAccountSuccess = d.store(false)

export const createBankAccountForm = createForm({
  domain: d,
  fields: {
    bic: {
      init: '',
      rules: [rules.required(), rules.exactLength(9)],
    },
    account: {
      init: '',
      rules: [rules.required(), rules.exactLength(20)],
    },
  },
})

export const createBankAccountFx = attach({
  effect: createContragentReqFx,
})
