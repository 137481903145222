import React from 'react'

import { NotificationItem, NotificationType } from '@/ui'

export const AutoCompleteAlert = () => (
  <NotificationItem
    type={NotificationType.Warning}
    title="Обратите внимание!"
    body="В акте сделано автоматическое заполнение полей по отрицательным остаткам. Они возникли из-за подписанного доп. соглашения, в котором был пересмотр цен или состава работ."
  />
)
