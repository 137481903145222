import { Service, ServiceAlias, ServiceReferences } from './types'

export const services: Service[] = [
  {
    alias: ServiceAlias.TechSupervision,
    name: 'Техзор',
    icon: 'techsupervision',
  },
  {
    alias: ServiceAlias.ISUP,
    name: 'ИСУП',
    icon: 'isup',
  },
  {
    alias: ServiceAlias.BuildDocs,
    name: 'BuildDocs',
    icon: 'buildDocs',
  },
  {
    alias: ServiceAlias.GStation,
    name: 'G-station',
    icon: 'gStation',
  },
  {
    alias: ServiceAlias.Accreditation,
    name: 'Аккредитация',
    icon: 'accreditation',
  },
  {
    alias: ServiceAlias.B2B,
    name: 'B2B',
    icon: 'b2b',
  },
  {
    alias: ServiceAlias.GrandTenders,
    name: 'Гранд-тендеры',
    icon: 'isup',
  },
]

export const serviceLinks: ServiceReferences = {
  [ServiceAlias.TechSupervision]: {
    url: 'https://fsk.tehzor.ru/',
  },
  [ServiceAlias.BuildDocs]: {
    url: 'https://builddocs.online/my-account',
  },
  [ServiceAlias.GStation]: {
    links: [
      {
        url: 'https://gs.gmini.tech/',
      },
      {
        url: 'https://gs1.gmini.tech/',
      },
      {
        url: 'https://gs5.gmini.tech/',
      },
      {
        url: 'https://gs9.gmini.tech/',
      },
      {
        url: 'https://gs12.gmini.tech/',
      },
    ],
  },
  [ServiceAlias.ISUP]: {
    url: 'https://isup2.fsk.ru/',
  },
  [ServiceAlias.Accreditation]: {
    url: 'https://fsk.ru/about/tenders?ysclid=ll3ihfsksc459623810',
  },
  [ServiceAlias.B2B]: {
    url: 'https://www.b2b-center.ru/',
  },
  [ServiceAlias.GrandTenders]: {
    url: 'https://grand-tender.prod.gmini.tech/',
  },
}
