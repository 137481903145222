import React from 'react'
import styled from 'styled-components'

import { TextL } from '@/ui'

import { OldFields } from './OldFields'
import { NewFields } from './NewFields'

export const SplitVolumeForm = React.memo(() => {
  return (
    <Wrapper>
      <FieldsWrapper>
        <FormLabel>
          Выбранная строка
        </FormLabel>
        <OldFields />
      </FieldsWrapper>

      <FieldsWrapper>
        <FormLabel>
          Новая строка
        </FormLabel>
        <NewFields />
      </FieldsWrapper>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`

const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const FormLabel = styled.div`
  ${TextL}
`
