import React from 'react'
import styled from 'styled-components'

import { TextM, palette } from '@/ui'
import { Step } from '../../model/types'

type Props = {
  currStep: Step
}

export const StepInfo = ({ currStep }: Props) => {
  const title = React.useMemo(() => {
    return currStep === Step.Second
      ? 'шаг 2. Предоставление данных'
      : 'шаг 1. Проверка ИНН компании'
  }, [currStep])

  return (
    <Wrapper>
      <Title>
        {title}
      </Title>
      <Lines>
        <Line isActive={currStep === Step.First} />
        <Line isActive={currStep === Step.Second} />
      </Lines>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const Title = styled.div`
  ${TextM}
  text-transform: uppercase;
`

const Lines = styled.div`
  display: flex;
  gap: 4px;
`

const Line = styled.div<{ isActive: boolean }>`
  height: 4px;
  border-radius: 2px;
  width: 100%;
  background-color: ${({ isActive }) => (isActive ? palette.accent100 : palette.accent30)};
`
