import React from 'react'
import styled, { css } from 'styled-components'

import { ThemedStyledProps, themeVar } from './theming'

type Props = {
  value?: string
  setValue?: (v: string) => void
  placeholder?: string
  type?: string
  fill?: boolean
  errorText?: string
  error?: boolean
  dataTestId: string
}

export const Input = ({
  value,
  setValue,
  placeholder,
  type,
  fill,
  error,
  errorText,
  dataTestId,
}: Props) => (
  <InputContainer>
    <InputWrapper
      error={error}
      fill={fill}
      type={type}
      value={value}
      onChange={(e) => setValue && setValue(e.target.value)}
      placeholder={placeholder}
      data-testid={dataTestId}
    />
    <ErrorText>{errorText}</ErrorText>
  </InputContainer>
)

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ErrorText = styled.div`
  color: ${themeVar('inputErrorColor')};
  margin-top: 4px;
  font-size: 12px;
  height: 16px;
`

type InputProps = {
  fill?: boolean
  error?: boolean
}

const InputWrapper = styled.input<ThemedStyledProps & InputProps>`
  display: flex;
  font-size: 16px;
  color: ${themeVar('textGray')};
  padding: 14px 14px 14px 0px;
  border: none;
  font-weight: 400;
  border-bottom: 1px solid ${({ theme }) => theme.inputBorderColor};

  &::placeholder {
    color: ${themeVar('textGray')};
  }

  &:focus {
    outline: none;
  }

  ${({ fill }) => Boolean(fill)
    && css`
      width: 100%;
      box-sizing: border-box;
    `}

  ${({ error }) => Boolean(error)
    && css`
      border-bottom: 1px solid ${({ theme }) => theme.inputErrorColor};
    `}

    background-color: ${themeVar('fullTransparent')};
  &:-webkit-autofill,
  &:-webkit-autofill::first-line &:-webkit-autofill:hover,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: ${themeVar('text')};
    -webkit-box-shadow: 0 0 0px 1000px ${themeVar('mainBackground')} inset;
    box-shadow: 0 0 0px 1000px ${themeVar('mainBackground')} inset;
    font-size: 16px !important;
  }
`
