import React from 'react'
import styled from 'styled-components'

import { TextM, TextMLight, palette } from '@/ui'

type Props = {
  name: string
  value?: string | null
}

export const DetailItem = ({ name, value }: Props) => (
  <Wrapper>
    <Name>
      {name}
    </Name>
    <Value hasValue={Boolean(value)}>
      {value || '—'}
    </Value>
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
`

const Name = styled.div`
  ${TextM}
  flex: 0 0 130px;
`

const Value = styled.div<{ hasValue: boolean }>`
  ${TextMLight}
  color: ${({ hasValue }) => (hasValue ? palette.grey90 : palette.grey60)};
`
