import React from 'react'
import styled from 'styled-components'

import { getFullTime } from '@/lib/date'
import { TextMLight } from '../typography'
import { palette } from '../palette'

type Props = {
  date: string
}

export const FullTime = ({ date }: Props) => <Time>{getFullTime(date)}</Time>


const Time = styled.time`
  ${TextMLight}
  color: ${palette.grey70};
`
