import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'

import {
  palette,
  TextM,
  TextMLight,
} from '@/ui'
import { numberWithSpaces } from '@/lib/number-represent'

export type TableCellProps = {
  value?: string | null
  valueType?: 'number' | 'percent' | 'text'
  align?: 'left' | 'center' | 'right'
  colSpan?: number
  decimalScale?: number
  isHeaderCell?: boolean
  isBold?: boolean
  isUppercase?: boolean
}

export const TableCell = ({
  value,
  valueType = 'number',
  align = 'center',
  decimalScale = 2,
  colSpan,
  isHeaderCell,
  isBold,
  isUppercase,
}: TableCellProps) => {
  const formattedValue = useMemo(() => {
    if (!value) return ''

    switch (valueType) {
      case 'number':
        return numberWithSpaces(value, { decimalScale, dot: ',' })
      case 'percent':
        return `${numberWithSpaces(value, { decimalScale, dot: ',' })} %`
      case 'text':
      default:
        return value
    }
  }, [value, valueType])

  return (
    <Wrapper
      align={align}
      isHeaderCell={isHeaderCell}
      colSpan={colSpan}
      isBold={isBold}
      isUppercase={isUppercase}
    >
      {formattedValue}
    </Wrapper>
  )
}

type WrapperProps = Pick<TableCellProps, 'align' | 'isHeaderCell' | 'colSpan' | 'isBold' | 'isUppercase'>

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  padding: 16px 8px;
  word-break: break-word;

  justify-content: center;
  text-align: center;

  &:not(:last-child) {
    border-right: 1px solid ${palette.grey30};
  }

  ${({ isBold }) => (isBold ? TextM : TextMLight)}

  ${({ align }) => align === 'left' && css`
    justify-content: flex-start;
    text-align: left;
  `}

  ${({ align }) => align === 'right' && css`
    justify-content: flex-end;
    text-align: right;
  `}

  ${({ colSpan }) => colSpan && css`
    grid-column: span ${colSpan};
  `}

  ${({ isHeaderCell }) => isHeaderCell && css`
    padding: 16px 8px;
    background-color: ${palette.grey10};

    &:first-child {
      border-top-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
    }
  `}
`
