import React from 'react'
import styled from 'styled-components'

import { palette } from '../../palette'

export const Divider = () => (
  <SVG height="1" width="100%">
    <path
      d="M0.5,0.5 1000,0.5"
      width="100%"
      height="100%"
      strokeDasharray="4"
      stroke={palette.grey40}
    />
  </SVG>
)

const SVG = styled.svg`
  margin: 12px 0;
`
