import React from 'react'
import { useUnit } from 'effector-react'

import { DocumentInfoBlock } from '@/ui'
import { $documentInfo } from '../../../shared-model'
import { onOpenAdditionalModal } from '../../model/private'

export const EstimateInfo = React.memo(() => {
  const info = useUnit($documentInfo)

  if (!info) return null

  const {
    status,
    contract,
    progress,
    approving_additional_agreement,
    reject_comment,
    cancel_comment,
    features,
  } = info

  return (
    <DocumentInfoBlock
      contract={contract}
      features={features}
      progress={progress}
      status={status}
      newDocument={approving_additional_agreement}
      rejectionReason={reject_comment || cancel_comment}
      onOpenAdditional={onOpenAdditionalModal}
    />
  )
})

