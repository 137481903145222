import {
  LotCatalogItem, LotRowType, MorphologyType, WorkTableItem,
} from '@/dal'

import { MorphologyRow } from './types'

export const getMorphologyBreadcrumbs = (morphology?: WorkTableItem['morphology_elements']) => {
  if (!morphology) return []
  const { construction_object, construction_building, construction_section } = morphology
  return [construction_object, construction_building, construction_section]
}

export const getMorphologyString = (morphology?: WorkTableItem['morphology_elements']) => (
  getMorphologyBreadcrumbs(morphology).join(' ')
)

export const mapCatalogItemsToOptions = (items?: LotCatalogItem[]) => (items
  ? items.map(({ id, name }) => ({ id, label: name }))
  : [])

export const mapArrayToOptions = <T = unknown>(items?: T[]) => (items
  ? items.map((value) => ({ id: value, label: String(value) }))
  : [])

export const trimTrailingZeros = (number?: string) => number?.replace(/(\.|,)?0*$/, '')

export const getMorphologyRowDeepestNonNullField = (row: MorphologyRow) => {
  if (!row.object) return null
  if (!row.building) return MorphologyType.Object
  if (!row.section.length) return MorphologyType.Building
  return MorphologyType.Section
}

export const getRowTypeName = (rowType: LotRowType) => (rowType === LotRowType.Work ? 'Р' : 'М')
