import React from 'react'
import { useUnit } from 'effector-react'
import styled from 'styled-components'

import {
  Icon2,
  NewButton,
  TextLLight,
  palette,
} from '@/ui'

import { ArticleType } from '@/dal'
import { $openedArticle, readArticleButtonClicked, readArticleFx } from '../../model/private'

export const ReadButton = React.memo(() => {
  const article = useUnit($openedArticle)
  const isPending = useUnit(readArticleFx.pending)

  if (!article || article.publication_type !== ArticleType.Important) return null

  return (
    <Wrapper>
      {article.is_read ? (
        <Row>
          <Icon2 size={16} icon="success" />
          Новость прочитана
        </Row>
      ) : (
        <NewButton
          size="S"
          label="Прочитать новость"
          isPending={isPending}
          dataTestId="article-button"
          onClick={readArticleButtonClicked}
        />
      )}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  flex: 0 0 55%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const Row = styled.div`
  display: flex;
  gap: 4px;
  align-content: center;
  ${TextLLight}
  color: ${palette.grey60};
`
