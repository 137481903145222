type Payload = {
  [key: string]: unknown
  /** @deprecated */
  files?: File[] | null
}

/** Флаг, указывающий, что вместо файла на бэк отправляется id ранее вложенного файла */
export const EXISTING_FILE_FLAG = '__existing_file'

// TODO ключ files[] ещё используется на каких-то эндпойнтах, но у нас считается устаревшим
// желательно избавиться, но нужен синк с бэком
export function prepareFormData({ files, ...payload }: Payload, filesKey = 'files[]') {
  const formData = new FormData()

  for (const key in payload) {
    if (payload[key] === undefined || payload[key] === null) continue
    if (Array.isArray(payload[key])) {
      payload[key].forEach((element) => {
        formData.append(key, element)
      })
    } else {
      formData.append(key, payload[key] as string | Blob)
    }
  }
  if (files) {
    for (const file of files) {
      // костыль для отправки id существующих файлов, желательно переработать
      if (file.type.includes(EXISTING_FILE_FLAG)) {
        const id = file.type.split('/')[1]
        if (id) {
          formData.append('files_ids', id)
        }
      } else {
        formData.append(filesKey, file)
      }
    }
  }
  return formData
}
