import React, { FormEvent } from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'
import { useForm } from 'effector-forms'

import {
  InputField,
  NewButton,
  NumberInputField,
} from '@/ui'

import { $isFormPending, profileForm } from '../../model/private'

export const EditProfileForm = React.memo(() => {
  const {
    fields, isValid, submit, isDirty,
  } = useForm(profileForm)

  const isPending = useUnit($isFormPending)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (isPending) return
    submit()
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Fields>
        <InputField
          label="ФИО"
          value={fields.fullName.value}
          onChange={fields.fullName.onChange}
          onClear={() => fields.fullName.set('')}
          placeholder="Введите данные"
          size="L"
          error={fields.fullName.firstError?.errorText}
          dataTestId="edit-profile-name-input"
          disabled={isPending}
          isRequired
        />
        <NumberInputField
          label="Телефон"
          value={fields.phone.value}
          onChange={fields.phone.onChange}
          onClear={() => fields.phone.set('')}
          placeholder="+7"
          size="L"
          error={fields.phone.firstError?.errorText}
          format="+7 ### ### ## ##"
          mask="_"
          allowEmptyFormatting
          dataTestId="edit-profile-phone-input"
          disabled={isPending}
          isRequired
        />
        <InputField
          label="Должность"
          value={fields.position.value}
          onChange={fields.position.onChange}
          onClear={() => fields.position.set('')}
          placeholder="Укажите вашу должность в компании"
          size="L"
          error={fields.position.firstError?.errorText}
          dataTestId="edit-profile-position-input"
          disabled={isPending}
          isRequired
        />
        <InputField
          label="Почта"
          value={fields.email.value}
          onChange={fields.email.onChange}
          placeholder="example@email.ru"
          size="L"
          dataTestId="edit-profile-email-input"
          disabled
        />
      </Fields>

      <NewButton
        label="Сохранить"
        type="submit"
        dataTestId="edit-profile-submit-btn"
        isDisabled={!isValid || !isDirty || isPending}
        isPending={Boolean(fields.fullName.value && isPending)}
      />
    </Form>
  )
})

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
