import React from 'react'

import { WorkFilter, WorkList, WorkNavigation } from '../containers'

export const Works = () => (
  <div>
    <WorkNavigation />
    <WorkFilter />
    <WorkList />
  </div>
)
