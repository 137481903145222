import { attachWrapper } from '@42px/effector-extra'
import { AxiosError } from 'axios'

import { prepareFormData } from '@/lib/form-data'

import { Method, authRequestFx } from '../request'
import { AbortableRequest, DocumentAlias, FileDTO } from '../interfaces'
import { CompensationDocument, PostCompensationAttachmentsParams } from './types'
import { defaultErrorHandler } from '../error-handler'

export const getCompensationInfoReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ signal, id }: AbortableRequest<{ id: CompensationDocument['id'] }>) => ({
    method: Method.get,
    url: `/compensations/${id}/`,
    signal,
  }),
  mapResult: ({ result }): CompensationDocument => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const postCompensationAttachmentsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, ...body }: PostCompensationAttachmentsParams) => ({
    method: Method.post,
    url: `/files/by_document/${DocumentAlias.COMPENSATION_DOCUMENT}/${id}/`,
    body: prepareFormData({ ...body, files: undefined }),
  }),
  mapResult: ({ result }): FileDTO[] => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const approveCompensationReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, comment }: { id: CompensationDocument['id'], comment?: string }) => ({
    method: Method.post,
    url: `/compensations/${id}/approve/`,
    body: {
      comment: comment ?? null,
    },
  }),
  mapResult: ({ result }): CompensationDocument => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})
