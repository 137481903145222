import { sample } from 'effector'

import { RouterPaths, replaceNavigate } from '@/features/app/model'

import { createToast } from '@/features/toast-service/model'
import {
  ChangePasswordGate,
  checkPasswordTokenFx,
  changePasswordForm,
  resetPasswordFx,
} from './change-private'

sample({
  clock: ChangePasswordGate.open,
  target: checkPasswordTokenFx,
})

sample({
  clock: changePasswordForm.formValidated,
  source: ChangePasswordGate.state,
  filter: Boolean,
  fn: (token, { password }) => ({
    token,
    password,
  }),
  target: resetPasswordFx,
})

sample({
  clock: [checkPasswordTokenFx.fail, resetPasswordFx.done],
  fn: () => RouterPaths.Login,
  target: replaceNavigate,
})

sample({
  clock: ChangePasswordGate.close,
  target: changePasswordForm.reset,
})

createToast({
  effect: resetPasswordFx,
  doneText: 'Новый пароль успешно установлен',
})
