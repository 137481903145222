import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { RequestForAccessError, RequestForAccessErrorType } from '@/dal'

import { RouterPaths } from '@/features/app/model'
import { NewButton } from '@/ui'

import { CompanyNotFound } from './CompanyNotFound'
import { AccreditationInProccess } from './AccreditationInProccess'
import { AccreditationTimeOut } from './AccreditationTimeOut'
import { AccreditationFailed } from './AccreditationFailed'
import { AlreadyExistsInLKP } from './AlreadyExistsInLKP'
import { goToFirstStep } from '../../model/private'

type Props = RequestForAccessError

export const ErrorInfo = ({ code, already_registered_users }: Props) => {
  const Error = React.useMemo(() => {
    switch (code) {
      case RequestForAccessErrorType.AccreditationTimeOutError: {
        return <AccreditationTimeOut />
      }
      case RequestForAccessErrorType.CompanyNotFound: {
        return <CompanyNotFound />
      }
      case RequestForAccessErrorType.AccreditationInProccess: {
        return <AccreditationInProccess />
      }
      case RequestForAccessErrorType.AccreditationFailed: {
        return <AccreditationFailed />
      }
      case RequestForAccessErrorType.AlreadyExistsInLKP: {
        return <AlreadyExistsInLKP users={already_registered_users} />
      }
      default: return null
    }
  }, [code, already_registered_users])

  if (!Error) return null

  return (
    <Wrapper>
      {Error}
      <ButtonWrapper>
        <NewButton
          label="Назад к 1 шагу"
          onClick={goToFirstStep}
          buttonType="line-primary"
          isFill
          dataTestId="go-back"
        />

        <Link to={RouterPaths.Login}>
          <NewButton
            label="ОК"
            isFill
            dataTestId="go-to-login-button"
          />
        </Link>

      </ButtonWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const ButtonWrapper = styled.div`
  display: flex;
  gap: 12px;

  a {
    width: 100%;
  }
`

