import { useStoreMap, useUnit } from 'effector-react'
import React from 'react'
import styled from 'styled-components'

import {
  HeadXL, ModalLayoutV2, NewButton, NewFileItem, palette,
} from '@/ui'

import {
  $companyContractList,
  $reportsContractId,
  closeContractReports,
} from '../../model/private'

export const ReportsModal = React.memo(() => {
  const contractId = useUnit($reportsContractId)
  const contract = useStoreMap({
    store: $companyContractList,
    keys: [contractId],
    fn: (contracts, [contractId]) => contracts.find((item) => item.id === contractId) ?? null,
  })

  if (!contract?.reports) return null

  const allReportsLink = contract.contract_all_reports

  return (
    <ModalLayoutV2
      dataTestId="contract-reports-modal"
      closeModal={closeContractReports}
    >
      <Wrapper>
        <Title>
          Отчеты
        </Title>
        <FileList>
          {contract.reports.map((item) => (
            <NewFileItem extension={item.extension} key={item.id} name={item.name} url={item.url} size="L" isDense />
          ))}
        </FileList>
        <ButtonsRow>
          {allReportsLink ? (
            <LinkWrapper href={allReportsLink}>
              <NewButton label="Скачать все" dataTestId="contract-all-reports" isFill />
            </LinkWrapper>
          ) : (
            <NewButton label="Скачать все" dataTestId="contract-all-reports" isFill isDisabled />
          )}
          <NewButton label="Отменить" dataTestId="" buttonType="grey" isFill onClick={closeContractReports} />
        </ButtonsRow>
      </Wrapper>
    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  padding: 32px 32px 20px;
  width: 450px;
`

const Title = styled.div`
  ${HeadXL}
  margin-bottom: 16px;
`

const FileList = styled.div`
  margin-bottom: 24px;
`

const ButtonsRow = styled.div`
  display: flex;
  gap: 12px;
`

const LinkWrapper = styled.a`
  width: 100%;
`
