import { sample, split } from 'effector'
import { debounce } from 'patronum'


import { AddLotWorkMaterialsPayload, EstimateLotId } from '@/dal'
import { NotificationType } from '@/ui'
import { createToast, showToast } from '@/features/toast-service/model'

import {
  $additionalComment,
  $availableFilters,
  $catalogMaterials,
  $editingRowId,
  $editingRows,
  $filters,
  $isDeletingMaterialEverywhere,
  $isDescriptionExpanded,
  $isEditMode,
  $materialGroups,
  $searchMaterialValue,
  $selectedMaterials,
  $workId,
  $workStructure,
  addLotWorkMaterials,
  addLotWorkMaterialsFx,
  addMaterialsModal,
  changeEditingRow,
  deleteLotWorkMaterial,
  deleteLotWorkMaterialFx,
  deleteMaterialModal,
  distributeAmountsModal,
  distributeLotWorkAmountsFx,
  editCommentModal,
  exitEditMode,
  getLotWorkFiltersFx,
  getLotWorkMaterialGroupsFx,
  getLotWorkMaterialsFx,
  getLotWorkStructureFx,
  onOptionClicked,
  replaceSelectedGroups,
  requestEndedWithErrors,
  resetEditingRows,
  resetSelectedGroups,
  resetTableRow,
  resetTableRowFx,
  saveChanges,
  setAdditionalComment,
  setEditingRowId,
  setIsDeletingMaterialEverywhere,
  setIsDescriptionExpanded,
  setIsEditMode,
  setSearchMaterialValue,
  updateComment,
  updateCommentFx,
  updateLotWorkValues,
  updateLotWorkValuesFx,
  updateSpecificWorkItems,
} from './private'
import { $lotId, EstimateLotWorkGate } from './public'
import { distributeAmountsForm } from './forms'
import { getUnitsFx } from '../../table/model/private'
import { ActionPayload, AmountDistributionType, ItemAction } from './types'
import { getMorphologyString } from '../../model/helpers'

$workId
  .on(EstimateLotWorkGate.open, (_, { workId }) => workId)
  .reset(EstimateLotWorkGate.close)

$workStructure
  .on(getLotWorkStructureFx.doneData, (_, data) => data)
  .on(updateSpecificWorkItems, (data, updatedItems) => (data ? {
    ...data,
    items: data.items.map((item) => updatedItems.find(({ id }) => item.id === id) ?? item),
  } : null))
  .reset(EstimateLotWorkGate.close)

$availableFilters
  .on(getLotWorkFiltersFx.doneData, (_, data) => data)
  .reset(EstimateLotWorkGate.close)

$catalogMaterials
  .on(getLotWorkMaterialsFx.doneData, (_, data) => data)
  .reset(EstimateLotWorkGate.close)

$materialGroups
  .on(getLotWorkMaterialGroupsFx.doneData, (_, data) => data)
  .reset(EstimateLotWorkGate.close)

$isEditMode
  .on(setIsEditMode, (_, val) => val)
  .reset(EstimateLotWorkGate.close, exitEditMode)

$editingRowId
  .on(setEditingRowId, (_, val) => val)
  .reset(EstimateLotWorkGate.close, exitEditMode)

$editingRows
  .on(changeEditingRow, (data, row) => (data.some(({ id }) => id === row.id)
    ? data.map((item) => (item.id !== row.id ? item : {
      ...item,
      ...row,
    }))
    : [...data, row]
  ))
  .reset(EstimateLotWorkGate.close, resetEditingRows)

$isDeletingMaterialEverywhere
  .on(setIsDeletingMaterialEverywhere, (_, val) => val)
  .reset(deleteMaterialModal.close)

$searchMaterialValue
  .on(setSearchMaterialValue, (_, val) => val)
  .reset(addMaterialsModal.close)

$additionalComment
  .on(setAdditionalComment, (_, val) => val)
  .reset(editCommentModal.close)

$isDescriptionExpanded
  .on(setIsDescriptionExpanded, (_, val) => val)
  .reset(EstimateLotWorkGate.close)

sample({
  clock: EstimateLotWorkGate.open,
  // TODO переместить getUnitsFx в shared
  target: [getUnitsFx, getLotWorkMaterialGroupsFx, getLotWorkMaterialsFx],
})

sample({
  clock: EstimateLotWorkGate.close,
  target: [addMaterialsModal.close, deleteMaterialModal.close, editCommentModal.close],
})

sample({
  clock: [
    EstimateLotWorkGate.open,
    debounce({ source: $filters, timeout: 700 }),
    deleteLotWorkMaterialFx.done,
    addLotWorkMaterialsFx.done,
  ],
  source: {
    gate: EstimateLotWorkGate.state,
    filters: $filters,
  },
  filter: EstimateLotWorkGate.status,
  fn: ({ gate, filters }) => ({
    id: gate.id,
    workId: gate.workId,
    ...filters,
  }),
  target: [getLotWorkStructureFx, getLotWorkFiltersFx],
})

sample({
  clock: addLotWorkMaterialsFx.done,
  source: EstimateLotWorkGate.state,
  target: [getLotWorkMaterialGroupsFx, getLotWorkMaterialsFx],
})

sample({
  clock: [resetSelectedGroups, $materialGroups.updates, addMaterialsModal.open],
  source: $materialGroups,
  fn: (groups) => groups.map(({ id }) => id),
  target: replaceSelectedGroups,
})

sample({
  clock: exitEditMode,
  target: resetEditingRows,
})

sample({
  clock: distributeAmountsModal.close,
  target: distributeAmountsForm.reset,
})

sample({
  clock: distributeAmountsForm.formValidated,
  source: distributeAmountsModal.$meta,
  filter: (info) => Boolean(info?.id),
  fn: (info,
    {
      amountDistributionType, split_count_to_all, single_count_for_all, single_price_for_all,
    }) => {
    const formData = amountDistributionType === AmountDistributionType.SplitForAll
      ? {
        split_count_to_all,
        single_price_for_all,
      } : {
        single_count_for_all,
        single_price_for_all,
      }
    return {
      rowId: info?.id as string,
      ...formData,
    }
  },
  target: distributeLotWorkAmountsFx,
})

sample({
  clock: distributeLotWorkAmountsFx.done,
  target: distributeAmountsModal.close,
})

const cases = {
  addMaterial: addMaterialsModal.open.prepend(
    ({ item }: ActionPayload) => ({ morphology: item.morphology_elements }),
  ),
  edit: setEditingRowId.prepend(({ item }: ActionPayload) => item.id),
  reset: resetTableRow.prepend(({ item }: ActionPayload) => ({ rowId: item.id })),
  addComment: editCommentModal.open.prepend(({ item }: ActionPayload) => ({
    ...item,
    morphology: item.morphology_elements,
  })),
  // TODO пока нет поля comment на бэке
  editComment: editCommentModal.open.prepend(({ item }: ActionPayload) => ({
    ...item,
    morphology: item.morphology_elements,
  })),
}

split({
  source: onOptionClicked,
  match: {
    addMaterial: ({ type }) => ItemAction.AddMaterial === type,
    edit: ({ type }) => ItemAction.Edit === type,
    reset: ({ type }) => ItemAction.Reset === type,
    addComment: ({ type }) => ItemAction.AddComment === type,
    editComment: ({ type }) => ItemAction.EditComment === type,
  },
  cases,
})

sample({
  clock: resetTableRow,
  source: $lotId,
  filter: Boolean,
  fn: (id, { rowId }) => ({ id, rowId }),
  target: resetTableRowFx,
})

sample({
  clock: updateComment,
  target: updateCommentFx,
})

sample({
  clock: updateCommentFx.done,
  target: editCommentModal.close,
})

sample({
  clock: addLotWorkMaterials,
  source: {
    workId: $workId,
    materials: $selectedMaterials,
  },
  filter: ({ workId }) => Boolean(workId),
  fn: (payload) => payload as AddLotWorkMaterialsPayload,
  target: addLotWorkMaterialsFx,
})

sample({
  clock: addLotWorkMaterialsFx.done,
  target: addMaterialsModal.close,
})

sample({
  clock: deleteLotWorkMaterial,
  source: {
    id: $lotId,
    isEverywhere: $isDeletingMaterialEverywhere,
  },
  filter: ({ id }) => Boolean(id),
  fn: ({ id, isEverywhere }, rowId) => ({ id: id as EstimateLotId, rowId, isEverywhere }),
  target: deleteLotWorkMaterialFx,
})

sample({
  clock: deleteLotWorkMaterialFx.done,
  target: deleteMaterialModal.close,
})

sample({
  clock: saveChanges,
  target: updateLotWorkValues,
})

sample({
  clock: updateLotWorkValues,
  source: {
    id: $lotId,
    items: $editingRows,
  },
  filter: ({ id, items }) => Boolean(id && items.length),
  fn: ({ id, items }) => ({
    id: id as EstimateLotId,
    items: items.map(({
      id, count, price, total_cost,
    }) => ({
      id,
      count: count ? Number(count) : undefined,
      price: price ? Number(price) : undefined,
      total_cost: total_cost ? Number(total_cost) : undefined,
    })),
  }),
  target: updateLotWorkValuesFx,
})

sample({
  clock: [distributeLotWorkAmountsFx.doneData, resetTableRowFx.doneData],
  target: updateSpecificWorkItems,
})

sample({
  clock: updateLotWorkValuesFx.doneData,
  fn: ({ items }) => items,
  target: [exitEditMode, updateSpecificWorkItems],
})

sample({
  clock: requestEndedWithErrors,
  fn: ({ failed, total }) => ({
    content: `Возникли ошибки при сохранении ${failed} из ${total} строк`,
    icon: NotificationType.Alert,
  }),
  target: showToast,
})

createToast({
  effect: resetTableRowFx,
  doneText: ({ result }) => `Строка ${result[0]?.name} очищена`,
})

createToast({
  effect: updateCommentFx,
  doneText: ({ params }) => {
    const name = getMorphologyString(params.morphology)
    return `Для ${name} в прочих и сопутствующих материалах добавлен комментарий`
  },
})
