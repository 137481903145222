import React from 'react'
import styled from 'styled-components'
import FocusTrap from 'focus-trap-react'
import {
  DateRange, DayContent, DayContentProps, DayPicker,
} from 'react-day-picker'
import ru from 'date-fns/locale/ru'
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react'
import { createPortal } from 'react-dom'

import { getDayJsObject } from '@/lib/date'
import { noop } from '@/lib/noop'

import { InputField } from '../InputField'
import { DialogSheetWrapper } from '../../components/DialogSheetWrapper'
import { DateInputCommonProps } from '../types'
import { CalendarCaption } from '../CalendarCaption'

function DateTime(props: DayContentProps) {
  const date = getDayJsObject(props.date).format('DD-MM-YYYY')
  return (
    <div data-testid={date}>
      <DayContent {...props} />
    </div>
  )
}

type Props = DateInputCommonProps & {
  formattedValue: string
  footerSlot?: React.JSX.Element
  hasToClose?: boolean
  onOpen?: () => void
  onClose?: () => void
} & ({
  mode: 'single'
  inputValue?: Date
  selected: Date | undefined
  onDaySelect: (_: unknown, e: Date) => void
  onRangeSelect?: never
} | {
  mode: 'range'
  inputValue: {
    from: string
    to: string
  }
  selected: DateRange
  onRangeSelect: (e: DateRange | undefined, date: Date) => void
  onDaySelect?: never
})

export const DateInputWrapper = ({
  placeholder,
  inputValue,
  initialOpenedDateValue,
  dataTestId,
  disabled,
  label,
  required,
  errorText,
  noOpenOnClear,
  activeDateRange,
  size,
  isInvalid,
  mode,
  formattedValue,
  selected,
  footerSlot,
  hasToClose,
  onDaySelect,
  onRangeSelect,
  onClear,
  onOpen,
  onClose,
}: Props) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const inputRef = React.useRef<HTMLInputElement>(null)

  const closeDropDown = () => {
    onClose?.()
    setIsOpen(false)
  }

  const openDropDown = () => {
    onOpen?.()
    setIsOpen(true)
  }

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: (isOpen) => (
      isOpen ? openDropDown() : closeDropDown()
    ),
    middleware: [offset(4), flip(), shift()],
    whileElementsMounted: autoUpdate,
    placement: 'bottom-start',
    strategy: 'fixed',
  })

  const click = useClick(context)
  const dismiss = useDismiss(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss])

  const date = React.useMemo(() => {
    const value = inputValue && 'from' in inputValue ? inputValue.from : inputValue
    if (!value || !getDayJsObject(value).isValid()) return undefined
    return new Date(value)
  }, [inputValue])

  const initialOpenedDate = initialOpenedDateValue && new Date(initialOpenedDateValue)

  const handleSingleSelect = (_: unknown, date: Date) => {
    onDaySelect?.(_, date)
    closeDropDown()
  }

  const handleClear = () => {
    onClear?.()
    if (noOpenOnClear) return
    openDropDown()
  }

  const dayPickerProps = React.useMemo(() => (mode === 'single' ? {
    mode,
    selected: date,
    onSelect: handleSingleSelect,
  } : {
    mode,
    selected,
    onSelect: onRangeSelect,
  }), [mode, selected, date, handleSingleSelect, onRangeSelect])

  React.useEffect(() => {
    if (hasToClose) closeDropDown()
  }, [hasToClose])

  return (
    <Wrapper>
      <PopperTarget ref={refs.setReference} {...getReferenceProps()}>
        <InputField
          dataTestId={`${dataTestId}-input`}
          value={formattedValue}
          label={label || ''}
          isRequired={required}
          isLabel={false}
          onChange={noop}
          placeholder={placeholder || 'дд/мм/гггг'}
          disabled={disabled}
          error={errorText}
          onClear={onClear ? handleClear : undefined}
          postfixIcon="calendar"
          size={size}
          ref={inputRef}
          readOnly
          isInvalid={isInvalid}
        />
      </PopperTarget>

      {isOpen && !disabled && (
        createPortal(
          <FocusTrap
            active
            focusTrapOptions={{
              initialFocus: false,
              allowOutsideClick: true,
              clickOutsideDeactivates: true,
            }}
          >
            <DialogSheetWrapper
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              {/* мб лучше отрефакторить, вынести DayPicker наверх и передавать как children */}
              <DayPicker
                components={{
                  DayContent: DateTime,
                  Caption: CalendarCaption,
                }}
                captionLayout="dropdown-buttons"
                disabled={[
                  (day) => {
                    if (!activeDateRange) return false
                    let result = false
                    if (activeDateRange.from) {
                      result = day < activeDateRange.from
                    }
                    if (!result && activeDateRange.to) {
                      result = day > activeDateRange.to
                    }
                    return result
                  },
                ]}
                initialFocus={isOpen}
                defaultMonth={date || initialOpenedDate || activeDateRange?.from}
                locale={ru}
                data-testid={`${dataTestId}-select-input-date`}
                {...dayPickerProps}
              />
              {footerSlot}
            </DialogSheetWrapper>
          </FocusTrap>,
          document.body,
        )
      )}
    </Wrapper>
  )
}

const PopperTarget = styled.div`
  display: flex;
  flex-direction: column;
`

const Wrapper = styled.div`
  width: 100%;
`
