import { useUnit } from 'effector-react'
import React from 'react'

import { DocumentAlias } from '@/dal'

import { $selectedDocumentType } from '../../model/private'
import { CreateAct } from './CreateAct'
import { CreatePlan } from './CreatePlan'
import { CreateKorDoc } from './CreateKorDoc'
import { CreatePayment } from './CreatePayment'

export const CreateDocumentContent = React.memo(() => {
  const type = useUnit($selectedDocumentType)

  switch (type) {
    case DocumentAlias.ACT: return <CreateAct />
    case DocumentAlias.BUDGET: return <CreatePlan />
    case DocumentAlias.KOR_DOC: return <CreateKorDoc />
    case DocumentAlias.PAYMENT: return <CreatePayment />
    default: return null
  }
})

