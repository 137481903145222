import { Effect, sample } from 'effector'

import { CustomResponseError, isCancelError } from '@/dal'

import { replaceNavigate } from './public'

type Params<P, R, E> = {
  effect: Effect<P, R, E>
  route: string
}

export const redirectOnFail = <P, R, E extends CustomResponseError>({
  effect, route,
}: Params<P, R, E>) => {
  sample({
    clock: effect.failData,
    filter: (error) => !isCancelError(error),
    fn: () => route,
    target: replaceNavigate,
  })
}
