import { attachWrapper } from '@42px/effector-extra'
import { AxiosError, AxiosResponse } from 'axios'

import { Method, authRequestFx } from '../request'
import { GetGroupTreeParams, TreeItem } from './types'
import { AbortableRequest } from '../interfaces'

export const getGroupsTreeReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ documentId, documentType, signal }: AbortableRequest<GetGroupTreeParams>) => ({
    method: Method.get,
    url: `/${documentType}/groups/${documentId}/`,
    signal,
  }),
  mapResult: ({ result }: { result: AxiosResponse<TreeItem[]> }) => result.data,
  mapError: ({ error }: { error: AxiosError }) => error.response?.data,
})
