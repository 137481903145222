import React from 'react'
import { DocumentStatuses } from '@/dal'

export const useIsShowApprovedInfo = (documentStatus?: DocumentStatuses) => React.useMemo(
  () => {
    if (!documentStatus) return false
    return [
      DocumentStatuses.ToBeAgreed,
      DocumentStatuses.AgreedContractor,
      DocumentStatuses.DocumentsWaiting,
    ].includes(documentStatus)
  }, [documentStatus])
