import { RefObject, useEffect } from 'react'

const INERT = 'inert'

export const useBlockNestedInputs = <T extends HTMLElement>(
  ref: RefObject<T>,
  isBlocked: boolean,
) => {
  useEffect(() => {
    if (isBlocked) {
      // https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inert
      // текущая версия styled-components, видимо, не распознаёт
      ref.current?.setAttribute(INERT, '')
    } else {
      ref.current?.removeAttribute(INERT)
    }
    return () => ref.current?.removeAttribute(INERT)
  }, [ref.current, isBlocked])
}
