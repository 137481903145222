import { sample } from 'effector'
import { debounce } from 'patronum'

import {
  ActDTO,
  DocumentAlias,
  DocumentStatuses,
  ReportPeriod,
  VisasDocType,
} from '@/dal'

import { openAdditionalRequestModal } from '@/features/additional-request/model'
import { createToast } from '@/features/toast-service/model'

import {
  $isCompensationsModalOpen,
  $isReportingDatesModalOpen,
  actForm,
  actReportingDatesForm,
  attachmentsModel,
  closeCompensationsModal,
  closeDateModal,
  countAdvanceRemainderFx,
  onCountAdvanceRemainder,
  onOpenAdditionalModal,
  onOpenAttachmentsModal,
  onSetDeductionOfPaidAdvance,
  openCompensationsModal,
  openDateModal,
  postActReportingDatesFx,
  setDeductionOfPaidAdvanceFx,
  updateActCommentFx,
} from './info.private'
import {
  $documentId,
  $documentInfo,
  ActGate,
  updateFeatures,
  updateKSData,
} from './public'
import { getActInfoFx } from './private'

$isReportingDatesModalOpen
  .on(openDateModal, () => true)
  .reset(ActGate.close, closeDateModal)

$isCompensationsModalOpen
  .on(openCompensationsModal, () => true)
  .reset(closeCompensationsModal)

sample({
  clock: openDateModal,
  source: $documentInfo,
  filter: (info) => Boolean(info?.report_period),
  fn: (info) => info?.report_period as ReportPeriod,
  target: actReportingDatesForm.set,
})

sample({
  clock: actReportingDatesForm.formValidated,
  source: $documentId,
  filter: Boolean,
  fn: (id, { date_end }) => ({ id, date_end }),
  target: postActReportingDatesFx,
})

sample({
  clock: postActReportingDatesFx.done,
  target: closeDateModal,
})

sample({
  clock: closeDateModal,
  target: actReportingDatesForm.reset,
})

sample({
  clock: onCountAdvanceRemainder,
  source: $documentId,
  filter: Boolean,
  target: countAdvanceRemainderFx,
})

sample({
  clock: onSetDeductionOfPaidAdvance,
  source: $documentId,
  filter: Boolean,
  fn: (id, sum) => ({ id, sum }),
  target: setDeductionOfPaidAdvanceFx,
})

sample({
  clock: [setDeductionOfPaidAdvanceFx.doneData, countAdvanceRemainderFx.doneData],
  target: updateKSData,
})

sample({
  clock: [setDeductionOfPaidAdvanceFx.done, countAdvanceRemainderFx.done],
  source: $documentId,
  filter: Boolean,
  target: updateFeatures,
})

createToast({
  effect: setDeductionOfPaidAdvanceFx,
})

sample({
  clock: onOpenAttachmentsModal,
  source: $documentInfo,
  filter: Boolean,
  fn: (act) => ({
    id: act.id,
    files: act.attachments,
  }),
  target: attachmentsModel.open,
})

sample({
  clock: getActInfoFx.doneData,
  filter: Boolean,
  fn: ({ comment }) => ({ comment: comment ?? '' }),
  target: actForm.set,
})

sample({
  clock: [
    debounce({
      source: actForm.fields.comment.changed,
      timeout: 200,
    }),
  ],
  source: $documentId,
  filter: Boolean,
  fn: (id, comment) => ({ id, comment }),
  target: updateActCommentFx,
})

sample({
  clock: onOpenAdditionalModal,
  source: {
    isOpen: ActGate.status,
    act: $documentInfo,
  },
  filter: ({ isOpen, act }) => Boolean(isOpen && act),
  fn: ({ act }) => ({
    docType: DocumentAlias.ACT as VisasDocType,
    docId: act?.id as ActDTO['id'],
    status: act?.status as DocumentStatuses,
    isSpecialVisa: Boolean(act?.features.is_special_visa),
  }),
  target: openAdditionalRequestModal,
})
