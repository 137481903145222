import styled, { css } from 'styled-components'

import { palette, TextLLight, TextMLight } from '@/ui'

type CellProps = {
  size?: 'M' | 'L'
  align?: 'left' | 'right'
  isHeaderCell?: boolean
  isBold?: boolean
  isGrey?: boolean
  hasIndent?: boolean
}

const BORDER_STYLE = `1px solid ${palette.grey30}`

export const Cell = styled.div<CellProps>`
  padding: 16px 24px;
  border-right: ${BORDER_STYLE};
  border-bottom: ${BORDER_STYLE};

  &:first-child {
    border-left: ${BORDER_STYLE};
  }

  text-align: ${({ align = 'left' }) => (align === 'left' ? 'left' : 'right')};

  ${({ size = 'M' }) => (size === 'M' ? TextMLight : TextLLight)}

  ${({ isHeaderCell }) => isHeaderCell && css`
    padding: 8px 24px;
    border-color: transparent !important;

    &:not(:last-child) {
      border-right: ${BORDER_STYLE} !important;
    }
  `}

  ${({ isBold }) => isBold && css`
    font-weight: 400;
  `}

  ${({ isGrey }) => isGrey && css`
    color: ${palette.grey70};
  `}

  ${({ hasIndent }) => hasIndent && css`
    padding-left: 40px;
  `}
`
