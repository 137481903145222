import { attachWrapper } from '@42px/effector-extra'
import { AxiosError } from 'axios'

import { Method, authRequestFx } from '../request/units'
import { requestForAccessErrorHandler } from './error-handler'
import { GetOrganizationsResponse, RequestForAccessResponse, SendCopmanyInfoParams } from './types'
import { Response } from '../interfaces'

export const checkCompanyINNReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (inn: string) => ({
    url: '/authorization/request-access-to-lkp/check-inn/',
    method: Method.get,
    query: { inn },
  }),
  mapResult: ({ result }: Response<RequestForAccessResponse>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => (
    requestForAccessErrorHandler(error.response?.data)
  ),
})

export const getOrganizationsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: () => ({
    url: '/authorization/request-access-to-lkp/get-organizations/',
    method: Method.get,
  }),
  mapResult: ({ result }: Response<GetOrganizationsResponse>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => (
    requestForAccessErrorHandler(error.response?.data)
  ),
})

export const sendCompanyInfoReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (body: SendCopmanyInfoParams) => ({
    url: '/authorization/request-access-to-lkp/submit/',
    method: Method.post,
    body,
  }),
  mapResult: () => null,
  mapError: ({ error }: { error: AxiosError }) => (
    requestForAccessErrorHandler(error.response?.data)
  ),
})
