import {
  Contract,
  DocumentAlias,
  DocumentStatuses,
  SystemAlias,
} from '../interfaces'

export type TaskItem = {
  document_id: number
  document_type: DocumentAlias
  document_status: DocumentStatuses
  project_name: string
  total_sum: string | null
  created_at: string // isoDate
  contract_id: Contract['id']
  contract_name: string
  is_visa: boolean
  document_name: string
  subcontract_id: Contract['id'] | null
}

export enum AppealRequestStatus {
  NoAnswer = 'no_answer',
  Unread = 'unread',
  Closed = 'closed',
}

export type AppealTaskItem = {
  document_type: 'task'
  task_data: {
    task_status: AppealRequestStatus
    author: string
    description: string
    executor: string
    can_send_message: boolean
  } & ({
    related_document_id: number
    related_document_type: DocumentAlias
    related_document_name: string
  } | {
    related_document_id: null
    related_document_type: null
    related_document_name: null
  })
} & Omit<TaskItem, 'document_type'>

export type ActiveTaskCount = {
  count: number
}

export type TaskFilter = {
  label: string
  value: string
}

export type SystemActions = { [key in SystemAlias]: boolean }
