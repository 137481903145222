import { useForm } from 'effector-forms'
import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import {
  HeadL,
  NewCheckbox,
  Switch,
  palette,
} from '@/ui'

import {
  $avansTableItems,
  $isFilled,
  fillTableFx,
  onFillTable,
  workFilters,
} from '../../../model/table.private'
import { $isPaymentAct, $isBlockChanges, $paymentInfo } from '../../../model/private'

const $itemsCount = $avansTableItems.map((items) => items?.length || 0)
const $canFill = $paymentInfo.map((info) => Boolean(info?.features.can_fill_by_remaining))

export const TableFilters = React.memo(() => {
  const { fields } = useForm(workFilters)
  const isFilled = useUnit($isFilled)
  const isFillPending = useUnit(fillTableFx.pending)
  const count = useUnit($itemsCount)
  const isPaymentAct = useUnit($isPaymentAct)
  const canFill = useUnit($canFill)
  const isBlockChanges = useUnit($isBlockChanges)

  return (
    <Wrapper>
      <Title>
        Список работ
        <WorkCount>{count}</WorkCount>
      </Title>
      {!isPaymentAct && (
        <RightCol>
          <Filters>
            <NewCheckbox
              label="Доступный аванс"
              isChecked={fields.availableAvans.value}
              onChange={fields.availableAvans.onChange}
              isBoolean
            />
            <NewCheckbox
              label="Показать заполненные"
              onChange={fields.showOnlyFilled.onChange}
              isChecked={fields.showOnlyFilled.value}
              isBoolean
            />
          </Filters>
          {canFill && (
            <Switch
              label="Заполнить по остатку"
              onChange={() => onFillTable(!isFilled)}
              isChecked={isFilled}
              disabled={isFillPending || isBlockChanges}
            />
          )}
        </RightCol>
      )}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  background-color: ${palette.white};
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;

  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.h5`
  ${HeadL}
  display: flex;
  gap: 8px;
`

const WorkCount = styled.span`
  ${HeadL}
  color: ${palette.grey60};
`

const RightCol = styled.div`
  display: flex;
  gap: 40px;
`

const Filters = styled.div`
  display: flex;
  gap: 20px;
`
