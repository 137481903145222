import { useUnit } from 'effector-react'
import React, { FormEvent } from 'react'
import styled from 'styled-components'
import { useForm } from 'effector-forms'

import {
  HeadXL,
  ModalLayoutV2,
  NewButton,
  NewTextButton,
  TextAreaField,
  TextLLight,
} from '@/ui'

import {
  $isRejectModalOpen,
  closeModal,
  onAdditionalRequest,
  rejectApprovingFx,
  rejectForm,
} from '../../model/private'

export const RejectApprovingModal = React.memo(() => {
  const isOpen = useUnit($isRejectModalOpen)
  const isPendingReject = useUnit(rejectApprovingFx.pending)

  const {
    fields,
    isValid,
    submit,
  } = useForm(rejectForm)

  if (!isOpen) return null

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    submit()
  }

  const handleClose = () => {
    if (isPendingReject) return
    closeModal()
  }

  const isSubmitDisabled = !isValid || !fields.comment.value.trim()

  return (
    <ModalLayoutV2 closeModal={handleClose}>
      <Wrapper>
        <Top>
          <Title>
            Отклонение решения
          </Title>
          <Text>
            Хотите отклонить итоговое решение?
            После отклонения будет запущен процесс аннулирования документа и он станет недоступен.
            При наличии вопросов направьте
            {' '}
            <NewTextButton
              label="доп. запрос"
              dataTestId="open-additional-request"
              onClick={onAdditionalRequest}
              textStyle="LL"
              isInline
            />
            .
          </Text>
        </Top>

        <Form onSubmit={handleSubmit}>
          <TextAreaField
            dataTestId="reject-reason"
            label="Комментарий"
            onChange={fields.comment.onChange}
            value={fields.comment.value}
            maxSymbolCount={1000}
            currentSymbolCount={fields.comment.value.length}
            autoGrow
            height={100}
            maxHeight={250}
            placeholder="Укажите краткую причину отклонения принятого решения"
            error={fields.comment.firstError?.errorText}
            disabled={isPendingReject}
            required
          />
          <Row>
            <NewButton
              dataTestId="reject-button"
              label="Отклонить"
              isFill
              type="submit"
              isDisabled={isSubmitDisabled}
              isPending={isPendingReject}
            />
            <NewButton
              dataTestId="close-button"
              label="Назад"
              buttonType="grey"
              onClick={handleClose}
              isFill
              isDisabled={isPendingReject}
            />
          </Row>
        </Form>
      </Wrapper>
    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 500px;
  padding: 32px;
`

const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Title = styled.div`
  ${HeadXL}
`

const Text = styled.div`
  ${TextLLight}
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Row = styled.div`
  display: flex;
  gap: 16px;
`
