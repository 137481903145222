import React from 'react'
import styled, { css } from 'styled-components'
import { usePopperTooltip } from 'react-popper-tooltip'

import { Icon } from './Icon'
import { themeVar } from './theming'
import { PopperToolTip } from './PopperToolTip'

type CheckboxType = 'primary' | 'secondary'

type CheckboxProps = {
  onChange: (value: boolean) => void
  dataTestId: string
  label?: string
  tooltip?: {
    textOn: string | JSX.Element
    textOff: string | JSX.Element
  }
  checked?: boolean
  disabled?: boolean
  checkboxType?: CheckboxType
  labelPosition?: 'right' | 'left'
}

export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  onChange,
  dataTestId,
  tooltip,
  checked,
  disabled,
  checkboxType = 'primary',
  labelPosition = 'right',
}) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    placement: 'top',
  })

  return (
    <>
      {tooltip && (
        <PopperToolTip
          getArrowProps={getArrowProps}
          getTooltipProps={getTooltipProps}
          setTooltipRef={setTooltipRef}
          tooltip={checked ? tooltip.textOn : tooltip.textOff}
          visible={visible}
        />
      )}
      <Wrapper
        ordered={labelPosition === 'right' ? 'row' : 'row-reverse'}
        isDisabled={disabled}
        ref={setTriggerRef}
      >
        {checkboxType === 'primary' && (
          <PrimaryInputWrapper checked={checked}>
            <Input
              checked={checked}
              type="checkbox"
              onChange={() => (!disabled ? onChange(!checked) : undefined)}
              data-testid={dataTestId}
            />
            {checked && (
              <IconWrapper>
                <Icon icon="checkbox-checked" />
              </IconWrapper>
            )}
          </PrimaryInputWrapper>
        )}
        {checkboxType === 'secondary' && (
          <>
            <Icon icon={checked ? 'switch-on' : 'switch-off'} />
            <Input
              checked={checked}
              type="checkbox"
              onChange={() => (!disabled ? onChange(!checked) : undefined)}
              data-testid={dataTestId}
            />
          </>
        )}
        {label && <Text>{label}</Text>}
      </Wrapper>
    </>
  )
}

type WrapperProps = {
  ordered: 'row' | 'row-reverse'
  isDisabled?: boolean
}

const Wrapper = styled.label<WrapperProps>`
  display: flex;
  flex-direction: ${({ ordered }) => ordered};
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  color: inherit;
  cursor: pointer;
  gap: 8px;

  ${({ isDisabled }) => isDisabled
    && css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`

type PrimaryInputWrapperProps = {
  checked?: boolean
}

const PrimaryInputWrapper = styled.div<PrimaryInputWrapperProps>`
  height: 14px;
  width: 14px;
  border: solid 1px
    ${({ checked }) => (checked ? themeVar('linkColor') : themeVar('inlineInputBorder'))};
  border-radius: 0;
  position: relative;
`

const Input = styled.input`
  width: 0;
  height: 0;
  opacity: 0;
`

const Text = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${themeVar('text')};
`

const IconWrapper = styled.div`
  position: absolute;
  top: -2px;
  left: -2px;
`
