import React from 'react'
import styled from 'styled-components'

import { CommentsModel } from './createCommentsModel'
import { createAddComment } from './createContainers/createAddComment'
import { createCommentsList } from './createContainers/createCommentsList'

export const createCommentsView = (params: CommentsModel) => {
  const AddComments = createAddComment({
    form: params.form,
    $totalCommentsCount: params.$totalCommentsCount,
    getCommentsFx: params.getCommentsFx,
  })

  const CommentsList = createCommentsList({
    $comments: params.$comments,
    getCommentsFx: params.getCommentsFx,
  })

  return React.memo(() => (
    <Wrapper>
      <AddComments />
      <CommentsList />
    </Wrapper>
  ))
}


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`
