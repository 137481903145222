import { createForm } from 'effector-forms'
import { attach } from 'effector'
import { createGate } from 'effector-react'

import { rules } from '@/lib/form-validators'
import {
  GetOrganizationsResponse,
  checkCompanyINNReqFx,
  getOrganizationsReqFx,
  sendCompanyInfoReqFx,
} from '@/dal'

import { d } from './domain'
import { CompanyInfo, INNError, Step } from './types'

export const RequestForAccessGate = createGate()

export const $currentStep = d.store<Step>(Step.First)
export const goToSecondStep = d.event()
export const goToFirstStep = d.event()

export const $companyInfo = d.store<CompanyInfo | null>(null)

export const $organizations = d.store<GetOrganizationsResponse | null>(null)

export const $innError = d.store<INNError | null>(null)

export const innForm = createForm({
  fields: {
    inn: {
      init: '',
      rules: [rules.required(), rules.inn()],
    },
  },
})

export const companyInfoForm = createForm({
  fields: {
    name: {
      init: '',
      rules: [rules.required(), rules.fullName()],
    },
    profession: {
      init: '',
      rules: [rules.required()],
    },
    number: {
      init: '',
      rules: [rules.required()],
    },
    company_fsk: {
      init: null as number | null,
      rules: [rules.required()],
    },
    project: {
      init: null as null | number,
      rules: [rules.required()],
    },
    phone: {
      init: '',
      rules: [rules.required(), rules.phoneNumber()],
    },
    email: {
      init: '',
      rules: [rules.required(), rules.email()],
    },
  },
  validateOn: ['change'],
})

export const setInnError = d.event<INNError>()

export const checkINNFx = attach({
  effect: checkCompanyINNReqFx,
})

export const getOrganizationsFx = attach({
  effect: getOrganizationsReqFx,
})

export const sendCompanyInfoFx = attach({
  effect: sendCompanyInfoReqFx,
})
