import React from 'react'

import {
  RouterPaths,
  SubSystemId,
} from '@/features/app/model'
import { onClickNavItem } from '@/features/header/model'

import { InDevelopmentLink } from './InDevelopmentLink'
import { LinkWithChildren } from './LinkWithChildren'
import { LinkItem } from './LinkItem'

type SystemItemProps = {
  link: RouterPaths | null
  name: string
  isActive: boolean
  subsystemId: SubSystemId
  hasAction: boolean
  isInDevelopment: boolean
  childLinks?: {
    link: RouterPaths,
    name: string
  }[]
}

export const SystemItem: React.FC<SystemItemProps> = ({
  link,
  name,
  isActive,
  childLinks,
  subsystemId,
  hasAction,
  isInDevelopment,
}) => {
  const handleClick = React.useCallback(() => {
    if (link) onClickNavItem({ subsystemId })
  }, [link, subsystemId])

  if (isInDevelopment || !link) return <InDevelopmentLink name={name} subsystemId={subsystemId} />

  if (childLinks?.length) {
    return (
      <LinkWithChildren
        handleClick={handleClick}
        childLinks={childLinks}
        isActive={isActive}
        name={name}
        subsystemId={subsystemId}
      />
    )
  }

  return (
    <LinkItem
      handleClick={handleClick}
      hasAction={hasAction}
      isActive={isActive}
      link={link}
      name={name}
      subsystemId={subsystemId}
    />
  )
}

