import { attach, combine } from 'effector'

import {
  addLotWorkMaterialsReqFx,
  CatalogMaterial,
  CatalogMaterialGroup,
  deleteLotWorkMaterialReqFx,
  distributeLotWorkAmountsReqFx,
  EstimateLotWorkElementsPayload,
  getLotWorkFiltersReqFx,
  getLotWorkMaterialGroupsReqFx,
  getLotWorkMaterialsReqFx,
  getLotWorkStructureReqFx,
  LotWorkFiltersResponse,
  LotWorkStructureResponse,
  resetLotWorkRowReqFx,
  updateAdditionalMaterialCommentReqFx,
  UpdateAdditionalMaterialPayload,
  updateLotWorkValuesReqFx,
  WorkTableItem,
} from '@/dal'
import { createChunkRequest, createModal, createSelectedIds } from '@/features/factories'
import { createFilters } from '@/features/shared/filterFactory/createFilters'

import { d } from './domain'
import {
  ActionPayload,
  DeleteMaterialInfo,
  DistributeAmountsInfo,
  EditCommentInfo,
  EditingRow,
} from './types'
import { mapArrayToOptions } from '../../model/helpers'
import { $units } from '../../table/model'

export const $workId = d.store<string | null>(null)

export const $workStructure = d.store<LotWorkStructureResponse | null>(null)
export const $workItems = $workStructure.map((data) => (data ? data.items : []))
export const $breadcrumbs = $workStructure.map((data) => (data ? data.breadcrumbs.reverse() : []))
export const $morphologyItemCount = $workStructure.map((data) => (
  data ? data.morphology_item_count : 0
))
export const $materials = $workStructure.map((data) => (data ? data.materials : []))
export const updateSpecificWorkItems = d.event<WorkTableItem[]>()

export const $availableFilters = d.store<LotWorkFiltersResponse | null>(null)
export const $availableNames = $availableFilters.map((data) => mapArrayToOptions(data?.names))
export const $availableUnits = combine(
  [$availableFilters, $units],
  ([filters, units]) => filters?.units.map((id) => {
    const unit = units.find((item) => item.id === id)
    return {
      id: unit?.id ?? 0,
      label: unit?.short_name ?? '',
    }
  }) ?? [],
)
// TODO брать значения из справочника
export const $availableObjects = $availableFilters.map((data) => mapArrayToOptions(data?.objects))
export const $availableBuildings = $availableFilters.map((data) => (
  mapArrayToOptions(data?.buildings)
))
export const $availableSections = $availableFilters.map((data) => mapArrayToOptions(data?.sections))

export const $materialGroups = d.store<CatalogMaterialGroup[]>([])
export const $catalogMaterials = d.store<CatalogMaterial[]>([])

export const $searchMaterialValue = d.store('')
export const setSearchMaterialValue = d.event<string>()

export const $isEditMode = d.store(false)
export const setIsEditMode = d.event<boolean>()

export const $editingRowId = d.store<WorkTableItem['id'] | null>(null)
export const setEditingRowId = d.event<WorkTableItem['id'] | null>()

export const exitEditMode = d.event()

export const $editingRows = d.store<EditingRow[]>([])
export const changeEditingRow = d.event<EditingRow>()
export const resetEditingRows = d.event()

export const $isDeletingMaterialEverywhere = d.store(false)
export const setIsDeletingMaterialEverywhere = d.event<boolean>()

export const $additionalComment = d.store('')
export const setAdditionalComment = d.event<string>()

export const $isDescriptionExpanded = d.store(false)
export const setIsDescriptionExpanded = d.event<boolean>()

export const onOptionClicked = d.event<ActionPayload>()
export const resetTableRow = d.event<{ rowId: WorkTableItem['id'] }>()
export const addLotWorkMaterials = d.event()
export const deleteLotWorkMaterial = d.event<WorkTableItem['id']>()
export const updateLotWorkValues = d.event()
export const updateComment = d.event<UpdateAdditionalMaterialPayload>()
export const saveChanges = d.event()

export const addMaterialsModal = createModal<{ morphology?: WorkTableItem['morphology_elements'] }>()
export const deleteMaterialModal = createModal<DeleteMaterialInfo>()
export const editCommentModal = createModal<EditCommentInfo>()
export const distributeAmountsModal = createModal<DistributeAmountsInfo>()

export const resetSelectedGroups = d.event()
export const {
  $ids: $selectedMaterialGroups,
  toggle: toggleSelectedMaterialGroup,
  replaceAll: replaceSelectedGroups,
} = createSelectedIds({ resetEvents: [addMaterialsModal.close] })

export const {
  $ids: $selectedMaterials,
  toggle: toggleSelectedMaterial,
} = createSelectedIds<string>({ resetEvents: [addMaterialsModal.close] })

export const {
  $filters,
  removeFilter,
  setFilter,
  setFilters,
  clearFilter,
} = createFilters<Omit<EstimateLotWorkElementsPayload, 'limit' | 'offset'>>({}, d)

export const getLotWorkStructureFx = attach({
  effect: getLotWorkStructureReqFx,
})

export const getLotWorkFiltersFx = attach({
  effect: getLotWorkFiltersReqFx,
})

export const getLotWorkMaterialGroupsFx = attach({
  effect: getLotWorkMaterialGroupsReqFx,
})

export const getLotWorkMaterialsFx = attach({
  effect: getLotWorkMaterialsReqFx,
})

export const addLotWorkMaterialsFx = attach({
  effect: addLotWorkMaterialsReqFx,
})

export const deleteLotWorkMaterialFx = attach({
  effect: deleteLotWorkMaterialReqFx,
})

export const resetTableRowFx = attach({
  effect: resetLotWorkRowReqFx,
})

export const distributeLotWorkAmountsFx = attach({
  effect: distributeLotWorkAmountsReqFx,
})

export const {
  effectFx: updateLotWorkValuesFx,
  requestEndedWithErrors,
  $counter,
  $totalItems,
} = createChunkRequest({
  fetchEffect: updateLotWorkValuesReqFx,
  domain: d,
})

export const updateCommentFx = attach({
  effect: updateAdditionalMaterialCommentReqFx,
})
