import React, { useState } from 'react'
import NumberFormat from 'react-number-format'
import styled, { css } from 'styled-components'

import { PaletteColor, palette } from '../../palette'
import { TextMLight } from '../../typography'
import { CellInputWrapper, CellInputWrapperProps } from './CellInputWrapper'
import { IconName2 } from '../../icons'

type Props = {
  onChange: (val: string) => void
  onFocus?: () => void
  onBlur?: () => void
  onToggle?: (val: boolean) => void
  onEnter?: () => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onIconClick?: () => void
  displayValue: string
  iconTooltip?: string
  isDisable?: boolean
  customIcon?: IconName2
  allowNegative?: boolean
  dataTestId?: string
  textAlign?: 'right' | 'center' | 'left'
  inputValue: string
  isInvalid?: boolean
  placeholder?: string
  format?: string
  suffix?: string
  allowEmptyFormatting?: boolean
  mask?: string
  decimalSeparator?: string
  allowLeadingZeros?: boolean
  decimalScale?: number
  fixedDecimalScale?: boolean
  color?: PaletteColor
  padding?: string
  hasAbsoluteIcon?: boolean
  submitKeys?: string[]
  hasToFocus?: boolean
  isCentered?: boolean
  popupZIndex?: number
  sameComponentHeight?: boolean
  ref?: React.MutableRefObject<HTMLElement>
} & Pick<CellInputWrapperProps, 'verticalAlign'>

export const CellNumberInput = ({
  onChange,
  onBlur,
  onFocus,
  onToggle,
  onIconClick,
  onEnter,
  onKeyDown,
  displayValue,
  iconTooltip,
  isDisable,
  customIcon,
  allowNegative = false,
  textAlign = 'left',
  dataTestId,
  inputValue,
  isInvalid,
  allowEmptyFormatting,
  format,
  mask,
  placeholder,
  suffix,
  decimalSeparator,
  decimalScale,
  allowLeadingZeros,
  fixedDecimalScale,
  color,
  padding,
  hasAbsoluteIcon,
  submitKeys = [],
  hasToFocus,
  isCentered,
  popupZIndex,
  verticalAlign,
  sameComponentHeight,
  ref,
}: Props) => {
  const [interimValue, setInterimValue] = useState(inputValue)

  const handleBlur = () => {
    if (interimValue) {
      onChange(interimValue)
    } else {
      setInterimValue(inputValue)
    }
    onBlur?.()
  }

  const handleChange = (value: string) => {
    if (value) {
      onChange(value)
    }
    setInterimValue(value)
  }

  return (
    <CellInputWrapper
      suffix={suffix}
      value={displayValue}
      inputRef={ref}
      isMaskInput
      textAlign={textAlign}
      isInvalid={Boolean(isInvalid)}
      icon={customIcon}
      color={color}
      padding={padding}
      isCentered={isCentered}
      hasAbsoluteIcon={hasAbsoluteIcon}
      iconTooltip={iconTooltip}
      isDisable={isDisable}
      decimalScale={decimalScale}
      submitKeys={['Enter', ...submitKeys]}
      hasToFocus={hasToFocus}
      popupZIndex={popupZIndex}
      selectAfterFocus
      verticalAlign={verticalAlign}
      sameComponentHeight={sameComponentHeight}
      onIconClick={onIconClick}
      onFocus={onFocus}
      onBlur={handleBlur}
      onToggle={onToggle}
      onEnter={onEnter}
      onKeyDown={onKeyDown}
    >
      <Input
        onValueChange={(e) => handleChange(e.value)}
        suffix={suffix}
        value={interimValue}
        mask={mask}
        allowEmptyFormatting={allowEmptyFormatting}
        decimalSeparator={decimalSeparator}
        decimalScale={decimalScale}
        allowNegative={allowNegative}
        allowLeadingZeros={allowLeadingZeros}
        fixedDecimalScale={fixedDecimalScale}
        format={format}
        textAlign={textAlign}
        placeholder={placeholder}
        isInvalid={Boolean(isInvalid)}
        data-testid={dataTestId}
        disabled={isDisable}
        thousandSeparator=" "
        allowedDecimalSeparators={[',', '.']}
      />
    </CellInputWrapper>
  )
}

type InputProps = {
  textAlign: 'right' | 'center' | 'left'
  isInvalid: boolean
}

const Input = styled(NumberFormat)<InputProps>`
  background-color: transparent;
  height: fit-content;
  border: none;
  outline: none;
  transition: color 0.15s linear;
  ${TextMLight}
  width: 100%;
  ${({ textAlign }) => textAlign && css`
    text-align: ${textAlign};
  `}

  ${({ isInvalid }) => isInvalid && css`
    color: ${palette.red100};
    &::placeholder {
      color: ${palette.red100};
    }
  `}
`
