import { Domain, Unit } from 'effector'

import { root } from '@/root-domain'

type Params = {
  domain?: Domain
  resetEvents?: Unit<unknown>[]
}

export const createSelectedIds = <T = number>({ domain, resetEvents = [] }: Params = {}) => {
  const d = domain ?? root.domain()

  const $ids = d.store<T[]>([])
  const toggle = d.event<T>()
  const replaceAll = d.event<T[]>()
  const resetAll = d.event()

  $ids
    .on(toggle, (ids, id) => (ids.includes(id) ? ids.filter((item) => item !== id) : [...ids, id]))
    .on(replaceAll, (_, ids) => ids)
    .reset(resetAll, ...resetEvents)

  return {
    $ids,
    toggle,
    replaceAll,
    resetAll,
  }
}
