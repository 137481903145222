import React from 'react'
import styled from 'styled-components'
import { ContractItem } from '@/dal'
import { NewButton, NewIconButton } from '@/ui'

type Props = {
  onButton: () => void
  isPending: boolean
  contractId: ContractItem['id']
  hasDocs?: boolean
  link?: string
}

export const GetRetention = ({
  hasDocs,
  contractId,
  link,
  isPending,
  onButton,
}: Props) => {
  const preventClick = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleButton = (e: React.MouseEvent) => {
    preventClick(e)
    onButton()
  }

  return (
    <Wrapper onClick={preventClick}>
      <NewButton
        size="S"
        isFill
        onClick={handleButton}
        dataTestId={`get-retention-${contractId}`}
        label="Получить удержания"
        buttonType="secondary"
        isPending={isPending}
      />

      {hasDocs && (
        <a href={link} target="_blank" rel="noreferrer">
          <NewIconButton
            dataTestId="download-closure-docs"
            icon="download"
            size={16}
            padding="10px"
            borderRadius="4px"
            backgroundColor="white"
            borderColor="grey40"
            color="grey90"
            hoverBackgroundColor="grey40"
          />
        </a>
      )}
    </Wrapper>
  )
}
const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`
