import React from 'react'
import styled from 'styled-components'

import {
  TextM,
  TextMLight,
  palette,
} from '@/ui'
import { CommentAuthor } from '@/dal'
import { getFullTime } from '@/lib/date'

type Props = {
  isReply: boolean
  author: CommentAuthor
  body: string
  date: string
}

export const CommentItem = ({
  body,
  date,
  isReply,
  author,
}: Props) => (
  <Wrapper isAnswer={isReply}>
    <UserAvatar src={author.avatar} alt="user avatar" />
    <Content>
      <UserNames>
        {author.last_name}
        {' '}
        {author.first_name}
        {' '}
        {author.patronymic}
      </UserNames>
      <Body>
        {body}
      </Body>
      <TimeStamp>
        {getFullTime(date)}
      </TimeStamp>
    </Content>
  </Wrapper>
)

const Wrapper = styled.div<{ isAnswer?: boolean }>`
  margin: ${({ isAnswer }) => (isAnswer ? '0 0 0 40px' : '0')};
  display: flex;
  gap: 8px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const UserAvatar = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
`

const UserNames = styled.div`
  ${TextM}
`

const TimeStamp = styled.data`
  ${TextMLight}
  color: ${palette.grey70};
  margin-top: 8px;
`

const Body = styled.div`
  ${TextMLight}
`
