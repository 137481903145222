import React from 'react'
import styled, { css } from 'styled-components'

import { themeVar, WINDOW_WIDTH } from './theming'

type Props = {
  visible: boolean
  onClose: () => void
  isMenu?: boolean
  isEstimate?: boolean
  isDialog?: boolean
  isStructure?: boolean
}

export const Drawer: React.FC<Props> = ({
  children,
  visible,
  onClose,
  isMenu,
  isEstimate,
  isDialog,
  isStructure,
}) => (visible ? (
  <ModalContainer
    isMenu={isMenu}
    isEstimate={isEstimate}
    isDialog={isDialog}
    isStructure={isStructure}
  >
    {!isEstimate && <Overlay onClick={onClose} /* onon */ />}
    <Content
      isMenu={isMenu}
      isEstimate={isEstimate}
      isDialog={isDialog}
      isStructure={isStructure}
    >
      {children}
    </Content>
  </ModalContainer>
) : null)

type InternalProps = {
  isMenu?: boolean
  isEstimate?: boolean
  isDialog?: boolean
  isStructure?: boolean
}

const ModalContainer = styled.div<InternalProps>`
  display: flex;
  flex-direction: row;

  ${({ isMenu, isDialog, isStructure }) => (isMenu || isDialog || isStructure)
    && css`
      z-index: 9;
    `}

  ${({ isEstimate }) => isEstimate
    && css`
      height: 100%;

      @media (max-width: ${WINDOW_WIDTH.smallDesktop - 1}px) {
        z-index: 9;
      }
    `}
`

const Overlay = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
`

const Content = styled.div<InternalProps>`
  color: ${themeVar('modalTextColor')};
  box-sizing: border-box;

  background-color: ${themeVar('modalBgColor')};

  ${({ isMenu }) => isMenu
    && css`
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      width: 195px;
    `}

  ${({ isEstimate }) => isEstimate
    && css`
      width: 448px;

      @media (max-width: ${WINDOW_WIDTH.bigDesktop - 1}px) {
        width: 219px;
      }

      @media (max-width: ${WINDOW_WIDTH.desktop - 1}px) {
        width: 206px;
      }

      @media (max-width: ${WINDOW_WIDTH.smallDesktop - 1}px) {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        height: auto;
      }
    `}

  ${({ isDialog }) => isDialog
    && css`
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      width: 705px;
    `}

  ${({ isStructure }) => isStructure
    && css`
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      width: 780px;

      @media (max-width: ${WINDOW_WIDTH.desktop - 1}px) {
        width: 656px;
      }
    `}
`

export const DrawerHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-size: 24px;
  padding-bottom: 16px;
  font-weight: 600;
  border-bottom: 1px solid ${themeVar('drawerHeaderBorderColor')};
`
