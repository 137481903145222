import React from 'react'
import styled, { css } from 'styled-components'
import { useStoreMap } from 'effector-react'

import { TreeItem } from '@/dal'
import {
  Icon2, RubleV2, TextMLight, palette,
} from '@/ui'
import { numberWithSpaces } from '@/lib/number-represent'

import {
  $expandedNodes,
  $groupsTree,
  selectNode,
} from '../../model/private'

type Props = {
  id: TreeItem['id']
}

export const TreeNode = React.memo(({ id }: Props) => {
  const node = useStoreMap({
    store: $groupsTree,
    keys: [id],
    fn: (list, [itemId]) => list?.find((item) => item.id === itemId),
  })
  const isExpanded = useStoreMap({
    store: $expandedNodes,
    keys: [id],
    fn: (nodes, [itemId]) => nodes?.includes(itemId) || false,
  })

  if (!node) return null

  const hasChildren = Boolean(node.children?.length)

  const handleSelectNode = () => {
    if (isExpanded) {
      selectNode(node.parent)
    } else {
      selectNode(node.id)
    }
  }

  const icon = isExpanded ? 'minusWithCircle' : 'plusWithCircle'
  const folderIcon = isExpanded ? 'activeFolder' : 'folder'

  return (
    <Wrapper hasMargin={Boolean(node.parent)} hasChildren={hasChildren}>
      <Row onClick={handleSelectNode}>
        <IconsWrapper isExpanded={isExpanded}>
          {hasChildren && <Icon2 size={12} icon={icon} />}
          <Icon2 icon={folderIcon} size={hasChildren ? 16 : 24} />
        </IconsWrapper>

        <Folder>
          <FolderName isExpanded={isExpanded}>
            {node.code_1c && `${node.code_1c} `}
            {node.name}
          </FolderName>

          <Text>
            {numberWithSpaces(node.group_cost)}
            <RubleV2 />
          </Text>
        </Folder>
      </Row>

      {isExpanded && hasChildren && node.children?.map((nodeId) => (
        <TreeNode
          id={nodeId}
          key={nodeId}
        />
      ))}
    </Wrapper>
  )
})

const Wrapper = styled.div<{ hasMargin: boolean, hasChildren: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${({ hasMargin }) => hasMargin && css`
    margin-left: 24px;
  `}

  ${({ hasChildren }) => !hasChildren && css`
    padding-left: 14px;
  `}
`

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`

const Folder = styled.div`
  flex-grow: 1;
  display: flex;
  gap: 24px;
  padding-top: 4px;
  cursor: pointer;
  overflow: hidden;
`

const IconsWrapper = styled.div<{isExpanded: boolean}>`
  display: flex;
  gap: 4px;
  align-items: center;

  color: ${({ isExpanded }) => (isExpanded ? palette.accent100 : palette.grey70)};
`

const FolderName = styled.div<{isExpanded: boolean}>`
  flex-grow: 1;
  ${TextMLight}
  user-select: none;
  color: ${({ isExpanded }) => (isExpanded ? palette.accent100 : palette.grey90)};
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;

`

const Text = styled.div`
  ${TextMLight}
  color: ${palette.grey70};
  flex: 0 0 max-content;
`
