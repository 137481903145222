import {
  RequestForAccessErrorType,
  RequestForAccessResponseError,
} from './types'
import {
  AccreditationFailedError,
  AccreditationTimeOutError,
  AccreditationInProccessError,
  CompanyNotFoundError,
  CompanyAlreadyExistsInLKPError,
} from './errors'
import {
  DefaultHandledError,
  defaultErrorHandler,
} from '../error-handler'

export type RequestForAccessHandledError =
  AccreditationTimeOutError
  | CompanyNotFoundError
  | AccreditationInProccessError
  | AccreditationFailedError
  | DefaultHandledError

export const requestForAccessErrorHandler = (error: RequestForAccessResponseError):
  RequestForAccessHandledError => {
  switch (error?.code) {
    case RequestForAccessErrorType.CompanyNotFound: {
      return new CompanyNotFoundError(error)
    }
    case RequestForAccessErrorType.AccreditationTimeOutError: {
      return new AccreditationTimeOutError(error)
    }
    case RequestForAccessErrorType.AccreditationInProccess: {
      return new AccreditationInProccessError(error)
    }
    case RequestForAccessErrorType.AccreditationFailed: {
      return new AccreditationFailedError(error)
    }
    case RequestForAccessErrorType.AlreadyExistsInLKP: {
      return new CompanyAlreadyExistsInLKPError(error)
    }
    default: return defaultErrorHandler(error)
  }
}
