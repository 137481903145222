import styled from 'styled-components'

export const ResetStyleButton = styled.button`
  padding: 0;
  margin: 0;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  height: 100%;
  color: inherit;
  border: none;
  font-family: 'FuturaFuturis';

  &:disabled {
    cursor: not-allowed;
  }
`
