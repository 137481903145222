import React from 'react'
import styled, { css } from 'styled-components'

import { themeVar, ThemedStyledProps } from './theming'

type Props = {
  visible: boolean
  onClose: () => void
  dataTestId: string
  topFloat?: boolean
  topSticked?: boolean
  fillW?: boolean
  fillWidthVw?: number
  fillWidthPx?: number
  fillHeightPx?: number
  padding?: number
  zIndex?: number
}

export const Modal: React.FC<Props> = ({
  children,
  visible,
  onClose,
  dataTestId,
  topFloat,
  fillW,
  topSticked = false,
  fillWidthVw = 85,
  fillWidthPx,
  fillHeightPx,
  padding,
  zIndex = 80,
}) => (visible ? (
  <Container zIndex={zIndex}>
    <Content
      fillWidthVw={fillWidthVw}
      fillWidthPx={fillWidthPx}
      fillHeightPx={fillHeightPx}
      fillW={fillW}
      topFloat={topFloat}
      topSticked={topSticked}
      padding={padding}
    >
      {children}
    </Content>
    <Overlay onClick={onClose} data-testid={dataTestId} />
  </Container>
) : null)

type ContainerProps = {
  zIndex: number
}

const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  position: fixed;
  z-index: ${({ zIndex }) => zIndex};
  background-color: rgba(0, 0, 0, 0.8);
`

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
`

type ContentProps = {
  topFloat?: boolean
  topSticked?: boolean
  fillW?: boolean
  fillWidthVw: number
  fillWidthPx?: number
  fillHeightPx?: number
  padding?: number
}

const Content = styled.div<ThemedStyledProps & ContentProps>`
  color: ${themeVar('modalTextColor')};
  box-sizing: border-box;
  z-index: 100;
  position: absolute;

  ${({ topFloat }) => topFloat
    && css`
      top: 75px;
    `}

  ${({ topSticked }) => topSticked
    && css`
      top: 0px;
    `}

  ${({ fillW, fillWidthVw }) => Boolean(fillW)
    && css`
      width: ${fillWidthVw}vw;
      min-width: 320px;
      max-width: 683px;
    `}

  ${({ fillWidthPx }) => Boolean(fillWidthPx)
    && css`
      width: ${fillWidthPx}px;
    `}

  ${({ fillHeightPx }) => Boolean(fillHeightPx)
    && css`
      height: ${fillHeightPx}px;
    `}

  padding: ${({ padding }) => padding || 32}px;
  box-shadow: 0 0 5px -15px rgba(0, 0, 0, 0.9);

  // Theme
  background-color: ${themeVar('modalBgColor')};
  margin-bottom: 80px;

  animation: fadeout 0.5s ease-out forwards;
  -webkit-animation: fadeout 0.5s;
`
