
import { sample } from 'effector'

import { DocumentAlias } from '@/dal'
import {
  $searchName,
  changeSearchName,
  filters,
  resetSearchName,
  $articleItems,
  getActArticleFx,
  resetFilters,
} from './filters.private'
import { ActWorkGate } from './private'
import { $documentId } from '../../model'

$searchName
  .on(changeSearchName, (_, val) => val)
  .reset(resetSearchName, ActWorkGate.close)

$articleItems
  .on(getActArticleFx.doneData, (_, items) => items
    .map((item) => ({ label: item.name, id: item.id })),
  )
  .reset(ActWorkGate.close)

sample({
  clock: [ActWorkGate.close, resetFilters],
  target: [filters.reset, resetSearchName],
})

sample({
  clock: ActWorkGate.open,
  source: $documentId,
  filter: Boolean,
  fn: (id) => ({ id, documentType: DocumentAlias.ACT }),
  target: getActArticleFx,
})
