import React from 'react'
import styled from 'styled-components'

import { NewButton } from '@/ui'

type Props = {
  submitLabel?: string
  isDisabled: boolean
  isPending?: boolean
  hasScreenshotButton?: boolean
  onClose: () => void
  onSubmit: () => void
}

export const ButtonsRow = ({
  submitLabel = 'Далее',
  isDisabled,
  isPending,
  hasScreenshotButton,
  onClose,
  onSubmit,
}: Props) => {
  return (
    <Wrapper>
      <FirstButtonWrapper>
        {/* TODO кнопка скриншота */}
        {hasScreenshotButton && (
          <NewButton
            label="Сделать скриншот"
            prefixIcon="scissors"
            buttonType="line-grey"
            dataTestId="new-responsible-appeal-back-btn"
          />
        )}
      </FirstButtonWrapper>
      <NewButton
        label="Отменить"
        buttonType="grey"
        isDisabled={isPending}
        dataTestId="form-cancel-btn"
        onClick={onClose}
      />
      <NewButton
        label={submitLabel}
        isDisabled={isDisabled}
        isPending={isPending}
        dataTestId="form-submit-btn"
        onClick={onSubmit}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: end;
  gap: 12px;
`

const FirstButtonWrapper = styled.div`
  margin-right: auto;
`
