import styled, { css } from 'styled-components'
import { themeVar } from './theming'

export const BaseFont = styled.div``

export const H1TitleCSS = css`
  color: ${themeVar('text')};
  font-size: 24px;
  line-height: 1.25;
  font-family: 'FuturaFuturis';
  font-style: normal;
  font-weight: 400;
`

export const H2TitleCSS = css`
  color: ${themeVar('text')};
  font-size: 20px;
  line-height: 1.5;
  font-family: 'FuturaFuturis';
  font-style: normal;
  font-weight: 400;
`

export const H3TitleCSS = css`
  color: ${themeVar('text')};
  font-size: 14px;
  line-height: 1.3;
  font-family: 'FuturaFuturis';
  font-style: normal;
  font-weight: 400;
`

export const H4TitleCSS = css`
  color: ${themeVar('text')};
  font-size: 12px;
  line-height: 1.33;
  font-family: 'FuturaFuturis';
  font-style: normal;
  font-weight: 400;
`

export const LargeTextCSS = css`
  color: ${themeVar('text')};
  font-size: 18px;
  line-height: 1.3;
  font-family: 'FuturaFuturis';
  font-style: normal;
  font-weight: 400;
`

export const LargeLightTextCSS = css`
  ${LargeTextCSS}
  font-weight: 300;
`

export const MediumTextCSS = css`
  color: ${themeVar('text')};
  font-size: 14px;
  line-height: 1.3;
  font-family: 'FuturaFuturis';
  font-style: normal;
  font-weight: 400;
`

export const MediumLightTextCSS = css`
  ${MediumTextCSS}
  font-weight: 300;
`
export const SmallTextCSS = css`
  color: ${themeVar('text')};
  font-size: 12px;
  line-height: 1.2;
  font-family: 'FuturaFuturis';
  font-style: normal;
  font-weight: 400;
`

export const SmallTextLightCSS = css`
  ${SmallTextCSS}
  font-weight: 300;
`

export const Header3 = styled.h3`
  font-weight: 500;
  font-size: 24px;
  text-align: left;
`
