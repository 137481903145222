import { ExtendedRefs, FloatingContext, ReferenceType } from '@floating-ui/react'
import { DocumentProgress, DocumentStatuses, PaymentOrder } from '@/dal'

export enum NotificationType {
  Alert = 'alert',
  Warning = 'warning',
  Success = 'success',
  Info = 'info'
}

export type TextSize = 'L' | 'M' | 'S' | 'LL' | 'ML' | 'SL'

export type TooltipProps = {
  refs: ExtendedRefs<ReferenceType>
  arrowProps?: {
    ref: React.RefObject<SVGSVGElement>,
    context: FloatingContext,
  }
  floatingStyles: React.CSSProperties
  getFloatingProps: (
    userProps?: React.HTMLProps<HTMLElement>
  ) => Record<string, unknown>
}

export type ConnectedPaymentType = {
  id: PaymentOrder['id']
  name: string
  status: DocumentStatuses
  progress: DocumentProgress | null
}
