import React from 'react'
import { useUnit } from 'effector-react'
import { useField } from 'effector-forms'
import styled from 'styled-components'

import { RoleItemType } from '@/dal'
import { NewDropDownField, NewIconButton } from '@/ui'

import {
  $isFormDisabled,
  $projects,
  $roles,
  contactForm,
} from '../../model/private'
import { SelectedRoles } from '../parts'
import { ProjectField } from '../../model'

type Props = {
  fieldId: string
  index: number
}

export const ProjectSelector = React.memo(({ fieldId, index }: Props) => {
  const projects = useUnit($projects)
  const { value, onChange, isValid } = useField(contactForm.fields.projects)
  const isDisabled = useUnit($isFormDisabled)
  const roles = useUnit($roles)
  const projectLabel = index === 0 ? 'Проект' : `Проект ${index + 1}`

  const field = React.useMemo(() => (
    value.find((item) => item.id === fieldId) as ProjectField
  ), [value, fieldId])

  const options = React.useMemo(() => {
    const filteredProjects = projects?.filter(
      (item) => !value.some((val) => item.id === val.projectId && val.id !== fieldId)) ?? []
    return filteredProjects.map((item) => ({
      label: item.name,
      id: item.id,
    }))
  }, [projects, value])

  const roleOptions = React.useMemo(() => {
    const filterdRoles = roles?.filter((role) => !field.roles.includes(role.id)) ?? []
    return filterdRoles.map((item) => ({
      label: item.name,
      id: item.id,
    }))
  }, [field, roles])

  const selectedRoles = React.useMemo(() => (
    roles?.filter((role) => field.roles.includes(role.id)) ?? []
  ), [field, roles])

  const handleOptionClick = (id: number) => {
    const newVal = value.map((item) => {
      if (item.id === fieldId) {
        return {
          ...item,
          projectId: id,
        }
      }
      return item
    })
    onChange(newVal)
  }

  const handleRoleOption = (roleId: number) => {
    const newVal = value.map((item) => {
      if (item.id === fieldId) {
        return {
          ...item,
          roles: [...item.roles, roleId],
        }
      }
      return item
    })
    onChange(newVal)
  }

  const handleRemoveRoleClick = (id: RoleItemType['id']) => {
    const newField = {
      ...field,
      roles: field.roles.filter((roleId) => roleId !== id),
    }
    const newValue = value.map((item) => (item.id === field.id ? newField : item))
    onChange(newValue)
  }

  const handleDelete = () => {
    onChange(value.filter((item) => item.id !== fieldId))
  }

  const isProjectError = !isValid && !field.projectId
  const isRoleError = !isValid && field.roles.length === 0

  return (
    <Wrapper>
      <ProjectInputWrapper hasError={isProjectError}>
        <NewDropDownField
          isDisabled={isDisabled}
          dataTestId="role-field"
          label={projectLabel}
          size="L"
          required
          placeholder="Выберите проект из списка"
          options={options}
          onOptionClick={handleOptionClick}
          selectedId={field.projectId ?? null}
          error={isProjectError ? 'Выберите проект' : ''}
        />
        {(Boolean(index) || value.length !== 1) && (
          <NewIconButton
            dataTestId={`delete-project-${fieldId}`}
            icon="delete"
            size={16}
            padding="12px"
            color="grey60"
            hoverColor="grey90"
            onClick={handleDelete}
          />
        )}
      </ProjectInputWrapper>

      <NewDropDownField
        isDisabled={!field.projectId || isDisabled}
        dataTestId="role-field"
        label="Роль"
        size="L"
        required
        placeholder="Выберите роль человека из списка"
        options={roleOptions}
        onOptionClick={handleRoleOption}
        selectedId={null}
        error={isRoleError ? 'Выберите роль в проекте' : ''}
      />
      <SelectedRoles
        isDisabled={isDisabled}
        items={selectedRoles}
        onDelete={handleRemoveRoleClick}
      />
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const ProjectInputWrapper = styled.div<{hasError: boolean}>`
  display: flex;
  align-items: ${({ hasError }) => (hasError ? 'center' : 'flex-end')};
`
