import React from 'react'

import { RouterPaths, SubSystemId } from '@/features/app/model'
import { useTooltip } from '@/lib/hooks'
import { Icon2 } from '@/ui'

import { Wrapper } from './Styles'
import { TooltipMenu } from './TooltipMenu'

type Props = {
  subsystemId: SubSystemId,
  name: string
  isActive: boolean
  handleClick: () => void
  childLinks: {
    name: string,
    link: RouterPaths
  }[]
}

export const LinkWithChildren = ({
  isActive,
  name,
  subsystemId,
  childLinks,
  handleClick,
}: Props) => {
  const {
    floatingStyles,
    getFloatingProps,
    getReferenceProps,
    close,
    isOpen,
    refs,
  } = useTooltip({
    placement: 'bottom-start',
  })
  const onLinkClick = () => {
    handleClick()
    close()
  }
  return (
    <Wrapper
      data-testid={`a-menu-item-${subsystemId}`}
      isActive={isActive}
      isInDevelopment={false}
      {...getReferenceProps()}
      ref={refs.setReference}
    >
      {name}
      <Icon2 icon={isOpen ? 'arrowUp' : 'arrowDown'} />
      <TooltipMenu
        childLinks={childLinks}
        floatingStyles={floatingStyles}
        refs={refs}
        getFloatingProps={getFloatingProps}
        isOpen={isOpen}
        onLinkClick={onLinkClick}
      />

    </Wrapper>
  )
}
