import React from 'react'
import styled from 'styled-components'

import { NewButton, palette } from '@/ui'

type Props = {
  isPending?: boolean
  isDisableSubmit: boolean
  onCancel: () => void
}

export const ButtonRow = ({
  isDisableSubmit,
  isPending,
  onCancel,
}: Props) => {
  return (
    <Row>
      <NewButton
        isFill
        label="Добавить"
        dataTestId="create-row-submit-button"
        type="submit"
        isPending={isPending}
        isDisabled={isDisableSubmit}
      />
      <NewButton
        isFill
        label="Отменить"
        dataTestId="create-row-cancel-button"
        onClick={onCancel}
        isPending={isPending}
        buttonType="grey"
      />
    </Row>
  )
}

const Row = styled.div`
  position: sticky;
  bottom: 0;
  background-color: ${palette.white};
  display: flex;
  gap: 12px;
  padding-top: 12px;
`
