export const palette = {
  accent100: '#E84E0E',
  accent90: '#FF6C2F',
  accent80: '#FF7B44',
  accent70: '#FF946B',
  accent60: '#FFA684',
  accent50: '#FFB69A',
  accent40: '#FFC6B0',
  accent30: '#FFD1BF',
  accent20: '#FFDDCF',
  accent10: '#FFEAE2',
  rgbGrey: '140, 140, 140',
  grey100: '#1C1B1B',
  grey90: '#262525',
  grey80: '#333131',
  grey70: '#8C8C8C',
  grey60: '#AAAAAA',
  grey50: '#E0E0E0',
  grey40: '#E8E8E8',
  grey30: '#F1F1F1',
  grey20: '#F4F4F4',
  grey10: '#F8F8F8',
  shadow: 'rgba(171, 171, 171, 0.25)',
  tooltipShadow: 'rgba(122, 122, 122, 0.25)',
  white: '#FFF',
  red100: '#FF0000',
  red10: '#FFEEEF',
  lightBlue: '#0094FF',
  blue: '#0035BC',
  lightGreen100: '#00BD4C',
  lightGreen10: '#E8F9F1',
  green: '#009E65',
  violet100: '#6100C1',
  violet: '#978FED',
  violet70: '#A346FF',
  violet10: '#F7EEFF',
  yellow100: '#FFD172',
  yellow30: '#FFF1DB',
  yellow20: '#FFF4E2',
  yellow10: '#FFF7EA',
  pink100: '#EE89DF',
  telegram: '#3391FF',
}

export type PaletteColor = keyof typeof palette

const isPaletteColor = (color: string): color is PaletteColor => color in palette

export const getColor = (color: PaletteColor | 'transparent' | 'inherit' | 'none') => {
  if (isPaletteColor(color)) return palette[color]
  return color
}
