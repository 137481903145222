import React from 'react'
import { useGate } from 'effector-react'
import {
  Route,
  Switch,
} from 'react-router-dom'

import { RouterPaths } from '@/features/app/model'

import { MyCompanyGate } from '../../model/private'
import { Contacts } from '../../contacts/view'
import { Details } from '../../details/view'

import { RouteWrapper } from '../containers'

export const MyCompany = React.memo(() => {
  useGate(MyCompanyGate)


  return (
    <Switch>
      <Route exact path={RouterPaths.MyCompany}>
        <RouteWrapper title="Реквизиты">
          <Details />
        </RouteWrapper>
      </Route>

      <Route exact path={RouterPaths.Contacts}>
        <RouteWrapper title="Справочник контактов">
          <Contacts />
        </RouteWrapper>
      </Route>
    </Switch>
  )
})

