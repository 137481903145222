import React from 'react'

import { InputNumber } from './inputs'

type Props = {
  value: string
  isSelect: boolean
  dataTestId: string
  maxValue: string
  initialValue: string
  decimalScale?: number
  disabled?: boolean,
  allowNegative?: boolean,
  allowedDecimalSeparators?: string[]

  onSendValue: (val: string) => void
  onFocus: () => void
  onChange: (val: string) => void
  onMaxValueError: () => void
}

export const WorkTableInput = ({
  value,
  isSelect,
  dataTestId,
  maxValue,
  initialValue,
  decimalScale = 2,
  disabled,
  allowNegative = true,
  allowedDecimalSeparators,

  onSendValue,
  onChange,
  onFocus,
  onMaxValueError,
}: Props) => {
  const inputRef = React.createRef<HTMLInputElement>()

  React.useEffect(() => {
    if (isSelect) {
      inputRef?.current?.focus()
    }
  }, [isSelect])

  const handleMaxValueError = () => {
    onMaxValueError()
    onChange(initialValue)
  }

  const sendValue = () => {
    const numberVal = value ? parseFloat(value) : 0.0000

    if (!value) {
      onChange(numberVal.toFixed(decimalScale))
    }
    onSendValue(numberVal.toFixed(decimalScale))
  }

  const handleBlur = () => {
    if (initialValue === value) return
    if (parseFloat(value) > parseFloat(maxValue)) {
      handleMaxValueError()
      return
    }
    sendValue()
  }

  const handlePostfixIconClick = () => {
    if (parseFloat(value) === parseFloat(maxValue)) return
    onChange(maxValue)
    onSendValue(maxValue)
  }

  return (
    <InputNumber
      onFocus={onFocus}
      elementRef={inputRef}
      dataTestId={dataTestId}
      onChange={onChange}
      value={value}
      allowNegative={allowNegative}
      hiddenError
      decimalScale={decimalScale}
      postFixIcon="accept-volume"
      inputStyle="first"
      thousandSeparator=" "
      decimalSeparator="."
      allowedDecimalSeparators={allowedDecimalSeparators}
      onPostFixIconClick={handlePostfixIconClick}
      onBlur={handleBlur}
      disabled={disabled}
    />
  )
}
