import React from 'react'
import { combine } from 'effector'
import { useUnit } from 'effector-react'

import { ConfirmModalLayout } from '@/ui'

import { $documentInfo } from '../../../../shared-model'
import {
  $isResetTableConfirmOpen,
  $resetTargetItemId,
  closeResetTableConfirm,
  onConfirmReset,
  resetTableFx,
  resetTableItemFx,
} from '../../../model/reset-table-item.private'

const $documentName = $documentInfo.map((info) => info?.name || null)

const $isPending = combine(
  resetTableFx.pending,
  resetTableItemFx.pending,
  (...args) => args.some((val) => val),
)

export const ResetConfirm = React.memo(() => {
  const isOpen = useUnit($isResetTableConfirmOpen)
  const name = useUnit($documentName)
  const isPending = useUnit($isPending)
  const rowId = useUnit($resetTargetItemId)

  const bodyText = React.useMemo(() => {
    if (rowId) return 'Внесенные изменения в строке будут сброшены, вы не сможете их вернуть. Сбросить изменения?'
    return `После сброса изменений документ ${name || ''} вернется в исходный вид. Сбросить внесенные изменения?`
  }, [name, rowId])

  return (
    <ConfirmModalLayout
      title="Сброс изменений"
      cancelLabel="Отменить"
      confirmLabel="Да, сбросить"
      dataTestId="reset-confirm"
      isPending={isPending}
      body={bodyText}
      closeModal={closeResetTableConfirm}
      isOpen={isOpen}
      onCancel={closeResetTableConfirm}
      onConfirm={onConfirmReset}
    />
  )
})
