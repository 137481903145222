import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { TextL, TextMLight } from '@/ui'
import { StepType } from '@/dal'

import { QuestionsProgress } from './QuestionsProgress'
import { StepActions } from './StepActions'
import { StarScale, ScrollableContainer } from '../parts'
import { $currentStep, $currentStepForm, changeStepFormRating } from '../../model/private'

export const RatingStep = React.memo(() => {
  const [step, currentStepForm] = useUnit([$currentStep, $currentStepForm])

  if (!step || !currentStepForm || currentStepForm.type !== StepType.Rating) return null

  return (
    <>
      <QuestionsProgress />
      <ScrollableContainer>
        <QuestionWrapper>
          {step.title}
        </QuestionWrapper>

        <QuestionDescription>
          {step.description}
        </QuestionDescription>

        <StarScale
          rating={currentStepForm.rating}
          descriptions={currentStepForm.descriptions ?? []}
          onRate={(value) => changeStepFormRating({ stepId: step.id, rating: value })}
        />
      </ScrollableContainer>

      <StepActions />
    </>
  )
})

const QuestionWrapper = styled.div`
  ${TextL}
`

const QuestionDescription = styled.p`
  ${TextMLight}
  margin: 8px 0 20px;
`
