import React from 'react'
import styled, { css } from 'styled-components'

import { ArticleType } from '@/dal'
import {
  HeadM,
  Icon2,
  TextLLight,
  TextM,
  TextMLight,
  TextS,
  palette,
} from '@/ui'
import { getFullTime } from '@/lib/date'
import { getHighlightedText } from '@/lib/getHighlightedText'
import { WideArticleCardSkeleton } from './WideArticleCardSkeleton'

type Props = {
  type?: ArticleType
  title: string
  description: string
  date: string
  isLoading?: boolean
  id: number
  isRead?: boolean
  commentsCount: number
  searchValue: string
  onCardClick: (id: number) => void
}

export const WideArticleCard = ({
  date,
  description,
  title,
  type,
  isLoading,
  id,
  isRead = true,
  commentsCount,
  searchValue,
  onCardClick,
}: Props) => {
  if (isLoading) return <WideArticleCardSkeleton />

  const time = getFullTime(date)
  const isImportant = type === ArticleType.Important

  const highlightedTitle = getHighlightedText(title, searchValue, palette.accent20)
  const highlightedDescription = getHighlightedText(description, searchValue, palette.accent20)

  return (
    <Wrapper onClick={() => onCardClick(id)} isImportant={isImportant} isRead={isRead}>
      {!isRead && <UnreadArticleBadge />}
      <Row>
        <Title>
          {highlightedTitle}
        </Title>
        <Description>
          {highlightedDescription}
        </Description>
      </Row>
      <Footer>
        <Date dateTime={date}>
          {time}
        </Date>
        <CommentsCount>
          <Icon2 icon="message" color="grey50" size={16} />
          {commentsCount}
        </CommentsCount>
      </Footer>
      {isImportant && (
        <Label>
          Важное
        </Label>
      )}
    </Wrapper>
  )
}

const UnreadArticleBadge = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 6px;
  height: 6px;
  background-color: ${palette.accent100};
  border-radius: 50%;
`

const Title = styled.h5`
 display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden; 
  transition: color 0.15s linear;
  ${HeadM}
`

const Wrapper = styled.div<{
  isImportant: boolean,
  isRead: boolean
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background-color: ${palette.white};
  border-radius: 20px;
  padding: 24px;
  width: 100%;
  cursor: pointer;
  transition: border-color 0.15s linear;

  ${({ isRead }) => css`
    border: 1px solid ${isRead ? palette.grey40 : palette.accent100};
  `}

  @media (hover: hover) {
    &:hover  {
      border: 1px solid ${palette.accent100};

      ${Title} {
        color: ${palette.accent100};
      }
    }
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const Description = styled.p`
  margin-bottom: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  ${TextLLight}
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Date = styled.time`
  ${TextMLight}
  color: ${palette.grey70};
`

const CommentsCount = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  ${TextM}
  color: ${palette.grey60};
`

const Label = styled.div`
  ${TextS}
  position: absolute;
  top: 0;
  left: 28px;
  transform: translateY(-50%);
  color: ${palette.accent100};
  border-radius: 15px;
  background-color: ${palette.accent10};
  padding: 4px 8px;
  border: 1px solid ${palette.accent100};
`
