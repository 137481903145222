import { createDomain } from 'effector'
import { createDarkTheme } from '../themes/dark'
import { createDefaultTheme, ThemeItem } from '../themes/main'


const privateTheming = createDomain()

export const $availableThemes = privateTheming.store<ThemeItem[]>([
  createDefaultTheme(), createDarkTheme(),
])
