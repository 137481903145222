import { TreeItem } from '@/dal'

export const getSubTree = (tree: TreeItem[], itemId: TreeItem['id']) => {
  const getNode = (nodeId: TreeItem['parent'] | undefined) => {
    if (!nodeId) return null
    return tree.find((item) => item.id === nodeId) ?? null
  }

  let node: null | TreeItem = getNode(itemId) as TreeItem
  const ids = [node.id]

  do {
    node = getNode(node?.parent)
    if (node) {
      ids.unshift(node.id)
    }
  } while (node !== null)

  return ids
}
