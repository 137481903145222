import { createGate } from 'effector-react'
import { attach, combine } from 'effector'

import {
  AbortableRequest,
  EstimateTableRow,
  FileDTO,
  GetEstimateTableParams,
  getEstimateFlatTableReqFx,
  getEstimateFolderRowsReqFx,
  getEstimateTreeTableReqFx,
  postAttachmentsForTableItemReqFx,
} from '@/dal'
import { createPagination } from '@/features/shared/createPagination'
import { createSingleEffect } from '@/lib/createSingleEffect'
import { createAttachmentsModalModel } from '@/features/factories/attach-modal'
import { DefaultUpdateItems, createUpdateTreeItemsFx } from '@/lib/tree'

import { getFlatTree } from '@/lib/tree/getFlatTree'
import { d } from './domain'
import {
  CloseFoldersParams,
  DeleteItemParamsFx,
  OnOption,
  TableUpdatePayload,
  TreeType,
} from './types'
import { PAGINATION_LIMIT } from './const'

export const toggleCellVisibility = d.event<number>()

export const TableGate = createGate('table-gate')

export const resetTableData = d.event<void>()

export const $tableRoot = d.store<EstimateTableRow[] | null>(null)
export const $tableTree = d.store<TreeType>({})
export const $isTreeTable = d.store<boolean>(false)
export const changeIsTree = d.event<boolean>()

export const $totalWorkCount = d.store<number | null>(null)

export const $flatItems = combine({
  root: $tableRoot,
  tree: $tableTree,
  isTree: $isTreeTable,
}, getFlatTree<EstimateTableRow>)

export const $openedTableItemId = d.store<EstimateTableRow['id'] | null>(null)
export const $openedTableItemSplitRowId = d.store<EstimateTableRow['id'] | null>(null)
export const onOpenTableItemFiles = d.event<{ id: EstimateTableRow['id'] }>()
export const onOpenTableItemSplitRowFiles = d.event<{ id: EstimateTableRow['id'] }>()
export const $openedTableItem = combine($flatItems, $openedTableItemId, (items, id) => (
  items?.find((item) => item.id === id) || null
))

export const $openedTableItemSplitRow = combine(
  $flatItems,
  $openedTableItemSplitRowId,
  (items, id) => {
    const item = items?.find((item) => item?.new_lines?.[0]?.id === id)
    return item?.new_lines?.[0] ?? null
  },
)

export const postAttachForTableItemFx = attach({
  effect: postAttachmentsForTableItemReqFx,
})

export const postAttachForTableItemSplitRowFx = attach({
  effect: postAttachmentsForTableItemReqFx,
})

export const $canSendRowFiles = $openedTableItem
  .map((info) => Boolean(info?.features?.can_add_attachments))

export const $canSendSplittedRowFiles = $openedTableItemSplitRow
  .map((info) => Boolean(info?.features?.can_add_attachments))

export const attachmentsModel = createAttachmentsModalModel<EstimateTableRow['id']>({
  domain: d,
  sendFx: postAttachForTableItemFx,
  $canSendFiles: $canSendRowFiles,
  $files: $openedTableItem.map((item) => item?.attachments || null),
})

export const splitRowAttachmentsModel = createAttachmentsModalModel<EstimateTableRow['id']>({
  domain: d,
  sendFx: postAttachForTableItemSplitRowFx,
  $canSendFiles: $canSendSplittedRowFiles,
  $files: $openedTableItemSplitRow.map((item) => item?.attachments || null),
})

export const updateItemFilesInRoot = d
  .event<{ files: FileDTO[], parent: null, id: EstimateTableRow['id'] }>()

export const updateItemFilesInTree = d
  .event<{
    files: FileDTO[],
    parent: NonNullable<EstimateTableRow['parent']>,
    id: EstimateTableRow['id']
  }>()

export const updateSplitRowItemFiles = d.event<{ files: FileDTO[], id: EstimateTableRow['id'] }>()

// update table data

export const setRootUpdate = d.event<EstimateTableRow[] | null>()
export const setMapUpdate = d.event<TreeType>()

export const addCreatedItemsFx = d
  .effect<DefaultUpdateItems<EstimateTableRow>, TableUpdatePayload, Error>()
export const updateItemsFx = createUpdateTreeItemsFx<EstimateTableRow>()
export const deleteTableItemFx = d.effect<DeleteItemParamsFx, TableUpdatePayload, Error>()

export const openFolder = d.event<EstimateTableRow['id']>()
export const onCloseFolder = d.event<EstimateTableRow['id']>()
export const closeFolders = d.event<CloseFoldersParams>()

// handle options

export const onOption = d.event<OnOption>()
export const onLoadMore = d.event<number | undefined>()

// get table data

export const $pendingFolderIds = d.store<EstimateTableRow['id'][]>([])

// подтверждение закрытия режима массового редактирования/обнуления
export const $isExitSpecialModeModalOpen = d.store(false)
export const openExitSpecialModeModal = d.event()
export const closeExitSpecialModeModal = d.event()

export const {
  $hasMore,
  initEffect,
  paginationEffect,
  $offset,
  decrementItems,
  incrementItems,
} = createPagination<AbortableRequest<GetEstimateTableParams>, EstimateTableRow[]>({
  fetchEffect: getEstimateFlatTableReqFx,
  limit: PAGINATION_LIMIT,
  domain: d,
})

export const {
  abortFx: abortInitFlatTabelFx,
  requestFx: initFlatTableFx,
} = createSingleEffect(initEffect)

export const {
  abortFx: abortPaginationFlatTabelFx,
  requestFx: paginateFlatTableFx,
} = createSingleEffect(paginationEffect)

export const {
  abortFx: abortGetEstimateTreeTableFx,
  requestFx: getEstimateTreeTableFx,
} = createSingleEffect(getEstimateTreeTableReqFx)

export const getEstimateFolderRowsFx = attach({
  effect: getEstimateFolderRowsReqFx,
})

export const $isTableLoading = combine({
  isFlatPending: initFlatTableFx.pending,
  isTreePending: getEstimateTreeTableFx.pending,
}, ({ isFlatPending, isTreePending }) => isFlatPending || isTreePending)

// scroll to row

export const $scrollToItemId = d.store<EstimateTableRow['id'] | null>(null)
export const scrollToItemById = d.event<EstimateTableRow['id']>()
export const resetScrollToId = d.event<void>()
export const $scrollToIndex = combine(
  {
    flatItems: $flatItems,
    id: $scrollToItemId,
  },
  ({
    flatItems,
    id,
  }) => {
    if (!id) return null
    const index = flatItems?.findIndex((item) => item.id === id) || -1
    return index >= 0 ? index : null
  })

export const onCommentButton = d.event<EstimateTableRow['id']>()
export const readComments = d.event<EstimateTableRow['id']>()
