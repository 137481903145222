import React from 'react'
import styled from 'styled-components'

import { palette } from '@/ui'

import { Charts } from './Charts'
import { Labels } from './Labels'


export const Graph = React.memo(() => (
  <Container>
    <GraphContainer>
      <Overlay>
        <Line />
        <Line />
        <Line />
        <Line />
      </Overlay>

      <Charts />

    </GraphContainer>

    <Labels />

  </Container>
))

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 9px;
`

const GraphContainer = styled.div`
  height: 160px;
  width: 100%;
  position: relative;
  border-bottom: 1px solid ${palette.grey60};
`

const Overlay = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
`

const Line = styled.div`
  height: 20%;
  width: 100%;
  border-top: 1px dashed ${palette.grey60};
`
