import React from 'react'

import { Label } from '@/ui'

type Props = {
  isThirdParties: boolean
}

export const PaymentSubTypeLabel = ({ isThirdParties }: Props) => {
  const label = isThirdParties ? 'Аванс на материалы' : 'Аванс на СМР'

  return (
    <Label
      text={label}
      colorText="violet100"
      size="L"
      bgColor="violet10"
    />
  )
}
