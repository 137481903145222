import { ArticlesFilterOption } from './type'

export const LIKE_REACTION = '👍'
export const DISLIKE_REACTION = '👎'

export const filterOptions: ArticlesFilterOption[] = [
  { label: 'Сначала новые', id: 'newestFirst' },
  { label: 'Сначала старые', id: 'oldestFirst' },
  { label: 'Только важные', id: 'importantOnly' },
  { label: 'Только непрочитанные', id: 'unreadOnly' },
]
