import styled from 'styled-components'
import { themeVar } from './theming'

type Props = {
    size?: number
}

export const InlineDot = styled.div<Props>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: ${({ size }) => size ?? 4}px;
    height:  ${({ size }) => size ?? 4}px;
    background: ${themeVar('textGray')};
    border-radius: 50%;
    margin-left: 4px;
    margin-right: 4px;
`
