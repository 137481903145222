
import { d } from './domain'
import { ToastItem } from './types'

export const $toasts = d.store<ToastItem[]>([])

export type CloseToastParams = Pick<ToastItem, 'timeoutId' | 'id'>
type ToastTimeoutPayload = {
  id: ToastItem['id']
  ms: number
}

export const setToastTimeout = d.event<ToastTimeoutPayload>()
export const setTimeoutFx = d.effect<ToastTimeoutPayload, Pick<ToastItem, 'id' | 'timeoutId'>, Error>()
export const closeToastFx = d.effect<CloseToastParams, ToastItem['id'], Error>()
export const onCloseButton = d.event<ToastItem['id']>()
