import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import { SimpleLoader } from '@/ui'
import { ContractTableItem } from './ContractTableItem'

import {
  $openedContractIds,
  $companyContractList,
  getCompanyContractFx,
} from '../../model/private'
import { EmptyList } from './EmptyList'

export const ContractTableBody = React.memo(() => {
  const companyContractList = useUnit($companyContractList)
  const loading = useUnit(getCompanyContractFx.pending)
  const openedContracts = useUnit($openedContractIds)

  if (loading) {
    return (
      <LoaderWrapper>
        <SimpleLoader />
      </LoaderWrapper>
    )
  }

  if (!companyContractList.length) return <EmptyList />

  return (
    <Wrapper>

      {companyContractList.map((contract) => {
        const isOpen = openedContracts.includes(contract.id)
        return (
          <React.Fragment key={contract.id}>
            <ContractTableItem
              contract={contract}
              isOpen={isOpen}
            />
            {isOpen && contract.child?.map((child) => (
              <ContractTableItem
                key={child.id}
                contract={child}
                isSubItem
              />
            ))}
          </React.Fragment>
        )
      })}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

const LoaderWrapper = styled.div`
  width: 100%;
  padding: 50px 0;
  display: flex;
  justify-content: center;
`
