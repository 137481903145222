
type ButtonColors = {
  borderColor: string
  textColor: string
  bgColor: string
  iconColor?: string
}

export type ButtonTheme = {
  static: ButtonColors
  hover: ButtonColors
  disabled: ButtonColors
}

export const mainTheme = {
  baseFont: 'FuturaFuturis',
  backgroundColor: '#202021',
  notificationFontColor: '#fff',
  loginFontColor: '#fff',
  fontColor: '#727273',
  white: '#FFFFFF',
  burgerFillColor: '#E84E0E',

  blackThemedText: '#fff',
  blackThemedLineColor: '#3D3D3D',
  blackThemedBgColor: '#4F4F4F',

  mainBackground: '#F7F7F7',
  main: '#E84E0E',
  text: '#2D2D2E',
  textGray: '#7A7A7A',
  lightBackground: '#FFFFFF',
  line: '#BDBDBD',
  gray1: '#333333',
  gray2: '#4F4F4F',
  gray3: '#828282',
  gray4: '#A9A9A9',
  gray5: '#E0E0E0',
  gray6: '#F2F2F2',
  gray8: '#727273',
  gray9: '#CDCDCD',
  gray10: '#999999',
  gray11: '#E6E6E6',
  gray12: '#6B6B6B',
  gray13: '#F5F5F5',
  gray14: '#959595',
  gray15: '#BEBEBE',
  gray16: '#FDEDE7',
  mainOrange: 'rgba(232, 78, 14, 0.15)',
  dividerColor: 'rgba(0, 0, 0, 0.05)',
  orange5: '#FFE8D9',
  orange3: '#FFD3C1',
  orange4: '#FFD9CB',
  orange6: '#F6B89F',
  secondOrange: '#FFEDE6',
  whiteText: '#fff',
  grey: '#D0D0D0',

  messageReplyBorderColor: '#D0D0D0',

  inlineInputBorder: '#D0D0D0',

  fourthButtonBgColor: 'transparent',

  buttonPrimary: {
    static: {
      borderColor: '#E84E0E',
      textColor: '#fff',
      bgColor: '#E84E0E',
    },
    hover: {
      borderColor: '#EB6932',
      textColor: '#fff',
      bgColor: '#EB6932',
    },
    disabled: {
      borderColor: '#EB6932',
      textColor: '#fff',
      bgColor: '#EB6932',
    },
  },
  buttonSecondary: {
    static: {
      borderColor: '#2D2D2E',
      textColor: '#2D2D2E',
      bgColor: '#fff',
    },
    hover: {
      borderColor: '#202021',
      textColor: '#fff',
      bgColor: '#202021',
    },
    disabled: {
      borderColor: '#B8B8B8',
      textColor: '#B8B8B8',
      bgColor: 'rgba(0,0,0,0)',
    },
  },
  buttonThird: {
    static: {
      borderColor: '#EB6932',
      textColor: 'rgba(45, 45, 46, 1)',
      bgColor: 'rgba(0,0,0,0)',
      iconColor: '#EB6932',
    },
    hover: {
      borderColor: '#EB6932',
      textColor: '#FFF',
      bgColor: '#EB6932',
      iconColor: '#FFFFFF',
    },
    disabled: {
      borderColor: '#B8B8B8',
      textColor: '#B8B8B8',
      bgColor: 'rgba(0,0,0,0)',
    },
  },
  actHasMessage: {
    static: {
      borderColor: '#BEBEBE',
      textColor: '#2D2D2E',
      bgColor: '#FFFFFF',
      iconColor: '#E84E0E',
    },
    hover: {
      borderColor: '#BEBEBE',
      textColor: '#FFFFFF',
      bgColor: '#E84E0E',
      iconColor: '#FFFFFF',
    },
    disabled: {
      borderColor: '#BEBEBE',
      textColor: '#979697',
      bgColor: '#F7F7F7',
      iconColor: '#979697',
    },
  },
  actNoMessage: {
    static: {
      borderColor: '#BEBEBE',
      textColor: '#2D2D2E',
      bgColor: '#FFFFFF',
      iconColor: '#2D2D2E',
    },
    hover: {
      borderColor: '#BEBEBE',
      textColor: '#FFFFFF',
      bgColor: '#E84E0E',
      iconColor: '#FFFFFF',
    },
    disabled: {
      borderColor: '#BEBEBE',
      textColor: '#979697',
      bgColor: '#F7F7F7',
      iconColor: '#979697',
    },
  },
  agreedContractor: {
    static: {
      borderColor: '#E84E0E',
      textColor: '#FFFFFF',
      bgColor: '#E84E0E',
      iconColor: '#FFFFFF',
    },
    hover: {
      borderColor: '#E84E0E',
      textColor: '#FFFFFF',
      bgColor: '#E84E0E',
      iconColor: '#FFFFFF',
    },
    disabled: {
      borderColor: '#E84E0E',
      textColor: '#FFFFFF',
      bgColor: '#E84E0E',
      iconColor: '#FFFFFF',
    },
  },
  buttonL: {
    static: {
      borderColor: '#DBDBDB',
      textColor: '#A9A9A9',
      bgColor: 'rgba(0,0,0,0)',
    },
    hover: {
      borderColor: '#DBDBDB',
      textColor: '#E84E0E',
      bgColor: 'rgba(0,0,0,0)',
    },
    disabled: {
      borderColor: '#B8B8B8',
      textColor: '#B8B8B8',
      bgColor: '#fff',
    },
  },
  buttonCrossFiltre: {
    static: {
      borderColor: '#DBDBDB',
      textColor: '#A9A9A9',
      bgColor: 'rgba(0,0,0,0)',
    },
    hover: {
      borderColor: '#DBDBDB',
      textColor: '#727273',
      bgColor: 'rgba(0,0,0,0)',
    },
    disabled: {
      borderColor: '#B8B8B8',
      textColor: '#B8B8B8',
      bgColor: '#fff',
    },
  },
  template: {
    static: {
      borderColor: '#DBDBDB',
      textColor: 'transparent',
      bgColor: 'rgba(0,0,0,0)',
    },
    hover: {
      borderColor: '#DBDBDB',
      textColor: 'transparent',
      bgColor: 'rgba(0,0,0,0)',
    },
    disabled: {
      borderColor: '#B8B8B8',
      textColor: '#B8B8B8',
      bgColor: '#fff',
    },
  },

  badgeBgColor: '#FFE9E0',
  badgeTextColor: '#E84E0E',

  inputBorderColor: '#3D3D3D',
  inputTextColor: '#2D2D2E',
  inputPlaceholderColor: '#fff',
  inputErrorColor: '#E84E0E',

  baseInputBorderColor: '#BDBDBD',
  baseInputPlaceholderColor: '#727273',

  notificationBgColor: '#2D2B2E',

  popupBgColor: '#2D2B2E',

  linkColor: '#E84E0E',
  actBlockFontColor: '#2D2D2E',
  actBlockBorderColor: '#BDBDBD',

  modalBgColor: '#fff',
  modalTextColor: '#2D2D2D',
  calendarHighlightColor: '#E84E0E',
  calendarBorderColor: '#BDBDBD',

  selectionTextColor: '#fff',
  selectionBgColor: '#A3946B',

  estimateBlockBorderColor: '#BDBDBD',

  tableHeaderBorderColor: '#BDBDBD',
  tableHeaderHoverFontColor: '#2D2D2E',
  tableRowHoverFontColor: '#CCCCCC',
  tableRowActiveFontColor: '#E84E0E',
  tableRowNotEvenBgColor: '#FFFFFF',

  actEditHeaderBgColor: '#fff',
  actEditTableBgColor: '#F4F4F4',

  drawerHeaderBorderColor: 'rgba(0,0,0,0.2)',
  loaderTransparentBg: '#FCE5DB',

  declineBlockBackgroundColor: '#FEF6F3',
  subsystemBorder: '#D9D9D9',
  subsystemActive: '#F5F5F5',

  breadCrumbsText: '#A1A1A1',
  progressBackgroundColor: '#F6B89F',
  progressActiveColor: '#E84E0E',
  progressRequest: '#BEBEBE',

  fullTransparent: 'rgba(0,0,0,0)',
  opacityText: '#969697',
  opacityIcon: '#f5b296',

  planItemBorderColor: '#cbcbcb',
  red: '#DD2025',
  backAdminExample: '#FFE8DE',

  progressBackground: '#FFD9CB',
  deductionRemove: '#CDCDCD',
  additionRequestBg: '#E9E9E9',
  borderComment: '#E8E8E8',
  headerComment: '#979797',

  helpUserPopapBorder: '#E2E2E2',
  helpUserPopapShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',

  activeIconColor: '#E84E0E',
  inActiveIconColor: '#7A7A7A',

  iconColor: '#E84E0E',
  accordionArrowColor: '#7A7A7A',

  answerIconColor: '#686868',

  refreshIconColor: '#000',

  borderGray: '#D0D0D0',
  notificationColor: '#FF0000',
}

export type BaseTheme = typeof mainTheme
export type KeyTheme = keyof typeof mainTheme

export type ThemeItem = {
  name: string
  variables: BaseTheme
}

export const createDefaultTheme = (): ThemeItem => ({
  name: 'main',
  variables: mainTheme,
})
