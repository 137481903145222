import React, { useRef } from 'react'
import styled from 'styled-components'

type Props = {
  onLoadMore: () => void
  isLoading: boolean
  children?: React.ReactNode
}

export const PaginationItem = (props: Props) => {
  const { onLoadMore, isLoading, children } = props
  const intersectionRef = useRef<HTMLDivElement>(null)

  React.useLayoutEffect(() => {
    const element = intersectionRef.current
    if (!element) return
    const observer = new IntersectionObserver((entries) => {
      const intersectionContainer = entries[0]
      if (intersectionContainer.isIntersecting && !isLoading) {
        onLoadMore()
      }
    }, { threshold: 0.5, rootMargin: '100px' })
    observer.observe(element as HTMLDivElement)
    return () => observer.disconnect()
  }, [isLoading, onLoadMore])

  return (
    <IntersectionContainer ref={intersectionRef}>{isLoading && children}</IntersectionContainer>
  )
}

const IntersectionContainer = styled.div`
  width: 100%;
  min-height: 24px;
  display: flex;
  justify-content: center;
`
