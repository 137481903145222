import { useForm } from 'effector-forms'
import React, { FormEvent } from 'react'
import { useUnit } from 'effector-react'
import styled from 'styled-components'

import { InputField, NewButton } from '@/ui'
import { restorePasswordForm, restorePasswordFx } from '../../model/restore-private'
import { $userNotFoundError } from '../../model/private'

export const RestorePasswordForm = React.memo(() => {
  const {
    fields,
    isValid,
    submit,
    errorText,
  } = useForm(restorePasswordForm)

  const isPending = useUnit(restorePasswordFx.pending)
  const isNotFoundError = useUnit($userNotFoundError)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    submit()
  }
  const error = isNotFoundError
    ? 'Пользователь с такой почтой не найден, проверьте правильность ввода данных'
    : errorText('email')
  return (
    <Form onSubmit={handleSubmit}>
      <InputField
        dataTestId="email-input"
        onChange={fields.email.onChange}
        onClear={fields.email.reset}
        value={fields.email.value}
        error={error}
        placeholder="Введите e-mail"
        size="L"
      />
      <NewButton
        label="Отправить"
        dataTestId="submit-button"
        type="submit"
        isFill
        isDisabled={!isValid || !fields.email.value}
        isPending={isPending}
      />
    </Form>
  )
})

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`
