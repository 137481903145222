import React from 'react'
import styled, { css } from 'styled-components'

import { DefaultButton, Icon2, palette } from '@/ui'

type Props = {
  number: number
  isHovered: boolean
  isFilled: boolean
  onClick: (number: number) => void
  onMouseEnter: (number: number) => void
  onMouseLeave: (number: number) => void
}

export const Star = ({
  number, isHovered, isFilled, onClick, onMouseEnter, onMouseLeave,
}: Props) => {
  const fillColor = isFilled ? 'accent100' : isHovered ? 'accent50' : 'transparent'

  return (
    <Wrapper
      isFilled={isFilled || isHovered}
      onClick={() => onClick(number)}
      data-testid="survey-star-btn"
      onMouseEnter={() => onMouseEnter(number)}
      onMouseLeave={() => onMouseLeave(number)}
    >
      <Icon2 icon="star" fill={fillColor} color="accent100" />
    </Wrapper>
  )
}

const Wrapper = styled(DefaultButton)<{ isFilled: boolean }>`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  padding: 10px;
  transition: background-color 0.15s linear;

  ${({ isFilled }) => isFilled && css`
      background-color: ${palette.accent10};
    `}

  &:not(:last-child) {
    border-right: 1px solid ${palette.accent100};
  }

  svg {
    transition: fill 0.15s linear;
  }
`
