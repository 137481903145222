import {
  ActDTO,
  ActId,
  ContractDirection,
  ContractItem,
  DocumentAlias,
  DocumentStatuses,
  EstimateDoc,
  EstimateDocId,
  EstimateLotId,
  ExistingKorDoc,
  PaymentId,
  PaymentInfo,
  Plan,
} from '@/dal'

export type DocumentBaseInfo = {
  id: ActId
  type: DocumentAlias.ACT
} | {
  id: EstimateDocId
  type: DocumentAlias.KOR_DOC
} | {
  id: PaymentId
  type: DocumentAlias.PAYMENT
} | {
  id: number
  type: DocumentAlias.BUDGET
} | {
  id: EstimateLotId
  type: DocumentAlias.ESTIMATE_LOT
}

export type TargetDocument = {
  documentName: string
  draft?: ExistingKorDoc
  discardedDoc?: ExistingKorDoc
  status?: DocumentStatuses
} & DocumentBaseInfo

export type OnCopyDocument = {
  id: number
  type: DocumentAlias
  deletedDoc?: number | null
}

export enum DocumentManagerActions {
  Delete = 'delete',
  Copy = 'copy',
  Create = 'create'
}

export type CreateDocumentInfo = {
  contractName: string
  contractId: ContractItem['id']
  contractDirection: ContractDirection
  draft?: ExistingKorDoc
  discardedDoc?: ExistingKorDoc
}

export type CreateDocumentPayload = CreateDocumentInfo & {
  type: DocumentAlias
}

export type OnCreatedDocumentPayload = {
  id: unknown
  type: DocumentAlias
} & ({
  type: DocumentAlias.KOR_DOC
} & EstimateDoc | {
  type: DocumentAlias.ACT,
  } & ActDTO | {
  type: DocumentAlias.PAYMENT
} & PaymentInfo | {
  type: DocumentAlias.BUDGET
} & Plan)
