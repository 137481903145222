import React from 'react'
import { useUnit } from 'effector-react'

import { ModalLayoutV2 } from '@/ui'
import { noop } from '@/lib/noop'
import { useOnChangeLocation } from '@/lib/useOnChangeLocation'

import {
  $isModalOpen,
  $isModalWithSelect,
  closeCreateDocumentModal,
  copyDocumentFx,
} from '../../model/private'
import { $isSomeDocumentCreating } from '../../model'
import { CreateDocumentWithSelect, CreateSingleDocument } from '../containers'

export const CreateDocumentModal = React.memo(() => {
  const [isOpen, isModalWithSelect, isCreating, isCopying] = useUnit([
    $isModalOpen,
    $isModalWithSelect,
    $isSomeDocumentCreating,
    copyDocumentFx.pending,
  ])

  useOnChangeLocation(closeCreateDocumentModal)

  if (!isOpen) return null

  return (
    <ModalLayoutV2
      dataTestId="create-document-modal"
      closeModal={isCreating || isCopying ? noop : closeCreateDocumentModal}
    >
      {isModalWithSelect ? <CreateDocumentWithSelect /> : <CreateSingleDocument /> }
    </ModalLayoutV2>
  )
})
