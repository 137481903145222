import React, { Key } from 'react'
import styled from 'styled-components'

import { TabsProps, TabSize } from './types'
import { TabItem } from './TabItem'
import { palette } from '../../palette'

type Props<Id> = TabsProps<Id> & {
  size?: TabSize
}

export const Tabs = <T extends Key>({
  onItemClick,
  items,
  activeId,
  size = 'M',
}: Props<T>) => {
  if (!items.length) return null

  return (
    <Wrapper>
      {items.map((item) => (
        <TabItem
          key={item.id}
          item={item}
          activeId={activeId}
          onItemClick={onItemClick}
          size={size}
        />
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  position: relative;

  &::before {
    content: '';
    background-color: ${palette.grey10};
    border-radius: 4px;
    height: 1px;
    bottom: 0;
    width: 100%;
    position: absolute;
  }
`
