import { Estimate, PlanSubmissionMonth } from '@/dal'
import { d } from './domain'

export const $submissionMonths = d.store<PlanSubmissionMonth[] | null>(null)
export const onGetSubmissionMonths = d.event<Estimate['id']>()
export const resetSubmissionMonths = d.event<void>()

export const $availableMonths = $submissionMonths.map((months) => (
  months?.reduce((acc: Date[], { month, is_free }) => {
    if (is_free) {
      const timestamp = new Date(month)
      acc.push(timestamp)
    }
    return acc
  }, [])) ?? null)
