import { condition } from 'patronum'
import { sample } from 'effector'

import { ContractItem } from '@/dal'
import { createToast } from '@/features/toast-service/model'

import {
  $closingContractId,
  $closureDocumentInfo,
  $selectedDocIds,
  closeContractFx,
  closeContract,
  closeModal,
  getClosureDocumentsByIdFx,
  openClosestDocumentModal,
  toggleDocId,
  $currStep,
  nextStep,
  prevStep,
} from './private'
import { onCloseContract, onUpdatedContractItem } from './public'
import { CloseContractParams } from './types'

$closingContractId
  .on(openClosestDocumentModal, (_, id) => id)
  .reset(closeModal)

$closureDocumentInfo
  .on(getClosureDocumentsByIdFx.doneData, (_, { docs, link_closing_documents }) => ({
    link: link_closing_documents,
    docs,
  }))
  .reset(closeModal)

$currStep
  .on(nextStep, (step) => step + 1)
  .on(prevStep, (step) => step - 1)
  .reset(closeModal)

$selectedDocIds
  .on(toggleDocId, (ids, id) => {
    if (ids.includes(id)) return ids.filter((curr) => curr !== id)
    return [...ids, id]
  })
  .reset([closeModal, $currStep.updates])

condition({
  source: onCloseContract,
  if: ({ needDocs }) => needDocs,
  then: openClosestDocumentModal.prepend(({ id }: CloseContractParams) => id),
  else: closeContractFx.prepend(({ id }: CloseContractParams) => ({ id, docs: [] })),
})

sample({
  clock: openClosestDocumentModal,
  fn: (id) => ({ id }),
  target: getClosureDocumentsByIdFx,
})

sample({
  clock: closeContract,
  source: {
    id: $closingContractId,
    docs: $selectedDocIds,
  },
  filter: ({ id, docs }) => Boolean(id && docs.length),
  fn: ({ id, docs }) => ({
    id: id as ContractItem['id'],
    docs,
  }),
  target: [closeContractFx, closeModal],
})

sample({
  clock: closeContractFx.doneData,
  target: onUpdatedContractItem,
})

createToast({
  effect: closeContractFx,
  doneText: 'Закрывающие документы отправлены на подписание',
})

