import { attach } from 'effector'
import { createGate } from 'effector-react'
import { createForm } from 'effector-forms'

import {
  ContractVisibility,
  getFSKCompanyReqFx,
  getProjectsReqFx,
  getStatusReqFx,
} from '@/dal'
import { d } from './domain'
import { OptionItem } from './types'
import { $contractDirections } from './public'

export const FiltersGate = createGate('Filters')

export const $contractProjectList = d.store<OptionItem[] | null>(null)
export const $statusList = d.store<OptionItem[] | null>(null)
export const $organizationList = d.store<OptionItem[] | null>(null)

export const $directionOptions = $contractDirections.map((items) => items.map(
  (item) => ({
    id: item.type,
    label: item.name,
  }),
))

export const $visibility = d.store(ContractVisibility.visible)
export const changeVisibility = d.event<ContractVisibility>()

export const filterForm = createForm({
  domain: d,
  fields: {
    name: {
      init: '',
    },
    direction: {
      init: null as null | string,
    },
    company_id: {
      init: null as null | number,
    },
    status: {
      init: null as null | number,
    },
    project_id: {
      init: null as null | number,
    },
    date_from: {
      init: '',
    },
    date_to: {
      init: '',
    },
  },
})

export const getProjectsFx = attach({
  effect: getProjectsReqFx,
})

export const getFSKCompanyFx = attach({
  effect: getFSKCompanyReqFx,
})

export const getStatusFx = attach({
  effect: getStatusReqFx,
})
