import React from 'react'
import styled from 'styled-components'

type Props = {
  fontWeight?: number
}

export const Ruble = (
  {
    fontWeight = 400,
  }: Props) => (
    <Wrapper style={{ fontWeight }}>&#x20bd;</Wrapper>
)

const Wrapper = styled.span`
  font-family: Helvetica neue;
  margin-left: 4px;
`
