import { attach } from 'effector'
import {
  EstimateTableRow,
  resetEstimateTableReqFx,
  resetEstimateTableItemReqFx,
} from '@/dal'
import { d } from './domain'

export const $isResetTableConfirmOpen = d.store<boolean>(false)
export const $resetTargetItemId = d.store<EstimateTableRow['id'] | null>(null)
export const onResetTable = d.event<void | EstimateTableRow['id']>()
export const closeResetTableConfirm = d.event<void>()

export const onConfirmReset = d.event<void>()
export const resetItem = d.event<void>()
export const resetTable = d.event<void>()

export const resetTableFx = attach({
  effect: resetEstimateTableReqFx,
})

export const resetTableItemFx = attach({
  effect: resetEstimateTableItemReqFx,
})
