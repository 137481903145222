import { StepType, SurveyInfo } from '@/dal'
import { StepFormObject } from './types'

export const mapSurveyFormsToParams = (id: SurveyInfo['id'], stepForms: StepFormObject[]) => {
  const mappedSteps = stepForms.map((stepForm) => {
    if (stepForm.type === StepType.Rating) {
      return {
        id: stepForm.id,
        stars: stepForm.rating,
      }
    }
    const selectedChoices = stepForm.choices.filter(({ isChecked }) => isChecked)
    const choices = selectedChoices.map(({ id, text }) => ({
      id,
      answer_text: text || null,
    }))
    return {
      choices,
      id: stepForm.id,
    }
  })

  return { id, steps: mappedSteps }
}
