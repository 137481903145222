import styled, { css } from 'styled-components'

import { TextM, TextMLight, palette } from '@/ui'

type ColProps = {
  isEdited?: boolean
}

export const Col = styled.div<ColProps>`
  display: flex;
  flex-direction: column;

  ${({ isEdited }) => isEdited && css`
    background-color: ${palette.yellow10};
  `}
`

type GridWrapperProps = {
  isTotal?: boolean
}

export const GridWrapper = styled.div<GridWrapperProps>`
  display: grid;
  grid-template-columns: minmax(0px, 100px) repeat(3, minmax(0, 1fr));
  gap: 0px 20px;
  
  ${({ isTotal }) => isTotal && css`
    background-color: ${palette.grey10};
  `}
`

export const Label = styled.span<{ isBold?: boolean }>`
  ${({ isBold }) => (isBold ? TextM : TextMLight)};
  &:not(:first-child) {
    text-align: right;
  }
  color: ${palette.grey70};
`

type ValueProps = {
  isBold?: boolean
  isDiff?: boolean
  isGrey?: boolean
}

export const Value = styled.span<ValueProps>`
  ${({ isBold }) => (isBold ? TextM : TextMLight)};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  ${({ isDiff }) => isDiff && css`
    color: ${palette.accent100};
  `}

  ${({ isGrey }) => isGrey && css`
    color: ${palette.grey60};
  `}
`
