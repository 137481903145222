import React from 'react'
import styled from 'styled-components'
import { usePopperTooltip } from 'react-popper-tooltip'

import { themeVar } from './theming'

type UsePopperTooltip = ReturnType<typeof usePopperTooltip>

type Props = {
  setTooltipRef: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  getTooltipProps: UsePopperTooltip['getTooltipProps']
  getArrowProps: UsePopperTooltip['getArrowProps']
  tooltip: string | JSX.Element
  visible: boolean
}

export const PopperToolTip = ({
  getArrowProps,
  getTooltipProps,
  setTooltipRef,
  tooltip,
  visible,
}: Props) => {
  if (!visible) return null
  return (
    <Tooltip ref={setTooltipRef} {...getTooltipProps()}>
      <ToolTipArrow {...getArrowProps()} />
      {tooltip}
    </Tooltip>
  )
}

const Tooltip = styled.div`
  padding: 8px 16px;

  background-color: ${themeVar('lightBackground')};
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  color: ${themeVar('text')};

  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
  z-index: 5;
`

const ToolTipArrow = styled.span`
  /* border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff;
  position: absolute;
  bottom: -10px; */
`
