import { downloadBlobFileFx } from './public'

downloadBlobFileFx.use(({ blob, fileName }) => {
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = fileName
  document.body.appendChild(a)
  a.click()
  a.remove()
})
