import React from 'react'
import styled from 'styled-components'

import {
  palette,
} from '@/ui'

import { AvansTable, TableFilters, TableHeader } from '../containers'


export const PaymentAvansTable = React.memo(() => {
  return (
    <div>
      <StickyWrapper>
        <TableFilters />
        <TableHeader />
      </StickyWrapper>
      <AvansTable />
    </div>
  )
})

const StickyWrapper = styled.div`
  position: sticky;
  top: 16px;
  background-color: ${palette.grey10};
`
