import React from 'react'
import styled from 'styled-components'

import { Icon } from './Icon'
import { themeVar } from './theming'

type Props = {
  onDel: (value?: number) => void
  filename: string
  extension: string
  imagePreview?: string
  fileIndex: number
}

export const FileItem = ({
  onDel,
  filename,
  extension,
  imagePreview,
  fileIndex,
}: Props) => {
  let image: string | undefined

  if (imagePreview) {
    image = `url(${imagePreview})`
  }

  return (
    <Container>
      <FilePic style={{ background: image }}>{extension}</FilePic>
      <FileName>{filename}</FileName>
      <DeleteIcon onClick={() => onDel(fileIndex)}>
        <Icon icon="close" />
      </DeleteIcon>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  padding: 8px;
  border-bottom: 1px solid ${themeVar('line')};

  &:last-child {
    border-bottom: none;
  }
`

const FileName = styled.div`
  flex: 1;
`
const DeleteIcon = styled.div`
  display: flex;
  cursor: pointer;
`

const FilePic = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${themeVar('gray4')};
  border: 1px solid ${themeVar('gray4')};
  color: ${themeVar('mainBackground')};
  font-size: 12px;
  text-transform: uppercase;
  background-size: contain;
`
