import { attachWrapper } from '@42px/effector-extra'
import { AxiosError, AxiosResponse } from 'axios'
import { attach } from 'effector'

import { prepareFormData } from '@/lib/form-data'

import { AbortableRequest, FileDTO, Response } from '../interfaces'
import {
  Method,
  authRequestFx,
  downloadFilesFx,
} from '../request'
import {
  ClosureDocument,
  ClosurePercentage,
  ContractDataPayload,
  ContractDirectionItem,
  ContractItem,
  GetClosureDocumentResponse,
  GetCompanyContractsParams,
  PostContractAttachmentsParams,
  PostReconcilationReportParams,
} from './types'
import { defaultErrorHandler } from '../error-handler'

export const getDownloadPrinterFormReqFx = attach({
  effect: downloadFilesFx,
  mapParams: (id: number) => ({
    method: Method.get,
    url: `/kor_doc/doc/download/${id}`,
  }),
})

export const getCompanyContractReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, signal, ...query }: AbortableRequest<GetCompanyContractsParams>) => ({
    method: Method.get,
    url: `/contracts/?contragent_id=${id}`,
    query,
    signal,
  }),
  mapResult: ({ result }: { result: AxiosResponse<ContractItem[]> }) => result.data,
  mapError: ({
    error,
  }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const putContractVisibilityReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, visibility, signal }: AbortableRequest<ContractDataPayload>) => ({
    method: Method.put,
    url: `/contracts/?contract=${id}`,
    body: {
      visibility,
    },
    signal,
  }),
  mapResult: ({ result }: { result: AxiosResponse<ContractItem> }) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const getContractByIdReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, signal }: AbortableRequest<{ id: number | string }>) => ({
    method: Method.get,
    url: `/get-contract/${id}`,
    signal,
  }),
  mapResult: ({ result }: { result: AxiosResponse<ContractItem> }) => result.data,
})

export const postReconcilationReportReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({
    contractId,
    ...body
  }: PostReconcilationReportParams) => ({
    method: Method.post,
    url: `/responsible-task/reconciliation_report/${contractId}/`,
    body,
  }),
  mapResult: ({ result }: { result: AxiosResponse }) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const postContractAttachmentsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({
    id,
    ...body
  }: PostContractAttachmentsParams) => ({
    method: Method.post,
    url: `/files/by_document/contract/${id}/`,
    body: prepareFormData({ ...body, files: undefined }),
  }),
  mapResult: ({ result }: { result: AxiosResponse<FileDTO[]> }) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const getClosureDocumentsByIdReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ id, signal }: AbortableRequest<{ id: ContractItem['id'] }>) => ({
    method: Method.get,
    url: `/contract/${id}/closing_documents/`,
    signal,
  }),
  mapResult: ({ result }: { result: AxiosResponse<GetClosureDocumentResponse> }) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const closeContractReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ docs, id }: { id: ContractItem['id'], docs: ClosureDocument['id'][] }) => ({
    method: Method.post,
    url: `/contract/${id}/close/`,
    body: { file_ids: docs },
  }),
  mapResult: ({ result }: { result: AxiosResponse<ContractItem> }) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const updateClosurePercentageReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (id: ContractItem['id']) => ({
    method: Method.post,
    url: `contract/${id}/update-closing-sums/`,
  }),
  mapResult: ({ result }: Response<ClosurePercentage>) => result.data,
  mapError: ({ error }: { error: AxiosError }) => defaultErrorHandler(error.response?.data),
})

export const getContractDirectionsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: () => ({
    method: Method.get,
    url: '/main/contract/directions/',
  }),
  mapResult: ({ result }: { result: AxiosResponse<ContractDirectionItem[]> }) => result.data,
})
