import { useUnit } from 'effector-react'
import React from 'react'
import styled from 'styled-components'

import {
  HeadL,
  SimpleLoader,
  TextLLight,
  palette,
} from '@/ui'
import { $userData } from '../../model'

export const ProfileInfo = React.memo(() => {
  const info = useUnit($userData)

  if (!info) return <Loader><SimpleLoader /></Loader>
  return (
    <UserInfo>
      <UserInfoName>
        {info.fullName}
      </UserInfoName>
      <UserInfoEmail>
        {info.email}
      </UserInfoEmail>
    </UserInfo>
  )
})

const Loader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const UserInfoName = styled.div`
  ${HeadL}
`

const UserInfoEmail = styled.div`
  ${TextLLight}
  color: ${palette.grey70};
`
