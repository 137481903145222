import { attachWrapper } from '@42px/effector-extra'
import { AxiosResponse } from 'axios'
import { AbortableRequest, CompanyItem, StatusResponse } from '../interfaces'

import { authRequestFx, Method } from '../request'
import {
  FSKCompanyItem,
  GetFSKCompanyParams,
  ProjectItemType,
} from './types'

export const getFSKCompanyReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (query?: GetFSKCompanyParams) => ({
    method: Method.get,
    url: '/company_fsk/',
    query,
  }),
  mapResult: ({ result }: {result: AxiosResponse<FSKCompanyItem[]>}) => result.data,
})

export const getProjectsReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: (params: AbortableRequest<{contragent?: number | string}> | undefined) => ({
    method: Method.get,
    url: '/project/',
    query: { contragent: params?.contragent },
    signal: params?.signal,
  }),
  mapResult: ({ result }: {result: AxiosResponse<ProjectItemType[]>}) => result.data,
})

export const getStatusReqFx = attachWrapper({
  effect: authRequestFx,
  mapParams: ({ company_id }: { company_id?: CompanyItem['id'] }) => ({
    method: Method.get,
    url: '/status/',
    query: {
      company_id,
    },
  }),
  mapResult: ({ result }: { result: AxiosResponse<StatusResponse[]> }) => result.data,
})
