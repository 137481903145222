import React, { memo } from 'react'
import styled, { css } from 'styled-components'
import { useUnit } from 'effector-react'

import {
  HeadM, Icon2, SimpleLoader, palette,
} from '@/ui'

import {
  $areDocumentsLoading,
  $areFiltersLoading,
  $estimateLotList,
  $totalDocCount,
  abortFx,
} from '../../model/private'
import { EstimateLotItem, LotPaginationItem } from '../containers'

export const EstimateLotList = memo(() => {
  const lotList = useUnit($estimateLotList)
  const totalDocCount = useUnit($totalDocCount)
  const areDocumentsLoading = useUnit($areDocumentsLoading)
  const areFiltersLoading = useUnit($areFiltersLoading)

  const isLoading = areDocumentsLoading || areFiltersLoading

  React.useEffect(() => () => {
    abortFx()
  }, [])

  return (
    <Wrapper hasMinHeight={isLoading || !lotList?.length}>
      {!lotList?.length && !isLoading
        ? (
          <EmptyList>
            <Icon2 icon="emptyTasks" size={170} />
            <EmptySubtitle>
              {totalDocCount
                ? 'Документы не найдены, измените параметры фильтрации'
                : 'У вас нет документов'}
            </EmptySubtitle>
          </EmptyList>
        ) : !isLoading && (
          <ListContainer>
            {lotList?.map((item) => (
              <EstimateLotItem
                key={item.id}
                {...item}
              />
            ))}
            <LotPaginationItem />
          </ListContainer>
        )}

      {isLoading && (
        <LoaderWrapper>
          <SimpleLoader />
        </LoaderWrapper>
      )}
    </Wrapper>
  )
})

const Wrapper = styled.div<{ hasMinHeight: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;

  ${({ hasMinHeight }) => hasMinHeight && css`
    min-height: 380px;
  `}
`

const LoaderWrapper = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ListContainer = styled.div`
  width: 100%;
  height: 100%;
`

const EmptyList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-grow: 1;
`

const EmptySubtitle = styled.p`
  ${HeadM}
  color: ${palette.grey60};
`
