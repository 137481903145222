import React from 'react'
import styled from 'styled-components'
import { CaptionProps, useNavigation } from 'react-day-picker'
import { format } from 'date-fns'
import ru from 'date-fns/locale/ru'

import { NewIconButton } from '../buttons'
import { TextL } from '../typography'

export const CalendarCaption = (props: CaptionProps) => {
  const { goToMonth, nextMonth, previousMonth } = useNavigation()

  return (
    <Wrapper>
      <NewIconButton
        icon="arrowLeft"
        color="grey90"
        size={16}
        padding="2px"
        borderRadius="50%"
        hoverBackgroundColor="grey20"
        disabled={!previousMonth}
        onClick={() => previousMonth && goToMonth(previousMonth)}
        dataTestId="calendar-prev-month"
      />
      <Date>
        {format(props.displayMonth, 'LLLL yyy', { locale: ru })}
      </Date>
      <NewIconButton
        icon="arrowRight"
        color="grey90"
        size={16}
        padding="2px"
        borderRadius="50%"
        hoverBackgroundColor="grey20"
        disabled={!nextMonth}
        onClick={() => nextMonth && goToMonth(nextMonth)}
        dataTestId="calendar-next-month"
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Date = styled.p`
  ${TextL}
  text-transform: capitalize;
`
