import React from 'react'
import { useUnit } from 'effector-react'

import { createAppealCommentsView } from '@/features/create-appeals-list/view/factories'

import {
  $messages,
  $selectedAppeal,
  commentForm,
  completeTaskFx,
  getMessagesFx,
  resetSelectedAppeal,
} from '../../model/appeals.private'
import { MessagesModalLayout } from '../parts'
import { SelectedAppeal } from '../../model/types'

const CommentsView = createAppealCommentsView<SelectedAppeal>({
  $comments: $messages,
  $isCommentsPending: getMessagesFx.pending,
  $selectedItem: $selectedAppeal,
  $isMessageSending: completeTaskFx.pending,
  commentForm,
  resetSelectedItem: resetSelectedAppeal,
})

export const AppealMessages = () => {
  const item = useUnit($selectedAppeal)

  if (!item) return null

  return (
    <MessagesModalLayout closeModal={resetSelectedAppeal}>
      <CommentsView />
    </MessagesModalLayout>
  )
}
