import React from 'react'
import styled, { css } from 'styled-components'
import { Icon2 } from '../icons'
import { NewIconButton, NewTextButton } from '../buttons'
import { TextM, TextMLight } from '../typography'
import { PaletteColor, palette } from '../palette'
import { NotificationType } from '../types'
import { NOTIFICATION_BG_COLOR, NOTIFICATION_ICON_COLOR } from '../const'

export type NotificationItemProps = {
  type: NotificationType
  body: string | JSX.Element
  title?: string | JSX.Element
  isFill?: boolean
  padding?: string
} & ({
  showCloseButton?: false
  onClose?: () => void
} | {
  showCloseButton: true
  onClose: () => void
}) & ({
  actionLabel?: undefined
  onActionClick?: () => void
  dataTestId?: string
} | {
  actionLabel: string
  onActionClick: () => void
  dataTestId: string
})

export const NotificationItem = ({
  body,
  onClose,
  type,
  showCloseButton,
  title,
  actionLabel,
  dataTestId,
  onActionClick,
  isFill,
  padding,
}: NotificationItemProps) => (
  <Item
    isFill={isFill}
    padding={padding}
    bgColor={NOTIFICATION_BG_COLOR[type]}
    type={type}
  >
    <Icon2 icon={type} size={20} color={NOTIFICATION_ICON_COLOR[type]} />

    <Text>
      {Boolean(title) && (
        <Title>
          {title}
        </Title>
      )}
      <Body>
        {body}
      </Body>
    </Text>

    {actionLabel && (
      <ActionWrapper>
        <NewTextButton
          label={actionLabel}
          onClick={onActionClick}
          textStyle="M"
          dataTestId={dataTestId}
        />
      </ActionWrapper>
    )}

    {showCloseButton && (
      <NewIconButton
        dataTestId=""
        color="grey60"
        hoverColor="grey90"
        onClick={onClose}
        icon="cross"
        size={20}
      />
    )}
  </Item>
)


const Item = styled.div<{ bgColor: PaletteColor, type: NotificationType, padding?: string, isFill?: boolean }>`
  display: flex;
  gap: 8px;
  padding: ${({ padding }) => (padding || '16px 20px')};
  border-radius: 8px;
  border: 1px solid transparent;
  background-color: ${({ bgColor }) => palette[bgColor]};
  transition: background-color 0.15s linear;
  ${({ type }) => type === NotificationType.Info && css`
    border: 1px solid ${palette.grey20};
  `}

  ${({ isFill }) => isFill && css`
    width: 100%;
  `}
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: flex-start;

  width: 100%;
  padding-top: 2px;
`

const Title = styled.p`
  ${TextM}
`

const Body = styled.div`
  ${TextMLight}
`

const ActionWrapper = styled.div`
  padding-top: 2px;
`
