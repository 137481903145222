import React from 'react'
import styled from 'styled-components'

import {
  CircleProgress,
  PaletteColor,
  Square,
  TextM,
  TextMLight,
  TextS,
} from '@/ui'
import { numberWithSpaces } from '@/lib/number-represent'

type Props = {
  filledPercent: number,
  stroke: PaletteColor,
  buttonSlot?: React.JSX.Element | null
  title?: string,
  labels: ({
    label: string,
    percent: string,
    sum: string,
    squareColor: PaletteColor | 'transparent',
  } | null)[]
}

export const ProgressItem = ({
  filledPercent,
  buttonSlot,
  stroke,
  labels,
  title,
}: Props) => {
  return (
    <Wrapper>
      <CircleProgress
        filledPercent={filledPercent}
        stroke={stroke}
        showPercent
        fixedSize={58}
        strokeWidth={10}
      />
      <Col>
        {title && (
          <Row>
            <Title>{title}</Title>
            {buttonSlot}
          </Row>
        )}
        {labels.map((item, index) => item && (
          <Row key={index}>
            <Label>
              <Square color={item.squareColor} />
              {item.label}
            </Label>
            <Total>
              (
              {item.percent}
              %)
              {' '}
              {numberWithSpaces(item.sum)}
            </Total>
          </Row>
        ))}
      </Col>

    </Wrapper>

  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  gap: 4px;
`

const Row = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;
`

const Title = styled.div`
  ${TextS}
  padding-bottom: 8px;
  text-transform: uppercase;
`

const Label = styled.div`
  ${TextMLight};
  display: flex;
  gap: 8px;
  align-items: center;
`

const Total = styled.div`
  ${TextM}
`
