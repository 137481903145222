import { createForm } from 'effector-forms'
import { attach } from 'effector'
import { createGate } from 'effector-react'

import { rules } from '@/lib/form-validators'
import { INVALID_DATE } from '@/features/app/model/const'
import { compareTwoRuDates } from '@/lib/date'
import { getStartDateActReqFx, postActReqFx } from '@/dal'
import { createSingleEffect } from '@/lib/createSingleEffect'

import { d } from './domain'

export const CreateActGate = createGate('CreateActGate')

export const onConfirmCreatePIRAct = d.event<void>()

const correctDateValidator = {
  name: 'correctDate',
  validator: (value: string) => value !== INVALID_DATE,
  errorText: 'Выберите корректное значение даты',
}

export const createActForm = createForm({
  domain: d,
  validateOn: ['submit'],
  fields: {
    date_start: {
      init: '',
      rules: [
        rules.required(),
        correctDateValidator,
        {
          name: 'dateBefore',
          validator: (
            value: string,
            form,
          ) => compareTwoRuDates(value, form.date_stop, '<'),
          errorText: 'Дата начала не может быть позже даты окончания',
        },
      ],
    },
    date_stop: {
      init: '',
      rules: [
        rules.required(),
        correctDateValidator,
        {
          name: 'dateAfter',
          validator: (value: string, form) => compareTwoRuDates(value, form.date_start, '>'),
          errorText: 'Дата окончания не может быть раньше даты начала',
        },
      ],
    },
  },
})

export const createActFx = attach({
  effect: postActReqFx,
})

export const {
  requestFx: getStartDateActFx,
} = createSingleEffect(
  getStartDateActReqFx, { abortEvents: [CreateActGate.close] },
)
