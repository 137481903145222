// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../fonts/FuturaFuturisC.ttf";
import ___CSS_LOADER_URL_IMPORT_1___ from "../fonts/FuturaFuturisLightC.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: FuturaFuturis;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  font-weight: 400;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: FuturaFuturis;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  font-weight: 300;\n  font-style: normal;\n}\n\n*, *::before, *::after {\n  box-sizing: border-box;\n}\n", "",{"version":3,"sources":["webpack://./src/ui/theming/global.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,4CAAuC;EACvC,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;EAC1B,4CAA4C;EAC5C,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":["@font-face {\n  font-family: FuturaFuturis;\n  src: url('../fonts/FuturaFuturisC.ttf');\n  font-weight: 400;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: FuturaFuturis;\n  src: url('../fonts/FuturaFuturisLightC.ttf');\n  font-weight: 300;\n  font-style: normal;\n}\n\n*, *::before, *::after {\n  box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
