import styled, { css } from 'styled-components'

import {
  TABLE_BORDER_STYLE,
  TABLE_BOX_SHADOW,
  TABLE_HEADER_BORDER_STYLE,
  TABLE_HEADER_THICK_BORDER_STYLE,
  palette,
} from '@/ui'

export const FirstCellWrapper = styled.div<{ hasShadow?: boolean }>`
  position: sticky;
  left: 0;
  z-index: 4;
  background-color: ${palette.white};
  clip-path: inset(0px -16px 0px 0px);
  transition: 0.2s linear;
  transition-property: background-color, box-shadow;

  ${({ hasShadow }) => hasShadow && css`
    box-shadow: ${TABLE_BOX_SHADOW};
  `}
`
export const FirstHeaderCellWrapper = styled(FirstCellWrapper)<{ hasRightDivider?: boolean }>`
  z-index: 5;
  border-right: ${TABLE_HEADER_BORDER_STYLE};

  ${({ hasRightDivider }) => hasRightDivider && css`
    border-right: ${TABLE_HEADER_THICK_BORDER_STYLE};
  `}
`

export const SplitCellWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > *:first-child {
    height: 50%;
  }
`

export const CellRow = styled.div`
  display: flex;
  border-top: ${TABLE_BORDER_STYLE};
  border-right: ${TABLE_BORDER_STYLE};
  height: 50%;
`
