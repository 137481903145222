import React from 'react'
import styled from 'styled-components'
import { useUnit } from 'effector-react'

import {
  HeadXL, ModalLayoutV2, NewButton, TextLLight,
} from '@/ui'

import { $isReadOnlyModalOpen, closeReadOnlyModal } from '../../model/private'

export const ReadOnlyModal = React.memo(() => {
  const isOpen = useUnit($isReadOnlyModalOpen)

  if (!isOpen) return null

  return (
    <ModalLayoutV2 dataTestId="close-read-only-modal-btn" closeModal={closeReadOnlyModal}>
      <Wrapper>
        <Container>
          <Header>Режим чтения</Header>

          <Paragraph>
            В режиме чтения вам доступна возможность просмотра документов, просмотра чатов, вложений
            и скачивания документов.
          </Paragraph>

          <Paragraph>
            Функции создания, редактирования и отправки на согласование — недоступны.
          </Paragraph>
        </Container>

        <NewButton
          label="Понятно"
          dataTestId="act-error-close-modal-button"
          isFill
          onClick={closeReadOnlyModal}
        />
      </Wrapper>
    </ModalLayoutV2>
  )
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 627px;
  padding: 32px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Header = styled.header`
  ${HeadXL}
  text-align: center;
  text-transform: uppercase;
`

const Paragraph = styled.p`
  ${TextLLight}
  text-align: center;
`
