import React from 'react'
import styled from 'styled-components'

import {
  DefaultButton, Icon2, IconName2, TextLLight, palette,
} from '@/ui'

type Props = {
  label: string
  icon: IconName2
  onClick: () => void
  dataTestId: string
}

export const UserPopupButton = ({
  label, icon, onClick, dataTestId,
}: Props) => (
  <Button data-testid={dataTestId} onClick={onClick}>
    <Icon2 icon={icon} size={16} color="grey100" />
    <ButtonText>
      {label}
    </ButtonText>
  </Button>
)

const Button = styled(DefaultButton)`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  padding: 8px 16px;
  background-color: ${palette.white};

  @media (hover: hover) {
    &:hover {
      background-color: ${palette.grey10};
    }
  }
`

const ButtonText = styled.p`
  ${TextLLight}
`
