import React from 'react'
import { useLocation } from 'react-router-dom'

export const useOnChangeLocation = (event: () => void) => {
  const location = useLocation()

  React.useEffect(() => {
    event()
  }, [location, event])
}
