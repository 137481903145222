import {
  CustomResponseError, ErrorAsIssueError, RequestCancelError, TextError, UserNotFoundError,
} from './errors'

export const isTextError = (error: Error):
  error is TextError => error instanceof TextError

export const isCustomError = (error: Error):
  error is CustomResponseError => error instanceof CustomResponseError

export const isUserNotFoundError = (error: Error):
  error is UserNotFoundError => error instanceof UserNotFoundError

export const isErrorAsIssueError = (error: Error):
  error is ErrorAsIssueError => error instanceof ErrorAsIssueError

export const isCancelError = (error: Error): error is RequestCancelError => (
  error instanceof RequestCancelError
)
