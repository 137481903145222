import Warning from './warning.svg'
import Alert from './alert.svg'
import Success from './success.svg'
import Info from './info.svg'

export const notificationIcons = {
  alert: Alert,
  warning: Warning,
  info: Info,
  success: Success,
}
