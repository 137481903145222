import { useStoreMap } from 'effector-react'

import { TargetDocument } from '../model'
import { $isCopyingDocuments, $isDeletingDocuments } from '../model/private'

type Params = {
  id: TargetDocument['id'] | null
  type: TargetDocument['type']
}

export const usePendingDocument = ({ id, type }: Params) => {
  const isDeleting = useStoreMap({
    store: $isDeletingDocuments,
    keys: [id, type],
    fn: (items, [id, type]) => items.some((item) => item.type === type && item.id === id),
  })
  const isCopying = useStoreMap({
    store: $isCopyingDocuments,
    keys: [id, type],
    fn: (items, [id, type]) => items.some((item) => item.type === type && item.id === id),
  })

  return {
    isDeleting,
    isCopying,
  }
}
