import React, { FormEvent } from 'react'
import styled from 'styled-components'
import { useForm } from 'effector-forms'
import { useUnit } from 'effector-react'

import { InputField, NewButton } from '@/ui'
import {
  repeatForm,
  repeatRegistrationLetterFx,
} from '../../model/repeat-registration-letter.private'
import { $userNotFoundError } from '../../model/private'

export const RepeatRegistrationForm = React.memo(() => {
  const {
    fields,
    submit,
    isValid,
    errorText,
  } = useForm(repeatForm)
  const isPending = useUnit(repeatRegistrationLetterFx.pending)
  const isNotFoundError = useUnit($userNotFoundError)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    submit()
  }
  const error = isNotFoundError
    ? 'Пользователь с такой почтой не найден, проверьте правильность ввода данных'
    : errorText('email')

  return (
    <Form onSubmit={handleSubmit}>
      <InputField
        size="L"
        dataTestId="email-input"
        onChange={fields.email.onChange}
        onClear={fields.email.reset}
        value={fields.email.value}
        error={error}
        placeholder="Введите e-mail"
      />
      <NewButton
        dataTestId="submit-button"
        label="Отправить"
        isFill
        isDisabled={!fields.email.value || !isValid}
        isPending={isPending}
        type="submit"
      />
    </Form>
  )
})

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`
